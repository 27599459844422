import { Component, Inject, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Certidoescamadasconfig } from '@funcate/sigweb-cti-api';
import { Subscription } from 'rxjs';
import { LayersConfigService } from '../../layers/layers.config.service';
import { BackendService } from '../../../service/backend.service';
import { ToastrService } from 'ngx-toastr';
import { ToolsService } from '../tools.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { GeneralService } from 'src/app/service/general.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../../vo/notification';
import { UserGroup } from '../../vo/user-group';
import { LayerConfig } from '../../layers/layers.config';


@Component({
  selector: 'app-generate-certidoes',
  templateUrl: './generate-certidoes.component.html',
  styleUrls: ['./generate-certidoes.component.css']
})
export class GenerateCertidoesComponent implements OnInit {

  tool:any;
  subscriptionTool:Subscription;
  inscricoes: string[];
  feature: any;
  featureSRID: string;
  solicitante: string;
  notificationSubscrition: Subscription;
  certidaoUso: boolean;
  certidaoEmplacamento: boolean;
  certidaoUnificacao: boolean;
  certidaoDesdobro: boolean;
  certidaoAreasEDatas: boolean;
  certidaoMedidas: boolean;
  relatorioGeralImovel: boolean;
  isConsulta: boolean; 
  

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private toolsService: ToolsService, private authenticationService: AuthenticationService) {

    this.inscricoes=data.inscricoes;
    this.feature=data.feature;
    this.featureSRID=data.featureSRID;
    this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (tool:any) => {
        this.tool=tool;
      }
    );
   
   }

  ngOnInit(): void 
  {    
    this.isConsulta = true;
    let currentUserInfo = this.authenticationService.getCurrentUser();
    if(currentUserInfo['usergroup_id']==UserGroup.ADMINISTRATOR.id
    ||currentUserInfo['usergroup_id']==UserGroup.EDITION.id)
    {
      this.isConsulta=false;
    }
    else
    {
      this.isConsulta=true;
    }
    
  }

  switchCertidao(certidao: string) : void
  {
    this.certidaoEmplacamento=false;
    this.certidaoUso=false;
    this.certidaoUnificacao=false;
    this.certidaoDesdobro=false;
    this.certidaoAreasEDatas=false;
    this.certidaoMedidas=false;
    this.relatorioGeralImovel=false;

    if(certidao=='certidao-uso')
    {
      this.certidaoUso=true;
    }
    if(certidao=='certidao-emplacamento')
    {
      this.certidaoEmplacamento=true;
    }
    if(certidao=='certidao-unificacao')
    {
      this.certidaoUnificacao=true;
    }
    if(certidao=='certidao-desdobro')
    {
      this.certidaoDesdobro=true;
    }
    if(certidao=='certidao-areas-e-datas')
    {
      this.certidaoAreasEDatas=true;
    }
    if(certidao=='certidao-medidas')
    {
      this.certidaoMedidas=true;
    }
    if(certidao=='relatorio-geral-imovel')
    {
      this.relatorioGeralImovel=true;
    }

  }

}
