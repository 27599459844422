import {ToolsService} from '../../tools.service';
import { LayersInfo } from '../../../vo/layersinfo';
import { MatDialog } from '@angular/material/dialog';
//import { FetureInfoToolComponent } from '../fetureinfo-tool.component';
import { DialogComponent } from '../../../dialog/dialog.component';
import { Tool } from '../../tool';
import { EditColetaPGVToolComponent } from './edit-coleta-pgv-tool.component';
import { Feature } from 'src/app/map/vo/feature';
import { AbstractLayerTool } from '../../abstract-layer-tool';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';
import { FeatureService } from 'src/app/service/feature.service';
import { ToastService } from 'src/app/service/toast.service';
import { ColetaPgvService } from 'src/app/service/coleta-pgv.service';
import { ColetaPGV } from 'src/app/map/vo/coleta-pgv';


export class EditColetaPgvTool extends Tool {

    private dialog: MatDialog;

    cursorType='tool-cursor-pointer';
    id='edit-coleta-pgv-tool';
    enabled=false;
    name='Editar Coleta PGV Tool';
    title='Editar coleta PGV por apontamento';
    type='click';
    map:L.Map=null;
    ll: any; 
    protected visible:boolean=true;
    icon='auto_fix_normal';
    layerTool: boolean=false;
    customParameter=new Map<String, any>();
    private selectedFeature: Feature;
    private dialogRef: any;

    constructor(toolService: ToolsService, private canvasService: CanvasService, private layerConfigService: LayersConfigService, 
        private featureService: FeatureService, private toastService: ToastService, private coletaPgvService: ColetaPgvService, dialog: MatDialog) 
    { 
        super(toolService)
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{
        this.map=event.sourceTarget;
        this.ll=event.latlng;
        
       this.loadFeature();

    };

    public enableImpl()
    {
        let layer : LayerConfig = this.layerConfigService.getColetaPGVLayer();
        if(layer.isEnabled()==false)
        {
            this.layerConfigService.enableLayer(layer);
        }
    }
    public disableImpl()
    {
    }

    public closeDialog()
    {
        this.dialogRef.close();
    }
    public setFeature(feature: Feature)
    {
        this.selectedFeature = feature;
    }

    public isEnabled(layer :LayerConfig) : boolean
    {
        let currentLayerTool = this.layerConfigService.getColetaPGVLayer();

        if(this.enabled && currentLayerTool.getId()==layer.getId() )
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    private loadFeature()
    {

        let layer = this.layerConfigService.getColetaPGVLayer()
        
        let idAttribute = this.layerConfigService.getLayerAppConfig(layer, "id_attribute"); 

        if(layer)
        {
            this.map.eachLayer( (l)=>{
                if(l["wmsParams"]) {
        
                    if(l["id"]==layer.getId())
                    {
                        this.featureService.getFeatureInfo(this.ll, l, this.map).then(featureInfo => {
            
                            if(featureInfo)
                            {
                                if(featureInfo.features.length>0)
                                {
                                         
                                    let coletaId = this.featureService.getPropertyByKey(featureInfo.features[0].properties, idAttribute);

                                    if(coletaId)
                                    {
                                        this.showEditDialog(coletaId.value.toString());
                                    }
                                    else
                                    {
                                        this.toastService.error("Camada de PGV configurada erroneamente.","");
                                    }
                                }
                                else
                                {
                                    this.toastService.error("Não foi possível encontrar coleta algum no local apontado.","");
                                }
                            }
                        
                        });
                    }
                }
            });
        }
    }

    private showEditDialog(coletaId: string)
    {
        let layer = this.layerConfigService.getColetaPGVLayer()

        this.coletaPgvService.getColetaById(coletaId).then((coletaPGV: ColetaPGV)=>
        {
            this.dialogRef = this.dialog.open(DialogComponent, {
                height: '70%',
                width: '40%',
                data: { component: EditColetaPGVToolComponent, title: "Editar dados de coleta PGV", coleta: coletaPGV, layer: layer , parent: this}            
            });
    
            // let whenDialogIsOpen=this.dialogRef.afterOpened().toPromise();
            // whenDialogIsOpen.then(
            //     ()=>{
            //         this.toolsService.dispatchTool(this);
            //     }
            // );
    
            this.dialogRef.afterClosed().toPromise().then(()=>{
                let layer : LayerConfig = this.layerConfigService.getColetaPGVLayer();
    
                this.canvasService.redrawLayer(layer);
            });
        });
       
    }


  public closeEditColeta()
  {
    this.dialogRef.close();
  }
}
