<mat-accordion class="layers-config-accordion">    
  <ng-container>
      <mat-expansion-panel [expanded]="true" class="tool-expasion-panel" >
          <mat-expansion-panel-header>
              <mat-panel-title id="user-header" class="user-col">
                  <mat-icon class="material-icons layer-icon">layers</mat-icon>Importação de camadas
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="align-column">            
            <div class="outside-div">
              <mat-card id="progresso" class="import-layers-cards-background" >
                <div id="passos-div">
                    <mat-card *ngFor="let step of stepsController.getSteps()" [ngClass]="step ==  stepsController.getCurrentStep() ? 'passo-selecionado' : ''">Passo {{step.index}}</mat-card>
                </div>
                <mat-progress-bar id="progress-bar" mode="determinate" [value]="this.stepsController.getPercent()">
                </mat-progress-bar>
              </mat-card>
              <mat-card class="import-layers-cards-background"  id="passos-descricao-card">
                <mat-card-title>{{stepsController.getCurrentStep().getTitle()}}</mat-card-title>
                <mat-card-content>{{stepsController.getCurrentStep().getDescription()}}</mat-card-content>
            </mat-card>

              <!-- PASSO 1-->
            
              <mat-card class="import-layers-cards-background" id="upload-file-card" *ngIf="isCardVisible('upload-file-card')">
                <mat-card-subtitle class="create-layer-card-subtitle"><mat-icon class="material-icons">publish</mat-icon>Arquivo para Importar</mat-card-subtitle>
                  <mat-card-content>          
                    <div class="import-layer-file-div">
                      <input class="form-control" type="text" disabled id="import-layer-file-name-display" [value]="stepsController.getCurrentStep().currentFilename"/>
                      <button mat-raised-button color="primary" [ngClass]="{'spinner' : stepsController.getCurrentStep().uploadingFile==true}" (click)="importLayerFileInput.click()" [disabled]="stepsController.getCurrentStep().uploadingFile">Procurar</button>
                    </div>
                    <input #importLayerFileInput id="import-layer-file-input" type="file" style="display: none;" accept=".zip,.dxf,.kml" (change)="stepsController.getCurrentStep().onFileSelect($event)"/>
                  </mat-card-content>
              </mat-card>

              <!-- PASSO 2-->

              <mat-card class="import-layers-cards-background" id="upload-sld-file-card" *ngIf="isCardVisible('upload-sld-file-card')">
                <mat-card-subtitle class="create-layer-card-subtitle"><mat-icon class="material-icons">format_paint</mat-icon>Arquivo de Estilo</mat-card-subtitle>
                  <mat-card-content>          
                    <div class="import-layer-sld-preview" *ngIf="legendPreviewURL!=null">
                      <label for="import-layer-apply-style" class="">Pré-visualização</label>
                      <img [src]="legendPreviewURL" id="legend-preview" >
                    </div>
                    <div class="import-layer-file-div">
                      <input class="form-control" type="text" disabled id="import-layer-sld-file-name-display" [value]="stepsController.getCurrentStep().currentSLDFilename"/>
                      <button mat-raised-button color="primary"  [ngClass]="{'spinner' : stepsController.getCurrentStep().uploadingFile==true}" (click)="importLayerFileInput.click()" [disabled]="stepsController.getCurrentStep().uploadingFile">Procurar</button>
                    </div>
                    <input #importLayerFileInput id="import-layer-file-input" type="file" style="display: none;" accept=".sld" (change)="stepsController.getCurrentStep().onFileSelect($event)"/>
                  </mat-card-content>
              </mat-card>

              <!-- PASSO 3-->

              <mat-card class="import-layers-cards-background" id="upload-check-data-card" *ngIf="isCardVisible('upload-check-data-card')">
                <mat-card-subtitle class="create-layer-card-subtitle"><mat-icon class="material-icons">fact_check</mat-icon>Informações do Arquivo</mat-card-subtitle>
                  <mat-card-content>          
                    <div>    
                      <mat-form-field>
                        <mat-label>Tipo do Arquivo</mat-label>
                        <input matInput type="text" placeholder="Tipo do Arquivo"
                          [value]="importlayerfileinfo.filetype" disabled>
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label>Nome da Camada</mat-label>
                        <input matInput type="text" placeholder="Nome da Camada"
                          [value]="importlayerfileinfo.layername" [ngClass]="{'invalidLayerName' : stepsController.getCurrentStep().isTableNameValid==false}" (input)="clearLayerName($event)" (onPaste)="clearLayerName($event)" (focusout)="stepsController.getCurrentStep().onFocusOutEvent($event)" pattern="[a-zA-Z]">
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label>Tipo de Geometrias</mat-label>
                        <input matInput type="text" placeholder="Tipo de Geometrias"
                          [value]="importlayerfileinfo.geomtype" disabled>
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label>Quantidade de Feições</mat-label>
                        <input matInput type="text" placeholder="Quantidade de Feições"
                          [value]="importlayerfileinfo.featurecount" disabled>
                      </mat-form-field>

                      <mat-form-field>
                        <mat-label>Projeção EPSG</mat-label>
                        <input matInput type="text" placeholder="Projeção EPSG"
                          [value]="importlayerfileinfo.srid" disabled>
                      </mat-form-field>  
                      
                      <mat-card>
                        <mat-card-subtitle>Campos da camada</mat-card-subtitle>
                          <mat-card-content>          
                            <table class="table table-striped table-bordered" id="import-layer-fields-table">                      
                              <thead>
                                <tr>
                                  <th>Nome</th>
                                  <th>Tipo</th>
                                  <th>Observações</th>
                                </tr>
                                </thead>                                
                                <tbody>
                                <tr *ngFor="let field of importlayerfileinfo.fields">
                                  <td>{{field.name}}</td>
                                  <td>{{field.type}}</td>
                                  <td>
                                    <mat-icon *ngIf="field.approved==true" [title]="field.msg" class="field-ok-icon">check_circle</mat-icon>
                                    <mat-icon *ngIf="field.approved==false"  [title]="field.msg" class="field-warning-icon">warning</mat-icon>
                                  </td>
                                  
                                </tr>
                                </tbody>
                            </table>
                          </mat-card-content>
                      </mat-card>

                      <mat-card *ngIf="importlayerfileinfo['sldfields_validation']">
                        <mat-card-subtitle>Campos utilizados no Estilo</mat-card-subtitle>
                          <mat-card-content>          
                            <table class="table table-striped table-bordered" id="import-layer-fields-table">                      
                              <thead>
                                <tr>
                                  <th>Nome</th>
                                  <th>Observações</th>
                                </tr>
                                </thead>                                
                                <tbody>
                                <tr *ngFor="let field of importlayerfileinfo['sldfields_validation']">
                                  <td>{{field.name}}</td>
                                  <td>
                                    <mat-icon *ngIf="field.approved==true" [title]="field.msg" class="field-ok-icon">check_circle</mat-icon>
                                    <mat-icon *ngIf="field.approved==false"  [title]="field.msg" class="field-warning-icon">warning</mat-icon>
                                  </td>
                                </tr>
                                </tbody>
                            </table>
                          </mat-card-content>
                      </mat-card>
      
                  </div>
                  </mat-card-content>
              </mat-card>

              <!-- PASSO 4-->

              <mat-card class="import-layers-cards-background" id="create-layer-basic-info-card" *ngIf="isCardVisible('create-layer-basic-info-card')">
                <mat-card-subtitle class="create-layer-card-subtitle"> <mat-icon class="material-icons">info</mat-icon>Informações da Camada</mat-card-subtitle>
                  <mat-card-content>          
                    <div class="align-row">                  
                      <mat-form-field appearance="fill">
                        <mat-label>Nome:</mat-label>
                        <input matInput type="text" class="property-value"  [(ngModel)]="newLayerConfig.name" required>
                      </mat-form-field>   
                      <mat-form-field appearance="fill">
                        <mat-label>Titulo: </mat-label>
                        <input matInput type="text" class="property-value"  [(ngModel)]="newLayerConfig.title">
                      </mat-form-field>   
                    </div>
                    <div class="align-row">                  
                      <mat-form-field appearance="fill">
                        <mat-label>Descrição: </mat-label>
                        <input matInput type="text" class="property-value"  [(ngModel)]="newLayerConfig.description">
                      </mat-form-field>   
                    </div>
                  </mat-card-content>
              </mat-card>

              <!-- PASSO 5-->

              <mat-card class="import-layers-cards-background" id="create-layer-wms-info-card" *ngIf="isCardVisible('create-layer-wms-info-card')">
                <mat-card-subtitle class="create-layer-card-subtitle"> <mat-icon class="material-icons">public</mat-icon>Informações de Conexão WMS</mat-card-subtitle>
                  <mat-card-content>          
                    <div class="align-row">                  
                      <mat-form-field appearance="fill">
                        <mat-label>Fonte (URL): </mat-label>
                        <input matInput type="text" class="property-value" [(ngModel)]="newLayerConfig.source" disabled>
                      </mat-form-field>   
                    </div>
                    <div class="align-row">                  
                      <mat-form-field appearance="fill">
                        <mat-label>Caminho: </mat-label>
                        <input matInput type="text" class="property-value" [(ngModel)]="newLayerConfig.path" required  disabled>
                      </mat-form-field>   
                    </div>
                    <div class="align-row">                                        
                      <mat-form-field appearance="fill">
                        <mat-label>Workspace: </mat-label>
                        <input matInput type="text" class="property-value" [(ngModel)]="newLayerConfig.workspace" required  disabled>
                      </mat-form-field>   
                      <mat-form-field appearance="fill">
                        <mat-label>Identificador: </mat-label>
                        <input matInput type="text" class="property-value" [(ngModel)]="newLayerConfig.id" required  disabled>
                      </mat-form-field>   
                    </div>
                  </mat-card-content>
              </mat-card>


              <!-- PASSO 6-->

              <mat-card class="import-layers-cards-background" id="create-layer-tools-info-card" *ngIf="isCardVisible('create-layer-tools-info-card')">
                <mat-card-subtitle class="create-layer-card-subtitle"> <mat-icon class="material-icons">construction</mat-icon>Ferramentas Habilitadas</mat-card-subtitle>
                  <mat-card-content>          
                    <mat-selection-list #layertools (selectionChange)="stepsController.getCurrentStep().onToolSelection($event)" class="layer-tools-selection">
                      <mat-list-option *ngFor="let tool of stepsController.getCurrentStep().allTools" class="tool-list-option" [value]="tool" [selected]="tool.enabled">
                          <div class="tool-div">
                            {{tool.toolTitle}} 
                          </div>                                                 
                      </mat-list-option>
                    </mat-selection-list>
                  </mat-card-content>
              </mat-card>


              <!-- PASSO 7-->

              <mat-card class="import-layers-cards-background" id="create-layer-permissions-info-card" *ngIf="isCardVisible('create-layer-permissions-info-card')">
                <mat-card-subtitle class="create-layer-card-subtitle"> <mat-icon class="material-icons">manage_account</mat-icon>Permissões</mat-card-subtitle>
                  <mat-card-content>          
                    <div class="align-row">                  
                      <div>                          
                        <mat-checkbox class="property-value checkbox-public" [(ngModel)]="newLayerConfig.permission.public">Pública</mat-checkbox>          
                      </div>
                    </div>
                    <div class="align-column">                                              
                      <mat-label>Grupos de Usuários: </mat-label>
                      <div class="align-column">
                        <mat-card *ngFor="let userGroupPermission of stepsController.getCurrentStep().currentLayerUserGroupsPermissions" class="user-group-card">
                          <div class="align-row">
                            <div class="user-group-name">
                              {{userGroupPermission.userGroup.name}}
                            </div>
                            <div class="user-group-permission">                                      
                              <div>                                  
                                <mat-checkbox class="property-value" [(ngModel)]="userGroupPermission.read" (change)="stepsController.getCurrentStep().onGrupoPermissionChange(userGroupPermission)">Leitura</mat-checkbox>                    
                              </div>
                              <div>                                  
                                <mat-checkbox class="property-value" [(ngModel)]="userGroupPermission.write" (change)="stepsController.getCurrentStep().onGrupoPermissionChange(userGroupPermission)">Escrita</mat-checkbox>                   
                              </div>
                            </div>
                          </div>
                        </mat-card>
                      </div>                                                         
                  </div>
                  </mat-card-content>
              </mat-card> 
              
              <!-- PASSO 8-->

              <mat-card class="import-layers-cards-background" id="create-layer-advanced-config-info-card" *ngIf="isCardVisible('create-layer-advanced-config-info-card')">
                <mat-card-subtitle class="create-layer-card-subtitle"> <mat-icon class="material-icons">settings_suggest</mat-icon>Configurações Avançadas</mat-card-subtitle>
                  <mat-card-content>          
                    <div class="align-row">
                      <json-editor [options]="stepsController.getCurrentStep().layerAdvancedConfigEditorOptions" [data]="stepsController.getCurrentStep().layerAdvancedConfigJson" class="layer-advanced-config" style="width: 100%; height: 600px;"></json-editor>
                    </div>
                  </mat-card-content>
              </mat-card>  
                           
            </div>            
            <mat-card class="import-layers-cards-background" id="botoes-card"  *ngIf="isCardVisible('botoes-card')">
              <div class="botoes-div" [ngClass]="{'botoes-div-first-step': stepsController.isFirstStep()==true}">
                  <button *ngIf="stepsController.isFirstStep()==false" mat-raised-button color="primary" class="voltar-recuo" (click)="this.stepsController.back()">Voltar</button>
                  
                  <button *ngIf="!stepsController.isLastStep() && stepsController.getCurrentStep().valid()" mat-raised-button color="primary" class="avancar-recuo" (click)="this.stepsController.next()">Avançar</button>
                  <button *ngIf="stepsController.isLastStep() && stepsController.getCurrentStep().valid()" mat-raised-button color="primary" class="avancar-recuo" (click)="this.stepsController.finish()">Finalizar</button>
              </div>
          </mat-card>      
          </div>          
      </mat-expansion-panel>
  </ng-container>
</mat-accordion>


