<div *ngIf="mergeTool && currentFeatureId1 && currentFeatureId2" class="fullFrame">
    
    <input type="hidden" value="{{currentFeatureGeom1}}" id="merge-tool-geom1">
    <input type="hidden" value="{{currentFeatureId1}}" id="merge-tool-geom-id1" #geomid1>
    <input type="hidden" value="{{currentFeatureGeom2}}" id="merge-tool-geom2">
    <input type="hidden" value="{{currentFeatureId2}}" id="merge-tool-geom-id2" #geomid2>
    <input type="hidden" value="{{currentFeatureSRID}}" id="merge-tool-geom-srs">
    <input type="hidden" value="{{baseLayer.getId()}}" id="merge-tool-baselayer-id">
    <input type="hidden" value="{{getBaseLayerURL()}}" id="merge-tool-baselayer-url">
    <input type="hidden" value="{{getBaseLayerName()}}" id="merge-tool-baselayer-layername">

    <div id="merge-tool-form">
        <input type="hidden" id="merge-tool-out-geom-json" name="geom" #outgeom>
        
        <button mat-raised-button color="primary" id="merge-tool-submit-form" (click)="save(geomid1.value, geomid2.value, outgeom.value)">
            <span class="material-icons">save</span> Save
        </button>     
    </div>

    <iframe src="assets/external/lote-tools/html/anexacao.html" class="fullFrame" frameBorder="0"></iframe>

</div>
