<mat-accordion *ngIf="layer" class="add-coleta-pgv-accordion">
    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="layername">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header" class="layers-col">
                    <mat-icon class="material-icons layer-icon">layers</mat-icon>Formulário de Coleta de Amostras
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
               <div class="form-line">
                   <div class="half-width">
                        <mat-form-field appearance="fill">
                            <mat-label>Fonte da Informação: (nome da imobiliária ou corretor) </mat-label>
                            <input matInput type="text" class="property-value"  [(ngModel)]="coletaPGV.fonte" required readonly disabled>
                        </mat-form-field>    

                    </div>
                   <div class="full-width">
                    <mat-form-field appearance="fill">
                        <mat-label>Usuário:</mat-label>
                        <input matInput type="text" class="property-value" [(ngModel)]="coletaPGV.nomeUser" disabled>
                      </mat-form-field>
                    </div>
                    <div class="full-width">
                        <mat-form-field appearance="fill">
                            <mat-label>Data do coleta:</mat-label>
                            <input matInput [matDatepicker]="picker" class="property-value"  [(ngModel)]="coletaPGV.dataColeta" [max]="maxDate" required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker [dateClass]="dateClass" #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
               </div>
               <div class="form-line">
                    <div class="half-width">
                        <mat-form-field appearance="fill">
                            <mat-label>Natureza da Transação: </mat-label>
                            <mat-select required [(ngModel)]="coletaPGV.naturezaTransacao" class="property-value">
                                <mat-option *ngFor="let naturezaTransacao of coletaPGV.naturezasTransacao" [value]="naturezaTransacao.id" >
                                {{naturezaTransacao.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>   
                    </div>
                    <div class="half-width">
                        <mat-form-field appearance="fill">
                            <mat-label>Tipo da Construção: </mat-label>
                            <mat-select required [(ngModel)]="coletaPGV.tipoConstrucao" class="property-value">
                                <mat-option *ngFor="let tipoConstrucao of coletaPGV.tiposConstrucao" [value]="tipoConstrucao.name" >
                                {{tipoConstrucao.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>   
                    </div>
               </div>
               <div class="form-line">
                    <div class="half-width">
                        <mat-form-field appearance="fill">
                            <mat-label>Área do Terreno (m2): </mat-label>
                            <input matInput type="text" [allowNegativeNumbers]="false" suffix=" m²" mask="separator.2" [thousandSeparator]="'.'" [decimalMarker]="','"  class="property-value"  [(ngModel)]="coletaPGV.areaTerreno" required >
                        </mat-form-field>   
                    </div>
                    <div class="half-width">
                        <mat-form-field appearance="fill">
                            <mat-label>Área da Construção (m2): </mat-label>
                            <input matInput type="text" [allowNegativeNumbers]="false" suffix=" m²" mask="separator.2" [thousandSeparator]="'.'" [decimalMarker]="','" class="property-value"  [(ngModel)]="coletaPGV.areaConstrucao" required>
                        </mat-form-field>   
                    </div>
               </div>
               <div class="form-line">
                <div class="half-width">
                    <mat-form-field appearance="fill">
                        <mat-label>Valor do Terreno (R$): </mat-label>
                        <input matInput type="text" [allowNegativeNumbers]="false" prefix="R$ " mask="separator.2" mask="separator" [thousandSeparator]="'.'" [decimalMarker]="','" class="property-value" [(ngModel)]="coletaPGV.valorTerreno" (change)="updateTotal()" >
                    </mat-form-field>   
                </div>
                <div class="half-width">
                    <mat-form-field appearance="fill">
                        <mat-label>Valor da Construção (R$): </mat-label>
                        <input matInput type="text" [allowNegativeNumbers]="false" prefix="R$ " mask="separator.2" mask="separator" [thousandSeparator]="'.'" [decimalMarker]="','" class="property-value"  [(ngModel)]="coletaPGV.valorConstrucao" (change)="updateTotal()" >
                    </mat-form-field>   
                </div>
               </div>
               <div class="form-line">
                   <div class="full-width">
                        <mat-form-field appearance="fill">
                            <mat-label>Valor Total do Imóvel (Terreno + Construção) (R$): </mat-label>
                            <input matInput type="text"  prefix="R$ " mask="separator" [thousandSeparator]="'.'" [decimalMarker]="','" class="property-value"  [(ngModel)]="coletaPGV.valorTotal">
                        </mat-form-field>   
                   </div>
      
               </div>
               <div class="form-line">
                    <div class="full-width">
                        <mat-form-field appearance="fill">
                            <mat-label>Endereço: </mat-label>
                            <input matInput type="text" class="property-value" [(ngModel)]="coletaPGV.endereco">
                        </mat-form-field>   
                    </div>
               </div>
               <div class="form-line">
                    <div class="full-width">
                        <mat-form-field appearance="fill">
                            <mat-label>Observação: </mat-label>
                            <textarea matInput type="text" class="property-value" rows="5"  [(ngModel)]="coletaPGV.obs"></textarea>
                        </mat-form-field>   
                    </div>
               </div>
            </div>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>


<div class="buttons-div">
    <div class="save-button">
        <button mat-raised-button color="primary" (click)="save()">Salvar</button>

    </div>
    <div class="cancel-button">
        <button mat-raised-button color="basic" (click)="close()">Cancelar</button>
    </div>
</div>