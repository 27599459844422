import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import {LayersConfigService} from '../layers/layers.config.service';
import {CanvasService} from '../canvas/canvas.service';
import { MatRadioChange } from '@angular/material/radio';
import { LayerConfig } from './layers.config';
import { NotificationService } from 'src/app/service/notification.service';
import { Subscription } from 'rxjs';
import { AppNotification } from '../vo/notification';
import { ToolsService } from '../tools/tools.service';
import { Tool } from '../tools/tool';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { GeneralService } from 'src/app/service/general.service';

@Component({
  selector: 'app-layers',
  templateUrl: './layers.component.html',
  styleUrls: ['./layers.component.scss']
})
export class LayersComponent implements OnInit 
{
  
  baselayers:LayerConfig[] = [];
  overlayers:LayerConfig[] = [];
  layerFilter: string;

  panelOpenState = true;

  notificationSubscrition: Subscription;

  authenticationService: AuthenticationService;

  constructor(private layersConfigService:LayersConfigService,
             private canvasService:CanvasService,
             private notificationService: NotificationService,
             private toolsService: ToolsService, authenticationService: AuthenticationService, private generalService: GeneralService) { 
  
    this.authenticationService = authenticationService;
    this.canvasService=canvasService;

    this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
      if (notification.event==AppNotification.ENABLE_LAYER_EVENT) 
      {
        this.enableOverLayer(notification.object);
      }
      else if (notification.event==AppNotification.ENABLE_LAYER_EDITION_EVENT) 
      {
        this.setEditionLayer(notification.object,true);
      } else if (notification.event==AppNotification.DISABLE_LAYER_EDITION_EVENT) 
      {
        this.setEditionLayer(notification.object,false);
      }
      else if (notification.event==AppNotification.AUTHENTICATION_CHANGED) 
      {
        if(notification.object)
        {
          //Depois do login, obtendo as camadas para o usuário logado.
          this.layersConfigService.loadLayers().then((layers)=>{
              //Atualizando as camadas a partir do JSON recebido
              this.layersConfigService.updateLayersFromJSON(layers); 

              this.overlayers = this.layersConfigService.getOverlayers()
              this.baselayers = this.layersConfigService.getBaselayers();

              this.addInitialLayers();

              this.canvasService.notifyLayerChanged();    

              let notification = new AppNotification(AppNotification.LAYERS_LOADED);

              this.notificationService.send(notification);
          });  
        }
        else
        {
          console.error("User not authenticated, not loading layers")
        }              
      }
    });
    
  }

  ngOnInit(): void {
    
    this.overlayers = this.layersConfigService.getOverlayers();
    this.baselayers = this.layersConfigService.getBaselayers();
    
    this.canvasService.onCanvasReady().then( () =>
    {
      //When canvas is ready
      this.addInitialLayers();
      
    });

  }

  ngOnDestroy() {
    this.notificationSubscrition.unsubscribe();
  }
/**
 * This funtion reads all layers from layer JSON config and add each of then enabled to leaflet respecting order as Z-Index
 */
  addInitialLayers()
  {
    //BaseLayers
    let baselayer=this.layersConfigService.getBaselayers().find((layerconfig)=>
    {
      if(layerconfig.isEnabled()) 
      {
        return layerconfig;
      }
    });
    if(baselayer)
    {
      this.canvasService.swapBaselayer(baselayer);
    }    

    this.canvasService.updateOverLayers();

    
  }

  /**
   * Handling enable/disable layer event 
   */
  onChangeOverlayer($event: MatSlideToggleChange){
    let overlayer = this.layersConfigService.getOverLayerById($event.source.id);

    overlayer.setEnabled($event.checked);
    if (overlayer.isEnabled())
    {
      this.canvasService.addOverlayerToMap(overlayer);
    }
    else
    {
      this.canvasService.removeOverlayerFromMap(overlayer);
    }            

      this.canvasService.updateOverLayers();
    
  }

  onChangeBaselayer($event: MatRadioChange){
    this.layersConfigService.getBaselayers().forEach((layerconfig)=>{layerconfig.setEnabled(false);});
    let baselayer=this.layersConfigService.getBaselayers().find((layerconfig)=>{if(layerconfig.getId()==$event.source.id) return layerconfig;});
    baselayer.setEnabled($event.source.id==baselayer.getId());
    this.canvasService.swapBaselayer(baselayer);
  }

  dropBaseLayer(event: CdkDragDrop<string[]>) {
    
    moveItemInArray(this.layersConfigService.getBaselayers(), event.previousIndex, event.currentIndex);

    this.canvasService.updateOverLayers();
  }

  dropOverLayer(event: CdkDragDrop<string[]>) {
    
    moveItemInArray(this.layersConfigService.getOverlayers(), event.previousIndex, event.currentIndex);
    
    this.canvasService.updateOverLayers();
  }

  enableOverLayer(layer: LayerConfig)
  {
    this.overlayers.forEach(overlayer => {
      if(layer.getId()==overlayer.getId())
      {
        overlayer.setEnabled(true);
      }
    });
    this.canvasService.updateOverLayers();
  }

  getLayerTools(layer: LayerConfig) : Array<Tool>
  {
    let layerTools: Array<Tool> = this.toolsService.getLayerTools(layer);
    return layerTools;
  }

  toogleTool(toogleTool: Tool, layer: LayerConfig)
  {
    toogleTool.customParameter.set("layer", layer);
    this.toolsService.toogleTool(toogleTool);
  }

  setEditionLayer(layer: LayerConfig, enable: boolean)
  {
    if(enable)
    {
      if(!layer.isEnabled())
      {
        this.enableOverLayer(layer);
      }
      layer.getApplicationconfigs()['onedition']=true;
    }
    else
    {
      layer.getApplicationconfigs()['onedition']=null;
    }
    
  }

  formatOpacityLabel(value: number) {
    return value + "%";
  }

  setOpacityForLayer(layer: LayerConfig, event)
  {
    console.log(layer);
    console.log(event);
    let opacity: number = event.value;

    let leafletLayer = this.canvasService.getLeafletLayerById(layer.getId());
    this.canvasService.updateLayerOpacity(leafletLayer, opacity/100);
  }
  layerFilterChanged()
  {
    let empty = false;
    if(!this.layerFilter)
    {
      empty = true;
    }

    let filter = this.generalService.removeAccents(this.layerFilter.trim().toLowerCase());

    this.overlayers.forEach(overlayer => 
      {
       let layerName = "";
       if(overlayer.getName())
       {
        layerName = this.generalService.removeAccents(overlayer.getName().toLowerCase());
       }

       let layerTitle = "";
       if(overlayer.getTitle())
       {
        layerTitle = this.generalService.removeAccents(overlayer.getTitle().toLowerCase());
       }

       let layerDescription = "";
       if(overlayer.getDescription())
       {
        layerDescription = this.generalService.removeAccents(overlayer.getDescription().toLowerCase());
       }
        
      if(empty == true 
          || (layerName.includes(filter)
          || layerTitle.includes(filter)
          || layerDescription.includes(filter)))
        {
          overlayer.setHidden(false);
        }
        else
        {
          overlayer.setHidden(true);
        }
    });
  }
  clearLayerFilter()
  {
    this.layerFilter="";
    this.layerFilterChanged();
  } 

}
