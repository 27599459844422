import { ToolsService } from '../tools.service';
import { MatDialog } from '@angular/material/dialog';
import { SplitToolComponent } from './split-tool.component';
import { Feature } from '../../vo/feature';
import { DialogComponent } from '../../dialog/dialog.component';
import { Tool } from '../tool';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { LayerConfig } from '../../layers/layers.config';
import { LayersConfigService } from '../../layers/layers.config.service';
import { ToastService } from 'src/app/service/toast.service';


export class SplitTool extends AbstractLayerTool {

    dialog: MatDialog;

    cursorType='pointer';
    id='split-layer-tool';
    enabled=false;
    name='Split Geometry Tool';
    title='Desdobro';
    type='click';
    map:L.Map=null;
    icon='flip';
    ll: any;
    protected visible:boolean=true;
    selectedFeature: Feature;
    layerTool: boolean=true;
    customParameter=new Map<String, any>();
    dialogRef: any;

    constructor(toolsService: ToolsService, private layerConfigService: LayersConfigService,  private toastService: ToastService, dialog: MatDialog) 
    { 
        super(toolsService)
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{
        this.map=event.sourceTarget;
        this.ll=event.latlng;
        
        this.dialogRef = this.dialog.open(DialogComponent, {
            height: '80%',
            width: '80%',
            data: { component: SplitToolComponent, title: "Desdobro" }
        });

        let whenDialogIsOpen=this.dialogRef.afterOpened().toPromise();
        whenDialogIsOpen.then(
            ()=>{
                this.toolsService.dispatchTool(this);
            }
        );
    };
    public enableImpl()
    {
        let currentLayerTool = this.toolsService.getToolParameter(this, "layer");
        if(currentLayerTool)
        {
            this.layerConfigService.enableLayer(currentLayerTool);
        }
        this.toastService.sucess("Aponte a geometria para o desdobro.", "Ferramenta de Desdobro Habilitada"); 
    }
    public disableImpl()
    {
    }    

    public isVisibleForLayer(layer :LayerConfig) : boolean
    {
        return this.layerTool;
    }

    public isEnabled(layer :LayerConfig) : boolean
    {
        let currentLayerTool = this.toolsService.getToolParameter(this, "layer");
        if(this.enabled && currentLayerTool.getId()==layer.getId() )
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    public close()
    {
        this.dialogRef.close();
    }

}
