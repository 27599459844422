import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Fichasresultado, FichasresultadoInner } from '@funcate/sigweb-cti-api';
import { environment } from '../../environments/environment';
import { DialogComponent } from '../map/dialog/dialog.component';
import { LayersConfigService } from '../map/layers/layers.config.service';
import { FichaPreviewComponent } from '../map/tools/property-registration/ficha-preview/ficha-preview.component';
import { ColetaPGV } from '../map/vo/coleta-pgv';
import { Property } from '../map/vo/property';
import { AuthenticationService } from './authentication.service';
import { BackendService } from './backend.service';
import { FeatureService } from './feature.service';
import { GeneralService } from './general.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class ColetaPgvService {

  constructor(private layerService: LayersConfigService, private featureService: FeatureService) { }
  
  public getColetaById(id: string) : Promise<any>
  {
    let promise = new Promise((resolve, reject) => {

      let layer = this.layerService.getColetaPGVLayer()
      let idAttribute = this.layerService.getLayerAppConfig(layer, "id_attribute"); 
  
      if(idAttribute)
      {
  
        let attributesFilterList: Array<Array<Property>>=[];
        let attributeFilterList: Array<Property>=[];
  
        attributeFilterList.push(new Property(idAttribute, id));  
        attributesFilterList.push(attributeFilterList);
  
          this.featureService.getFeatures(layer, "" , attributesFilterList).then(features => 
          {
        
            if(features && features.length>0)
            {
              let feature = features[0];

              if(feature.properties)
              {
                if(feature.properties)
                {
                  let properties : Array<Property> = this.featureService.getWFSPropertiesObjectAsPropertyArray(feature.properties);

                  let coletaPGV = new ColetaPGV();

                  coletaPGV.fromProperties(properties);
                  coletaPGV.setFeatureGeoJSON(feature);

                  resolve(coletaPGV);
                }
              }
            }
            else
            {
              reject(null);
            }
          });
      }

    });

    return promise;  
  }
}
