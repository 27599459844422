import { Select } from 'ol/interaction'


export class SelectInteraction extends Select
{
    id: string;
    step: any;
    constructor(id:string, options)
    {
        super(options);
        this.id=id;
        this.step=options.currentStep;
        this.on('select', this.onFeatureSelected);
    }
    
    public onFeatureSelected(selectedFeature)
    {
        if(selectedFeature.selected.length>0)
        {
            this.step.onFeatureSelected(selectedFeature.selected[0]);
        }        
    }
    
}



