import { UserGroup } from './user-group';
import { LayerConfig } from '../layers/layers.config';

export class LayerTool
 {
    toolId: string;
    toolTitle: string;
    enabled: boolean;
    layer: LayerConfig;

    constructor(id: string, title: string, enabled: boolean, layer: LayerConfig)
    {
        
        this.toolId=id;
        this.toolTitle=title;
        this.enabled=enabled;
        this.layer=layer;
    }

}
