import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BackendService } from '../../../service/backend.service';
import { ToolsService } from '../tools.service';
import { BASE_PATH, Cadastroimobiliario, Fichas, FichasresultadoInner, Fotosfachada, HistoricoprocessoimovelInner, Userinfo } from '@funcate/sigweb-cti-api';
import { FeatureService } from 'src/app/service/feature.service';
import { AppNotification } from '../../vo/notification';
import { NotificationService } from 'src/app/service/notification.service';
import { Property } from '../../vo/property';
import { GeneralService } from 'src/app/service/general.service';
import { FichaService } from 'src/app/service/ficha.service';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/service/toast.service';
import { IAlbum, Lightbox } from 'ngx-lightbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FichaPreviewComponent } from './ficha-preview/ficha-preview.component';
import { DialogComponent } from '../../dialog/dialog.component';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { FichaLinkComponent } from './ficha-link/ficha-link.component';
import { FichaUploadComponent } from './ficha-upload/ficha-upload.component';
import { LayersConfigService } from '../../layers/layers.config.service';
import { Tool } from '../tool';
import { LayerConfig } from '../../layers/layers.config';
import { Historicoprocessoimovel } from '@funcate/sigweb-cti-api/model/historicoprocessoimovel';
import { EditHistoricoComponent } from './edit-historico/edit-historico.component';
import { GenericConfirmationDialogComponent } from '../../dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { Inscricao } from '../../vo/inscricao';
import { FotoFachadaPreviewComponent } from './foto-fachada-preview/foto-fachada-preview.component';
import { FotosfachadaManagerComponent } from '../fotosfachada-manager/fotosfachada-manager.component';
import { FotosFachadaManagerTool } from '../fotosfachada-manager/fotosfachada-manager-tool';
import { CanvasService } from '../../canvas/canvas.service';
import { UserGroup } from '../../vo/user-group';
import { Resumorevisao2021 } from '@funcate/sigweb-cti-api/model/resumorevisao2021';

@Component({
  selector: 'app-property-registration',
  templateUrl: './property-registration.component.html',
  styleUrls: ['./property-registration.component.scss']
})
export class PropertyRegistrationComponent implements OnInit {

  //propertyRegistrationTool:any;

  message:string;
  associationMessage:string;
  errorMessage:string;
  ctrlLoading:boolean;
  ctrlAssociate:boolean;
  featureId:Property;
  cadImob:Cadastroimobiliario;
  resumoRevisao2021: Resumorevisao2021;
  fichas:any;
  historicoProcesso: any;
  albumFotosFachada: Array<IAlbum>=[];
  fotoFachadaURL: string = environment.BACKEND_API_BASE_PATH + "/cadastro/fotofachada/get/";
  fichasDisplayedColumns: string[] = ['id', 'idLogradouro', 'nomeLogradouro', 'numeroOuComplemento', 'nomeArquivoCompleto', 'tipo','acoes'];
  historicoProcessoDisplayedColumns: string[] = [ 'processo', 'observacoes', 'assunto','acoes'];
  newFichaDialog: any;
  newFichaDialogUpload: any;
  editHistoricoDialog: any;
  confirmDialogRef: any;
  fotoFachadaDialog: any;
  fotosFachadasManagerDialog: any;

  // using to display into UI
  inscricao:Inscricao;
  
  noValueText="sem valor";

  parent: any;


  imagesList = [{
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/5.jpg',
    title: 'Hummingbirds are amazing creatures'
}, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg'
}, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/4.jpg',
    title: 'Example with title.'
},{
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/7.jpg',
    title: 'Hummingbirds are amazing creatures'
}, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/1.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/1.jpg'
}, {
    image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/2.jpg',
    thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/2.jpg',
    title: 'Example two with title.'
}];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private notificationService: NotificationService,
    private backendService: BackendService,
    private toastService: ToastService,
    private lightbox: Lightbox,
    private dialog: MatDialog,
    private fichaService: FichaService,
    private toolsService: ToolsService,
    private layersConfigService: LayersConfigService,
    private canvasService: CanvasService,
    private authenticationService: AuthenticationService
    )
  {
    this.init(this.data);
  }

  private init(data: any)
  {
    this.parent=data.parent;
    this.inscricao = data.ic;
    this.featureId = data.featureId;
    this.loadDataFromAPI(this.inscricao);
    this.ctrlLoading=true;
    this.ctrlAssociate=false;
    this.cadImob={};

  }

  public loadDataFromAPI(inscricao:Inscricao){
    if(this.backendService && inscricao!=null)
    {
      this.backendService.getCadImob(inscricao.getInscricaoCompleta())
      .then((cadImob: Cadastroimobiliario)=>{
        this.cadImob=cadImob;
        this.message="";
        this.ctrlLoading=false;
      }).catch((error)=>{
        this.errorMessage="";
        this.toastService.error("Algo não ocorreu como o esperado com o serviço de informações de cadastro imobiliário.","");
      }).finally(()=>{
        this.ctrlLoading=false;
      });
    }
  }

  public onDisassociate() 
  {    
    this.ctrlLoading=true;
    console.log("Call on disassociate from API with codImob:"+this.inscricao.getInscricaoCompleta());
    if(this.backendService)
    {
      this.backendService.deassociateGeo(this.inscricao.getInscricaoCompleta(), this.featureId.value.toString())
      .then(()=>{
        this.message="";
        this.toastService.sucess("Componente geo foi desassociado do cadastro.","");
        this.ctrlLoading=false;
        this.associationMessage="";
        this.parent.reloadPropertyRegistration();
      }).catch((error)=>{
        this.errorMessage="";
        this.toastService.error("Algo não ocorreu como o esperado com o serviço de informações de cadastro imobiliário.","");
        console.log(error);
      }).finally(()=>{
        this.ctrlLoading=false;
      });
    }
  }

  public tabChanged($event)
  {
    //Aba de fichas
    if($event.index==6)
    {
      this.loadFichas()
    }
    //Aba de fotos de fachadas
    else if($event.index==5)
    {
      this.loadFotosFachadas();
    }
    //Aba Historico Processo
    else if($event.index==7)
    {
      this.loadHistoricoProcesso();
    }
    else if($event.index==8)
    {
      this.loadResumoRevisao2021();
    }
  }

  public loadFichas()
  {
    if(this.inscricao)
    {
      this.ctrlLoading=true;
      this.backendService.getFichas(this.inscricao.getInscricaoCompleta())
      .then((fichas: any)=>{
        this.fichas=fichas;
        this.fichas.forEach(ficha => {
          if(ficha.numero_ou_complementos)
          {
            ficha.numero_ou_complementos=ficha.numero_ou_complementos.replace("."+ficha.tipo,"").trim();
          }
        });
        this.message="";
        this.ctrlLoading=false;

      }).catch((error)=>{
        this.errorMessage="";
        this.toastService.error("Não foi possível buscar as fichas do imovel no serviço de informações do cadastro imobiliário.","");
        console.log(error);
      }).finally(()=>{
        this.ctrlLoading=false;
      });
    }
  }
  public loadFotosFachadas()
  {
    if(this.inscricao)
    {
      this.ctrlLoading=true;
      this.backendService.getFotosFachada(this.inscricao.getInscricaoCompleta())
      .then((fotos: Fotosfachada)=>{
        

        this.albumFotosFachada = [];
        fotos.forEach(foto => {
          let album : any = {};
          album.src=this.fotoFachadaURL+foto.id;
          album.caption="";
          this.albumFotosFachada.push(album);
        });

        this.message="";
        this.ctrlLoading=false;

      }).catch((error)=>{
        this.errorMessage="";
        this.toastService.error("Não foi possível buscar as fotos de fachadas no serviço de informações do cadastro imobiliário.","");
        console.log(error);
      }).finally(()=>{
        this.ctrlLoading=false;
      });
    }
  }

  public loadHistoricoProcesso()
  {
    if(this.inscricao)
    {
      this.ctrlLoading=true;
      this.backendService.getHistoricoProcessoImovel(this.inscricao.getInscricaoCompleta())
      .then((historico: Historicoprocessoimovel)=>{
        this.historicoProcesso = historico;

        this.message="";
        this.ctrlLoading=false;

      }).catch((error)=>{
        this.errorMessage="";
        this.toastService.error("Não foi possível buscar o historico do imovel no serviço de informações do cadastro imobiliário.","");
        console.log(error);
      }).finally(()=>{
        this.ctrlLoading=false;
      });
    }
  }

  public openFotoFachada(index)
  {
    this.fotoFachadaDialog = this.dialog.open(DialogComponent, {
      maxWidth: '90%',
      maxHeight: '90%',        
      data: { component: FotoFachadaPreviewComponent, title: "Fotos Fachada para Inscrição: " + this.inscricao.getInscricaoSeparadaPorPonto(), parentDialog: this, albumFotosFachada: this.albumFotosFachada, currentIndex: index }
    });

  }

  public onAssociate() {
    let notification = new AppNotification(AppNotification.ON_GEO_ASSOCIATION_EVENT, this.featureId);
    this.notificationService.send(notification);
    this.parent.closePropertyRegistration();
    console.log("Call property search tool to associate a feature and property registration. FeatureId:"+this.featureId.value);
  }

  public enableDisassociate(){
    // TODO: insert authentication user control
    return ((!this.ctrlAssociate && this.cadImob['imovel'])?(true):(false));
  }

  public enableAssociate(){
    // TODO: insert authentication user control
    return this.ctrlAssociate;
  }

  public getFicha(id:string, extension:string)
  {
   this.fichaService.getFicha(id, extension, this.dialog);
  }
  public getFichaIcon(ficha: any)
  {
    return this.fichaService.getFichaIcon(ficha);
  }

  ngOnInit(): void {
  }

  formatDecimalField(stringValue: string) : string
  {
    if(stringValue)
    {
      return parseFloat(stringValue).toFixed(2);
    }
    else
    {
      return "";
    }
    
  }

  public newFichaLink(idLogradouro: string, inscricao: string)
  {
    this.newFichaDialog = this.dialog.open(DialogComponent, {
      height: '90%',
      width: '95%',
      data: { component: FichaLinkComponent, title: "Associar documento existente ao imóvel", idLogradouro: idLogradouro, inscricao: inscricao, parentDialog: this }
    });

    this.newFichaDialog.afterClosed().toPromise().then(()=>{
      this.loadFichas();
  });
  }

  public closeNewFichaDialog()
  {
    if(this.newFichaDialog)
    {
      this.newFichaDialog.close();
    }
  }

  public desassociarFicha(fichaId: string)
  {
    if(window.confirm("Gostaria realmente de desassociar documento da inscrição?"))
    {
      this.fichaService.desassociarFicha(this.inscricao.getInscricaoCompleta(), fichaId).then(()=>
      {
        this.loadFichas();
        this.toastService.sucess("Documento desassociada com sucesso.","Sucesso")
      }).catch(()=>{
        this.toastService.error("Problema ao desassociar documento.","Erro")
      })
    }
  }

  public closeNewFichaUploadDialog()
  {
    if(this.newFichaDialogUpload)
    {
      this.newFichaDialogUpload.close();
    }
  }

  public newFichaUpload()
  {

    this.newFichaDialogUpload = this.dialog.open(DialogComponent, {
      height: '70%',
      width: '75%',
      data: { component: FichaUploadComponent, title: "Associar novo documento ao imóvel", inscricoes: [this.inscricao.getInscricaoCompleta()], parentDialog: this }
    });

    this.newFichaDialogUpload.afterClosed().toPromise().then(()=>{
      this.loadFichas();
    });
  }

  public addHistorico()
  {

    let item : HistoricoprocessoimovelInner = new Object;

    item.ic = this.inscricao.getInscricaoCompleta();

    this.editHistoricoDialog = this.dialog.open(DialogComponent, {
      height: '60%',
      maxHeight: '60%',
      maxWidth: '80%',
      data: { component: EditHistoricoComponent, title: "Adicionar histórico de processo para o imóvel", historico: item, parent: this  }
    });

    this.editHistoricoDialog.afterClosed().toPromise().then(()=>{
      this.loadHistoricoProcesso();
    });
  }

  public editHistorico(item: any)
  {
    this.editHistoricoDialog = this.dialog.open(DialogComponent, {
      height: '60%',
      maxHeight: '60%',
      maxWidth: '80%',
      data: { component: EditHistoricoComponent, title: "Adicionar histórico de processo para o imóvel", historico: item, parent: this }
    });

    this.editHistoricoDialog.afterClosed().toPromise().then(()=>{
      this.loadHistoricoProcesso();
    });
  }

  private deleteHistorico(item: any)
  {
    this.backendService.deleteHistoricoProcessoImovel(item.id).then(()=>
    {
      this.loadHistoricoProcesso();
      this.toastService.sucess("Sucesso ao remover histórico de processo para o imóvel.","Sucesso");
    }).catch(()=>
    {
      this.toastService.error("Problema ao remover histórico de processo para o imóvel.","Erro ao remover");
    })
  }

  public confirmDeleteHistorico(item: any)
  { 
    this.confirmDialogRef = this.dialog.open(DialogComponent, {
      height: '30%',
      width: '20%',
      data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente apagar o histórico de processo?" }
    });

    this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
      if(confirmResult)
      {
         this.deleteHistorico(item);
      }

    });
  }

  public closeEditHistorico(historicoProcesso: Historicoprocessoimovel)
  {
    if(this.editHistoricoDialog)
    {
      this.editHistoricoDialog.close();
    }
  }

  public showFotosFachadaManager()
  {
    let fotosManagerTool = this.toolsService.getToolByType(FotosFachadaManagerTool);
    return this.toolsService.isToolVisibleForCurrentUser(fotosManagerTool);

  }

  public gerenciarFotosFachada()
  {
    if(this.inscricao)
    {
      let selectedItens:Inscricao[]=[];

      selectedItens.push(this.inscricao);
  
      this.fotosFachadasManagerDialog = this.dialog.open(DialogComponent, {
        height: '60%',
        width: '80%',
        maxHeight: '60%',
        maxWidth: '80%',
        data: { component: FotosfachadaManagerComponent, title: "Gerenciar fotos de fachadas", parent: this.dialog, icList: selectedItens }
      });
      this.fotosFachadasManagerDialog.afterClosed().toPromise().then(()=>{
        this.loadFotosFachadas();
        
        let layerConfig = this.layersConfigService.getCtiLayer();
        if(layerConfig)
        {
            this.canvasService.redrawLayer(layerConfig);
        }            
      });
    }
    
  }

  public hasEditionPermition()
  {
    let user: Userinfo = this.authenticationService.getCurrentUser();

    let layerConfig = this.layersConfigService.getCtiLayer();

    if(user)
    {
      //Verifica se o usuário tem permissão de escrita na camada de imoveis
      if(layerConfig.isWritableForUser(user))
      {
        //Verifica se o usuário tem perfil editor ou admin
        if(user['usergroup_id']==UserGroup.EDITION.id || user['usergroup_id']==UserGroup.ADMINISTRATOR.id || user['usergroup_id']==UserGroup.DOC_EDITION.id)
        {
          return true;
        }
      }
    }
    return false;
  }

  public loadResumoRevisao2021()
  {
    if(this.inscricao)
    {
      this.ctrlLoading=true;
      this.backendService.getResumoRevisao2021(this.inscricao.getInscricaoCompleta())
      .then((resumo: Resumorevisao2021)=>{
        this.resumoRevisao2021=resumo;
        this.message="";
        this.ctrlLoading=false;

      }).catch((error)=>{
        this.errorMessage="";
        this.toastService.error("Não foi possível buscar os documentos do imovel no serviço de informações do cadastro imobiliário.","");
        console.log(error);
      }).finally(()=>{
        this.ctrlLoading=false;
      });
    }
  }

}
