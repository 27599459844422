<mat-accordion *ngIf="layer" class="manage-coleta-pgv-accordion">
    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="layername">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header" class="layers-col">
                    <mat-icon class="material-icons layer-icon">format_list_numbered</mat-icon>Lista de Coleta de Amostras
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            
            <mat-form-field class="filtro">
              <mat-label>Filtro</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Ex. fonte" #input>
            </mat-form-field>

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 coletas-table" matSort *ngIf="dataSource" (matSortChange)="sortData($event)">
                <ng-container matColumnDef="fonte">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="fonte"> Fonte </th>
                    <td mat-cell *matCellDef="let coleta">
                        {{coleta.fonte}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="nome_user">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="nome_user"> Usuário </th>
                    <td mat-cell *matCellDef="let coleta">
                        {{coleta.nomeUser}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="natureza_transacao">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="natureza_transacao"> Natureza Transação </th>
                    <td mat-cell *matCellDef="let coleta">
                      {{ coleta.getNaturezaNameById(coleta.naturezaTransacao) }}
                    </td>
                  </ng-container>
                <ng-container matColumnDef="data_coleta">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="data_coleta"> Data da Coleta </th>
                    <td mat-cell *matCellDef="let coleta" class=""  > 
                        {{formatDate(coleta.dataColeta, 'dd/MM/yyyy')}}

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="data_registro">
                    <th mat-header-cell *matHeaderCellDef  mat-sort-header="data_registro"> Data do Registro </th>
                    <td mat-cell *matCellDef="let coleta" class=""> 
                        {{formatDate(coleta.dataRegistro, 'dd/MM/yyyy HH:mm')}}

                    </td>
                  </ng-container>
         
                  <ng-container matColumnDef="area_terreno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="area_terreno"> Área do Terreno </th>
                    <td mat-cell *matCellDef="let coleta" class=""> 
                        {{coleta.areaTerreno}} m²

                    </td>
                  </ng-container>

                  <ng-container matColumnDef="area_construcao">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="area_construcao"> Área da Construção </th>
                    <td mat-cell *matCellDef="let coleta" class=""> 
                        {{coleta.areaConstrucao}} m²
                    </td>
                  </ng-container>
         
                  <ng-container matColumnDef="valor_terreno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="valor_terreno"> Valor do Terreno </th>
                    <td mat-cell *matCellDef="let coleta" class=""> 
                        R$ {{coleta.valorTerreno}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="valor_construcao">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="valor_construcao"> Valor da Construção </th>
                    <td mat-cell *matCellDef="let coleta" class=""> 
                        R$ {{coleta.valorConstrucao}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="valor_total">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="valor_total"> Valor Total do Imovel </th>
                    <td mat-cell *matCellDef="let coleta" class=""> 
                        R$ {{coleta.valorTotal}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="tipo_da_construcao">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="tipo_da_construcao"> Tipo da Construção </th>
                    <td mat-cell *matCellDef="let coleta" class=""> 
                        {{ coleta.tipoConstrucao }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="endereco">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="endereco"> Endereço </th>
                    <td mat-cell *matCellDef="let coleta" class=""> 
                        {{ coleta.endereco }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="obs">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="obs"> Observações </th>
                    <td mat-cell *matCellDef="let coleta" class="wrap"> 
                        {{coleta.obs}}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="acoes">
                    <th mat-header-cell *matHeaderCellDef> Ações </th>
                    <td mat-cell *matCellDef="let coleta" class=""> 
                        <mat-icon title="Ver no mapa" (click)="fit(coleta)">fit_screen</mat-icon>
                        <mat-icon *ngIf="tool.isPGVUser()" title="Editar coleta" (click)="edit(coleta)">edit</mat-icon>
                        <mat-icon *ngIf="tool.isPGVUser()" title="Remover coleta"  (click)="confirmDelete(coleta)">delete</mat-icon>
                        <mat-icon></mat-icon>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                 
            </table>

            <mat-paginator [length]="100"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
            aria-label="Selecione a página" showFirstLastButtons></mat-paginator>

            <div *ngIf="!coletasPGV" class="sem-coletas">
                Não existem coletas. Use a ferramenta de "Adicionar coleta por apontamento".
            </div>
  

        </mat-expansion-panel>
    </ng-container>
</mat-accordion>