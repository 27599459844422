import { Component, OnInit, HostListener } from '@angular/core';
import { Tool } from './tool';
import { ToolsService } from './tools.service';


@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss']
})
export class ToolsComponent implements OnInit {

 
  constructor(private toolsService: ToolsService) 
  { 
  }

  ngOnInit(): void {
  }

  toogleTool(toogleTool: Tool)
  {
    this.toolsService.toogleTool(toogleTool);
  }

  public getTools()
  {
    return this.toolsService.getTools();
  }

}
