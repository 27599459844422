import * as L from "leaflet";

export class ScaleSelector extends L.Control {
    
    onAdd(map:any){
        this.options={
            position:"bottomleft"
        };

        let container = L.DomUtil.create("div", "leaflet-control leaflet-control-scale-selector leaflet-bar");

        let min=map.getMinZoom();
        let max=map.getMaxZoom();
        let zlvalues = { 0: "10000 km (~1/560 milhões)", 1: "5000 km (~1/280 milhões)", 2: "3000 km (~1/140 milhões)", 3: "1000 km (~1/70 milhões)", 4: "500 km (~1/35 milhões)", 5: "300 km (~1/17 milhões)", 6: "200 km (~1/8.7 milhões)", 7: "100 km (~1/4.4 milhões)", 8: "50 km (~1/2.2 milhões)", 9: "30 km (~1/1 milhão)", 10: "10 km (~1/546.000)", 11: "5 km (~1/273.000)", 12: "3 km (~1/136.500)", 13: "1 km (~1/68.200)", 14: "500 m (~1/34.100)", 15: "300 m (~1/17.000)", 16: "200 m (~1/8.500)", 17: "100 m (~1/4.300)", 18: "50 m (~1/2.150)", 19: "20 m (~1/1.000)", 20: "10 m (~1/500)", 21: "5 m (~1/250)" , 22: "2 m (~1/150)" };
        let zlevel=L.DomUtil.create("select");
        zlevel.setAttribute("id","scale-selector");
        for (let index = min; index <= max; index++) {
            let opt=L.DomUtil.create("option");
            opt.setAttribute("value",index);
            opt.innerText=zlvalues[index];
            if(index==map.getZoom()){
                opt.setAttribute("selected","selected");
            }
            zlevel.appendChild(opt);
        }
        container.appendChild(zlevel);
        container.onchange=(ev)=>{
            map.setZoom(ev.target[ev.target["selectedIndex"]].value);
        };

        map.on("zoomend", ()=>{
            let zlevel=L.DomUtil.get("scale-selector");
            for (let opt in zlevel) {
                if(zlevel[opt] && zlevel[opt].value==map.getZoom()){
                    zlevel[opt].setAttribute("selected","selected");
                }
            }
        });

        return container;
    }
}
