import { FormControl, FormGroup } from "@angular/forms";
import { ToastService } from "src/app/service/toast.service";
import { Step } from "../../../../vo/step";
import { ImportLayerComponent } from "../import-layer.component";
import { StringUtils } from "../../../../../util/string-utils";
import { BackendService } from "src/app/service/backend.service";
import { Importlayerfileinfo } from "@funcate/sigweb-cti-api";
import { environment } from 'src/environments/environment';

export class ImportLayerUploadSLDFileStep extends Step
 {
  
    fileUploadControl: FormGroup;
    public currentSLDFilename: string;  
    uploadingFile: boolean;  
    
    constructor(index: number, private importLayerComponent: ImportLayerComponent, private toastService: ToastService, private backendService: BackendService)
    {       
        super(index);        
        this.setTitle("Passo 2: Selecione o arquivo de estilo");
        this.setDescription("Selecione o arquivo de estilo para ser aplicado à camada importada. O arquivo suportado é no formato SLD.");
        this.currentSLDFilename="";
        
        this.uploadingFile= false;
        this.fileUploadControl = new FormGroup(
          {
            file: new FormControl()
          }
        );
    }
    execute()
    {
      if(StringUtils.isNullOrEmpty(this.importLayerComponent.importlayerfileinfo.sldfilepath)==false)
      {
        this.currentSLDFilename = this.importLayerComponent.importlayerfileinfo.sldfilepath.replace(/^.*[\\\/]/, '');
        
        this.loadLegendPreview(this.importLayerComponent.importlayerfileinfo.sldpreviewname);
      }      
      else
      {
        this.importLayerComponent.legendPreviewURL = null;
      }
    }
    callback(object: any)
    {

    }
    rollback()
    {
    }
    finish()
    {
        
    }
    valid()
    {
      return true;
    }
    onFileSelect(event) {
      if (event.target.files.length > 0) 
      {        
        this.uploadingFile = true;
        this.currentSLDFilename = event.target.files[0].name;
        const file = event.target.files[0];
        this.fileUploadControl.get('file').setValue(file);
        this.uploadFile(file);
      }
      else
      {
        this.uploadingFile= false;
        this.toastService.info("Selecione um arquivo SLD válido.", "Atenção");
      }
    }
    private uploadFile(file: any)
    {      
      if(file)
      {
        let layerFile: Blob = new Blob([file], { type: 'application/octet-stream' });        
        
        

        this.backendService.uploadSldFile(this.importLayerComponent.importlayerfileinfo.layername, file.name, layerFile).then((returnImportLayerInfo: Importlayerfileinfo)=>
        {
          if(returnImportLayerInfo.error==false)
          {
            this.importLayerComponent.importlayerfileinfo.sldfields=returnImportLayerInfo.sldfields;
            this.importLayerComponent.importlayerfileinfo.sldfilepath=returnImportLayerInfo.sldfilepath;
            this.importLayerComponent.importlayerfileinfo.sldpreviewname=returnImportLayerInfo.sldpreviewname;
            if(this.importLayerComponent.importlayerfileinfo.sldpreviewname)
            {
              this.loadLegendPreview(this.importLayerComponent.importlayerfileinfo.sldpreviewname)
            }
            else
            {
              this.importLayerComponent.legendPreviewURL = null;
            }
            
            this.uploadingFile= false;
            this.toastService.sucess("Estilo carregado.","Sucesso");
          }
          else
          {
            this.uploadingFile= false;
            this.toastService.error("Não foi possível carregar o arquivo para o servidor. " + returnImportLayerInfo.msg, "Erro");
          }                   
          
        }).catch((e)=>
        {
          this.uploadingFile= false;
          console.log(e);
          this.toastService.error("Problema ao subir arquivo para o servidor.","Erro");
        })
      }
      else
      {
        this.uploadingFile= false;
        this.toastService.error("Favor selecionar primeiramente um arquivo.","Erro");
      }
    }
    private loadLegendPreview(legendPreviewStyleName)
    {
      let geoserverURL = environment.GEOSERVER_URL_DOMAIN+environment.GEOSERVER_URL_PATH; 

      let anticfloat = (Math.floor( Math.random() * 2000000 ) + 1)/21;
      this.importLayerComponent.legendPreviewURL = geoserverURL+"?SERVICE=WMS&REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=legend-preview&style="+legendPreviewStyleName+"&anticfloat="+anticfloat;      
    }
    public isValidFile()
    {
      if(this.fileUploadControl.get('file'))
      {
        return true;
      }
      return false;
    }
}

