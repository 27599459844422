import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LatLng, Layer, Util } from 'leaflet';
import { environment } from 'src/environments/environment';
import { CanvasService } from '../map/canvas/canvas.service';
import { LayerConfig } from '../map/layers/layers.config';
import { LayersConfigService } from '../map/layers/layers.config.service';
import { Feature } from '../map/vo/feature';
import { FeatureInfo } from '../map/vo/featureinfo';
import { Property } from '../map/vo/property';
import * as L from 'leaflet';

@Injectable({
    providedIn: 'root'
  })

export class FeatureService {
   
    /** start http service client */
    constructor(public http: HttpClient,
        private layersService: LayersConfigService) { }


    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/xml'
        })
    };
        
    private getFeatureInfoUrl(latlng:LatLng, layer: LayerConfig, leafletLayer: L.Layer, map:L.Map)
    {
        // Construct a GetFeatureInfo request URL given a point
        let point = map.latLngToContainerPoint(latlng),
            size = map.getSize(),
            // this crs is used to show layer added to map
            crs = map.options.crs,

            // these are the SouthWest and NorthEast points 
            // projected from LatLng into used crs
            sw = crs.project(map.getBounds().getSouthWest()),
            ne = crs.project(map.getBounds().getNorthEast()),
            
            params = {
                request: 'GetFeatureInfo',
                service: 'WMS',
                srs: crs.code,
                version: "1.1.1",
                // these are bbox defined by SouthWest and NorthEast coords
                bbox: sw.x + ',' + sw.y + ',' + ne.x + ',' + ne.y,
                height: size.y,
                width: size.x,
                layers: layer.getId(),
                query_layers: layer.getId(),
                info_format: 'application/json',
                feature_count: 20
            };
        
        params[params.version === '1.3.0' ? 'i' : 'x'] = parseInt( String(point.x) );
        params[params.version === '1.3.0' ? 'j' : 'y'] = parseInt( String(point.y) );
        
        let anticfloat = (Math.floor( Math.random() * 2000000 ) + 1)/21;
        

        let url = this.layersService.getLayerURL(layer);
        url+="?anticfloat="+anticfloat;
        url+= Util.getParamString(params, leafletLayer["_url"], false);

        return  url
    };

    private getFeatureURL(layer:LayerConfig, srs: string)
    {
       
       let layerId = this.layersService.getLayerId(layer);
            
       let params = {
                request: 'GetFeature',
                service: 'WFS',
                srsName: srs,
                version: '2.0.0',
                typeNames: layerId,
                outputFormat: 'application/json',
            };
     
        let geoserverURL = environment.GEOSERVER_URL_DOMAIN+environment.GEOSERVER_URL_PATH; 
        
             
        return geoserverURL + Util.getParamString(params, geoserverURL, false);
    };

    public async getFeatureInfo(ll: any, leafletLayer: L.Layer, map: L.Map) : Promise<any>
    {


        let promise = new Promise((resolve, reject) => {
            let featureInfo=new FeatureInfo();

            let layer = this.layersService.getOverLayerById(leafletLayer['id']);

            if(layer)
            {

                let srs="";
          
                let url=decodeURIComponent(this.getFeatureInfoUrl(ll, layer, leafletLayer, map));
                url=encodeURIComponent(url);

                let url_proxy=environment.PROXY_URL+"?url="+url;
                this.http.get(url_proxy, this.httpOptions).subscribe(
                    (data:any)=>{
                        if(data && data.features.length>0) {
                            data.features.forEach( (el:any) => {
                                featureInfo.layerName=layer.getId();
                                let layerObj = this.layersService.getOverLayerById(featureInfo.layerName);
                                if(layerObj)
                                {
                                    featureInfo.layerConfig=layerObj;
                                }
                                let f=new Feature();
                                f.geom=el.geometry;
                                f.id=el.id;
                                f.srs=srs;
                                for (let prop in el.properties){
                                    if( el.properties.hasOwnProperty(prop) ){
                                        let p=new Property(null, null);
                                        p.key=prop;
                                        p.value=el.properties[prop];
                                        f.properties.push(p);
                                    }
                                }
                                featureInfo.features.push(f);
                            });
                        }
                    },(error)=>{
                        console.log("GetFeatureInfo error:"+JSON.stringify(error));
    
                        let serviceException=( error.error.text && error.error.text.includes("ServiceExceptionReport") )?(error.error.text):("");
    
                        let message="Falhou ao consultar a camada:"+leafletLayer["wmsParams"]["layers"]+"<br>"+serviceException;
                        
                        reject(message);
    
                    }, () => {
                        resolve(featureInfo);
                    }
                );
            }
            else
            {
                let message = "Não foi possível encontrar as configurações da camada. "
                console.log(message);
                console.log(leafletLayer);
            }
            
            
        });
        return promise;

    }
    public async getFeaturesByAttribute(attribute:string, value:string, layer:LayerConfig,  srs: string, orderByAttribute: string = null) : Promise<any>
    {
        let attributesFilterList: Array<Array<Property>>=[];
        let attributeFilterList: Array<Property>=[];

        attributeFilterList.push(new Property(attribute, value));
        attributesFilterList.push(attributeFilterList);

        return this.getFeatures(layer, srs, attributesFilterList, orderByAttribute);
    }

    public async getFeatures(layer:LayerConfig, srs: string, attributesFilterList: Array<Array<Property>>, orderByAttribute: string = null) : Promise<any>
    {
        let promise = new Promise((resolve, reject) => {

            let layerId = this.layersService.getLayerId(layer); 
            let features: any;
            let url=decodeURIComponent(this.getFeatureURL(layer, srs));
            url=encodeURIComponent(url);

            if(!srs)
            {
                srs="EPSG:4326"
            }

            let filter = this.getWFSAttributeFilter(layer, attributesFilterList, srs, orderByAttribute);
            
            let url_proxy=environment.PROXY_URL+"?url="+url;

            this.http.post(url_proxy, filter, this.httpOptions).subscribe(
                (data:any)=>{
                    if(data && data.features.length>0) {
                        features = data.features;
                    }
                },(error)=>{
                    console.log("GetFeature error:"+JSON.stringify(error));

                    let serviceException=( error.error.text && error.error.text.includes("ServiceExceptionReport") )?(error.error.text):("");

                    let message="Falhou ao buscar objeto geográfico da camada:"+layerId+"<br>"+serviceException;
                    
                    reject(message);

                }, () => {
                    resolve(features);
                }
            );
        });
        return promise;

    }

    public async getFeaturesByBox(layer:LayerConfig, srs: string, bounds: L.LatLngBounds) : Promise<any>
    {
        let promise = new Promise((resolve, reject) => {

            let layerId = this.layersService.getLayerId(layer);
            let features: any;
            let url=decodeURIComponent(this.getFeatureURL(layer, srs));
            url=encodeURIComponent(url);

            let filter = this.getWFSBoxFilter(layer, bounds);
           
            let url_proxy=environment.PROXY_URL+"?url="+url;

            this.http.post(url_proxy, filter, this.httpOptions).subscribe(
                (data:any)=>{
                    if(data && data.features.length>0) {
                        features = data.features;
                    }
                },(error)=>{
                    console.log("GetFeature error:"+JSON.stringify(error));

                    let serviceException=( error.error.text && error.error.text.includes("ServiceExceptionReport") )?(error.error.text):("");

                    let message="Falhou ao buscar objetos geográficos da camada:"+layerId+"<br>"+serviceException;
                    
                    reject(message);

                }, () => {
                    resolve(features);
                }
            );
        });
        return promise;

    }

    public getFeaturesBounds(features)
    {
        let boundsLayer = L.geoJSON(features);
        let bounds = boundsLayer.getBounds();  
        return bounds;
    }

    public async getPolygonNeighborhood(layer:LayerConfig, srs: string, geometry: any) : Promise<any>
    {
        let promise = new Promise((resolve, reject) => {

            let layerId = this.layersService.getLayerId(layer);
            let features: any;
            let url=decodeURIComponent(this.getFeatureURL(layer, srs));
            url=encodeURIComponent(url);

            if(!srs)
            {
                srs="EPSG:4326"
            }

            let filter = this.getWFSPolygonDWithinFilter(layer, geometry, srs);
            
            let url_proxy=environment.PROXY_URL+"?url="+url;

            this.http.post(url_proxy, filter, this.httpOptions).subscribe(
                (data:any)=>{
                    if(data && data.features.length>0) {
                        features = data.features;
                    }
                },(error)=>{
                    console.log("GetFeature error:"+JSON.stringify(error));

                    let serviceException=( error.error.text && error.error.text.includes("ServiceExceptionReport") )?(error.error.text):("");

                    let message="Falhou ao buscar objeto geográfico da camada:"+layerId+"<br>"+serviceException;
                    
                    reject(message);

                }, () => {
                    resolve(features);
                }
            );
        });
        return promise;

    }
    public getWMSAttributeCQLFilter(attributeFilter: Property)
    {
        let cqlFilter : string ="";

        if(attributeFilter)
        {
            cqlFilter+=attributeFilter.key;
            cqlFilter+="=";
            cqlFilter+=attributeFilter.value;
        }

        return cqlFilter;
        
    }

    private getGeometryGMLPosList(geometry: any)
    {
      let gmlCoordinates = "";
      if(geometry.coordinates.length>0 && geometry.coordinates[0].length>0)
      {
        geometry.coordinates[0][0].forEach(coordinates => {
          gmlCoordinates+=coordinates[0] + " ";
          gmlCoordinates+=coordinates[1] + " ";
        }); 
      }
      return gmlCoordinates;
      
    }

    public getWFSPolygonDWithinFilter(layer:LayerConfig, geometry: any, srs: string)
    {
        let gmlCoordinates = this.getGeometryGMLPosList(geometry);

        let layerId = this.layersService.getLayerId(layer);

        let filter = '<wfs:GetFeature xmlns:wfs="http://www.opengis.net/wfs" xmlns:ogc="http://www.opengis.net/ogc" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" service="WFS" version="1.1.0" outputFormat="json" xsi:schemaLocation="http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.0.0/WFS-basic.xsd"  xmlns:gml="http://www.opengis.net/gml"> ';
        filter +='<wfs:Query typeName="'+layerId+'"  srsName="'+srs+'">';
        filter +='<ogc:Filter>';
        filter +='<ogc:DWithin>';
        filter +='<ogc:PropertyName>geom</ogc:PropertyName>';
        filter +='<gml:Polygon>';
        filter +='<gml:exterior>';
        filter +='<gml:LinearRing>';
        filter +='<gml:posList>'+gmlCoordinates+'</gml:posList>';
        filter +='</gml:LinearRing>';
        filter +='</gml:exterior>';
        filter +='</gml:Polygon>';
        filter +='<ogc:Distance units="m">0.5</ogc:Distance>';
        filter +='</ogc:DWithin>';       
        filter += '</ogc:Filter>';
        filter += '</wfs:Query>';
        filter += '</wfs:GetFeature>';

        return filter;
    }
    public getWFSAttributeFilter(layer:LayerConfig, attributesFilterList: Array<Array<Property>>, srs: string, sortByAttribute: string =null)
    {

        let layerId = this.layersService.getLayerId(layer);

        let filter = '<wfs:GetFeature service="WFS" version="1.1.0" outputFormat="json" xmlns:wfs="http://www.opengis.net/wfs" '
        filter +='xmlns:ogc="http://www.opengis.net/ogc" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" '
        filter +='xsi:schemaLocation="http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.0.0/WFS-basic.xsd">'
        filter +='<wfs:Query typeName="'+layerId+'"  srsName="'+srs+'">';


        if(sortByAttribute)
        {
            filter +='<ogc:SortBy>';
            filter +='<ogc:SortProperty>';
            filter +='<ogc:PropertyName>'+sortByAttribute+'</ogc:PropertyName>';
            filter +='<ogc:SortOrder>ASC</ogc:SortOrder>';
            filter +='</ogc:SortProperty>';
            filter +='</ogc:SortBy>';
        }        


        filter +='<ogc:Filter>';

        if (attributesFilterList.length>1){
            filter +='<ogc:Or>';
        }

        attributesFilterList.forEach((attributeFilterList)=>{
            filter += this.makeWFSAndCluseFilter(attributeFilterList);
        });

        if (attributesFilterList.length>1){
            filter +='</ogc:Or>';
        }
     
       
        filter += '</ogc:Filter>';
        filter += '</wfs:Query>';
        filter += '</wfs:GetFeature>';

        return filter;
    }

    public getWFSBoxFilter(layer:LayerConfig, bounds: L.LatLngBounds)
    {

        let layerId = this.layersService.getLayerId(layer);

        let filter = '<wfs:GetFeature service="WFS" version="1.0.0" outputFormat="json" xmlns:wfs="http://www.opengis.net/wfs" '
        filter +='xmlns:ogc="http://www.opengis.net/ogc" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:gml="http://www.opengis.net/gml" '
        filter +='xsi:schemaLocation="http://www.opengis.net/wfs http://schemas.opengis.net/wfs/1.0.0/WFS-basic.xsd">'
        filter +='<wfs:Query typeName="'+layerId+'"  srsName="EPSG:4326">';
        filter +='<ogc:Filter>';

        filter +='<ogc:Intersects>';
        filter +='<ogc:PropertyName>geom</ogc:PropertyName>';
        filter +='<gml:Box srsName="EPSG:4326">';
        filter +='<gml:coord>';
        filter +='<gml:X>'+bounds.getWest()+'</gml:X>';
        filter +='<gml:Y>'+bounds.getSouth()+'</gml:Y>';
        filter +='</gml:coord>';
        filter +='<gml:coord>';
        filter +='<gml:X>'+bounds.getEast()+'</gml:X>';
        filter +='<gml:Y>'+bounds.getNorth()+'</gml:Y>';
        filter +='</gml:coord>';
        filter +='</gml:Box>';
        filter +='</ogc:Intersects>';
        //southwest_lng,southwest_lat,northeast_lng,northeast_lat'
       
        filter += '</ogc:Filter>';
        filter += '</wfs:Query>';
        filter += '</wfs:GetFeature>';

        return filter;
    }

    private makeWFSAndCluseFilter(attributeFilterList: Array<Property>){
        let filter='';

        if(attributeFilterList.length>1)
        {
            filter +='<ogc:And>';
        }

        attributeFilterList.forEach(attribute => {
            filter +='<ogc:PropertyIsEqualTo>';
            filter +=    '<ogc:PropertyName>'+attribute.key+'</ogc:PropertyName>';
            filter +=    '<ogc:Literal>'+attribute.value+'</ogc:Literal>';
            filter += '</ogc:PropertyIsEqualTo>';
        });
            
        if(attributeFilterList.length>1)
        {
            filter +='</ogc:And>';
        }

        return filter;
    }

    public getPropertyByKey(properties: Array<Property>, key: string) : Property
    {
        let returnProperty: Property=null;
        properties.forEach(property => 
            {
              if(property.key==key)
              {
                returnProperty = property
              }
          });

        return returnProperty;

    }

    public getWFSPropertiesObjectAsPropertyArray(wfsFeatureProperty: any) : Array<Property>
    {
        const objectKeys = Object.keys(wfsFeatureProperty) as Array<any>;

        let properties = new Array<Property>();

        objectKeys.forEach(key => {
            properties.push(new Property(key, wfsFeatureProperty[key]));                        
        });

        return properties;
    }

    public getDefaultWFSEPSG(): string
    {
     return  "EPSG%3A4326"
    }
    public getDefaultWFSSRID(): string
    {
     return  "4326"
    }

    public convertGJSONPropertiesToPropertyList(properties) : Array<Property>
    {
        let outProperties : Array<Property> = Object.entries(properties).map(( [k, v] ) => (
            new Property(k,v==null?"":v.toString()
        )));
        return outProperties;
    }

    public getFeatureProperties(featureattributes: any, layer: LayerConfig) : Array<Property>
    {  
      let geomAttribute=this.layersService.getLayerGeomAttribute(layer);
  
      let properties :Array<Property>=[];
      featureattributes.forEach(featureattribute => {
        if(featureattribute.attribute_name!=geomAttribute)
        {
          //Auxiliar table attribute
          if(Array.isArray(featureattribute.attribute_value))
          {
            let auxiliarTableRows: any = []
            //Iterate over auxliar table rows
            featureattribute.attribute_value.forEach(subElement => {
                //Reading properties from row
                let rowProperties : any = this.getFeatureProperties(subElement, layer);
            
                auxiliarTableRows.push(rowProperties);
                
            });

            let property = new Property(featureattribute.attribute_name, auxiliarTableRows);
            properties.push(property);    
            
          }
          else
          {
            let property = new Property(featureattribute.attribute_name, featureattribute.attribute_value);
            properties.push(property);
          }          
        }      
      });
      
      return properties;
    }

    
} 