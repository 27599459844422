import { Tool } from '../tool';
import { Subscription } from 'rxjs';
import { EditionOperation } from '../../vo/edition-operation';
import { NotificationService } from '../../../service/notification.service';
import { EditionService } from '../../../service/edition.service';
import { CanvasService } from '../../canvas/canvas.service';
import { AppNotification } from '../../vo/notification';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { LayerConfig } from '../../layers/layers.config';
import { AbstractEditionTool } from '../edit-layer-tool/abstract-edition-tool';
import { Operation } from '../../vo/operation';
import { ToolsService } from '../tools.service';

export class SaveEditionLayerTool extends AbstractEditionTool {

    cursorType=null;
    id='save-edition-layer-tool';
    enabled=false;
    name='Save Edition Layer Tool';
    title='Save Edition';
    type='click';
    map:L.Map=null;
    icon='save';
    ll: any;
    protected visible:boolean=true;
    layerTool: boolean=true;
    customParameter=new Map<String, any>();
    notificationSubscrition: Subscription;
    operations: Map<String,EditionOperation> = new Map<String,EditionOperation>();
    currentEditionLayer: LayerConfig;

    constructor(toolsService: ToolsService, private notificationService: NotificationService, private canvasService: CanvasService, private editionService: EditionService) 
    { 
        super(toolsService)
        this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
            if (notification.event==AppNotification.ON_EDITION_OPERATIONS_CHANGE_EVENT) 
            {
                this.operations=notification.object;
                if(this.operations.size==0)
                {
                    this.visible = false;
                }
                else
                {
                    this.visible = true;
                }
            }
          });
    }

    public execAction=(event:L.LeafletMouseEvent)=>
    {
    };

    public enableImpl()
    {
        console.log(this.operations);

        let operationCount=0;

        this.operations.forEach((value: EditionOperation, key: string) =>
          {
            if(value.operation==Operation.Insert)
            {
              this.editionService.insertGeom(value, this.canvasService.getCurrentEditionLayer()).then(()=>{
                operationCount++;
                this.updateMapAfterOperation();
                
                if(operationCount==this.operations.size)
                {
                  this.clearEditionContext();
                }
              }
              )
            }
            else if(value.operation==Operation.Delete)
            {
              // if(value.feature.id>=0)
              // {
                this.editionService.deleteGeom(value, this.canvasService.getCurrentEditionLayer()).then(()=>{
                  operationCount++;
                  this.updateMapAfterOperation();
                  
                  if(operationCount==this.operations.size)
                  {
                    this.clearEditionContext();
                  }
                });
              // }
            }
            else if(value.operation==Operation.Update)
            {
              // if(value.feature.id>=0)
              // {
                this.editionService.updateGeom(value, this.canvasService.getCurrentEditionLayer()).then(()=>{
                  operationCount++;
                  this.updateMapAfterOperation();
                  
                  if(operationCount==this.operations.size)
                  {
                    this.clearEditionContext();
                  }
                });
              // }
            }
            

        });


    }

    private updateMapAfterOperation()
    {
      this.canvasService.redrawLayer(this.canvasService.getCurrentEditionLayer());
    }

    public disableImpl()
    {
    }

    public isVisibleForLayer(layer :LayerConfig) : boolean
    {
        if(this.canvasService.getCurrentEditionLayer()==layer)
        {
            return true;
        }
        else
        {
            return false;
        }
    }
    public removeToolControl()
    {
      let c = this.canvasService.getMap().pm["Toolbar"].getButtons()[this.id];
    }
  
    public addToolControl() {
      
      let button = this.canvasService.getMap().pm["Toolbar"].getButtons()[this.id];
      
      if(!button)
      {
        const actions = [
          {
            text: 'Salvar Edições', id:'save-edition-action' ,onClick: () => {
              let notification = new AppNotification(AppNotification.ENABLE_LAYER_EDITION_TOOL_CONTROL_EVENT, this);
              this.notificationService.send(notification);
            }
          }];
        let options = {
          name: this.id,
          block: 'save',
          title: 'Salvar geometria editada',
          className: 'save-edition-leaflet-control',
          actions: actions
        };
        this.canvasService.getMap().pm["Toolbar"].createCustomControl(
          options
        );
      }
    }
    public toolbarButtonClicked(event: any) 
    {
      if(event.btnName && event.btnName==this.id)
      {
        if(this.enabled==false)
        {
          this.enable();
        }        
      }
      else
      {
        if(this.enabled)
        {
          this.disable();
        }
      }
    }
    public toolbarActionClicked(event: any) 
    {
      if(event.btnName && event.btnName==this.id)
      {
        if(event.action && event.action.id=="save-edition-action")
        {
          this.enable();  
        }
        else
        {
          if(this.enabled)
          {
            this.disable();
          }
        }
        
      }
      else
      {
        if(this.enabled)
        {
          this.disable();
        }
        
      }
    }
    public isEnabled(layer :LayerConfig) : boolean
    {
        return this.enabled;
    }

    private clearEditionContext()
    {
      let notification = new AppNotification(AppNotification.CLEAR_EDITION_CONTEXT);
      this.notificationService.send(notification);

      this.operations.clear();
    }
}
