import { Feature } from "ol";
import { Imovel } from "../vo/imovel";

export class NewImovelLink {
    private index?: number;
    private imovel?: Imovel;
    private feature?: Feature<any>;

    constructor(index: number, imovel: Imovel, feature: Feature<any>)
    {
        this.index = index;
        this.imovel = imovel;
        this.feature = feature
    }

    public getImovel(): Imovel {
        return this.imovel;
    }

    public setImovel(imovel: Imovel): void {
        this.imovel = imovel;
    }

    public getFeature(): Feature<any> {
        return this.feature;
    }

    public setFeature(feature: Feature<any>): void {
        this.feature = feature;
    }

    public getIndex(): number {
        return this.index;
    }

    public setIndex(index: number): void {
        this.index = index;
    }
    
}
