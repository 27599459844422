import { MatDialog } from '@angular/material/dialog';
import { Relatorioareairregularconfig, Userinfo } from '@funcate/sigweb-cti-api';
import { DialogComponent } from 'src/app/map/dialog/dialog.component';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';
import { LayersInfo } from 'src/app/map/vo/layersinfo';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { BackendService } from 'src/app/service/backend.service';
import { CertidoesService } from 'src/app/service/certidoes.service';
import { FeatureService } from 'src/app/service/feature.service';
import { GeneralService } from 'src/app/service/general.service';
import { ToastService } from 'src/app/service/toast.service';
import { environment } from 'src/environments/environment';
import { Feature } from '../../../vo/feature';
import { FeatureInfoTool } from '../../fetureinfo-tool/featureinfo-tool';
import { FetureInfoToolComponent } from '../../fetureinfo-tool/fetureinfo-tool.component';
import { Tool } from '../../tool';
import { GenericFeatureReport } from './generic-feature-report';
import { AbstractDocumentType } from '../../../vo/document/abstract-document-type';
import { AppNotification } from 'src/app/map/vo/notification';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/service/notification.service';
import { LayerFeatures } from 'src/app/map/vo/layer-features';

export class SettlementPropertyFeatureReport extends GenericFeatureReport
{
    customFeatureReportId: string;
    downloading: boolean;
    mapLayerAppConfig: string = "settlement-property-feature-report-map";
    
    mapWidth: number=1200
    mapHeight: number=600
    notificationSubscrition: Subscription;
    parent: any;
    readonly toolSettlementEdificationLayerNameAttributeConfig: string = "assentamentos_precarios_edificacoes_layername";
    readonly toolSettlementEdificationImovelIdAttributeAttributeConfig: string = "imovel_id_attribute";

    constructor(toastService: ToastService,
         authenticationService: AuthenticationService,
          generalService: GeneralService,          
         layerConfigService: LayersConfigService,
          certidoesService: CertidoesService,
           featureService: FeatureService,
           notificationService: NotificationService)
    {
        super(toastService, authenticationService, generalService, layerConfigService, certidoesService, featureService, notificationService);
        this.customFeatureReportId = "settlement-property-feature-report";        
        
        this.addDownloadFinishedListener();
    }
    addDownloadFinishedListener()
    {
      this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
        if (notification.event==AppNotification.ON_DOWNLOAD_FINISHED_EVENT)
        {
            console.log("Download from '"+notification.object.url+"' is done to out file '"+notification.object.outputFilename+"'.");
            this.downloading = false;
            this.toastService.sucess("Sucesso baixando o arquivo solicitiado.", "Sucesso");
            this.parent.hideGeneration();
            
        } else if (notification.event==AppNotification.ON_DOWNLOAD_ERROR_EVENT)
        {
            console.log("Failed downloading from '"+notification.object.url+"' is done to out file '"+notification.object.outputFilename+"'.");
            this.downloading = false;
            this.toastService.error("Falha ao realizar o download do relatório solicitado.", "Error");
            this.parent.hideGeneration();            
        } 
    });
    }
    generateReport(feature: Feature, layerConfig: LayerConfig, dialog: any)
    {
        this.parent=dialog;

        let user = this.authenticationService.getCurrentUser();
        if(!user)
        {
          this.toastService.error("Usuário não está autenticado corretamente. Efetue o login novamente.", "Problemas ao gerar certidão.");
          this.parent.hideGeneration();
        }
        else
        {
          this.createRelatorioImovelIrregular(feature, layerConfig, user);
        }

        console.log("Generating settlement report.")
    }
    private createRelatorioImovelIrregular(feature: Feature, imoveisLayer: LayerConfig, currentUser: Userinfo)
    {
      let mapLayers = this.layerConfigService.getLayersWithAppConfig(this.mapLayerAppConfig);
      
      let layerTableName = this.layerConfigService.getLayerTable(imoveisLayer);

      let properties = this.featureService.getWFSPropertiesObjectAsPropertyArray(feature.properties);


      let idAttribute=this.layerConfigService.getLayerIdAttribute(imoveisLayer);
      let icAttribute=this.layerConfigService.getLayerInscricaoAttribute(imoveisLayer);
      let geomAttribute=this.layerConfigService.getLayerGeomAttribute(imoveisLayer);
      let idValueProperty = this.featureService.getPropertyByKey(properties, idAttribute);
      let icValueProperty = this.featureService.getPropertyByKey(properties, icAttribute);

      let irregularEdificationLayerName = this.layerConfigService.getLayerAppConfig(imoveisLayer, this.toolSettlementEdificationLayerNameAttributeConfig); 

      if(!irregularEdificationLayerName)
      {
        this.toastService.error("Faltando configuração do nome da camada de edificações irregulares na configuração da camada de imoveis irregulares.", "Error");
        console.error("Faltando configuração do nome da camada de edificações irregulares na configuração da camada de imoveis irregulares.")
        this.parent.hideGeneration();
        return;
      }

      let edificacoesLayer = this.layerConfigService.getOverLayerById(irregularEdificationLayerName);      
        
      if(!edificacoesLayer)
      {      
        this.toastService.error("Camada de edificações não encontrada.", "Error");
        console.error("Camada de edificações não encontrada.")
        this.parent.hideGeneration();
        return;
      }            

      let imovelIdAttribute = this.layerConfigService.getLayerAppConfig(edificacoesLayer, this.toolSettlementEdificationImovelIdAttributeAttributeConfig);

      if(!icAttribute)
      {
        this.toastService.error("Não existe configuração do atributo de inscrição para a camada de imoveis irregulares.", "Error");
        console.log("Missing layer config: inscricao_attribute");
        this.parent.hideGeneration();
        return;
      }

      this.featureService.getFeaturesByAttribute(imovelIdAttribute, idValueProperty.value.toString(), edificacoesLayer, "" ).then((edificationFeatures: any[])=>
      {
        let layersFeatures : LayerFeatures[] = [];
        if(mapLayers.length>0)
        { 
          mapLayers.forEach(mapLayer => 
          {
            if(this.layerConfigService.isVectorLayer(mapLayer))
            {
              if(mapLayer.getId()==imoveisLayer.getId())
              {
                let layerFeatures = new LayerFeatures(mapLayer, [feature], true);
                layersFeatures.push(layerFeatures);
              } else if(mapLayer.getId()==edificacoesLayer.getId())
              {
                let layerFeatures = new LayerFeatures(mapLayer, edificationFeatures, true);
                layersFeatures.push(layerFeatures);
              }              
            }
            else
            {
              let layerFeatures = new LayerFeatures(mapLayer, [], false);
              layersFeatures.push(layerFeatures);
            }
          });
          
          let mapURL = this.certidoesService.getMapURLForLayerFeatures(layersFeatures, this.mapWidth, this.mapHeight);
          //let mapURL = this.certidoesService.getMapURL(feature, mapLayers, this.mapWidth, this.mapHeight);
    
          let config: Relatorioareairregularconfig = new Object();
          config['inscricao']=icValueProperty.value;
          config['table']=layerTableName;
          config['id_attribute']=idAttribute;
          config['id_value']=idValueProperty.value;
          config['url_mapa']=mapURL;
  
          config['tipo_documentacao_titular']=AbstractDocumentType.SETTLEMENT_PROPERTY_CAD_SOCIAL_TITULAR_DOCUMENTS;
          config['tipo_documentacao_sehab']=AbstractDocumentType.SETTLEMENT_PROPERTY_CAD_SOCIAL_SEHAB_DOCUMENTS;
          config['tipo_documentacao_imovel']=AbstractDocumentType.SETTLEMENT_PROPERTY_DOCUMENTS;
          config['geom_attribute']=geomAttribute;        
  
            if(config==null)
            {
              this.toastService.error("Não existe camadas configuradas pro relatório para exibição de mapa base e ortofo.", "Error");
              this.parent.hideGeneration();
              return;
            }
  
            let fileDate = this.generalService.formatDate(new Date(), "dd-MM-yyyy_HH-mm");
  
            let filename = "relatorio-area-irregular-"+config['id_value']+"-"+fileDate+".zip";
            let url = environment.BACKEND_API_BASE_PATH+'/relatorios/imovel/irregular';
            this.downloading = true;
            this.generalService.downloadFilePostMethod(url, config, filename, currentUser.token);
        }
      }).catch(()=>{
        this.toastService.error("Não foi possível buscar as feições de edificações para o imóvel selecionado.", "Error");
        console.error("Não foi possível buscar as feições de edificações.");
      });     
  
    
    }

    
}
