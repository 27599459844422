<mat-accordion *ngIf="tool.currentLayer" class="manage-coleta-pgv-accordion">
    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="layername" id="attribute-search-tool-mat-expansion-panel">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header" class="layers-col">
                    <mat-icon class="material-icons layer-icon">format_list_numbered</mat-icon>Lista de atributos
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            
            <mat-form-field class="filtro">
              <mat-label>Filtro</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Ex. fonte" #input>
            </mat-form-field>

            <div class="selection-icons">
                <button *ngIf="this.selection.hasValue()" title="Destacar as feições selecionadas no mapa." type="button" (click)="fitSelectedFeatures()" mat-raised-button color="primary">
                    <span class="material-icons">location_on</span> Destacar no mapa
                </button>
                <button disabled *ngIf="!this.selection.hasValue()" title="Selecione pelo menos uma feição para destacar no mapa." type="button" mat-raised-button color="primary">
                    <span class="material-icons">location_on</span> Destacar no mapa
                </button>

                <button *ngIf="!this.selection.hasValue() || exporting == true" disabled type="button" mat-raised-button color="primary">
                    <span class="material-icons">save_alt</span> Exportar
                </button>
                <button *ngIf="this.selection.hasValue() && exporting == false" type="button" (click)="onExportSelecteds()" mat-raised-button color="primary">
                    <span class="material-icons">save_alt</span> Exportar
                </button>

                <button type="button" title="Editar informações sobre a feição selecionado." *ngIf="this.getSelectedFeatures().length==1 && hasEditionPermition()" (click)="editInfo()" mat-raised-button color="primary">
                    <span class="material-icons">info</span> Editar informações
                </button>
                <button disabled type="button" title="Selecione apenas uma feição para editar as informações." *ngIf="this.getSelectedFeatures().length!=1 && hasEditionPermition()" mat-raised-button color="primary">
                    <span class="material-icons">info</span> Editar informações
                </button>

                <button type="button" title="Gerar relatório da feição selecionada." *ngIf="this.getSelectedFeatures().length==1 && layerFeatureReportToolEnabled==true" (click)="showFeatureReport()" mat-raised-button color="primary">
                    <span class="material-icons">summarize</span> Gerar relatório
                </button>
                <button disabled type="button" title="Selecione apenas uma feição para gerar o relatório da feição." *ngIf="this.getSelectedFeatures().length!=1 && layerFeatureReportToolEnabled==true" mat-raised-button color="primary">
                    <span class="material-icons">summarize</span> Gerar relatório
                </button>

                <button type="button" title="Ver informações sobre a feição selecionado." *ngIf="this.getSelectedFeatures().length==1" (click)="showInfo()" mat-raised-button color="primary">
                    <span class="material-icons">info</span> Informações
                </button>
                <button disabled type="button" title="Selecione apenas uma feição para ver as informações." *ngIf="this.getSelectedFeatures().length!=1" (click)="showInfo()" mat-raised-button color="primary">
                    <span class="material-icons">info</span> Informações
                </button>
                
            </div>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 coletas-table" matSort *ngIf="dataSource" (matSortChange)="sortData($event)" matSortActive="name" matSortDirection="asc">
                
              <ng-container *ngFor="let attr of getCurrentAttributesList(); let colIndex = index" matColumnDef="{{attr}}">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="{{attr}}">{{attr}}</th>
                <td mat-cell *matCellDef="let element">{{element.properties[colIndex].value}}</td>
              </ng-container>

                <!-- RADIO Column -->

                <ng-container matColumnDef="row_selection">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                    [checked]="selection.hasValue() && isAllSelected()"
                                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                                    [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()"
                                    (change)="$event ? selection.toggle(row) : null"
                                    [checked]="selection.isSelected(row)"
                                    [aria-label]="checkboxLabel(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>

                  <tr mat-header-row *matHeaderRowDef="getTableColumns()"></tr>
                  <tr mat-row *matRowDef="let row; columns: getTableColumns()"></tr>

            </table>

            <table id="export-table-lista-atributos">
            </table>

            <mat-paginator [length]="100"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
            aria-label="Selecione a página" showFirstLastButtons></mat-paginator>

            <div *ngIf="!currentFeatures && loading == false" class="sem-dados">
                Não existem coletas. Use a ferramenta de "Adicionar coleta por apontamento".
            </div>

            <div *ngIf="loading" class="loading">
                <mat-spinner></mat-spinner>    
            </div>

            
  

        </mat-expansion-panel>
    </ng-container>
</mat-accordion>
<div id="temp-map-div" style="display: none;"></div>