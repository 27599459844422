import { ToolsService } from '../tools.service';
import { Tool } from '../tool';

export class FotosFachadaManagerTool extends Tool {

    cursorType=null;
    id='fotosfachada-manager-tool';
    enabled=false;
    name='Gerenciar Fotos de Fachada';
    title='Gerenciar Fotos de Fachada';
    type='click';
    map:L.Map=null;
    icon='description';
    ll: any;
    protected visible:boolean=false;
    layerTool: boolean=false;
    customParameter=new Map<String, any>();

    constructor(toolsService: ToolsService) 
    { 
        super(toolsService)
    }

    public execAction=(event:L.LeafletMouseEvent)=>{
        return;
    };

    public enableImpl()
    {
      return;
    }
    public disableImpl()
    {
        return;
    }       
}
