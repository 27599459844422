import { Injectable } from '@angular/core';
import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { BoundingBox } from '../map/vo/boundingbox';
import { LayersConfigService } from '../map/layers/layers.config.service';
import { GeneralService } from './general.service';
import { environment } from '../../environments/environment';
import { Layer, Util } from 'leaflet';
import { XMLParser } from '../util/xml-parser';

@Injectable({
    providedIn: 'root'
  })

export class CapabilitiesService {
   
    
    constructor(public http: HttpClient,
      private layersService: LayersConfigService,
      private generalService: GeneralService) 
    {
    }

    private getCapabilitiesUrl(l:Layer)
    {
        let wmsParams=l["wmsParams"];
        let params = {
                request: 'GetCapabilities',
                service: 'WMS',
                version: wmsParams.version,
                layers: wmsParams.layers
        };
        
        return l["_url"] + Util.getParamString(params, l["_url"], false);
    };


    public async getCapabilitiesforLayer(leafletLayer: L.Layer) : Promise<any>
    {
        let promise = new Promise((resolve, reject) => {
          let capabilities=null;
            let url=decodeURIComponent(this.getCapabilitiesUrl(leafletLayer));
            url=encodeURIComponent(url);
            
            let url_proxy=environment.PROXY_URL+"?url="+url;
            this.http.get(url_proxy, {responseType: 'text'}).subscribe(
                (data:any)=>{
                    if(data) {
                        capabilities = XMLParser.parseXMLToObject(data);
                    }
                },(error)=>{
                    console.log("GetCapabilitiesForLayer error:" + JSON.stringify(error));

                    let serviceException=( error.error.text && error.error.text.includes("ServiceExceptionReport") )?(error.error.text):("");

                    let message="Falhou ao consultar a camada:"+leafletLayer["wmsParams"]["layers"]+"<br>"+serviceException;
                    
                    reject(message);

                }, () => {
                    resolve(capabilities);
                }
            );
        });
        return promise;

    }

    public getElementByName(value: string, elements: Array<any>) : any
    {
        let returnElement= this.getElementByAttribute("name",value, elements);
        return returnElement;
    }
    public getFirstElement(elements: Array<any>) : any
    {
        if(elements.length>0)
        {
            return elements[0];
        }
        console.log("Empty capabilities element, return null");
        return null;

    }
    public getElementsByName(name: string, elements: Array<any>) : Array<any>
    {
        let returnElements=[];
        elements.forEach(element => {
            if(element["name"]==name)
            {
                returnElements.push(element);
            }
        });
        return returnElements;
    }
    
    public getElementByAttribute(attributeName: string, attributeValue: string, elements: Array<any>) : any
    {
        let returnElement=null;
        elements.forEach(element => {
            if(element[attributeName]==attributeValue)
            {
                returnElement=element;
            }
        });
        return returnElement;
    }

    public getLayerLatLongBoundingBox(capabilities: any) : BoundingBox
    {
        let WMT_MS_Capabilities =this.getElementByName("WMT_MS_Capabilities", capabilities.elements);
        let capability = this.getElementByName("Capability", WMT_MS_Capabilities.elements);
        let layer = this.getElementByName("Layer", capability.elements);
        let subLayer = this.getElementByName("Layer", layer.elements);
        let latlongbb = this.getElementByName("LatLonBoundingBox", subLayer.elements);
        
        let bbox : BoundingBox = new BoundingBox(parseFloat(latlongbb.attributes.minx),parseFloat(latlongbb.attributes.miny),parseFloat(latlongbb.attributes.maxx),parseFloat(latlongbb.attributes.maxy));
        return bbox;
    }
    
} 