<div class="download-div" *ngIf="layer">
    <div class="download-layer">
        <div class="download-layer-format">
            <div class="download-layer-format-label">
                <mat-label>Formato:</mat-label>
            </div>
            <div class="download-layer-format-buttons">
                <mat-button-toggle-group name="downloadFormat" aria-label="Font Style" [(ngModel)]="downloadFormat">
                    <mat-button-toggle *ngFor="let format of formats" value="{{format.name}}">{{format.description}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
    
        <div class="download-layer-name">
            <div class="download-layer-name-label">
                <mat-label>Camada:</mat-label>    
            </div>
            <div class="download-layer-name-value">
                <input matInput placeholder="" value="{{layer.name}}" name="downloadLayer" readonly="readonly">
            </div>
        </div>    
        <mat-divider></mat-divider>
        
        <div class="download-layer-button">
            <button *ngIf="downloading == false" type="button" (click)="download()" mat-raised-button color="primary" id="download-layer-button">
                <span class="material-icons">get_app</span> Baixar
            </button>
            <button *ngIf="downloading == true" type="button" (click)="download()" mat-raised-button color="disabled" id="download-layer-button" disabled="disabled">
                Baixando
            </button>
            <mat-spinner *ngIf="downloading == true" [diameter]="15" strokeWidth="2"></mat-spinner>    

        </div>    
</div>

