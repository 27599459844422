import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/map/dialog/dialog.component';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { LayersInfo } from 'src/app/map/vo/layersinfo';
import { Feature } from '../../../vo/feature';
import { FeatureInfoTool } from '../../fetureinfo-tool/featureinfo-tool';
import { FetureInfoToolComponent } from '../../fetureinfo-tool/fetureinfo-tool.component';
import { Tool } from '../../tool';

export class AttributeSearchFeatureEdit
{
    customizedFeatureInfo: string;
    feature: Feature;
    parent: MatDialog;
    layerConfig: LayerConfig;
    tool: any;

    constructor(parent: MatDialog, tool: any, feature: Feature, layerConfig: LayerConfig)
    {
        this.feature = feature;
        this.parent=parent;
        this.layerConfig = layerConfig;
        this.tool = tool;
    }
    showFeatureEdit()
    {
        return this.tool.openEditAttribute(this);
    }
}
