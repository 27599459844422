import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { ToastService } from 'src/app/service/toast.service';
import {SafePipe} from '../../../util/safe.pipe';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.css']
})
export class DocumentPreviewComponent implements OnInit 
{

  url: string;
  documentData: any;
  documentDataType: any;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, 
  private authenticationService: AuthenticationService, private toastService: ToastService,  public sanitizer: DomSanitizer) {
    console.log(data);
    this.url = data.documentUrl;
    this.documentDataType = data.documentDataType;
    
  }

  ngOnInit(): void 
  {
    
    let user = this.authenticationService.getCurrentUser();
    if(!user)
    {
      this.toastService.error("Usuário não está autenticado corretamente. Efetue o login novamente.", "Problemas ao baixar documento.");
    }

    let httpOptions = {};
              
    if(user.token)
    {
      let header = new HttpHeaders().set('Authorization', 'Bearer ' + user.token );
     
      httpOptions = {
        headers: header
      };        
    }

    httpOptions['observe'] = 'body';
    httpOptions['params'] = {};
    httpOptions['reportProgress'] = false;
    httpOptions['responseType'] = 'blob';


    this.http.get(this.url, httpOptions).subscribe((res:any ) => {
    
      const file = new Blob([res], {type: this.documentDataType});
      const blob = window.URL.createObjectURL(file);
      //const link =  document.createElement('object');
      //link.data = blob;
      
      this.documentData=this.sanitizer.bypassSecurityTrustResourceUrl(blob)
    });
  }

}
