import { Property } from "./property";

export class Settlement {
    id: number;
    nucleo: string;
    sigla: string;
    codigoPLHIS: string;
    poligonal: string;
    tipoInadequacao: string;
    origem: string;
    areaTotal: string;
    propriedade: string;
    tipologia: string;
    nroDomicilios: string;
    nroCoberturas: string;
    historicoQtdDomicilios: any;
    documentos: any;
    projetos: any;
    gravames: any;

    constructor()
    {
    }

    toProperties()
    {
        let properties : Array<Property> = [];

        if(this.id)
        {
            //id
            properties.push(new Property("id",this.id.toString()));
        }
        if(this.nucleo)
        {
            //id
            properties.push(new Property("nucleo",this.nucleo.toString()));
        }
        if(this.sigla)
        {
            //id
            properties.push(new Property("sigla_nucleo",this.sigla.toString()));
        }
        if(this.codigoPLHIS)
        {
            //id
            properties.push(new Property("codigo_phlis",this.codigoPLHIS.toString()));
        }
        if(this.poligonal)
        {
            properties.push(new Property("poligonal",this.poligonal.toString()));
        }
        if(this.tipoInadequacao)
        {
            properties.push(new Property("tipo_inadequacao",this.tipoInadequacao.toString()));
        }
        if(this.origem)
        {
            properties.push(new Property("origem",this.origem.toString()));
        }
        if(this.propriedade)
        {
            properties.push(new Property("propriedade",this.propriedade.toString()));
        }
        if(this.tipologia)
        {
            properties.push(new Property("tipologia",this.tipologia.toString()));
        }
        if(this.nroDomicilios)
        {
            properties.push(new Property("nro_domicilios",this.nroDomicilios.toString()));
        }
        if(this.nroCoberturas)
        {
            properties.push(new Property("nro_coberturas",this.nroCoberturas.toString()));
        }
        if(this.historicoQtdDomicilios)
        {
            properties.push(new Property("historico_contagem_domicilios",JSON.stringify(this.historicoQtdDomicilios)));
        }
        if(this.areaTotal)
        {
            properties.push(new Property("area_total",this.areaTotal));
        }
        return properties;
    }
    
    

}
