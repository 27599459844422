
export class AppNotification 
{
    static readonly ENABLE_LAYER_EVENT: string = 'ENABLE_LAYER_EVENT';
    static readonly ENABLE_LAYER_EDITION_EVENT: string = 'ENABLE_LAYER_EDITION_EVENT';
    static readonly ENABLE_LAYER_EDITION_TOOL_CONTROL_EVENT: string = 'ENABLE_LAYER_EDITION_TOOL_CONTROL_EVENT';
    static readonly ON_EDITION_OPERATIONS_CHANGE_EVENT: string = 'ON_EDITION_OPERATIONS_CHANGE_EVENT';
    static readonly CLEAR_EDITION_CONTEXT: string = 'CLEAR_EDITION_CONTEXT';
    static readonly DISABLE_LAYER_EDITION_EVENT: string = 'DISABLE_LAYER_EDITION_EVENT';
    static readonly CHANGE_PRINT_TITLE_EVENT: string = 'CHANGE_PRINT_TITLE_EVENT';
    static readonly ON_GEO_ASSOCIATION_EVENT: string = 'ON_GEO_ASSOCIATION_EVENT';
    static readonly ON_DOWNLOAD_FINISHED_EVENT: string = 'ON_DOWNLOAD_FINISHED_EVENT';
    static readonly ON_DOWNLOAD_ERROR_EVENT: string = 'ON_DOWNLOAD_ERROR_EVENT';
    static readonly TOOGLE_SAVE_EDITION_BUTTON_VISIBILITY_EVENT: string = 'TOOGLE_SAVE_EDITION_BUTTON_VISIBILITY_EVENT';
    static readonly CHANGE_TOOL_EVENT: string = 'CHANGE_TOOL_EVENT';
    static readonly AUTHENTICATION_CHANGED: string = 'AUTHENTICATION_CHANGED';
    static readonly SHOW_AUTHENTICATION_DIALOG: string = 'SHOW_AUTHENTICATION_DIALOG';
    static readonly FINISH_CUSTOM_EDITION_INTERACTION: string = 'FINISH_CUSTOM_EDITION_INTERACTION';
    static readonly ON_CUSTOM_EDITION_STEP_CHANGED: string = 'ON_CUSTOM_EDITION_STEP_CHANGED';
    static readonly LAYERS_LOADED: string = 'LAYERS_LOADED';
    static readonly ON_MAP_READY: string = 'ON_MAP_READY';


    constructor(event: string, object: any = null)
    {
        this.event = event;
        this.object = object;
    }

    event: string;
    object: any;
}
