<mat-accordion class="edit-street-attributes-accordion">
    
  <ng-container >
      <mat-expansion-panel [expanded]="true" class="expansion-panel">
          <mat-expansion-panel-header>
              <mat-panel-title id="layer-header" class="expansion-panel-title">
                  <mat-icon class="material-icons layer-icon">table_view</mat-icon>Editar Atributos de Logradouro
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
          </mat-expansion-panel-header>
          <div>
              <table mat-table [dataSource]="attributes" class="mat-elevation-z8">
                  <ng-container matColumnDef="key">
                      <th mat-header-cell *matHeaderCellDef> Campo </th>
                      <td mat-cell *matCellDef="let property"> {{property.key}} </td>
                    </ng-container>
                    <ng-container matColumnDef="value">
                      <th mat-header-cell *matHeaderCellDef> Valor </th>
                      <td mat-cell *matCellDef="let property" >
                          <input *ngIf="property.key!=idAttribute" matInput placeholder="" id="{{property.key}}" value="{{property.value}}" class="property-value" #attributesElements>
                          <div *ngIf="property.key==idAttribute" class="property-readonly-value">{{property.value}}</div>
                      </td>
                      
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
          </div>
      </mat-expansion-panel>
  </ng-container>
</mat-accordion>


<div class="buttons-div">
  <div class="save-button">
      <button mat-raised-button color="primary" (click)="save()">Salvar</button>

  </div>
  <div class="cancel-button">
      <button mat-raised-button color="basic" (click)="close()">Cancelar</button>
  </div>
</div>