import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { LayersConfigService } from '../../../layers/layers.config.service';
import { ToolsService } from '../../tools.service';
import { GeneralService } from 'src/app/service/general.service';
import { NotificationService } from 'src/app/service/notification.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnnotationTool } from '../annotation-tool';
import { Subscription } from 'rxjs';



@Component({
  selector: 'annotation-name',
  templateUrl: './annotation-name.component.html',
  styleUrls: ['./annotation-name.component.css']
})
export class AnnotationNameComponent implements OnInit {

  name: string;
  layer: any;
  parent: any;
  private tool:AnnotationTool;
  private subscriptionTool:Subscription;
  dialogTitle:string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private toolsService: ToolsService) 
  { 
    this.name=data.name;
    this.layer=data.layer;
    this.parent=data.parent;
    this.dialogTitle = data.title;
    this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (tool:AnnotationTool) => {
        this.tool=tool;
        this.update();
      },()=> {
      }
    );
  }
  ngOnInit(): void 
  {
    
  }

  update()
  {
    this.name="";
  }

  save()
  {
    this.layer.bindTooltip(this.name, {permanent:true, direction:'auto'})    
    this.close();
  }
  close()
  {
    this.parent.closeCreateCallback();
  }


}
