import { CanvasService } from '../../canvas/canvas.service';
import { AbstractEditionTool } from '../edit-layer-tool/abstract-edition-tool';
import * as L from 'leaflet';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../../vo/notification';
import { ToolsService } from '../tools.service';
import { FeatureService } from 'src/app/service/feature.service';
import { Property } from '../../vo/property';
import { LayersConfigService } from '../../layers/layers.config.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { LayerConfig } from '../../layers/layers.config';
export class EditLayerLoadGeomTool extends AbstractEditionTool {

  cursorType='tool-cursor-pointer';
  id='edit-layer-load-geom-tool';
  enabled=false;
  protected visible:boolean=false;
  name='Edit Layer Download Geom Tool';
  title='Ferramenta para download de geometria';
  type='click';
  map:L.Map=null;
  ll: any;
  icon='';
  layerTool: boolean=true;
  customParameter=new Map<String, any>();
  loadEditionGeomControl: any;
  selectedFeature: any;
  currentAction: any;

  constructor(private canvasService: CanvasService, toolsService: ToolsService, private notificationService: NotificationService, private featureService: FeatureService, private layersConfigService: LayersConfigService) 
  {
      super(toolsService)
  }

  public execAction=(event:L.LeafletMouseEvent)=>
  {
    if(this.enabled)
    {
      if(this.currentAction=="load-geom-action")
      {
        this.map=event.sourceTarget;
        this.ll=event.latlng;
        this.getFeature();
      }
    }
  }

  public enableImpl()
  {
  }
  public disableImpl()
  {
    console.log(this.canvasService.getMemoryEditionLayer().toGeoJSON());
  }

  public getNotificationService()
  {
    return this.notificationService;
  }

  private getFeature()
  {
    let currentEditionLayer = this.canvasService.getCurrentEditionLayer();
    if(currentEditionLayer!=null)
    {

      let idAttribute = this.layersConfigService.getLayerIdAttribute(currentEditionLayer);

      if(idAttribute)
      {
        this.map.eachLayer( (l)=>{
          if(l["wmsParams"]) 
          {
            if(l["id"]==currentEditionLayer.getId())
            {
              this.featureService.getFeatureInfo(this.ll, l, this.map).then(featureInfo => {
    
                if(featureInfo)
                {
                  if(featureInfo.features.length>0)
                  {
                    this.selectedFeature=featureInfo.features[0];
                    console.log(this.selectedFeature);
  
  
                     let attributesFilterList: Array<Array<Property>>=[];
                     let attributeFilterList: Array<Property>=[];
  
                    let idProperty = this.featureService.getPropertyByKey(this.selectedFeature.properties, idAttribute);

                    if(idProperty)
                    {
                      attributeFilterList.push(idProperty);
                      attributesFilterList.push(attributeFilterList);
                      this.featureService.getFeatures(currentEditionLayer, "", attributesFilterList).then(features => 
                        {
                          if(features && features.length>0)
                          {
                
                            if(this.canvasService.getMemoryEditionLayer())
                            {
                              this.canvasService.getMemoryEditionLayer().addData(features);
                            }
                          }
                          
                        });
                    }
                    else
                    {
                      console.log("Property "+ idAttribute +" not found...")
                    }
                  }
                }
              });
            }
          }
        });
      }
      
    }
  }

  private getFeatureByBox()
  {
    let currentEditionLayer = this.canvasService.getCurrentEditionLayer();
    let bounds = this.canvasService.getMap().getBounds();

    this.featureService.getFeaturesByBox(currentEditionLayer, "EPSG%3A4326", bounds).then(features => 
      {
        if(features && features.length>0)
        {

          if(this.canvasService.getMemoryEditionLayer())
          {
            this.canvasService.getMemoryEditionLayer().addData(features);
          }
        }
        
      });
  }

   public removeToolControl()
  {

  }

  public addToolControl() {
    
    let button = this.canvasService.getMap().pm["Toolbar"].getButtons()[this.id];
    
    if(!button)
    {
      const actions = [
        'cancel',
        {
          text: 'Apontar Geometrias',
          id:'load-geom-action',
          onClick: () => {
            let notification = new AppNotification(AppNotification.ENABLE_LAYER_EDITION_TOOL_CONTROL_EVENT, this);
            this.notificationService.send(notification);
          }
        },
        {
          text: 'Carregar Geometrias Visíveis', id:'load-geom-area-action' ,onClick: () => {

            if(window.confirm("Esse processo pode demorar e até travar seu navegador dependendo da quantidade de geometrias. Certifiquesse de que a área vísivel seja exatamente as geometrias que deseja baixar. Deseja continuar?"))
            {
              let notification = new AppNotification(AppNotification.ENABLE_LAYER_EDITION_TOOL_CONTROL_EVENT, this);
              this.notificationService.send(notification);

              this.getFeatureByBox();
              
            }
          }
        }
      ];
      let options = {
        name: this.id,
        title: 'Carregar Geometrias',
        className: 'load-edition-geom-leaflet-control',
        actions: actions
      };
      this.canvasService.getMap().pm["Toolbar"].createCustomControl(
        options
      );
    }
  }
  public toolbarButtonClicked(event: any) 
  {
    if(event.btnName && event.btnName==this.id)
    {
      this.enable();
    }
    else
    {
      if(this.enabled)
      {
        this.disable();
      }
    }
  }
  public toolbarActionClicked(event: any) 
  {
    if(event.btnName && event.btnName==this.id)
    {
      if(event.action && (event.action.id=="load-geom-action" || event.action.id=="load-geom-byarea-action" ))
      {
        this.currentAction=event.action.id;
        this.enable();  
      }
      else
      {
        if(this.enabled)
        {
          this.disable();
        }
      }
      
    }
    else
    {
      if(this.enabled)
      {
        this.disable();
      }
      
    }
  }
  public isVisibleForLayer(layer :LayerConfig) : boolean
  {
      return this.layerTool;
  }
  public isEnabled(layer :LayerConfig) : boolean
  {
      return this.enabled;
  }

}
