import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Fotosfachada, FotosfachadaInner } from '@funcate/sigweb-cti-api';
import { Inscricao } from 'src/app/map/vo/inscricao';
import { BackendService } from 'src/app/service/backend.service';
import { GeneralService } from 'src/app/service/general.service';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-fotosfachada-edit',
  templateUrl: './fotosfachada-edit.component.html',
  styleUrls: ['./fotosfachada-edit.component.css']
})
export class FotosfachadaEditComponent implements OnInit {

  acao: string;
  new: boolean;
  fotosFachadasControl: FormGroup;
  maxDate = new Date();

  //Para Edições
  fotosFachadasSelecionadas: Fotosfachada;

  //Para novas fotos
  inscricoes: Inscricao[];
  dataCaptura: Date;
  parent: any;
 

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private backendService: BackendService, private toastService: ToastService, private dateAdapter: DateAdapter<Date>,
  private generalService: GeneralService) 
  {    
    this.parent = data.parent;
    this.new = data.new;
    
    if(data.new==true)
    {
      this.fotosFachadasControl = new FormGroup(
        {
          file: new FormControl(),
          dataCaptura: new FormControl(),
          new: new FormControl()
        }        
      );
      this.inscricoes = data.inscricoes;
      this.dataCaptura = new Date();
      this.acao = "Adicionar";
    }
    else
    {
      this.fotosFachadasControl = new FormGroup(
        {
          dataCaptura: new FormControl(),
          new: new FormControl()
        }        
      );
      this.fotosFachadasSelecionadas = this.data.fotosFachadasSelecionadas;
      this.acao = "Editar";      
    }
    
    if(this.fotosFachadasSelecionadas && this.fotosFachadasSelecionadas.length==1)
    {
      //Editando somente uma foto de fachada.
      this.dataCaptura = this.fotosFachadasSelecionadas[0]['data_captura'];
    }
    else
    {
      this.dataCaptura = new Date();
    }

    this.dateAdapter.setLocale('pt-BR'); //dd/MM/yyyy
     
  }

  ngOnInit(): void {
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fotosFachadasControl.get('file').setValue(file);
    }
  }

  save()
  {
    
    let datePipe = new DatePipe('pt-BR');
    let dataCapturaStr = datePipe.transform(this.dataCaptura.toString(), 'dd/MM/yyyy');    
  
    if(this.new)
    {

      let file = this.fotosFachadasControl.get('file').value;
      let foto: Blob = new Blob([file], { type: 'application/octet-stream' });

      let inscricoesStr = "";

      for (let i = 0; i < this.inscricoes.length; i++) {
        let inscricao = this.inscricoes[i];
        if(i!=0)
        {
          inscricoesStr+=";"
        }      
        inscricoesStr+=inscricao.getInscricaoCompleta();

      }

      if(file)
      {
        this.backendService.addFotoFachada(inscricoesStr, file.name, dataCapturaStr, foto).then(()=>
        {
          this.toastService.sucess("Fotos de fachadas associada.","Sucesso");
          this.parent.closeEditFotosDialog();
        }).catch(()=>
        {
          this.toastService.error("Problema ao associar fotos de fachada.","Erro");
        })
      }
      else
      {
        this.toastService.error("Favor selecionar primeiramente um arquivo.","Erro");
      }
    }
    else
    {
      
      let fotosFachadas: Fotosfachada = []


      for (let i = 0; i < this.fotosFachadasSelecionadas.length; i++) 
      {
        let fotoFachada : FotosfachadaInner = new Object();

        fotoFachada.id=this.fotosFachadasSelecionadas[i].id;
        fotoFachada.ic=this.fotosFachadasSelecionadas[i].ic;
        fotoFachada.setor=this.fotosFachadasSelecionadas[i].setor;
        fotoFachada.quadra=this.fotosFachadasSelecionadas[i].quadra;
        fotoFachada.logradouro=this.fotosFachadasSelecionadas[i].logradouro;
        fotoFachada.lote=this.fotosFachadasSelecionadas[i].lote;
        fotoFachada.sublote=this.fotosFachadasSelecionadas[i].sublote;
        fotoFachada['data_captura']=dataCapturaStr;
        fotoFachada.filepath=this.fotosFachadasSelecionadas[i].filepath;
        fotosFachadas.push(fotoFachada);
      }

      this.backendService.editFotoFachada(fotosFachadas).then(()=>
      {
        this.toastService.sucess("Fotos de fachadas atualizadas..","Sucesso");
        this.parent.closeEditFotosDialog();
      }).catch(()=>
      {
        this.toastService.error("Problema ao atualizar fotos de fachada.","Erro");
      });
    }
  
  }

   
  
  close()
  {
    this.parent.closeEditFotosDialog();
  }

}
