import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { LayerConfig } from '../../layers/layers.config';
import { ToolsService } from '../tools.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { ToastService } from 'src/app/service/toast.service';
import { AddGeomByCoordsEditionLayerTool } from './add-geom-by-coords-edition-layer-tool';
import { Coordinate } from 'ol/coordinate';
import { MatTableDataSource } from '@angular/material/table';
import { Feature } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import { GeomType } from '../../vo/geomtype';
import { Point, Polygon } from 'leaflet';
import { EditLayerTool } from '../edit-layer-tool/edit-layer-tool';
import { Operation } from '../../vo/operation';


@Component({
  selector: 'add-geom-by-coords-edition-layer-tool',
  templateUrl: './add-geom-by-coords-edition-layer-tool.component.html',
  styleUrls: ['./add-geom-by-coords-edition-layer-tool.component.css']
})


export class AddGeomByCoordsEditionLayerToolComponent implements AfterViewInit
{
  private tool:AddGeomByCoordsEditionLayerTool;
  private subscriptionTool:Subscription;
  coordsType: any[];
  searchCoordsType: number;
  utmCoordsX: string;
  utmCoordsY: string;
  geoCoordsDecimalX: any;
  geoCoordsDecimalY: any;
  geoCoordsDegreesX: any;
  geoCoordsDegreesY: any;  
  displayedColumns: string[] = ['index','x', 'y'];
  dataSource: MatTableDataSource<any>;
  coordinates: any[];
  geomType: GeomType;
  editLayerTool: EditLayerTool;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    private toolsService: ToolsService, private canvasService: CanvasService, private toastService: ToastService, private dialog: MatDialog) 
  {
    this.searchCoordsType=1;
    this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (tool:AddGeomByCoordsEditionLayerTool) => {
        this.tool=tool;
        this.update();
      },()=> {
      }
    );
    this.coordinates= [];
    this.dataSource= new MatTableDataSource<any>();
    this.geomType = GeomType.Polygon;
    this.editLayerTool=data.editLayerTool;
  }

  ngOnInit(): void
  {
    this.geoCoordsDegreesX = new Object();
    this.geoCoordsDegreesY = new Object();
  }

  ngAfterViewInit() 
  {
    //
  }  

  private update() 
  {
   this.initCoordsType();
  }

  private initCoordsType()
  {
    this.coordsType = [];
    this.coordsType.push(
    {
      id:1,
      name: "Coordenada UTM Zona 23S - SIRGAS2000 (metros)",
      srid: 31983
    });
    // this.coordsType.push(
    // {
    //   id:2,
    //   name: "Coordenada Geográficas (Grau Decimal)",
    //   srid: 4326
      
    // });
    // this.coordsType.push(
    // {
    //   id:3,
    //   name: "Coordenada Geográficas (Grau Minuto Segundo)",
    //   srid: 4326
    // });      
  }

  public addCoord()
  {
    let coord=null;
    if(this.searchCoordsType==1)
    {
      coord = {x:Number.parseFloat(this.utmCoordsX), y:Number.parseFloat(this.utmCoordsY) };
      //this.canvasService.reprojectCoordToWGS84(Number.parseFloat(this.utmCoordsX), Number.parseFloat(this.utmCoordsY), this.coordsType[0].srid);
    } else if(this.searchCoordsType==2)
    {
      coord = {x:Number.parseInt(this.geoCoordsDecimalX), y: Number.parseInt(this.geoCoordsDecimalY) };
    } else if(this.searchCoordsType==3)
    {      

      let ddX = this.canvasService.convertDMSToDD(Number.parseInt(this.geoCoordsDegreesX.degrees),Number.parseInt(this.geoCoordsDegreesX.minutes), Number.parseFloat(this.geoCoordsDegreesX.seconds), this.geoCoordsDegreesX.direction);
      let ddY = this.canvasService.convertDMSToDD(Number.parseInt(this.geoCoordsDegreesY.degrees),Number.parseInt(this.geoCoordsDegreesY.minutes), Number.parseFloat(this.geoCoordsDegreesY.seconds), this.geoCoordsDegreesY.direction);

      coord = {x:Number.parseFloat(ddX), y: Number.parseFloat(ddY) };
    }

    if(coord && coord.x && coord.y)
    {
      this.coordinates.push(coord);
      this.dataSource.data=this.coordinates;
    }
    else
    {
      this.toastService.error("Coordenadas inválidas", "Erro");
    }
  }

  public addGeom()
  {

    let coords = [];

    this.coordinates.forEach(coordinate => 
      {
        let coord=coordinate;
        if(this.searchCoordsType==1)
        {
          coord = this.canvasService.reprojectCoordToWGS84(Number.parseInt(coordinate.x), Number.parseInt(coordinate.y), this.coordsType[0].srid);
        }
        coords.push([coord.y,coord.x]);        
    });

    if(coords.length>0)
    {
      let geom = null;

      if(this.geomType==GeomType.Point)
      {      
        geom = new Point(coords[0][0], coords[0][1]);
      } else if(this.geomType==GeomType.Line)
      {
        //geom = new LineString(coords);
      } else if(this.geomType==GeomType.Polygon)
      {
        geom = new Polygon([coords]);  
      }

      if(this.validateGeom(geom)==true)
      {
        let outLayer : any = this.canvasService.getMemoryEditionLayer().addData(geom.toGeoJSON());        

        let layer = outLayer.getLayers()[outLayer.getLayers().length-1];

        let event = { layer: layer,
        shape: geom.pm.getShape(),
        type: Operation.Insert };
        this.editLayerTool.onInsertFeature(event);

        this.canvasService.fitFeatures([geom.toGeoJSON()]);

        this.canvasService.clearMarker();

        this.tool.closeDialog();
      }
      else
      {
        this.toastService.error("Geometria inválida, verifique as coordenadas.", "Erro");  
      }
    }
    else
    {
      this.toastService.error("Não foram encontradas coordenadas.", "Erro");
    }
  } 

  validateGeom(geom: any)
  {
    if(this.geomType==GeomType.Point)
      { 
        //Não suportado ainda     
        return false;

      } else if(this.geomType==GeomType.Line)
      {
        //Não suportado ainda     
        return false;
      } else if(this.geomType==GeomType.Polygon)
      {
        //Testing LinearRing
        if(geom.toGeoJSON().geometry.coordinates.length==1)
        {
          //Testing Coordinates
          if(geom.toGeoJSON().geometry.coordinates[0].length>2)
          {
            //Testing if polygon is closed
            if(geom.toGeoJSON().geometry.coordinates[0][0][0]==geom.toGeoJSON().geometry.coordinates[0][geom.toGeoJSON().geometry.coordinates.length-1][0]
            && geom.toGeoJSON().geometry.coordinates[0][0][1]==geom.toGeoJSON().geometry.coordinates[0][geom.toGeoJSON().geometry.coordinates.length-1][1])
            {
              return true;
            }
          }
        }
        return false;
      }      
  } 
  onPaste(event)
  {
    if(event.target &&
      (event.target.id='utm-x') ||
      (event.target.id='utm-y'))
      {
        let clipboardData = event.clipboardData;

        if(clipboardData)
        {
          let clipboardText = clipboardData.getData("text")
          if(clipboardText)
          {
            if(clipboardText.includes(','))
            {
              let coords = clipboardText.split('\n');
              coords.forEach(coord => {
                if(coord.includes(','))
                {
                  let c = coord.split(',')
                  if(c.length==2)
                  {

                    let geom = {x:c[0], y: c[1] };

                    this.coordinates.push(geom);
                  }                  
                }
              });
              
              this.dataSource.data=this.coordinates;
              setTimeout(function(){
                event.target.value="";
              }, 20);
            }
          }
        }
        

        
      }
      
  }    
  clear()
  {
    this.coordinates=[];
    this.dataSource.data=this.coordinates;
  }
}
