import LineString from 'ol/geom/LineString';
import { AppNotification } from 'src/app/map/vo/notification';
import { GeometryService } from 'src/app/service/geometry.service';
import { NotificationService } from 'src/app/service/notification.service';
import { CustomEditionController } from '../custom-edition-controller';
import {CaptureCoordinateInteraction} from './capture-coord-interaction'

export class SegmentSelectionInteraction extends CaptureCoordinateInteraction
{
    static id: string = "segment-selection-tool";
    private currentFeature: any;
	private selectedSegment: LineString;

    constructor(private editionController: CustomEditionController, private notificationService: NotificationService, feature: any)
    {
        super(SegmentSelectionInteraction.id);
        this.setup({handlePointerUp: this.frontSelectionToolCallback})
        this.currentFeature=feature;
    }

    public frontSelectionToolCallback(event: any)
	{
        if(this.isDragging() == true)
        {
            return true;
        }
        
        var coordAccepted = this.handleSelectFront(event.coordinate);		
        return coordAccepted;	
        
	}


    private getIntersectionSegment(outerLinearRing: any, olCoordinate: any)
    {
        var index = GeometryService.findIntersectionSegment(olCoordinate, outerLinearRing);

		return index;
    }

    private handleSelectFront(olCoordinate)
	{

        let polygon = this.currentFeature.getGeometry();
		let outerLinearRing = polygon.getLinearRing(0);
        
		var segmentIndex = this.getIntersectionSegment(outerLinearRing, olCoordinate);

		if(segmentIndex == -1)
		{
			console.log("Você deve clicar na borda do elemento que gostaria que ficasse virado para a frente.");
			return false;
		}
	
		var olSegmentStartCoord = GeometryService.getSegmentStartCoord(outerLinearRing, segmentIndex);
		var olSegmentEndCoord = GeometryService.getSegmentEndCoord(outerLinearRing, segmentIndex);
		
		this.selectedSegment = new LineString([olSegmentStartCoord, olSegmentEndCoord]);

	    let notification = new AppNotification(AppNotification.FINISH_CUSTOM_EDITION_INTERACTION);        
        notification.object= {interaction:this}
        this.notificationService.send(notification);
	}

	public getSelectedSegment()
	{
		return this.selectedSegment;
	}

}



