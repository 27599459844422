import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../dialog.component';

@Component({
  selector: 'app-generic-confirmation-dialog',
  templateUrl: './generic-confirmation-dialog.component.html',
  styleUrls: ['./generic-confirmation-dialog.component.css']
})
export class GenericConfirmationDialogComponent implements OnInit {

  title: string;
  confirmMessage: string;
  dialogRef: MatDialogRef<DialogComponent>;

  constructor(dialogRef: MatDialogRef<DialogComponent>,
     @Inject(MAT_DIALOG_DATA) public data: any) { 
    this.title = data.confirmTitle;
    this.confirmMessage = data.confirmMessage;
    this.dialogRef=dialogRef;
  }

  ngOnInit(): void 
  {
  }
  confirm()
  {
    this.dialogRef.close(true);
  }
  cancel()
  {
    this.dialogRef.close(false);
  }

}
