import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { LayerConfig } from '../../layers/layers.config';
import { Property } from '../../vo/property';
import { ColetaPGV } from '../../vo/coleta-pgv';
import { ToolsService } from '../tools.service';
import { AttributeSearchFeatureInfo } from './attribute-search-feature-info/attribute-search-feature-info';
import { AttributeSearchSettlementInfo } from './attribute-search-feature-info/attribute-search-settlement-info';
import { AttributeSearchPropertyRegistration } from './attribute-search-feature-info/attribute-search-property-registration-info';
import { AttributeSearchSettlementPropertyInfo } from './attribute-search-feature-info/attribute-search-settlement-property-info';
import { AttributeSearchFeatureEdit } from './attribute-search-feature-edit/attribute-search-feature-edit';
import { AttributeSearchSettlementEdit } from './attribute-search-feature-edit/attribute-search-settlement-edit ';
import { AttributeSearchSettlementPropertyEdit } from './attribute-search-feature-edit/attribute-search-settlement-property-edit';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AttributeSearchTool } from './attribute-search-tool';
import { EditionService } from 'src/app/service/edition.service';
import { FeatureService } from 'src/app/service/feature.service';
import { Userinfo } from '@funcate/sigweb-cti-api';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { DatePipe } from '@angular/common';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { DialogComponent } from 'src/app/map/dialog/dialog.component';
import { GenericConfirmationDialogComponent } from 'src/app/map/dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { Operation } from 'src/app/map/vo/operation';
import { GeomType } from 'src/app/map/vo/geomtype';
import { EditionOperation } from 'src/app/map/vo/edition-operation';
import { Toast } from 'ngx-toastr';
import { ToastService } from 'src/app/service/toast.service';
import { UserGroup } from 'src/app/map/vo/user-group';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Imovel } from 'src/app/map/vo/imovel';
import { Feature } from '../../vo/feature';
import { SelectionModel } from '@angular/cdk/collections';
import * as XLSX from 'xlsx';
import { FeatureInfoTool } from '../fetureinfo-tool/featureinfo-tool';
import { SettlementTool } from '../settlement-tools/settlement-tool';
import { PropertyRegistrationTool } from '../property-registration/property-registration-tool';
import { EditAttributeLayerTool } from '../edit-attribute-layer-tool/edit-attribute-layer-tool';
import { EditSettlementTool } from '../settlement-tools/edit-settlement-tool';
import { DocumentService } from 'src/app/service/document.service';
import { GenericFeatureReportTool } from '../generic-feature-report-tool/generic-feature-report-tool';
import { SettlementProperty } from '../../vo/settlement-property';
import { SettlementPropertyTool } from '../settlement-property-tools/settlement-property-tool';
import { EditSettlementPropertyTool } from '../settlement-property-tools/edit-settlement-property-tool';

@Component({
  selector: 'attribute-search-tool-tool',
  templateUrl: './attribute-search-tool.component.html',
  styleUrls: ['./attribute-search-tool.component.css']
})


export class AttributeSearchToolComponent implements OnInit
{
  tool:AttributeSearchTool;
  displayedColumns: string[] = ['fonte','nome_user','natureza_transacao' ,'data_coleta', 'data_registro', 'area_terreno', 'area_construcao','valor_terreno', 'valor_construcao', 'valor_total', 'tipo_da_construcao', 'endereco', 'obs', 'acoes' ];     
  public currentAttributes: Array<Property>=[];  
  dataSource : MatTableDataSource<Feature>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  selection = new SelectionModel<Imovel>(true, []);

  public currentFeatures: Array<Feature>;
  public loading: boolean;
  public exporting: boolean;
  public currentFilter: string;
  layerFeatureReportToolEnabled: boolean;
  genericFeatureReportTool: any;

  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    private toolsService: ToolsService, private featureService: FeatureService, 
    private layerService: LayersConfigService, private authenticationService: AuthenticationService,
     private canvasService: CanvasService, private editionService: EditionService, 
     private toastService: ToastService, private documentService: DocumentService,
     private dialog: MatDialog) 
  {
      this.tool=data.tool;
      this.loading = false;
      this.exporting = false;
      this.dataSource = new MatTableDataSource<Feature>()
  }

  ngOnInit(): void
  {
    
    if (this.tool) 
    {     
      this.layerFeatureReportToolEnabled=false;
      this.genericFeatureReportTool = this.toolsService.getLayerToolById("generic-feature-report-tool");
    this.layerFeatureReportToolEnabled = this.isFeatureReportToolEnabledForCurrentLayer();
      this.loadAttributes();     
    }
  }  

  private isFeatureReportToolEnabledForCurrentLayer()
  {
    return this.toolsService.isToolEnabledForLayer(this.genericFeatureReportTool,this.tool.currentLayer);
  }

  private loadAttributes()
  {
    
    this.dataSource = new MatTableDataSource<Feature>();
    this.loading = true;
      this.currentAttributes=[];
      let attributesFilterList: Array<Array<Property>>=[];
      let attributeFilterList: Array<Property>=[];

      let layerIdAttribute = this.layerService.getLayerIdAttribute(this.tool.currentLayer);

      if(layerIdAttribute)
      {
        this.featureService.getFeatures(this.tool.currentLayer, "" , attributesFilterList, layerIdAttribute).then(wfsFeatures => 
          {
        
            if(wfsFeatures && wfsFeatures.length>0)
            {
              this.currentFeatures = new Array<Feature>();
  
              wfsFeatures.forEach(wfsFeature => {
  
                if(wfsFeature.properties)
                {
                  if(wfsFeature.properties)
                  {
                    let properties : Array<Property> = this.featureService.getWFSPropertiesObjectAsPropertyArray(wfsFeature.properties);                  
  
                    let featureIdProperty = this.featureService.getPropertyByKey(properties, layerIdAttribute);   
                    
                    if(this.currentAttributes.length==0)
                    {
                      this.loadLayerAttributes(properties);
                    }

                    let currentFeature = new Feature();
                    currentFeature.id = featureIdProperty.value;
                    currentFeature.properties = properties;
                    currentFeature.geoJSON = wfsFeature;
                      
                    this.currentFeatures.push(currentFeature);  
                                        
                  }
                }
                this.loading = false;
              });
              this.dataSource = new MatTableDataSource<Feature>(this.currentFeatures);
              this.dataSource.paginator = this.paginator;
              //this.dataSource.sort = ;
              this.sortData(this.sort)

              this.defineFilterPredicate();

              if(this.currentFilter)
              {
                this.dataSource.filter = this.currentFilter;
              }
              this.selection.clear();

            }
            else
            {
              this.currentFeatures = [];
              this.dataSource = new MatTableDataSource<Feature>(this.currentFeatures);
              this.dataSource.paginator = this.paginator;
              this.loading = false;
            }

          });
      }
      else
      {
        this.loading = false;
        this.toastService.error("Camada não tem campo ID configurado corretamente.", "Erro");
      }
    
        
    
  }

  public getTableColumns()
  {
    let attributes = this.getCurrentAttributesList();
    
    attributes.push("row_selection");
    
    return attributes;
  }

  private loadLayerAttributes(properties: Array<Property>)
  {
    properties.forEach(prop => 
      {
        this.currentAttributes.push(new Property(prop.key.toString(),null));      
    });
    
  }

  public formatDate(date: Date, format: string) : string
  {
    let data="";
    if(date)
    {
      let datePipe = new DatePipe('pt-BR');
      data = datePipe.transform(date.toString(), format);
    }
 

    return data;
  }

  public fitSelectedFeatures()
  {    
    if(!this.selection.hasValue()){
      return;
    }
    let features = this.getSelectedFeatures();

    let fitFeatures = []
    features.forEach(feature => {
      fitFeatures.push(feature.geoJSON);
    });
    this.canvasService.fitFeatures(fitFeatures);
    if(fitFeatures.length==1)
    {
      this.toastService.sucess("A feição selecionada foi destacada no mapa.", "Sucesso");
    }
    else
    {
      this.toastService.sucess("As feições selecionadas foram destacadas no mapa.", "Sucesso");
    }
    
  }

  private getSelectedFeatures() : Feature[]
  {
    let selectedFeatures:Feature[]=[];
    this.dataSource.filteredData.forEach(imovel => {
      if(this.selection.isSelected(imovel)){
        selectedFeatures.push(imovel);
      }
    });
    return selectedFeatures;    
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    if(!this.dataSource) return;
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: Imovel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  public getCurrentAttributesList(): string[]
  {
    let attributes :string[]= [];
    this.currentAttributes.forEach(prop => {
      attributes.push(prop.key.toString());
    });    
    return attributes;
  } 
  sortData(sort: Sort) {

    this.dataSource.sort = this.sort;
    const data = this.currentFeatures.slice();
    if (!sort.active || sort.direction === '') {
      this.currentFeatures = data;
      return;
    }

    this.currentFeatures.sort((a, b) => {
      const isAsc = sort.direction === 'asc';      


      for (let i = 0; i < a.properties.length; i++)
      {
        const propertyA : Property = a.properties[i];
        const propertyB : Property = b.properties[i];

        if(sort.active==propertyA.key)
        {
          return this.compare(new String(propertyA.value).toString(), new String(propertyB.value).toString(), isAsc);
        }     
      }
    });
    this.dataSource = new MatTableDataSource<Feature>(this.currentFeatures);
    this.dataSource.paginator = this.paginator;
    this.defineFilterPredicate();
  } 
  private compare(a: number | string, b: number | string, isAsc: boolean) 
  {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.currentFilter = filterValue.trim().toLowerCase();
    this.dataSource.filter = this.currentFilter;
  }

  private defineFilterPredicate()
  {
    this.dataSource.filterPredicate = function(data, filter: string): boolean 
    {
      for (let index = 0; index < data.properties.length; index++) 
      {
        const property = data.properties[index];
        if(property.value  && (new String(property.value)).toLowerCase().includes(filter))
        {
          return true;
        }    
      }    
      return false;
    };
  }  

  public onExportSelecteds()
  {
    this.exporting = true;
    
    let table = document.getElementById('export-table-lista-atributos');

    let selectedItens:Feature[]=this.getSelectedFeatures();

    let trHeader = document.createElement("tr");    
    
    this.currentAttributes.forEach(attribute => 
    {
      this.addExportTableHeader(trHeader, attribute.key);  
    });

    table.appendChild(trHeader);

    selectedItens.forEach((feature: Feature) => 
    {
      let trBody = document.createElement("tr");     

      feature.properties.forEach((property : Property) => {
        this.addExportTableColumn(trBody,property.value);       
      });      

      table.appendChild(trBody);
    });
    
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(table);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'GEOSV - Lista de Atributos' );
 
    let datePipe = new DatePipe('pt-BR');
    let dataCapturaStr = datePipe.transform(new Date(), 'dd-MM-yyyy_HH-mm-ss');   

    XLSX.writeFile(wb, "geosv-lista-atributos-"+this.tool.currentLayer.getId()+"-"+dataCapturaStr+".xlsx");

    this.exporting = false;
  }
  private addExportTableHeader(tr, text)
  {
    let th = document.createElement("th");
    th.innerHTML=text;
    tr.appendChild(th);
   
  }

  private addExportTableColumn(tr, text)
  {
    let td = document.createElement("td");
    td.innerHTML=text;
    tr.appendChild(td);
  }

  public showInfo()
  {    
    let selectedFeatures = this.getSelectedFeatures();
    if(selectedFeatures.length==1)
    {

      let customizedFeatureInfo = this.layerService.getLayerAppConfig(this.tool.currentLayer, "customized_feature_info");
      

      //if(this.tool.currentLayer.getId()=='')
      if(customizedFeatureInfo == AttributeSearchSettlementInfo.customizedFeatureInfo)
      {
        let tool = new SettlementTool(this.toolsService, this.layerService, this.toastService, this.canvasService, this.featureService, this.editionService, this.dialog);
        let searchInfo:  AttributeSearchSettlementInfo = new AttributeSearchSettlementInfo(this.dialog, tool, selectedFeatures[0], this.tool.currentLayer);
        searchInfo.showFeatureInfo();
      }
      else if(customizedFeatureInfo == AttributeSearchPropertyRegistration.customizedFeatureInfo)
      {
        let tool = new PropertyRegistrationTool(this.toolsService, this.layerService, this.featureService, this.toastService, this.dialog);
        let searchInfo:  AttributeSearchPropertyRegistration = new AttributeSearchPropertyRegistration(this.dialog, tool, selectedFeatures[0], this.tool.currentLayer, this.layerService, this.featureService);
        searchInfo.showFeatureInfo();
      }
      else if(customizedFeatureInfo == AttributeSearchSettlementPropertyInfo.customizedFeatureInfo)
      {
        let tool = new SettlementPropertyTool(this.toolsService, this.documentService, this.layerService, this.toastService, this.canvasService, this.featureService, this.editionService, this.dialog);
        let searchInfo:  AttributeSearchSettlementPropertyInfo = new AttributeSearchSettlementPropertyInfo(this.dialog, tool, selectedFeatures[0], this.tool.currentLayer, this.layerService, this.featureService);
        searchInfo.showFeatureInfo();
      }
      else
      {

        let tool = new FeatureInfoTool(this.toolsService, this.dialog);

        let searchInfo:  AttributeSearchFeatureInfo = new AttributeSearchFeatureInfo(this.dialog, tool, selectedFeatures[0], this.tool.currentLayer);
        searchInfo.showFeatureInfo();
      }
    }
  }
  public editInfo()
  {    
    let selectedFeatures = this.getSelectedFeatures();
    if(selectedFeatures.length==1)
    {

      let customizedFeatureInfo = this.layerService.getLayerAppConfig(this.tool.currentLayer, "customized_feature_edit");
      

      if(customizedFeatureInfo == AttributeSearchSettlementEdit.customizedFeatureInfo)
      {
        let tool = new EditSettlementTool(this.toolsService, this.documentService, this.layerService, 
          this.toastService, this.canvasService, this.featureService, this.editionService ,this.dialog);
        let attrEdit:  AttributeSearchSettlementEdit = new AttributeSearchSettlementEdit(this.dialog, tool, selectedFeatures[0], this.tool.currentLayer);
        let editDialog = attrEdit.showFeatureEdit();

        editDialog.afterClosed().toPromise().then(()=>{
          this.loadAttributes();
          let layer : LayerConfig = this.toolsService.getToolParameter(this.tool, "layer");
          this.canvasService.redrawLayer(layer);
      });
        
      } else if(customizedFeatureInfo == AttributeSearchSettlementPropertyEdit.customizedFeatureInfo) {

        let tool = new EditSettlementPropertyTool(this.toolsService, this.documentService, this.layerService, 
          this.toastService, this.canvasService, this.featureService, this.editionService ,this.dialog);
        let attrEdit:  AttributeSearchSettlementPropertyEdit = new AttributeSearchSettlementPropertyEdit(this.dialog, tool, selectedFeatures[0], this.tool.currentLayer, this.layerService, this.featureService);
        let editDialog = attrEdit.showFeatureEdit();

        editDialog.afterClosed().toPromise().then(()=>{
          this.loadAttributes();
          let layer : LayerConfig = this.toolsService.getToolParameter(this.tool, "layer");
          this.canvasService.redrawLayer(layer);
      });
        
      }
      else 
      {

        let tool = new EditAttributeLayerTool(this.dialog, this.toolsService, this.layerService, this.canvasService);

        let attrEdit:  AttributeSearchFeatureEdit = new AttributeSearchFeatureEdit(this.dialog, tool, selectedFeatures[0], this.tool.currentLayer);
        
        let editDialog = attrEdit.showFeatureEdit();

        editDialog.afterClosed().toPromise().then(()=>{
          this.loadAttributes();
          let layer : LayerConfig = this.toolsService.getToolParameter(this.tool, "layer");
          this.canvasService.redrawLayer(layer);
      });
      }
    }      
  }
  showFeatureReport()
  {
    let selectedFeatures = this.getSelectedFeatures();
    if(selectedFeatures.length==1)
    {
      this.toastService.sucess("Gerando relatório da feição selecionada...", "Aguarde");
      //this.genericFeatureReportTool.showGeneration();
      this.genericFeatureReportTool.layer = this.tool.currentLayer;
      this.genericFeatureReportTool.generateReport([selectedFeatures[0].geoJSON]);
    }
  }

  public hasEditionPermition()
  {
    let user: Userinfo = this.authenticationService.getCurrentUser();

    if(user)
    {
      //Verifica se o usuário tem permissão de escrita na camada de imoveis
      if(this.tool.currentLayer.isWritableForUser(user))
      {
        //Verifica se o usuário tem perfil editor ou admin
      
        return true;
      
      }
    }
    return false;


    return true;
  }
  
}
