import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import {ToolsService} from '../tools.service';
import { Subscription } from 'rxjs';
import { FeatureService } from 'src/app/service/feature.service';
import { LayersInfo } from '../../vo/layersinfo';
import { FeatureInfo } from '../../vo/featureinfo';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Feature } from '../../vo/feature';


@Component({
  selector: 'app-fetureinfo-tool',
  templateUrl: './fetureinfo-tool.component.html',
  styleUrls: ['./fetureinfo-tool.component.scss']
})
export class FetureInfoToolComponent implements OnInit, OnDestroy {

  featureInfoTool:any;
  subscriptionTool:Subscription;
  message: string;
  ctrlLoading:boolean;
  displayedColumns: string[] = ['key', 'value'];

  // Using to support the logic embedded in html.
  cor1="cor1";
  cor2="cor2";
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private toolService: ToolsService,
    private featureService: FeatureService) 
    {
    this.subscriptionTool=this.toolService.toolsObservable.subscribe(
      (featureInfoTool:any) => {
        this.featureInfoTool=featureInfoTool;
        this.update();
        console.log("Obteve informações do serviço tool.service");
      },()=> {
        console.log("Falhou ao obter informações do serviço tool.service");
      }
    );
     if(data && data.infoIntegrationObject)
     {
      this.featureInfoTool = data.infoIntegrationObject;
      this.populateInitialData(data.infoIntegrationObject.feature);
     }
  }

  private populateInitialData(feature: Feature)
  {
    this.featureInfoTool.layersInfo=new LayersInfo();
    let featureInfo = new FeatureInfo();
    featureInfo.layerConfig = this.featureInfoTool.layerConfig;
    featureInfo.features.push(feature);
    this.featureInfoTool.layersInfo.layersFeatures.push(featureInfo);
  }

  update()
  {
    if(this.featureInfoTool)
    {
      this.featureInfoTool.layersInfo=new LayersInfo();
      this.message="Consultando dados...";
      this.ctrlLoading=true;
      this.featureInfoTool.map.eachLayer( (l:any)=>{
        
          this.featureService.getFeatureInfo(this.featureInfoTool.ll, l, this.featureInfoTool.map)
          .then(featureInfo => {
            if(featureInfo && featureInfo.features.length>0)
            {
              this.formatPropertiesValues(featureInfo);
              this.featureInfoTool.layersInfo.layersFeatures.push(featureInfo);
              this.message="";
              this.ctrlLoading=false;
            }
          }).catch(error => {
            this.message="Ops, algo não ocorreu como o esperado com o serviço de informações do mapa.";
            this.ctrlLoading=false;
            console.log(error);
          });
        }
      );
    }
  }
  /**
   * Função para formatar dados do feature info. 
   * @param featureInfo 
   */
  private formatPropertiesValues(featureInfo: FeatureInfo)
  {
    for (let i = 0; i < featureInfo.features.length; i++) {
      let feature = featureInfo.features[i];
      for (let j = 0; j < feature.properties.length; j++)
       {
         //Formatando o index em imagens com indice gray 32bits, com 2 casas decimais
        if(feature.properties[j].key=='GRAY_INDEX')
        {
          if(feature.properties[j].value)
          {
            let value: number = parseFloat(feature.properties[j].value=featureInfo.features[i].properties[0].value.toString());
            feature.properties[j].value=featureInfo.features[i].properties[0].value=value.toFixed(2);
          }
          
          featureInfo.features[i].properties[0].key
        }
       }
      
      
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptionTool.unsubscribe();
  }
  
}
