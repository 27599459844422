import { Copyadditionalimoveldata, EditionoperationsInner, Historicoprocessoimovel, HistoricoprocessoimovelInner } from "@funcate/sigweb-cti-api";
import { Imoveisrelations } from "@funcate/sigweb-cti-api/model/imoveisrelations";
import { Collection, Feature } from "ol";
import GeoJSON from 'ol/format/GeoJSON';
import Polygon from "ol/geom/Polygon";
import VectorSource from "ol/source/Vector";
import { DialogComponent } from "src/app/map/dialog/dialog.component";
import { GenericConfirmationDialogComponent } from "src/app/map/dialog/generic-confirmation-dialog/generic-confirmation-dialog.component";
import { LayersConfigService } from "src/app/map/layers/layers.config.service";
import { Inscricao } from "src/app/map/vo/inscricao";
import { NewImovelLink } from "src/app/map/vo/new-imovel-link";
import { Operation } from "src/app/map/vo/operation";
import { BackendService } from "src/app/service/backend.service";
import { FeatureService } from "src/app/service/feature.service";
import { GeometryService } from "src/app/service/geometry.service";
import { ToastService } from "src/app/service/toast.service";
import { Step } from "../../../vo/step";
import { EditHistoricoComponent } from "../../property-registration/edit-historico/edit-historico.component";
import { AddInteraction } from "../../tools-utils/custom-edition-interaction/interaction/add-interaction";
import { SelectInteraction } from "../../tools-utils/custom-edition-interaction/interaction/select-interaction";
import { SetupImoveisLinkToolComponent } from "../setup-imoveis-link-tool.component";

export class DesdobroSearchInscricaoSpatilizeStep extends Step
 {
    private confirmDialogRef: any;
    private selectedFeatures: Collection<Feature<any>>;
    private sequentialNewFeatureIndex : number;
    private geoJSONDefaultSRID = '4326'
    addHistoricoDialog: any;
    private historicoProcesso: Historicoprocessoimovel;

    constructor(index: number, private setupImoveisLinkToolComponent: SetupImoveisLinkToolComponent, private toastService: ToastService, 
      private backendService: BackendService, private layersConfigService: LayersConfigService, private featureService: FeatureService)    {       
        super(index);
        this.setupImoveisLinkToolComponent=setupImoveisLinkToolComponent;
        this.setTitle("Passo 2: Indicar e espacializar inscrições");
        this.setDescription("Buscar e indicar inscrições cadastrais \"ativadas\" e espacializar cada uma delas.");
        this.selectedFeatures=new Collection<Feature<any>>();
        this.sequentialNewFeatureIndex=0;
    }
    execute()
    {
      let layers = [];
      layers.push(this.setupImoveisLinkToolComponent.olImoveisLayer);
      var selectInteraction = new SelectInteraction(this.setupImoveisLinkToolComponent.selectInterectionId,{
        layers: layers,
        features: this.selectedFeatures,
        currentStep: this,
        style: this.setupImoveisLinkToolComponent.imoveisSelectedStyleWithLabelFunction
      });

      //SELECT
      this.setupImoveisLinkToolComponent.customEditionController.addInteraction(this.setupImoveisLinkToolComponent.selectInterectionId,selectInteraction);
      //this.editEdificacoesToolComponent.customEditionController.enableInteraction(this.editEdificacoesToolComponent.edificacoesSelectInterectionId);

      //DRAW
      const addInteraction = new AddInteraction(this.setupImoveisLinkToolComponent.addInterectionId, {currentStep: this, type: 'MultiPoint', source: this.setupImoveisLinkToolComponent.olImoveisLayer.getSource()});
      this.setupImoveisLinkToolComponent.customEditionController.addInteraction(this.setupImoveisLinkToolComponent.addInterectionId,addInteraction);

      //DEFAULT Initial Interaction
      //this.setupImoveisLinkToolComponent.enableEditionInteraction(this.setupImoveisLinkToolComponent.edificacoesModifyInterectionId);
    }
    callback(object: any)
    {
      if(object.edificacoesChanged)
      {
        this.updateFeatureSelection(object.selectedFeature);
      }
      else
      {
        if(object.enableInteractionId)
        {
           this.enableInterection(object.enableInteractionId)
        }
        
      }  
    }
    rollback()
    {

    }
    finish()
    {
        if(this.valid())
        {
          this.confirm();
        }
    }
    valid()
    {
      //Pelo menos 2 novos imoveis
      if(this.setupImoveisLinkToolComponent.desdobroNewImoveisLink.length>=2)
      {
        //Todos imoveis devem possuir Geometria
        for (let i = 0; i < this.setupImoveisLinkToolComponent.desdobroNewImoveisLink.length; i++) 
        {
          const newImovelLink = this.setupImoveisLinkToolComponent.desdobroNewImoveisLink[i];
          if(newImovelLink.getFeature()==null)        
            {
              this.toastService.error("Para continuar todas as novas inscrições devem possuir um ponto georeferenciado no mapa.","Erro");
              return false;
            }                  
        }      
        return true;      

      }
      this.toastService.error("Para continuar é necessário adicionar pelo menos 2 novas inscrições.","Erro");
      return false;
    }

    private enableInterection(interactionId)
    {
    
      this.setupImoveisLinkToolComponent.currentEditionInteraction=null;
     if(interactionId==this.setupImoveisLinkToolComponent.addInterectionId)
      {
        this.setupImoveisLinkToolComponent.customEditionController.disableInteraction(this.setupImoveisLinkToolComponent.selectInterectionId);
        this.setupImoveisLinkToolComponent.customEditionController.disableInteraction(this.setupImoveisLinkToolComponent.deleteInterectionId);
        this.setupImoveisLinkToolComponent.customEditionController.enableInteraction(this.setupImoveisLinkToolComponent.addInterectionId);
      }
      else if(interactionId==this.setupImoveisLinkToolComponent.deleteInterectionId)
      {
        
        this.deleteCurrentFeature();

      } else if(interactionId==this.setupImoveisLinkToolComponent.selectInterectionId)
      {
        this.setupImoveisLinkToolComponent.customEditionController.disableInteraction(this.setupImoveisLinkToolComponent.selectInterectionId);
        this.setupImoveisLinkToolComponent.customEditionController.disableInteraction(this.setupImoveisLinkToolComponent.addInterectionId);
        this.setupImoveisLinkToolComponent.customEditionController.enableInteraction(this.setupImoveisLinkToolComponent.selectInterectionId);
      }
      
      
    }

    private updateFeatureSelection(selectedFeature: NewImovelLink)
    {
      this.selectedFeatures.clear();
      if(selectedFeature)
      {
        this.selectedFeatures.push(selectedFeature.getFeature());
      }
      this.setupImoveisLinkToolComponent.setCurrentSelectedFeature(selectedFeature);
    }

    public onFeatureSelected(selectedFeature: Feature<any>)
    {
      this.setupImoveisLinkToolComponent.desdobroNewImoveisLink.forEach(newImovelLink => {
        if(newImovelLink.getFeature()==selectedFeature)
        {
          this.setupImoveisLinkToolComponent.setCurrentSelectedFeature(newImovelLink);  
        }        
      });
      
    }

    private deleteCurrentFeature()
    {
       let currentSelectedFeature = this.setupImoveisLinkToolComponent.getCurrentSelectedFeature();
      
       if(currentSelectedFeature)
       {
         this.confirmDialogRef = this.setupImoveisLinkToolComponent.dialog.open(DialogComponent, {
           height: '30%',
           width: '20%',
           data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente apagar a inscrição selecionada?" }
         });
  
         this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
           if(confirmResult)
           {
            //Criando novo array de features
            let deletedNewImoveisLink : NewImovelLink[]  = []    
            let index = 1;        
            this.setupImoveisLinkToolComponent.desdobroNewImoveisLink.forEach(newImovelLink => {
              if(newImovelLink != currentSelectedFeature)
              {
                deletedNewImoveisLink.push(newImovelLink);
                newImovelLink.setIndex(index);
                newImovelLink.getFeature().set('index', index);
                index++;
              }
            });

            //Remove Geometry
            let edificacoesSource : VectorSource<Polygon> = this.setupImoveisLinkToolComponent.olImoveisLayer.getSource();            
            edificacoesSource.removeFeature(currentSelectedFeature.getFeature()); 

            this.setupImoveisLinkToolComponent.desdobroNewImoveisLink = deletedNewImoveisLink;            
          }
          else
          {
            this.setupImoveisLinkToolComponent.currentEditionInteraction=null;
          }
        });
      }      
    }
    public onFeatureInserted(feature: Feature<any>)
    {
      if(feature)
      {
        let geom = feature.getGeometry().clone();

        feature.setProperties(this.setupImoveisLinkToolComponent.currentFeature.getProperties());

        let imoveisICAttribute = this.layersConfigService.getLayerAppConfig(this.setupImoveisLinkToolComponent.imoveisLayer, this.setupImoveisLinkToolComponent.imoveisICAttributeConfig);          

        feature.setGeometry(geom);

        //Setting default new attribute id
        feature.set(this.setupImoveisLinkToolComponent.imoveisIdAttribute, "*");

        feature.set('index', this.setupImoveisLinkToolComponent.getCurrentSelectedFeature().getIndex());

        let ic = this.setupImoveisLinkToolComponent.imovelToInscricaoCompleta(this.setupImoveisLinkToolComponent.getCurrentSelectedFeature().getImovel());
        let setor = this.setupImoveisLinkToolComponent.getCurrentSelectedFeature().getImovel().setor;
        let quadra = this.setupImoveisLinkToolComponent.getCurrentSelectedFeature().getImovel().quadra;
        let logradouro = this.setupImoveisLinkToolComponent.getCurrentSelectedFeature().getImovel().logradouro;
        let lote = this.setupImoveisLinkToolComponent.getCurrentSelectedFeature().getImovel().lote;
        let sublote = this.setupImoveisLinkToolComponent.getCurrentSelectedFeature().getImovel().sublote;

        feature.set(imoveisICAttribute, ic);
        feature.set("setor", setor);
        feature.set("quadra", quadra);
        feature.set("logradouro", logradouro);
        feature.set("lote", lote);
        feature.set("sublote", sublote);

        this.setupImoveisLinkToolComponent.getCurrentSelectedFeature().setFeature(feature);

        this.enableInterection(this.setupImoveisLinkToolComponent.selectInterectionId);

      }
    }

    private confirm()
    { 
          this.confirmDialogRef = this.setupImoveisLinkToolComponent.dialog.open(DialogComponent, {
            height: '30%',
            width: '20%',
            data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente salvar as modificações selecionadas?" }
          });
    
          this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
            if(confirmResult)
            {

                this.commitEditionChanges();
            }

          });        
    }

    private commitEditionChanges()
    {
      let operations : Array<EditionoperationsInner> = []      

      let layerTable = this.layersConfigService.getLayerTable(this.setupImoveisLinkToolComponent.imoveisLayer);
    
      let layerGeomAttribute = this.layersConfigService.getLayerGeomAttribute(this.setupImoveisLinkToolComponent.imoveisLayer);
  
      let layerIdAttribute = this.setupImoveisLinkToolComponent.imoveisIdAttribute;
  
      this.setupImoveisLinkToolComponent.desdobroNewImoveisLink.forEach(newImoveisLink => 
      {
        let feature = newImoveisLink.getFeature();

        let idValue = this.setupImoveisLinkToolComponent.getAttributeValue(feature, layerIdAttribute);

        let format = new GeoJSON();

        let geometry = feature.getGeometry();
        if(geometry.getType()=="Point")
        {
          geometry = GeometryService.convertPointToMultiPoint(geometry);
        }          

        let geoJSON = format.writeGeometry(geometry, {featureProjection: "EPSG:"+this.setupImoveisLinkToolComponent.inputFeatureSRID});


        let currentOperation : EditionoperationsInner = new Object();

        //Verificar se a feição é uma das novas
        if(feature.get("index")!=this.setupImoveisLinkToolComponent.imovelDesativadoLabelKey)
        {
          currentOperation.operationtype=1;    
        }
        else
        {
          currentOperation.operationtype=3;
        }

        let newProperties = [];    

       let properties = this.featureService.convertGJSONPropertiesToPropertyList(feature.getProperties());      

       let imoveisICAttribute = this.layersConfigService.getLayerAppConfig(this.setupImoveisLinkToolComponent.imoveisLayer, this.setupImoveisLinkToolComponent.imoveisICAttributeConfig);          

        properties.forEach(property => {
          if(property.key==imoveisICAttribute
            ||property.key=="setor"
            ||property.key=="quadra"
            ||property.key=="logradouro"
            ||property.key=="lote"
            ||property.key=="sublote")
          {
            newProperties.push(property);
          }
        });

        currentOperation.table=layerTable;
        currentOperation.geomattribute=layerGeomAttribute;
        currentOperation.geom=geoJSON;
        currentOperation.idattribute=layerIdAttribute;
        currentOperation.idvalue=idValue.toString();
        currentOperation.srid=this.geoJSONDefaultSRID;
        currentOperation.properties=newProperties;

        operations.push(currentOperation);
                      
                 
      });

      //Delete old
      let idValue = this.setupImoveisLinkToolComponent.getAttributeValue(this.setupImoveisLinkToolComponent.currentFeature, layerIdAttribute);

      let deleteOperation : EditionoperationsInner = new Object();
      deleteOperation.operationtype=3;
      deleteOperation.table=layerTable;
      deleteOperation.geomattribute=layerGeomAttribute;
      deleteOperation.idattribute=layerIdAttribute;
      deleteOperation.idvalue=idValue.toString();
      deleteOperation.srid=this.geoJSONDefaultSRID;
      operations.push(deleteOperation);


     
      this.backendService.executeEditionOperations(operations).then((operations)=>
      {

        this.linkImovelToNewImovelId(operations);      

        this.commitLinkChanges();

      }).catch((exception: any)=>
      {
        this.setupImoveisLinkToolComponent.toastService.error(exception.errorMessage, "Erro");
        //this.rollbackHistorico();
        console.log(exception);
      });

    }

    private linkImovelToNewImovelId(operations: any[])
    {
      let imoveisICAttribute = this.layersConfigService.getLayerAppConfig(this.setupImoveisLinkToolComponent.imoveisLayer, this.setupImoveisLinkToolComponent.imoveisICAttributeConfig);          
      //Setting newIds on features
      operations.forEach(operation => 
        {
          //Localizando a ligação imovel_id -> ic
          if(operation.operationtype==1)
          {
            let newId = operation.sucess;

            let operationIC = this.featureService.getPropertyByKey(operation.properties, imoveisICAttribute).value;
            
            this.setupImoveisLinkToolComponent.desdobroNewImoveisLink.forEach(newImovelLink => {
              let imovelIC = this.setupImoveisLinkToolComponent.imovelToInscricaoCompleta(newImovelLink.getImovel());

              if(operationIC==imovelIC)
              {
                //Achou o imovel correspondente ao imovel id inserido.
                newImovelLink.getFeature().set(this.setupImoveisLinkToolComponent.imoveisIdAttribute, newId);            
              }
            });
          }            
      });
    }

    private updateImoveisRelation()
    {
      let imoveisRelations: Imoveisrelations = [];

      let newImoveisLink : NewImovelLink[] = this.setupImoveisLinkToolComponent.desdobroNewImoveisLink;

      newImoveisLink.forEach(newImovelLink => 
        {
        let ic = this.setupImoveisLinkToolComponent.imovelToInscricaoCompleta(newImovelLink.getImovel());
        let imovelRelation = new Object();
        imovelRelation['inscricao'] = ic;
        let imovelId = this.setupImoveisLinkToolComponent.getAttributeValue(newImovelLink.getFeature(), this.setupImoveisLinkToolComponent.imoveisIdAttribute);

        imovelRelation['imovel_id'] = imovelId;

        imoveisRelations.push(imovelRelation);
        
      });

      this.backendService.updateImoveisRelation(imoveisRelations).then((updatedImoveisRelations)=>
      {
        console.log(updatedImoveisRelations);
        
        this.addHistorico(newImoveisLink);
      });
    }

    private commitLinkChanges()
    {

      let newImoveisLink : NewImovelLink[] = this.setupImoveisLinkToolComponent.desdobroNewImoveisLink;

      let copyadditionalimoveldata: Copyadditionalimoveldata = new Object();
      copyadditionalimoveldata['inscricoes_origem']=[];

      copyadditionalimoveldata['inscricoes_origem'].push(this.setupImoveisLinkToolComponent.currentInscricao);      

      copyadditionalimoveldata['inscricoes_destino']=[];  

      newImoveisLink.forEach(newImovelLink => 
      {
        let ic = this.setupImoveisLinkToolComponent.imovelToInscricaoCompleta(newImovelLink.getImovel());
        copyadditionalimoveldata['inscricoes_destino'].push(ic);
      });      

      this.backendService.copyAdditionalImovelData(copyadditionalimoveldata).then(()=>
      {
        this.toastService.sucess("Desdobramento concluído e vínculos de imóveis criados.", "Sucesso");

        this.updateImoveisRelation();
        
      }).catch((exception)=>
      {
        //this.rollbackHistorico();
      });
    }

    private addHistorico(newImoveisLink : NewImovelLink[])
    {  
      if(newImoveisLink && newImoveisLink.length>0)
      {
        let icList : Inscricao[]= [];

        
        newImoveisLink.forEach(newImovelLink => 
        {
          let icStr = this.setupImoveisLinkToolComponent.imovelToInscricaoCompleta(newImovelLink.getImovel());

          let ic =  new Inscricao(icStr);

          icList.push(ic);
          
        });  

        let item : HistoricoprocessoimovelInner = new Object;
  
        this.addHistoricoDialog = this.setupImoveisLinkToolComponent.dialog.open(DialogComponent, {
          height: '60%',
          maxHeight: '60%',
          maxWidth: '80%',
          data: { component: EditHistoricoComponent, title: "Adicionar histórico de processo para o imóvel desdobrado", historico: item, icList: icList, parent: this  }
        });
    
        this.addHistoricoDialog.afterClosed().toPromise().then(()=>{
          
        });
      }
      else
      {
        this.toastService.error("Esse lote não possui inscrição associada, sendo assim, não é possível realizar o desmebramento, nem a criação do histório de processo.", "Erro ao Salvar")
      }  
  
    }
  
    //Após fechar a adição do historico do processo, se voltar um o ID, passamos pro proxima passo e guardamos o ID para caso de precisar voltar atrás
    public closeEditHistorico(historicoProcesso: Historicoprocessoimovel)
    {
      if(historicoProcesso.length>0)
      {
        if(historicoProcesso[0].id)
        {
          if(this.addHistoricoDialog)
          {
            this.addHistoricoDialog.close();

            this.historicoProcesso = historicoProcesso;

            this.setupImoveisLinkToolComponent.tool.close();
          }
        }
      }      
    }
    


}
