import { FormControl, FormGroup } from "@angular/forms";
import { Importlayercheckinforesult } from "@funcate/sigweb-cti-api";
import { BackendService } from "src/app/service/backend.service";
import { ToastService } from "src/app/service/toast.service";
import { Step } from "../../../../vo/step";
import { ImportLayerComponent } from "../import-layer.component";

export class ImportLayerCheckDataStep extends Step
 {
  
    fileUploadControl: FormGroup;
    public currentSLDFilename: string;
    isTableNameValid: boolean;
    
    constructor(index: number, private importLayerComponent: ImportLayerComponent, private toastService: ToastService, private backendService: BackendService)
    {       
        super(index);        
        this.setTitle("Passo 3: Revisão da camada submetida e definição dos parâmetros e propriedades.");
        this.setDescription("Revisão do conteúdo do arquivo submetido, incluindo projeção, nome, quantidade de feições, propriedades e opcionalmente propriedades utilizadas no estilo. Defina o nome da camada/tabela em banco e verifique se as propriedades da camada estão nos padrões esperados.");
        this.currentSLDFilename="";

        this.fileUploadControl = new FormGroup(
          {
            file: new FormControl()
          }
        );    
         
    }
    execute()
    {
      let layerName = this.importLayerComponent.importlayerfileinfo.layername.toLowerCase();
      layerName = layerName.replace(/[^a-z0-9_]/gi, "");     
      this.importLayerComponent.importlayerfileinfo.layername = layerName;
      this.isTableNameValid = false;
      this.checkIfTableNameExists(layerName);
    }
    callback(object: any)
    {

    }
    rollback()
    {
    }
    finish()
    {
         
    }
    valid()
    {
      return this.isTableNameValid;
    }  
    onFocusOutEvent(event: any)
    {
      this.checkIfTableNameExists(event.target.value);       
   
   }
   checkIfTableNameExists(value)
   {
    this.backendService.checkLayerImportInfo(value).then((result: Importlayercheckinforesult)=>{

      if(result['layername_valid']==false)
      {
        this.toastService.warning("Nome da camada inválida, altere e tente novamente.", "Aviso");
        this.isTableNameValid = false;
      }
      else
      {
        if(result['table_exists']==true || result['geoserver_layer_exists']==true)
        {
          this.toastService.warning("Nome da camada já existe no servidor, favor alterar.", "Aviso");
          this.isTableNameValid = false;
        }
        else
        {
          this.toastService.sucess("Nome da camada aprovado para importação.", "Aviso");
          this.isTableNameValid = true;
          this.importLayerComponent.importlayerfileinfo.layername=value
        }
      }
     });   
   }
    public isValidFile()
    {
      if(this.fileUploadControl.get('file'))
      {
        return true;
      }
      return false;
    }
}

