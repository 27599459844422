import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { Fotosfachada } from '@funcate/sigweb-cti-api';
import { DialogComponent } from 'src/app/map/dialog/dialog.component';
import { GenericConfirmationDialogComponent } from 'src/app/map/dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { Inscricao } from 'src/app/map/vo/inscricao';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { BackendService } from 'src/app/service/backend.service';
import { GeneralService } from 'src/app/service/general.service';
import { ToastService } from 'src/app/service/toast.service';
import { environment } from 'src/environments/environment';
import { Tool } from '../tool';
import { ToolsService } from '../tools.service';
import { FotosfachadaEditComponent } from './fotosfachada-edit/fotosfachada-edit.component';
import { FotosFachadaManagerTool } from './fotosfachada-manager-tool';

@Component({
  selector: 'app-fotosfachada-manager',
  templateUrl: './fotosfachada-manager.component.html',
  styleUrls: ['./fotosfachada-manager.component.css']
})
export class FotosfachadaManagerComponent implements OnInit {

  private inscricoes: Inscricao[];
  ic: Inscricao;
  isMultiple: boolean;
  selected: Inscricao[];
  fotosFachadas: Fotosfachada;
  sameNumeroPredial: boolean;
  currentNumeroPredial: string;
  currentSelectAllState: boolean;
  @ViewChild('fotosfachadas') selectionList: MatSelectionList;
  fotoFachadaURL: string = environment.BACKEND_API_BASE_PATH + "/cadastro/fotofachada/get/";
  fotosFachadasEditDialog: any;
  parent: any;
  confirmDialogRef: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private toastService: ToastService, private backendService: BackendService, 
  private toolsService: ToolsService, private authenticationService: AuthenticationService, private generalService: GeneralService ) 
  { 
    this.inscricoes = this.data.icList;
    this.parent = this.data.parent;
    if(this.inscricoes && this.inscricoes.length>0)
    {
      if(this.inscricoes.length==1)
      {
        this.isMultiple = false;
        this.ic = this.inscricoes[0];
        this.sameNumeroPredial = true;            
      }
      else
      {
        this.isMultiple = true;

      }
      this.selected=[];
      
    }
    this.fotosFachadas=[];
    this.currentSelectAllState=false;
  }

  ngOnInit(): void 
  {

    for (let i = 0; i < this.inscricoes.length; i++) {
      const ic = this.inscricoes[i];
      this.selected.push(ic);
      if(this.currentNumeroPredial!=null)
      {
        let numeroPredial = ic.setor+ic.quadra+ic.logradouro+ic.lote
        if(numeroPredial != this.currentNumeroPredial)
        {
          this.toastService.error("Números prediais distintos selecionados. Para realizar uma consulta de multiplas inscrições, tenha certeza de ter escolhido Setor, Quadra, Logradouro e Lote identicos.","Falha na seleção de fotos de fachadas.");
          this.close();
        }
      }
      else
      {
        this.currentNumeroPredial=ic.setor+ic.quadra+ic.logradouro+ic.lote;
      }
    }
    this.loadFotosFachada();
  }

  private loadFotosFachada()
  {
    if(this.currentNumeroPredial)
    {
      let ic = this.currentNumeroPredial;

      if(this.isMultiple == false)
      {
        ic = this.ic.getInscricaoCompleta();
      }

      this.backendService.getFotosFachada(ic)
      .then((fotos: Fotosfachada)=>{

        this.fotosFachadas = [];

        this.inscricoes.forEach(inscricao => 
        {
          let selectIc = inscricao.getInscricaoCompleta();
          fotos.forEach(foto => {
          
            if(foto.ic==selectIc)  
            {
              this.fotosFachadas.push(foto);
            }
          
          });
          
        });
        
        // fotos.forEach(foto => {
        //   let album : any = {};
        //   album.src=this.fotoFachadaURL+foto.id;
        //   album.caption="";
        //   this.albumFotosFachada.push(album);
        // });
  
        // this.message="";
        // this.ctrlLoading=false;
  
      }).catch((error)=>{
        this.toastService.error("Não foi possível buscar as fotos de fachadas no serviço de informações do cadastro imobiliário.","");
        console.log(error);
      }).finally(()=>{
      });
    }
    
  }

  toggleAllCheckbox()
  {
    if(this.currentSelectAllState==false)
    {
      this.selectionList.options.forEach((item: MatListOption) => item.selected = true);
      this.currentSelectAllState=true;
    }
    else
    {
      this.selectionList.options.forEach((item: MatListOption) => item.selected = false);
      this.currentSelectAllState=false;
    }

    
  }

  addFotosFachadas()
  {
    this.fotosFachadasEditDialog = this.parent.open(DialogComponent, {
      height: '60%',
      width: '60%',
      maxHeight: '60%',
      maxWidth: '80%',
      data: { component: FotosfachadaEditComponent, title: "Adicionar fotos de fachadas", parent: this, inscricoes: this.inscricoes, new: true }
    });

    this.fotosFachadasEditDialog.afterClosed().toPromise().then(()=>{
      this.loadFotosFachada();
  });
  }

  editFotosFachadas()
  {
    let fotosFachadas = this.getSelectedFotosFachadas();
    this.fotosFachadasEditDialog = this.parent.open(DialogComponent, {
      height: '60%',
      width: '60%',
      maxHeight: '60%',
      maxWidth: '80%',
      data: { component: FotosfachadaEditComponent, title: "Editar fotos de fachadas", parent: this, new: false, fotosFachadasSelecionadas: fotosFachadas }
    });

    this.fotosFachadasEditDialog.afterClosed().toPromise().then(()=>{
      this.loadFotosFachada();
  });
  }

  private deleteFotosFachadas(fotosFachadas: Fotosfachada)
  {

    for (let i = 0; i < fotosFachadas.length; i++) {
      let fotoFachada = fotosFachadas[i];
      if(fotoFachada['data_captura']==null)      
      {
        delete fotoFachada['data_captura'];      
      }      
    }

    this.backendService.deleteFotoFachada(fotosFachadas)
    .then(()=>{
      this.toastService.sucess("Fotos de fachadas apagadas.","Sucesso");
      this.loadFotosFachada();
    }).catch((error)=>{
      this.toastService.error("Não foi possível apagar as fotos de fachadas.","");
      console.log(error);
    }).finally(()=>{
    });
  }

  public confirmDelete()
  {
    let fotosFachadas = this.getSelectedFotosFachadas();
 
    this.confirmDialogRef = this.parent.open(DialogComponent, {
      height: '40%',
      width: '50%',
      data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente apagar as " + fotosFachadas.length + " fotos de fachadas selecionadas?" }
    });

    this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
      if(confirmResult)
      {
         this.deleteFotosFachadas(fotosFachadas);
      }

    });
  }


  isFotosFachadasSelected() : boolean
  {
    let show = false;
    if(this.selectionList)
    {
      this.selectionList.options.forEach((item: MatListOption) => 
      {
        if(item.selected==true)
        {
          show = true;
        }
      }
      );
    }    
    return show;
  }

  private getSelectedFotosFachadas() : Fotosfachada
  {
    let fotosFachadas: Fotosfachada = [];
    if(this.selectionList)
    {
      this.selectionList.options.forEach((item: MatListOption) => 
      {
        if(item.selected==true)
        {
          fotosFachadas.push(item.value);
        }
      }
      );
    }    
    return fotosFachadas;
  }

  onSelection(event) {
    console.log(event);
  }

  save ()
  {

  }

  close ()

  {
    
  }

  closeEditFotosDialog()
  {
    this.fotosFachadasEditDialog.close();
  }

  public formatDate(date: Date, format: string) : string
  {
    let data="";
    if(date)
    {
      let datePipe = new DatePipe('pt-BR');
      data = datePipe.transform(date.toString(), format, '+0300');
    } 

    return data;
  }

  public downloadFotoFachada(id:string, filename:string)
  {

    let url = this.fotoFachadaURL+id;
      let user = this.authenticationService.getCurrentUser();
      if(!user)
      {
        this.toastService.error("Usuário não está autenticado corretamente. Efetue o login novamente.", "Problemas ao baixar ficha do imovel.");
      }
      else
      {
        this.generalService.downloadFileGetMethod(url, filename, user.token);
      }
  }

}
