<div class="row fnc-scroll">
    <div class="col-md-12 layer-accordion">
        <mat-accordion >
            <mat-expansion-panel [expanded]="true" class="layer-expansion-panel">
                <mat-expansion-panel-header >
                    <mat-panel-title id="layer-header" class="layers-col">
                        Camadas de Sobreposição
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="layer-search">
                    <mat-form-field class="example-form-field">
                        <mat-label>Filtrar Camadas:</mat-label>
                        <input matInput type="text" [(ngModel)]="layerFilter" (ngModelChange)="layerFilterChanged()">
                        <button *ngIf="layerFilter" matSuffix mat-icon-button aria-label="Limpar" (click)="clearLayerFilter()">
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-form-field>
                </div>
                <div cdkDropList id="overlayer-list" [cdkDropListData]="overlayers" class="overlayer-list"
                    (cdkDropListDropped)="dropOverLayer($event)">
                    <div class="overlayer-placeholder" *cdkDragPlaceholder></div>
                    <ng-container *ngFor="let overlayer of overlayers">
                        <div class="overlayer-box" *ngIf="overlayer.isReadableForUser(authenticationService.getCurrentUser()) && overlayer.isHidden() == false" cdkDrag>
                            <div class="layer-row" cdkDragHandle>
                                <div class="layerNameIcon" >
                                    <mat-icon class="material-icons layer-icon">layers</mat-icon>
                                    <div class="layer-text">

                                        <h3 *ngIf="!overlayer.getApplicationconfigs()['onedition']"> {{overlayer.getName()}} </h3>
                                        <h3 *ngIf="overlayer.getApplicationconfigs()['onedition']==true" class="layer-onedition"> {{overlayer.getName()}} - Em Edição</h3>
                                        <p>
                                            <span> {{overlayer.title}} </span><mat-icon class="overlayer-description-icon" [matTooltip]="overlayer.getDescription()">help</mat-icon>
                                        </p>
                                    </div>
                                </div>  
                                <div class="layer-switch">
                                    <mat-slide-toggle [checked]="overlayer.isEnabled()" [name]="overlayer.getName()"
                                    [id]="overlayer.getId()" ([ngModel])="overlayer.isEnabled()" 
                                    (change)="onChangeOverlayer($event)" color="primary"></mat-slide-toggle>
                                </div>   
                            </div>
                            <mat-divider></mat-divider>                   
                            <div  class="layer-option-row cdk-drag-disabled" cdkDragDisabled>
                                <mat-accordion>
                                    <mat-expansion-panel [expanded]="false">
                                        <mat-expansion-panel-header >
                                            <mat-panel-title>
                                            </mat-panel-title>
                                            <mat-panel-description>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <div class="layer-options">
                                            <div class="layer-tools">
                                                <span *ngFor="let tool of getLayerTools(overlayer)" [ngClass]="tool.isVisible() ? 'tool-visible' : 'tool-novisible'">
                                                    <mat-icon *ngIf="tool.isVisible() && tool.isVisibleForLayer(overlayer)" [ngClass]="tool.isEnabled(overlayer) ? 'tool-on' : 'tool-off'" (click)="toogleTool(tool, overlayer)" title="{{tool.title}}" class="btn-icon" aria-hidden="false" aria-label="{{tool.title}}">{{tool.icon}}</mat-icon>                                        
                                                </span>
                                            </div>
    
                                            <div class="layer-opacity">
                                                <mat-slider thumbLabel
                                                [displayWith]="formatOpacityLabel"
                                                (change)="setOpacityForLayer(overlayer, $event)"
                                                tickInterval="1000"
                                                step="1"
                                                min="0"
                                                max="100" aria-label="units" value="100"></mat-slider>
                                            </div>

                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                            
                        </div>
                    </ng-container>
                </div>
                </mat-expansion-panel>
            
                <mat-expansion-panel class="layer-expansion-panel">
                    <mat-expansion-panel-header hideToggle>
                        <mat-panel-title class="base-col" id="base-header">
                            Camadas de Base
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div cdkDropList id="baselayer-list" [cdkDropListData]="baselayers" class="baselayer-list"
                        (cdkDropListDropped)="dropBaseLayer($event)">
                        <div class="baselayer-placeholder" *cdkDragPlaceholder></div>
                        <mat-radio-group>
                            
                            <div class="baselayer-box" *ngFor="let baselayer of baselayers" cdkDrag>
                                <div class="layerNameIcon">
                                    <mat-icon class="material-icons layer-icon">layers</mat-icon>
                                    {{baselayer.getTitle()}}
                                </div>
                                
                                <mat-radio-button [checked]="baselayer.isEnabled()" [name]="baselayer.getName()" [id]="baselayer.getId()" [value]="baselayer.getId()" (change)="onChangeBaselayer($event)" color="primary"></mat-radio-button>
                            </div>
                        </mat-radio-group>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

    </div>
</div>
