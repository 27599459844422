import {Property} from './property';

export class Feature {
    id: any;
    geom: any;    
    srs: string;
    properties=new Array<Property>();
    geoJSON: any;

    getGeometryJSON() : string
    {
        let geoJSON = JSON.stringify(this.geom);
        return geoJSON;
    }    
}
