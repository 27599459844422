import { AbstractDocumentType } from './abstract-document-type';
import { AbstractDocumentAuxiliarField } from './auxiliar-fields/abstract-document-auxiliar-field';
import { DocumentAuxiliarSelectField } from './auxiliar-fields/document-auxiliar-select-field';

export class SettlementPropertyCadSocialTitularDocumentType implements AbstractDocumentType
{
    type: number;
    constructor(type: number)
    {
        this.type = type;
    }
    getSupportedExtensions()
    {
        return ["jpg", "png", "tif", "pdf"]
    }
    getAuxiliarComponents() : AbstractDocumentAuxiliarField[]
    {
        let auxiliarComponents = [];

        let tipoDocumento = new DocumentAuxiliarSelectField();

        tipoDocumento.id="titular-tipo-documento"
        tipoDocumento.label = "Tipo:";
        tipoDocumento.options.push("CPF");
        tipoDocumento.options.push("CARTEIRA DE IDENTIDADE (RG)");
        tipoDocumento.options.push("COMPROVANTE DE RESIDÊNCIA");
        tipoDocumento.options.push("CERTIDÃO DE CASAMENTO");
        tipoDocumento.options.push("CERTIDÃO DE NASCIMENTO");

        auxiliarComponents.push(tipoDocumento);
        
        return auxiliarComponents;
    }
}