import { UserGroup } from './user-group';

export class BoundingBox
 {
    private minx: number;
    private miny: number;

    private maxx: number;
    private maxy: number;

    constructor(minx: number, miny: number, maxx: number, maxy: number)
    {
        this.minx = minx;
        this.miny = miny;
        this.maxx = maxx;
        this.maxy = maxy;
    }

    public getMinx(): number {
        return this.minx;
    }

    public setMinx(minx: number): void {
        this.minx = minx;
    }

    public getMiny(): number {
        return this.miny;
    }

    public setMiny(miny: number): void {
        this.miny = miny;
    }

    public getMaxx(): number {
        return this.maxx;
    }

    public setMaxx(maxx: number): void {
        this.maxx = maxx;
    }

    public getMaxy(): number {
        return this.maxy;
    }

    public setMaxy(maxy: number): void {
        this.maxy = maxy;
    }


    

}
