import { Component, OnInit } from '@angular/core';
import { LayersConfigService } from '../../layers/layers.config.service';
import { LayerConfig } from '../../layers/layers.config'
import { Subscription } from 'rxjs';
import { ToolsService } from '../tools.service';
import { GeneralService } from 'src/app/service/general.service';
import { DownloadFormat } from 'src/app/map/vo/download-format';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../../vo/notification';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { ToastService } from 'src/app/service/toast.service';
import { DatePipe } from '@angular/common';
import { Inscricao } from '../../vo/inscricao';


@Component({
  selector: 'app-export-sinter-tool',
  templateUrl: './export-sinter-tool.component.html',
  styleUrls: ['./export-sinter-tool.component.css']
})
export class ExportSinterToolComponent implements OnInit {

  overlayers: LayerConfig[]=[];
  downloadTool:any;
  subscriptionTool:Subscription;  
  downloadFormat: string;
  formats: Array<DownloadFormat>;
  notificationSubscrition: Subscription;
  downloading: boolean = false;
  exportSinterFormControl: FormGroup = new FormGroup(
    {
      setor: new FormControl(),
      quadra: new FormControl()
    }
  );
  setorLength: number = 2;
  quadraLength: number = 5;
  

  constructor(private toolsService: ToolsService,
    private generalService: GeneralService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private toastService: ToastService) 
  { 
    this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
      if (notification.event==AppNotification.ON_DOWNLOAD_FINISHED_EVENT)
      {
          console.log("Download from '"+notification.object.url+"' is done to out file '"+notification.object.outputFilename+"'.");
          this.downloading = false;
      }
      else if (notification.event==AppNotification.ON_DOWNLOAD_ERROR_EVENT)
      {
        this.toastService.error("Falha ao realizar o download.", "Erro")
        console.log("Erro ao realizar o download." + notification.object.error.message)
        this.downloading = false;
      }
  });
  }

  ngOnInit(): void 
  {
    this.init()
  }
  private init()
  {
    this.exportSinterFormControl.get('setor').valueChanges.subscribe(value => {
      value = this.generalService.removeNAN(value);
      let v = this.generalService.trimSize(this.setorLength, value);
      this.exportSinterFormControl.get('setor').setValue(v, { emitEvent: false} );
    });
    this.exportSinterFormControl.get('quadra').valueChanges.subscribe(value => {
     value = this.generalService.removeNAN(value);
     let v = this.generalService.trimSize(this.quadraLength, value);
     this.exportSinterFormControl.get('quadra').setValue(v, { emitEvent: false} );
   });
  }

  public download()
  {
    let setor = this.exportSinterFormControl.get('setor').value;
    let quadra = this.exportSinterFormControl.get('quadra').value;

    this.downloading = true;

    let data = this.formatDate(new Date(), "yyyyMMddHHmm")
  
    let outputFilename=environment.CODIGO_IBGE_SINTER + "_"+ data +".zip";

    let url = environment.BACKEND_API_BASE_PATH+'/export/sinter';
    
    if(!setor){
      setor=""
    }

    if(!quadra){
      quadra=""
    }

    url+="?setor="+setor;
    url+="&quadra="+quadra;

    let user = this.authenticationService.getCurrentUser();
    if(!user)
    {
      this.toastService.error("Usuário não está autenticado corretamente. Efetue o login novamente.", "Problemas ao exportar pacote Sinter.");
    }
    else
    {
      this.generalService.downloadFileGetMethod(url, outputFilename, user.token);
    }
  }
  public transfer()
  {
    this.toastService.warning("Em desenvolvimento.", "Atenção.");
  }
  public formatDate(date: Date, format: string) : string
  {
    let data="";
    if(date)
    {
      let datePipe = new DatePipe('pt-BR');
      data = datePipe.transform(date.toString(), format);
    }
 

    return data;
  }
  onPaste(event)
  {
    if(event.target &&
      (event.target.id='propertysearch-tool-setor') ||
      (event.target.id='propertysearch-tool-quadra') ||
      (event.target.id='propertysearch-tool-logradouro') ||
      (event.target.id='propertysearch-tool-lote') ||
      (event.target.id='propertysearch-tool-sublote'))
      {
        let clipboardData = event.clipboardData;

        if(clipboardData)
        {
          let clipboardText = clipboardData.getData("text")
          if(clipboardText)
          {
            //Tirando os pontos se existirem
            clipboardText=clipboardText.replaceAll(".", "");

            if(clipboardText.length==19)
            {
              let inscricao: Inscricao = new Inscricao(clipboardText);

              this.exportSinterFormControl.get('setor').setValue(inscricao.setor);
              this.exportSinterFormControl.get('quadra').setValue(inscricao.quadra);
              this.exportSinterFormControl.get('logradouro').setValue(inscricao.logradouro);
              this.exportSinterFormControl.get('lote').setValue(inscricao.lote);
              this.exportSinterFormControl.get('sublote').setValue(inscricao.sublote);

              this.onFormSubmit();
            }            
          }
        }
      }
  }

  public validateLength(field: string, value: any)
  {
    if(value.target.value)
    {
      let v: any;
      switch (field) {
        case 'setor':
          v = this.generalService.fillZero(2, value.target.value);
          this.exportSinterFormControl.get('setor').setValue(v, { emitEvent: false} );
          break;
        case 'quadra':
          v = this.generalService.fillZero(5, value.target.value);
          this.exportSinterFormControl.get('quadra').setValue(v, { emitEvent: false} );
          break;
        
        default:
          break;
      }
    }
  }

  public onFormSubmit() {
    
    let formValues=this.exportSinterFormControl.value;

    if(this.exportSinterFormControl.get('quadra').value)
    {
      if(!this.exportSinterFormControl.get('setor').value)
      {
        this.toastService.error("Favor preencher o setor.", "Erro")
        return;
      }
    }

    this.download();

    }

}
