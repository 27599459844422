import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BackendService } from '../service/backend.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  appVersion: any;
  appTitle: any;
  appDescription: any;
  apiVersions: any;

  constructor(private backendService: BackendService) { }

  ngOnInit(): void 
  {
    this.appTitle=environment.title;
    this.appVersion=environment.version;
    this.appDescription=environment.description;
    this.backendService.getApiVersion().then((versions)=>
    {
      this.apiVersions = versions;
    });
  }

}
