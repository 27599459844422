import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { ToolsService } from '../tools.service';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { LayerConfig } from '../../layers/layers.config';
import { Userinfo } from '@funcate/sigweb-cti-api';
import { Tool } from '../tool';
import { LayersConfigService } from '../../layers/layers.config.service';
import { FeatureService } from '../../../service/feature.service';
import { Property } from '../../vo/property';
import { ToastService } from 'src/app/service/toast.service';
import { GenerateCertidoesComponent } from './generate-certidoes.component';
import { CanvasService } from '../../canvas/canvas.service';
import GML3 from 'ol/format/GML3';
import GML2 from 'ol/format/GML2';
import GML32 from 'ol/format/GML32';
import GeoJSON from 'ol/format/GeoJSON';



export class CertidoesTool extends Tool {

    private dialog: MatDialog;

    cursorType=null;
    id='certidoes-tool';
    enabled=false;
    name='Gerar documentos Tool';
    title='Gerar documentos';
    type='click';
    map:L.Map=null;
    icon='description';
    ll: any;
    protected visible:boolean=true;
    layerTool: boolean=false;
    customParameter=new Map<String, any>();
    notificationSubscrition: any;
    lotesLayer: LayerConfig;
    imoveisLayer: LayerConfig;
    lotesIdAttribute: string;
    imoveisLoteIdAttribute: string;
    imoveisICAttribute: string;
    selectedLoteFeature: any;   


    private loteIdAttributeConfig: string = "lote_id_attribute";
    private imoveisICAttributeConfig: string = "inscricao_attribute";

    

    private dialogRef: any;

    constructor(dialog: MatDialog, toolsService: ToolsService, private layersConfigService: LayersConfigService,
         private featureService: FeatureService, private toastService: ToastService, private canvasService: CanvasService) 
    { 
        super(toolsService)
        this.dialog = dialog;
     

    }

    public execAction=(event:L.LeafletMouseEvent)=>{

        this.map=event.sourceTarget;
        this.ll=event.latlng;
        
        this.loadFeature();
    };

    public enableImpl()
    {
        let layer = this.layersConfigService.getCertidoesToolLayer();
        if(layer)
        {
            this.layersConfigService.enableLayer(layer);
        }


        this.lotesLayer = this.layersConfigService.getCertidoesToolLayer();

        this.imoveisLayer = this.layersConfigService.getCtiLayer();
       
        this.lotesIdAttribute = this.layersConfigService.getLayerIdAttribute(this.lotesLayer);

        this.imoveisLoteIdAttribute = this.layersConfigService.getLayerAppConfig(this.imoveisLayer, this.loteIdAttributeConfig);  
        
        this.imoveisICAttribute = this.layersConfigService.getLayerAppConfig(this.imoveisLayer, this.imoveisICAttributeConfig);          

        if(!this.lotesLayer
        || !this.imoveisLayer
        || !this.lotesIdAttribute
        || !this.imoveisLoteIdAttribute)
        {
            console.log("Disabling tool, tool configuration.");
            this.toastService.error("Não é possível usar a ferramenta de geração de documentos devido a falha de configuração.", "Desabilitando ferramenta.");
            this.disable();
        }  
    }
    public disableImpl()
    {
    }
    public closeDialog()
    {
        if(this.dialogRef)
        {
            this.dialogRef.close();
        }
    }   
    private loadFeature()
    {
        if(this.lotesLayer && this.imoveisLayer && this.lotesIdAttribute && this.imoveisLoteIdAttribute)
        {
            this.map.eachLayer( (l)=>{
                if(l["wmsParams"]) {
        
                    if(l["id"]==this.lotesLayer.getId())
                    {
                        this.featureService.getFeatureInfo(this.ll, l, this.map).then(featureInfo => {
            
                            if(featureInfo && featureInfo.features.length>0)
                            {
                              let selectedFeature=featureInfo.features[0];
            
                              let currentLoteId = this.featureService.getPropertyByKey(selectedFeature.properties, this.lotesIdAttribute);
            
                              if(currentLoteId)
                              {

                                let srs: string = featureInfo.features[0].srs;

                                this.loadLoteFeature(currentLoteId, srs);
                                 
                              }
                              else
                              {
                                console.log("Property "+ this.lotesIdAttribute +" not found...")
                              }
                        
                            }
                            else
                            {
                            console.log("Can't find any feature here...")
                            this.toastService.error("Não foi possível encontrar imóvel algum no local apontado.","");
                            }
                        
                        });
                    }
                }
            });
        }
    }
    /**
     * Busca feature da camada lotes (Usada para montar o box da imagem de certidão)
     * @param loteIdProperty 
     * @param srs 
     */
    private loadLoteFeature(loteIdProperty: Property, srs: string)
    {
        let attributesFilterList: Array<Array<Property>>=[];
        let attributeFilterList: Array<Property>=[];

        attributeFilterList.push(loteIdProperty);
        attributesFilterList.push(attributeFilterList);
          //Buscando lote feature
        this.featureService.getFeatures(this.lotesLayer, srs, attributesFilterList).then(lotesFeatures => 
        {                                
          if(lotesFeatures && lotesFeatures.length>0)
          {           

            this.selectedLoteFeature = lotesFeatures[0];

            

            if(loteIdProperty && loteIdProperty.value)
            {
                let currentLoteId = loteIdProperty.value.toString();
                this.loadImoveisFeatures(currentLoteId, srs);
            }
            else
            {
                console.log("Não foi possível buscar o id do lote")
            }
          }
        });
    }
    /**
     * Busca os imoveis com o loteId do lote selecionado
     * @param imoveisLayer 
     * @param loteId 
     */
    private loadImoveisFeatures(loteId: string, srs: string) 
    {
        let loteIdFilter :Property = new Property(this.imoveisLoteIdAttribute, loteId);

        let attributesFilterList: Array<Array<Property>>=[];
        let attributeFilterList: Array<Property>=[];

        attributeFilterList.push(loteIdFilter);
        attributesFilterList.push(attributeFilterList);
          //Buscando imoveis feature
        this.featureService.getFeatures(this.imoveisLayer, srs , attributesFilterList).then(imoveisFeatures => 
        {                                
          if(imoveisFeatures && imoveisFeatures.length>0)
          {
            let inscricoes: string[] = [];  
            for (let i = 0; i < imoveisFeatures.length; i++) {
                const imovelFeature = imoveisFeatures[i];  
                
                let properties : Array<Property> = this.featureService.getWFSPropertiesObjectAsPropertyArray(imovelFeature.properties);    
                let featureICProperty = this.featureService.getPropertyByKey(properties, this.imoveisICAttribute);                          

                if(featureICProperty && featureICProperty.value)
                {
                    inscricoes.push(featureICProperty.value.toString());
                }               
            }
            let currentFeatureSRID=srs.replace("EPSG:","");                                     
            this.showGenerateCertidoesDialog(inscricoes, this.selectedLoteFeature, currentFeatureSRID);
          }
          else
          {
              this.toastService.warning("Não foram encontrados imoveis para o lote.", "Aviso")
          }
        });
    }

    private showGenerateCertidoesDialog(inscricoes: string[], feature: any, srid: string)
    {

        this.dialogRef = this.dialog.open(DialogComponent, {
            height: '70%',
            width: '60%',
            data: { component: GenerateCertidoesComponent, title: "Gerar Documentos", inscricoes: inscricoes, feature: feature, featureSRID: srid }
        });

        let whenDialogIsOpen=this.dialogRef.afterOpened().toPromise();
        whenDialogIsOpen.then(
            ()=>{
                this.toolsService.dispatchTool(this);
            }
        );
    }


       
}
