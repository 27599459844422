import VectorLayer from "ol/layer/Vector";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import Vector from 'ol/source/Vector';
import { GeometryService } from "../../../../service/geometry.service";
import { Step } from "../../../vo/step";
import { EditEdificacoesToolComponent } from "../edit-edificacoes-tool.component";
import LineString from "ol/geom/LineString";
import Feature from "ol/Feature";
import { SegmentSelectionInteraction } from "../../tools-utils/custom-edition-interaction/interaction/segment-selection-interaction";

export class EditEdificacoesRecuoStep extends Step
 {

    private recuoFeatures: Feature<LineString>[];
    private selectedRecuoFeature: Feature<LineString>;
    private defaultRecuoStyle: Style;
    private selectedDefaultRecuoStyle: Style;

    constructor(index: number, private editEdificacoesToolComponent: EditEdificacoesToolComponent)
    {
        super(index);
        this.setTitle("Passo 2: Definir o recuo de referência");
        this.setDescription("Selecione o lado que deseja alterar o recúo padrão de referencia e digite a distância do limite desejado para aplicar.");
        this.recuoFeatures=[]

        this.defaultRecuoStyle = new Style({
          stroke: new Stroke({
            color: [0, 0, 0, 1],
            width: 3,
            lineDash: [4,8],
            lineDashOffset: 6
          })
        });

        this.selectedDefaultRecuoStyle = new Style({
          stroke: new Stroke({
            color: [0, 199, 146, 1],
            width: 4,
            lineDash: [4,8],
            lineDashOffset: 8
          })
        });
        
    }
    execute()
    {

        this.clearRecuoLayer();
        let recuoReferenceSource = new Vector();
        this.editEdificacoesToolComponent.recuoLayer = new VectorLayer({ source: recuoReferenceSource });
        this.editEdificacoesToolComponent.recuoLayer.setStyle(this.defaultRecuoStyle);

        this.loadRecuoFeatures();

        recuoReferenceSource.addFeatures( this.recuoFeatures );

       this.onUseRecuoChange();

    }

    onUseRecuoChange()
    {
      if(this.editEdificacoesToolComponent.useRecuo)
      {
        this.editEdificacoesToolComponent.customEditionController.addLayer(this.editEdificacoesToolComponent.recuoLayer);

        this.editEdificacoesToolComponent.customEditionController.addSegmentSelecionTool(this.editEdificacoesToolComponent.lotesLayer.getSource(), this.editEdificacoesToolComponent.currentLotesFeature);
        this.editEdificacoesToolComponent.customEditionController.addSnap(this.editEdificacoesToolComponent.lotesSnapInterectionId, this.editEdificacoesToolComponent.lotesLayer.getSource());
  
        this.editEdificacoesToolComponent.customEditionController.enableInteraction(SegmentSelectionInteraction.id);
        this.editEdificacoesToolComponent.customEditionController.enableInteraction(this.editEdificacoesToolComponent.lotesSnapInterectionId);

      }
      else
      {
        this.editEdificacoesToolComponent.customEditionController.disableInteraction(SegmentSelectionInteraction.id);
        this.editEdificacoesToolComponent.customEditionController.disableInteraction(this.editEdificacoesToolComponent.lotesSnapInterectionId);
        this.editEdificacoesToolComponent.customEditionController.removeLayer(this.editEdificacoesToolComponent.recuoLayer);
      }
    }
    clearRecuoLayer()
    {
      this.editEdificacoesToolComponent.setSelectedRecuoSegment(null);
      this.clearSelection();

      this.recuoFeatures=[];

      if(this.editEdificacoesToolComponent.recuoLayer)
      {
        this.editEdificacoesToolComponent.customEditionController.removeLayer(this.editEdificacoesToolComponent.recuoLayer);
        this.editEdificacoesToolComponent.recuoLayer=null;
      }
    }

    callback(object: any)
    {
      if(object instanceof SegmentSelectionInteraction)
      {
        this.clearSelection();
        let selectedSegment = object.getSelectedSegment();
        if(selectedSegment)
        {
          this.selectSegment(selectedSegment);
        }
      }
      else
      {
        //Botão aplicar recuo
        if(object && object.apply)
        {
          this.applyRecuo(Number(object.distance))
        }
      }
    }
    private clearSelection()
    {
      this.selectedRecuoFeature=null;
      this.editEdificacoesToolComponent.setRecuoDistance(null);
      if(this.recuoFeatures)
      {
        this.recuoFeatures.forEach(feature => {
          feature.setStyle(this.defaultRecuoStyle);
        });
      }
    }

    private applyRecuo(distance: number)
    {
        if(this.selectedRecuoFeature)
        {          
          let originSegment =  this.selectedRecuoFeature.get('originSegment');
          let parallelSegment = GeometryService.getParallelLine(originSegment, -distance);

          this.selectedRecuoFeature.setGeometry(parallelSegment);
          this.selectedRecuoFeature.set('distance', distance);
        }
    }

    private selectSegment(selectedSegment: LineString)
    {
      
      this.recuoFeatures.forEach(feature => {
        let originSegment = feature.get('originSegment');
        if(GeometryService.isEqualSegment(originSegment, selectedSegment))
        {
          let distance = feature.get('distance');
          feature.setStyle(this.selectedDefaultRecuoStyle);
          this.selectedRecuoFeature=feature;
          this.editEdificacoesToolComponent.setRecuoDistance(distance);
        }
      });
    }    

    private loadRecuoFeatures()
    {
      let frontSegment : LineString = this.editEdificacoesToolComponent.getFrontSelectedSegment();

      //getting each polygon segment as a LineString
      let segments: LineString[] = GeometryService.getPolygonSegments(this.editEdificacoesToolComponent.currentLotesFeature.getGeometry());

      //For each LineString creating a feature
      for (let i = 0; i < segments.length; i++) 
      {
        const segment = segments[i];
   
        let distance = 1.5
        if(GeometryService.isEqualSegment(frontSegment, segment))
        {
          distance = 5;
        }
        let parallelSegment = GeometryService.getParallelLine(segment, -distance);
        let parallelRecuoFeature : Feature<LineString> = this.editEdificacoesToolComponent.currentLotesFeature.clone();
        parallelRecuoFeature.set('segmentIndex', i);
        parallelRecuoFeature.set('distance', distance);
        parallelRecuoFeature.set('originSegment', segment);
        parallelRecuoFeature.setGeometry(parallelSegment);
        this.recuoFeatures.push(parallelRecuoFeature);
      }
      
    }

    rollback()
    {
      this.clearRecuoLayer();

      this.editEdificacoesToolComponent.customEditionController.disableInteraction(SegmentSelectionInteraction.id);
      this.editEdificacoesToolComponent.customEditionController.disableInteraction(this.editEdificacoesToolComponent.lotesSnapInterectionId);

      this.editEdificacoesToolComponent.customEditionController.removeInteraction(this.editEdificacoesToolComponent.recuoSnapInterectionId);
      this.editEdificacoesToolComponent.customEditionController.removeInteraction(SegmentSelectionInteraction.id);

      this.editEdificacoesToolComponent.customEditionController.resetRotation();
    }
    finish()
    {
      this.clearSelection();
    }

    valid()
    {
      return true;
    }
}
