import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/map/dialog/dialog.component';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { LayersInfo } from 'src/app/map/vo/layersinfo';
import { Feature } from '../../../vo/feature';
import { FetureInfoToolComponent } from '../../fetureinfo-tool/fetureinfo-tool.component';
import { SettlementTool } from '../../settlement-tools/settlement-tool';
import { SettlementComponent } from '../../settlement-tools/settlement.component';
import { AttributeSearchFeatureInfo } from './attribute-search-feature-info';

export class AttributeSearchSettlementInfo extends AttributeSearchFeatureInfo
{
    static customizedFeatureInfo: string = "attribute-search-settlement-info";

    constructor(parent: MatDialog,  tool: any, feature: Feature, layerConfig: LayerConfig)
    {
        super(parent, tool, feature, layerConfig);        
    }
    showFeatureInfo()
    {
        this.tool.currentFeature = this.feature;
        this.tool.layer = this.layerConfig;
        this.tool.openEditSettlement();       
    }
}
