import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Fichasfilter, Fichasresultado, FichasresultadoInner } from '@funcate/sigweb-cti-api';
import { Inscricao } from 'src/app/map/vo/inscricao';
import { BackendService } from 'src/app/service/backend.service';
import { FichaService } from 'src/app/service/ficha.service';
import { GeneralService } from 'src/app/service/general.service';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-ficha-link',
  templateUrl: './ficha-link.component.html',
  styleUrls: ['./ficha-link.component.css']
})
export class FichaLinkComponent implements OnInit 
{
  fichasPesquisadas: Fichasresultado;
  fichasDisplayedColumns: string[] = ['id', 'idLogradouro', 'nomeLogradouro', 'numeroOuComplemento', 'nomeArquivoCompleto', 'tipo','associada','acoes','selecao'];
  fichasSelecionadas : Map<String, FichasresultadoInner>;
  inscricao: Inscricao;
  parentDialog: any;

  fichaSearchControl: FormGroup = new FormGroup(
    {
      id: new FormControl(),
      logradouro: new FormControl(),
      nomeArquivoCompleto: new FormControl(),
      desassociadas: new FormControl()
    }
  );

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, 
  private fichaService: FichaService, private generalService: GeneralService, private toastService: ToastService, private backEndService: BackendService) 
  {
    this.inscricao=this.data.inscricao
    this.setLogradouro(this.data.idLogradouro);
    this.parentDialog = data.parentDialog;
  }

  ngOnInit(): void 
  {
    this.fichasSelecionadas = new Map<String, FichasresultadoInner>();
  }

  public getFicha(id:string, extension:string)
  {
    this.fichaService.getFicha(id, extension, this.dialog);
  }
  public getFichaIcon(ficha: any)
  {
    return this.fichaService.getFichaIcon(ficha);
  }
  public onFormSubmit() 
  {
    let id = this.fichaSearchControl.get('id').value;
    let logradouro = this.fichaSearchControl.get('logradouro').value;
    let nomeArquivoCompleto = this.fichaSearchControl.get('nomeArquivoCompleto').value;
    let desassociadas = this.fichaSearchControl.get('desassociadas').value;

    if(id || logradouro || nomeArquivoCompleto)
    {
      console.log(id);
      console.log(logradouro);
      console.log(nomeArquivoCompleto);
      this.loadFichas(id, logradouro, nomeArquivoCompleto, desassociadas);
    }
    else
    {
      this.toastService.error("Preencha pelo menos um parametro de pesquisa.", "Erro")
    }
  }
  private setLogradouro(logradouro: string)
  {
    this.fichaSearchControl.get('logradouro').setValue(logradouro);
  }

  public validateLength(field: string, value: any)
  {
    if(value.target.value)
    {
      if(field=='logradouro')
      {
        let v = this.generalService.fillZero(4, value.target.value);
        this.fichaSearchControl.get('logradouro').setValue(v, { emitEvent: false} );
      }
    }  

  }

  private loadFichas(id: string, logradouro: string, nomeArquivoCompleto: string, desassociadas: boolean)
  {
    let filtro: Fichasfilter = new Object();

    if(id)
    {
      filtro["id"] = id;
    }
    
    if(logradouro)
    {
      filtro["id_logradouro"] = logradouro;
    }

    if(nomeArquivoCompleto)
    {
      filtro["nome_arquivo_completo"] = nomeArquivoCompleto;
    }

    if(desassociadas)
    {
      filtro["somente_desassociadas"] = desassociadas;
    }

    this.backEndService.getFichasByFiltro(filtro).then((fichas: Fichasresultado)=>
    {
      this.fichasPesquisadas=fichas;
      this.fichasSelecionadas = new Map<String, FichasresultadoInner>();
    });
  }

  public clearFields(){
    this.fichaSearchControl.reset();
  }

  public onCheckboxChange(event: any, ficha: FichasresultadoInner)
  {
    let enabled: boolean = event.checked;
    if(this.fichasSelecionadas.has(ficha.id))
    {
      if(enabled==false)
      {
        this.fichasSelecionadas.delete(ficha.id);
      }
    }
    else
    {
      if(enabled==true)
      {
        this.fichasSelecionadas.set(ficha.id, ficha);
      }
    }
  }

  public salvarSelecionados()
  {
    if(this.fichasSelecionadas && this.fichasSelecionadas.size>0)
    {
      let fichas = [];
      this.fichasSelecionadas.forEach((ficha: any)=>{
        ficha.associada=true;
        fichas.push(ficha);
      });

      console.log(fichas);
      this.backEndService.setFichasForInscricao(fichas, this.inscricao.getInscricaoCompleta()).then((value: Fichasresultado) =>
      {
        this.toastService.sucess("Documentos associados com sucesso", "Sucesso")
        if(this.parentDialog)
        {
          this.parentDialog.closeNewFichaDialog();
        }
      }).catch(()=>
        {
          this.toastService.error("Não foi possível associar os documentos solicitadas.", "Erro")
        }
      );
      
    }
  }

}
