import { Draw, Modify, Select } from 'ol/interaction'
import VectorSource from 'ol/source/Vector';


export class AddInteraction extends Draw
{
    id: string;
    step: any;
    vectorSourceLayer: VectorSource<any>
    constructor(id:string, options)
    {
        super(options);
        this.id=id;
        this.step=options.currentStep;
        this.vectorSourceLayer=options.source;
        this.vectorSourceLayer.set('addinteraction', this);
        this.vectorSourceLayer.on('addfeature', function(event: any)
        {
            event.target.get('addinteraction').onFeatureInserted(event);
        });
    }
    
     public onFeatureInserted(event)
     {
        this.step.onFeatureInserted(event.feature);
    }
    
}



