<div id="body">
    <div id="left-div">
        <mat-card id="progresso">
            <div id="passos-div">
                <mat-card *ngFor="let step of stepsController.getSteps()" [ngClass]="step ==  stepsController.getCurrentStep() ? 'passo-selecionado' : ''">Passo {{step.index}}</mat-card>
            </div>
            <mat-progress-bar id="progress-bar" mode="determinate" [value]="progressBarPercent">
            </mat-progress-bar>
        </mat-card>
        <mat-card id="map-card">
            <div id="ol_map" class="map-container"></div>
        </mat-card>
    </div>
    <div id="right-div">
        <mat-card id="passos-descricao-card">
            <mat-card-title>{{stepsController.getCurrentStep().getTitle()}}</mat-card-title>
            <mat-card-content>{{stepsController.getCurrentStep().getDescription()}}</mat-card-content>
        </mat-card>

        <mat-card id="tools-card" *ngIf="isCardVisible('tools-card')">
            <div>
                <mat-icon title="Adicionar Feição" (click)="enableEditionInteraction(edificacoesAddInterectionId)" [ngClass]="currentEditionInteraction==edificacoesAddInterectionId?'edicaoEdificacoesToolEnabled':''">add</mat-icon>
                <mat-icon title="Duplicar Feição" (click)="enableEditionInteraction(edificacoesDuplicateInterectionId)" [ngClass]="currentEditionInteraction==edificacoesDuplicateInterectionId?'edicaoEdificacoesToolEnabled':''">content_copy</mat-icon>
                <mat-icon title="Modificar Feição" (click)="enableEditionInteraction(edificacoesModifyInterectionId)"  [ngClass]="currentEditionInteraction==edificacoesModifyInterectionId?'edicaoEdificacoesToolEnabled':''">edit</mat-icon>
                <mat-icon title="Mover Feição" (click)="enableEditionInteraction(edificacoesTranslateInterectionId)"  [ngClass]="currentEditionInteraction==edificacoesTranslateInterectionId?'edicaoEdificacoesToolEnabled':''">open_with</mat-icon>
                <mat-icon title="Apagar Feição" (click)="enableEditionInteraction(edificacoesDeleteInterectionId)" [ngClass]="currentEditionInteraction==edificacoesDeleteInterectionId?'edicaoEdificacoesToolEnabled':''">delete</mat-icon>
            </div>
            
        </mat-card>

        <mat-card id="feature-card" *ngIf="isCardVisible('feature-card')">
            <mat-card-subtitle>Edificações</mat-card-subtitle>
            <mat-card-content>
            <mat-radio-group aria-label="Edificação Selecionada:" >


              <table mat-table id="currentEdificacoesFeatures" [dataSource]="currentEdificacoesFeatures" class="mat-elevation-z8">
                
                <ng-container matColumnDef="edificacoes-radio">
                    <th mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element"> <mat-radio-button [value]="element" [checked]="element === selectedEditionFeature" (change)="onEdificacoesChange($event)"></mat-radio-button></td>
                 </ng-container>
                 
                 <ng-container matColumnDef="index">
                    <th mat-header-cell *matHeaderCellDef > Edificação </th>
                    <td mat-cell *matCellDef="let element; let i = index;">{{i+1}}</td>
                 </ng-container>                 
                         
                <!-- <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> Feição </th>
                    <td mat-cell *matCellDef="let element"> {{getAttributeValue(element, edificacoesIdAttribute)}}   </td>
                  </ng-container> -->

                  <ng-container matColumnDef="pavimento">
                    <th mat-header-cell *matHeaderCellDef> Pavimento </th>
                    <td mat-cell *matCellDef="let element"> 
                      
                      
                      <mat-select matNativeControl class="attribute-pavimento-input" 
                      (selectionChange)="onAttributeValueChanged($event, element, edificacoesPavimentosAttribute)" 
                      [value]="getAttributeValue(element, edificacoesPavimentosAttribute)"  
                      id="pavimento-{{i}}"
                      >
                        <option value=" " class="wrong-option"> {{getAttributeValue(element, edificacoesPavimentosAttribute)}} </option>
                        <mat-option *ngFor="let pavimento of edificacoesListaPavimentosValidos" [value]="pavimento">{{pavimento}}</mat-option>

                      </mat-select>

                      
                    
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="classe">
                    <th mat-header-cell *matHeaderCellDef> Classe </th>
                    
                    <td mat-cell *matCellDef="let element; index as i;"> 

                      <mat-select matNativeControl class="attribute-classe-input" 
                      (selectionChange)="onAttributeValueChanged($event, element, edificacoesClasseAttribute)" 
                      [value]="getAttributeValue(element, edificacoesClasseAttribute)"  
                      id="classe-{{i}}"
                      >
                        <option value=" " class="wrong-option"> {{getAttributeValue(element, edificacoesClasseAttribute)}} </option>
                        <mat-option *ngFor="let classe of edificacoesListaClassesValidas" [value]="classe">{{classe}}</mat-option>

                      </mat-select>
                    
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="aream2">
                    <th mat-header-cell *matHeaderCellDef> Área </th>
                    <td mat-cell *matCellDef="let element"> {{getAttributeValueNumberFixed(element, edificacoesAreaAttribute, 2)}} m2  </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="edificoesFeaturesTableRowsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: edificoesFeaturesTableRowsColumns; let i = index"></tr>
              </table>

            </mat-radio-group>
        </mat-card-content>
        <mat-card-footer> <mat-card class="total-area"><span>Área Total: <b> {{getNumberFixedValue(areaTotal, 2)}} m2</b></span></mat-card>  </mat-card-footer>
        </mat-card>

        <mat-card id="tabela-card"  *ngIf="isCardVisible('tabela-card') && edificacaoSegmentList && edificacaoSegmentList.length>0">
            <mat-card-subtitle>Segmentos</mat-card-subtitle>
            <mat-card-content>
            <div *ngIf="edificacaoSegmentList">
                <table mat-table id="edificacaoSegmentsTable" [dataSource]="edificacaoSegmentList" class="mat-elevation-z8">

                    <ng-container matColumnDef="segment">
                      <th mat-header-cell *matHeaderCellDef> Segmento. </th>
                      <td mat-cell *matCellDef="let element"> {{element.index}} </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="distance">
                        <th mat-header-cell *matHeaderCellDef> Tamanho (metros). </th>
                        <td mat-cell *matCellDef="let element"><input matInput class="segment-distance-input" [(ngModel)]="element.distance" (ngModelChange)="element.changed=true" step="0.01" min="0" type="number">  </td>
                      </ng-container>

                      <ng-container matColumnDef="angle">
                        <th mat-header-cell *matHeaderCellDef> Ângulo (Graus). </th>
                        <td mat-cell *matCellDef="let element"><input matInput class="segment-angle-input" [(ngModel)]="element.angle" (ngModelChange)="element.changed=true" step="0.01" min="0" type="number">  </td>
                      </ng-container>

                      <tr mat-header-row *matHeaderRowDef="edificaoSegmentsTableRowsColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: edificaoSegmentsTableRowsColumns;"></tr>
                  </table>
                  <div class="button-salvar-edificacao-segments">
                    <button mat-raised-button color="primary" class="aplicar-segmentos" (click)="applySegmentChanges()">Aplicar</button>
                  </div>
                  
            </div>
            </mat-card-content>


        </mat-card>      
        <mat-card id="recuo-card"  *ngIf="isCardVisible('recuo-card')">
            
            
                <div>
                  <div class="recuo-radio">
                    <mat-radio-group aria-label="Edificação Selecionada:">
                      <mat-radio-button value="true" [checked]="useRecuo === true" (change)="onUseRecuoChange($event, true)">
                        Ligado
                      </mat-radio-button>
                      <mat-radio-button value="false" [checked]="useRecuo === false" (change)="onUseRecuoChange($event, false)">
                        Desligado
                      </mat-radio-button>                        
                    </mat-radio-group>
                  </div>
                  <form class="recuo-form" *ngIf="useRecuo === true">
                    <div>
                      <mat-form-field>
                        <mat-label>Recuo (metros)</mat-label>
                        <input #inputRecuo matInput min="0" type="number" class="recuo-input" placeholder="Recuo em metros"
                          [value]="currentRecuoDistance" [disabled]="!currentRecuoDistance" step="0.1">
                      </mat-form-field>
                    </div>
                    <div>
                      <button mat-raised-button color="primary" class="aplicar-recuo" [disabled]="!validateRecuo(inputRecuo)"
                        (click)="applyRecuo(inputRecuo)">Aplicar</button>
                    </div>
                  </form>
                
                </div>

              
                
              
        </mat-card>

        <mat-card id="commit-edificacoes-features-card" *ngIf="isCardVisible('commit-edificacoes-features-card')">
          <mat-card-subtitle>Edificações</mat-card-subtitle>
          <mat-card-content>
          <section  >


            <table mat-table id="commit-edificacoes-features-table" [dataSource]="commitEdificacoesFeatures" class="mat-elevation-z8">
              
              <ng-container matColumnDef="commit-edificacoes-checkbox">
                  <th mat-header-cell *matHeaderCellDef> </th>
                  <td mat-cell *matCellDef="let element"> <mat-checkbox [value]="element" [checked]="true" (change)="onSelectedCommitEdificacoesChange($event)"></mat-checkbox></td>
               </ng-container>
               
               <ng-container matColumnDef="index">
                  <th mat-header-cell *matHeaderCellDef> Edificação </th>
                  <td mat-cell *matCellDef="let element; let i = index;">{{i+1}}</td>
               </ng-container>                 
                       
              <!-- <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef> Feição </th>
                  <td mat-cell *matCellDef="let element"> {{getAttributeValue(element, edificacoesIdAttribute)}}   </td>
                </ng-container> -->

                <ng-container matColumnDef="pavimento">
                  <th mat-header-cell *matHeaderCellDef> Pavimento </th>
                  <td mat-cell *matCellDef="let element"> {{getAttributeValue(element, edificacoesPavimentosAttribute)}} </td>
                </ng-container>

                <ng-container matColumnDef="classe">
                  <th mat-header-cell *matHeaderCellDef> Classe </th>
                  <td mat-cell *matCellDef="let element"> {{getAttributeValue(element, edificacoesClasseAttribute)}}</td>
                </ng-container>

                <ng-container matColumnDef="aream2">
                  <th mat-header-cell *matHeaderCellDef> Área </th>
                  <td mat-cell *matCellDef="let element"> {{getAttributeValueNumberFixed(element, edificacoesAreaAttribute, 2)}} m2  </td>
                </ng-container>

                <ng-container matColumnDef="situacao">
                  <th mat-header-cell *matHeaderCellDef> Situação </th>
                  <td mat-cell *matCellDef="let element"> <mat-icon [title]="getSituacaoFeature(element).title" [class]='getSituacaoFeature(element).icon'>{{getSituacaoFeature(element).icon}}</mat-icon>  </td>
                </ng-container>

                <ng-container matColumnDef="operacao">
                  <th mat-header-cell *matHeaderCellDef> Operação </th>
                  <td mat-cell *matCellDef="let element"> <mat-icon [title]="getEdificacaoOperacao(element).title" [class]='getEdificacaoOperacao(element).class'>{{getEdificacaoOperacao(element).icon}}</mat-icon>  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="commitEdificoesFeaturesTableRowsColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: commitEdificoesFeaturesTableRowsColumns; let i = index"></tr>
            </table>

          </section>
      </mat-card-content>
      </mat-card>

        <mat-card id="botoes-card"  *ngIf="isCardVisible('botoes-card')">
            <div class="botoes-div">
                <button mat-raised-button color="primary" class="voltar-recuo" (click)="back()">Voltar</button>
                
                <button *ngIf="!stepsController.isLastStep()" mat-raised-button color="primary" class="avancar-recuo" (click)="next()">Avançar</button>
                <button *ngIf="stepsController.isLastStep()" mat-raised-button color="primary" class="avancar-recuo" (click)="finish()">Finalizar</button>
            </div>
        </mat-card>    
        
    </div>
</div>
