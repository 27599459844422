import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Historicoprocessoimovel, HistoricoprocessoimovelInner } from '@funcate/sigweb-cti-api';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/service/backend.service';
import { EditionService } from 'src/app/service/edition.service';
import { FeatureService } from 'src/app/service/feature.service';
import { ToastService } from 'src/app/service/toast.service';
import { CanvasService } from '../../canvas/canvas.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { LayerConfig } from '../../layers/layers.config';
import { LayersConfigService } from '../../layers/layers.config.service';
import { EditHistoricoComponent } from '../property-registration/edit-historico/edit-historico.component';
import { ToolsService } from '../tools.service';

@Component({
  selector: 'app-merge-tool',
  templateUrl: './merge-tool.component.html',
  styleUrls: ['./merge-tool.component.css']
})
export class MergeToolComponent implements OnInit {

  mergeTool:any;
  subscriptionTool:Subscription;
  layerConfig: LayerConfig;
  baseLayer: LayerConfig;
  currentFeatureId1: any;
  currentFeatureId2: any;
  currentFeatureGeom1: string;
  currentFeatureGeom2: string;
  currentFeatureSRID: string;
  addHistoricoDialog: any;
  outGeom1Id: any;
  outGeom2Id: any;
  outGeomJSON: any;
  currentHistoricoId: string;
  

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private toolService: ToolsService, private featureService: FeatureService,
  private layerConfigService: LayersConfigService, private editionService: EditionService
 , private toastService: ToastService, private canvasService: CanvasService, private backendService: BackendService)
  {
    this.subscriptionTool=this.toolService.toolsObservable.subscribe(
      (mergeTool:any) => {
        this.mergeTool=mergeTool;
        this.update(this.data.currentFeature1, this.data.currentFeature2, this.data.featureSRID);
        console.log("Selecionadas geometrias para unificação");
      },()=> {
        console.log("Falhou ao selecionar as geometrias para unificação");
      }
    );
    
   }

   private update(currentFeature1: any, currentFeature2: any, currentSRID: any)
   {
     this.currentFeatureSRID=currentSRID;
    this.baseLayer=this.layerConfigService.getMergeToolBaseLayer();
    this.layerConfig = this.toolService.getToolParameter(this.mergeTool, "layer");
    this.processFeatures(currentFeature1, currentFeature2);
   }

  ngOnInit(): void 
  {

  }

  private processFeatures(currentFeature1: any, currentFeature2: any)
  {
    if(currentFeature1 && currentFeature2)
    {
      let layerIdAttribute = this.layerConfigService.getLayerIdAttribute(this.layerConfig);
      this.currentFeatureId1=this.getWFSPropertyByKey(currentFeature1.properties,layerIdAttribute);
      this.currentFeatureId2=this.getWFSPropertyByKey(currentFeature2.properties,layerIdAttribute);
      this.currentFeatureSRID=this.featureService.getDefaultWFSSRID();
      this.currentFeatureGeom1=JSON.stringify(currentFeature1.geometry);
      this.currentFeatureGeom2=JSON.stringify(currentFeature2.geometry);      
    }
  }

  private getWFSPropertyByKey(properties: any, key: string) : any
  {
    let property="";
    if(properties)
    {
      property = properties[key]
    }
    return property
  }

  public getBaseLayerURL()
  {
    let url = "";
    let backLayer = this.baseLayer;
    if(backLayer)
    {
      //let randint = (Math.floor( Math.random() * 2000000 ) + 1);
      url+=this.baseLayer.getSource()+this.baseLayer.getPath();
      
    }
    return url;
  }

  public getBaseLayerName()
  {
    let name = "";
    if(this.baseLayer)
    {
      name= this.layerConfigService.getLayerId(this.baseLayer);
    }
    return name;
  }

  ngOnDestroy(): void {
    this.subscriptionTool.unsubscribe();
  }

  public save(geomId1: string, geomId2: string, geomJSON: string)
  {
    this.outGeom1Id=geomId1;
    this.outGeom2Id=geomId2;
    this.outGeomJSON=geomJSON;
    if(this.layerConfig)
    {
      //this.addHistorico()
      this.commitMerge();
    }
  }

  private commitMerge()
  {

    this.editionService.merge(this.outGeom1Id, this.outGeom2Id, this.outGeomJSON, this.layerConfig).then(()=>
    {
      this.updateImoveisRelation();

    }).catch(()=>
    {
     // this.rollbackHistorico();
      this.toastService.error("Não foi possível concluir o processo de unificação!", "Erro");
    })
  }

  private updateImoveisRelation()
  {    
   
    let imoveisRelations = [];

    {
      let imovelRelation = new Object();

      imovelRelation['lote_id'] = this.outGeom1Id;
      imovelRelation['inscricao'] = null;
      imovelRelation['imovel_id'] = null;
  
      imoveisRelations.push(imovelRelation);  
    }

    {
      let imovelRelation = new Object();

      imovelRelation['lote_id'] = this.outGeom2Id;
      imovelRelation['inscricao'] = null;
      imovelRelation['imovel_id'] = null;
  
      imoveisRelations.push(imovelRelation);  
    }
      
   
    this.backendService.updateImoveisRelation(imoveisRelations).then((updatedImoveisRelations)=>
    {
      this.toastService.sucess("Geometrias unificadas!", "Sucesso");
      
      this.canvasService.redrawLayer(this.layerConfig);

      this.mergeTool.close();
    });
  }
  
}
