import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { LocalStorageService } from 'src/app/service/local-storage.service';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../../vo/notification';
import { ToolsService } from '../tools.service';


@Component({
  selector: 'app-pdfprinting',
  templateUrl: './pdfprinting.component.html',
  styleUrls: ['./pdfprinting.component.scss']
})
export class PdfprintingComponent implements OnInit, OnDestroy {

  pdfPrintingTool:any;
  subscriptionTool:Subscription;
  pdfTitleStorageKey: string = "pdf_title";
  pdfFooterStorageKey: string = "pdf_footer";

  pdfPrintingControl: FormGroup = new FormGroup(
    {
      pdfTitle: new FormControl(),
      pdfFooter: new FormControl()
    }
  );

  constructor(private notificationService: NotificationService,
    private toolService: ToolsService,
    private localStorageService: LocalStorageService)
  {
    this.subscriptionTool=this.toolService.toolsObservable.subscribe(
      (pdfPrintingTool:any) => {
        this.pdfPrintingTool=pdfPrintingTool;
      }
    );
  }

  ngOnInit(): void 
  {
    this.localStorageService.getValue(this.pdfTitleStorageKey).toPromise().then((pdfTitle: any) => {
      if(pdfTitle)
      {
        this.pdfPrintingControl.get("pdfTitle").setValue(JSON.parse(pdfTitle).value);
      }
    });
    this.localStorageService.getValue(this.pdfFooterStorageKey).toPromise().then((pdfFooter: any) => {
      if(pdfFooter)
      {
        this.pdfPrintingControl.get("pdfFooter").setValue(JSON.parse(pdfFooter).value);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptionTool.unsubscribe();
  }

  onTitleChange(){
    this.localStorageService.setValue(this.pdfTitleStorageKey,this.pdfPrintingControl.get("pdfTitle").value).subscribe(() => {
      console.log("Set print title");
    });
  }

  onFooterChange(){
    this.localStorageService.setValue(this.pdfFooterStorageKey ,this.pdfPrintingControl.get("pdfFooter").value).subscribe(() => {
      console.log("Set print footer");
    });
  }

  onPrint(){
    let formValues=this.pdfPrintingControl.value;
    let notification = new AppNotification(AppNotification.CHANGE_PRINT_TITLE_EVENT, formValues);
    this.notificationService.send(notification);
    this.pdfPrintingTool.closeDialog();
  }

}
