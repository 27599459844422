<div class="authentication-layout" >
    <div class="authentication-header">
        <div class="flex-shrink-0" role="banner"  id="header">
            <div class="col">
              <img width="120px" alt="Logo de cabeçalho para {{title}}" src="assets/logotipo.png" />
              <span>{{description}}</span>
            
              <div class="spacer"></div>
              
            </div>
        </div>
    </div>
    <div class="authentication-content" *ngIf="changePasswordFlag==false">
        <form [formGroup]="authenticationFormGroup" (ngSubmit)="authenticate()">
            <div class="authentication-card-div">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>Autenticação</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-form-field class="">
                            <mat-label>Usuario</mat-label>
                            <input matInput
                            formControlName="username">
                        </mat-form-field>
                        <mat-form-field class="">
                            <mat-label>Senha</mat-label>
                            <input type="password" matInput
                            formControlName="password">
                        </mat-form-field>
                        <div class="authentication-button-div">
                            <button mat-raised-button type="submit" color="primary">Autenticar</button>
                        </div>
                        
                    </mat-card-content>
                </mat-card>
            </div>
        </form>
    </div>
    <div class="authentication-content" *ngIf="changePasswordFlag==true">
        <form [formGroup]="changePasswordFormGroup" (ngSubmit)="changePassword()">
            <div class="authentication-card-div">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>Alteração de senha inicial</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-form-field class="">
                            <mat-label>Usuario</mat-label>
                            <input matInput
                            formControlName="username" readonly>
                        </mat-form-field>
                        <mat-form-field class="">
                            <mat-label>Senha Antiga</mat-label>
                            <input type="password" matInput
                            formControlName="oldPassword">
                        </mat-form-field>
                        <mat-form-field class="">
                            <mat-label>Senha Nova</mat-label>
                            <input type="password" matInput
                            formControlName="newPassword">
                        </mat-form-field>
                        <mat-form-field class="">
                            <mat-label>Repita a Senha Nova</mat-label>
                            <input type="password" matInput
                            formControlName="newPasswordRepeat">
                        </mat-form-field>
                        <div class="authentication-button-div">
                            <button mat-raised-button type="submit" color="primary">Alterar senha</button>
                        </div>
                        
                    </mat-card-content>
                </mat-card>
            </div>
        </form>
    </div>
    <div class="authentication-footer">
        <div class="col-12" id="footer">
            <h3>{{description}}</h3>
            <h5>versão: v{{appVersion}}</h5>
          </div>
    </div>
</div>
