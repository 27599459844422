import { Property } from "./property";

export class SettlementPropertyUsoExterno
{
    id: number;
    id_imovel_area_irregular: number;
    uso_externo: string;
    
    constructor()
    {    
    }

    fromProperties(properties: Array<Property>)
    {
        properties.forEach(property => 
        {
            let value : any = property.value;

            this[property.key.toString()] = value;
            
        });
    }
}
