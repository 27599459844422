import { Step } from "../../../vo/step";
import { FrontSelectionInteraction } from "../../tools-utils/custom-edition-interaction/interaction/front-selection-interaction";
import { EditEdificacoesToolComponent } from "../edit-edificacoes-tool.component";

export class EditEdificacoesRotationStep extends Step
 {
    constructor(index: number, private editEdificacoesToolComponent: EditEdificacoesToolComponent)
    {       
        super(index);
        this.editEdificacoesToolComponent=editEdificacoesToolComponent;
        this.setTitle("Passo 1: Selecionar frente");
        this.setDescription("Selecione a frente do lote clicando sobre o segmento desejado.");
    }
    execute()
    {

        this.editEdificacoesToolComponent.customEditionController.addFrontSelecionTool(this.editEdificacoesToolComponent.lotesLayer.getSource(), this.editEdificacoesToolComponent.currentLotesFeature);
        this.editEdificacoesToolComponent.customEditionController.addSnap(this.editEdificacoesToolComponent.lotesSnapInterectionId, this.editEdificacoesToolComponent.lotesLayer.getSource());
        this.editEdificacoesToolComponent.customEditionController.addSnap(this.editEdificacoesToolComponent.edificacoesSnapInterectionId, this.editEdificacoesToolComponent.edificacoesLayer.getSource());
  
        this.editEdificacoesToolComponent.customEditionController.enableInteraction(FrontSelectionInteraction.id);
        this.editEdificacoesToolComponent.customEditionController.enableInteraction(this.editEdificacoesToolComponent.lotesSnapInterectionId);
    }
    callback(fronSelectionInteraction: any)
    {
        this.editEdificacoesToolComponent.customEditionController.disableInteraction(FrontSelectionInteraction.id);
        this.editEdificacoesToolComponent.customEditionController.removeInteraction(FrontSelectionInteraction.id);

        this.editEdificacoesToolComponent.setFrontSelectedSegment(fronSelectionInteraction.getSelectedSegment());
 
        this.editEdificacoesToolComponent.stepsController.next();

    }
    rollback()
    {
        this.editEdificacoesToolComponent.customEditionController.resetRotation();
    }
    finish()
    {
        
    }
    valid()
    {
      return true;
    }
}
