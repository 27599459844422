<mat-accordion >
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="layername">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header" class="layers-col">
                    <mat-icon class="material-icons layer-icon">layers</mat-icon>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <ngx-monaco-editor [options]="editorOptions" [(ngModel)]="code"></ngx-monaco-editor>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>


<div class="buttons-div">
    <!-- <div class="save-button">
        <button mat-raised-button color="primary" (click)="save()">Salvar</button>

    </div> -->
    <div class="cancel-button">
        <button mat-raised-button color="basic" (click)="close()">Cancelar</button>
    </div>
    <div class="apply-button">
        <button mat-raised-button color="primary" (click)="save()">Aplicar</button>

    </div>
</div>



