import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/map/dialog/dialog.component';
import { GenericConfirmationDialogComponent } from 'src/app/map/dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { SettlementPropertyMembro } from 'src/app/map/vo/settlement-property-membro';
import { SettlementPropertyMembroFonteRenda } from 'src/app/map/vo/settlement-property-membro-fonte-renda';
import { BackendService } from 'src/app/service/backend.service';
import { DocumentService } from 'src/app/service/document.service';
import { GenericDomainOptionsService } from 'src/app/service/generic-domain-options.service';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-edit-cadastro-social',
  templateUrl: './edit-cadastro-social.component.html',
  styleUrls: ['./edit-cadastro-social.component.css']
})
export class EditCadastroSocialComponent implements OnInit 
{

  currentMember: SettlementPropertyMembro;
  optionsTiposMembro: any[];
  optionsEstadoCivilMembro: any[];
  optionsSexoMembro: any[];
  optionsEscolaridadeMembro: any[];
  optionsFonteRendaMembro: any[];
  optionsProgramaSocialMembro: any[];
  optionsDeficienciaMembro: any[];
  tipoMembro: string;
  readOnly: boolean;
  parent: any;

  newMembro: boolean;

  confirmDialogRef: any;
  maxDate = new Date();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private documentService: DocumentService, private toastService: ToastService,  private dateAdapter: DateAdapter<Date>, private genericDomainOptionsService: GenericDomainOptionsService) 
  {
    this.readOnly=data.readOnly;

    this.parent=data.parent;

    if(data.member)
    {
      this.newMembro=false;
      this.currentMember = data.member;
      this.loadCurrentTipoMembro();
    }
    else
    {
      this.newMembro=true;
      this.tipoMembro="Outros Membros";
      this.currentMember = new SettlementPropertyMembro();
    }
    
    this.optionsTiposMembro = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-cadastro-social-tipo-membro");    
    this.optionsEstadoCivilMembro = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-cadastro-social-estado-civil");    
    this.optionsSexoMembro = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-cadastro-social-sexo"); 
    this.optionsEscolaridadeMembro = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-cadastro-social-escolaridade"); 
    this.optionsProgramaSocialMembro = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-cadastro-social-programa-social");
    this.optionsFonteRendaMembro = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-cadastro-social-fonte-renda");
    this.optionsDeficienciaMembro = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-cadastro-social-deficiencia");
    
    
  }

  isSelected(elements: string[], value)
  {
    if(elements.includes(value))
    {
      return true;
    }
    return false;
  }

  private loadCurrentTipoMembro()
  {
    if(this.currentMember.possuidor)
    {
      this.tipoMembro = "Possuidor";
    } else if(this.currentMember.conjuge)
    {
      this.tipoMembro = "Cônjuge"
    }
    else
    {
      this.tipoMembro = "Outros Membros"
    }

  }


  public tipoMembroChanged()
  {
    if(this.tipoMembro=="Possuidor")
    {
      this.currentMember.possuidor=true;
      this.currentMember.conjuge=false;
    }else if(this.tipoMembro=="Cônjuge")
    {
      this.currentMember.possuidor=false;
      this.currentMember.conjuge=true;
      this.currentMember.programa_social=[]
    }
    else
    {
      this.currentMember.possuidor=false;
      this.currentMember.conjuge=false;
      this.currentMember.programa_social=[]
    }
  }

  ngOnInit(): void 
  {    
    
  }  
  confirmSave()
  {
    this.confirmDialogRef = this.parent.dialog.open(DialogComponent, {
      height: '30%',
      width: '20%',
      data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente salvar?" }
    });

    this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
      if (confirmResult) {
        this.save();
      }
    });
  }
  private save()
  {
    if(this.currentMember!=null)
    {
      this.parent.saveMember(this.currentMember, this.newMembro);
    }
    else
    {
      console.log("Current feature não está definido.");
    }
  } 
  public close()
  {
    this.parent.closeEditMember();
  }
  isMemberTypeAvailable(option: string)
  {
    if(option=="Possuidor" )
    {      
      for (let i = 0; i < this.parent.settlementProperty.cadastro_social.length; i++) 
      {
        if(this.parent.settlementProperty.cadastro_social[i].possuidor==true)
        {
          return true;
        }
      }
    } else if(option=="Cônjuge" )
    {
      for (let i = 0; i < this.parent.settlementProperty.cadastro_social.length; i++) 
      {
        if(this.parent.settlementProperty.cadastro_social[i].conjuge==true)
        {
          return true;
        }
      }
    }
    
    return false;
    
  }


}
