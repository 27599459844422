<mat-accordion>    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="">
            <mat-expansion-panel-header>
                <mat-panel-title id="user-header" class="user-col">
                    <mat-icon class="material-icons">file_upload</mat-icon> Adicionar Documento
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-card>
                <mat-card-header>
                    <mat-card-title> Documentos </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <form id="document-upload-tool" [formGroup]="documentUploadControl" (ngSubmit)="onFormSubmit()">
                        <div class="document-div">
                            <div class="document-name-div">
                                <mat-form-field>
                                    <input matInput    
                                        class="property-value"                                    
                                        name="documentName"
                                        placeholder="Nome do Documento:"
                                        formControlName="documentName">
                                </mat-form-field>                                
                            </div>
                            <div class="document-auxiliar-fields">
                                <div *ngFor="let auxComponent of auxiliarFields">                                                                     
                                        
                                        <div *ngIf="auxComponent.type=='text'">
                                            <mat-form-field>                                           
                                                <mat-label>{{auxComponent.label}}</mat-label>
                                                <input matInput [placeholder]="auxComponent.label" [(ngModel)]="auxComponent.value" class="property-value" [ngModelOptions]="{standalone: true}">
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="auxComponent.type=='select'">
                                            <mat-form-field>                                           

                                                <mat-label>{{auxComponent.label}}</mat-label>                                        
                                                <mat-select [placeholder]="auxComponent.label" class="property-value" [(ngModel)]="auxComponent.value" [ngModelOptions]="{standalone: true}">
                                                    <mat-option *ngFor="let option of auxComponent.options" [value]="option" >
                                                        {{option}}
                                                    </mat-option>
                                                </mat-select>   
                                            </mat-form-field>
                                        </div>     
                                        <div *ngIf="auxComponent.type=='date'">
                                            <mat-form-field>   
                                                <mat-label>{{auxComponent.label}}</mat-label>
                                                <input matInput  [matDatepicker]="data_picker" [max]="maxDate" [(ngModel)]="auxComponent.value" class="property-value"  [ngModelOptions]="{standalone: true}">
                                                <mat-datepicker-toggle matSuffix [for]="data_picker"></mat-datepicker-toggle>
                                                <mat-datepicker #data_picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>                                        
                                </div>
                                                             
                            </div>
                            <div class="document-upload">
                                <input type="file" name="file" (change)="onFileSelect($event)"  [accept]="getAcceptedExtensions()" class="property-value"/>
                            </div>
                            <div class="filtro-botoes">  
                                <button type="submit" mat-raised-button color="primary">
                                    <span class="material-icons">add</span> Adicionar
                                </button>                                
                                &nbsp;
                                <button mat-raised-button color="primary" (click)="parentDialog.closeDocumentUpload();">
                                    <span class="material-icons">close</span> Fechar
                                </button>
                            </div>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
  
 