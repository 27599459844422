import { DialogComponent } from "src/app/map/dialog/dialog.component";
import { GenericConfirmationDialogComponent } from "src/app/map/dialog/generic-confirmation-dialog/generic-confirmation-dialog.component";
import { Step } from "../../../vo/step";
import { EditEdificacoesToolComponent } from "../edit-edificacoes-tool.component";
import { EditionoperationsInner, Historicoprocessoimovel, HistoricoprocessoimovelInner, Insertedoperation, Insertoperation, Updateoperation } from '@funcate/sigweb-cti-api';
import Feature from "ol/Feature";
import { LayersConfigService } from "src/app/map/layers/layers.config.service";
import { BackendService } from "src/app/service/backend.service";
import GeoJSON from 'ol/format/GeoJSON';
import { Property } from "src/app/map/vo/property";
import { GeometryService } from "src/app/service/geometry.service";
import { EditHistoricoComponent } from "../../property-registration/edit-historico/edit-historico.component";

export class EditEdificacoesCommitStep extends Step
 {
    private geoJSONDefaultSRID = '4326'
    private addHistoricoDialog: any;
    private currentHistoricoId: string;

    confirmDialogRef: any;
    constructor(index: number, private editEdificacoesToolComponent: EditEdificacoesToolComponent, private layersConfigService: LayersConfigService, private backendService: BackendService)
    {
        super(index);
        this.setTitle("Passo 4: Verifique as edificações modificadas.");
        this.setDescription("Verifique as geometrias que foram modificadas durante a edição e mantenha marcadas as que deseja salvar.");

    }
    execute()
    {
        this.editEdificacoesToolComponent.edificacoesLayer.setStyle(this.editEdificacoesToolComponent.edificacoesStyleWithLabelFunction);
      this.loadCommitEdificacoes();
    }

    private loadCommitEdificacoes()
    {
      this.editEdificacoesToolComponent.commitEdificacoesFeatures=[];

      this.editEdificacoesToolComponent.currentEdificacoesFeatures.forEach((feature: Feature<any>)=>
        {
          let operationFeature = feature.clone();

          //Definir como habilitado a operação por padrão para todas as features
          operationFeature.set('enabled', true);

          if(feature.getId()<0)
          {
            //Insert
            operationFeature.set(this.editEdificacoesToolComponent.operationIdFeatureKey, 1);
          }
          else
          {
            //Update
            if(feature.get(this.editEdificacoesToolComponent.modifiedFeatureKey))
            {
              operationFeature.set(this.editEdificacoesToolComponent.operationIdFeatureKey, 2);
            }
          }
          
          this.editEdificacoesToolComponent.commitEdificacoesFeatures.push(operationFeature);

        });

        this.editEdificacoesToolComponent.deletedEdificacoes.forEach((feature: Feature<any>)=>
        {
          let operationFeature = feature.clone();

          operationFeature.set('enabled', true);

          //Delete
          operationFeature.set(this.editEdificacoesToolComponent.operationIdFeatureKey, 3);
          
          this.editEdificacoesToolComponent.commitEdificacoesFeatures.push(operationFeature);

        });
    }

    callback(object)
    {
      if(object.selectionChanged)
      {
        let selectionChangedFeature = object.changedFeature
        if(selectionChangedFeature.get("enabled"))
        {
          selectionChangedFeature.set("enabled", false);
        }
        else
        {
          selectionChangedFeature.set("enabled", true);
        }
      }
    }

    finish()
    {
        this.confirm();
    }

    private confirm()
    {
 
          this.confirmDialogRef = this.editEdificacoesToolComponent.dialog.open(DialogComponent, {
            height: '30%',
            width: '20%',
            data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente salvar as modificações selecionadas?" }
          });
    
          this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
            if(confirmResult)
            {
               //this.commitEditionChanges();
               this.addHistorico();
            }

          });
        
    }

    private commitEditionChanges()
    {
      this.editEdificacoesToolComponent.commitEdificacoesFeatures;

      let operations : Array<EditionoperationsInner> = []      

      this.editEdificacoesToolComponent.commitEdificacoesFeatures.forEach(feature => 
      {
        //Verificar se a feição foi modificada e se está habilitada na interface
        if(feature.get(this.editEdificacoesToolComponent.modifiedFeatureKey)
            &&feature.get('enabled'))
        {          
      
            let operationId : number = feature.get(this.editEdificacoesToolComponent.operationIdFeatureKey);

            if(operationId)
            {
            
              let layerTable = this.layersConfigService.getLayerTable(this.editEdificacoesToolComponent.edificacoesLayerConfig);
        
              let layerGeomAttribute = this.layersConfigService.getLayerGeomAttribute(this.editEdificacoesToolComponent.edificacoesLayerConfig);
         
              let layerIdAttribute = this.editEdificacoesToolComponent.edificacoesIdAttribute;
         
              let idValue = this.editEdificacoesToolComponent.getAttributeValue(feature, layerIdAttribute);
 
             let format = new GeoJSON();

             let geometry = feature.getGeometry();
             if(geometry.getType()=="Polygon")
             {
              geometry = GeometryService.convertPolygonToMultiPolygon(geometry);
             }
             
 
             let geoJSON = format.writeGeometry(geometry, {featureProjection: "EPSG:"+this.editEdificacoesToolComponent.inputFeatureSRID});
 
             let currentOperation : EditionoperationsInner = new Object();
             currentOperation.operationtype=operationId
             currentOperation.table=layerTable;
             currentOperation.geomattribute=layerGeomAttribute;
             currentOperation.geom=geoJSON;
             currentOperation.idattribute=layerIdAttribute;
             currentOperation.idvalue=idValue.toString();
             currentOperation.srid=this.geoJSONDefaultSRID;
 
             let properties = this.convertToProperties(feature);
 
             currentOperation.properties=properties;
 
             operations.push(currentOperation);
            }            
         }          
      });

      this.backendService.executeEditionOperations(operations).then((operations)=>
      {
        this.editEdificacoesToolComponent.tool.close();

      }).catch((exception: any)=>
      {
        //Chamando função para apagar o histórico gravado
        this.rollbackHistorico();
        this.editEdificacoesToolComponent.toastService.error(exception.errorMessage, "Erro")
        console.log(exception);
      });

    }

    private addHistorico()
    {  
      let item : HistoricoprocessoimovelInner = new Object;
  
      item.ic = this.editEdificacoesToolComponent.currentIC;
  
      this.addHistoricoDialog = this.editEdificacoesToolComponent.dialog.open(DialogComponent, {
        height: '60%',
        maxHeight: '60%',
        maxWidth: '80%',
        data: { component: EditHistoricoComponent, title: "Adicionar histórico de processo para o imóvel editado", historico: item, parent: this  }
      });
  
      this.addHistoricoDialog.afterClosed().toPromise().then(()=>{
        
      });
    }

    private convertToProperties(feature:  Feature<any>) : Property[]
    {

      let properties = []
      let pavimento : Property = new Property(this.editEdificacoesToolComponent.edificacoesPavimentosAttribute, feature.get(this.editEdificacoesToolComponent.edificacoesPavimentosAttribute))
      properties.push(pavimento);
      let classe : Property = new Property(this.editEdificacoesToolComponent.edificacoesClasseAttribute, feature.get(this.editEdificacoesToolComponent.edificacoesClasseAttribute))
      properties.push(classe);
      let area : Property = new Property(this.editEdificacoesToolComponent.edificacoesAreaAttribute, feature.get(this.editEdificacoesToolComponent.edificacoesAreaAttribute))
      properties.push(area);  
      let ic : Property = new Property(this.editEdificacoesToolComponent.edificacoesICAttribute, this.editEdificacoesToolComponent.currentIC);
      properties.push(ic);  

      return properties;
    }

    rollback()
    {
        this.editEdificacoesToolComponent.defineLayerStyles();
        this.editEdificacoesToolComponent.edificacoesLayer.setStyle(this.editEdificacoesToolComponent.edificacoesStyle);
    }

    valid()
    {
      return true;
    }
    
    //Após fechar a adição do historico do processo, se voltar um o ID, passamos pro proxima passo e guardamos o ID para caso de precisar voltar atrás
    public closeEditHistorico(historicoProcesso: Historicoprocessoimovel)
    {
      if(historicoProcesso.length>0)
      {
        if(historicoProcesso[0].id)
        {
          this.currentHistoricoId = historicoProcesso[0].id.toString();
          //Historico inserido, inserir geometrias agora
          if(this.addHistoricoDialog)
          {
            this.addHistoricoDialog.close();
            //Commit das operações
            this.commitEditionChanges();
          }
        }
      }
     
    }
    //Rollback if operations insert error
    private rollbackHistorico()
    {
      this.backendService.deleteHistoricoProcessoImovel(this.currentHistoricoId).then(()=>
      {
        console.log("Remoção do historico de processo adicionado (id: "+this.currentHistoricoId+") devido a um problema no commit das operações de edição.")
      })
    }
}