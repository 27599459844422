import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppNotification } from '../map/vo/notification';

@Injectable({ providedIn: 'root' })

export class NotificationService 
{
    private subject = new Subject<any>();

    send(notification: AppNotification) {
        this.subject.next(notification);
    }

    clear() {
        this.subject.next();
    }

    onNotification(): Observable<AppNotification> {
        return this.subject.asObservable();
    }
}