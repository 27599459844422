import { Component, OnInit, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-edit-style-sld',
  templateUrl: './edit-style-sld.component.html',
  styleUrls: ['./edit-style-sld.component.css']
})

export class EditStyleSLDComponent implements OnInit 
{

  editorOptions = {theme: 'vs-dark', language: 'javascript'};
  code: string= 'function x() {\nconsole.log("Hello world!");\n}';
  
  constructor() 
  {

  }

  ngOnInit(): void 
  {
  }

  close()
  {

  }

  save()

  {

  }



}
