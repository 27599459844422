import { ToolsService } from '../tools.service';
import { MatDialog } from '@angular/material/dialog';
import { Feature } from '../../vo/feature';
import { DialogComponent } from '../../dialog/dialog.component';
import { Tool } from '../tool';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { LayerConfig } from '../../layers/layers.config';
import { LayersConfigService } from '../../layers/layers.config.service';
import { FeatureService } from 'src/app/service/feature.service';
import { ToastService } from 'src/app/service/toast.service';
import { CanvasService } from '../../canvas/canvas.service';
import { Property } from '../../vo/property';
import { SetupImoveisLinkToolComponent } from './setup-imoveis-link-tool.component';
import { EditHistoricoComponent } from '../property-registration/edit-historico/edit-historico.component';


export class SetupImoveisLinkTool extends AbstractLayerTool {

    private dialog: MatDialog;

    cursorType='tool-cursor-pointer';
    id='setup-imoveis-link-tool';
    enabled=false;
    name='Gerenciar Vinculo de Imóvel';
    title='Gerenciar Vinculo de Imóvel';
    
    type='click';
    map:L.Map=null;
    icon='cable';
    ll: any;
    protected visible:boolean=true;
    layerTool: boolean=true;
    customParameter=new Map<String, any>();
    dialogRef: any;
    
    //custom variables
    toolLayer: LayerConfig;
    lotesLayer: LayerConfig;

    currentFeature: any;
    lotesCurrentFeature: any;
    featureSRID: string;
    featureSRS: string;
    toolLotesLayerIdConfig: string = "lotes_layer_id";
    imoveisLoteIdAttributeConfig: string = "lote_id_attribute";
    currentInscricao: string;

    constructor(toolsService: ToolsService, private layerConfigService: LayersConfigService, private featureService: FeatureService, private toastService: ToastService, 
        private canvasService: CanvasService,dialog: MatDialog) 
    { 
        super(toolsService)
        this.dialog = dialog;

    }

    public execAction=(event:L.LeafletMouseEvent)=>{
        this.map=event.sourceTarget;
        this.ll=event.latlng;
        
        if(this.toolLayer)
        {
            this.loadFeature();
        }

    };
    public enableImpl()
    {
        this.currentFeature=null;

        this.toolLayer = this.toolsService.getToolParameter(this, "layer");

        if(this.toolLayer)
        {
            this.layerConfigService.enableLayer(this.toolLayer);
        }

        let lotesLayerId = this.layerConfigService.getLayerAppConfig(this.toolLayer, this.toolLotesLayerIdConfig);

        this.lotesLayer = this.layerConfigService.getOverLayerById(lotesLayerId);

        this.toastService.sucess("Aponte o imóvel que deseja editar os vínculos.", "Ferramenta de Gerencia de Vínculos de Imóvel"); 
    }
    public disableImpl()
    {
    }

   
    public isVisibleForLayer(layer :LayerConfig) : boolean
    {
        return this.layerTool;
    }

    private openDialog()
    {
        this.dialogRef = this.dialog.open(DialogComponent, {
            height: '90%',
            width: '90%',
            data: { component: SetupImoveisLinkToolComponent, title: "Gerencia de Vínculos de Imóvel", currentFeature: this.currentFeature, currentFeatureSRID: this.featureSRID, imoveisLayer: this.toolLayer, lotesLayer: this.lotesLayer,
        lotesCurrentFeature: this.lotesCurrentFeature, currentInscricao: this.currentInscricao}
        });

        let whenDialogIsOpen=this.dialogRef.afterOpened().toPromise();
        whenDialogIsOpen.then(
            ()=>{
                this.toolsService.dispatchTool(this);
            }
        );

        this.dialogRef.afterClosed().toPromise().then(()=>{
            this.currentFeature=null;
        });
    }

    private loadFeature()
    {

        if(this.toolLayer)
        {
            this.map.eachLayer( (l)=>{
                if(l["wmsParams"]) {
        
                    if(l["id"]==this.toolLayer.getId())
                    {
                        this.featureService.getFeatureInfo(this.ll, l, this.map).then(featureInfo => {
            
                            if(featureInfo)
                            {
                                if(featureInfo.features.length>0)
                                {
                                    //this.currentFeature=featureInfo.features[0];
                                    this.featureSRS = this.map.options.crs.code; 
                                    this.featureSRID=this.featureSRS.replace("EPSG:","");
                                    this.loadImovel(featureInfo.features[0], this.featureSRS);
                                }
                            }
                        
                        });
                    }
                }
            });
        }
    }

    private loadImovel(feature: any, srs: any)
    {
        if(feature)
        {
            let imoveisIdAttribute = this.layerConfigService.getLayerIdAttribute(this.toolLayer);

            let imoveisICAttribute = this.layerConfigService.getLayerInscricaoAttribute(this.toolLayer);

            let imoveisAttributesFilterList: Array<Array<Property>>=[];
            let imoveisAttributeFilterList: Array<Property>=[];
    
            let imovelIdProperty = this.featureService.getPropertyByKey(feature.properties, imoveisIdAttribute);

            let imovelIcProperty = this.featureService.getPropertyByKey(feature.properties, imoveisICAttribute);
    
            if(imovelIcProperty 
                && imovelIcProperty.value 
                && imovelIcProperty.value.toString().length > 0 )
                {
                    if(imovelIdProperty)
                    {
                        this.currentInscricao = imovelIcProperty.value.toString();
                        imoveisAttributeFilterList.push(imovelIdProperty);
                        imoveisAttributesFilterList.push(imoveisAttributeFilterList);
                
                        this.featureService.getFeatures(this.toolLayer, srs , imoveisAttributesFilterList).then(features => 
                        {
                            if(features && features.length>0)
                            {
                                this.currentFeature = features[0];
        
                                this.loadLotesFeatures(this.currentFeature, srs);
                            }
                        });
                    }
                    else
                    {
                        console.log("Property "+ imoveisIdAttribute +" not found...")
                    }
                }
                else
                {
                    this.toastService.error("Imóvel selecionado não possui inscrição cadastral válida.", "Erro")
                }

            
        }        
    }

    private loadLotesFeatures(imovelFeature, srs)
    {
        if(imovelFeature)
        {

            // Buscando lote_id em imoveis para buscar o lote
            let imoveisLoteIdAttribute = this.layerConfigService.getLayerAppConfig(this.toolLayer, this.imoveisLoteIdAttributeConfig);      

            let imovelFeaturePropertiesList = this.featureService.getWFSPropertiesObjectAsPropertyArray(imovelFeature.properties);

            let imovelLoteIdProperty = this.featureService.getPropertyByKey(imovelFeaturePropertiesList, imoveisLoteIdAttribute);

            let lotesIdAttribute = this.layerConfigService.getLayerIdAttribute(this.lotesLayer);
    
            let imoveisAttributesFilterList: Array<Array<Property>>=[];
            let imoveisAttributeFilterList: Array<Property>=[];

            if(imovelLoteIdProperty && imovelLoteIdProperty.value)
            {
                imoveisAttributeFilterList.push(new Property(lotesIdAttribute, imovelLoteIdProperty.value.toString()));
                imoveisAttributesFilterList.push(imoveisAttributeFilterList);
        
                this.featureService.getFeatures(this.lotesLayer, srs , imoveisAttributesFilterList).then(features => 
                {
                    if(features && features.length>0)
                    {
                        this.lotesCurrentFeature = features[0];
                    }
                    this.openDialog();
                });
            }
            else
            {
                console.log("Property "+ lotesIdAttribute +" not found...")
                this.openDialog();
            }
        }        
    }

    public isEnabled(layer :LayerConfig) : boolean
    {
        let currentLayerTool = this.toolsService.getToolParameter(this, "layer");
        if(this.enabled && currentLayerTool.getId()==layer.getId() )
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    public close()
    {
        this.canvasService.clearHighlights();
        this.canvasService.redrawLayer(this.toolLayer);
        this.dialogRef.close();
    }


   

}
