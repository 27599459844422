<mat-accordion class="fotos-fachada-accordion">
    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="layername">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header">
                    <mat-icon class="material-icons layer-icon">layers</mat-icon>Gerenciador de Fotos de Fachadas
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>Inscrições Selecionadas</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-select *ngIf="isMultiple" [(ngModel)]="selected" multiple>
                            <mat-option *ngFor="let inscricao of inscricoes" [value]="inscricao" [disabled]="true">
                                {{inscricao.getInscricaoCompleta()}}
                              </mat-option>    
                        </mat-select>
                        <mat-card *ngIf="!isMultiple" >{{ic.getInscricaoCompleta()}}</mat-card>
                    </mat-card-content>
                </mat-card>       
            </div>
            <div>
                <mat-card>
                    <mat-card-header>
                        <mat-card-title class="fotos-fachadas-title">
                            <div class="fotos-fachadas-title-text">  Fotos de Fachadas  </div>
                            <div class="fotos-fachadas-actions">
                                <button type="button" title="Adicionar fotos de fachadas para todas as inscições selecionadas." (click)="addFotosFachadas()" mat-raised-button color="primary">
                                    <span class="material-icons">add</span> Adicionar
                                </button>
                                <button type="button" title="Editar fotos de fachadas para todas as fotos de fachadas selecionadas." *ngIf="isFotosFachadasSelected()" (click)="editFotosFachadas()" mat-raised-button color="primary">
                                    <span class="material-icons">edit_note</span> Editar
                                </button>
                                <button disabled type="button" title="Selecione uma ou mais fotos de fachadas para editar." *ngIf="!isFotosFachadasSelected()" mat-raised-button color="primary">
                                    <span class="material-icons">edit_note</span> Editar
                                </button>
                                <button type="button" title="Remover fotos de fachadas selecionadas." *ngIf="isFotosFachadasSelected()" (click)="confirmDelete()" mat-raised-button color="primary">
                                    <span class="material-icons">delete</span> Remover
                                </button>
                                <button disabled type="button" title="Selecione uma ou mais fotos de fachadas para remover." *ngIf="!isFotosFachadasSelected()" mat-raised-button color="primary">
                                    <span class="material-icons">delete</span> Remover
                                </button>
                                <button type="button" title="Selecionar/Desselecionar todas as fotos de fachadas." (click)="toggleAllCheckbox()" mat-raised-button color="primary">
                                    <span class="material-icons">check_box</span> Selecionar/Desselecionar
                                </button>
                            </div>
                             
                    </mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-selection-list #fotosfachadas (selectionChange)="onSelection($event)">
                            <mat-list-option *ngFor="let foto of fotosFachadas" class="foto-list-option" [value]="foto">
                                <div class="foto-ic-div">
                                    <div class="foto-div" (click)="downloadFotoFachada(foto.id, foto.filepath)" title="Download da foto de fachada"><img src="{{fotoFachadaURL}}{{foto.id}}" class="fotofachada-img" >
    
                                    </div>
                                    <div class="ic-div">Inscrição: {{foto.ic}} - Data de Captura: {{formatDate(foto.data_captura, 'dd/MM/yyyy')}} </div>
                                </div>
                                                       
                            </mat-list-option>
                          </mat-selection-list>
                    </mat-card-content>
                </mat-card>       
            </div>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>

