import { FormControl, FormGroup } from "@angular/forms";
import { Importlayercheckinforesult } from "@funcate/sigweb-cti-api";
import { LayerConfig } from "src/app/map/layers/layers.config";
import { BackendService } from "src/app/service/backend.service";
import { ToastService } from "src/app/service/toast.service";
import { Step } from "../../../../vo/step";
import { ImportLayerComponent } from "../import-layer.component";

export class ImportLayerBasicInfoStep extends Step
 {
    constructor(index: number, private importLayerComponent: ImportLayerComponent, private toastService: ToastService, private backendService: BackendService)
    {       
        super(index);        
        this.setTitle("Passo 4: Informações básicas da camada.");
        this.setDescription("Preencha as informações básicas da camada que serão exibidas na árvore de camadas.");         
    }
    execute()
    {
      
      let path = "/geoserver/"+this.importLayerComponent.importlayerfileinfo.defaultworkspace+"/"+this.importLayerComponent.importlayerfileinfo.layername+"/wms";
      let newLayer = new LayerConfig(this.importLayerComponent.importlayerfileinfo.layername, 
        this.importLayerComponent.importlayerfileinfo.layername,
         this.importLayerComponent.importlayerfileinfo.defaultworkspace, 
         this.importLayerComponent.importlayerfileinfo.layername,
          null, false, null, path, null, [], [], { public: false, usergroups: []});

      let advancedConfig = {       
        "geom_attribute": this.importLayerComponent.importlayerfileinfo.geomcolumn,
        "geomtype": this.importLayerComponent.importlayerfileinfo.geomtype,
        "id_attribute": this.importLayerComponent.importlayerfileinfo.idcolumn,
        "table": this.importLayerComponent.importlayerfileinfo.layername,
        "vector": true
      };
      newLayer.setApplicationconfigs([advancedConfig]);

      this.importLayerComponent.newLayerConfig = newLayer;
    }
    callback(object: any)
    {

    }
    rollback()
    {
    }
    finish()
    {
         this.backendService.checkLayerImportInfo(this.importLayerComponent.importlayerfileinfo.layername).then((result: Importlayercheckinforesult)=>{
          console.log(result);
          if(result.layernameValid==false)
          {
            this.toastService.warning("Nome da camada inválida, altere e tente novamente.", "Aviso");
          }
          else
          {
            if(result.tableExists==true || result.geoserverLayerExists==true)
            {
              this.toastService.warning("Nome da camada já existe no servidor, altere e tente novamente.", "Aviso");
            }
            else
            {
              
            }
          }
         });
    }
    valid()
    {
      return true;
    }  
    public isValidFile()
    {
     
    }
}

