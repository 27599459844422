import { ToastService } from "src/app/service/toast.service";
import { Step } from "../../../vo/step";
import { SetupImoveisLinkToolComponent } from "../setup-imoveis-link-tool.component";

export class SelectOperationStep extends Step
 {
    constructor(index: number, private setupImoveisLinkToolComponent: SetupImoveisLinkToolComponent, private toastService: ToastService)
    {       
        super(index);
        this.setupImoveisLinkToolComponent=setupImoveisLinkToolComponent;
        this.setTitle("Passo 1: Definir o tipo de operação");
        this.setDescription("Passo 1: Definir o tipo de operação");
       
    }
    execute()
    {
     
    }
    callback()
    {
     
    }
    rollback()
    {

    }
    finish()
    {
        
    }
    valid()
    {
      if(this.setupImoveisLinkToolComponent.selectedOperation)
      {
        return true;
      }
      else
      {
        this.toastService.error("Selecione uma operação.","Erro")
        return false;
      }      
    }
}
