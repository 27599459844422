
<div class="pmsv-logo">
    <img src="assets/logotipo.png" alt="Logo de cabeçalho para SIGWeb" > <img src="assets/logo-funcate.png" alt="Logo de cabeçalho para SIGWeb" >
</div>

<mat-card>
    <mat-card-content class="version-card">
        <div class="module-name">{{appTitle}} - {{appDescription}}</div><div>Versão: <b>{{appVersion}}</b></div>
    </mat-card-content>
</mat-card>

<mat-card *ngFor="let apiVersion of apiVersions;" >
    <mat-card-content class="version-card">
        <div class="module-name">{{apiVersion.modulo}}</div><div>Versão:<b> {{apiVersion.version}}</b></div>
    </mat-card-content>
</mat-card>

