import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackendService } from 'src/app/service/backend.service';
import { DocumentService } from 'src/app/service/document.service';
import { ToastService } from 'src/app/service/toast.service';
import { AbstractDocumentType } from '../../vo/document/abstract-document-type';
import { AbstractDocumentAuxiliarField } from '../../vo/document/auxiliar-fields/abstract-document-auxiliar-field';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.css']
})
export class DocumentUploadComponent implements OnInit 
{
  documentUploadControl: FormGroup;
  parentDialog: any;
  private documentType: number;
  private featureId: string;
  documentName: string;
  supportedExtensions: string[];
  docType: AbstractDocumentType;
  auxiliarFields: AbstractDocumentAuxiliarField[]=[];
  maxDate = new Date();
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private documentService: DocumentService, private toastService: ToastService, private dateAdapter: DateAdapter<Date>) 
  {

    this.documentUploadControl = new FormGroup(
      {
        file: new FormControl(),
        documentName: new FormControl()
      }
    );  

    
    
    this.documentName="";
     
    this.parentDialog = this.data.parentDialog;
    this.documentType = data.documentType;
    this.featureId = data.featureId;    
    this.supportedExtensions = data.supportedExtensions;

    this.docType = AbstractDocumentType.getInstance(this.documentType);

    this.auxiliarFields = this.docType.getAuxiliarComponents();


    
  }

  ngOnInit(): void 
  {
    
  }
  onFileSelect(event) {
    if (event.target.files.length > 0) 
    {
      const file = event.target.files[0];
      this.documentUploadControl.get('file').setValue(file);
      this.documentName=file.name;
      this.documentUploadControl.get('documentName').setValue(this.documentName);
    }
  }
  getAcceptedExtensions()
  {
    let extensions = "";

    this.supportedExtensions.forEach(ext => 
    {
      if(extensions)
      {
        extensions+=",." + ext
      } else
      {
        extensions+="." + ext
      }      
    });

    return extensions;
    
  }
  checkIfExtensionIsSupported(extension: string) : boolean
  {   
    let found = false;
    this.supportedExtensions.forEach(ext => 
    {
      if(ext==extension)
      {
        found = true;
      }
    });
    return found;
  }

  onFormSubmit()
  {
    
    let file = this.documentUploadControl.get('file').value;
    let name = this.documentUploadControl.get('documentName').value;
    let documento: Blob = new Blob([file], { type: 'application/octet-stream' });

    let filenameList = file.name.split(".");

    if(filenameList.length>=2)
    {
      let extension = filenameList[filenameList.length-1].toLowerCase();
      
      if(this.checkIfExtensionIsSupported(extension))
      {
        if(file)
        {
          let auxData = null;

          if(this.auxiliarFields && this.auxiliarFields.length>0)
          {
            auxData = JSON.stringify(this.auxiliarFields);
          }
          this.documentService.uploadDocumento(name, extension, this.docType.type.toString(), "", this.featureId, auxData, documento).then(()=>
          {
            this.toastService.sucess("Documento associado.","Sucesso");
            this.parentDialog.closeDocumentUpload();
          }).catch(()=>
          {
            this.toastService.error("Problema ao associar documento.","Erro");
          })
        }
        else
        {
          this.toastService.error("Favor selecionar primeiramente um arquivo.","Erro");
        }
      }
      else
      {
        this.toastService.error("Extensão do arquivo inválida.","Erro");
      }
      
    }
    else
    {
      this.toastService.error("Nome do arquivo inválido.","Erro");
    }  


  }

}
