import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Vector from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { defaults as DefaultControls} from 'ol/control';
import View from 'ol/View';
import Source from 'ol/source/Source';
import {getCenter} from 'ol/extent';
import Layer from 'ol/layer/Layer';
import BaseLayer from 'ol/layer/Base';
import { FrontSelectionInteraction }  from './interaction/front-selection-interaction'
import { SegmentSelectionInteraction }  from './interaction/segment-selection-interaction'
import { LayerSnapInteraction }  from './interaction/layer-snap-interection'
import { Interaction, Snap } from 'ol/interaction';
import { NotificationService } from 'src/app/service/notification.service';
import { InteractionController }  from './interaction/interaction-controller'
import {defaults as DefaultInteractions} from 'ol/interaction.js';
import Feature from 'ol/Feature';


export class CustomEditionController
{

	private map: Map;
	private interectionController: InteractionController;

	constructor(mapContainerId: string, private notificationService: NotificationService)
	{
		this.interectionController = new InteractionController();
		this.initMap(mapContainerId);
	}

	private initMap(mapContainerId: string) 
	{
		this.map = new Map({
			layers: [
				new TileLayer({source: new OSM()})
				],
			  target: mapContainerId,
			  controls: DefaultControls({zoom: false, attribution: false, rotate: false}),
			  view: new View({
				constrainRotation: false
			  }),
			  interactions: DefaultInteractions({
				doubleClickZoom: true,
				dragPan: true,
				mouseWheelZoom: true
			  }),
		});
		this.map.set('custom-edition-controller', this, true);

		// var interactions = this.map.getInteractions();
		// interactions.forEach((interaction) => {
    	// 	interaction.setActive(false);
		// });

	}
	public addLayer(layer : any)
	{
		this.map.addLayer(layer);
	}

	public removeLayer(layer : any)
	{
		this.map.removeLayer(layer);
	}

	public fitLayer(layer: any)
	{
	  var extent = layer.getSource().getExtent();
      var center = getCenter(extent);

      this.map.getView().fit(extent);
      this.map.getView().setCenter(center);
	}

	public fitFeature(feature: Feature<any>)
	{
		let extent = feature.getGeometry().getExtent();
		this.map.getView().fit(extent);
		var center = getCenter(extent);
		this.map.getView().setCenter(center);
	}

	public addFrontSelecionTool(lotesLayer: any, featuretoSelectFront: any) : Interaction
	{		
		let frontSelectionInteraction = new FrontSelectionInteraction(this, this.notificationService, featuretoSelectFront);
		this.addInteraction(frontSelectionInteraction.id,frontSelectionInteraction);

		return frontSelectionInteraction;
	}


	public addSegmentSelecionTool(layer: any, featuretoSelect: any) : Interaction
	{		
		let segmentSelectionInteraction = new SegmentSelectionInteraction(this, this.notificationService, featuretoSelect);
		this.addInteraction(SegmentSelectionInteraction.id,segmentSelectionInteraction);

		return segmentSelectionInteraction;
	}

	public removeInteraction(id: string)
	{

		let interaction = this.interectionController.getInteraction(id);

		if(interaction)
		{
			this.interectionController.removeInteraction(id);

			this.map.removeInteraction(interaction);
		}
	}

	public disableInteraction(id: string)
	{
		let interaction = this.interectionController.getInteraction(id);

		if(interaction)
		{
			interaction.setActive(false);
		}
	}

	public enableInteraction(id: string)
	{
		let interaction = this.interectionController.getInteraction(id);

		if(interaction)
		{
			interaction.setActive(true);
		}
	}

	public getInteraction(id: string)
	{
		return this.getInteraction(id);
	}

	public addInteraction(id: string,interection: Interaction)
	{
		this.map.addInteraction(interection);
		this.interectionController.addInteraction(id, interection);
	}

	public addSnap(id: string,layer: any) : Interaction
	{
		let snap = new LayerSnapInteraction(id, {source: layer});
		this.addInteraction(id, snap);

		return snap;
	}

	public resetRotation()
	{
		this.map.getView().setRotation(0);
	}

	public getRotation()
	{
		return this.map.getView().getRotation();
	}

	public getMap()
	{
		return this.map;
	}

	
    
};
