<h3 *ngIf="errorMessage"> {{errorMessage}} </h3>
<div *ngIf="associationMessage" class="bg_warning">
    <table cellspacing="0">
        <tr>
            <td>
                <h2> {{associationMessage}} </h2>
            </td>
            <td class="bt_association">
                <button type="button" (click)="onAssociationCancel()" mat-raised-button color="accent">
                    <span class="material-icons">backspace</span> Cancelar associação
                </button>
            </td>
        </tr>
    </table>
</div>
<h2 *ngIf="message"> {{message}} </h2>
<mat-progress-bar *ngIf="ctrlLoading" mode="indeterminate"></mat-progress-bar>

<mat-accordion>
    <mat-expansion-panel [expanded]="!hasResult()" class="search-session">
        <mat-expansion-panel-header>
            <mat-panel-title class="search-session-title">
                <mat-icon class="material-icons">search</mat-icon> Filtros
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <form id="propertysearch-tool" [formGroup]="propertySearchControl" (ngSubmit)="onFormSubmit()">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Identificação - inscrição imobiliária completa ou parcial</mat-card-title>
                </mat-card-header>
                <mat-card-content>

                    <!-- start form -->
                    <table cellspacing="0">
                        <tr>
                            <td>
                                <mat-form-field>
                                    <input matInput
                                    placeholder="Setor:"
                                    name="setor"
                                    formControlName="setor"
                                    id="propertysearch-tool-setor"
                                    (paste)="onPaste($event)"
                                    value="" (blur)="validateLength('setor', $event)">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <input matInput
                                    placeholder="Quadra:"
                                    name="quadra"
                                    formControlName="quadra"
                                    id="propertysearch-tool-quadra"
                                    (paste)="onPaste($event)"
                                    value="" (blur)="validateLength('quadra', $event)">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <input matInput
                                    placeholder="Logradouro:"
                                    name="logradouro"
                                    formControlName="logradouro"
                                    id="propertysearch-tool-logradouro"
                                    (paste)="onPaste($event)"
                                    value="" (blur)="validateLength('logradouro', $event)">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <input matInput
                                    placeholder="Lote:"
                                    name="lote"
                                    formControlName="lote"
                                    id="propertysearch-tool-lote"
                                    (paste)="onPaste($event)"
                                    value="" (blur)="validateLength('lote', $event)">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field>
                                    <input matInput
                                    placeholder="SubLote:"
                                    name="sublote"
                                    formControlName="sublote"
                                    id="propertysearch-tool-sublote"
                                    (paste)="onPaste($event)"
                                    value="" (blur)="validateLength('sublote', $event)">
                                </mat-form-field>
                            </td>
                        </tr>
                    </table>
                    <!-- end form -->

                </mat-card-content>
            </mat-card>
            <!-- New card "Endereço" -->
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Endereço - campo único ou combinação</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table cellspacing="0">
                        <tr>
                            <td colspan="2">
                                <mat-form-field>
                                    <input matInput
                                    placeholder="Logradouro:"
                                    name="endereco_imovel"
                                    formControlName="endereco_imovel"
                                    value="">
                                </mat-form-field>
                            
                            </td>
                            <td colspan="1">
                                <mat-form-field>
                                    <input matInput
                                    placeholder="Número:"
                                    name="numero_imovel"
                                    formControlName="numero_imovel"
                                    value="">
                                </mat-form-field>
                            
                            </td>
                            <td colspan="2">
                                <mat-form-field>
                                    <input matInput
                                    placeholder="Complemento:"
                                    name="complemento_imovel"
                                    formControlName="complemento_imovel"
                                    value="">
                                </mat-form-field>
                            
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <mat-form-field>
                                    <input matInput
                                    placeholder="Bairro:"
                                    name="bairro_imovel"
                                    formControlName="bairro_imovel"
                                    value="">
                                </mat-form-field>
                            
                            </td>
                            <td colspan="1">
                                <mat-form-field>
                                    <input matInput
                                    placeholder="CEP:"
                                    name="cep_imovel"
                                    formControlName="cep_imovel"
                                    maxlength="8"
                                    minlength="8"
                                    value="">
                                </mat-form-field>
                            
                            </td>
                            <td colspan="1">
                                <mat-checkbox formControlName="without_geometry">Exibir apenas registros sem associação com geometria?</mat-checkbox>
                            </td>
                            <td>
                                <mat-form-field class="propertysearch-tool-status-option">
                                    <mat-label>Status</mat-label>
                                    <mat-select formControlName="status" name="status" class="status-select"> 
                                        <mat-option value="all">Todos</mat-option>
                                        <mat-option value="active">Ativos</mat-option>
                                        <mat-option value="inactive">Inativos</mat-option>
                                    </mat-select>
                                </mat-form-field>                                
                            </td>
                        </tr>

                        <tr>
                            <td colspan="3" >
                                <div class="validationMessage" *ngIf="!isValidFormSubmitted && validationMessage">
                                    <span>{{ validationMessage }}</span>
                                </div>
                            </td>
                            <td colspan="1" class="control-buttons">
                                <button type="submit" mat-raised-button color="primary">
                                    <span class="material-icons">search</span> Pesquisar
                                </button>
                                &nbsp;
                                <button type="button" (click)="onFieldsClean()" mat-raised-button color="primary">
                                    <span class="material-icons">backspace</span> Limpar
                                </button>
                            </td>
                        </tr>

                    </table>
                    <!-- end form -->
                </mat-card-content>
            </mat-card>
        </form>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="hasResult()" class="search-session">
        <mat-expansion-panel-header>
            <mat-panel-title class="search-session-title">
                <mat-icon class="material-icons">view_list</mat-icon> Resultados da pesquisa
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-card>
            <mat-card-header>
                <mat-card-title>Total de itens encontrados: <span *ngIf="hasResult()">{{ mtpLength }}</span></mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <table cellspacing="0">
                    <tr>
                        <td class="control-buttons" >
                            <span *ngIf="this.searchOnly==false">
                                <button *ngIf="this.selection.hasValue()" title="Destacar os imóveis selecionados no mapa." type="button" (click)="onHighlightSelectedFeatures()" mat-raised-button color="primary">
                                    <span class="material-icons">location_on</span> Destacar no mapa
                                </button>
                                <button disabled *ngIf="!this.selection.hasValue()" title="Selecione pelo menos um imóvel para destacar no mapa." type="button" (click)="onHighlightSelectedFeatures()" mat-raised-button color="primary">
                                    <span class="material-icons">location_on</span> Destacar no mapa
                                </button>
                                &nbsp;
                                <button *ngIf="enableAssociate() && this.selection.hasValue()" title="Associar inscrição a geometria no mapa" type="button" (click)="onAssociationAdd()" mat-raised-button color="accent">
                                    <span class="material-icons">add_location</span> Associar Geo
                                </button>
                                <button  disabled *ngIf="!enableAssociate() || this.selection.hasValue()==false" title="Associar inscrição a geometria no mapa" type="button" (click)="onAssociationAdd()" mat-raised-button color="accent">
                                    <span class="material-icons">add_location</span> Associar Geo
                                </button>
                                &nbsp;
                                <button *ngIf="this.selection.hasValue()" type="button" (click)="addHistoricoProcesso()" mat-raised-button color="accent" title="Adicionar histórico de processo para as inscrições selecionadas">
                                    <span class="material-icons">post_add</span> Adicionar Histórico de Processo
                                </button>
                                <button  disabled *ngIf="!this.selection.hasValue()" type="button" mat-raised-button color="accent" title="Adicionar histórico de processo para as inscrições selecionadas">
                                    <span class="material-icons">post_add</span> Adicionar Histórico de Processo
                                </button>
                                &nbsp;
                                <button *ngIf="this.selection.hasValue()" type="button" (click)="addDocuments()" mat-raised-button color="accent" title="Adicionar documentos para as inscrições selecionadas">
                                    <span class="material-icons">note_add</span> Adicionar Documentos do Imóvel
                                </button>
                                <button  disabled *ngIf="!this.selection.hasValue()" type="button" mat-raised-button color="accent" title="Adicionar documentos para as inscrições selecionadas">
                                    <span class="material-icons">note_add</span> Adicionar Documentos do Imóvel
                                </button>
                                &nbsp;
                                <button *ngIf="!this.selection.hasValue()" disabled type="button" mat-raised-button color="primary">
                                    <span class="material-icons">save_alt</span> Exportar
                                </button>
                                <button *ngIf="this.selection.hasValue()" type="button" (click)="onExportSelecteds()" mat-raised-button color="primary">
                                    <span class="material-icons">save_alt</span> Exportar
                                </button>

                                &nbsp;
                                <button type="button" title="Gerenciar fotos de fachadas para as incrições selecionadas." *ngIf="showFotosFachadaManager()" (click)="gerenciarFotosFachada()" mat-raised-button color="primary">
                                    <span class="material-icons">image</span> Gerenciar Fotos de Fachada
                                </button>
                                <button disabled type="button" title="Selecione uma ou mais inscrições do mesmo número predial." *ngIf="!showFotosFachadaManager()" (click)="gerenciarFotosFachada()" mat-raised-button color="primary">
                                    <span class="material-icons">image</span> Gerenciar Fotos de Fachada
                                </button>
                                &nbsp;
                                <button type="button" title="Ver informações do cadastro imobiliário para o imóvel selecionado." *ngIf="this.getSelectedInscricoes().length==1" (click)="showInfo()" mat-raised-button color="primary">
                                    <span class="material-icons">info</span> Informações Cadastro Imobiliário
                                </button>
                                <button disabled type="button" title="Selecione somente um imóvel para ver informações do cadastro imobiliário do imóvel selecionado." *ngIf="this.getSelectedInscricoes().length!=1" (click)="showInfo()" mat-raised-button color="primary">
                                    <span class="material-icons">info</span> Informações Cadastro Imobiliário
                                </button>
                            </span>   
                            <span *ngIf="this.searchOnly==true">
                                <button type="button" title="Selecionar inscrições" (click)="selectInscricoes()" mat-raised-button color="primary">
                                    <span class="material-icons">info</span> Selecionar inscrições
                                </button>
                            </span>                         

                        </td>
                    </tr>
                </table>
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort  (matSortChange)="sortData($event)">

                    <!-- Checkbox Column -->
                    <ng-container *ngIf="!enableAssociate()" matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                        [aria-label]="checkboxLabel()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)"
                                        [aria-label]="checkboxLabel(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>




                    <!-- setor Column -->
                    <ng-container matColumnDef="id" >
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="id"> id </th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container>

                    <!-- setor Column -->
                    <ng-container matColumnDef="setor">
                    <th mat-header-cell *matHeaderCellDef  mat-sort-header="setor"> Setor </th>
                    <td mat-cell *matCellDef="let element"> {{element.setor}} </td>
                    </ng-container>
                
                    <!-- quadra Column -->
                    <ng-container matColumnDef="quadra">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="quadra"> Quadra </th>
                    <td mat-cell *matCellDef="let element"> {{element.quadra}} </td>
                    </ng-container>
                
                    <!-- logradouro Column -->
                    <ng-container matColumnDef="logradouro" >
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="logradouro"> Logradouro </th>
                    <td mat-cell *matCellDef="let element"> {{element.logradouro}} </td>
                    </ng-container>
                
                    <!-- Lote Column -->
                    <ng-container matColumnDef="lote">
                    <th mat-header-cell *matHeaderCellDef  mat-sort-header="lote"> Lote </th>
                    <td mat-cell *matCellDef="let element"> {{element.lote}} </td>
                    </ng-container>

                    <!-- SubLote Column -->
                    <ng-container matColumnDef="sublote">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header="sublote"> SubLote </th>
                        <td mat-cell *matCellDef="let element"> {{element.sublote}} </td>
                    </ng-container>
                    
                    <!-- endereco_imovel Column -->
                    <ng-container matColumnDef="endereco_imovel">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header="endereco_imovel"> Endereço Imovel </th>
                        <td mat-cell *matCellDef="let element"> {{element.endereco_imovel}} </td>
                    </ng-container>
                    
                    <!-- numero_imovel Column -->
                    <ng-container matColumnDef="numero_imovel">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header="numero_imovel"> Número Imovel </th>
                        <td mat-cell *matCellDef="let element"> {{element.numero_imovel}} </td>
                    </ng-container>
                    
                    <!-- complemento_imovel Column -->
                    <ng-container matColumnDef="complemento_imovel">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header="complemento_imovel"> Complemento Imovel </th>
                        <td mat-cell *matCellDef="let element"> {{element.complemento_imovel}} </td>
                    </ng-container>

                    <!-- cep_imovel Column -->
                    <ng-container matColumnDef="cep_imovel">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header="cep_imovel"> CEP Imovel </th>
                        <td mat-cell *matCellDef="let element"> {{element.cep_imovel}} </td>
                    </ng-container>

                    <!-- bairro_imovel Column -->
                    <ng-container matColumnDef="bairro_imovel">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header="bairro_imovel"> Bairro Imovel </th>
                        <td mat-cell *matCellDef="let element"> {{element.bairro_imovel}} </td>
                    </ng-container>

                    <!-- status Column -->
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef  mat-sort-header="status"> Status </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="element.ativo? 'status-ativo' : 'status-inativo'">  {{element.ativo?'Ativo':'Inativo'}} </td>
                    </ng-container>

                    <!-- RADIO Column -->
                    <ng-container *ngIf="enableAssociate()" matColumnDef="radio">
                        <th mat-header-cell *matHeaderCellDef>Associar</th>
                        <td mat-cell *matCellDef="let row">
                            <mat-radio-group>
                                <mat-radio-button [value]="row.id"></mat-radio-button>
                            </mat-radio-group>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
                </table>
                <mat-paginator [showFirstLastButtons]="true" [length]="mtpLength" [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
                <table id="export-table-pesquisa-imovel">
                </table>
            </mat-card-content>
            
        </mat-card>
    </mat-expansion-panel>
</mat-accordion>