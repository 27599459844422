import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/map/dialog/dialog.component';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';
import { Inscricao } from 'src/app/map/vo/inscricao';
import { LayersInfo } from 'src/app/map/vo/layersinfo';
import { FeatureService } from 'src/app/service/feature.service';
import { Feature } from '../../../vo/feature';
import { FetureInfoToolComponent } from '../../fetureinfo-tool/fetureinfo-tool.component';
import { SettlementTool } from '../../settlement-tools/settlement-tool';
import { SettlementComponent } from '../../settlement-tools/settlement.component';
import { AttributeSearchFeatureInfo } from './attribute-search-feature-info';

export class AttributeSearchPropertyRegistration extends AttributeSearchFeatureInfo
{
    static customizedFeatureInfo: string = "attribute-search-property-registration-info";

    constructor(parent: MatDialog,  tool: any, feature: Feature, layerConfig: LayerConfig, private layerService: LayersConfigService, private featureService: FeatureService)
    {
        super(parent, tool, feature, layerConfig);        
    }
    showFeatureInfo()
    {
        this.tool.currentFeature = this.feature;
        this.tool.layer = this.layerConfig;

        //Getting IC and Id attribute config
        let inscricaoAttribute = this.layerService.getLayerInscricaoAttribute(this.layerConfig);
        let idAttribute = this.layerService.getLayerIdAttribute(this.layerConfig);                  

        //Getting properties from FeatureInfo
        let featureIdProperty = this.featureService.getPropertyByKey(this.feature.properties, idAttribute);
        let icProperty = this.featureService.getPropertyByKey(this.feature.properties, inscricaoAttribute);


        this.tool.displayDialog(new Inscricao(icProperty.value.replace('\n','')), featureIdProperty);
    }
}
