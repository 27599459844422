import { Property } from "./property";
import { ClonableObject } from "./clonable-object";

export class SettlementPropertyMembroFonteRenda extends ClonableObject
{
    id: number;
    id_cadastro_social_imovel_area_irregular: number;
    fonte_renda: string;
    
    constructor()
    {
        super();    
    }   

    fromProperties(properties: Array<Property>)
    {
        properties.forEach(property => 
        {
            let value : any = property.value;

            this[property.key.toString()] = value;
            
        });
    }
}
