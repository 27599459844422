import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../dialog/dialog.component';
import { ToolsService } from '../../tools.service';
import { AbstractLayerTool } from '../../abstract-layer-tool';
import { LayerConfig } from '../../../layers/layers.config';
import { ManageColetaPGVToolComponent } from './manage-coleta-pgv-tool.component';
import { Userinfo } from '@funcate/sigweb-cti-api';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { Property } from 'src/app/map/vo/property';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from 'src/app/map/vo/notification';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { UserGroup } from 'src/app/map/vo/user-group';
import { Tool } from '../../tool';

export class ManageColetaPGVTool extends Tool {

    private dialog: MatDialog;

    cursorType=null;
    id='manage-coleta-pgv-tool';
    enabled=false;
    name='Consultar Coleta PGV Tool';
    title='Consultar Coleta PGV';
    type='click';
    map:L.Map=null;
    icon='format_list_numbered';
    ll: any;
    protected visible:boolean=true;
    layerTool: boolean=false;
    customParameter=new Map<String, any>();
    notificationSubscrition: any;

    private dialogRef: any;

    constructor(dialog: MatDialog, toolsService: ToolsService, private canvasService: CanvasService, private layerService: LayersConfigService, private notificationService: NotificationService, private authenticationService: AuthenticationService) 
    { 
        super(toolsService)
        this.dialog = dialog;


        this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
            if (notification.event==AppNotification.LAYERS_LOADED) 
            {
              let userAuthenticated = this.authenticationService.getCurrentUser();
                if(userAuthenticated!=null)
                {
                    this.setColetaPGVLayerUserFilter(userAuthenticated);       
                }              
            }             
          });

    }

    public execAction=(event:L.LeafletMouseEvent)=>{};

    public enableImpl()
    {

        let currentLayerTool : LayerConfig = this.layerService.getColetaPGVLayer();
        if(currentLayerTool.isEnabled()==false)
        {
            this.layerService.enableLayer(currentLayerTool);
        }

        this.dialogRef = this.dialog.open(DialogComponent, {
            height: '90%',
            maxHeight: '100%',
            maxWidth: '100%',
            data: { component: ManageColetaPGVToolComponent, title: "Consultar Coleta PGV" }
        });

        let whenDialogIsOpen=this.dialogRef.afterOpened().toPromise();
        whenDialogIsOpen.then(
            ()=>{
                this.toolsService.dispatchTool(this);
            }
        );
        this.dialogRef.afterClosed().toPromise().then(()=>{
            this.toolsService.toogleTool(this);
        });
    }
    public disableImpl()
    {
    }

    public isVisibleForLayer(layer :LayerConfig) : boolean
    {
        return this.layerTool;
    }

    public isEnabled(layer :LayerConfig) : boolean
    {
        let currentLayerTool : LayerConfig = this.layerService.getColetaPGVLayer();
        if(this.enabled && currentLayerTool.getId()==layer.getId() )
        {
            return true;
        }
        else
        {
            return false;
        }
    }
    public closeDialog()
    {
        if(this.dialogRef)
        {
            this.dialogRef.close();
        }
    }

    public setColetaPGVLayerUserFilter(user: Userinfo)
    {
        if(user.authenticated)
        {

            let currentLayerTool : LayerConfig = this.layerService.getColetaPGVLayer();
            if(currentLayerTool)
            {
                let sourceAttribute = this.layerService.getLayerAppConfig(currentLayerTool, "source_attribute"); 
                if(this.isPGVUser())
                {
                    let source = "'"+user['user_pgv_source']+"'";
                    this.canvasService.setLayerFilter(new Property(sourceAttribute,source),currentLayerTool);    
                }  
                else
                {
                    //TODO: Deveria validar o tipo de usuário? Admin e editor?
                    this.canvasService.setLayerFilter(null,currentLayerTool);    
                }
                
            }
                     
        }        
    }
    public isPGVUser()
    {
      let user: Userinfo = this.authenticationService.getCurrentUser();
  
      if(user)
      {
        if(user['usergroup_id']==UserGroup.EDITION.id || user['usergroup_id']==UserGroup.ADMINISTRATOR.id)
        {
          return true;
        }
  
      }
      return false;
  
    }
    
}
