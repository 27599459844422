<mat-accordion class="search-coord-tool-accordion">
    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="layername">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header" class="layers-col">
                    <mat-icon class="material-icons layer-icon">approval</mat-icon> Digite uma coordenada para adicionar:
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            
            <div class="out-container">
              <div class="sidebar">
                <div class="search-coord-type">
                  <div class="search-coord-type-buttons">
                      <mat-button-toggle-group aria-label="" [(ngModel)]="searchCoordsType" vertical>
                          <mat-button-toggle *ngFor="let coordType of coordsType" value="{{coordType.id}}" class="coord-type-button-label" >{{coordType.name}}</mat-button-toggle>
                      </mat-button-toggle-group>
                  </div>
                </div>
              </div>
              <div class="content">
                <div *ngIf="searchCoordsType==1">
                  <div>
                    <mat-form-field appearance="fill">
                      <mat-label>X: </mat-label>
                      <input matInput type="text" class="property-value" (paste)="onPaste($event)" [(ngModel)]="utmCoordsX" id="utm-x" [decimalMarker]="'.'" [dropSpecialCharacters]="false" mask="0*.0*" [thousandSeparator]="','" required>
                    </mat-form-field>   
                    <mat-form-field appearance="fill">
                      <mat-label>Y: </mat-label>
                      <input matInput type="text" class="property-value"  (paste)="onPaste($event)" [(ngModel)]="utmCoordsY" id="utm-y" [decimalMarker]="'.'"  [dropSpecialCharacters]="false" mask="0*.0*" [thousandSeparator]="','" required>
                    </mat-form-field>                      
                  </div>

                </div>
                <div *ngIf="searchCoordsType==2">
                  <div>
                    <mat-form-field appearance="fill">
                      <mat-label>Longitude: </mat-label>
                      <input matInput type="text" class="property-value"  [(ngModel)]="geoCoordsDecimalX" [patterns]="customPatterns" [allowNegativeNumbers]="true" id="lat-decimal-x" [dropSpecialCharacters]="false" [decimalMarker]="'.'" [thousandSeparator]="','" required>
                    </mat-form-field>   
                    <mat-form-field appearance="fill">
                      <mat-label>Latitude: </mat-label>
                      <input matInput type="text" class="property-value"  [(ngModel)]="geoCoordsDecimalY" [patterns]="customPatterns" [allowNegativeNumbers]="true" id="lat-decimal-y" [dropSpecialCharacters]="false" [decimalMarker]="'.'" [thousandSeparator]="','" required>
                    </mat-form-field>                      
                  </div>
                </div>
                <div *ngIf="searchCoordsType==3">
                  <div class="coords-degrees-label"> Longitude (-00° 00'' 00.00'): </div>
                  <div class="coords-degrees">                    
                    <div>
                      <mat-form-field appearance="fill">
                        <mat-label> Graus </mat-label>
                        <input matInput type="text" class="property-value"  [(ngModel)]="geoCoordsDegreesX.degrees" prefix="" mask="-00" suffix="°" [decimalMarker]="'.'" [thousandSeparator]="','" required>
                      </mat-form-field>   
                    </div>
                    <div>
                      <mat-form-field appearance="fill">
                        <mat-label> Minutos </mat-label>
                        <input matInput type="text" class="property-value"  [(ngModel)]="geoCoordsDegreesX.minutes" prefix="" mask="00" suffix="''" [decimalMarker]="'.'" [thousandSeparator]="','" required>
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-form-field appearance="fill">
                        <mat-label> Segundos </mat-label>
                        <input matInput type="text" class="property-value"  [(ngModel)]="geoCoordsDegreesX.seconds" prefix="" mask="00.00" [dropSpecialCharacters]="false" suffix="'" [decimalMarker]="'.'" [thousandSeparator]="','" required>
                      </mat-form-field>   
                    </div>
                    <div>
                      <mat-form-field appearance="fill">
                        <mat-label> Região </mat-label>
                        <mat-select required [(ngModel)]="geoCoordsDegreesX.direction" class="property-value">
                          <mat-option value="E">Leste</mat-option>
                          <mat-option value="W">Oeste</mat-option>
                        </mat-select>
                      </mat-form-field>   
                    </div>
                  </div>
                  <div class="coords-degrees-label"> Latitude (-00° 00'' 00.00'): </div>
                  <div class="coords-degrees">
                     
                    <div>
                      <mat-form-field appearance="fill">
                        <mat-label> Graus </mat-label>
                        <input matInput type="text" class="property-value"  [(ngModel)]="geoCoordsDegreesY.degrees" prefix="" mask="-00" suffix="°" [decimalMarker]="'.'" [thousandSeparator]="','" required>
                      </mat-form-field>   
                    </div>
                    <div>
                      <mat-form-field appearance="fill">
                        <mat-label> Minutos </mat-label>
                        <input matInput type="text" class="property-value"  [(ngModel)]="geoCoordsDegreesY.minutes" prefix="" mask="00" suffix="''" [decimalMarker]="'.'" [thousandSeparator]="','" required>
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-form-field appearance="fill">
                        <mat-label> Segundos </mat-label>
                        <input matInput type="text" class="property-value"  [(ngModel)]="geoCoordsDegreesY.seconds" prefix="" mask="00.00" [dropSpecialCharacters]="false" suffix="'" [decimalMarker]="'.'" [thousandSeparator]="','" required>
                      </mat-form-field>   
                    </div>
                    <div>
                      <mat-form-field appearance="fill">
                        <mat-label> Região </mat-label>
                        <mat-select required [(ngModel)]="geoCoordsDegreesY.direction" class="property-value">
                          <mat-option value="N">Norte</mat-option>
                          <mat-option value="S">Sul</mat-option>
                        </mat-select>
                      </mat-form-field>   
                    </div>
                  </div>
                <div>                      
                  
                  </div>

                </div>

                <div class="buttons">

                  <button mat-raised-button color="primary" (click)="addCoord()">Adicionar</button> 

              </div>

            </div>  

            


              
          </div>

          <div id="coordinates-div" *ngIf="coordinates.length!=0">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

              <ng-container matColumnDef="index">
                <th mat-header-cell *matHeaderCellDef > Coordanada </th>
                <td mat-cell *matCellDef="let element; let i = index;">{{i+1}}</td>
             </ng-container>    
            
              <!-- Name Column -->
              <ng-container matColumnDef="x">
                <th mat-header-cell *matHeaderCellDef> X </th>
                <td mat-cell *matCellDef="let element"> {{element.x}} </td>
              </ng-container>
            
              <!-- Weight Column -->
              <ng-container matColumnDef="y">
                <th mat-header-cell *matHeaderCellDef> Y </th>
                <td mat-cell *matCellDef="let element"> {{element.y}} </td>
              </ng-container>
                     
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>    
          <div class="buttons"  *ngIf="coordinates.length!=0">

            <button mat-raised-button color="primary" (click)="addGeom()">Adicionar Geometria</button> 
            <button mat-raised-button color="primary" (click)="clear()">Limpar</button> 

          </div>



        </mat-expansion-panel>
    </ng-container>
</mat-accordion>