import { Copyadditionalimoveldata, EditionoperationsInner } from "@funcate/sigweb-cti-api";
import { Collection, Feature } from "ol";
import GeoJSON from 'ol/format/GeoJSON';
import Polygon from "ol/geom/Polygon";
import VectorSource from "ol/source/Vector";
import { DialogComponent } from "src/app/map/dialog/dialog.component";
import { GenericConfirmationDialogComponent } from "src/app/map/dialog/generic-confirmation-dialog/generic-confirmation-dialog.component";
import { LayersConfigService } from "src/app/map/layers/layers.config.service";
import { NewImovelLink } from "src/app/map/vo/new-imovel-link";
import { Operation } from "src/app/map/vo/operation";
import { BackendService } from "src/app/service/backend.service";
import { FeatureService } from "src/app/service/feature.service";
import { GeometryService } from "src/app/service/geometry.service";
import { ToastService } from "src/app/service/toast.service";
import { Step } from "../../../vo/step";
import { AddInteraction } from "../../tools-utils/custom-edition-interaction/interaction/add-interaction";
import { SelectInteraction } from "../../tools-utils/custom-edition-interaction/interaction/select-interaction";
import { SetupImoveisLinkToolComponent } from "../setup-imoveis-link-tool.component";
import { DesdobroSearchInscricaoSpatilizeStep } from "../steps/desdobro-search-inscricao-spatialize";
import { AnexacaoSearchInscricaoSpatilizeStep } from "../steps/anexacao-search-inscricao-spatialize";
import { SubstituicaoSearchInscricaoSpatilizeStep } from "./substituicao-search-inscricao-spatialize";

export class SearchInscricaoSpatilizeStep extends Step
 {
    
    currentStep: any;
    constructor(index: number, private setupImoveisLinkToolComponent: SetupImoveisLinkToolComponent, private toastService: ToastService, 
      private backendService: BackendService, private layersConfigService: LayersConfigService, private featureService: FeatureService)    {       
        super(index);
       
    }
    execute()
    {
      if(this.setupImoveisLinkToolComponent.selectedOperation=='desdobramento')
      {
        this.currentStep = new DesdobroSearchInscricaoSpatilizeStep(super.getIndex(),
          this.setupImoveisLinkToolComponent, this.toastService, this.backendService, this.layersConfigService, this.featureService);
      } else if(this.setupImoveisLinkToolComponent.selectedOperation=='substituicao')
      {
        this.currentStep = new SubstituicaoSearchInscricaoSpatilizeStep(super.getIndex(),
          this.setupImoveisLinkToolComponent, this.toastService, this.backendService, this.layersConfigService, this.featureService);
      }
      else
      {
        this.currentStep = new AnexacaoSearchInscricaoSpatilizeStep(super.getIndex(),
        this.setupImoveisLinkToolComponent, this.toastService, this.backendService, this.layersConfigService, this.featureService);
      }
      
      this.setTitle(this.currentStep.getTitle());
      this.setDescription(this.currentStep.getDescription());
      this.currentStep.execute();
    }
    callback(object: any)
    {
      this.currentStep.callback(object);
    }
    rollback()
    {

    }
    finish()
    {
      this.currentStep.finish();
    }
    valid()
    {
      return this.currentStep.valid();
    }

    public onFeatureSelected(selectedFeature: Feature<any>)
    {
      this.currentStep.onFeatureSelected(selectedFeature);      
    }
   
    public onFeatureInserted(feature: Feature<any>)
    {
      this.currentStep.onFeatureInserted(feature);      
    }


}
