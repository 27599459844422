import { MatDialog } from '@angular/material/dialog';
import { ToolsService } from '../tools.service';
import { Tool } from '../tool';
import { DialogComponent } from '../../dialog/dialog.component';
import { SecurityManagementToolComponent } from './security-management-tool.component';


export class SecurityManagementTool extends Tool {

    cursorType='tool-cursor-default';
    id='security-management-tool';
    enabled=false;
    protected visible:boolean=true;
    name='Security Management Tool';
    title='Gerencia de Segurança';
    type='click';
    icon='admin_panel_settings';
    layerTool: boolean=false;
    customParameter=new Map<String, any>();
    private dialogRef: any;

    private dialog: MatDialog;
    
    constructor(dialog: MatDialog, toolsService: ToolsService) {
        super(toolsService)
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{}

    public enableImpl()
    {
        this.dialogRef = this.dialog.open(DialogComponent, {
            height: '70%',
            width: '70%',
            data: { component: SecurityManagementToolComponent, title: "Gerencia de Segurança" }
        });

         let whenDialogIsOpen=this.dialogRef.afterOpened().toPromise();
         whenDialogIsOpen.then(
             ()=>{
                 this.toolsService.dispatchTool(this);
             }
         );
        this.dialogRef.afterClosed().toPromise().then(()=>{
            this.toolsService.toogleTool(this);
        });
    }
    public disableImpl()
    {
    }
    public closeDialog()
    {
        this.dialogRef.close();
    }
}
