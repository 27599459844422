import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Fichasresultado, FichasresultadoInner } from '@funcate/sigweb-cti-api';
import { environment } from '../../environments/environment';
import { DialogComponent } from '../map/dialog/dialog.component';
import { FichaPreviewComponent } from '../map/tools/property-registration/ficha-preview/ficha-preview.component';
import { AuthenticationService } from './authentication.service';
import { BackendService } from './backend.service';
import { GeneralService } from './general.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class FichaService {

  constructor(private generalService: GeneralService, private authenticationService: AuthenticationService, private toastService: ToastService, private backendService: BackendService) { }
  
  public getFicha(id:string, extension:string, dialog: MatDialog)
  {
    let outputFilename=id+"."+extension.trim().toLowerCase();
    let url = environment.BACKEND_API_BASE_PATH+'/cadastro/ficha/'+outputFilename;
    if(extension=="pdf" || extension=="jpg" || extension=="png" || extension=="tif")
    {
      let dataType = this.generalService.getDataTypeByFileExtension(extension.toLowerCase());

      let dialogRef = dialog.open(DialogComponent, {
        height: '90%',
        width: '95%',
        data: { component: FichaPreviewComponent, title: "Ficha de Imovel", fichaUrl: url, fichaDataType: dataType}
      });
    }
    else
    {
      let user = this.authenticationService.getCurrentUser();
      if(!user)
      {
        this.toastService.error("Usuário não está autenticado corretamente. Efetue o login novamente.", "Problemas ao baixar ficha do imovel.");
      }
      else
      {
        this.generalService.downloadFileGetMethod(url, outputFilename, user.token);
      }
      
    }
  }
  public getFichaIcon(ficha: any)
  {
    let icon = "download"
    let tipo = (ficha.tipo+"").toLowerCase();
    if(tipo=='jpg' || tipo=='png' || tipo=='pdf' || tipo=='tiff')
    {
      return "preview";
    }
  }

  public desassociarFicha(inscricao: string, fichaId: string) : Promise<any>
  {
    let fichaResultado: FichasresultadoInner = new Object();

    fichaResultado.associada=false;
    fichaResultado.id=fichaId.toString();

    let fichasResultado: Fichasresultado = [];

    fichasResultado.push(fichaResultado);

    return this.backendService.setFichasForInscricao(fichasResultado, inscricao);
    
  }
}
