import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Featureattributes } from '@funcate/sigweb-cti-api';
import { Subscription } from 'rxjs';
import { EditionService } from 'src/app/service/edition.service';
import { FeatureService } from 'src/app/service/feature.service';
import { CanvasService } from '../../canvas/canvas.service';
import { LayerConfig } from '../../layers/layers.config';
import { LayersConfigService } from '../../layers/layers.config.service';
import { Feature } from '../../vo/feature';
import { Property } from '../../vo/property';
import { ToolsService } from '../tools.service';
import { EditAttributeLayerTool } from './edit-attribute-layer-tool';

@Component({
  selector: 'app-edit-attribute-layer-tool',
  templateUrl: './edit-attribute-layer-tool.component.html',
  styleUrls: ['./edit-attribute-layer-tool.component.css']
})

export class EditAttributeLayerToolComponent implements OnInit 
{
  private tool:EditAttributeLayerTool;
  private subscriptionTool:Subscription;
  public layer: LayerConfig;
  public idAttribute: string;
  displayedColumns: string[] = ['key', 'value'];
  public currentFeature: Feature;
  public features: Array<Feature>=[];
  @ViewChildren('attributesElements') attributesElements;
  editIntegrationObject: any;
  

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private toolsService: ToolsService,
    private layerConfigService: LayersConfigService,
    private featureService: FeatureService,
    private canvasService:CanvasService,
    private editionService: EditionService) {
    this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (tool:EditAttributeLayerTool) => {
        this.tool=tool;
        this.update();
        console.log("Tool acionou selecão de geometria");
      },()=> {
        console.log("Falhou ao selecionar geometria para edição");
      }
    );


    if(data && data.editIntegrationObject && data.editIntegrationObject.feature)
    {
     this.editIntegrationObject = data.editIntegrationObject
     this.tool = this.editIntegrationObject.tool;
     this.layer = this.editIntegrationObject.layerConfig;     

     this.populateInitialData(this.editIntegrationObject.feature);
    }
   }

  ngOnInit(): void 
  {

  }

  private populateInitialData(feature)
  {

    this.idAttribute=this.layerConfigService.getLayerIdAttribute(this.layer);

    this.populateFeature(feature);
  }

  public populateFeature(feature: any)
  {
    let featureObject = new Feature();                    

    let idValueProperty = this.featureService.getPropertyByKey(feature.properties, this.idAttribute);                   

    
    if(idValueProperty)
    {
      featureObject.id = idValueProperty.value.toString();                      

      let layerTableName = this.layerConfigService.getLayerTable(this.layer);

      
      this.editionService.getFeatureAttributes(layerTableName, this.idAttribute, idValueProperty.value.toString()).then((featureattributes: Featureattributes)=>
      {
  
        let properties : Array<Property> = this.featureService.getFeatureProperties(featureattributes, this.layer);    
  
        featureObject.properties=properties;
  
        this.features.push(featureObject);     
        
        if(this.currentFeature==null)
        {
          this.currentFeature=featureObject;
        }
                                
      });

    }
    
  }

  private update() 
  {
    if (this.tool) 
    {
      this.layer = this.toolsService.getToolParameter(this.tool, "layer");

      if (this.layerConfigService.isVectorLayer(this.layer))
      {
        this.idAttribute=this.layerConfigService.getLayerIdAttribute(this.layer);
        this.tool.setFeature(null);
        this.canvasService.getMap().eachLayer((l) => {
          if (l["wmsParams"])
          {

            if (l["id"] == this.layer.getId())
            {
              this.featureService.getFeatureInfo(this.tool.ll, l, this.canvasService.getMap()).then(featureInfo => {

                if (featureInfo)
                {
                  this.features=[];

                  featureInfo.features.forEach(feature => 
                    {                    

                    this.populateFeature(feature);
                    
                  });



                  // if (featureInfo.features.length > 0)
                  // {

                  //   let idValueProperty = this.featureService.getPropertyByKey(featureInfo.features[0].properties, this.idAttribute);
                    
                  //   if(idValueProperty)
                  //   {
                  //     let layerTableName = this.layerConfigService.getLayerTable(this.layer);

                  //     this.editionService.getFeatureAttributes(layerTableName, this.idAttribute, idValueProperty.value.toString()).then((featureattributes: Featureattributes)=>
                  //     {
                  //       this.features = 
                  //       this.loadFeatureProperties(featureattributes);    
                  //     });
                  //   }
                  // }
                }
              });

            }

          }
        });
      }

    }

  }

  public save()
  {
    if(this.currentFeature!=null)
    {
      let inputs = this.attributesElements.toArray();
      inputs.forEach(input => {
        this.currentFeature.properties.forEach(attribute=> {
          if(input.nativeElement.id==attribute.key)
          {
            attribute.value=input.nativeElement.value;
          }
        });
        
      });
      this.editionService.updateAttributes(this.currentFeature.properties, this.layer).then(featureInfo => {
        console.log('Saved');
        this.close();
      });
    }
    else
    {
      console.log("Current feature não está definido.");
    }
  }

  public close()
  {
    this.tool.closeDialog();
  }
}
