import { ClonableObject } from "./clonable-object";
import { Property } from "./property";

export class SettlementPropertyMembroProgramaSocial  extends ClonableObject
{
    id: number;
    id_cadastro_social_imovel_area_irregular: number;
    programa_social: string;
    
    constructor()
    {    
        super();
    }

    fromProperties(properties: Array<Property>)
    {
        properties.forEach(property => 
        {
            let value : any = property.value;

            this[property.key.toString()] = value;
            
        });
    }
}
