import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { LayerConfig } from '../../../layers/layers.config';
import { Property } from '../../../vo/property';
import { ToolsService } from '../../tools.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditionService } from 'src/app/service/edition.service';
import { FeatureService } from 'src/app/service/feature.service';
import { Userinfo } from '@funcate/sigweb-cti-api';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { DatePipe } from '@angular/common';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { DialogComponent } from 'src/app/map/dialog/dialog.component';
import { GenericConfirmationDialogComponent } from 'src/app/map/dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { Operation } from 'src/app/map/vo/operation';
import { Annotation } from 'src/app/map/vo/annotation';
import { GeomType } from 'src/app/map/vo/geomtype';
import { EditionOperation } from 'src/app/map/vo/edition-operation';
import { Toast } from 'ngx-toastr';
import { AnnotationTool } from '../annotation-tool';
import { ToastService } from 'src/app/service/toast.service';
import { UserGroup } from 'src/app/map/vo/user-group';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';


@Component({
  selector: 'manage-annotation-tool-tool',
  templateUrl: './manage-annotation-tool.component.html',
  styleUrls: ['./manage-annotation-tool.component.css']
})


export class ManageAnnotationToolComponent implements AfterViewInit
{
  private tool:AnnotationTool;
  private subscriptionTool:Subscription;
  public idAttribute: string;
  displayedColumns: string[] = ['id','title','desc','count', 'acoes' ];     
  public attributes: Array<Property>=[];
  public pgvSources: Array<any>=[];
  public annotations: Array<Annotation>;
  private confirmDialogRef: any;
  private editDialogRef: any;
  dataSource : MatTableDataSource<Annotation>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    private toolsService: ToolsService, private featureService: FeatureService, 
    private layerService: LayersConfigService, private authenticationService: AuthenticationService, private canvasService: CanvasService, private editionService: EditionService, private toastService: ToastService, private dialog: MatDialog) {
      this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (tool:AnnotationTool) => {
        this.tool=tool;
        this.update();
      },()=> {
      }
    );
   }

  ngOnInit(): void
  {
  }

  ngAfterViewInit() 
  {
   
  }  

  private update() 
  {
    if (this.tool) 
    {     
      this.loadAnnotations(this.authenticationService.getCurrentUser());     
    }
  }

  private loadAnnotations(user: Userinfo)
  {
    this.annotations = [];


    {
      let annotation = new Annotation();
      annotation.setTitle("Anotações Habitação");
      annotation.setDescription("Anotações do Setor de Habitação");
      annotation.setGroupId(user.usergroupId);
      annotation.setId(0);
      annotation.setOwnerUserId(Number.parseInt(user.id));
      //annotation.set
      this.annotations.push(annotation);
    }
    //this.annotations;

    this.dataSource = new MatTableDataSource<Annotation>(this.annotations);

    
  }

  public formatDate(date: Date, format: string) : string
  {
    let data="";
    if(date)
    {
      let datePipe = new DatePipe('pt-BR');
      data = datePipe.transform(date.toString(), format);
    }
 

    return data;
  }

  public fit(annotation: Annotation)
  {
    
    //this.canvasService.fitFeatures([annotation.getFeatureGeoJSON()]);
  }

  public confirmDelete(annotation: Annotation)
  {

 
    this.confirmDialogRef = this.dialog.open(DialogComponent, {
      height: '30%',
      width: '20%',
      data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente apagar a coleta?" }
    });

    this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
      if(confirmResult)
      {
         this.delete(annotation);
      }

    });
  }

  private delete(annotation: Annotation)
  {
    // if(this.tool.isPGVUser())
    // {
    //   let editionOperation: EditionOperation = new EditionOperation(coleta.getId(), coleta.getFeatureGeoJSON(), GeomType.Point, Operation.Delete);
      
    //   this.editionService.deleteGeom(editionOperation, this.layer).then((operation)=>{

    //     this.update();
    //     this.toastService.sucess("Ponto de coleta PGV removido com sucesso!", "Ponto removido!!");
    //     this.canvasService.redrawLayer(this.layer);

    //   });
    // }
  }

  public edit(annotation: Annotation)
  {
    // if(this.tool.isPGVUser())
    // {
    //   this.editDialogRef = this.dialog.open(DialogComponent, {
    //     height: '60%',
    //     width: '40%',
    //     data: { component: EditColetaPGVToolComponent, title: "Editar dados coleta PGV", coleta: coleta, layer: this.layer, parent: this  }
    //   });
    // }   
  }

  sortData(sort: Sort) {

    this.dataSource.sort = this.sort;
    const data = this.annotations.slice();
    if (!sort.active || sort.direction === '') {
      this.annotations = data;
      return;
    }

    this.annotations.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'title': return this.compare(a.getTitle(), b.getTitle(), isAsc);
        case 'desc': return this.compare(a.getDescription(), b.getDescription(), isAsc);
        default: return 0;
      }
    });
    this.dataSource = new MatTableDataSource<Annotation>(this.annotations);
    this.dataSource.paginator = this.paginator;
    this.defineFilterPredicate();
  } 
  private compare(a: number | string, b: number | string, isAsc: boolean) 
  {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private defineFilterPredicate()
  {
    this.dataSource.filterPredicate = function(data, filter: string): boolean 
    {
      let datePipe = new DatePipe('pt-BR');

      if(data.getTitle() && data.getTitle().toLowerCase().includes(filter))
      {
        return true;
      }

      if(data.getDescription() && data.getDescription().toLowerCase().includes(filter))
      {
        return true;
      }

     
      return false;

    };
  }

  public getElementCount(annotation: Annotation) : number
  {
    return 10;
  }



  
}
