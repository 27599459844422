<div *ngIf="splitTool && splitTool.selectedFeature" class="fullFrame">
    
    <input type="hidden" value="{{splitTool.selectedFeature.getGeometryJSON()}}" id="split-tool-geom">
    <input type="hidden" value="{{currentFeatureId}}" id="split-tool-geomid">
    <input type="hidden" value="{{currentFeatureSRID}}" id="split-tool-geomsrs">
    <input type="hidden" value="{{splitToolBaseLayer.getId()}}" id="split-tool-baselayer-id">
    <input type="hidden" value="{{getBaseLayerURL()}}" id="split-tool-baselayer-url">
    <input type="hidden" value="{{getBaseLayerName()}}" id="split-tool-baselayer-layername">

    <input type="hidden" value="{{edificationFeatures}}" id="split-tool-edifications-features-json">    
    <input type="hidden" value="{{newEdificationFeaturesJSON}}" id="split-tool-new-edifications-features-json">    
    <input type="hidden" value="{{removedEdificationFeaturesJSON}}" id="split-tool-removed-edifications-features-json">    

    <div id="split-tool-form">
        <input type="hidden" id="split-tool-feature-id" name="id" #id >
        <input type="hidden" id="split-tool-geom1-json" name="geom1" #geom1>
        <input type="hidden" id="split-tool-geom2-json" name="geom2" #geom2>
        
        <button mat-raised-button color="primary" id="split-tool-submit-form" (click)="saveSplit(id.value, geom1.value, geom2.value)">
            <span class="material-icons">save</span> Save
        </button>     
    </div>

    <iframe src="assets/external/lote-tools/html/desmembramento.html" class="fullFrame"  frameBorder="0"></iframe>

</div>
