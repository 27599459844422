export class Style {
    id:string;
    name:string;
    title:string;
    legendURL:string;
    enabled: boolean;

    clone()
    {
        let style = new Style();
        style.id=this.id;
        style.name=this.name;
        style.title=this.title;
        style.legendURL=this.legendURL;
        style.enabled=this.enabled;
        return style;
    }
}
