import { Property } from "./property";
import { SettlementPropertyPatologiaAparente } from "./settlement-property-patologia-aparente";
import { SettlementPropertyUsoExterno } from "./settlement-property-uso-externo";
import { SettlementPropertyMembro } from "./settlement-property-membro";

export class SettlementProperty
{
    //Identificação
    id: number;
    inscricao: string;
    nucleo: string;
    quadra: string;
    logradouro: string;
    edif: string;
    subedif: string;

    //Endereço
    nome_logradouro: string;
    numero_oficial: string;
    numero_cadastro: string;
    emplacamento_sabesp: string;
    emplacamento_cpfl: string;
    complemento: string;
    bairro: string;
    cep: string;

    //Caracteristicas
    area_construida_levantada: number;
    area_construida_geo: number;
    uso_imovel: string;
    ocupacao: string;
    acesso_lote: string;
    acesso_domicilio: string;    
    tipologia_edificacao: string;
    status_construcao: string;
    situacao_consolidacao: string;
    material_construtivo: string;
    revestimento_externo: string;
    material_cobertura: string;
    tipo_lote: string;
    total_quartos: number;
    quantidade_pessoas_cada_quarto: number;
    total_banheiros: number;
    cavalete_agua: string;
    poste_padrao_energia: string;
    abastecimento_agua: string;
    esgotamento_sanitario: string;
    energia_eletrica: string;
    ha_comodos_alugados_ou_cedidos: boolean;
    
    //Tabela Auxiliar
    patologias_aparentes: SettlementPropertyPatologiaAparente[];
    //Tabela Auxiliar
    usos_externos: SettlementPropertyUsoExterno[];

    cadastro_social: SettlementPropertyMembro[];

    documentacao_titular: any[];

    documentacao_sehab: any[];

    //TSU

    possui_tsu: boolean;
    tsu_possuidor_carne: string;
    tsu_inscricao: string;
    tsu_cpf_possuidor: string;
    tsu_logradouro: string;
    tsu_numero: string;
    tsu_complemento: string;
    tsu_bairro: string;
    tsu_cidade: string;
    tsu_cep: string;

    
    
    constructor()
    {
        this.patologias_aparentes=[];
        this.usos_externos=[];
        this.cadastro_social=[];
    }

    fromProperties(properties: Array<Property>)
    {

        properties.forEach(property => 
        {
            if(property.key.toString()=="patologias_aparentes")
            {
                this.fromPatologiasAuxiliarTable(property.value);
            }
            else if(property.key.toString()=="usos_externos")
            {
                this.fromUsosExternosAuxiliarTable(property.value);
            } else if(property.key.toString()=="cadastro_social")
            {
                this.fromCadastroSocialAuxiliarTable(property.value);
            } else
            {
                let value : any = property.value;

                this[property.key.toString()] = value;    
            }
        });
    }

    private fromPatologiasAuxiliarTable(tableRows: any)
    {
        this.patologias_aparentes=[];
        tableRows.forEach(patologia_aparente => {
            let patologiaAparente: SettlementPropertyPatologiaAparente = new SettlementPropertyPatologiaAparente();
            patologiaAparente.fromProperties(patologia_aparente);
            this.patologias_aparentes.push(patologiaAparente);
        });
    }

    private fromUsosExternosAuxiliarTable(tableRows: any)
    {
        this.usos_externos=[];
        tableRows.forEach(uso_externo => {
            let usoExterno: SettlementPropertyUsoExterno = new SettlementPropertyUsoExterno();
            usoExterno.fromProperties(uso_externo);
            this.usos_externos.push(usoExterno);
        });
    }

    private fromCadastroSocialAuxiliarTable(tableRows: any)
    {
        this.cadastro_social=[];
        tableRows.forEach(member => {
            let newMember: SettlementPropertyMembro = new SettlementPropertyMembro();
            newMember.fromProperties(member);
            this.cadastro_social.push(newMember);
        });
    }

    getPatologiasAparentesAsString() : string[]
    {
        let patologias : string[] = [];

        this.patologias_aparentes.forEach(patologia => {
            patologias.push(patologia.patologia);
        });

        return patologias;
    }
    setPatologiasAparentesAsString(patologia: string, selecionado: boolean)
    {
        let selectedPatologia : SettlementPropertyPatologiaAparente;
        
        for (let i = 0; i < this.patologias_aparentes.length; i++) {
            const item = this.patologias_aparentes[i];            
        
            if(item.patologia == patologia)
            {
                selectedPatologia = item;
            }
        };

        if(selecionado==false)
        {
            if(selectedPatologia)
            {
                const index = this.patologias_aparentes.indexOf(selectedPatologia);
                this.patologias_aparentes.splice(index,1);
            }
        }
        else
        {
            if(selectedPatologia==null)
            {
                let newPatologia = new SettlementPropertyPatologiaAparente();
                newPatologia.patologia=patologia;
                newPatologia.id_imovel_area_irregular=this.id;
                this.patologias_aparentes.push(newPatologia);
            }
        }
      
        

    }

    getUsoExternoAsString() : string[]
    {
        let usos_externos : string[] = [];

        this.usos_externos.forEach(uso_externo => {
            usos_externos.push(uso_externo.uso_externo);
        });

        return usos_externos;
    }
    setUsoExternoAsString(usoExterno: string, selecionado: boolean)
    {
        let selectedUsoExterno : SettlementPropertyUsoExterno;
        
        for (let i = 0; i < this.usos_externos.length; i++) {
            const item = this.usos_externos[i];            
        
            if(item.uso_externo == usoExterno)
            {
                selectedUsoExterno = item;
            }
        };

        if(selecionado==false)
        {
            if(selectedUsoExterno)
            {
                const index = this.usos_externos.indexOf(selectedUsoExterno);
                this.usos_externos.splice(index,1);
            }
        }
        else
        {
            if(selectedUsoExterno==null)
            {
                let newUso = new SettlementPropertyUsoExterno();
                newUso.uso_externo=usoExterno;
                newUso.id_imovel_area_irregular=this.id;
                this.usos_externos.push(newUso);
            }
        }
      
        

    }
}
