import { MatDialog } from '@angular/material/dialog';
import { EditAttributeLayerToolComponent } from './edit-attribute-layer-tool.component';
import { DialogComponent } from '../../dialog/dialog.component';
import { ToolsService } from '../tools.service';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { LayerConfig } from '../../layers/layers.config';
import { Feature } from '../../vo/feature';
import { LayersConfigService } from '../../layers/layers.config.service';
import { CanvasService } from '../../canvas/canvas.service';

export class EditAttributeLayerTool extends AbstractLayerTool {

    private dialog: MatDialog;

    cursorType='tool-cursor-pointer';
    id='edit-attribute-layer-tool';
    enabled=false;
    name='Edit Attribute Tool';
    title='Editar Atributos';
    type='click';
    icon='table_view';
    ll: any;
    protected visible:boolean=true;
    layerTool: boolean=true;
    customParameter=new Map<String, any>();
    private selectedFeature: Feature;
    private dialogRef: any;
    layer: LayerConfig;

    constructor(dialog: MatDialog, toolsService: ToolsService, private layerConfigService: LayersConfigService, private canvasService: CanvasService) 
    { 
        super(toolsService)
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{
        this.ll=event.latlng;
      
        this.openEditAttribute();

        let whenDialogIsOpen=this.dialogRef.afterOpened().toPromise();
        whenDialogIsOpen.then(
            ()=>{
                this.toolsService.dispatchTool(this);
            }
        );
        this.dialogRef.afterClosed().toPromise().then(()=>{
            let layer : LayerConfig = this.toolsService.getToolParameter(this, "layer");
            this.canvasService.redrawLayer(layer);
        });
    };

    public openEditAttribute(editIntegrationObject: any=null)
    {
        this.dialogRef = this.dialog.open(DialogComponent, {
            height: '80%',
            width: '50%',
            data: { component: EditAttributeLayerToolComponent, title: "Modificar Atributos", editIntegrationObject: editIntegrationObject }
        });
        return this.dialogRef;
    }

    public closeDialog()
    {
        this.dialogRef.close();
    }

    public enableImpl()
    {
        let layer : LayerConfig = this.toolsService.getToolParameter(this, "layer");
        if(layer.isEnabled()==false)
        {
            this.layerConfigService.enableLayer(layer);
        }
    }
    public disableImpl()
    {
    }

    public isVisibleForLayer(layer :LayerConfig) : boolean
    {
        return this.layerTool;
    }

    public getFeature() : Feature
    {
        return this.selectedFeature;
    }

    public setFeature(feature: Feature)
    {
        this.selectedFeature = feature;
    }

    public isEnabled(layer :LayerConfig) : boolean
    {
        let currentLayerTool = this.toolsService.getToolParameter(this, "layer");
        if(this.enabled && currentLayerTool.getId()==layer.getId() )
        {
            return true;
        }
        else
        {
            return false;
        }
    }
    
}
