import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Importlayerfileinfo } from '@funcate/sigweb-cti-api';
import { DialogComponent } from 'src/app/map/dialog/dialog.component';
import { GenericConfirmationDialogComponent } from 'src/app/map/dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { Style } from 'src/app/map/vo/style';
import { BackendService } from 'src/app/service/backend.service';
import { StyleService } from 'src/app/service/style.service';
import { ToastService } from 'src/app/service/toast.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upload-style-sld-file',
  templateUrl: './upload-style-sld-file.component.html',
  styleUrls: ['./upload-style-sld-file.component.css']
})

export class UploadStyleSldFileComponent implements OnInit 
{
  legendPreviewURL: string;
  currentSLDFilename: string;
  uploadingFile: boolean;
  fileUploadControl: FormGroup;
  layer: LayerConfig; 
  sldPreviewName: string;
  currentStyle: Style;
  newStyle: boolean;
  parent: any;
  currentSLDFilePath: string;
  importSLDFileInfo: Importlayerfileinfo;
  deleteConfirmDialogRef: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
   private toastService: ToastService,
    private backendService: BackendService,
    private styleService: StyleService) 
  {
    this.currentSLDFilePath=""
    this.newStyle = true;
    this.layer = data.layer;
    this.parent = data.parent;
    if(data.currentStyle)
    {
      this.currentStyle = data.currentStyle.clone();
      this.currentStyle.id=styleService.getStyleIdWithoutWorkspace(this.currentStyle);
      this.newStyle = false;
      this.legendPreviewURL = this.currentStyle.legendURL;
    }
    else
    {
      this.currentStyle = new Style();
      this.currentStyle.id = this.layer.getId();



    }
    this.fileUploadControl = new FormGroup(
      {
        file: new FormControl()
      }
    );    

  }



  ngOnInit(): void 
  {
    this.currentSLDFilename=""
  }

  close()
  {
    if(this.parent)
    {
      this.parent.closeUploadStyleDialog();
    }
  }

  save()
  {
    this.backendService.createStyle(this.currentSLDFilePath, this.currentStyle.id, this.layer.getId(), this.currentStyle.name, this.currentStyle.title).then(()=>
    {      
      this.toastService.sucess("Estilo adicionado com sucesso.", "Sucesso");

      this.close();
    }).catch(()=>{
      this.toastService.error("Problema ao importar o estilo para a camada selecionada.", "Erro");
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) 
    {        
      this.uploadingFile = true;
      this.currentSLDFilename = event.target.files[0].name;
      const file = event.target.files[0];
      this.fileUploadControl.get('file').setValue(file);
      this.uploadFile(file);
    }
    else
    {
      this.uploadingFile= false;
      this.toastService.error("Selecione um arquivo SLD válido.", "Atenção");
    }
  }
  private uploadFile(file: any)
  {      
    if(file)
    {
      let layerFile: Blob = new Blob([file], { type: 'application/octet-stream' });

      this.backendService.uploadSldFile(this.layer.getId(), file.name, layerFile).then((returnImportLayerInfo: Importlayerfileinfo)=>
      {
        if(returnImportLayerInfo.error==false)
        {
          //this.importLayerComponent.importlayerfileinfo.sldfields=returnImportLayerInfo.sldfields;
          //this.importLayerComponent.importlayerfileinfo.sldfilepath=returnImportLayerInfo.sldfilepath;
          this.sldPreviewName=returnImportLayerInfo.sldpreviewname;
          this.currentSLDFilePath = returnImportLayerInfo.sldfilepath;
          if(this.sldPreviewName)
          {
            this.loadLegendPreview(this.sldPreviewName)
          }
          else
          {
            this.legendPreviewURL = null;
          }
          
          this.uploadingFile= false;
          this.toastService.sucess("Estilo carregado.","Sucesso");
        }
        else
        {
          this.uploadingFile= false;
          this.toastService.error("Não foi possível carregar o arquivo para o servidor. " + returnImportLayerInfo.msg, "Erro");
        }                   
        
      }).catch((e)=>
      {
        this.uploadingFile= false;
        console.log(e);
        this.toastService.error("Problema ao subir arquivo para o servidor.","Erro");
      })
    }
    else
    {
      this.uploadingFile= false;
      this.toastService.error("Favor selecionar primeiramente um arquivo.","Erro");
    }
  }
  private loadLegendPreview(legendPreviewStyleName)
  {
    let geoserverURL = environment.GEOSERVER_URL_DOMAIN+environment.GEOSERVER_URL_PATH; 

    let anticfloat = (Math.floor( Math.random() * 2000000 ) + 1)/21;
    this.legendPreviewURL = geoserverURL+"?SERVICE=WMS&REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=legend-preview&style="+legendPreviewStyleName+"&anticfloat="+anticfloat;
  }
  public validate()
  {
    // if(!this.currentSLDFilePath)
    // {
    //   this.toastService.error("Por favor selecione um arquivo válido.", "Erro ao salvar")
    //   return;
    // }

    if(this.currentStyle.name==null)
    {
      this.currentStyle.name="";
    }

    if(this.currentStyle.title==null)
    {
      this.currentStyle.title="";
    }


    if(this.newStyle)
    {
      this.backendService.existsStyle(this.currentStyle.id).then((result)=>{
        if(result && result.exists==true)
        {
          this.toastService.error("O id do estilo já existe.", "Erro ao salvar")
        }
        else
        {
            this.save();          
        }
      })
    }
    else
    {
      this.confirmEditStyle();
    }

  }
  clearStyleName(e)
  {
    let currentValue = e.target.value;
    e.target.value=currentValue.replace(/[^a-z0-9_]/gi, "");     
    
  }

  public confirmEditStyle()
  {
    this.deleteConfirmDialogRef = this.parent.dialog.open(DialogComponent, {
      height: '30%',
      width: '20%',
      data: { component: GenericConfirmationDialogComponent, title: "Excluir Estilo", confirmTitle: "", confirmMessage: "Deseja realmente excluir o estilo?" }
    });

    this.deleteConfirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
      if (confirmResult) {
        this.save();
      }

    });
  }

}

