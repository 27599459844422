<h3 *ngIf="errorMessage"> {{errorMessage}} </h3>
<h2 *ngIf="message"> {{message}} </h2>
<mat-progress-bar *ngIf="ctrlLoading" mode="indeterminate"></mat-progress-bar>

<mat-card>
    <mat-card-header>
        <mat-card-title>Identificação</mat-card-title>
    </mat-card-header>
    <mat-card-content>

        <!-- start form -->
        <table class="example-full-width" cellspacing="0">
            <tr>
                <td colspan="2">
                    <mat-form-field class="example-full-width">
                        <mat-label>Inscrição Imobiliária:</mat-label>
                        <input disabled matInput value="{{inscricao.getInscricaoSeparadaPorPonto()}}">
                    </mat-form-field>
                
                </td>
                <td colspan="3">
                    &nbsp;
                </td>
            </tr>

            <tr>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Setor:</mat-label>
                        <input disabled matInput value="{{ inscricao.setor }}">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Quadra:</mat-label>
                        <input disabled matInput value="{{ inscricao.quadra }}">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Logradouro:</mat-label>
                        <input disabled matInput value="{{ inscricao.logradouro }}">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Lote:</mat-label>
                        <input disabled matInput value="{{ inscricao.lote }}">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>SubLote:</mat-label>
                        <input disabled matInput value="{{ inscricao.sublote }}">
                    </mat-form-field>
                </td>
            </tr>
        </table>
        <!-- end form -->

    </mat-card-content>
</mat-card>
<!-- New card "Endereço" -->
<mat-card>
    <mat-card-header>
        <mat-card-title>Endereço</mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="!cadImob || !cadImob['imovel']">
        <h4>Não há informação de endereço</h4>
    </mat-card-content>
    <mat-card-content *ngIf="cadImob && cadImob['imovel']">
        <table class="example-full-width" cellspacing="0">
            <tr>
                <td colspan="2">
                    <mat-form-field class="example-full-width">
                        <mat-label>Logradouro:</mat-label>
                        <input disabled matInput value="{{ cadImob['imovel']['endereco_imovel'] }}">
                    </mat-form-field>
                
                </td>
                <td colspan="1">
                    <mat-form-field class="example-full-width">
                        <mat-label>Número:</mat-label>
                        <input disabled matInput value="{{ cadImob['imovel']['numero_imovel'] }}">
                    </mat-form-field>
                
                </td>
                <td colspan="1">
                    <mat-form-field class="example-full-width">
                        <mat-label>Complemento:</mat-label>
                        <input disabled matInput value="{{ cadImob['imovel']['complemento_imovel'] }}">
                    </mat-form-field>
                
                </td>
            </tr>

            <tr>
                <td colspan="2">
                    <mat-form-field class="example-full-width">
                        <mat-label>Bairro:</mat-label>
                        <input disabled matInput value="{{ cadImob['imovel']['bairro_imovel'] }}">
                    </mat-form-field>
                
                </td>
                <td colspan="1">
                    <mat-form-field class="example-full-width">
                        <mat-label>CEP:</mat-label>
                        <input disabled matInput value="{{ cadImob['imovel']['cep_imovel'] }}">
                    </mat-form-field>
                
                </td>
                <td colspan="1">
                    &nbsp;
                </td>
            </tr>
        </table>
        <!-- end form -->
    </mat-card-content>
</mat-card>
<!-- New card "TABS" -->
<mat-card>
    <mat-card-content>
        <mat-tab-group mat-align-tabs="start" (selectedTabChange)="tabChanged($event)">
            <mat-tab label="Características">
                <mat-card>
                    <mat-card-content *ngIf="!cadImob || !cadImob['caracteristicas']">
                        <h4>Não há informação de caracteristicas</h4>
                    </mat-card-content>
                    <mat-card-content *ngIf="cadImob && cadImob['caracteristicas']">
                        <table class="example-full-width" cellspacing="0">
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Área Construída (m²):</mat-label>
                                        <input disabled matInput value="{{ formatDecimalField(cadImob['caracteristicas']['area_construida']) }}">
                                    </mat-form-field>
                                
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Tipo da Construção:</mat-label>
                                        <input disabled matInput value="{{ cadImob['caracteristicas']['tipo_da_construcao'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Área do Terreno (m²):</mat-label>
                                        <input disabled matInput value="{{ formatDecimalField(cadImob['caracteristicas']['area_do_terreno']) }}">
                                    </mat-form-field>
                                
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Padrão Construção:</mat-label>
                                        <input disabled matInput value="{{ cadImob['caracteristicas']['padrao_da_construcao'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Testada (m):</mat-label>
                                        <input disabled matInput value="{{ formatDecimalField(cadImob['caracteristicas']['testada']) }}">
                                    </mat-form-field>
                                
                                </td>
                                <td colspan="2">
                                    &nbsp;
                                </td>
                            </tr>
                        </table>
                    </mat-card-content>
                </mat-card>
            </mat-tab><!-- caracteristicas end tab -->

            <mat-tab label="Proprietário">
                <mat-card>
                    <mat-card-content *ngIf="!cadImob || !cadImob['proprietario']">
                        <h4>Não há informação de proprietário</h4>
                    </mat-card-content>
                    <mat-card-content *ngIf="cadImob && cadImob['proprietario']">
                        <table class="example-full-width" cellspacing="0">
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Código:</mat-label>
                                        <input disabled matInput value="{{ cadImob['proprietario']['codigo_proprietario'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td colspan="2">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Nome/Razão Social:</mat-label>
                                        <input disabled matInput value="{{ cadImob['proprietario']['nome_razao_proprietario'] }}">
                                    </mat-form-field>
                                
                                </td>
                            </tr>
                
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>CPF/CNPJ:</mat-label><!-- TODO: include pipe to format CPF/CNPJ -->
                                        <input disabled matInput value="{{ cadImob['proprietario']['cpf_proprietario'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>RG:</mat-label><!-- TODO: include pipe to format RG -->
                                        <input disabled matInput value="{{ cadImob['proprietario']['rg_proprietario'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Telefone:</mat-label>
                                        <input disabled matInput value="{{ cadImob['proprietario']['telefone_proprietario'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Celular:</mat-label>
                                        <input disabled matInput value="{{ cadImob['proprietario']['celular_proprietario'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>E-mail:</mat-label>
                                        <input disabled matInput value="{{ cadImob['proprietario']['email_proprietario'] }}">
                                    </mat-form-field>
                                
                                </td>
                            </tr>
                        </table>
                    </mat-card-content>
                </mat-card>
            </mat-tab><!-- Proprietário end tab -->
            
            <mat-tab label="Compromissário">
                <mat-card>
                    <mat-card-content *ngIf="!cadImob || !cadImob['compromissario']">
                        <h4>Não há informação de proprietário</h4>
                    </mat-card-content>
                    <mat-card-content *ngIf="cadImob && cadImob['compromissario']">
                        <table class="example-full-width" cellspacing="0">
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Código:</mat-label>
                                        <input disabled matInput value="{{ cadImob['compromissario']['codigo_compromissario'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td colspan="2">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Nome/Razão Social:</mat-label>
                                        <input disabled matInput value="{{ cadImob['compromissario']['nome_razao_compromissario'] }}">
                                    </mat-form-field>
                                
                                </td>
                            </tr>
                
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>CPF/CNPJ:</mat-label><!-- TODO: include pipe to format CPF/CNPJ -->
                                        <input disabled matInput value="{{ cadImob['compromissario']['cpf_compromissario'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>RG:</mat-label><!-- TODO: include pipe to format RG -->
                                        <input disabled matInput value="{{ cadImob['compromissario']['rg_compromissario'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td>
                                    &nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Telefone:</mat-label>
                                        <input disabled matInput value="{{ cadImob['compromissario']['telefone_compromissario'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Celular:</mat-label>
                                        <input disabled matInput value="{{ cadImob['compromissario']['celular_compromissario'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>E-mail:</mat-label>
                                        <input disabled matInput value="{{ cadImob['compromissario']['email_compromissario'] }}">
                                    </mat-form-field>
                                
                                </td>
                            </tr>
                        </table>
                    </mat-card-content>
                </mat-card>
            </mat-tab><!-- Compromissário end tab -->

            <mat-tab label="Endereço de Entrega">
                <mat-card>
                    <mat-card-content *ngIf="!cadImob || !cadImob['endereco_de_correspondencia']">
                        <h4>Não há informação de endereço</h4>
                    </mat-card-content>
                    <mat-card-content *ngIf="cadImob && cadImob['endereco_de_correspondencia']">
                        <table class="example-full-width" cellspacing="0">
                            <tr>
                                <td colspan="2">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Logradouro:</mat-label>
                                        <input disabled matInput value="{{ cadImob['endereco_de_correspondencia']['endereco_de_entrega'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td colspan="1">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Número:</mat-label>
                                        <input disabled matInput value="{{ cadImob['endereco_de_correspondencia']['numero_de_entrega'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td colspan="1">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Complemento:</mat-label>
                                        <input disabled matInput value="{{ cadImob['endereco_de_correspondencia']['complemento_de_entrega'] }}">
                                    </mat-form-field>
                                
                                </td>
                            </tr>
                
                            <tr>
                                <td colspan="2">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Bairro:</mat-label>
                                        <input disabled matInput value="{{ cadImob['endereco_de_correspondencia']['bairro_de_entrega'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td colspan="1">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Cidade:</mat-label>
                                        <input disabled matInput value="{{ cadImob['endereco_de_correspondencia']['cidade_de_entrega'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td colspan="1">
                                    &nbsp;
                                </td>
                            </tr>

                            <tr>
                                <td colspan="1">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>CEP:</mat-label>
                                        <input disabled matInput value="{{ cadImob['endereco_de_correspondencia']['cep_de_entrega'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td colspan="2">
                                    &nbsp;
                                </td>
                            </tr>
                            
                        </table>
                        <!-- end form -->
                    </mat-card-content>
                </mat-card>
            </mat-tab><!-- Endereço de Entrega end tab -->

            <mat-tab label="Observações">
                <mat-card>
                    <mat-card-content *ngIf="!cadImob || !cadImob['observacoes']">
                        <h4>Não há informação</h4>
                    </mat-card-content>
                    <mat-card-content *ngIf="cadImob && cadImob['observacoes']">
                        <table class="example-full-width" cellspacing="0">
                            <tr>
                                <td colspan="1">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Data da Inscrição:</mat-label><!-- TODO: format this field with date type using pipe -->
                                        <input disabled matInput value="{{ cadImob['situacao_cadastral']['data_de_inscricao'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td colspan="2">
                                    &nbsp;
                                </td>
                            </tr>

                            <tr>
                                <td colspan="1">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Isenção de IPTU para próximo exercício:</mat-label>
                                        <input disabled matInput value="{{ cadImob['observacoes']['isencao_de_iptu_prox_exerc'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td colspan="2">
                                    &nbsp;
                                </td>
                            </tr>

                            <tr>
                                <td colspan="1">
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Encaminhado para gráfica:</mat-label>
                                        <input disabled matInput value="{{ cadImob['observacoes']['iptu_grafica'] }}">
                                    </mat-form-field>
                                
                                </td>
                                <td colspan="2">
                                    &nbsp;
                                </td>
                            </tr>
                            
                        </table>
                        <!-- end form -->
                    </mat-card-content>
                </mat-card>
            </mat-tab><!-- Observações end tab -->

            <mat-tab label="Foto Fachada" >
                <!--ng-image-slider #nav
                [images]="imagesList"
                [infinite]="false"
                [autoSlide]="1"
                [imageSize]="{width: '25%', height: 200}"
                slideImage="1"></ng-image-slider-->
                <mat-grid-list cols="5" rowHeight="200px">
                    <mat-grid-tile *ngFor="let albumFotofachada of albumFotosFachada; let i=index">
                        <img [src]="albumFotofachada.src" (click)="openFotoFachada(i)">
                    </mat-grid-tile>
                </mat-grid-list>
                
                <div class="fotos-manager-button-div">
                    <button type="button" title="Gerenciar fotos de fachadas para as incrições selecionadas." *ngIf="showFotosFachadaManager()" (click)="gerenciarFotosFachada()" mat-raised-button color="primary">
                        <span class="material-icons">image</span> Gerenciar Fotos de Fachada
                    </button>
                </div>
                

            </mat-tab><!-- Foto Fachada end tab -->

            <mat-tab label="Documentação do Imóvel">
                <table mat-table [dataSource]="fichas" class="mat-elevation-z8 example-full-width">

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> Id </th>
                        <td mat-cell *matCellDef="let ficha"> {{ficha.id}} </td>
                    </ng-container>

                    <ng-container matColumnDef="idLogradouro">
                        <th mat-header-cell *matHeaderCellDef> Código Logradouro </th>
                        <td mat-cell *matCellDef="let ficha"> {{ficha.id_logradouro}} </td>
                    </ng-container>

                    <ng-container matColumnDef="nomeLogradouro">
                        <th mat-header-cell *matHeaderCellDef> Nome Logradouro </th>
                        <td mat-cell *matCellDef="let ficha"> {{ficha.nome_logradouro}} </td>
                    </ng-container>


                    <ng-container matColumnDef="numeroOuComplemento">
                        <th mat-header-cell *matHeaderCellDef> Número </th>
                        <td mat-cell *matCellDef="let ficha"> {{ficha.numero_ou_complementos}} </td>
                    </ng-container>
                    
                    <!-- Weight Column -->
                    <ng-container matColumnDef="nomeArquivoCompleto">
                        <th mat-header-cell *matHeaderCellDef> Arquivo Origem </th>
                        <td mat-cell *matCellDef="let ficha"> {{ficha.nome_arquivo_completo}} </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="tipo">
                        <th mat-header-cell *matHeaderCellDef> Tipo Arquivo </th>
                        <td mat-cell *matCellDef="let ficha"> {{ficha.tipo}} </td>
                    </ng-container>
                    
                    <!-- Symbol Column -->
                    <ng-container matColumnDef="acoes">
                        <th  mat-header-cell *matHeaderCellDef="let ficha"> Ações 
                            <mat-icon *ngIf="hasEditionPermition()" title="Inserir e associar novo documento " (click)="newFichaUpload()" class="addFichaUpload">file_upload</mat-icon>
                            <mat-icon *ngIf="hasEditionPermition()" title="Associar documento" (click)="newFichaLink(logradouro,inscricao)" class="addFicha">add_box</mat-icon>
                        </th>
                        <td mat-cell *matCellDef="let ficha" > 
                            <mat-icon (click)="getFicha(ficha.id, ficha.tipo)" title="Visualizar Documento" class="btn-icon download-ficha" aria-hidden="false" aria-label="Visualizar Documento">{{ getFichaIcon(ficha) }}</mat-icon> 
                            <mat-icon *ngIf="hasEditionPermition()" (click)="desassociarFicha(ficha.id)" title="Desassociar Documento" class="btn-icon desassociar-ficha" aria-hidden="false" aria-label="Desassociar Documento">indeterminate_check_box</mat-icon> 
                        </td>
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="fichasDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: fichasDisplayedColumns;"></tr>
                    </table>
            </mat-tab><!-- Ficha do Imóvel end tab -->

            
            <mat-tab label="Histórico de Processo">
                <table mat-table [dataSource]="historicoProcesso" class="mat-elevation-z8 example-full-width">

                    <ng-container matColumnDef="processo">
                        <th mat-header-cell *matHeaderCellDef> Processo </th>
                        <td mat-cell *matCellDef="let item"> {{item.processo}} </td>
                    </ng-container>

                    <ng-container matColumnDef="observacoes">
                        <th mat-header-cell *matHeaderCellDef> Observações </th>
                        <td mat-cell *matCellDef="let item"> {{item.observacoes}} </td>
                    </ng-container>

                    <ng-container matColumnDef="assunto">
                        <th mat-header-cell *matHeaderCellDef> Assunto </th>
                        <td mat-cell *matCellDef="let item"> {{item.assunto}} </td>
                    </ng-container>

                    <ng-container matColumnDef="acoes">
                        <th mat-header-cell *matHeaderCellDef="let item"> <span *ngIf="hasEditionPermition()">Ações</span>  
                            <mat-icon *ngIf="hasEditionPermition()" title="Inserir histórico" (click)="addHistorico(logradouro,inscricao)" class="add-historico">add_box</mat-icon>
                        </th>
                        <td mat-cell *matCellDef="let item" > 
                            <mat-icon *ngIf="hasEditionPermition()" (click)="editHistorico(item)" title="Editar Histórico" class="btn-icon edit-historico" aria-hidden="false" aria-label="Editar Histórico">edit</mat-icon> 
                            <mat-icon *ngIf="hasEditionPermition()" (click)="confirmDeleteHistorico(item)" title="Apagar Histórico" class="btn-icon delete-historico" aria-hidden="false" aria-label="Apagar Histórico">delete</mat-icon> 
                        </td>
                    </ng-container>
                    
                    <tr mat-header-row *matHeaderRowDef="historicoProcessoDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: historicoProcessoDisplayedColumns;"></tr>
                    </table>
            </mat-tab><!-- Historico de Processo end tab -->

            <!-- Revisão Cadastral 2021 start tab -->
            <mat-tab label="Revisão Cadastral 2021" >
                <div *ngIf="resumoRevisao2021" class="resumo-revisao-div">
                    <mat-card class="revisao-card">
                        <mat-card-header>
                          <mat-card-title>Cadastro Técnico Imobiliário: (Jan/2021)</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="resumo-revisao-cards-div">
                                <div>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Area Construída (m²):</mat-label>
                                        <input disabled matInput value="{{ formatDecimalField(resumoRevisao2021.area_construida) }}">
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Tipo da Construção:</mat-label>
                                        <input disabled matInput value="{{ resumoRevisao2021.tipo_da_construcao}}">
                                    </mat-form-field>
                                </div>
                            </div>                        
                        </mat-card-content>
    
                      </mat-card>
                      <mat-card *ngIf="resumoRevisao2021.selecionado && resumoRevisao2021.qtd_inscricoes_cti==1" class="revisao-card">
                        <mat-card-header>
                          <mat-card-title>Levantamento Geo: (Ortofoto Jan/2021)</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="resumo-revisao-cards-div">
                                <div>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Area Construída (m²):</mat-label>
                                        <input disabled matInput value="{{ formatDecimalField(resumoRevisao2021.area_total_geo) }}">
                                    </mat-form-field>
                                </div>
                            </div>                        
                        </mat-card-content>
    
                      </mat-card>
                      <mat-card *ngIf="resumoRevisao2021.selecionado && resumoRevisao2021.qtd_inscricoes_cti==1" class="revisao-card">
                        <mat-card-header>
                          <mat-card-title>Resultado da Revisão: </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="resumo-revisao-cards-div ">
                                <div class="resumo-revisao-selecionado">
                                    Imóvel Selecionado
                                </div>
                                <div>
                                    <mat-form-field class="example-full-width">
                                        <mat-label>Acréscimo identificado (m²/%):</mat-label>
                                        <input disabled matInput value="+{{ formatDecimalField(resumoRevisao2021.defasagem) }} (+{{ formatDecimalField(resumoRevisao2021.defasagem_porcentagem) }}%)">
                                    </mat-form-field>
                                </div>
                                <div *ngIf="resumoRevisao2021.justificativa">
                                    Obs: {{ resumoRevisao2021.justificativa }}
                                </div>
                            </div>                        
                        </mat-card-content>
    
                      </mat-card>
    
                      <mat-card *ngIf="!resumoRevisao2021.selecionado" class="revisao-card">
                        <mat-card-header>
                          <mat-card-title>Resultado da Revisão: </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="resumo-revisao-cards-div">
                                <div class="resumo-revisao-nao-selecionado">
                                    Imóvel não selecionado
                                </div>
                            </div>                        
                        </mat-card-content>
    
                      </mat-card>
    
                      <mat-card *ngIf="resumoRevisao2021.selecionado && resumoRevisao2021.qtd_inscricoes_cti>1" class="revisao-card">
                        <mat-card-header>
                          <mat-card-title>Resultado da Revisão: </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="resumo-revisao-cards-div resumo-revisao-analise">
                                <div>
                                    Imóvel selecionado para análise
                                </div>
                                <div>
                                    (caso de multiplas inscrições por numero predial)
                                </div>
                            </div>                        
                        </mat-card-content>
    
                      </mat-card>

                      <mat-card *ngIf="resumoRevisao2021.selecionado" class="revisao-card">
                        <mat-card-header>
                          <mat-card-title>Critério de Seleção: </mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <table class="mat-elevation-z8">
                                <tbody>
                                    <tr>
                                        <td>
                                            <b>Faixa 1:</b> Para imóveis com área construída levantada entre 0m2 e 70m2 -> Considerar defasagem maior ou igual a 25%  
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Faixa 2:</b> Para imóveis com área construída levantada entre 70m2 e 150m2 -> Considerar defasagem maior ou igual a 20%  
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Faixa 3:</b> Para imóveis com área construída levantada entre 150m2 e 300m2 -> Considerar defasagem maior ou igual a 15%  
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <b>Faixa 4:</b> Para imóveis com área construída levantada acima de 300m2 -> Considerar defasagem maior ou igual a 13%         
                                        </td>
                                    </tr>
                                </tbody>                               
                            </table>
                            
                            
                            
                            
                        </mat-card-content>
    
                      </mat-card>
                </div>
                <div *ngIf="resumoRevisao2021">
                    <div *ngIf="resumoRevisao2021.selecionado && resumoRevisao2021.qtd_inscricoes_cti==1">
                        Os dados apresentados nos campos acima, são resultados da Revisão Cadastral referente à janeiro de 2021.<br>
                        As alterações de limites de edificações e lotes de área construída, feitos após esta data, não são mais objetos desta revisão.    
                    </div>
                </div>              


            </mat-tab><!--  Revisão Cadastral 2021 end tab -->
        </mat-tab-group>
    </mat-card-content>
</mat-card>

<!-- {{cadImob[key][subkey]?cadImob[key][subkey]:noValueText}} -->  