import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Layer, Util } from 'leaflet';

import { LayersConfigService } from '../map/layers/layers.config.service';
import { CapabilitiesService } from './capabilities.service';

import { Style } from '../map/vo/style';



@Injectable({
    providedIn: 'root'
  })

export class StyleService {
   
    /** start http service client */
    constructor(private capabilitiesService: CapabilitiesService)
    {

    }

    private getStyleFromElement(styleElement: any) : Style
    {
        let style: Style = new Style();
        let nameAttribute = this.getAtributeFromStyleElement("Name", styleElement);
        let titleAttribute = this.getAtributeFromStyleElement("Title", styleElement);
        let abstractAttribute = this.getAtributeFromStyleElement("Abstract", styleElement);
        
        let legendURLValue = this.getURLValueFromLegendURL(styleElement);
        
        style.id = nameAttribute.text;

        if(titleAttribute && titleAttribute.text)
        {
            // if style title contains " - " separator between name and title
            style.name = titleAttribute.text;
        }
        else
        {
            style.name=nameAttribute.text;
        }

        if(abstractAttribute && abstractAttribute.text)
        {
            // if style title contains " - " separator between name and title
            style.title = abstractAttribute.text;
        }
        else
        {
            style.title="";
        }

        
        style.legendURL=legendURLValue

        return style;

    }

    private getAtributeFromStyleElement(attributeName: string, styleElement: any) : any
    {
        let attributeElement = this.capabilitiesService.getElementByName(attributeName, styleElement.elements);    
        if(attributeElement && attributeElement.elements)
        {
            let attribute = this.capabilitiesService.getFirstElement(attributeElement.elements);
            return attribute;
        }
        else
        {
            return null;   
        }
    }
    private getURLValueFromLegendURL(styleElement: any) : string
    {
        let legendURLAttribute = this.capabilitiesService.getElementByName("LegendURL", styleElement.elements);
        let urlAttribute = this.capabilitiesService.getElementByName("OnlineResource", legendURLAttribute.elements);
        let url = urlAttribute["attributes"]["xlink:href"];

        let randint = (Math.floor( Math.random() * 2000000 ) + 1);

        if(url.includes("?"))
        {
            url=url+"&randint="+randint
        }
        else
        {
            url=url+"?randint="+randint
        }

        return url;
    }


    public getStylesFromCapabilitesObject(capabilities: any) : Array<Style>
    {
        //object.elements[1].elements[1].elements[3].elements[6783]
        
        let WMT_MS_Capabilities =this.capabilitiesService.getElementByName("WMT_MS_Capabilities", capabilities.elements);
        let capability = this.capabilitiesService.getElementByName("Capability", WMT_MS_Capabilities.elements);
        let layer = this.capabilitiesService.getElementByName("Layer", capability.elements);
        let subLayer = this.capabilitiesService.getElementByName("Layer", layer.elements);
        let styleElements = this.capabilitiesService.getElementsByName("Style", subLayer.elements);
        let styles : Style[]= []
        styleElements.forEach(styleElement => {
            let style = this.getStyleFromElement(styleElement);
            styles.push(style);
        });
        
        console.log(styles);
        return styles;
    }

    public getStyleIdWithoutWorkspace(style: Style)
    {
      if(style.id.includes(":"))
      {
        return style.id.split(":")[1]
      }
      else
      {
        return style.id
      }
    }
    
} 