import { AppNotification } from "src/app/map/vo/notification";
import { NotificationService } from "src/app/service/notification.service";
import { Step } from "../../vo/step";

export class StepsController
{
	steps: Step[];
	currentStep: number;

	constructor(private notificationService: NotificationService)
	{
		this.steps = [];
		this.currentStep=0;
	}

	public addNewStep(step: Step)
	{
		this.steps.push(step);
	}
	public back() : boolean
	{
		if((this.currentStep-2)>=0)
		{

			this.steps[this.currentStep-1].rollback();
			this.currentStep--;
			this.steps[this.currentStep-1].execute();

			let notification = new AppNotification(AppNotification.ON_CUSTOM_EDITION_STEP_CHANGED);        
			this.notificationService.send(notification);

			return true;
		}
		return false;
	}

	public next() : boolean
	{

		if(this.currentStep!=0)
		{
			//validate current step
			if(!this.steps[this.currentStep-1].valid())
			{
				return;
			}
			//finish current step
			this.steps[this.currentStep-1].finish();
		}
		if(this.currentStep<this.steps.length)
		{
	
			this.currentStep++;
			this.steps[this.currentStep-1].execute();

			let notification = new AppNotification(AppNotification.ON_CUSTOM_EDITION_STEP_CHANGED);        
			this.notificationService.send(notification);

			return true;
		}
		return false;
	}

	public getSize() : number
	{
		return this.steps.length;
	}

	public getPercent() : number
	{
		let percent = (this.currentStep / this.steps.length) * 100;
		return percent;
	}

	public getCurrentStep()
	{
		return this.steps[this.currentStep-1];
	}	  
	
	public getSteps() : Step[]
	{
		return this.steps;
	}

	public isLastStep()
	{
		if(this.currentStep==this.steps.length)
		{
			return true;
		}
		return false;
	}

	public isFirstStep()
	{
		if(this.currentStep==1)
		{
			return true;
		}
		return false;
	}

	public finish()
	{
		if(this.currentStep!=0)
		{
			this.steps[this.currentStep-1].finish();
		}
	}
};
