<mat-accordion class="certidao-desdobro-accordion">
    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="certidao-desdobro-panel">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header" class="layers-col">
                    <mat-icon class="material-icons layer-icon">description</mat-icon>Gerar Certidão de Desdobro de Lotes
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              
            </mat-expansion-panel-header>
            <div class="form">
                <div class="form-line">
                    <mat-form-field appearance="fill">
                        <mat-label>Inscrição </mat-label>
                        <mat-select required [(ngModel)]="ic" class="property-value">
                            <mat-option *ngFor="let inscricao of ics" [value]="inscricao" required>
                            {{inscricao}}
                            </mat-option>
                        </mat-select>
                     </mat-form-field>   
                </div>
                <div class="form-line">
                    <mat-form-field appearance="fill">
                        <mat-label>Interessado </mat-label>
                        <input matInput type="text" class="property-value"  [(ngModel)]="interessado" required>
                     </mat-form-field>                     
                </div>
                <div class="form-line">
                    <mat-form-field appearance="fill">
                        <mat-label>Ano </mat-label>
                        <input matInput type="text" class="property-value"  [(ngModel)]="ano" required>
                     </mat-form-field>   
                    <mat-form-field appearance="fill">
                        <mat-label>Processo </mat-label>
                        <input matInput type="text" class="property-value"  [(ngModel)]="processo" required>
                     </mat-form-field>                     
                </div>

               <div class="generate-button">
                <button type="button" (click)="generate()" mat-raised-button color="primary" id="generate-button" [disabled]="downloading">
                    <span class="material-icons">download</span> Gerar
                </button>

              </div>  
            </div> 

            
            </mat-expansion-panel>

    </ng-container>
</mat-accordion>
<div id="temp-map-div" style="display: none;"></div>