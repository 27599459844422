import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Historicoprocessoimovel, HistoricoprocessoimovelInner } from '@funcate/sigweb-cti-api';
import { Inscricao } from 'src/app/map/vo/inscricao';
import { BackendService } from 'src/app/service/backend.service';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'edit-add-historico',
  templateUrl: './edit-historico.component.html',
  styleUrls: ['./edit-historico.component.css']
})
export class EditHistoricoComponent implements OnInit 
{
  
  historico: HistoricoprocessoimovelInner;
  parent: any;
  icList: Inscricao[]
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private backendService: BackendService, private toastService: ToastService) 
  {
    this.historico = data.historico;
    this.parent = data.parent;
    this.icList = data.icList;
  }

  ngOnInit(): void 
  {      
  } 

  save()
  {    
    if(this.validate())
    {
      if(!this.historico.id)
      {
        let historicoProcessoList: Historicoprocessoimovel = []

        if(this.icList && this.icList.length>0)
        {
          //Multiple IC insert
          this.icList.forEach(ic => {
            let item : HistoricoprocessoimovelInner = new Object;
            item.ic=ic.getInscricaoCompleta();
            item.proprietario=this.historico.proprietario;
            item.logradouro=this.historico.logradouro;
            item.numero=this.historico.numero;
            item.loteamento=this.historico.loteamento;
            item.quadra=this.historico.quadra;
            item.lote=this.historico.lote;
            item.processo=this.historico.processo;
            item.observacoes=this.historico.observacoes;
            item.matricula=this.historico.matricula;
            item.assunto=this.historico.assunto;

            historicoProcessoList.push(item);
          });
        }
        else
        {
          //Single insert
          historicoProcessoList.push(this.historico);
        }

        this.backendService.addHistoricoProcessoImovel(historicoProcessoList).then((historicoProcessoReturn: Historicoprocessoimovel)=>
        {
          this.parent.closeEditHistorico(historicoProcessoReturn);

          this.toastService.sucess("Histórico inserido.", "Sucesso");
        }).catch(()=>
        {
          this.toastService.error("Problema ao inserir historico de processo.", "Erro ao salvar");
        });
      }
      else
      {
        let historicoProcessoList: Historicoprocessoimovel = []

        historicoProcessoList.push(this.historico);

        this.backendService.editHistoricoProcessoImovel(historicoProcessoList).then((historicoProcessoReturn: Historicoprocessoimovel)=>
        {

          this.parent.closeEditHistorico(historicoProcessoReturn);

          this.toastService.sucess("Histórico atualizado.", "Sucesso");


        }).catch(()=>
        {
          this.toastService.error("Problema ao atualizar historico de processo.", "Erro ao salvar");
        });
      }
      
    }
  }

  private validate()
  {
    if(!this.historico.processo)
    {
      this.toastService.error("Favor preencher o campo Processo.", "Preencha os campos obrigatórios");

      return false;
    } 

    return true;
  }

}
