<mat-accordion *ngIf="layer">
    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="layername">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header" class="layers-col">
                    <mat-icon class="material-icons layer-icon">layers</mat-icon>{{layer.getName()}}
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngIf="currentFeature!=null">
                <div id="features-selection-div">
                    <mat-form-field appearance="fill">
                        <mat-label>Feição:</mat-label>
                        <mat-select [(value)]="currentFeature">
                          <mat-option *ngFor="let feature of features" [value]="feature">
                            {{feature.id}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <table mat-table [dataSource]="currentFeature.properties" class="mat-elevation-z8">
                    <ng-container matColumnDef="key">
                        <th mat-header-cell *matHeaderCellDef> Campo </th>
                        <td mat-cell *matCellDef="let property"> {{property.key}} </td>
                      </ng-container>
                      <ng-container matColumnDef="value">
                        <th mat-header-cell *matHeaderCellDef> Valor </th>
                        <td mat-cell *matCellDef="let property" >
                            <input *ngIf="property.key!=idAttribute" matInput placeholder="" id="{{property.key}}" value="{{property.value}}" class="property-value" #attributesElements>
                            <div *ngIf="property.key==idAttribute" class="property-readonly-value">{{property.value}}</div>
                        </td>
                        
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>


<div class="buttons-div">
    <div class="save-button">
        <button mat-raised-button color="primary" (click)="save()">Salvar</button>

    </div>
    <div class="cancel-button">
        <button mat-raised-button color="basic" (click)="close()">Cancelar</button>
    </div>
</div>

