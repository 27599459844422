import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Documentos, DocumentosInner, Fotosfachada, Geooperationarea, GeooperationareaLayer, Geooperationcontains, GeooperationcontainsLayer1, GeooperationcontainsLayer2, Historicoprocessoimovel, HistoricoprocessoimovelInner, Userinfo } from '@funcate/sigweb-cti-api';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { FichaService } from 'src/app/service/ficha.service';
import { LayersConfigService } from '../../layers/layers.config.service';
import { FeatureService } from 'src/app/service/feature.service';
import { Settlement } from '../../vo/settlement';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import * as moment from 'moment';
import { ToastService } from 'src/app/service/toast.service';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditSettlementPropertyTool } from './edit-settlement-property-tool';
import { DialogComponent } from '../../dialog/dialog.component';
import { GenericConfirmationDialogComponent } from '../../dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { EditionService } from 'src/app/service/edition.service';
import { DocumentService } from 'src/app/service/document.service';
import { DocumentUploadComponent } from '../../dialog/document-upload-dialog/document-upload.component';
import { DatePipe } from '@angular/common';
import { UserGroup } from '../../vo/user-group';
import { SettlementProperty } from '../../vo/settlement-property';
import { AbstractDocumentType } from '../../vo/document/abstract-document-type';
import { BackendService } from 'src/app/service/backend.service';
import { GenericDomainOptionsService } from 'src/app/service/generic-domain-options.service';
import { ToolsService } from '../tools.service';
import { Property } from '../../vo/property';
import { SettlementPropertyMembroFonteRenda } from '../../vo/settlement-property-membro-fonte-renda';
import { EditCadastroSocialComponent } from './cadastro-social/edit-cadastro-social.component';
import { SettlementPropertyMembro } from '../../vo/settlement-property-membro';
import { IAlbum } from 'ngx-lightbox';
import { Inscricao } from '../../vo/inscricao';
import { environment } from 'src/environments/environment';
import { FotoFachadaPreviewComponent } from '../property-registration/foto-fachada-preview/foto-fachada-preview.component';
import { FotosFachadaManagerTool } from '../fotosfachada-manager/fotosfachada-manager-tool';
import { FotosfachadaManagerComponent } from '../fotosfachada-manager/fotosfachada-manager.component';
import { CanvasService } from '../../canvas/canvas.service';
import { EditHistoricoComponent } from '../property-registration/edit-historico/edit-historico.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};



@Component({
  selector: 'app-settlement-property',
  templateUrl: './settlement-property.component.html',
  styleUrls: ['./settlement-property.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class SettlementPropertyComponent implements OnInit {

  public optionsUsoImovel: string[];
  public optionsOcupacao: string[];
  public optionsAcessoLote: string[];
  public optionsAcessoDomicilio: string[];
  public optionsTipologiaEdificacao: string[];
  public optionsStatusConstrucao: string[];
  public optionsSituacaoConsolidacao: string[];
  public optionsMaterialConstrutivo: string[];
  public optionsRevestimentoExterno: string[];
  public optionsMaterialCobertura: string[];
  public optionsTipoLote: string[];
  public optionsCavaleteAgua: string[];
  public optionsPostePadraoEnergia: string[];
  public optionsAbastecimentoAgua: string[];
  public optionsEsgotamentoSanitario: string[];
  public optionsEnergiaEletrica: string[];
  public optionsPatologiasAparentes: string[];
  public optionsUsosExternos: string[];

  cadastroSocialDataSource = new MatTableDataSource();
  

  public settlementProperty : SettlementProperty;
  maxDate = new Date();
  newHistoricoDomicilio = null;

  tool : EditSettlementPropertyTool;

  cadastroSocialDisplayedColumns: string[] = ['nome', 'cpf', 'rg', 'tipoMembro','acoes'];  
  documentosTitularDisplayedColumns: string[] = ['id', 'titular_tipo_documento', 'nome', 'dataInsercao', 'tipo','acoes'];
  documentosSEHABDisplayedColumns: string[] = ['id', 'sehab_tipo_documento', 'sehab_data_emissao', 'sehab_tecnico_responsavel','nome', 'dataInsercao', 'tipo','acoes'];
  documentosImovelDisplayedColumns: string[] = ['id', 'nome', 'dataInsercao', 'tipo','acoes'];
  historicoProcessoDisplayedColumns: string[] = [ 'processo', 'observacoes', 'assunto','acoes'];

  currentFeature: any;
  confirmDialogRef: any;
  dialog: any;

  newDocumentoUploadDialog: any; 
  editCadastroSocialDialog: any; 
  
  //readonly toolSettlementEdificationLayerNameAttributeConfig: string = "assentamentos_precarios_edificacoes_layername";
  settlementPropertyCadSocialSehabDocumentType: number = AbstractDocumentType.SETTLEMENT_PROPERTY_CAD_SOCIAL_SEHAB_DOCUMENTS;
  settlementPropertyCadSocialTitularDocumentType: number = AbstractDocumentType.SETTLEMENT_PROPERTY_CAD_SOCIAL_TITULAR_DOCUMENTS;

  settlementPropertyDocumentType: number = AbstractDocumentType.SETTLEMENT_PROPERTY_DOCUMENTS;
  readOnly: boolean;

  documentosTitular: any;
  documentosSEHAB: any;
  documentosImovel: any;

  /**
   * Fotos de fachada
   */
  fotoFachadaURL: string = environment.BACKEND_API_BASE_PATH + "/cadastro/fotofachada/get/";
  albumFotosFachada: Array<IAlbum>=[];
  fotoFachadaDialog: any;
  fotosFachadasManagerDialog: any;

  /*
  Histórico de processo
  */
  historicoProcesso: any;
  editHistoricoDialog: any;

  calculatingArea: boolean;
  readonly toolSettlementEdificationLayerNameAttributeConfig: string = "assentamentos_precarios_edificacoes_layername";
  readonly toolSettlementEdificationImovelIdAttributeName: string = "imovel_id_attribute";
  

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private layersConfigService: LayersConfigService, 
    private authenticationService: AuthenticationService, private documentService: DocumentService, private dateAdapter: DateAdapter<Date>,
    private toastService: ToastService, private featureService: FeatureService,
     private editionService: EditionService, private backendService: BackendService, 
     private genericDomainOptionsService: GenericDomainOptionsService, private toolsService: ToolsService,
     private canvasService: CanvasService) 
  { 
    this.tool = data.tool;
    this.dialog = data.dialog;
    this.currentFeature = data.tool.currentFeature;    

      this.loadOptions();
      this.loadSettlementPropertyFromFeature();
      this.dateAdapter.setLocale('pt-BR');

      this.readOnly = data.readOnly;
      this.calculatingArea = false;
  }

  calculateArea(id)
  {
    let settlementEdificationLayerName = this.layersConfigService.getLayerAppConfig(this.tool.layer, this.toolSettlementEdificationLayerNameAttributeConfig);

    let settlementEdificationLayer = this.layersConfigService.getOverLayerById(settlementEdificationLayerName);

    if(settlementEdificationLayerName && settlementEdificationLayer)
    {      

      let geomAttribute: string = this.layersConfigService.getLayerGeomAttribute(settlementEdificationLayer);
      let settlementEdificationImovelIdAttribute = this.layersConfigService.getLayerAppConfig(settlementEdificationLayer, this.toolSettlementEdificationImovelIdAttributeName);
    
      if(settlementEdificationImovelIdAttribute)
      {
        let table: string = this.layersConfigService.getLayerTable(settlementEdificationLayer);        
  
        let geooperationarea: Geooperationarea = new Object();
        let layer: GeooperationareaLayer = new Object();
        layer.arealayerGeomAttribute = geomAttribute;
        layer.arealayerIdAttribute = settlementEdificationImovelIdAttribute;
        layer.arealayerIdValue = id;
        layer.arealayerTable = table;
        geooperationarea.layer = layer;
    
        this.backendService.calculateArea(geooperationarea).then((area_total)=>{
          this.toastService.sucess("Área calculada com sucesso.", "Sucesso");
          this.calculatingArea = false;
          this.settlementProperty.area_construida_geo = area_total['area']
    
        }).catch(()=>{
          this.toastService.error("Erro ao recalcular área.", "Erro");
          this.calculatingArea = false;
        });
      }
      else
      {     
        console.log("Missing configuration: " + this.toolSettlementEdificationImovelIdAttributeName);
        this.toastService.error("Faltando configuração com o nome do campo com o id do imovel na camada de edificações irregulares.", "Erro");    
      }
    }
    else
    {
      console.log("Missing configuration '" + this.toolSettlementEdificationLayerNameAttributeConfig + "' or invalid layer name: " + settlementEdificationLayerName);
      this.toastService.error("Faltando configuração com o nome da camada de edificações irregulares ou a camada do nome configurado não existe.", "Erro");
    }

  }

  private loadOptions()
  {
    this.optionsUsoImovel = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-uso-imovel");
    this.optionsOcupacao = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-ocupacao");
    this.optionsAcessoLote = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-acesso-lote");
    this.optionsAcessoDomicilio = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-acesso-domicilio");
    this.optionsTipologiaEdificacao = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-tipologia-edificacao");
    this.optionsStatusConstrucao = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-status-construcao");
    this.optionsSituacaoConsolidacao = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-situacao-consolidacao");
    this.optionsMaterialConstrutivo = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-material-construtivo");
    this.optionsRevestimentoExterno = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-revestimento-externo");
    this.optionsMaterialCobertura = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-mateiral-cobertura");
    this.optionsTipoLote = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-tipo-lote");
    this.optionsCavaleteAgua = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-cavalete-agua");
    this.optionsPostePadraoEnergia = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-poste-padrao-energia");
    this.optionsAbastecimentoAgua = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-abastecimento-agua");
    this.optionsEsgotamentoSanitario = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-esgotamento-sanitario");
    this.optionsEnergiaEletrica = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-energia-eletrica");
    this.optionsPatologiasAparentes = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-patologias-aparentes");
    this.optionsUsosExternos = this.genericDomainOptionsService.getOptionsForDomain("imovel-irregular-usos-externos");
  }

  isSelected(elements: string[], value)
  {
    if(elements.includes(value))
    {
      return true;
    }
    return false;
  }

  loadSettlementPropertyFromFeature()
  {
    this.settlementProperty = new SettlementProperty();

    this.settlementProperty.fromProperties(this.currentFeature.properties);  
    
    this.refresCadastroSocialTable();

    this.loadAllDocuments();

    this.loadFotosFachadas();

    this.loadHistoricoProcesso();

  }   
  
  public formatDate(date: Date, format: string) : string
  {
    let data="";
    if(date)
    {
      let datePipe = new DatePipe('pt-BR');
      data = datePipe.transform(date.toString(), format);
    }
 

    return data;
  }
  

  ngOnInit(): void 
  {
  }

  public hasEditionPermition()
  {
    let user: Userinfo = this.authenticationService.getCurrentUser();

    if(user)
    {
      //Verifica se o usuário tem permissão de escrita na camada de imoveis
      if(this.tool.layer.isWritableForUser(user))
      {
        //Verifica se o usuário tem perfil editor ou admin
        if(user['usergroup_id']==UserGroup.ADMINISTRATOR.id || user['usergroup_id']==UserGroup.EDITION.id || user['usergroup_id']==UserGroup.SEHAB_EDITION.id)
        {
          return true;
        }
      }
    }
    return false;


    return true;
  }

  public hasSEHABReadPermition()
  {
    let user: Userinfo = this.authenticationService.getCurrentUser();

    if(user)
    {
      //Verifica se o usuário tem permissão de escrita na camada de imoveis
      if(this.tool.layer.isReadableForUser(user))
      {
        //Verifica se o usuário tem perfil editor ou admin
        if(user['usergroup_id']==UserGroup.ADMINISTRATOR.id || user['usergroup_id']==UserGroup.SEHAB_READONLY.id || user['usergroup_id']==UserGroup.SEHAB_EDITION.id)
        {
          return true;
        }
      }
    }
    return false;


  }
  
  newDocumentUpload(documentType: number, title: string)
  {    
    let extensions = AbstractDocumentType.getInstance(documentType).getSupportedExtensions();
      this.newDocumentoUploadDialog = this.dialog.open(DialogComponent, {
        height: '70%',
        width: '75%',
        data: { component: DocumentUploadComponent, title: title, featureId: this.currentFeature.id, documentType: documentType, supportedExtensions: extensions, tool: this, parentDialog: this}
    });   
  }
  
  downloadDocument(id:string, nomeArquivo: string, extension:string)
  {
    this.documentService.getDocument(id, nomeArquivo, extension, this.dialog, true);
  }

  previewDocument(id:string, extension:string)
  {
    let nomeArquivo="";
    this.documentService.getDocument(id, nomeArquivo, extension, this.dialog);
  }

  hasDocumentPreview(document:Document)
  {
    return this.documentService.hasPreview(document)
  }

  closeDocumentUpload()
  {
    this.loadAllDocuments();
    this.newDocumentoUploadDialog.close();
  }
  deleteDocument(id: string)
  {
    this.documentService.deleteDocument(id).then(()=>
    {      
      this.toastService.sucess("Documento apagado com sucesso.", "Sucesso");
      this.loadAllDocuments();
    }).catch(()=>
    {
      this.toastService.error("Erro ao apagar documento.", "Problemas ao apagar documento.");
    });
  }  
  
  confirmDeleteDocument(id: string)
  {
    this.confirmDialogRef = this.dialog.open(DialogComponent, {
      height: '30%',
      width: '20%',
      data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente apagar o documento selecionado?" }
    });

    this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
      if (confirmResult) {
        this.deleteDocument(id);
      }
    });
  }

  confirmSave()
  {
    this.confirmDialogRef = this.dialog.open(DialogComponent, {
      height: '30%',
      width: '20%',
      data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente salvar os dados para o assentamento precário?" }
    });

    this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
      if (confirmResult) {
        this.save();
      }
    });

  }
  close()
  {
    this.tool.closeDialog();
  }
  private save()
  {
    if(this.settlementProperty!=null)
    {
      this.updateAllTables();
    }
    else
    {
      console.log("Current feature não está definido.");
    }
  } 

  public editCadastroSocial(cadastroSocial: SettlementPropertyMembro)
  {
    this.editCadastroSocialDialog = this.dialog.open(DialogComponent, {
      height: '70%',
      width: '75%',
      data: { component: EditCadastroSocialComponent, title: "Editar Membro do Cadastro Social", tool: this, parent: this, readOnly: this.readOnly, member: cadastroSocial }
    });
  }

  public confirmDeleteCadastroSocial(cadastroSocial: SettlementPropertyMembro)
  {
    this.confirmDialogRef = this.dialog.open(DialogComponent, {
      height: '30%',
      width: '20%',
      data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente apagar membro do cadastro social?" }
    });

    this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
      if (confirmResult) 
      {
        for (let i = 0; i < this.settlementProperty.cadastro_social.length; i++) {
          const element = this.settlementProperty.cadastro_social[i];
          if(element==cadastroSocial)
          {
            this.settlementProperty.cadastro_social.splice(i, 1);
            this.toastService.sucess("Membro removido do cadastro social.", "Sucesso!");
            this.refresCadastroSocialTable();
            break;
          }          
        }        
      }
    });
  }

  public newCadastroSocial()
  {
    this.editCadastroSocialDialog = this.dialog.open(DialogComponent, {
      height: '70%',
      width: '75%',
      data: { component: EditCadastroSocialComponent, title: "Adicionar Membro no Cadastro Social", tool: this, parent: this, readOnly: this.readOnly, member: null}
  });  
  }

  private updateAllTables()
  {   
    this.settlementProperty.cadastro_social.forEach(member => {
      member.id=null;
    });

    let properties : Array<Property> = this.featureService.getWFSPropertiesObjectAsPropertyArray(this.settlementProperty); 
    
    //Atualizando tabela principal 
    this.editionService.updateAttributes(properties, this.tool.layer).then(() => 
    {

      this.toastService.sucess("Sucesso em salvar em Área Irregular.","Sucesso");
      this.close();
    }).catch(()=>{
      this.toastService.error("Falha ao salvar em Área Irregular.","Erro");
    });
  }
  saveMember(saveMember: SettlementPropertyMembro, newMember: boolean)
  {
    if(newMember==false)
    {
      for (let i = 0; i < this.settlementProperty.cadastro_social.length; i++)
      {
        const member = this.settlementProperty.cadastro_social[i];
        if(member.id==saveMember.id)
        {
          this.settlementProperty.cadastro_social[i] = saveMember;
          this.toastService.sucess("Dados de membro salvos.", "Sucesso!")
          this.closeEditMember();
          break;          
        }
      }

    }
    else
    {
      this.settlementProperty.cadastro_social.push(saveMember);
      this.toastService.sucess("Novo membro adicionado com suceso.", "Sucesso!");
      this.closeEditMember();
    }
    
  }

  closeEditMember()
  {
    this.editCadastroSocialDialog.close();
    this.refresCadastroSocialTable();
  }

  refresCadastroSocialTable()
  {
    this.settlementProperty.cadastro_social.sort(function (a, b) {
      if (a.nome > b.nome) {
        return 1;
    }

    if (a.nome < b.nome) {
        return -1;
    }
    return 0;
    });

    this.cadastroSocialDataSource = new MatTableDataSource(this.settlementProperty.cadastro_social);
    
  }

  getCurrentPossuidorMember()
  {
    let possuidorMember = null;
    this.settlementProperty.cadastro_social.forEach(member => {
      
      if(member.possuidor)
      {
        possuidorMember = member;
      }
    });

    return possuidorMember;
  } 
  private loadAllDocuments()
  {    
    this.loadDocuments();   
  }

  private loadDocuments()
  {    
    this.documentService.getDocumentos(AbstractDocumentType.SETTLEMENT_PROPERTY_CAD_SOCIAL_TITULAR_DOCUMENTS,this.currentFeature.id).then((documentos:Documentos)=>
    {
      documentos.forEach(documento => {
        if(documento['aux_data'])
        {
          documento['aux_data']=JSON.parse(documento['aux_data'])
        }        
      });

      this.documentosTitular=documentos;
    });

    this.documentService.getDocumentos(AbstractDocumentType.SETTLEMENT_PROPERTY_CAD_SOCIAL_SEHAB_DOCUMENTS,this.currentFeature.id).then((documentos:Documentos)=>
    {
      documentos.forEach(documento => {
        if(documento['aux_data'])
        {
          documento['aux_data']=JSON.parse(documento['aux_data'])
        }           
      });
      this.documentosSEHAB=documentos;
    });

    this.documentService.getDocumentos(AbstractDocumentType.SETTLEMENT_PROPERTY_DOCUMENTS,this.currentFeature.id).then((documentos:Documentos)=>
    {
      this.documentosImovel=documentos;
    });
  }

  public getAuxAttribute(documento:any, attributeId: string) : string
  {
    if(documento['aux_data'] && documento['aux_data'].length > 0)
    {
      for (let i = 0; i < documento['aux_data'].length; i++) {
        const field = documento['aux_data'][i];
        if(field.id == attributeId && field.value)
        {
          return field.value
        }
      }

    }
    return ""
  }

  getDateAuxAttribute(documento: any, attributeId: string, format: string) : string
  {    
    let date :any= this.getAuxAttribute(documento, attributeId);
    if(date)
    {
      let formattedDate = this.formatDate(date, format);
      return formattedDate;
    }
    
    return "";
    
  }
  public loadFotosFachadas()
  {
    if(this.settlementProperty.inscricao)
    {
      let ic = new Inscricao(this.settlementProperty.inscricao);
      this.backendService.getFotosFachada( ic.getInscricaoCompleta())
      .then((fotos: Fotosfachada)=>{
        

        this.albumFotosFachada = [];
        fotos.forEach(foto => {
          let album : any = {};
          album.src=this.fotoFachadaURL+foto.id;
          album.caption="";
          this.albumFotosFachada.push(album);
        });

      }).catch((error)=>{
        this.toastService.error("Não foi possível buscar as fotos de fachadas no serviço de informações do cadastro imobiliário.","");
        console.log(error);
      }).finally(()=>{
      });
    }
  }
  public openFotoFachada(index)
  {
    if(this.settlementProperty.inscricao)
    {
      let ic = new Inscricao(this.settlementProperty.inscricao);

      this.fotoFachadaDialog = this.dialog.open(DialogComponent, {
        maxWidth: '90%',
        maxHeight: '90%',        
        data: { component: FotoFachadaPreviewComponent, title: "Fotos Fachada para Inscrição: " + ic.getInscricaoSeparadaPorPonto(), parentDialog: this, albumFotosFachada: this.albumFotosFachada, currentIndex: index }
      });
    }  

  }
  public showFotosFachadaManager()
  {
    let fotosManagerTool = this.toolsService.getToolByType(FotosFachadaManagerTool);
    return this.toolsService.isToolVisibleForCurrentUser(fotosManagerTool);
  }

  public gerenciarFotosFachada()
  {
    if(this.settlementProperty.inscricao)
    {
      let ic = new Inscricao(this.settlementProperty.inscricao);
      let selectedItens:Inscricao[]=[];

      selectedItens.push(ic);
  
      this.fotosFachadasManagerDialog = this.dialog.open(DialogComponent, {
        height: '60%',
        width: '80%',
        maxHeight: '60%',
        maxWidth: '80%',
        data: { component: FotosfachadaManagerComponent, title: "Gerenciar fotos de fachadas", parent: this.dialog, icList: selectedItens }
      });
      this.fotosFachadasManagerDialog.afterClosed().toPromise().then(()=>{
        this.loadFotosFachadas();
        
        let layerConfig = this.layersConfigService.getCtiLayer();
        if(layerConfig)
        {
            this.canvasService.redrawLayer(layerConfig);
        }            
      });
    }
    
  }

  isInscricaoValid()
  {
    if(this.settlementProperty.inscricao)
    {
      try
      {
        let ic = new Inscricao(this.settlementProperty.inscricao);
        return ic.isValid();
      }
      catch(err)
      {
        console.log(err);
        return false;
      }
    }
    return false;
  }

  public addHistorico()
  {

    if(this.settlementProperty.inscricao)
    {
      let item : HistoricoprocessoimovelInner = new Object;

      let ic = new Inscricao(this.settlementProperty.inscricao);
      
      item.ic = ic.getInscricaoCompleta();
  
      this.editHistoricoDialog = this.dialog.open(DialogComponent, {
        height: '60%',
        maxHeight: '60%',
        maxWidth: '80%',
        data: { component: EditHistoricoComponent, title: "Adicionar histórico de processo para o imóvel irregular", historico: item, parent: this  }
      });
  
      this.editHistoricoDialog.afterClosed().toPromise().then(()=>{
        this.loadHistoricoProcesso();
      });
    }    
  }

  public loadHistoricoProcesso()
  {
    if(this.settlementProperty.inscricao)
    {
      let ic = new Inscricao(this.settlementProperty.inscricao);
      this.backendService.getHistoricoProcessoImovel(ic.getInscricaoCompleta())
      .then((historico: Historicoprocessoimovel)=>{
        this.historicoProcesso = historico;


      }).catch((error)=>{
        this.toastService.error("Não foi possível buscar o historico do imovel no serviço de informações do cadastro imobiliário.","");
        console.log(error);
      }).finally(()=>{
      });
    }
  }

  public editHistorico(item: any)
  {
    this.editHistoricoDialog = this.dialog.open(DialogComponent, {
      height: '60%',
      maxHeight: '60%',
      maxWidth: '80%',
      data: { component: EditHistoricoComponent, title: "Adicionar histórico de processo para o imóvel", historico: item, parent: this }
    });

    this.editHistoricoDialog.afterClosed().toPromise().then(()=>{
      this.loadHistoricoProcesso();
    });
  }

  private deleteHistorico(item: any)
  {
    this.backendService.deleteHistoricoProcessoImovel(item.id).then(()=>
    {
      this.loadHistoricoProcesso();
      this.toastService.sucess("Sucesso ao remover histórico de processo para o imóvel.","Sucesso");
    }).catch(()=>
    {
      this.toastService.error("Problema ao remover histórico de processo para o imóvel.","Erro ao remover");
    })
  }

  public confirmDeleteHistorico(item: any)
  { 
    this.confirmDialogRef = this.dialog.open(DialogComponent, {
      height: '30%',
      width: '20%',
      data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente apagar o histórico de processo?" }
    });

    this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
      if(confirmResult)
      {
         this.deleteHistorico(item);
      }

    });
  }

  public closeEditHistorico(historicoProcesso: Historicoprocessoimovel)
  {
    if(this.editHistoricoDialog)
    {
      this.editHistoricoDialog.close();
    }
  }
  
}


