export class DownloadFormat {
    name:String;
    description:String;
    extension:String;
    isVector: boolean;

    constructor(name:string, description:string, extension:string, isVector:boolean)
    {
        this.name=name;
        this.description=description;
        this.extension=extension;
        this.isVector=isVector;
    }
}
