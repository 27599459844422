import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Users, Usersgroup, Usergroup } from '@funcate/sigweb-cti-api';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/service/backend.service';
import { ToastService } from 'src/app/service/toast.service';
import { AppUser } from '../../vo/app-user';
import { UserGroup } from '../../vo/user-group';
import { ToolsService } from '../tools.service';
import { SecurityManagementTool } from './security-management-tool';


@Component({
  selector: 'app-security-management-tool',
  templateUrl: './security-management-tool.component.html',
  styleUrls: ['./security-management-tool.component.css']
})
export class SecurityManagementToolComponent implements OnInit {

  public appUsers: Array<AppUser>=[];
  public userGroups: Array<UserGroup>;
  displayedColumns: string[] = ['user','group'];  
  private subscriptionTool:Subscription;
  private tool:SecurityManagementTool;
  dataSource: MatTableDataSource<AppUser>;
  permissionsFormGroup: FormGroup = new FormGroup(
    {
    }
  );
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(private toolsService: ToolsService, private backendService: BackendService, private toastService: ToastService) {
    
    this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (tool:SecurityManagementTool) => {
        this.init();
        this.tool=tool;
      },()=> {
      }
    );
   }

  init()
  {
    this.userGroups=UserGroup.USER_GROUPS;
    this.getUsers();
  }

  ngOnInit(): void 
  {
    
  }

  sortData(sort: Sort) {

    this.dataSource.sort = this.sort;
    const data = this.appUsers.slice();
    if (!sort.active || sort.direction === '') {
      this.appUsers = data;
      return;
    }

    this.appUsers.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'usuario': return this.compare(a.name, b.name, isAsc);
        default: return 0;
      }
    });
    this.dataSource = new MatTableDataSource<AppUser>(this.appUsers);
    this.defineFilterPredicate();
  } 

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private defineFilterPredicate()
  {
    this.dataSource.filterPredicate = function(data, filter: string): boolean 
    {
      if(data.name && data.name.toLowerCase().includes(filter))
      {
        return true;
      }     
      return false;

    };
  }

  private compare(a: number | string, b: number | string, isAsc: boolean) 
  {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public getUsers()
    {
      this.backendService.getAllUsers().then((users: Users)=>
      {
        this.backendService.getUsersGroups().then((usersGroup: Usersgroup)=>
        {
          let appUsers: Array<AppUser>=[]
          users.forEach(user => {
            let appUser: AppUser = new AppUser(user.id, user.nome, null);
           
            for (let i = 0; i < usersGroup.length; i++) {
              const userGroup = usersGroup[i];
              if(userGroup['usuario_id']==user.id)
              {
                  appUser.userGroup = UserGroup.getUserGroupById(userGroup["grupo_id"]);
              }
            }
            if(appUser.userGroup==null)
            {
              appUser.userGroup=UserGroup.NONE;
            }
           
            appUsers.push(appUser);
          });
          this.appUsers=appUsers;
          this.dataSource = new MatTableDataSource<AppUser>(this.appUsers);
          this.sort.sort(({ id: 'usuario', start: 'asc'}) as MatSortable);
          this.dataSource.sort = this.sort;
          this.createFormControls();
        })
      });
    }

    private createFormControls()
    {
      this.appUsers.forEach(appUser => {
        let formControlName = this.getFormControlNameByUserId(appUser.id);
        let formControl = new FormControl('');
        this.permissionsFormGroup.addControl(formControlName, formControl);
        formControl.setValue(appUser.userGroup);
      });
    }

    public getSelectedUserGroup(userGroup: UserGroup) : UserGroup
    {
      return UserGroup.getUserGroupById(userGroup.id);
    }

    public close()
    {
      this.tool.closeDialog();
    }
    
    public saveItem(id: number)
    {
      let formControlName = this.getFormControlNameByUserId(id);

      this.appUsers.forEach(appUser => 
      {
        if(appUser.id==id) 
        {
          appUser.userGroup=this.permissionsFormGroup.get(formControlName).value;
          
          let usergroup : Usergroup = new Object();
          usergroup["grupo_id"]=appUser.userGroup.id;
          usergroup["usuario_id"]=appUser.id;
          this.backendService.setUserGroup(usergroup).then(()=>
          {
            this.toastService.sucess("Usuário configurado.", "Sucesso");
          }
          ).catch(()=>
          {
            this.toastService.error("Problemas ao configurar usuário..", "Erro");
          }
            
          );
        }
      });
    }

    public cancelItem(id: number)
    {
      let formControlName = this.getFormControlNameByUserId(id);
      

      this.appUsers.forEach(appUser => 
      {
        if(appUser.id==id) 
        {
          this.permissionsFormGroup.get(formControlName).setValue(appUser.userGroup);
          this.toastService.sucess("Configuração do usuário restaurada.","Sucesso");
        }
      });
    }

    private getFormControlNameByUserId(id: any)
    {
      let formControlName = "user-"+id+"-group";
      return formControlName;
    }


}
