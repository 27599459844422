import Collection from "ol/Collection";
import Feature from "ol/Feature";
import { EditionSegment } from "src/app/map/vo/edition-segment";
import { GeometryService } from "src/app/service/geometry.service";
import { Step } from "../../../vo/step";
import { LayerSnapInteraction } from "../../tools-utils/custom-edition-interaction/interaction/layer-snap-interection";
import { SelectInteraction } from "../../tools-utils/custom-edition-interaction/interaction/select-interaction";
import { ModifyInteraction } from "../../tools-utils/custom-edition-interaction/interaction/modify-interaction";
import { TranslateInteraction } from "../../tools-utils/custom-edition-interaction/interaction/translate-interaction";
import { AddInteraction } from "../../tools-utils/custom-edition-interaction/interaction/add-interaction";
import { EditEdificacoesToolComponent } from "../edit-edificacoes-tool.component";
import { DialogComponent } from "src/app/map/dialog/dialog.component";
import { GenericConfirmationDialogComponent } from "src/app/map/dialog/generic-confirmation-dialog/generic-confirmation-dialog.component";
import VectorSource from "ol/source/Vector";
import Polygon from "ol/geom/Polygon";

export class EditEdificacoesEdicoesStep extends Step
 {
    private selectedFeatures: Collection<Feature<any>>;
    private editionSnapFeatures: Collection<Feature<any>>;
    private confirmDialogRef: any;
    private sequentialNewFeatureIndex : number;
    

    constructor(index: number, private editEdificacoesToolComponent: EditEdificacoesToolComponent)
    {
        super(index);
        this.setTitle("Passo 3: Execute a edição nas geometrias de edificações");
        this.setDescription("Selecione a geometria que deseja modificar ou habilite a ferramenta para adicionar uma nova geometria.");
        this.selectedFeatures=new Collection<Feature<any>>();
        this.editionSnapFeatures=new Collection<Feature<any>>();
        this.sequentialNewFeatureIndex=0;
    }
    execute()
    {
      this.editEdificacoesToolComponent.currentEditionInteraction=null;
      let layers = [];
      layers.push(this.editEdificacoesToolComponent.edificacoesLayer);

      var selectInteraction = new SelectInteraction(this.editEdificacoesToolComponent.edificacoesSelectInterectionId,{
        layers: layers,
        features: this.selectedFeatures,
        currentStep: this,
        style: this.editEdificacoesToolComponent.edificacoesSelectedStyleWithLabelFunction
      });

      //SELECT
      this.editEdificacoesToolComponent.customEditionController.addInteraction(this.editEdificacoesToolComponent.edificacoesSelectInterectionId,selectInteraction);
      //this.editEdificacoesToolComponent.customEditionController.enableInteraction(this.editEdificacoesToolComponent.edificacoesSelectInterectionId);

      //MODIFY
      const modify = new ModifyInteraction(this.editEdificacoesToolComponent.edificacoesModifyInterectionId, {features: this.selectedFeatures, currentStep: this});
      this.editEdificacoesToolComponent.customEditionController.addInteraction(this.editEdificacoesToolComponent.edificacoesModifyInterectionId,modify);

      //TRANSLATE
      const translate = new TranslateInteraction(this.editEdificacoesToolComponent.edificacoesTranslateInterectionId, {features: this.selectedFeatures, currentStep: this});
      this.editEdificacoesToolComponent.customEditionController.addInteraction(this.editEdificacoesToolComponent.edificacoesTranslateInterectionId,translate);

      //DRAW
      const addInteraction = new AddInteraction(this.editEdificacoesToolComponent.edificacoesAddInterectionId, {currentStep: this, type: 'MultiPolygon', source: this.editEdificacoesToolComponent.edificacoesLayer.getSource()});
      this.editEdificacoesToolComponent.customEditionController.addInteraction(this.editEdificacoesToolComponent.edificacoesAddInterectionId,addInteraction);

      //DEFAULT Initial Interaction
      this.editEdificacoesToolComponent.enableEditionInteraction(this.editEdificacoesToolComponent.edificacoesModifyInterectionId);

      //SNAP
      this.enableEditionSnapCollection();

      this.editEdificacoesToolComponent.calcularAreaTotal();

    }
    public onFeatureSelected(selectedFeature)
    {
      this.loadSegments(selectedFeature);
      this.editEdificacoesToolComponent.setCurrentSelectedFeature(selectedFeature);
    }

    private loadSegments(feature: any)
    {
      if(feature)
      {
        let viewRotation = this.editEdificacoesToolComponent.customEditionController.getRotation();

        let geometry = feature.getGeometry();
        if(geometry.getType()=="MultiPolygon")
        {
          geometry = feature.getGeometry().getPolygon(0);
        }

        let geometrySegments : EditionSegment[] = GeometryService.getGeometryEditionSegments(geometry, viewRotation);
        this.editEdificacoesToolComponent.edificacaoSegmentList = geometrySegments;
      }
      else
      {
        this.editEdificacoesToolComponent.edificacaoSegmentList = [];
      }


    }
    public onModifyEnd(feature)
    {
      this.editEdificacoesToolComponent.updateFeatureArea(feature);
      this.editEdificacoesToolComponent.setModifiedFeature(feature, true);
      this.loadSegments(feature);      
    }
    enableEditionSnapCollection()
    {
      let recuoFeatures = this.editEdificacoesToolComponent.recuoLayer.getSource().getFeatures();
      let edificacoesFeatures = this.editEdificacoesToolComponent.edificacoesLayer.getSource().getFeatures();
      let lotesFeatures = this.editEdificacoesToolComponent.lotesLayer.getSource().getFeatures();

      recuoFeatures.forEach(feature => {
        this.editionSnapFeatures.push(feature);        
      });

      edificacoesFeatures.forEach(feature => {
        this.editionSnapFeatures.push(feature);        
      });

      lotesFeatures.forEach(feature => {
        this.editionSnapFeatures.push(feature);        
      });

      let snap = new LayerSnapInteraction(this.editEdificacoesToolComponent.selectedEdificacoesSnapInterectionId, {features: this.editionSnapFeatures});
      this.editEdificacoesToolComponent.customEditionController.addInteraction(this.editEdificacoesToolComponent.selectedEdificacoesSnapInterectionId, snap);

      this.editEdificacoesToolComponent.customEditionController.enableInteraction(this.editEdificacoesToolComponent.selectedEdificacoesSnapInterectionId);
    }

    callback(object: any)
    {
      if(object.edificacoesChanged)
      {
        this.updateFeatureSelection(object.selectedFeature);
      }
      else
      {
        if(object.enableInteractionId)
        {
           this.enableInterection(object.enableInteractionId)
        }
        
      }
    }

    updateFeatureSelection(selectedFeature)
    {
      this.selectedFeatures.clear();
      if(selectedFeature)
      {
        this.selectedFeatures.push(selectedFeature);
      }
      this.onFeatureSelected(selectedFeature);
    }

    rollback()
    {
      this.editEdificacoesToolComponent.customEditionController.removeInteraction(this.editEdificacoesToolComponent.edificacoesModifyInterectionId);
      this.editEdificacoesToolComponent.customEditionController.removeInteraction(this.editEdificacoesToolComponent.edificacoesSelectInterectionId);
      this.editEdificacoesToolComponent.customEditionController.removeInteraction(this.editEdificacoesToolComponent.edificacoesTranslateInterectionId);
      this.editEdificacoesToolComponent.customEditionController.removeInteraction(this.editEdificacoesToolComponent.selectedEdificacoesSnapInterectionId);
      this.editEdificacoesToolComponent.customEditionController.removeInteraction(this.editEdificacoesToolComponent.edificacoesAddInterectionId);
    }
    finish()
    {
      this.editEdificacoesToolComponent.customEditionController.removeInteraction(this.editEdificacoesToolComponent.edificacoesModifyInterectionId);
      this.editEdificacoesToolComponent.customEditionController.removeInteraction(this.editEdificacoesToolComponent.edificacoesTranslateInterectionId);
      this.editEdificacoesToolComponent.customEditionController.removeInteraction(this.editEdificacoesToolComponent.edificacoesSelectInterectionId);
      this.editEdificacoesToolComponent.customEditionController.removeInteraction(this.editEdificacoesToolComponent.selectedEdificacoesSnapInterectionId);
      this.editEdificacoesToolComponent.customEditionController.removeInteraction(this.editEdificacoesToolComponent.edificacoesAddInterectionId);        
    }
    valid()
    {
      return this.validarAtributos();
    }
    private enableInterection(interactionId)
    {
      if(interactionId==this.editEdificacoesToolComponent.edificacoesModifyInterectionId)
      {
        this.editEdificacoesToolComponent.customEditionController.disableInteraction(this.editEdificacoesToolComponent.edificacoesAddInterectionId);
        this.editEdificacoesToolComponent.customEditionController.disableInteraction(this.editEdificacoesToolComponent.edificacoesTranslateInterectionId);
        this.editEdificacoesToolComponent.customEditionController.enableInteraction(this.editEdificacoesToolComponent.edificacoesSelectInterectionId);
        this.editEdificacoesToolComponent.customEditionController.enableInteraction(this.editEdificacoesToolComponent.edificacoesModifyInterectionId);                
      }
      else if(interactionId==this.editEdificacoesToolComponent.edificacoesTranslateInterectionId)
      {
        this.editEdificacoesToolComponent.customEditionController.disableInteraction(this.editEdificacoesToolComponent.edificacoesAddInterectionId);        
        this.editEdificacoesToolComponent.customEditionController.disableInteraction(this.editEdificacoesToolComponent.edificacoesModifyInterectionId);
        this.editEdificacoesToolComponent.customEditionController.enableInteraction(this.editEdificacoesToolComponent.edificacoesSelectInterectionId);
        this.editEdificacoesToolComponent.customEditionController.enableInteraction(this.editEdificacoesToolComponent.edificacoesTranslateInterectionId);
      }
      else if(interactionId==this.editEdificacoesToolComponent.edificacoesAddInterectionId)
      {
        this.editEdificacoesToolComponent.customEditionController.disableInteraction(this.editEdificacoesToolComponent.edificacoesSelectInterectionId);
        this.editEdificacoesToolComponent.customEditionController.disableInteraction(this.editEdificacoesToolComponent.edificacoesModifyInterectionId);
        this.editEdificacoesToolComponent.customEditionController.disableInteraction(this.editEdificacoesToolComponent.edificacoesTranslateInterectionId);
        this.editEdificacoesToolComponent.customEditionController.enableInteraction(this.editEdificacoesToolComponent.edificacoesAddInterectionId);
      }
      else if(interactionId==this.editEdificacoesToolComponent.edificacoesDeleteInterectionId)
      {
        this.editEdificacoesToolComponent.currentEditionInteraction=null;
        this.deleteCurrentFeature()

      } else if(interactionId==this.editEdificacoesToolComponent.edificacoesDuplicateInterectionId)
      {
        this.editEdificacoesToolComponent.currentEditionInteraction=null;
        this.duplicateCurrentFeature()
      }         
    }

    private deleteCurrentFeature()
    {
      let currentSelectedFeature = this.editEdificacoesToolComponent.getCurrentSelectedFeature();
      
      if(currentSelectedFeature)
      {
        this.confirmDialogRef = this.editEdificacoesToolComponent.dialog.open(DialogComponent, {
          height: '30%',
          width: '20%',
          data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente apagar a feição selecionada?" }
        });
  
        this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
          if(confirmResult)
          {
            let edificacoesSource : VectorSource<Polygon> = this.editEdificacoesToolComponent.edificacoesLayer.getSource();
            edificacoesSource.removeFeature(currentSelectedFeature); 
            this.editEdificacoesToolComponent.refreshFeaturesList();
            this.callback({edificacoesChanged: true});
            
            this.updateEdificacoesSnap();

            //Se for uma geometria já persistida, salvar para apagar do banco
            if(currentSelectedFeature.get(this.editEdificacoesToolComponent.edificacoesIdAttribute)>=0)
            {
              this.editEdificacoesToolComponent.setModifiedFeature(currentSelectedFeature, true);
              this.editEdificacoesToolComponent.deletedEdificacoes.push(currentSelectedFeature);              
            }
            

            this.editEdificacoesToolComponent.toastService.sucess("Feição selecionada foi removida!","Remover Feição")
            this.editEdificacoesToolComponent.currentEditionInteraction=null;

            this.editEdificacoesToolComponent.updateEdificacoesFeaturesIndex();
          }
          else
          {
            this.editEdificacoesToolComponent.currentEditionInteraction=null;
          }
        });
      }
      
    }

    public onFeatureInserted(feature: Feature<any>)
    {
      if(feature)
      {
        //Setting default new attribute id
        feature.set(this.editEdificacoesToolComponent.edificacoesIdAttribute, "*");
        feature.setId(this.getNewFeatureIndex());
        this.editEdificacoesToolComponent.updateFeatureArea(feature);
        this.editEdificacoesToolComponent.setModifiedFeature(feature, true);
        
        //Update edificações table and snap layer
        this.editEdificacoesToolComponent.refreshFeaturesList();
        this.updateEdificacoesSnap();

        this.editEdificacoesToolComponent.updateEdificacoesFeaturesIndex();

        this.editEdificacoesToolComponent.toastService.sucess("Feição inserida! ","Inserir Feição")
      }
    }

    private updateEdificacoesSnap()
    {
      this.editionSnapFeatures.clear();
      this.editEdificacoesToolComponent.edificacoesLayer.getSource().getFeatures().forEach(feature => 
      {
        this.editionSnapFeatures.push(feature);        
      });
    }

    private duplicateCurrentFeature()
    {

      let currentSelectedFeature : Feature<any> = this.editEdificacoesToolComponent.getCurrentSelectedFeature();

      if(currentSelectedFeature)
      {
        this.updateFeatureSelection(null);
        let duplicatedFeature = currentSelectedFeature.clone();
        duplicatedFeature.set(this.editEdificacoesToolComponent.edificacoesIdAttribute, "*");

        duplicatedFeature.setId(this.getNewFeatureIndex());

        this.editEdificacoesToolComponent.updateFeatureArea(duplicatedFeature);
        this.editEdificacoesToolComponent.setModifiedFeature(duplicatedFeature, true);
        
        this.editEdificacoesToolComponent.edificacoesLayer.getSource().addFeature(duplicatedFeature);
        //Update edificações table and snap layer
        this.editEdificacoesToolComponent.refreshFeaturesList();
        this.updateEdificacoesSnap();

        this.editEdificacoesToolComponent.updateEdificacoesFeaturesIndex();
      }
      else
      {
        this.editEdificacoesToolComponent.toastService.error("Não há feição selecionada para duplicar!","Duplicar Feição")
      }

    }

    private getNewFeatureIndex()
    {
      this.sequentialNewFeatureIndex--;
      return this.sequentialNewFeatureIndex;
    }



    private validarAtributos()
    {
      let valido: boolean= true;
      //classe
      
      for (let i = 0; i < this.editEdificacoesToolComponent.currentEdificacoesFeatures.length; i++)
      {
        const feature = this.editEdificacoesToolComponent.currentEdificacoesFeatures[i];

        let classe=this.editEdificacoesToolComponent.getAttributeValue(feature, this.editEdificacoesToolComponent.edificacoesClasseAttribute)
        let pavimentos=this.editEdificacoesToolComponent.getAttributeValue(feature, this.editEdificacoesToolComponent.edificacoesPavimentosAttribute)

        //verificando se atributo classe está na lista de validos
        if(!this.editEdificacoesToolComponent.edificacoesListaClassesValidas.includes(classe))
        { 
          let classes = this.editEdificacoesToolComponent.getValoresPossiveisText(this.editEdificacoesToolComponent.edificacoesListaClassesValidas)
          this.editEdificacoesToolComponent.toastService.error("Favor preencher campo classe com um valor válido: " + classes,"Problema no preenchimento dos atributos.");
          valido = false;
          break;
        }

        //verificando se atributo pavimentos está na lista de validos
        if(!this.editEdificacoesToolComponent.edificacoesListaPavimentosValidos.includes(pavimentos))
        { 
          let pavimentos = this.editEdificacoesToolComponent.getValoresPossiveisText(this.editEdificacoesToolComponent.edificacoesListaPavimentosValidos)
          this.editEdificacoesToolComponent.toastService.error("Favor preencher campo pavimentos com um valor válido: " + pavimentos,"Problema no preenchimento dos atributos.");
          valido = false;
          break;
        }
      }
        return valido;
    }

}
