import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/map/dialog/dialog.component';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';
import { LayersInfo } from 'src/app/map/vo/layersinfo';
import { FeatureService } from 'src/app/service/feature.service';
import { Feature } from '../../../vo/feature';
import { FeatureInfoTool } from '../../fetureinfo-tool/featureinfo-tool';
import { FetureInfoToolComponent } from '../../fetureinfo-tool/fetureinfo-tool.component';
import { Tool } from '../../tool';
import { AttributeSearchFeatureEdit } from './attribute-search-feature-edit';

export class AttributeSearchSettlementPropertyEdit extends AttributeSearchFeatureEdit
{
    static customizedFeatureInfo: string = "attribute-search-settlement-property-edit";

    constructor(parent: MatDialog, tool: any, feature: Feature, layerConfig: LayerConfig, private layerConfigService: LayersConfigService,
        private featureService: FeatureService)
    {
        super(parent, tool, feature, layerConfig);        
    }
    showFeatureEdit()
    {

        let idAttribute=this.layerConfigService.getLayerIdAttribute(this.layerConfig);
        let layerTableName = this.layerConfigService.getLayerTable(this.layerConfig);
        let idValueProperty = this.featureService.getPropertyByKey(this.feature.properties, idAttribute);  

        this.tool.currentFeature = this.feature;
        this.tool.layer = this.layerConfig;

        return this.tool.loadFeatureAttributes(layerTableName, idAttribute, idValueProperty.value.toString());        
        //return this.tool.openEditSettlementProperty();
    }
}
