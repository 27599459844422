import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Canvas } from 'leaflet';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from '../../environments/environment';
import { NotificationService } from '../service/notification.service';
import { CanvasService } from './canvas/canvas.service';
import { LayersConfigService } from './layers/layers.config.service';
import { ClearMarkerTool } from './tools/clearmarker-tool/clearmarker-tool';
import { DownloadTool } from './tools/download-tool/download-tool';
import { EditLayerTool } from './tools/edit-layer-tool/edit-layer-tool';
import { FeatureInfoTool } from './tools/fetureinfo-tool/featureinfo-tool';
import { MeasureTool } from './tools/measure-tool/measure-tool';
import { PdfprintingTool } from './tools/pdfprinting/pdfprinting-tool';
import { PropertyRegistrationTool } from './tools/property-registration/property-registration-tool';
import { PropertySearchTool } from './tools/propertysearch-tool/propertysearch-tool';
import { FotosFachadaManagerTool } from './tools/fotosfachada-manager/fotosfachada-manager-tool';
import { SavemapTool } from './tools/savemap-tool/savemap-tool';
import { SaveEditionLayerTool } from './tools/save-edition-layer-tool/save-edition-layer-tool';
import { SplitTool } from './tools/split-tool/split-tool';
import { MergeTool } from './tools/merge-tool/merge-tool';
import { FitLayerTool } from './tools/fit-layer-tool/fit-layer-tool';
import { AnnotationTool } from './tools/annotation-tool/annotation-tool';
import { Tool } from './tools/tool';
import { ToolsService } from './tools/tools.service';
import { AppNotification } from './vo/notification';
import { FeatureService } from '../service/feature.service';
import { EditAttributeLayerTool } from './tools/edit-attribute-layer-tool/edit-attribute-layer-tool';
import { EditionService } from '../service/edition.service';
import { Photo360LayerTool } from './tools/photo360-layer-tool/photo360-layer-tool';
import { EditStyleLayerTool } from './tools/edit-style-layer-tool/edit-style-layer-tool';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Userinfo } from '@funcate/sigweb-cti-api';
import { AuthenticationService } from '../service/authentication.service';
import { SecurityManagementTool } from './tools/security-management-tool/security-management-tool';
import { StreetSearchTool } from './tools/street-search-tool/street-search-tool';
import { DialogComponent } from './dialog/dialog.component';
import { FichaLinkComponent } from './tools/property-registration/ficha-link/ficha-link.component';
import { ToastService } from '../service/toast.service';
import { FichaUploadComponent } from './tools/property-registration/ficha-upload/ficha-upload.component';
import { CapabilitiesService } from '../service/capabilities.service';
import { VersionComponent } from '../version/version.component';
import { EditEdificacoesTool } from './tools/edit-edificacoes-tool/edit-edificacoes-tool';
import { EditEdificacoesToolComponent } from './tools/edit-edificacoes-tool/edit-edificacoes-tool.component';
import { AddColetaPgvTool } from './tools/coleta-pgv-tool/add-coleta-pgv-tool/add-coleta-pgv-tool';
import { ManageColetaPGVTool } from './tools/coleta-pgv-tool/manage-coleta-pgv-tool/manage-coleta-pgv-tool';
import { EditColetaPgvTool } from './tools/coleta-pgv-tool/edit-coleta-pgv-tool/edit-coleta-pgv-tool';
import { CertidoesConfigTool } from './tools/certidoes-config-tool/certidoes-config-tool';
import { GenerateCertidoesComponent } from './tools/certidoes-tool/generate-certidoes.component';
import { ColetaPgvService } from '../service/coleta-pgv.service';
import { CertidoesTool } from './tools/certidoes-tool/certidoes-tool';
import { FormControl, FormGroup } from '@angular/forms';
import { SetupImoveisLinkTool } from './tools/setup-imoveis-link-tool/setup-imoveis-link-tool';
import { EditSettlementTool } from './tools/settlement-tools/edit-settlement-tool';
import { EditSettlementPropertyTool } from './tools/settlement-property-tools/edit-settlement-property-tool';
import { SettlementPropertyTool } from './tools/settlement-property-tools/settlement-property-tool';
import { SettlementTool } from './tools/settlement-tools/settlement-tool';
import { NewImovelTool } from './tools/new-imovel-tool/new-imovel-tool';
import { SearchCoordTool } from './tools/search-coord-tool/search-coord-tool';
import { AppConfigTool } from './tools/app-config-tool/app-config-tool';
import { GenericFeatureReportTool } from './tools/generic-feature-report-tool/generic-feature-report-tool';
import { ExportSinterTool } from './tools/export-sinter-tool/export-sinter-tool';

import * as proj4 from 'proj4';
import { Inscricao } from './vo/inscricao';
import { FotosfachadaManagerComponent } from './tools/fotosfachada-manager/fotosfachada-manager.component';
import { SetupImoveisLinkToolComponent } from './tools/setup-imoveis-link-tool/setup-imoveis-link-tool.component';
import { BackendService } from '../service/backend.service';
import { ContactComponent } from '../contact/contact.component';
import { UserGroup } from './vo/user-group';
import { ImportLayerComponent } from './tools/app-config-tool/import-layer/import-layer.component';
import { DocumentService } from '../service/document.service';
import { ManageSettlementTool } from './tools/manage-settlement-tool/manage-settlement-tool';
import { AttributeSearchTool } from './tools/attribute-search-tool/attribute-search-tool';
import { GeneralService } from '../service/general.service';
import { CertidoesService } from '../service/certidoes.service';
import { AnnotationService } from '../service/annotation.service';
import { EditCadastroSocialComponent } from './tools/settlement-property-tools/cadastro-social/edit-cadastro-social.component';
import { AbstractDocumentType } from './vo/document/abstract-document-type';
import { DocumentUploadComponent } from './dialog/document-upload-dialog/document-upload.component';
import { EditStyleSLDComponent } from './tools/edit-style-layer-tool/edit-style-sld/edit-style-sld.component';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {

  title = environment.title;
  toolbarTxt = this.title+' - '+environment.description;
  footerTxt = this.toolbarTxt;
  appVersion = environment.version;
  // to PDF print
  datePrint:string;
  toolbarPrintTxt=this.toolbarTxt;
  footerPrintTxt=this.footerTxt;
  notificationSubscrition: Subscription;
  viewerEnabled: boolean;
  userAuthenticated : Userinfo;
  mapCoordinatesSRIDList: any[];
  currentMapCoordDisplaySRID: string;
  currentMapCoordDisplaySRIDDescription: string;

  // for resize left menu
  width = 150;
  x = 100;
  oldX = 0;
  grabber = false;
  
  constructor(private canvasService:CanvasService,
    private layerConfigService: LayersConfigService,
    private notificationService: NotificationService,
    private toolsService: ToolsService,
    private featureService: FeatureService,
    private editionService: EditionService,
    private authenticationService: AuthenticationService,
    private toastService: ToastService,
    private capabilitiesService: CapabilitiesService,
    private coletaPgvService: ColetaPgvService,
    private backendService: BackendService,
    private documentService: DocumentService,
    private generalService: GeneralService,
    private certidoesService: CertidoesService,
    private annotationService: AnnotationService,
    private dialog: MatDialog) 
  {
    this.viewerEnabled=true;
    this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
      if (notification.event==AppNotification.CHANGE_PRINT_TITLE_EVENT)
      {
        if(notification.object.pdfTitle)
          this.toolbarPrintTxt=notification.object.pdfTitle;
        if(notification.object.pdfFooter)
          this.footerPrintTxt=notification.object.pdfFooter;
        
        window.setTimeout(()=>{
          window.print();
        }, 150);
      }
      else if (notification.event==AppNotification.AUTHENTICATION_CHANGED) 
      {
        this.userAuthenticated = notification.object;
        
      } else if (notification.event==AppNotification.ON_MAP_READY) 
      {
        this.setupCursorCoordinatesDisplay(notification.object);
      }
       
    });
    this.initializateTools();
    this.initMapCoordinatesSRIDList();
  }

  initializateTools()
  {
    let tools = new Array<Tool>();
    tools.push(new FeatureInfoTool(this.toolsService, this.dialog));
    tools.push(new SplitTool(this.toolsService, this.layerConfigService, this.toastService, this.dialog));
    tools.push(new PropertyRegistrationTool(this.toolsService, this.layerConfigService, this.featureService, this.toastService, this.dialog));
    tools.push(new PropertySearchTool(this.notificationService, this.toolsService, this.dialog));
    tools.push(new PdfprintingTool(this.toolsService, this.dialog));
    tools.push(new MeasureTool(this.toolsService, this.canvasService));
    tools.push(new Photo360LayerTool( this.toolsService, this.layerConfigService, this.dialog));
    tools.push(new SavemapTool(this.canvasService, this.toolsService));
    tools.push(new ClearMarkerTool(this.canvasService, this.toolsService));
    tools.push(new DownloadTool(this.dialog, this.toolsService));
    tools.push(new EditLayerTool(this.dialog, this.canvasService, this.toolsService, this.notificationService, this.featureService, this.layerConfigService, this.editionService, this.toastService));
    tools.push(new EditAttributeLayerTool(this.dialog, this.toolsService, this.layerConfigService, this.canvasService));
    tools.push(new EditStyleLayerTool(this.dialog, this.toolsService, this.layerConfigService));
    tools.push(new SecurityManagementTool(this.dialog, this.toolsService));
    tools.push(new StreetSearchTool(this.dialog, this.toolsService));
    tools.push(new MergeTool(this.toolsService, this.layerConfigService, this.featureService, this.toastService, this.canvasService, this.dialog));
    tools.push(new FitLayerTool(this.canvasService, this.toolsService, this.capabilitiesService, this.layerConfigService));
    tools.push(new EditEdificacoesTool(this.toolsService, this.layerConfigService, this.featureService, this.toastService, this.canvasService, this.dialog));
    tools.push(new AddColetaPgvTool(this.toolsService, this.canvasService, this.layerConfigService, this.dialog));
    tools.push(new ManageColetaPGVTool(this.dialog, this.toolsService, this.canvasService, this.layerConfigService, this.notificationService, this.authenticationService));
    tools.push(new EditColetaPgvTool(this.toolsService, this.canvasService,this.layerConfigService, this.featureService, this.toastService, this.coletaPgvService, this.dialog));
    tools.push(new SetupImoveisLinkTool(this.toolsService, this.layerConfigService, this.featureService, this.toastService, this.canvasService, this.dialog));
    new ManageSettlementTool(this.dialog, this.toolsService, this.canvasService, this.layerConfigService, this.notificationService,
       this.authenticationService);
 //   tools.push(new CertidoesConfigTool(this.dialog, this.toolsService));
    tools.push(new CertidoesTool(this.dialog, this.toolsService, this.layerConfigService, this.featureService, this.toastService, this.canvasService));   
    // tools.push(new CertidaoUsoTool(this.dialog, this.toolsService, this.layerConfigService, this.featureService, this.toastService));
    // tools.push(new CertidaoEmplacamentoTool(this.dialog, this.toolsService, this.layerConfigService, this.featureService, this.toastService));
    tools.push(new FotosFachadaManagerTool(this.toolsService));
    tools.push(new NewImovelTool(this.toolsService, this.backendService, this.layerConfigService, this.toastService, this.canvasService, this.dialog));
    tools.push(new SearchCoordTool(this.dialog, this.toolsService));
    tools.push(new AppConfigTool(this.dialog, this.toolsService));
    tools.push(new AttributeSearchTool(this.dialog, this.toolsService, this.layerConfigService));    
    tools.push(new SettlementTool(this.toolsService, this.layerConfigService, this.toastService, this.canvasService, this.featureService, this.editionService, this.dialog));
    tools.push(new EditSettlementTool(this.toolsService, this.documentService, this.layerConfigService, this.toastService, this.canvasService, this.featureService, this.editionService, this.dialog));
    tools.push(new GenericFeatureReportTool(this.toolsService, this.layerConfigService, this.toastService, this.canvasService, this.featureService, this.editionService , this.authenticationService, this.generalService,this.certidoesService, this.notificationService, this.dialog));
    tools.push(new AnnotationTool(this.dialog, this.toolsService, this.canvasService, this.layerConfigService, this.notificationService, this.authenticationService, this.annotationService, this.toastService));    
    tools.push(new EditSettlementPropertyTool(this.toolsService, this.documentService, this.layerConfigService, this.toastService, this.canvasService, this.featureService, this.editionService, this.dialog));
    tools.push(new SettlementPropertyTool(this.toolsService, this.documentService, this.layerConfigService, this.toastService, this.canvasService, this.featureService, this.editionService, this.dialog));
    tools.push(new ExportSinterTool(this.dialog, this.toolsService));

    this.toolsService.setTools(tools);
    this.toolsService.loadToolsPermissions();

    
  }

  ngOnInit(): void {
    this.datePrint=(new Date()).toLocaleString();
  }

  ngOnDestroy() {
    this.notificationSubscrition.unsubscribe();
  }

  onMouseMove(event: MouseEvent) {
    if (!this.grabber) {
      return;
    }
    this.resizer(event.clientX - this.oldX);
    this.oldX = event.clientX;

    let mainmap=document.getElementsByClassName('main-map')[0];
    if(mainmap) mainmap.setAttribute("width",(document.body.clientWidth - this.width)+"px");
  }

  onMouseDown(event: MouseEvent): void{
    if(!this.grabber) {
      this.width=document.getElementById("sidebar").clientWidth;
      document.getElementById("sidebar").removeAttribute("class");
      document.getElementById('canvas').className="";//"col-sm-12 col-sm-offset-3 col-md-12 col-md-offset-2 h-100";
      
      this.addSafeDiv();
    }
    this.grabber = true;
    this.oldX = event.clientX;
  }

  onMouseUp(event: MouseEvent) {
    this.grabber = false;
    this.delSafeDiv();
  }

  addSafeDiv(){
    document.getElementById("safe-div").style.display="block";
  }

  delSafeDiv(){
    document.getElementById("safe-div").style.display="none";
  }

  resizer(offsetX: number) {
    this.width += offsetX;
  }

    /**
   * Handling enable/disable legend viewer 
   */
  onChangeLegendViewer($event: MatSlideToggleChange){
    this.viewerEnabled=$event.checked;            
  }

  login()
  {
    if(!this.userAuthenticated)
    {
      let notification = new AppNotification(AppNotification.SHOW_AUTHENTICATION_DIALOG);
      this.notificationService.send(notification);
    }
  }

  logout()
  {
    if(this.userAuthenticated && this.userAuthenticated.authenticated)
    {
      this.authenticationService.logout();
    }
  }

  showVersion()
  {
     let dialogRef = this.dialog.open(DialogComponent, {
      minHeight: '30%', 
      maxHeight: '80%',
      minWidth: '40%',
       maxWidth: '80%',
       data: { component: VersionComponent, title: "Sobre" }
     });
  }

  showContact()
  {
     let dialogRef = this.dialog.open(DialogComponent, {
      minHeight: '30%', 
      maxHeight: '80%',
      minWidth: '40%',
       maxWidth: '80%',
       data: { component: ContactComponent, title: "Fale Conosco" }
     });
  }

  public tempTest()
  {
    let extensions = AbstractDocumentType.getInstance(5).getSupportedExtensions();
      let newDocumentoUploadDialog = this.dialog.open(DialogComponent, {
        height: '70%',
        width: '75%',
        data: { component: EditStyleSLDComponent, title: "AAA"}
    });   
  

  }

  private initMapCoordinatesSRIDList()
  {
    this.mapCoordinatesSRIDList = [];
    this.mapCoordinatesSRIDList.push({'id': "EPSG:31983", 'description': "UTM Zona 23 Sul (Metros) - EPSG:31983"});
    this.mapCoordinatesSRIDList.push({'id': "EPSG:4326", 'description': "Latitute Longitude (Graus Decimais) - EPSG:4326"});
    this.mapCoordinatesSRIDList.push({'id': "EPSG:3857", 'description': "Pseudo-Mercator (Metros) - EPSG:3857"});

    this.currentMapCoordDisplaySRID = "EPSG:31983";
    this.currentMapCoordDisplaySRIDDescription = "UTM Zona 23 Sul (Metros) - EPSG:31983";

  }

  public currentMapDisplaySRIDChanged(event)
  {
    let coordinatesDisplayCurrentX = <HTMLSpanElement>document.getElementById("coordinates-display-current-x");
    let coordinatesDisplayCurrentY = <HTMLSpanElement>document.getElementById("coordinates-display-current-y");
    if(coordinatesDisplayCurrentX && coordinatesDisplayCurrentY)
    { 
      coordinatesDisplayCurrentX.textContent="0.0000000";
      coordinatesDisplayCurrentY.textContent="0.0000000";
    }

  }

  public setupCursorCoordinatesDisplay(map: L.Map)
  {
    map.on('mousemove', function(e)
    {
      let coordinatesDisplayCurrentX = <HTMLSpanElement>document.getElementById("coordinates-display-current-x");
      let coordinatesDisplayCurrentY = <HTMLSpanElement>document.getElementById("coordinates-display-current-y");
      let coordinatesDisplayCurrentSRID = <HTMLInputElement>document.getElementById("coordinates-display-current-srid");
      
      if(coordinatesDisplayCurrentX && coordinatesDisplayCurrentY && coordinatesDisplayCurrentSRID)
      {
        let x = 0.0000000;
        let y = 0.0000000;

        if(coordinatesDisplayCurrentSRID.value=="EPSG:4326")
        {
          x = e['latlng'].lng.toFixed(7);        
          y = e['latlng'].lat.toFixed(7);     
        }
        else
        {
          
          let reprojectedCoords = proj4['default']("EPSG:4326", coordinatesDisplayCurrentSRID.value,[e['latlng'].lng,e['latlng'].lat]);
          if(reprojectedCoords && reprojectedCoords.length>0)
          {
            x = reprojectedCoords[0].toFixed(7);
            y = reprojectedCoords[1].toFixed(7);
          }
        }
        coordinatesDisplayCurrentX.textContent=x.toString();
        coordinatesDisplayCurrentY.textContent=y.toString();    
      }
      
    });
  }  

  public openHelp()
  {
    let helpUrl = environment.HELP_URL;
    window.open(helpUrl, "_blank");
  }
  public getUserGroupName(user)
  {
    let usergroupId = user.usergroup_id;
    let group = UserGroup.getUserGroupById(usergroupId);
    if(group)
    {
      return group.name
    }
    else
    {
      return "Usuário sem perfil."
    }
  }

}
