import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { ToolsService } from '../tools.service';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { LayerConfig } from '../../layers/layers.config';
import { Feature } from '../../vo/feature';
import { LayersConfigService } from '../../layers/layers.config.service';
import { EditStyleLayerToolComponent } from './edit-style-layer-tool.component';

export class EditStyleLayerTool extends AbstractLayerTool {

    private dialog: MatDialog;

    cursorType=null;
    id='edit-style-layer-tool';
    enabled=false;
    name='Edit Style Tool';
    title='Gerador de Mapas Temáticos';
    type='click';
    icon='palette';
    ll: any;
    protected visible:boolean=true;
    layerTool: boolean=true;
    customParameter=new Map<String, any>();
    private dialogRef: any;

    constructor(dialog: MatDialog, toolsService: ToolsService,
        private layersService: LayersConfigService) 
    { 
        super(toolsService)
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>
    {
        
    };

    public closeDialog()
    {
        this.dialogRef.close();
    }

    public enableImpl()
    {
        let currentLayerTool = this.toolsService.getToolParameter(this, "layer");

        if(currentLayerTool)
        {
            this.layersService.enableLayer(currentLayerTool);
        }   

        this.dialogRef = this.dialog.open(DialogComponent, {
            height: '80%',
            minWidth: '80%',            
            data: { component: EditStyleLayerToolComponent, title: "Tipo de Mapa Temático", dialog: this.dialog }
        });

        let whenDialogIsOpen=this.dialogRef.afterOpened().toPromise();
        whenDialogIsOpen.then(
            ()=>{
                this.toolsService.dispatchTool(this);
            }
        );
        this.dialogRef.afterClosed().toPromise().then(()=>{
            this.toolsService.toogleTool(this);
        });
    }
    public disableImpl()
    {
    }

    public isVisibleForLayer(layer :LayerConfig) : boolean
    {
        return this.layerTool;
    }

    public isEnabled(layer :LayerConfig) : boolean
    {
        let currentLayerTool = this.toolsService.getToolParameter(this, "layer");
        if(this.enabled && currentLayerTool.getId()==layer.getId() )
        {
            return true;
        }
        else
        {
            return false;
        }
    }
    
}
