<div class="container">
    <div class="row foto-control-overlay">
        <div class="col foto-control-overlay-back" (click)="back()">
            <mat-icon>
                navigate_before
            </mat-icon>
        </div>
        <div class="col foto-control-middle">
            
        </div>
        <div class="col foto-control-overlay-next"  (click)="next()">
            <mat-icon>
                navigate_next
            </mat-icon>
        </div>
    </div>
    
    <img [src]="fotoData" class="image-viewer">
</div>

