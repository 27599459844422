import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { Featureattributes } from '@funcate/sigweb-cti-api';
import { Subscription } from 'rxjs';
import { EditionService } from 'src/app/service/edition.service';
import { FeatureService } from 'src/app/service/feature.service';
import { CanvasService } from '../../../canvas/canvas.service';
import { LayerConfig } from '../../../layers/layers.config';
import { LayersConfigService } from '../../../layers/layers.config.service';
import { Property } from '../../../vo/property';
import { ToolsService } from '../../tools.service';
import { ColetaPGV } from 'src/app/map/vo/coleta-pgv';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { ToastService } from 'src/app/service/toast.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import Point from 'ol/geom/Point';
import { Marker } from 'leaflet';
import { EditionOperation } from 'src/app/map/vo/edition-operation';
import { GeomType } from 'src/app/map/vo/geomtype';
import { Operation } from 'src/app/map/vo/operation';
import { GeometryService } from 'src/app/service/geometry.service';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-edit-pgv-tool-tool',
  templateUrl: './edit-coleta-pgv-tool.component.html',
  styleUrls: ['./edit-coleta-pgv-tool.component.css']
})

export class EditColetaPGVToolComponent implements OnInit 
{
  public layer: LayerConfig;
  public idAttribute: string;
  displayedColumns: string[] = ['key', 'value'];
  public attributes: Array<Property>=[];
  public coletaPGV: ColetaPGV;
  public parent: any;  
  maxDate = new Date();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    private authenticationService: AuthenticationService,
    private toastService: ToastService,
    private editionService: EditionService,
    private dateAdapter: DateAdapter<Date>)
    {
      this.coletaPGV = data.coleta;
      this.layer = data.layer;
      this.parent = data.parent;

      let userInfo = this.authenticationService.getCurrentUser();
      this.coletaPGV.setFonte(userInfo['user_pgv_source']);
      this.dateAdapter.setLocale('pt-BR'); //dd/MM/yyyy
   }

  ngOnInit(): void {
  }

  public save()
  {
    if(this.validateColeta())
    {
      //let point :Marker<any> = new Marker<any>(this.coletaPGV.getPosition());

      //let feature = point.toGeoJSON();

      let editionOperation: EditionOperation = new EditionOperation(this.coletaPGV.getId(), this.coletaPGV.getFeatureGeoJSON(), GeomType.Point, Operation.Update);


      this.editionService.updateAttributes(this.coletaPGV.toProperties(), this.layer).then(()=>
      {
        this.toastService.sucess("Ponto de coleta PGV atualizado com sucesso!", "Coleta Atualizada.");
        this.close();
      });

    }

  }

  private validateColeta() : boolean
  {

    let tituloToast: string = "Falha ao salvar!"

    if(!this.coletaPGV.getFonte())
    {
      this.toastService.error("Favor preencher o campo Fonte da Informação.", tituloToast);
      return false;
    }

    if(!this.coletaPGV.getNaturezaTransacao())
    {
      this.toastService.error("Favor preencher o campo Natureza de Transação.", tituloToast);
      return false;
    }

    if(!this.coletaPGV.getDataColeta())
    {
      this.toastService.error("Favor preencher o campo Data da Coleta.", tituloToast);
      return false;
    }

    if(!this.coletaPGV.getAreaTerreno())
    {
      if(this.coletaPGV.getAreaTerreno()!=0)
      {
        this.toastService.error("Favor preencher o campo Área do Terreno.", tituloToast);
        return false;
      }      
    }

    if(!this.coletaPGV.getAreaConstrucao())
    {
      if(this.coletaPGV.getAreaConstrucao()!=0)
      {
        this.toastService.error("Favor preencher o campo Área do Construção.", tituloToast);
        return false;
      }
    }
    if(!this.coletaPGV.getValorTotal())
    {
      this.toastService.error("Favor preencher o campo Valor Total do Imóvel.", tituloToast);
      return false;
    }
    if(!this.coletaPGV.getObs())
    {
      this.toastService.error("Favor preencher o campo Observação.", tituloToast);
      return false;
    }
    if(!this.coletaPGV.getTipoConstrucao())
    {
      this.toastService.error("Favor preencher o campo Tipo da Construção.", tituloToast);
      return false;
    }

    return true;

  }

  public close()
  {
    this.parent.closeEditColeta();
  }

  public updateTotal()
  {
    if(this.coletaPGV)
    {
      let total : number = 0;
      if(this.coletaPGV.getValorConstrucao())
      {
        total += Number.parseFloat(this.coletaPGV.getValorConstrucao().toString());
      }
      if(this.coletaPGV.getValorTerreno())
      {
        total += Number.parseFloat(this.coletaPGV.getValorTerreno().toString());
      }
      this.coletaPGV.setValorTotal(total);
    }
  }


}
