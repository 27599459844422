
<div id="generic-dialog">
    <div  class="modal-header fechar" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div *ngIf="dialogTitle" class="dialog-title"> {{dialogTitle}} </div>      
    </div>
    <mat-dialog-content class="content-strech">
        <div class="annotation-edit-div">
            <div class="annotation-edit-layer">
            
                <div class="annotation-edit-name">
                    <div class="annotation-edit-name-label">
                        <mat-label>Nome:</mat-label>    
                    </div>
                    <div class="annotation-edit-name-value">
                        <input matInput placeholder="" name="name" [(ngModel)]="name" autofocus>
                    </div>
                </div>    
        
                <div class="annotation-edit-button-div">
                    <button type="button" (click)="save()" mat-raised-button color="primary" id="annotation-edit-save-button">
                        <span class="material-icons">get_app</span> Salvar
                    </button>
                    <button type="button" (click)="close()" mat-raised-button color="primary"  id="annotation-edit-close-button">
                        <span class="material-icons">clear</span> Fechar
                    </button>
                </div>    
            </div>
        </div>
    </mat-dialog-content>
  </div>


