import { Injectable } from '@angular/core';
import * as jsts from 'jsts';
import LinearRing from 'ol/geom/LinearRing';
import LineString from 'ol/geom/LineString';
import MultiLineString from 'ol/geom/MultiLineString';
import MultiPoint from 'ol/geom/MultiPoint';
import MultiPolygon from 'ol/geom/MultiPolygon';
import Point from 'ol/geom/Point';
import Polygon from 'ol/geom/Polygon';
import GeoJSON from 'ol/format/GeoJSON';
import Geometry from 'ol/geom/Geometry';
import SimpleGeometry from 'ol/geom/SimpleGeometry';
import { EditionSegment } from "../map/vo/edition-segment";




declare var jsts: jsts;


@Injectable({
  providedIn: 'root'
})

export class GeometryService {

  static tolerance: number = 0.001;
  static CDR = 0.01745329251994329576;	//!< Conversion factor: degrees to radians
  static CRD = 57.29577951308232087679;	//!< Conversion factor: radians to degrees


  public static adjustPolygonOrientation(polygon) {
    var polygonFixed = polygon.clone();

    var fixedCoordinates = polygonFixed.getCoordinates({ right: true });
    polygonFixed.setCoordinates(fixedCoordinates);

    return polygonFixed;
  }

  public static findIntersectionSegment(olReferenceCoord, olLineString) {


    var index = -1;

    var coordArray = olLineString.getCoordinates();
    for (let i = 0; i < coordArray.length - 1; i++) {
      var olSegmentStartCoord = coordArray[i];
      var olSegmentEndCoord = coordArray[i + 1];

      if (GeometryService.isPointInLine(olReferenceCoord, olSegmentStartCoord, olSegmentEndCoord) == true) {
        index = i;
        break;
      }
    }

    return index;
  }

  public static isPointInLine(olReferenceCoord, olSegmentStartCoord, olSegmentEndCoord) {


    var jstsReferenceCoord = GeometryService.convertOL2JSTS_coord(olReferenceCoord);

    var jstsSegmentStartCoord = GeometryService.convertOL2JSTS_coord(olSegmentStartCoord);
    var jstsSegmentEndCoord = GeometryService.convertOL2JSTS_coord(olSegmentEndCoord);



    var distance = jsts.algorithm.CGAlgorithms.distancePointLine(jstsReferenceCoord, jstsSegmentStartCoord, jstsSegmentEndCoord);
    if (this.isNumberEqual(distance, 0) == true) {
      return true;
    }

    return false;
  }

  public static convertOL2JSTS_coord(olCoordinate) {

    var jstsCoord = new jsts.geom.Coordinate(olCoordinate[0], olCoordinate[1]);
    return jstsCoord;
  }

  public static isNumberEqual(number1, number2) {
    if (Math.abs(number1 - number2) < GeometryService.tolerance) {
      return true;
    }

    return false;
  }

  public static getSegmentStartCoord(linearRing: any, segmentIndex: any) {
    var coordArray = linearRing.getCoordinates();
    if (segmentIndex < 0 || segmentIndex >= coordArray.length) {
      return null;
    }

    var olSegmentStartCoord = coordArray[segmentIndex];
    return olSegmentStartCoord;
  }

  public static getSegmentEndCoord(linearRing: any, segmentIndex: any) {
    var coordArray = linearRing.getCoordinates();
    if (segmentIndex < 0 || segmentIndex >= coordArray.length) {
      return null;
    }

    var olSegmentEndCoord = coordArray[segmentIndex + 1];
    return olSegmentEndCoord;
  }

  public static convertOL2JSTS_segment(olSegmentStartCoord, olSegmentEndCoord) {
    var jstsCoord1 = GeometryService.convertOL2JSTS_coord(olSegmentStartCoord);
    var jstsCoord2 = GeometryService.convertOL2JSTS_coord(olSegmentEndCoord);

    var jstsSegment = new jsts.geom.LineSegment(jstsCoord1, jstsCoord2);
    return jstsSegment;
  }

  public static getAngleInRadians(olSegmentStartCoord, olSegmentEndCoord) 
  {
    var jstsSegment = GeometryService.convertOL2JSTS_segment(olSegmentStartCoord, olSegmentEndCoord);
    var angleInRadians = jstsSegment.angle();

    return angleInRadians;
  }

  public static getGeometryBuffer(geometry: any, buffer: number) : any
  {

    let format = new GeoJSON();
    
    let jsonGeom = format.writeGeometry(geometry);

    const jstsJSONreader = new jsts.io.GeoJSONReader();
    const jstsJSONwriter = new jsts.io.GeoJSONWriter();


    let jstsGeom = jstsJSONreader.read(jsonGeom);

    const parser = new jsts.io.OL3Parser();

    const buffered = jstsGeom.buffer(buffer);

    let outGeomJSON = jstsJSONwriter.write(buffered);

    let outGeom = format.readGeometry(outGeomJSON);

    return outGeom;

  }

  public static getPolygonSegments(polygon: Polygon) 
  {
    let segments: LineString[] =[];
    if(polygon.getLinearRingCount()>0)
    {
      let linearRing: LinearRing = polygon.getLinearRing(0);

      let coordinates = linearRing.getCoordinates();

      for (let i = 0; i < coordinates.length - 1; i++) 
      {
        var segmentStartCoord = coordinates[i];
        var segmentEndCoord = coordinates[i + 1];

        let segment : LineString= new LineString([segmentStartCoord,segmentEndCoord]);

        segments.push(segment);
      }
      
    }
    return segments;
  }

  public static getParallelLine(lineString: LineString, distance: number)
  {
    var coords = [];
    lineString.forEachSegment(function(from, to) {
      var angle = Math.atan2(to[1] - from[1], to[0] - from[0]);

      var newFrom = [
          Math.sin(angle) * distance + from[0],
          -Math.cos(angle) * distance + from[1]
      ];
      var newTo = [
          Math.sin(angle) * distance + to[0],
          -Math.cos(angle) * distance + to[1]
      ];
      coords.push(newFrom);
      coords.push(newTo);
    });
    return new LineString(coords);
  }


  public static isEqualSegment(lineString1: LineString,lineString2: LineString) : boolean
  {
    if(lineString1.getFirstCoordinate()[0]==lineString2.getFirstCoordinate()[0]
      &&lineString1.getFirstCoordinate()[1]==lineString2.getFirstCoordinate()[1]
      &&lineString1.getLastCoordinate()[0]==lineString2.getLastCoordinate()[0]
      &&lineString1.getLastCoordinate()[1]==lineString2.getLastCoordinate()[1])
      {
        return true;
      }
      return false;
  }

  public static getGeometryEditionSegments(geometry: SimpleGeometry, viewRotation: number) : EditionSegment[]
  {
    
    let segments : EditionSegment[] = []
    if(geometry.getCoordinates().length>0)
    {
      let coordinates=geometry.getCoordinates()[0];
      for (let i = 1; i < coordinates.length; i++) {

        var index = i;
        var length = 0;
        var angleInDegrees = 0;
        
        var previousCoord1  = coordinates[i-1];
        var currentCoord  = coordinates[i];
        
        var currentSegment = new LineString( [previousCoord1, currentCoord] );
        var currentAngleInRadians = this.getAngleInRadians(previousCoord1, currentCoord) - viewRotation;
        var currentAngleInDegrees = currentAngleInRadians * this.CRD;
        
        length = currentSegment.getLength();			
        length = Math.round(length * 100) / 100;
        
        currentAngleInDegrees = Math.round(currentAngleInDegrees * 100) / 100;
        if(currentAngleInDegrees < 0)
        {
          currentAngleInDegrees += 360;
        }
        
        var segmentLength = length;
        var angle = currentAngleInDegrees;
  
        segments.push(new EditionSegment(i,segmentLength,angle, previousCoord1, currentCoord));
        
      }
  
    }
    return segments;
  }

  //adjusts the line segment length to the given newLength.
  public static adjustLineLength(olSegmentStartCoord, olSegmentEndCoord, newLength)
  {
    
    var jstsSegment = GeometryService.convertOL2JSTS_segment(olSegmentStartCoord, olSegmentEndCoord);
    
    var currentLength = jstsSegment.getLength();
    var newLengthFraction = newLength / currentLength;
    
    //0 represents the initial coordinate, 1 represents the last coordinate
    var jstsCoordinate = jstsSegment.pointAlong(newLengthFraction)
    var olCoordinate = GeometryService.convertJSTS2OL_xy( jstsCoordinate.x, jstsCoordinate.y );

    return olCoordinate;
  }

  public static convertJSTS2OL_xy(x, y)
  {
     
    var olCoordinate = [x, y];
    return olCoordinate;
  }

  public static convertOLGeom2JSTSGeom(olGeom: Geometry)
  {
    let format = new GeoJSON();
    
    let jsonGeom = format.writeGeometry(olGeom);

    const jstsJSONreader = new jsts.io.GeoJSONReader();

    let jstsGeom = jstsJSONreader.read(jsonGeom);

    return jstsGeom;
  }

  public static convertPolygonToMultiPolygon(polygon: any)
  {
    let multiPolygon = new MultiPolygon([polygon]);

    return multiPolygon;
  }

  public static convertPointToMultiPoint(point: any)
  {
    let multiPoint = new MultiPoint([point]);

    return multiPoint;
  }



  public static pointToMultiPoint(feature: any) : any
  {
    if(feature.geometry)
    {
      feature.geometry.type="MultiPoint"
      feature.geometry.coordinates = [feature.geometry.coordinates]
    }
    return feature;
  } 

}