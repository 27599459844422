<form id="pdfprinting-tool" [formGroup]="pdfPrintingControl" (ngSubmit)="onPrint()">
            <!-- start form -->
    <table cellspacing="0">
        <tr>
            <td colspan="3">
                <mat-list>
                    <mat-list-item><mat-icon matListIcon>navigate_next</mat-icon><h3 matLine> Configuração padrão: folha A4 e orientação paisagem; </h3></mat-list-item>
                    <mat-list-item><mat-icon matListIcon>navigate_next</mat-icon><h3 matLine> A impressão utiliza a função nativa do navegador e depende da resolução de tela de cada usuário; </h3></mat-list-item>
                    <mat-list-item><mat-icon matListIcon>navigate_next</mat-icon><h3 matLine> Caso o alvo no mapa não esteja bem posicionado no documento impresso, arraste o mapa para a esquerda e para cima (conforme as setas vermelhas), e teste a impressão até que o alvo fique na posição desejada; </h3></mat-list-item>
                    <mat-list-item><mat-icon matListIcon>navigate_next</mat-icon><h3 matLine> Os textos usados como título e rodapé no mapa impresso podem ser alterados usando os campos abaixo; </h3></mat-list-item>
                </mat-list>
            </td>
        </tr>
        <tr>
            <td colspan="3">
                &nbsp;
            </td>
        </tr>
        <tr>
            <td>
                &nbsp;
            </td>
            <td style="text-align: center;width: 380px;">
                <mat-form-field>
                    <input matInput
                    placeholder="Título:"
                    name="pdfTitle"
                    formControlName="pdfTitle"
                    value="" (change)="onTitleChange()">
                </mat-form-field>
                <img alt="Modelo de impressão" src="assets/img/print_model.png" />
                <mat-form-field>
                    <input matInput
                    placeholder="Texto de rodapé:"
                    name="pdfFooter"
                    formControlName="pdfFooter"
                    value="" (change)="onFooterChange()">
                </mat-form-field>
            </td>
            <td>
                &nbsp;
            </td>
        </tr>
        <tr>
            <td colspan="3" class="control-buttons">
                <button type="submit" mat-raised-button color="primary">
                    <span class="material-icons">print</span> Imprimir
                </button>
            </td>
        </tr>
    </table>
    <!-- end form -->
</form>