import { FormControl, FormGroup } from "@angular/forms";
import { Importlayercheckinforesult } from "@funcate/sigweb-cti-api";
import { LayerConfig } from "src/app/map/layers/layers.config";
import { LayerTool } from "src/app/map/vo/layertool";
import { BackendService } from "src/app/service/backend.service";
import { ToastService } from "src/app/service/toast.service";
import { Step } from "../../../../vo/step";
import { ToolsService } from "../../../tools.service";
import { ImportLayerComponent } from "../import-layer.component";

export class ImportLayerToolsInfoStep extends Step
 {
    public allTools: LayerTool[];

    constructor(index: number, private importLayerComponent: ImportLayerComponent, private toastService: ToastService, private toolsService: ToolsService)
    {       
        super(index);        
        this.setTitle("Passo 5: Informações de Conexão WMS da camada.");
        this.setDescription("Verifique as informações de conexão WMS da camada.");         
    }
    execute()
    {      
      this.loadTools();
    }
    callback(object: any)
    {

    }
    rollback()
    {
    }
    finish()
    {
        
    }
    valid()
    {
      return true;
    } 
    onToolSelection(event) 
    {
  
      let selectedTool = event.option.value;
      selectedTool.enabled = event.option.selected;
      let layerTools = this.importLayerComponent.newLayerConfig.getLayertools();
      if(selectedTool.enabled==true)
      {    
        //Add tool to layer config  
        layerTools.push({ toolid: selectedTool.toolId});
      }
      else
      {
  
        let newLayerTool = [];
        //Remove tool to layer config
        layerTools.forEach((layerTool, index) => 
        {
          if(layerTool.toolid!=selectedTool.toolId)
          {          
            newLayerTool.push(layerTool);
          }
        });
        this.importLayerComponent.newLayerConfig.setLayertools(newLayerTool);
      }
      
      
    }
  loadTools()
  {
    this.allTools=[];

    let tools = this.toolsService.getTools();
    let layerTools = this.importLayerComponent.newLayerConfig.getLayertools();

    tools.forEach(tool => {
      if(tool.layerTool)
      {
        let enabled = false;
        layerTools.forEach(layerTool => 
        {          
          if(layerTool.toolid==tool.id)
          {
            enabled = true;    
          }           
        });        
        this.allTools.push(new LayerTool(tool.id.toString(), tool.title, enabled, this.importLayerComponent.newLayerConfig));       
      }
    });

    this.allTools.sort((a,b)=>{
      if (a.toolTitle < b.toolTitle) {
        return -1;
      }
      if (a.toolTitle > b.toolTitle) {
          return 1;
      }
    })

  }
}

