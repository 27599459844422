import {ToolsService} from '../../tools.service';
import { LayersInfo } from '../../../vo/layersinfo';
import { MatDialog } from '@angular/material/dialog';
//import { FetureInfoToolComponent } from '../fetureinfo-tool.component';
import { DialogComponent } from '../../../dialog/dialog.component';
import { Tool } from '../../tool';
import { AddColetaPGVToolComponent } from './add-coleta-pgv-tool.component';
import { Feature } from 'src/app/map/vo/feature';
import { AbstractLayerTool } from '../../abstract-layer-tool';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';

export class AddColetaPgvTool extends Tool {

    private dialog: MatDialog;

    cursorType='tool-cursor-pointer';
    id='add-coleta-pgv-tool';
    enabled=false;
    name='Add Coleta PGV Tool';
    title='Adicionar coleta PGV por apontamento';
    type='click';
    map:L.Map=null;
    ll: any; 
    protected visible:boolean=true;
    icon='push_pin';
    layerTool: boolean=false;
    customParameter=new Map<String, any>();
    private selectedFeature: Feature;
    private dialogRef: any;

    constructor(toolService: ToolsService, private canvasService: CanvasService, private layerConfigService: LayersConfigService, dialog: MatDialog) 
    { 
        super(toolService)
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{
        this.map=event.sourceTarget;
        this.ll=event.latlng;
        
        this.dialogRef = this.dialog.open(DialogComponent, {
            height: '70%',
            width: '40%',
            data: { component: AddColetaPGVToolComponent, title: "Inserção de dados de coleta PGV", position: this.ll}            
        });

        let whenDialogIsOpen=this.dialogRef.afterOpened().toPromise();
        whenDialogIsOpen.then(
            ()=>{
                this.toolsService.dispatchTool(this);
            }
        );

        this.dialogRef.afterClosed().toPromise().then(()=>{
            let layer : LayerConfig = this.layerConfigService.getColetaPGVLayer();

            this.canvasService.redrawLayer(layer);
        });


    };

    public enableImpl()
    {
        let layer : LayerConfig = this.layerConfigService.getColetaPGVLayer();
        if(layer.isEnabled()==false)
        {
            this.layerConfigService.enableLayer(layer);
        }
    }
    public disableImpl()
    {
    }

    public closeDialog()
    {
        this.dialogRef.close();
    }
    public setFeature(feature: Feature)
    {
        this.selectedFeature = feature;
    }

    public isEnabled(layer :LayerConfig) : boolean
    {
        let currentLayerTool = this.layerConfigService.getColetaPGVLayer();

        if(this.enabled && currentLayerTool.getId()==layer.getId() )
        {
            return true;
        }
        else
        {
            return false;
        }
    }
}
