import {ToolsService} from '../tools.service';
import { LayersInfo } from '../../vo/layersinfo';
import { MatDialog } from '@angular/material/dialog';
import { Photo360LayerToolComponent } from './photo360-layer-tool.component';
import { DialogComponent } from '../../dialog/dialog.component';
import { Tool } from '../tool';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { LayerConfig } from '../../layers/layers.config';
import { LayersConfigService } from '../../layers/layers.config.service';

export class Photo360LayerTool extends Tool {

    private dialog: MatDialog;

    cursorType='tool-cursor-360';
    id='photo360-layer-tool';
    enabled=false;
    name='Photo 360';
    title='Consultar foto 360° por apontamento';
    type='click';
    map:L.Map=null;
    ll: any; 
    protected visible:boolean=true;
    icon='photo_camera';
    layerTool: boolean=false;
    customParameter=new Map<String, any>();

    constructor(toolsService: ToolsService,  private layerConfigService: LayersConfigService, dialog: MatDialog) 
    { 
        super(toolsService)
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{
        this.map=event.sourceTarget;
        this.ll=event.latlng;

        let dialogRef = this.dialog.open(DialogComponent, {
            height: '90%',
            width: '95%',
            data: { component: Photo360LayerToolComponent, title: "Foto 360°" }            
        });

        let whenDialogIsOpen=dialogRef.afterOpened().toPromise();
        whenDialogIsOpen.then(
            ()=>{
                this.toolsService.dispatchTool(this);
            }
        );
    };

    public enableImpl()
    {
        let layerConfig = this.layerConfigService.getPhoto360Layer();
        if(layerConfig)
        {
            this.layerConfigService.enableLayer(layerConfig);
        }   
    }
    public disableImpl()
    {
    }
    
}
