import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { BackendService } from 'src/app/service/backend.service';
import { Certidoescamadasconfig } from '@funcate/sigweb-cti-api';
import { ToastService } from 'src/app/service/toast.service';
import { Subscription } from 'rxjs';
import { ToolsService } from '../../tools.service';

@Component({
  selector: 'app-layers-cruzamento-config',
  templateUrl: './layers-cruzamento-config.component.html',
  styleUrls: ['./layers-cruzamento-config.component.css']
})
export class LayersCruzamentoConfigComponent implements OnInit {

  availableLayers: LayerConfig[] = [];
  selectedLayers: LayerConfig[] = [];
  tool:any;
  subscriptionTool:Subscription;

  constructor(private layerService: LayersConfigService, private backendService: BackendService, private toastService: ToastService, private toolsService: ToolsService) {

    this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (tool:any) => {
        this.tool=tool;
      }
    );
   }

  ngOnInit(): void 
  {    
    this.publishLayers();
  }

  publishLayers()
  {

    this.backendService.getCertidoesLayersOrder().then((certidoescamadasconfig: Certidoescamadasconfig)=>
    {
      this.availableLayers = []    

      this.layerService.getOverlayers().forEach((layer: LayerConfig)=>
      {
        if(this.layerService.isVectorLayer(layer))
        {
          let found = false;
         for (let i = 0; i < certidoescamadasconfig.length; i++) 
         {
           const camadaConfig = certidoescamadasconfig[i];
           if(layer.getId()==camadaConfig.layer_id)
           {
            found = true;
            layer['position']=camadaConfig.layer_position
            break;  
           }

          
         }
         if(found)
         {
           
           this.selectedLayers.push(layer);
         }
         else
         {
          this.availableLayers.push(layer);
         }
        }        
      });


      this.sortSelectedLayersByPosition();
    });  
    
  }

  private sortSelectedLayersByPosition()
  {
    var sortedArray: LayerConfig[] = this.selectedLayers.sort((obj1, obj2) => {
      if (obj1['position'] > obj2['position']) {
          return 1;
      }
  
      if (obj1['position'] < obj2['position']) {
          return -1;
      }
  
      return 0;
  });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  save()
  {
    let certidoescamadasconfig: Certidoescamadasconfig = [];

    this.selectedLayers.forEach((selectedLayer, index) => {
      certidoescamadasconfig.push({layer_id: selectedLayer.getId(), layer_position: index+1, selected: true })      
    });
    

    this.backendService.setCertidoesLayersOrder(certidoescamadasconfig).then(()=>
    {
      this.toastService.sucess("Configuração de camadas para cruzamento de certidões salvo com sucesso", "Sucesso");
      this.tool.closeDialog();
    });
  }

}
