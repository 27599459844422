<form class="quicksearch-form">
  <div class="row">
    <div class="col-md-3">
      <mat-button-toggle-group name="searchType" aria-label="Tipo de Pesquisa" id="searchType" [(ngModel)]="isInscricaoEnabled">
        <mat-button-toggle [value]="false" id="endereco">Endereço</mat-button-toggle>
        <mat-button-toggle [value]="true" id="inscricao">Inscrição</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="col-md-9">
      <div class="row" id="enderecoForm" *ngIf="isInscricaoEnabled==false">
        <div class="col-md-9">
          <mat-form-field >
              
            <input type="text"
                  placeholder="Pesquisa de Endereço"
                  aria-label="Endereço"
                  matInput
                  [formControl]="enderecosControl"
                  [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="enderecoSelected($event.option.value)">
              <mat-option *ngFor="let option of enderecosFilteredOptions | async" [value]="option">
                {{option}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
      </div>
      <div  class="col-md-3">
        <mat-form-field >
          <input type="text"
                placeholder="Número"
                aria-label="Número"
                matInput
                [formControl]="numerosControl"
                [matAutocomplete]="auto2"
                >
            <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="numeroSelected($event.option.value)">
            <mat-option *ngFor="let option of numerosFilteredOptions | async" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="row" id="inscricaoForm" *ngIf="isInscricaoEnabled==true">
      <form [formGroup]="inscricaoControl" (ngSubmit)="submitInscricao()">
      <div class="row">
        <div class="col-md-2 campoInscricao"> 
          <mat-form-field>
            <input matInput placeholder="Setor" name="setor" value="" id="quicksearch-setor" formControlName="setor" (paste)="onPaste($event)" (blur)="validateLength('setor', $event)">
          </mat-form-field>
        </div>
        <div class="col-md-2 campoInscricao">
          <mat-form-field>
            <input matInput placeholder="Quadra" name="quadra" id="quicksearch-quadra" value="" formControlName="quadra" (paste)="onPaste($event)"  (blur)="validateLength('quadra', $event)">
          </mat-form-field>
        </div>
        <div class="col-md-2 campoInscricao">
          <mat-form-field>
           <input matInput placeholder="Logradouro" name="logradouro" id="quicksearch-logradouro" value="" formControlName="logradouro" (paste)="onPaste($event)"  (blur)="validateLength('logradouro', $event)">
          </mat-form-field>
        </div>
        <div class="col-md-2 campoInscricao">
          <mat-form-field>
            <input matInput placeholder="Lote" name="lote" value="" id="quicksearch-lote" formControlName="lote" (paste)="onPaste($event)"  (blur)="validateLength('lote', $event)">
          </mat-form-field>
        </div>
        <div class="col-md-2 campoInscricao">
          <mat-form-field>
            <input matInput placeholder="Sublote" name="sublote" value="" id="quicksearch-sublote" formControlName="sublote" (paste)="onPaste($event)" (blur)="validateLength('sublote', $event)">
          </mat-form-field>
        </div>
        <div class="col-md-1">
          <button mat-raised-button class="inscricaoButton"><span class="material-icons">
            search
            </span></button>
        </div>
        
      </div>
    </form>
    </div>
    </div>
 
  </div>
  
    
</form>