import { Tool } from '../tool';
import { Subscription } from 'rxjs';
import { EditionOperation } from '../../vo/edition-operation';
import { NotificationService } from '../../../service/notification.service';
import { EditionService } from '../../../service/edition.service';
import { CanvasService } from '../../canvas/canvas.service';
import { AppNotification } from '../../vo/notification';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { LayerConfig } from '../../layers/layers.config';
import { AbstractEditionTool } from '../edit-layer-tool/abstract-edition-tool';
import { Operation } from '../../vo/operation';
import { ToolsService } from '../tools.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { AddGeomByCoordsEditionLayerToolComponent } from './add-geom-by-coords-edition-layer-tool.component';
import { EditLayerTool } from '../edit-layer-tool/edit-layer-tool';
import { GeomType } from '../../vo/geomtype';
import { LayersConfigService } from '../../layers/layers.config.service';
import { ToastService } from 'src/app/service/toast.service';

export class AddGeomByCoordsEditionLayerTool extends AbstractEditionTool 
{

    cursorType=null;
    id='add-geom-by-coords-edition-layer-tool';
    enabled=false;
    name='Add Geometry By Coords Edition Layer Tool';
    title='Add Geom By Coords Edition';
    type='click';
    map:L.Map=null;
    icon='save';
    ll: any;
    protected visible:boolean=true;
    layerTool: boolean=true;
    customParameter=new Map<String, any>();
    notificationSubscrition: Subscription;
    operations: Map<String,EditionOperation> = new Map<String,EditionOperation>();
    currentEditionLayer: LayerConfig;
    private dialog: MatDialog;
    private dialogRef: any;
    editLayerTool: EditLayerTool;

    constructor(editLayerTool: EditLayerTool, dialog: MatDialog, toolsService: ToolsService, private notificationService: NotificationService, private canvasService: CanvasService, private layerConfigService: LayersConfigService, private toastService: ToastService) 
    { 
        super(toolsService)
        this.dialog = dialog;
        this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
            if (notification.event==AppNotification.ON_EDITION_OPERATIONS_CHANGE_EVENT) 
            {
                this.operations=notification.object;
                if(this.operations.size==0)
                {
                    this.visible = false;
                }
                else
                {
                    this.visible = true;
                }
            }
          });
          this.editLayerTool=editLayerTool;
    }

    public execAction=(event:L.LeafletMouseEvent)=>
    {
    };

    public enableImpl()
    {
      let geomtype = this.layerConfigService.getLayerGeomType(this.canvasService.getCurrentEditionLayer());
      if(geomtype==GeomType.Polygon)
      {
        this.dialogRef = this.dialog.open(DialogComponent, {
          height: '80%',
          maxWidth: '100%',
          maxHeight: '100%',
          data: { component: AddGeomByCoordsEditionLayerToolComponent, title: "Adicionar geometrias a partir de coordenadas", editLayerTool: this.editLayerTool }
        });
    
        let whenDialogIsOpen=this.dialogRef.afterOpened().toPromise();
        whenDialogIsOpen.then(
            ()=>{
                this.toolsService.dispatchTool(this);
            }
        );
      }
      else
      {
        this.toastService.warning("Não é possivel inserir por coordenadas para esse tipo de camada.", "Aviso");
      }
      
    }

    public disableImpl()
    {
    }

    public isVisibleForLayer(layer :LayerConfig) : boolean
    {
        if(this.canvasService.getCurrentEditionLayer()==layer)
        {
            return true;
        }
        else
        {
            return false;
        }
    }
    public removeToolControl()
    {
      
    }
  
    public addToolControl() 
    {
      let geomtype = this.layerConfigService.getLayerGeomType(this.canvasService.getCurrentEditionLayer());
      if(geomtype==GeomType.Polygon)
      {
        let button = this.canvasService.getMap().pm["Toolbar"].getButtons()[this.id];
      
        if(!button)
        {
          const actions = [
            {
              text: 'Adicionar geometria por coordenadas', id:'add-geom-by-coords-action' ,onClick: () => {
                let notification = new AppNotification(AppNotification.ENABLE_LAYER_EDITION_TOOL_CONTROL_EVENT, this);
                this.notificationService.send(notification);
              }
            }];
          let options = {
            name: this.id,
            title: 'Adicionar geometria por coordenadas',
            className: 'add-geom-by-coords-edition-leaflet-control',
            actions: actions
          };
          this.canvasService.getMap().pm["Toolbar"].createCustomControl(
            options
          );
        }
      }     
    }
    public toolbarButtonClicked(event: any) 
    {
      if(event.btnName && event.btnName==this.id)
      {
        this.enable();
      }
      else
      {
        if(this.enabled)
        {
          this.disable();
        }
      }
    }
    public toolbarActionClicked(event: any) 
    {
      if(event.btnName && event.btnName==this.id)
      {
        if(event.action && event.action.id=="add-geom-by-coords-action")
        {
          this.enable();  
        }
        else
        {
          if(this.enabled)
          {
            this.disable();
          }
        }
        
      }
      else
      {
        if(this.enabled)
        {
          this.disable();
        }
        
      }
    }
    public isEnabled(layer :LayerConfig) : boolean
    {
        return this.enabled;
    }
    public closeDialog()
    {
        if(this.dialogRef)
        {
            this.dialogRef.close();
            this.disable();
        }
    }  
}

