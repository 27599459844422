import { Component, Inject, OnInit } from '@angular/core';
import { LayersConfigService } from '../../layers/layers.config.service';
import { LayerConfig } from '../../layers/layers.config'
import { Observable, Subscription } from 'rxjs';
import { ToolsService } from '../tools.service';
import { BackendService } from '../../../service/backend.service';
import { FormControl, FormGroup } from '@angular/forms';
import { AppNotification } from '../../vo/notification';
import { Logradouros, LogradourosInner } from '@funcate/sigweb-cti-api';
import { map, startWith } from 'rxjs/operators';
import { Property } from '../../vo/property';
import { CanvasService } from '../../canvas/canvas.service';
import { FeatureService } from 'src/app/service/feature.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { EditStreetAttributesComponent } from './edit-attribute-street/edit-street-attributes.component';
import { EditAttributeLayerTool } from '../edit-attribute-layer-tool/edit-attribute-layer-tool';


@Component({
  selector: 'app-street-search-tool',
  templateUrl: './street-search-tool.component.html',
  styleUrls: ['./street-search-tool.component.css']
})
export class StreetSearchToolComponent implements OnInit {

  streetSearchTool:any;
  logradourosControl: FormControl = new FormControl();
  logradourosOptions: string[] = [];
  logradourosFilteredOptions: Observable<string[]>;
  subscriptionTool:Subscription;
  fitting: boolean = false;
  selectedStreetName: string = null;
  selectedStreetId: string = null;
  selected: boolean = false;
  private dialog: MatDialog;
  private editStreetDialog: MatDialogRef<DialogComponent, any>;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private toolsService: ToolsService, private backendService: BackendService, 
    private layerConfigService: LayersConfigService, private canvasService: CanvasService, private featureService: FeatureService) 
  { 
    this.dialog = data.dialog;
    this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (streetSearchTool:any) => {
        this.streetSearchTool=streetSearchTool;
        this.update();
      }
    );
  }

  ngOnInit(): void 
  {
  }
  private update()
  {
    if(this.streetSearchTool)
    {
      this.logradourosControl.setValue(null);
      this.selected=false;
      this.selectedStreetId=null;
      this.selectedStreetName=null;
      this.backendService.getLogradouros().then((logradouros: Logradouros)=>
      {

        this.logradourosOptions = [];
        logradouros.forEach(logradouro => {
          if(logradouro.logradouro)
          {
            this.logradourosOptions.push(logradouro.id + " - " + logradouro.logradouro);
          }
         });
    
          this.logradourosFilteredOptions = this.logradourosControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.filterLogradouros(value))
          );

      })
    }
  }
  private filterLogradouros(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.logradourosOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  public logradouroSelected(logradouroSelecionado: string)
  {
    if(logradouroSelecionado)
    {
      let parts = logradouroSelecionado.split(" - ");
      if(parts.length>1)
      {
        this.selectedStreetId = parts[0];
        this.selectedStreetName = parts[1];
        this.selected = true;

        return;
      }
    }
    this.selected = false;
  }

  public fitStreet()
  {
    if(this.streetSearchTool)
    {
      let streetLayer = this.toolsService.getToolParameter(this.streetSearchTool, "layer");

      if(streetLayer)
      {
        let attributesFilterList: Array<Array<Property>>=[];
        
        let attributeFilter: Array<Property> = this.getStreetFilter(streetLayer, this.selectedStreetId, this.selectedStreetName);

        attributesFilterList.push(attributeFilter);
  
        this.featureService.getFeatures(streetLayer, "", attributesFilterList).then(features => 
        {
          this.canvasService.fitFeatures(features);    
        });
  
      }
    }
    
  }

  private getStreetFilter(layer: LayerConfig, streetId: string, streetName: string) : Array<Property>
  {
    let attributeFilterList: Array<Property> = [];

    let streetIdAttributeName = this.layerConfigService.getLayerAppConfig(layer, "codigo_logradouro_attribute");
    let streetNameAttributeName = this.layerConfigService.getLayerAppConfig(layer, "nome_logradouro_attribute");   
    
    attributeFilterList.push(new Property(streetIdAttributeName, streetId));
    attributeFilterList.push(new Property(streetNameAttributeName, streetName));

    return attributeFilterList;

  }

  public editStreet()
  {
    if(this.hasEditionPermission())
    {
      let streetLayer = this.toolsService.getToolParameter(this.streetSearchTool, "layer");
      let streetIdAttributeName = this.layerConfigService.getLayerAppConfig(streetLayer, "codigo_logradouro_attribute");
  
      this.editStreetDialog = this.dialog.open(DialogComponent, {
        height: '80%',
        width: '40%',
        data: { component: EditStreetAttributesComponent, title: "Edição de Atributos do Logradouro: " + this.selectedStreetId + " - " + this.selectedStreetName, codLogradouro: this.selectedStreetId, layer: streetLayer, parent: this }
        });
    }  

  }

  public hasEditionPermission() : boolean
  {
    let genericAttributeEditionTool = this.toolsService.getLayerToolById('edit-attribute-layer-tool');

    return genericAttributeEditionTool.isVisible();    
  }

  closeEditStreet()
  {
    if(this.editStreetDialog)
    {
    
      this.editStreetDialog.close();
      this.editStreetDialog = null;
      this.update();
      this.redraw();

    }
  }

  private redraw()
  {
    let streetLayer = this.toolsService.getToolParameter(this.streetSearchTool, "layer");

    if(streetLayer)
    {
      this.canvasService.redrawLayer(streetLayer);
    }    
  }



}
