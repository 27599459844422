import {ToolsService} from '../tools.service';
import GeoJSON from 'ol/format/GeoJSON';

import { LayersInfo } from '../../vo/layersinfo';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
//import { FetureInfoToolComponent } from '../fetureinfo-tool.component';
import { DialogComponent } from '../../dialog/dialog.component';
import { Tool } from '../tool';
import { Feature } from 'src/app/map/vo/feature';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';

import { PropertysearchToolComponent } from '../propertysearch-tool/propertysearch-tool.component';
import { Documentos, EditionoperationsInner, Featureattributes, Historicoprocessoimovel, HistoricoprocessoimovelInner, Imoveisrelations } from '@funcate/sigweb-cti-api';
import { EditHistoricoComponent } from '../property-registration/edit-historico/edit-historico.component';
import { FeatureService } from 'src/app/service/feature.service';
import Point from 'ol/geom/Point';
import { Property } from '../../vo/property';
import { BackendService } from 'src/app/service/backend.service';
import { DocumentService } from 'src/app/service/document.service';
import { ToastService } from 'src/app/service/toast.service';
import { Imovel } from '../../vo/imovel';
import { Inscricao } from '../../vo/inscricao';
import MultiPoint from 'ol/geom/MultiPoint';
import { GeometryService } from 'src/app/service/geometry.service';
import { GenericConfirmationDialogComponent } from '../../dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';
//import { SettlementComponent } from './settlement.component';
import { EditionService } from 'src/app/service/edition.service';
import { GenericFeatureReport } from './reports/generic-feature-report';
import { SettlementFeatureReport } from './reports/settlement-feature-report';
import { SettlementPropertyFeatureReport } from './reports/settlement-property-feature-report';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { GeneralService } from 'src/app/service/general.service';
import { CertidoesService } from 'src/app/service/certidoes.service';
import { GeneratingReportComponent } from './reports/generating-report/generating-report.component';
import { NotificationService } from 'src/app/service/notification.service';

export class GenericFeatureReportTool extends AbstractLayerTool {

    private dialog: MatDialog;

    dialogRef: any;

    cursorType='tool-cursor-pointer';
    id='generic-feature-report-tool';
    enabled=false;
    name='Generic Feature Report Tool';
    title='Gerador de Relatório de Feição';
    type='click';
    map:L.Map=null;
    ll: any; 
    protected visible:boolean=true;
    icon='summarize';
    layerTool: boolean=true;
    customParameter=new Map<String, any>();        
    currentFeature: any;
    layer: LayerConfig;
    loadingFeature: boolean;

    reports: any[];

    editSettlementDialogRef: MatDialogRef<DialogComponent>;

    constructor(toolService: ToolsService, private layerConfigService: LayersConfigService, 
        private toastService: ToastService, private canvasService: CanvasService, private featureService: FeatureService, 
        private editionService: EditionService, private authenticationService: AuthenticationService, private generalService: GeneralService, 
        private certidoesService: CertidoesService, private notificationService: NotificationService, dialog: MatDialog) 
    { 
        super(toolService)
        this.dialog = dialog;
        this.loadingFeature=false;
        this.initCustomReportsImplementations();
    }

    private initCustomReportsImplementations()
    {
      this.reports=[];
      //this.reports.push(new GenericFeatureReport());
      this.reports.push(new SettlementFeatureReport(this.toastService, this.authenticationService, this.generalService, this.layerConfigService, this.certidoesService, this.featureService, this.notificationService));
      this.reports.push(new SettlementPropertyFeatureReport(this.toastService, this.authenticationService, this.generalService, this.layerConfigService, this.certidoesService, this.featureService, this.notificationService));

    }

    public execAction=(event:L.LeafletMouseEvent)=>{
        this.map=event.sourceTarget;
        this.ll=event.latlng;

        if(this.loadingFeature == false)
        {
            this.toastService.sucess("Localizando feição!", "Carregando...");
            this.loadFeature();
        }
        else
        {
            this.toastService.warning("Buscando feição apontada, por favor aguarde!", "Carregando...");
        }   
    };    

    public enableImpl()
    {
        let currentLayerTool = this.toolsService.getToolParameter(this, "layer");
        if(currentLayerTool)
        {
            this.layerConfigService.enableLayer(currentLayerTool);
        }                  
        
    }
    public disableImpl()
    {
    }

    public isVisibleForLayer(layer :LayerConfig) : boolean
    {
        return this.layerTool;
    }



    public isEnabled(layer :LayerConfig) : boolean
    {
      let currentLayerTool = this.toolsService.getToolParameter(this, "layer");

        if(this.enabled && currentLayerTool.getId()==layer.getId() )
        {
            return true;
        }
        else
        {
            return false;
        }
    }
        
    private loadFeature() 
    {

      this.showGeneration();

      this.layer = this.toolsService.getToolParameter(this, "layer");

      if (this.layerConfigService.isVectorLayer(this.layer))
      {
        let idAttribute=this.layerConfigService.getLayerIdAttribute(this.layer);
        //this.tool.setFeature(null);
        this.canvasService.getMap().eachLayer((l) => {
          if (l["wmsParams"])
          {

            if (l["id"] == this.layer.getId())
            {
              this.featureService.getFeatureInfo(this.ll, l, this.canvasService.getMap()).then(featureInfo => {

                if (featureInfo && featureInfo.features.length>0)
                {

                    let feature = featureInfo.features[0];        

                    let srs: string = featureInfo.features[0].srs;                    

                    let idValueProperty = this.featureService.getPropertyByKey(feature.properties, idAttribute);                   
                    
                    if(idValueProperty)
                    {
                    
                      let attributesFilterList: Array<Array<Property>>=[];
                      let attributeFilterList: Array<Property>=[];

                      attributeFilterList.push(idValueProperty);
                      attributesFilterList.push(attributeFilterList);
                    
                     this.featureService.getFeatures(this.layer, srs, attributesFilterList).then(features => 
                     {
                        if(features && features.length>0)
                        {                          
                          this.toastService.sucess("Feição encontrada", "Sucesso");
                          
                          this.generateReport(features);
                            
                        } else
                        {
                          this.toastService.error("Nenhuma feição encontrada.", "Erro");
                        }
                      });                 

                    }

                }
                else
                {
                  this.toastService.error("Nenhuma feição encontrada.", "Erro");
                }
              });

            }

          }
        });
      }

    

  }

  public generateReport(features)
  {
    this.showGeneration();
    this.currentFeature=features[0];
  
    let customReportId=this.layerConfigService.getLayerAppConfig(this.layer, "custom-report-id");

    let customReport = null;
    this.reports.forEach(report => 
    {
      if(report.customFeatureReportId==customReportId)                            
      {
        customReport = report;
      }
    });
    
    if(customReport)
    {      
      customReport.generateReport(this.currentFeature, this.layer, this);
    }
    else
    {
      let genericReport = new GenericFeatureReport(this.toastService, this.authenticationService, this.generalService, this.layerConfigService, this.certidoesService, this.featureService, this.notificationService);
      genericReport.generateReport(this.currentFeature, this.layer, this);
                            }
  }
  private showGeneration()
  {
    if(!this.dialogRef)
    {
      this.dialogRef = this.dialog.open(DialogComponent, {
        height: '70%',
        width: '60%',
        data: { component: GeneratingReportComponent, title: "Gerando documento", dialog: this}
    });
    }    
  }
  public hideGeneration()
  {
    this.dialogRef.close();
    this.dialogRef =null;
  }
  closeDialog()
  {
    this.editSettlementDialogRef.close();
  }
 
}
