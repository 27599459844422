import { LatLng } from 'leaflet';
import { Map } from 'ol';
import Point from 'ol/geom/Point';
import { Pointer } from 'ol/interaction'
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/src/Feature';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { Step } from 'src/app/map/vo/step';
import { FeatureService } from 'src/app/service/feature.service';
import {CaptureCoordinateInteraction} from './capture-coord-interaction'

export class GetFeatureInfoInteraction extends CaptureCoordinateInteraction
{
    constructor(id:string, private olLayer: VectorLayer<any>, private map: Map, private currentStep: any)
    {
        super(id);
        super.setup({handlePointerUp: this.handleCaptureCoordinate});

    }

    handleCaptureCoordinate(event)
    {
        console.log(event);
        if(event.coordinate_ && event.coordinate_.length==2)
        {
           
            this.loadFeature(event.coordinate_);
        }
    }

    private loadFeature(ll: any)
    {
        const viewResolution = /** @type {number} */ (this.map.getView().getResolution());
        const url = this.olLayer.getSource().getFeatureInfoUrl(
            ll,
            viewResolution,
            'EPSG:3857',
            {'INFO_FORMAT': 'application/json'}
        );
        if (url) {
            fetch(url)
            .then((response) => response.text())
            .then((features) => {
                let featuresObject = JSON.parse(features);
                this.currentStep.onGetFeatureInfo(featuresObject);
            });
        }
    }

 
}



