import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'generating-report',
  templateUrl: './generating-report.component.html',
  styleUrls: ['./generating-report.component.css']
})


export class GeneratingReportComponent implements AfterViewInit
{  
  
  
  constructor() 
  {
    
  }

  ngOnInit(): void
  {
  }
 
  ngAfterViewInit() 
  {
  
  }  

  
}
