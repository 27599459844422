import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { ToolsService } from '../tools.service';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { LayerConfig } from '../../layers/layers.config';
import { AttributeSearchToolComponent } from './attribute-search-tool.component';
import { Userinfo } from '@funcate/sigweb-cti-api';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { Property } from 'src/app/map/vo/property';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from 'src/app/map/vo/notification';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { UserGroup } from 'src/app/map/vo/user-group';
import { Tool } from '../tool';

export class AttributeSearchTool extends AbstractLayerTool {

    private dialog: MatDialog;

    cursorType=null;
    id='attribute-search-tool';
    enabled=false;
    name='Attribute Search Tool';
    title='Consultar Attributos';
    type='click';
    map:L.Map=null;
    icon='manage_search';
    ll: any;
    protected visible:boolean=true;
    layerTool: boolean=true;
    customParameter=new Map<String, any>();
    notificationSubscrition: any;
    currentLayer: LayerConfig;

    private dialogRef: any;

    constructor(dialog: MatDialog,
         toolsService: ToolsService,
           private layerService: LayersConfigService
            ) 
    { 
        super(toolsService)
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{};

    public enableImpl()
    {

        this.currentLayer = this.toolsService.getToolParameter(this, "layer");
        if(this.currentLayer.isEnabled()==false)
        {
            this.layerService.enableLayer(this.currentLayer);
        }

        this.dialogRef = this.dialog.open(DialogComponent, {
            height: '90%',
            maxHeight: '100%',
            maxWidth: '100%',
            data: { component: AttributeSearchToolComponent, title: "Pesquisa por Atributos", tool: this }
        });

        this.dialogRef.afterClosed().toPromise().then(()=>{
            this.toolsService.toogleTool(this);
        });
    }
    public disableImpl()
    {
    }

    public isVisibleForLayer(layer :LayerConfig) : boolean
    {
        return this.layerTool;
    }

    public isEnabled(layer :LayerConfig) : boolean
    {
        let currentLayerTool : LayerConfig = this.toolsService.getToolParameter(this, "layer");
        if(this.enabled && currentLayerTool.getId()==layer.getId() )
        {
            return true;
        }
        else
        {
            return false;
        }
    }
    public closeDialog()
    {
        if(this.dialogRef)
        {
            this.dialogRef.close();
        }
    }
    
}
