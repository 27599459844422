import { FormControl, FormGroup } from "@angular/forms";
import { Importlayerfileinfo } from "@funcate/sigweb-cti-api";
import { BackendService } from "src/app/service/backend.service";
import { ToastService } from "src/app/service/toast.service";
import { Step } from "../../../../vo/step";
import { ImportLayerComponent } from "../import-layer.component";

export class ImportLayerUploadFileStep extends Step
 {
  
    fileUploadControl: FormGroup;
    public currentFilename: string;
    uploadingFile: boolean;
    

    constructor(index: number, private importLayerComponent: ImportLayerComponent, private toastService: ToastService, private backendService: BackendService)
    {       
        super(index);        
        this.setTitle("Passo 1: Selecione o arquivo");
        this.setDescription("Selecione o arquivo da para ser importado. Os arquivos suportados são SHAPE-ZIP, DXF ou KML.");
        this.currentFilename="";

        this.fileUploadControl = new FormGroup(
          {
            file: new FormControl()
          }
        );    
         
    }
    execute()
    {

    }
    callback(object: any)
    {

    }
    rollback()
    {
    }
    finish()
    {
        
    }
    valid()
    {
      return this.isValidFile();
    }
    onFileSelect(event) {
      if (event.target.files.length > 0) 
      {
        this.uploadingFile = true;
        this.currentFilename = event.target.files[0].name;
        const file = event.target.files[0];
        this.fileUploadControl.get('file').setValue(file);
        
        this.uploadFile(file);        
      }
      else
      {
        this.uploadingFile = false;
        this.toastService.info("Selecione um arquivo válido.", "Atenção");
      }
    }

    private uploadFile(file: any)
    {      
      if(file)
      {
        let layerFile: Blob = new Blob([file], { type: 'application/octet-stream' });

        this.backendService.uploadLayerFile(file.name, layerFile).then((importLayerInfo: Importlayerfileinfo)=>
        {
          if(importLayerInfo.error==false)
          {
            this.importLayerComponent.importlayerfileinfo=importLayerInfo;
            let fields = []
            importLayerInfo.fields.forEach((field)=>{
              
              fields.push(this.validateFieldName(field));
            });
            importLayerInfo.fields=fields;
            if(importLayerInfo.sldfields)
            {
              importLayerInfo['sldfields_validation']=this.validateSLDFields(importLayerInfo.sldfields, importLayerInfo.fields);
            }
            this.uploadingFile = false;
            this.toastService.sucess("Arquivo carregado.","Sucesso");
          }
          else
          {
            this.uploadingFile = false;
            this.toastService.error("Não foi possível carregar o arquivo para o servidor. " + importLayerInfo.msg, "Erro");
          }                   
          
        }).catch((e)=>
        {
          this.uploadingFile = false;
          console.log(e);
          this.toastService.error("Problema ao subir arquivo para o servidor.","Erro");
        })
      }
      else
      {
        this.uploadingFile = false;
        this.toastService.error("Favor selecionar primeiramente um arquivo.","Erro");
      }
    }
    private isValidFile()
    {
      if(this.importLayerComponent.importlayerfileinfo!=null)
      {
        return true;
      }
      return false;
    }
    private validateFieldName(field) 
    {
      
      field.approved = true;
      field.msg = "";

      if(/[A-Z]/.test(field.name))
      {
        field.approved = false
        field.msg += "Não é recomendado importar camadas com nome dos campos em caixa alta.\n";
      }
      
      if(/[ `!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/.test(field.name)==true)
      {
        field.approved = false
        field.msg += "Não é recomendado importar camadas com nome dos campos contendo caracteres especiais.\n";
      }
      return field;
  }
  private validateSLDFields(sldFields, fields) 
    {
      let outFields = [];

      sldFields.forEach(sldfield => 
        {
         let newSldField={
          name: sldfield,
          approved:false,
          msg: "Campo utilizado em regra do estilo não existe na fonte de dados da camada. \n Isso pode inviabilizar a visualização da camada, rótulo ou regra."
         }          
        fields.forEach(field => 
          {
            newSldField={
              name: sldfield,
              approved:true,
              msg: "Campo utilizado em regra do estilo existe na fonte de dados da camada."
            };          
        });
        outFields.push(newSldField);
      });

      return outFields;
  }
}

