<div class="logradouros-div" >
  <div>
    <mat-label>Logradouro:</mat-label>
    
  </div>
  <div class="mat-form-field-div">
    <mat-form-field >
      <input type="text"
            placeholder=""
            aria-label="Logradouro"
            matInput
            [formControl]="logradourosControl"
            [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="logradouroSelected($event.option.value)">
        <mat-option *ngFor="let option of logradourosFilteredOptions | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>  
  </div>
  <div class="buttons-div">
    <button [disabled]="selected == false" type="button" (click)="fitStreet()" mat-raised-button color="primary" id="fit-logradouro-button">
        <span class="material-icons">location_on</span> Destacar no Mapa
    </button>
    <button *ngIf="this.hasEditionPermission()" [disabled]="selected == false" type="button" (click)="editStreet()" mat-raised-button color="primary" id="download-layer-button">
      <span class="material-icons">table_view</span> Editar
    </button>
  </div>    

</div>

