import { Component, OnInit } from '@angular/core';
import {LayersConfigService} from '../layers/layers.config.service';
import { LayerConfig } from '../layers/layers.config';
import { CanvasService } from '../canvas/canvas.service';
import { Observable } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AuthenticationService } from 'src/app/service/authentication.service';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {

  hasLegend:boolean;
  
  overlayers:LayerConfig[] = [];
  legendParams="?request=GetLegendGraphic&format=image/png&width=20&height=20&service=WMS&layer=";
  layerChange: Observable<void>;
  
  constructor(private layersConfigService:LayersConfigService,
    private canvasService:CanvasService,
    private authenticationService: AuthenticationService) {
    this.overlayers=this.layersConfigService.getOverlayers();
    this.layerChange=this.canvasService.getLayerChanges();
    this.hasLegend=true;
    this.layerChange.subscribe( ()=> {
      this.overlayers=this.layersConfigService.getOverlayers();
      this.hasLegend=false;
      this.overlayers.find( (ol)=>{
        if(ol.isEnabled()) {
          this.hasLegend=true;
          return ol;
        }
      });
    });
  }

  ngOnInit(): void {
  }

  isLayerReadableForUser(layer: LayerConfig)
  {
    let readable:boolean = layer.isReadableForUser(this.authenticationService.getCurrentUser());
    return readable;
  }

}
