import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { ToolsService } from '../tools.service';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';
import { NotificationService } from 'src/app/service/notification.service';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { Tool } from '../tool';
import { SearchCoordComponent } from './search-coord-tool.component';

export class SearchCoordTool extends Tool {

    private dialog: MatDialog;

    cursorType=null;
    id='search-coord-tool';
    enabled=false;
    name='Voo por Coordenada Tool';
    title='Localizar Coordenada';
    type='click';
    map:L.Map=null;
    icon='approval';
    ll: any;
    protected visible:boolean=true;
    layerTool: boolean=false;
    customParameter=new Map<String, any>();
    notificationSubscrition: any;

    private dialogRef: any;

    constructor(dialog: MatDialog, toolsService: ToolsService) 
    { 
        super(toolsService)
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{};

    public enableImpl()
    {
        this.dialogRef = this.dialog.open(DialogComponent, {
            maxHeight: '100%',
            maxWidth: '100%',
            data: { component: SearchCoordComponent, title: "Localizar Coordenada" }
        });

        let whenDialogIsOpen=this.dialogRef.afterOpened().toPromise();
        whenDialogIsOpen.then(
            ()=>{
                this.toolsService.dispatchTool(this);
            }
        );
        this.dialogRef.afterClosed().toPromise().then(()=>{
            this.toolsService.toogleTool(this);
        });
    }
    public disableImpl()
    {
    }
    
    public closeDialog()
    {
        if(this.dialogRef)
        {
            this.dialogRef.close();
        }
    }
    
}
