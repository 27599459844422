import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { ToolsService } from '../tools.service';
import { Tool } from '../tool';
import { AbstractLayerTool } from '../abstract-layer-tool';

export abstract class AbstractEditionTool extends AbstractLayerTool 
{
    public abstract addToolControl() : void;
    public abstract removeToolControl() : void;

    public abstract toolbarButtonClicked(event: any) : void;
    public abstract toolbarActionClicked(event: any) : void;
}
