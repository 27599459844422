import Point from 'ol/geom/Point';
import { Pointer } from 'ol/interaction'
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/src/Feature';

export class CaptureCoordinateInteraction extends Pointer
{
    id: string;
    feedbackLayer: any = null;
	dragging: boolean = false;
    currentCoordinate: any;
    handlePointerDown: any; 
    handlePointerMove: any; 
    handlePointerUp: any;  

    constructor(id:string)
    {
        super();
        this.id=id;
        //Setting handler to this child class
        Pointer.call(this, { handleEvent: this.handleEvent});
    }

    protected setup(options: any)
    {
        this.handlePointerDown = options.handlePointerDown ? options.handlePointerDown : null;	
        this.handlePointerMove = options.handlePointerMove ? options.handlePointerMove : null;
        this.handlePointerUp = options.handlePointerUp ? options.handlePointerUp : null;
    }

    public handleEvent(event) 
    {
        this.currentCoordinate = event.coordinate;
        
        var propagate = true;
        if (event.type == 'pointerdown')
        {
            this.dragging = false;
            if(this.handlePointerDown != null)
            {
                propagate = this.handlePointerDown(event);	
            }		
        } 
        else if (event.type == 'pointermove')
        {	
            this.dragging = event.dragging;
            if(this.handlePointerMove != null)
            {
                propagate = this.handlePointerMove(event);	
            }
        }
        else if (event.type == 'pointerup')
        {	
            if(this.handlePointerUp != null)
            {
                propagate = this.handlePointerUp(event);	
            }
        }
        
        this.updateFeedback();
            
        return true;
    }

    public isDragging() 
    {
        return this.dragging;
    }

    public setMap(map) 
    {
        super.setMap.call(this, map);
        
        if(map == null)
        {
            this.feedbackLayer.setMap(0);	
        }
        else
        {
            var source = new VectorSource({ useSpatialIndex: false });
            this.feedbackLayer = new VectorLayer({ source: source });
            this.feedbackLayer.setMap(this.getMap());	
        }
        
        this.updateFeedback();
    };

    public updateFeedback()
    {
        this.feedbackLayer.getSource().clear();
        
        if(this.currentCoordinate == null)
        {
            return;
        }
        
        //feature that represents the current point
        var currentPoint = new Feature(new Point(this.currentCoordinate));
        this.feedbackLayer.getSource().addFeature(currentPoint);
    }

    public clear()
    {
        this.currentCoordinate = null;
        this.feedbackLayer.getSource().clear();
    }

}



