import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LayerConfig } from '../map/layers/layers.config';
import * as L from 'leaflet';
import { FeatureService } from './feature.service';
import { LayersConfigService } from '../map/layers/layers.config.service';
import { Property } from '../map/vo/property';
import { LayerFeatures } from '../map/vo/layer-features';

@Injectable({
  providedIn: 'root'
})
export class CertidoesService 
{
  private certidoesHighlightStyleConfig = "certidoes_highlight_style";

  constructor(private featureService: FeatureService, private layerConfigService: LayersConfigService) { }
  
  public getMapURL(feature: any, mapLayers: LayerConfig[], width: number, height: number, bounds: L.LatLngBounds=null, zoomOutLevels: number=0)
  {
    let geoserverURL = environment.GEOSERVER_URL_DOMAIN + environment.GEOSERVER_URL_PATH;

    let properties : Array<Property>;
    if(feature.properties instanceof Array)
    {
      properties = feature.properties;
    }
    else
    {
      properties = this.featureService.getWFSPropertiesObjectAsPropertyArray(feature.properties); 
    }    

    let layersList = "";
    let layersStyles = "";
    let featureIds = "";
    mapLayers.reverse().forEach(mapLayer => {

      let layerStyle = this.layerConfigService.getLayerAppConfig(mapLayer, this.certidoesHighlightStyleConfig);
      let idAttribute = this.layerConfigService.getLayerIdAttribute(mapLayer);

      if(layersList.length!=0)
      {
        layersList+=",";
        layersStyles+=",";
        featureIds+=",";
      }
      if(layerStyle)
      {
        layersStyles+=layerStyle;
      }
      if(idAttribute)
      {
        let property = this.featureService.getPropertyByKey(properties, idAttribute);

        if(property)
        {
          featureIds+=property.value;
        }        

      }
      layersList+=mapLayer.getId();
    });

    if(bounds==null)
    {
      bounds = this.featureService.getFeaturesBounds(feature);
    }

    let fixedBox = this.getBoxForPixelDimensions(width, height, bounds, zoomOutLevels);

    let urlMap = geoserverURL;
    urlMap+="?SERVICE=WMS";
    urlMap+="&VERSION=1.1.1";
    urlMap+="&REQUEST=GetMap";
    urlMap+="&FORMAT=image/png";
    urlMap+="&TRANSPARENT=true";
    urlMap+="&LAYERS=" + layersList;
    urlMap+="&SRS=" + this.featureService.getDefaultWFSEPSG();
    urlMap+="&STYLES=" + layersStyles;
    urlMap+="&WIDTH="+width;
    urlMap+="&HEIGHT="+height;
    urlMap+="&BBOX="+fixedBox.toBBoxString();

    if(featureIds)
    {
      urlMap+="&featureid="+featureIds;
    }

    return urlMap;
  } 

  public getMapURLForLayerFeatures(layersFeatures: LayerFeatures[], width: number, height: number, bounds: L.LatLngBounds=null, zoomOutLevels: number=0)
  {

    let layersList = "";
    let layersStyles = "";
    let featureIds = "";

    let geoserverURL = environment.GEOSERVER_URL_DOMAIN + environment.GEOSERVER_URL_PATH;

    let allFeatures = []
    layersFeatures.reverse().forEach(layerFeatures => 
    {
      //Iterating over layers
      let layer : LayerConfig = layerFeatures.layer;
      let features: any[] = layerFeatures.features;

      let layerStyle = this.layerConfigService.getLayerAppConfig(layer, this.certidoesHighlightStyleConfig);
      let idAttribute = this.layerConfigService.getLayerIdAttribute(layer);

      if(layersList.length!=0)
      {
        layersList+=",";
        layersStyles+=",";        
      }
      if(layerStyle)
      {
        layersStyles+=layerStyle;
      }

      layersList+=layer.getId();

      if(layerFeatures.isVector==true)
      {
        features.forEach(feature => 
          {             
            //Iterating over feature of each layer
            let properties : Array<Property>;
            if(feature.properties instanceof Array)
            {
              properties = feature.properties;
            }
            else
            {
              properties = this.featureService.getWFSPropertiesObjectAsPropertyArray(feature.properties); 
            }   
            
            if(idAttribute)
            {
              let property = this.featureService.getPropertyByKey(properties, idAttribute);
      
              if(property)
              {
                if(featureIds!="")
                {
                  featureIds+=",";
                }
                featureIds+=property.value;
              }
            }
            allFeatures.push(feature);
          });  
      }      
    });
    if(bounds==null)
    {
      bounds = this.featureService.getFeaturesBounds(allFeatures);
    }

    let fixedBox = this.getBoxForPixelDimensions(width, height, bounds, zoomOutLevels);

    let urlMap = geoserverURL;
    urlMap+="?SERVICE=WMS";
    urlMap+="&VERSION=1.1.1";
    urlMap+="&REQUEST=GetMap";
    urlMap+="&FORMAT=image/png";
    urlMap+="&TRANSPARENT=true";
    urlMap+="&LAYERS=" + layersList;
    urlMap+="&SRS=" + this.featureService.getDefaultWFSEPSG();
    urlMap+="&STYLES=" + layersStyles;
    urlMap+="&WIDTH="+width;
    urlMap+="&HEIGHT="+height;
    urlMap+="&BBOX="+fixedBox.toBBoxString();

    if(featureIds)
    {
      urlMap+="&featureid="+featureIds;
    }

    return urlMap;
  } 

  private getBoxForPixelDimensions(width: number, height: number, bounds: L.LatLngBounds, zoomOutLevels: number) : L.LatLngBounds
  {
    let tempMapDiv = document.getElementById('temp-map-div');
    tempMapDiv.style.display='block';
    tempMapDiv.style.width=width+'px';
    tempMapDiv.style.height=height+'px';
    var tempMap = L.map('temp-map-div').setView([51.505, -0.09], 13);
    
    tempMap.fitBounds(bounds);

    if(zoomOutLevels)
    {
      let currentZoom = tempMap.getZoom();
      let newZoomLevel = currentZoom-zoomOutLevels;
      console.log("Changing certidões zoom from "+ currentZoom + " to " + newZoomLevel+"!");
      tempMap.setZoom(newZoomLevel, {animate: false});
    }    
    
    let outBounds : L.LatLngBounds = tempMap.getBounds();

    tempMapDiv.style.display='none';
    tempMapDiv.innerHTML='';

    tempMap.off();
    tempMap.remove();

    

    return outBounds;
  }
}
