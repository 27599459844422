import { Injectable } from '@angular/core';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../vo/notification';
import { GeomType } from '../vo/geomtype';
import { LayerConfig } from './layers.config'
import { Tool } from '../tools/tool';
import { Property } from '../vo/property';
import { BackendService } from 'src/app/service/backend.service';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/service/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LayersConfigService {
  /**
   * Maybe this shouln't be the place to store this layers list. But is better then storing on a component.
   * Later we can move this to a shared place for all components
   */
  baselayers:LayerConfig[] = [];
  overlayers:LayerConfig[] = [];
  notificationSubscrition: Subscription;
  layersJson: any;
  private defaultGWCTileMatrixName = 'EPSG%3A3857'

  constructor(private notificationService: NotificationService, private backendService: BackendService, private authenticationService: AuthenticationService) 
  {
  }

  public loadLayers() : Promise<any>
  {
    
    return this.backendService.getLayers();
         
  }
  
  getBaselayers() : LayerConfig[]
  {
    return this.baselayers;
  }
  getOverlayers() : LayerConfig[]
  {
     return this.overlayers;
  }

  getBaseLayerById(id: string)
  {
    let layer=this.getBaselayers().find((layerconfig)=>
    {
      if(id==layerconfig.getId())
      {
        return layerconfig;
      }
    });
    return layer;
  }

  getOverLayerById(id: string, overlayers: LayerConfig[] = null) : LayerConfig
  {
    if(overlayers==null)
    {
      overlayers=this.getOverlayers();
    }
    let layer=overlayers.find((layerconfig)=>
    {
      if(id==layerconfig.getId())
      {
        return layerconfig;
      }
    });
    return layer;
  }
  
  getOverLayerIndexById(id: string)
  {
    let index=this.getOverlayers().findIndex((layerconfig)=>
    {
      if(id==layerconfig.getId())
      {
        return true;
      }
    });
    return index;
  }
  /**
   * Returns only the first layer with quicksearch application config.
   */
  getQuicksearchLayer()
  {
    let qsLayer = null
    this.getOverlayers().forEach(layer => {
      layer.getApplicationconfigs().forEach(appConfig => {
        if(appConfig.quicksearch && appConfig.quicksearch==true)
        {
          qsLayer=layer
        }
      });
    });
    return qsLayer;
  }

  getPhoto360Layer()
  {
    let qsLayer = null
    this.getOverlayers().forEach(layer => {
      layer.getApplicationconfigs().forEach(appConfig => {
        if(appConfig.photo360layer && appConfig.photo360layer==true)
        {
          qsLayer=layer
        }
      });
    });
    return qsLayer;
  }

  getColetaPGVLayer()
  {
    let qsLayer = null
    this.getOverlayers().forEach(layer => {
      layer.getApplicationconfigs().forEach(appConfig => {
        if(appConfig.coletapgvlayer && appConfig.coletapgvlayer==true)
        {
          qsLayer=layer
        }
      });
    });
    return qsLayer;
  }

  getSplitToolBaseLayer()
  {
    let qsLayer = null
    this.getOverlayers().forEach(layer => {
      layer.getApplicationconfigs().forEach(appConfig => {
        if(appConfig.split_tool_base_layer && appConfig.split_tool_base_layer==true)
        {
          qsLayer=layer
        }
      });
    });
    return qsLayer;
  }

  getMergeToolBaseLayer()
  {
    let qsLayer = null
    this.getOverlayers().forEach(layer => {
      layer.getApplicationconfigs().forEach(appConfig => {
        if(appConfig.merge_tool_base_layer && appConfig.merge_tool_base_layer==true)
        {
          qsLayer=layer
        }
      });
    });
    return qsLayer;
  }

  getCertidoesToolLayer()
  {
    let outLayer = null
    this.getOverlayers().forEach(layer => {
      layer.getApplicationconfigs().forEach(appConfig => {
        if(appConfig.certidoes_tool && appConfig.certidoes_tool==true)
        {
          outLayer=layer
        }
      });
    });
    return outLayer;
  }

  getEdificacoesEditionToolBaseLayer()
  {
    let qsLayer = null
    this.getOverlayers().forEach(layer => {
      layer.getApplicationconfigs().forEach(appConfig => {
        if(appConfig.edificacoes_edition_tool_base_layer && appConfig.edificacoes_edition_tool_base_layer==true)
        {
          qsLayer=layer
        }
      });
    });
    return qsLayer;
  }

  getSetupImoveisLinkToolBaseLayer()
  {
    let qsLayer = null
    this.getOverlayers().forEach(layer => {
      layer.getApplicationconfigs().forEach(appConfig => {
        if(appConfig.setup_imoveis_link_tool_base_layer && appConfig.setup_imoveis_link_tool_base_layer==true)
        {
          qsLayer=layer
        }
      });
    });
    return qsLayer;
  }
  

  getLayerId(layer: LayerConfig)
  {
    if(layer.getWorkspace())
    {
      return layer.getWorkspace() + ":" + layer.getId();
    }
    return layer.getId(); 
  }

  public getCtiLayer()
  {
    let qsLayer = null
    this.getOverlayers().forEach(layer => {
      layer.getApplicationconfigs().forEach(appConfig => {
        if(appConfig.cti && appConfig.cti==true)
        {
          qsLayer=layer
        }
      });
    });
    return qsLayer;
  }

  public enableLayer(layerConfig: LayerConfig)
  {
    if(layerConfig)
    {
        let notification = new AppNotification(AppNotification.ENABLE_LAYER_EVENT, layerConfig);
        this.notificationService.send(notification);
    }    
  }

  public isVectorLayer(layerConfig: LayerConfig)
  {
     let isVector: boolean=false;
     layerConfig.getApplicationconfigs().forEach(appConfig => {
         if(appConfig.vector && appConfig.vector==true)
         {
          isVector = true;
         }
       });
       return isVector;    
  }

  public isWMTSLayer(layerConfig: LayerConfig)
  {
     let isWMTS: boolean=false;
     layerConfig.getApplicationconfigs().forEach(appConfig => {
         if(appConfig.wmts && appConfig.wmts==true)
         {
          isWMTS = true;
         }
       });
       return isWMTS;    
  }
  public getLayerGeomType(layerConfig: LayerConfig) : GeomType
  {
    let geomType: GeomType=null;
     layerConfig.getApplicationconfigs().forEach(appConfig => {
         if(appConfig.geomtype)
         {
          geomType= GeomType.getGeomTypeByName(appConfig.geomtype);
         }
       });
       return geomType;    
  }

  public getLayerIdAttribute(layerConfig: LayerConfig) : string
  {
    let idAttribute=null;
     layerConfig.getApplicationconfigs().forEach(appConfig => {
         if(appConfig.id_attribute)
         {
          idAttribute=appConfig.id_attribute
         }
       });
       return idAttribute;    
  }

  public getLayerTable(layerConfig: LayerConfig) : string
  {
    let table=null;
     layerConfig.getApplicationconfigs().forEach(appConfig => {
         if(appConfig.table)
         {
          table=appConfig.table
         }
       });
       return table;    
  }

  public getLayerGeomAttribute(layerConfig: LayerConfig) : string
  {
    let geomAttribute=null;
     layerConfig.getApplicationconfigs().forEach(appConfig => {
         if(appConfig.geom_attribute)
         {
          geomAttribute=appConfig.geom_attribute
         }
       });
       return geomAttribute;    
  }

  public getLayerInscricaoAttribute(layerConfig: LayerConfig) : string
  {
    let inscricao_attribute=null;
     layerConfig.getApplicationconfigs().forEach(appConfig => {
         if(appConfig.inscricao_attribute)
         {
          inscricao_attribute=appConfig.inscricao_attribute
         }
       });
       return inscricao_attribute;    
  }

  public getLayerAppConfig(layerConfig: LayerConfig, config: string) : string
  {
    let configValue=null;
     layerConfig.getApplicationconfigs().forEach(appConfig => {
         if(appConfig[config])
         {
          configValue=appConfig[config]
         }
       });
       return configValue;    
  }

  public getLayersWithAppConfig(config: string) : LayerConfig[]
  {
    let layers : LayerConfig[]= []
    this.getOverlayers().forEach(layer => {
      layer.getApplicationconfigs().forEach(appConfig => {
        if(appConfig.hasOwnProperty(config) && appConfig[config])
        {
          layers.push(layer);
        }
      });
    });
    return layers;
  }

  public getHighlightParents(layerConfig: LayerConfig) : any
  {
    let layerHighligthParents=null;
     layerConfig.getApplicationconfigs().forEach(appConfig => {
         if(appConfig.highlight_parent_layers)
         {
          layerHighligthParents=appConfig.highlight_parent_layers
         }
       });
       return layerHighligthParents;    
  }

  public getOverlayersFromJSON() : LayerConfig[]
  {
     let overlayers: LayerConfig[]=[];
    this.layersJson.layers.overlayers.forEach(overlayer => {      
      overlayers.push(new LayerConfig(overlayer.id, overlayer.name, overlayer.workspace,
         overlayer.title, overlayer.description, overlayer.enabled, overlayer.source,
          overlayer.path, null, overlayer.applicationconfigs, overlayer.layertools, overlayer["permission"]))
    });
    return overlayers;
  }

  public loadOverlayers()
  {
    this.overlayers = this.getOverlayersFromJSON();
  }
  public loadBaselayers()
  {
    this.baselayers=[];
    this.layersJson.layers.baselayers.forEach(baselayer => {
      this.baselayers.push(new LayerConfig(baselayer.id, baselayer.name, baselayer.workspace,
         baselayer.title, baselayer.description, baselayer.enabled, baselayer.source,
          baselayer.path, null, baselayer.applicationconfigs, baselayer.layertools, baselayer["permission"]))
    });
  }

  // public getLayerToolConfig(layer: LayerConfig, toolId: string, config: string) : string
  // {
  //   let layerToolConfig : string = "";
    
  //   let layerTools = layer.getLayertools();

  //   if(layerTools)
  //   {
  //     layerTools.forEach((layerTool: any)=>
  //     {
  //       if(layerTool.toolid==toolId)
  //       {
  //         if(layerTool.config)
  //         {
  //           layerTool.config.forEach(toolConfig => {
  //             if(toolConfig[config])
  //             {
  //               layerToolConfig = toolConfig[config];
  //             }              
  //           });
  //         }
  //       }
  //     });
  //   }
    
  //   return layerToolConfig
  // }

  public getLayerURL(layer: LayerConfig)
  {
    let url = "";
    if(layer)
    {
      //let randint = (Math.floor( Math.random() * 2000000 ) + 1);
      url+=layer.getSource()+layer.getPath();
    }
    return url;
  }

  /**
   * 
   * @param layers Update JSON Layers got from CTI
   */
  public updateLayersFromJSON(layers: any)
  {
    this.layersJson=layers;
    this.loadBaselayers();
    this.loadOverlayers();
    this.overlayers = this.getOverlayers();
    this.baselayers = this.getBaselayers();
  }

  public getDefaultGWCTileMatrixName()
  {
    return this.defaultGWCTileMatrixName;
  }
 
  
}

