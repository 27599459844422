import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastService } from '../service/toast.service';
import { AuthenticationService } from '../service/authentication.service';
import { LocalStorageService } from '../service/local-storage.service';
import { NotificationService } from '../service/notification.service';
import { BackendService } from '../service/backend.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent implements OnInit {

  title = environment.title;
  description = this.title+' - '+environment.description;
  appVersion = environment.version;
  changePasswordFlag: boolean;
  authenticationFormGroup: FormGroup = new FormGroup(
    {
      username: new FormControl(),
      password: new FormControl()
    }
  );
  changePasswordFormGroup: FormGroup = new FormGroup(
    {
      id: new FormControl(),
      username: new FormControl(),
      oldPassword: new FormControl(),
      newPassword: new FormControl(),
      newPasswordRepeat: new FormControl()
    }
  );

  constructor(private toastService: ToastService, 
    private authenticationService: AuthenticationService,
    private localStorage: LocalStorageService, private backendService: BackendService) 
    {
      this.changePasswordFlag=false;
    }

  ngOnInit(): void 
  {
  }

  authenticate() 
  {
    let username = this.authenticationFormGroup.get('username').value;
    let password = this.authenticationFormGroup.get('password').value;
    if(!username || !password)
    {
      this.toastService.error("Necessário preencher usuario e senha.", "Erro ao autenticar");
    }
    else
    {
      this.authenticationService.authenticate(username, password).then((userinfo)=>{
        console.log(userinfo);
        if(userinfo.change_password==true)
        {
          this.changePasswordFlag=true;
          this.changePasswordFormGroup.get('username').setValue(username);
          this.changePasswordFormGroup.get('id').setValue(userinfo.id);
          this.changePasswordFormGroup.get('oldPassword').setValue(password);
          this.backendService.setAuthToken(userinfo.token);
        }
        else
        {
          this.authenticationService.authenticationChanged();
        }        
      }).catch(
        (userInfo)=>
      {
        this.authenticationService.authenticationChanged();
      });
    }
  }

  changePassword() 
  {
    let userId = this.changePasswordFormGroup.get('id').value;
    let oldPassword = this.changePasswordFormGroup.get('oldPassword').value;
    let newPassword = this.changePasswordFormGroup.get('newPassword').value;
    let newPasswordRepeat = this.changePasswordFormGroup.get('newPasswordRepeat').value;
    if(!oldPassword || !newPassword)
    {
      this.toastService.error("Necessário preencher os campos 'Senha Antiga' e 'Senha Nova'.", "Erro ao trocar senha");
    } else if(newPassword!=newPasswordRepeat)
    {
      this.toastService.error("Os campos 'Nova Senha' e 'Repita Nova Senha' estão diferentes.", "Erro ao trocar senha");
    }
    else
    {
      this.authenticationService.changePassword(userId, oldPassword, newPassword).then(()=>
      {
        this.toastService.sucess("Senha alterada com sucesso!", "Alteração de senha inicial");
        this.authenticationService.authenticationChanged();
      }).catch(()=>
      {
        this.toastService.error("Não foi possível alterar a senha, confira os campos e tente novamente.", "Erro ao trocar senha");
      })
      
    }
  }
  
}

