import { AbstractDocumentType } from './abstract-document-type';
import { AbstractDocumentAuxiliarField } from './auxiliar-fields/abstract-document-auxiliar-field';
export class GenericFeatureDocumentType implements AbstractDocumentType
{
    type: number;
    constructor(type: number)
    {
        this.type = type;
    }
    getSupportedExtensions()
    {
        return ["jpg", "png", "tif", "pdf"];
    }
    getAuxiliarComponents() : AbstractDocumentAuxiliarField[]
    {
        return [];
    }
}