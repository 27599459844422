<mat-accordion class="layers-cruzamento-config-accordion">
    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="cruzamento-panel">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header" class="layers-col">
                    <mat-icon class="material-icons layer-icon">format_list_numbered</mat-icon>Configuração Cruzamento de Camadas de Certidões
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              
            </mat-expansion-panel-header>
            <div class="example-container">
                <h2>Camadas disponiveis:</h2>
              
                <div
                  cdkDropList
                  #availableList="cdkDropList"
                  [cdkDropListData]="availableLayers"
                  [cdkDropListConnectedTo]="[selectedList]"
                  class="example-list"
                  (cdkDropListDropped)="drop($event)">
                  <div class="example-box" *ngFor="let item of availableLayers" cdkDrag><mat-icon class="material-icons layer-icon">layers</mat-icon>&nbsp;{{item.name}}</div>
                </div>
              </div>
              
              <div class="example-container">
                <h2>Camadas selecionadas:</h2>
              
                <div
                  cdkDropList
                  #selectedList="cdkDropList"
                  [cdkDropListData]="selectedLayers"
                  [cdkDropListConnectedTo]="[availableList]"
                  class="example-list"
                  (cdkDropListDropped)="drop($event)">
                  <div class="example-box" *ngFor="let item of selectedLayers; let i = index;" cdkDrag>{{i+1}}&nbsp;-&nbsp;<mat-icon class="material-icons layer-icon">layers</mat-icon>{{item.name}}</div>
                </div>
              </div>
              <mat-divider></mat-divider>
        
              <div class="save-button">
                  <button type="button" (click)="save()" mat-raised-button color="primary" id="save-button">
                      <span class="material-icons">save</span> Salvar
                  </button>

              </div>    
            </mat-expansion-panel>

    </ng-container>
</mat-accordion>