import { ToolsService } from '../tools.service';
import { MatDialog } from '@angular/material/dialog';
import { Feature } from '../../vo/feature';
import { DialogComponent } from '../../dialog/dialog.component';
import { Tool } from '../tool';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { LayerConfig } from '../../layers/layers.config';
import { LayersConfigService } from '../../layers/layers.config.service';
import { FeatureService } from 'src/app/service/feature.service';
import { ToastService } from 'src/app/service/toast.service';
import { CanvasService } from '../../canvas/canvas.service';
import { Property } from '../../vo/property';
import { EditEdificacoesToolComponent } from './edit-edificacoes-tool.component';


export class EditEdificacoesTool extends AbstractLayerTool {

    private dialog: MatDialog;

    cursorType='pointer';
    id='edit-edificacoes-tool';
    enabled=false;
    name='Edit Edification Tool';
    title='Edição de Edificação';
    type='click';
    map:L.Map=null;
    icon='account_balance_wallet';
    ll: any;
    protected visible:boolean=true;
    lotesSelectedFeature: Feature;
    layerTool: boolean=true;
    customParameter=new Map<String, any>();
    dialogRef: any;

    loadingFeature: boolean;

    lotesFeature: any;
    edificacoesFeatures: any;


    //custom variables
    currentFeature: any;
    featureSRID: string;
    featureSRS: string;
    toolLotesLayerIdConfig: string = "lotes_layer_id";
    //Nome da chave da configuração que guarda o nome do attributo de inscricao do layer de lotes para essa tool
    toolLotesICAttributeConfig: string = "lotes_ic_attribute";
    //Nome da chave da configuração que guarda o nome do attributo de inscricao do layer de edificacoes para essa tool    
    toolEdificacoesICAttributeConfig: string = "edificacoes_ic_attribute";

    toolEdificacoesLoteIDAttributeConfig: string = "lote_id_attribute";    

    lotesICAttribute: string;    
    edificacoesICAttribute: string;
    currentLoteICValue: string; 
    edificacoesLoteIDAttribute: string;
    

    currentLoteIDValue: string;     
    

    lotesLayer: LayerConfig;
    edificacoesLayer: LayerConfig;

    constructor(toolsService: ToolsService, private layerConfigService: LayersConfigService, private featureService: FeatureService, private toastService: ToastService, 
        private canvasService: CanvasService,dialog: MatDialog) 
    { 
        super(toolsService)
        this.dialog = dialog;
        this.loadingFeature = false;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{
        this.map=event.sourceTarget;
        this.ll=event.latlng;
        
        if(this.lotesLayer)
        {
            if(this.loadingFeature == false)
            {
                this.loadFeature();
            }
            else
            {
                this.toastService.warning("Buscando feição apontada, por favor aguarde!", "Carregando...");
            }            
        }

    };
    public enableImpl()
    {
        this.loadingFeature = false;
        this.currentFeature=null;

        this.edificacoesLayer = this.toolsService.getToolParameter(this, "layer");
        if(this.edificacoesLayer)
        {
            this.layerConfigService.enableLayer(this.edificacoesLayer);
        }

        this.enableLotesLayer();

        this.toastService.sucess("Aponte o lote que deseja editar as edificações.", "Ferramenta de Edição Customizada de Edificações Habilitada"); 
    }
    public disableImpl()
    {
    }

    /**
     * Habilitar camada de lotes para obtensão de edificações associadas baseada na config da tool dentro do layer de edificações.
     * @param currentLayerTool Layer de Edificações
     */
    private enableLotesLayer()
    {
        if(this.edificacoesLayer)
        {
            let lotesLayerId = this.layerConfigService.getLayerAppConfig(this.edificacoesLayer, this.toolLotesLayerIdConfig);
            if(lotesLayerId)
            {
                
                this.lotesLayer = this.layerConfigService.getOverLayerById(lotesLayerId);

                if(this.lotesLayer)
                {
                    this.layerConfigService.enableLayer(this.lotesLayer);
                }
        
            }
        }
    }

    public isVisibleForLayer(layer :LayerConfig) : boolean
    {
        return this.layerTool;
    }

    private openDialog()
    {
        this.dialogRef = this.dialog.open(DialogComponent, {
            height: '90%',
            width: '90%',
            data: { component: EditEdificacoesToolComponent, title: "Edição de Edificações", lotesFeature: this.lotesFeature, edificacoesFeatures: this.edificacoesFeatures, featureSRID: this.featureSRID,            
            edificacoesLayerConfig: this.edificacoesLayer, lotesICAttribute: this.lotesICAttribute, edificacoesICAttribute: this.edificacoesICAttribute, currentIC: this.currentLoteICValue}
        });

        let whenDialogIsOpen=this.dialogRef.afterOpened().toPromise();
        whenDialogIsOpen.then(
            ()=>{
                this.toolsService.dispatchTool(this);
            }
        );

        this.dialogRef.afterClosed().toPromise().then(()=>{
            this.currentFeature=null;
        });
    }

    private loadFeature()
    {
        
        if(this.lotesLayer)
        {
            this.map.eachLayer( (l)=>{
                if(l["wmsParams"]) {
        
                    if(l["id"]==this.lotesLayer.getId())
                    {
                        this.loadingFeature = true;
                        
                        this.featureService.getFeatureInfo(this.ll, l, this.map).then(featureInfo => {
        
                            if(featureInfo && featureInfo.features.length>0)
                            {
                                this.lotesSelectedFeature=featureInfo.features[0];
                                console.log(this.lotesSelectedFeature);
            
                                //this.featureSRID=featureInfo.features[0].srs.replace("EPSG:","");   
                                this.featureSRS = this.map.options.crs.code; 
                                this.featureSRID=this.featureSRS.replace("EPSG:","");

                                this.loadLote(this.featureSRS);

                            }
                            else
                            {
                                this.loadingFeature = false;
                            }
                            
                        }).catch((error)=>
                        {
                            this.toastService.error("Problema ao buscar informações do local apontado. " , "Erro"); 
                            console.log(error);

                            this.loadingFeature = false;
                        });
                    }
                }
            });
        }
    }

    private loadLote(srs: any)
    {
        if(this.lotesSelectedFeature)
        {
            let lotesIdAttribute = this.layerConfigService.getLayerIdAttribute(this.lotesLayer);

            let lotesAttributesFilterList: Array<Array<Property>>=[];
            let lotesAttributeFilterList: Array<Property>=[];
    
            let loteIdProperty = this.featureService.getPropertyByKey(this.lotesSelectedFeature.properties, lotesIdAttribute);
    
            if(loteIdProperty)
            {
                lotesAttributeFilterList.push(loteIdProperty);
                lotesAttributesFilterList.push(lotesAttributeFilterList);
        
                this.featureService.getFeatures(this.lotesLayer, srs , lotesAttributesFilterList).then(features => 
                {
                    if(features && features.length>0)
                    {
                        this.lotesFeature = features[0];
    
                        this.loadEdificacoes(srs);
                    }
                    else
                    {
                        this.loadingFeature = false;
                    }
                }).catch((error)=>
                {
                    this.toastService.error("Problema ao buscar a feição do lote apontado. " , "Erro"); 
                    console.log(error);

                    this.loadingFeature = false;
                });
            }
            else
            {
                console.log("Property "+ lotesIdAttribute +" not found...")
            }
        }        
    }
    private loadEdificacoes(srs: any)
    {
        let lotesIdAttribute = this.layerConfigService.getLayerIdAttribute(this.lotesLayer);

        if(lotesIdAttribute && this.toolEdificacoesLoteIDAttributeConfig)
        {
            this.lotesICAttribute = this.layerConfigService.getLayerAppConfig(this.edificacoesLayer, this.toolLotesICAttributeConfig);

            this.edificacoesLoteIDAttribute = this.layerConfigService.getLayerAppConfig(this.edificacoesLayer, this.toolEdificacoesLoteIDAttributeConfig);

            this.edificacoesICAttribute = this.layerConfigService.getLayerAppConfig(this.edificacoesLayer, this.toolEdificacoesICAttributeConfig);            

            if(this.lotesFeature)
            {
                let edificacoesAttributesFilterList: Array<Array<Property>>=[];
                let edificacoesAttributeFilterList: Array<Property>=[];

                this.currentLoteIDValue = this.lotesFeature.properties[lotesIdAttribute];

                if(this.currentLoteIDValue)
                {
                    //Pesquisando as edificações relacionadas ao lote encontrado por inscrição
                    edificacoesAttributeFilterList.push(new Property(this.edificacoesLoteIDAttribute, this.currentLoteIDValue));
                    edificacoesAttributesFilterList.push(edificacoesAttributeFilterList);
            
                    this.featureService.getFeatures(this.edificacoesLayer, srs , edificacoesAttributesFilterList).then(features => 
                    {
                        if(features && features.length>0)
                        {
                            this.edificacoesFeatures = features;
                            
                            this.openDialog();
                        }
                        this.loadingFeature = false;
                    }).catch((error)=>
                    {
                        this.toastService.error("Problema ao buscar a feição da edificação do lote apontado. " , "Erro"); 
                        console.log(error);
    
                        this.loadingFeature = false;
                    });
                }
    
                
            }
        }
        else
        {
            console.log("Missing IC field config...")
        }

        
        
    }

    


    public isEnabled(layer :LayerConfig) : boolean
    {
        let currentLayerTool = this.toolsService.getToolParameter(this, "layer");
        if(this.enabled && currentLayerTool.getId()==layer.getId() )
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    public close()
    {
        this.canvasService.clearHighlights();
        this.canvasService.redrawLayer(this.edificacoesLayer);
        this.dialogRef.close();
    }

}
