export class Operation
 {
    private static operations : Map<string, Operation> = new Map<string, Operation>();
    static Insert: Operation = new Operation(1, "Insert");
    static Update: Operation = new Operation(2, "Update");
    static Delete: Operation = new Operation(4, "Delete");;
        
    id: number;
    name: string;

    constructor(id: number, name: string)
    {
        this.id=id;
        this.name=name;
        Operation.operations[name] = this;
    }

    static getOperationByName(name: string)
    {
        let operation: Operation = this.operations[name];
        return operation;
    }

}
