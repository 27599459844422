import { CanvasService } from '../../canvas/canvas.service';
import { Tool } from '../tool';
import { ToolsService } from '../tools.service';

export class ClearMarkerTool extends Tool {

    cursorType='tool-cursor-wait';
    id='clearmarker-tool';
    enabled=false;
    protected visible:boolean=true;
    name='Clear markers Tool';
    title='Remover marcadores do mapa';
    type='click';
    icon='location_off';
    layerTool: boolean=false;
    customParameter=new Map<String, any>();
    
    constructor(private canvasService: CanvasService, toolsService: ToolsService) {
        super(toolsService)
    }

    public execAction=(event:L.LeafletMouseEvent)=>{}

    public enableImpl()
    {
        this.toolsService.toogleTool(this);
        this.canvasService.clearMarker();
        this.canvasService.clearMeasure();
    }
    public disableImpl()
    {
    }
}
