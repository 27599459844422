import { Injectable } from '@angular/core';
import { Deleteoperation, Insertoperation, Updateattributes, Updateoperation, Getfeatureattributes, Featureattributes } from '@funcate/sigweb-cti-api';
import { LayerConfig } from '../map/layers/layers.config';
import { LayersConfigService } from '../map/layers/layers.config.service';
import { EditionOperation } from '../map/vo/edition-operation';
import { Property } from '../map/vo/property';
import { BackendService } from './backend.service';



@Injectable({
    providedIn: 'root'
  })

export class EditionService {

  private geoJSONDefaultSRID = '4326'
   
  constructor(private backendService: BackendService, private layersConfigService: LayersConfigService) 
  {
    
  }

  public insertGeom(editOperation: EditionOperation, layer: LayerConfig) : Promise<any>
  {
    let insertoperation: Insertoperation = new Object();

    let layerTable = this.layersConfigService.getLayerTable(layer);

    let layerGeomAttribute = this.layersConfigService.getLayerGeomAttribute(layer);
    let layerIdAttribute = this.layersConfigService.getLayerIdAttribute(layer);

    if(!layerTable)
    {
      throw ("Missing layer table config for this layer.");
    }

    if(!layerGeomAttribute)
    {
      throw ("Missing geom_attribute config for this layer.");
    }

    if(!layerIdAttribute)
    {
      throw ("Missing id_attribute config for this layer.");
    }

    insertoperation.table=layerTable;
    insertoperation.geomattribute=layerGeomAttribute;
    insertoperation.geom=JSON.stringify(editOperation.feature.geometry);
    insertoperation.srid=this.geoJSONDefaultSRID;
    insertoperation.idattribute=layerIdAttribute;

    return this.backendService.insertGeom(insertoperation);
  }

  public deleteGeom(editOperation: EditionOperation, layer: LayerConfig) : Promise<any>
  {
    let deleteoperation: Deleteoperation = new Object();

    let layerTable = this.layersConfigService.getLayerTable(layer);

    let layerIdAttribute = this.layersConfigService.getLayerIdAttribute(layer);

    let idValue = this.getOperationFeatureIdPropertyValue(layerIdAttribute, editOperation);

    if(!layerTable)
    {
      throw ("Missing layer table config for this layer.");
    }

    if(!layerIdAttribute)
    {
      throw ("Missing geom_attribute config for this layer.");
    }

    if(!idValue)
    {
      throw ("Can't find configured id value.");
    }

    deleteoperation.table=layerTable;
    deleteoperation.idattribute=layerIdAttribute;
    deleteoperation.idvalue=idValue.toString();

    return this.backendService.deleteGeom(deleteoperation);
  }

  public updateGeom(editOperation: EditionOperation, layer: LayerConfig) : Promise<any>
  {
    let updateoperation: Updateoperation = new Object();

    let layerTable = this.layersConfigService.getLayerTable(layer);

    let layerGeomAttribute = this.layersConfigService.getLayerGeomAttribute(layer);

    let layerIdAttribute = this.layersConfigService.getLayerIdAttribute(layer);

    let idValue = this.getOperationFeatureIdPropertyValue(layerIdAttribute, editOperation);

    if(!layerTable)
    {
      throw ("Missing layer table config for this layer.");
    }

    if(!layerGeomAttribute)
    {
      throw ("Missing geom_attribute config for this layer.");
    }

    if(!layerIdAttribute)
    {
      throw ("Missing geom_attribute config for this layer.");
    }

    if(!idValue)
    {
      throw ("Can't find configured id value.");
    }

    updateoperation.table=layerTable;
    updateoperation.geomattribute=layerGeomAttribute;
    updateoperation.geom=JSON.stringify(editOperation.feature.geometry);
    updateoperation.idattribute=layerIdAttribute;
    updateoperation.idvalue=idValue.toString();
    updateoperation.srid=this.geoJSONDefaultSRID;

    return this.backendService.updateGeom(updateoperation);
  }

  public getOperationFeatureIdPropertyValue(idAttribute: string, editionOperation: EditionOperation)
  {
    if(editionOperation.feature)
    {
      return editionOperation.feature.properties[idAttribute];
    }
  }

  private getPropertyValueById(attributes: Array<Property>, idField: string)
  {
    let value = null
    attributes.forEach(attribute => {
      if(attribute.key==idField)
      {
        value = attribute.value;
      }
    });
    return value;

  }

  public updateAttributes(properties: Array<Property>, layer: LayerConfig) : Promise<any>
  {
    let attributesUpdate : Updateattributes = new Object();

    let layerTable = this.layersConfigService.getLayerTable(layer);

    let layerGeomAttribute = this.layersConfigService.getLayerGeomAttribute(layer);

    let layerIdAttribute = this.layersConfigService.getLayerIdAttribute(layer);

    let idValue = this.getPropertyValueById(properties, layerIdAttribute);

    if(!layerTable)
    {
      throw ("Missing layer table config for this layer.");
    }

    if(!layerGeomAttribute)
    {
      throw ("Missing geom_attribute config for this layer.");
    }

    if(!layerIdAttribute)
    {
      throw ("Missing geom_attribute config for this layer.");
    }

    if(!idValue)
    {
      throw ("Can't find configured id value.");
    }

    let attributesJSON=JSON.stringify(properties);

    return this.updateTable(layerTable, layerIdAttribute, idValue.toString(), attributesJSON);
  }

  public updateTable(table: string, idAttribute: string, idValue: any, attributesJSON: string ) : Promise<any>
  {
    let attributesUpdate : Updateattributes = new Object();

    attributesUpdate.table=table;
    attributesUpdate.idattribute=idAttribute;
    attributesUpdate.idvalue=idValue;   

    attributesUpdate.attributesJSON=attributesJSON;

    return this.backendService.updateAttributes(attributesUpdate);
  }

  public split(featureId: string, geometry1: string, geometry2: string, layer: LayerConfig ) : Promise<any>
  {

    let layerGeomAttributeName: string = this.layersConfigService.getLayerGeomAttribute(layer);
    let layerIdAttributeName: string = this.layersConfigService.getLayerIdAttribute(layer);
    let layerTableName: string = this.layersConfigService.getLayerTable(layer);

    let promise = new Promise<any>((resolve, reject) => {

      let updateOperation: Updateoperation = new Object();
      updateOperation.geom=geometry1;
      updateOperation.geomattribute=layerGeomAttributeName;
      updateOperation.idvalue=featureId;
      updateOperation.idattribute=layerIdAttributeName;
      updateOperation.table=layerTableName;
      updateOperation.srid=this.geoJSONDefaultSRID;

      this.backendService.updateGeom(updateOperation).then(()=>
      {

        let insertOperation: Insertoperation = new Object();
        insertOperation.geom=geometry2
        insertOperation.geomattribute=layerGeomAttributeName;
        insertOperation.table=layerTableName;
        insertOperation.srid=this.geoJSONDefaultSRID;
        insertOperation.idattribute=layerIdAttributeName;

        this.backendService.insertGeom(insertOperation).then((insertedOperation)=>
      {
        insertOperation['newid'] = insertedOperation.newid;
        resolve({updateOperation: updateOperation , insertOperation: insertOperation});
      }).catch(()=>{
        reject();
      });      

  

      }).catch(()=>{
        reject();
      });      
    });

    return promise;
  }

  public merge(geomId1: string, geomId2: string, geomJSON: string, layer: LayerConfig ) : Promise<any>
  {

    let layerGeomAttributeName: string = this.layersConfigService.getLayerGeomAttribute(layer);
    let layerIdAttributeName: string = this.layersConfigService.getLayerIdAttribute(layer);
    let layerTableName: string = this.layersConfigService.getLayerTable(layer);

    let promise = new Promise<any>((resolve, reject) => {

      let updateOperation: Updateoperation = new Object();
      updateOperation.geom=geomJSON;
      updateOperation.geomattribute=layerGeomAttributeName;
      updateOperation.idvalue=geomId1;
      updateOperation.idattribute=layerIdAttributeName;
      updateOperation.table=layerTableName;
      updateOperation.srid=this.geoJSONDefaultSRID;

      this.backendService.updateGeom(updateOperation).then(()=>
      {
        let deleteOperation: Deleteoperation = new Object();
        deleteOperation.idattribute=layerIdAttributeName;
        deleteOperation.idvalue=geomId2;
        deleteOperation.table=layerTableName;

        this.backendService.deleteGeom(deleteOperation).then(()=>
      {
        resolve(true);
      }).catch(()=>{
        reject();
      });      

      }).catch(()=>{
        reject();
      });      
    });

    return promise;
  }

  public getFeatureAttributes(table: string, idAttribute: string, idValue: string) : Promise<Featureattributes>
  {
    let getfeatureattribute :Getfeatureattributes = new Object();
    getfeatureattribute.idattribute=idAttribute;
    getfeatureattribute.idvalue=idValue;
    getfeatureattribute.table=table;
    
    return this.backendService.getFeatureAttributes(getfeatureattribute);
  }
  

  
  
} 