
export class UserGroup 
{
    static readonly NONE: UserGroup = new UserGroup(0, "Nenhum");
    static readonly ADMINISTRATOR: UserGroup = new UserGroup(1, "Administração");
    static readonly EDITION: UserGroup = new UserGroup(2, "Edição");
    static readonly READONLY: UserGroup = new UserGroup(3, "Consulta");    
    static readonly DOC_EDITION: UserGroup = new UserGroup(4, "Gestão de Documentos");
    static readonly SEHAB_READONLY: UserGroup = new UserGroup(5, "Consulta SEHAB");
    static readonly SEHAB_EDITION: UserGroup = new UserGroup(6, "Editor SEHAB");
    //static readonly USER_GROUPS: Array<UserGroup> = [UserGroup.NONE, UserGroup.ADMINISTRATOR, UserGroup.EDITION, UserGroup.READONLY, UserGroup.PGV_COLETOR];
    static readonly USER_GROUPS: Array<UserGroup> = [UserGroup.NONE, UserGroup.ADMINISTRATOR, UserGroup.EDITION, UserGroup.DOC_EDITION, UserGroup.SEHAB_READONLY, UserGroup.SEHAB_EDITION, UserGroup.READONLY];

    id:number;
    name:string;

    constructor(id: number, name: string)
    {
        this.id = id;
        this.name = name;
    }

    public static getUserGroupById(id: number) : UserGroup
    {
        let outUserGroup =null;
        this.USER_GROUPS.forEach(userGroup => {
            if(userGroup.id==id)
            {
                outUserGroup=userGroup;
            }
        });

        if(!outUserGroup)
        {
            outUserGroup = UserGroup.NONE;
        }

        return outUserGroup;
    }
}
