import { MatDialog } from '@angular/material/dialog';
import { Relatorioareairregularconfig } from '@funcate/sigweb-cti-api';
import { DialogComponent } from 'src/app/map/dialog/dialog.component';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';
import { LayersInfo } from 'src/app/map/vo/layersinfo';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { BackendService } from 'src/app/service/backend.service';
import { CertidoesService } from 'src/app/service/certidoes.service';
import { FeatureService } from 'src/app/service/feature.service';
import { GeneralService } from 'src/app/service/general.service';
import { ToastService } from 'src/app/service/toast.service';
import { environment } from 'src/environments/environment';
import { Feature } from '../../../vo/feature';
import { FeatureInfoTool } from '../../fetureinfo-tool/featureinfo-tool';
import { FetureInfoToolComponent } from '../../fetureinfo-tool/fetureinfo-tool.component';
import { Tool } from '../../tool';
import { GenericFeatureReport } from './generic-feature-report';
import { AbstractDocumentType } from '../../../vo/document/abstract-document-type';
import { AppNotification } from 'src/app/map/vo/notification';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/service/notification.service';

export class SettlementFeatureReport extends GenericFeatureReport
{
    customFeatureReportId: string;
    downloading: boolean;
    mapLayerAppConfig: string = "settlement-feature-report-map";
    mapWidth: number=1200
    mapHeight: number=600
    notificationSubscrition: Subscription;
    parent: any;

    constructor(toastService: ToastService,
         authenticationService: AuthenticationService,
          generalService: GeneralService,          
         layerConfigService: LayersConfigService,
          certidoesService: CertidoesService,
           featureService: FeatureService,
           notificationService: NotificationService)
    {
        super(toastService, authenticationService, generalService, layerConfigService, certidoesService, featureService, notificationService);
        this.customFeatureReportId = "settlement-feature-report";        
        
        this.addDownloadFinishedListener();
    }
    addDownloadFinishedListener()
    {
      this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
        if (notification.event==AppNotification.ON_DOWNLOAD_FINISHED_EVENT)
        {
            console.log("Download from '"+notification.object.url+"' is done to out file '"+notification.object.outputFilename+"'.");
            this.downloading = false;
            this.toastService.sucess("Sucesso baixando o arquivo solicitiado.", "Sucesso");
            this.parent.hideGeneration();
            
        } else if (notification.event==AppNotification.ON_DOWNLOAD_ERROR_EVENT)
        {
            console.log("Failed downloading from '"+notification.object.url+"' is done to out file '"+notification.object.outputFilename+"'.");
            this.downloading = false;
            this.toastService.error("Falha ao realizar o download do relatório solicitado.", "Error");
            this.parent.hideGeneration();            
        } 
    });
    }
    generateReport(feature: Feature, layerConfig: LayerConfig, dialog: any)
    {
        this.parent=dialog;

        let user = this.authenticationService.getCurrentUser();
        if(!user)
        {
          this.toastService.error("Usuário não está autenticado corretamente. Efetue o login novamente.", "Problemas ao gerar certidão.");
        }
        else
        {
          let certidaoConfig = this.createRelatorioAreaIrregular(feature, layerConfig);

          if(certidaoConfig==null)
          {
            this.toastService.error("Não existe camadas configuradas pro relatório para exibição de mapa base e ortofo.", "Error");
            return;
          }

          let fileDate = this.generalService.formatDate(new Date(), "dd-MM-yyyy_HH-mm");

          let filename = "relatorio-area-irregular-"+certidaoConfig['id_value']+"-"+fileDate+".zip";
          let url = environment.BACKEND_API_BASE_PATH+'/relatorios/area/irregular';
          this.downloading = true;
          this.generalService.downloadFilePostMethod(url, certidaoConfig, filename, user.token);
        }

        console.log("Generating settlement report.")
    }
    private createRelatorioAreaIrregular(feature: Feature, layerConfig: LayerConfig) : Relatorioareairregularconfig
    {
      let mapLayers = this.layerConfigService.getLayersWithAppConfig(this.mapLayerAppConfig);
      
      let layerTableName = this.layerConfigService.getLayerTable(layerConfig);

      let idAttribute=this.layerConfigService.getLayerIdAttribute(layerConfig);

      let properties = this.featureService.getWFSPropertiesObjectAsPropertyArray(feature.properties);
      let idValueProperty = this.featureService.getPropertyByKey(properties, idAttribute);
  
      if(mapLayers.length>0)
      {
        let mapURL = this.certidoesService.getMapURL(feature, mapLayers, this.mapWidth, this.mapHeight);
  
        let config: Relatorioareairregularconfig = new Object();
        config['table']=layerTableName;
        config['id_attribute']=idAttribute;
        config['id_value']=idValueProperty.value;
        config['url_mapa']=mapURL;
        config['tipo_documentos_gerais']=AbstractDocumentType.SETTLEMENT_DOCUMENTS;
        config['tipo_documentos_projetos']=AbstractDocumentType.SETTLEMENT_PROJECTS_DOCUMENTS;
        config['tipo_documentos_gravames']=AbstractDocumentType.SETTLEMENT_JUDICIAL_DOCUMENTS;
  
        return config;
      }
      console.log("Missing configuration on layers: " + this.mapLayerAppConfig);
      return null;
    }
}
