import { Userinfo } from "@funcate/sigweb-cti-api";
import { environment } from "src/environments/environment";

export const BASELAYERS_INITIAL_ZINDEX: number=200;
export const OVERLAYERS_INITIAL_ZINDEX: number=201;

export class LayerConfig
 {
    constructor(id: string, name: string, workspace: string, title: string, description: string, enabled: boolean, source: string, 
        path: string, style: string, applicationconfigs: Array<any>, layertools: Array<any>, permission: any)
    {
        this.id = id;    
        this.name = name;
        this.workspace = workspace;
        this.title = title;
        this.description = description;
        this.enabled = enabled;
        this.source = source;
        this.path = path;
        this.applicationconfigs = applicationconfigs;
        this.layertools = layertools;
        this.style = style;
        this.permission = permission;
        this.styleTitle=title;
        this.hidden = false;
        this.memory = false;
        this.parseBounds();
    }

    private id: string;
    private name: string;
    private workspace: string;
    private title: string;
    private description: string;
    private enabled: boolean;
    private source: string;
    private path: string;
    private style: string;
    private styleTitle: string;
    private applicationconfigs: Array<any>;
    private layertools: Array<any>;
    private permission: LayerPermission;
    private cqlfilter: string;
    private hidden: boolean;
    private memory: boolean;
    private bounds: 
    {
        lowerLeft: {lat: any, lng: any},
        upperRight: {lat: any, lng: any}
    };    

    public getId(): string {
        return this.id;
    }

    public setId(id: string): void {
        this.id = id;
    }

    public getName(): string {
        return this.name;
    }

    public setName(name: string): void {
        this.name = name;
    }

    public getWorkspace(): string {
        return this.workspace;
    }

    public setWorkspace(workspace: string): void {
        this.workspace = workspace;
    }

    public getTitle(): string {
        return this.title;
    }

    public setTitle(title: string): void {
        this.title = title;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(description: string): void {
        this.description = description;
    }

    public isEnabled(): boolean {
        return this.enabled;
    }

    public setEnabled(enabled: boolean): void {
        this.enabled = enabled;
    }

    public getSource(): string 
    {
        if(this.source)
        {
            return this.source;
        }
        else
        {
            return environment.GEOSERVER_URL_DOMAIN;
        }
        
    }

    public setSource(source: string): void {
        this.source = source;
    }

    public getPath(): string {
        return this.path;
    }

    public setPath(path: string): void {
        this.path = path;
    }

    public getApplicationconfigs(): Array<any> {
        return this.applicationconfigs;
    }

    public setApplicationconfigs(applicationconfigs: Array<any>): void {
        this.applicationconfigs = applicationconfigs;
    }

    public getLayertools(): Array<any> {
        return this.layertools;
    }

    public setLayertools(layertools: Array<any>): void {
        this.layertools = layertools;
    }


    public getStyle()
    {
        return this.style;
    }

    public setStyle(style: string)
    {
        this.style = style;
    }

    public getPermission() : LayerPermission
    {
        return this.permission;
    }

    public getCqlfilter(): string {
        return this.cqlfilter;
    }

    public setCqlfilter(cqlfilter: string): void {
        this.cqlfilter = cqlfilter;
    }

    public isReadableForUser(userInfo: Userinfo) : boolean
    {
        let layerPermission = this.getPermission();
        if(layerPermission.public)
        {
          return true;
        }
        else
        {
            if(userInfo)
            {
                for (let i = 0; i < layerPermission.usergroups.length; i++) {
                    const userGroup = layerPermission.usergroups[i];

                    if(userGroup.read && 
                      userGroup.id==userInfo["usergroup_id"])
                    {
                        return true;
                    }
                  }
            }            
        }
        
        return false;
    }

    public isWritableForUser(userInfo: Userinfo) : boolean
    {
        let layerPermission = this.getPermission();
        if(layerPermission.public)
        {
            
          return true;
        }
        else
        {
            for (let i = 0; i < layerPermission.usergroups.length; i++) {
                const userGroup = layerPermission.usergroups[i];
                
                if(userGroup.write && 
                userGroup.id==userInfo["usergroup_id"])
                {
                    
                    return true;
                }
          }
          
        }
        
        return false;
    }
    private parseBounds()
    {
        if(this.applicationconfigs.length>0 && this.applicationconfigs[0].bounds)
        {
            let bounds = this.applicationconfigs[0].bounds;
            if(bounds)
            {
                if(bounds.lowercorner && bounds.uppercorner)
                {
                    //Lower Left
                    let lls = bounds.lowercorner.split(" ");
                    //Upper Right
                    let urs = bounds.uppercorner.split(" ");
                    if(lls.length==2 || urs.length==2)
                    {
                        this.bounds = {
                            lowerLeft: {
                                lat: lls[1],
                                lng: lls[0]
                            },
                            upperRight: {
                                lat: urs[1],
                                lng: urs[0]
                            }
                        }
                    }                
                }
            }
        }
        return null;
    }

    public getBounds()
    {
        return this.bounds;
    }

    public setStyleTitle(styleTitle: string)
    {
        this.styleTitle = styleTitle;
    }    

    public getStyleTitle()
    {
        return this.styleTitle;
    }   
    
    public isHidden() : boolean
    {
        return this.hidden;
    }

    public setHidden(hidden: boolean)
    {
        this.hidden = hidden;
    }

    public isMemory() : boolean
    {
        return this.memory;
    }

    public setMemory(memory: boolean)
    {
        this.memory = memory;
    }

}



export interface LayerPermission
{
    public: boolean;
    usergroups: UserGroupPermission[]

}

export interface UserGroupPermission
{
    id: number;
    read: boolean;
    write: boolean;
}