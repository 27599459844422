export class GeomType
 {
    private static geomTypes : Map<string, GeomType> = new Map<string, GeomType>();
    static Point: GeomType = new GeomType(1, "Point");
    static Line: GeomType = new GeomType(2, "Line");
    static Polygon: GeomType = new GeomType(4, "Polygon");
        
    id: number;
    name: string;

    constructor(id: number, name: string)
    {
        this.id=id;
        this.name=name;
        GeomType.geomTypes[name] = this;
    }

    static getGeomTypeByName(name: string)
    {
        let geomType: GeomType = this.geomTypes[name];
        return geomType;
    }

    static getMultipleGeomType(type: GeomType) : string
    {
      if(type==GeomType.Point)
      {
        return "MultiPoint";
      } else if(type==GeomType.Line)
      {
        return "MultiLineString";
      } else if(type==GeomType.Polygon)
      {
        return "MultiPolygon";
      }
    }

}
