
<mat-list *ngIf="hasLegend">
    <mat-divider></mat-divider>
    <div #allOverlayers *ngFor="let layer of overlayers;">
        <mat-list-item *ngIf="layer.isEnabled() && isLayerReadableForUser(layer)" class="legend-item">
            <div class="legend-div">


     
                <mat-divider></mat-divider>

                <mat-accordion>
                    <mat-expansion-panel [expanded]="false">
                        <mat-expansion-panel-header >
                            <mat-panel-title>
                                <div class="legend-text-div">
                                    <div class="legend-text">
                                        <div>
                                            <mat-icon class="material-icons layer-icon">layers</mat-icon>
                                        </div>
                                        <div>
                                            <h3 matLine> {{layer.getName()}} </h3>
                                            <p matLine>
                                                <span> {{layer.getTitle()}} </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </mat-panel-title>
                            <mat-panel-description>
                  
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        
                        <div class="legend-img-div" title="{{layer.getStyleTitle()}}">
                            <img matListAvatar src="{{layer.getSource()}}{{layer.getPath()}}?request=GetLegendGraphic&format=image/png&width=20&height=20&service=WMS&layer={{layer.getId()}}&style={{layer.getStyle()}}" alt="Legenda para a camada {{layer.getTitle()}}">
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
           
               
               
                

            </div>
        </mat-list-item>
        <mat-divider *ngIf="layer.isEnabled()"></mat-divider>
    </div>
</mat-list>