import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { AppNotification } from '../map/vo/notification';

import { AuthenticationService } from './authentication.service';
import { ToastService } from './toast.service';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root'
  })

export class GeneralService {
   
    private notificationService: NotificationService;


    constructor(private http: HttpClient, notificationService: NotificationService ) 
    {
      this.notificationService = notificationService;
    }

    public downloadFileGetMethod(url:string, outputFilename: string, authToken: string = "")
    {
      let httpOptions = {};
              
      if(authToken)
      {
        let header = new HttpHeaders().set('Authorization', 'Bearer ' + authToken );
       
        httpOptions = {
          headers: header
        };        
      }

      httpOptions['observe'] = 'body';
      httpOptions['params'] = {};
      httpOptions['reportProgress'] = false;
      httpOptions['responseType'] = 'blob';

      this.http.get(url, httpOptions).subscribe((res:any ) => {
      
        const file = new Blob([res],{
          type: res.type
        });
        const blob = window.URL.createObjectURL(file);
        const link =  document.createElement('a');
        link.href = blob;
        link.download = `${outputFilename}`
        link.click();
        window.URL.revokeObjectURL(blob);
        link.remove();
        this.notifyDownloadDone(url, outputFilename);        
      },(error)=>{

        this.notifyDownloadError(url, outputFilename, error);        
      });
    }

    public downloadFilePostMethod(url:string, body: any, outputFilename: string, authToken: string = "")
    {
      let httpOptions = {};
              
      if(authToken)
      {
        let header = new HttpHeaders().set('Authorization', 'Bearer ' + authToken );
       
        httpOptions = {
          headers: header
        };        
      }

      httpOptions['observe'] = 'body';
      httpOptions['params'] = {};
      httpOptions['reportProgress'] = false;
      httpOptions['responseType'] = 'blob';

      this.http.post(url, body, httpOptions).subscribe((res:any ) => {
      
        const file = new Blob([res],{
          type: res.type
        });
        const blob = window.URL.createObjectURL(file);
        const link =  document.createElement('a');
        link.href = blob;
        link.download = `${outputFilename}`
        link.click();
        window.URL.revokeObjectURL(blob);
        link.remove();
        this.notifyDownloadDone(url, outputFilename);        
      },(error)=>{

        this.notifyDownloadError(url, outputFilename, error);        
      });
    }

    public notifyDownloadDone(url: string, outputFilename: string)
    {
      let download: any = new Object();
      download.url = url;
      download.outputFilename = outputFilename;
      let notification = new AppNotification(AppNotification.ON_DOWNLOAD_FINISHED_EVENT, download);
      this.notificationService.send(notification);
    }

    public notifyDownloadError(url: string, outputFilename: string, error: any)
    {
      let download: any = new Object();
      download.url = url;
      download.error = error;
      download.outputFilename = outputFilename;
      let notification = new AppNotification(AppNotification.ON_DOWNLOAD_ERROR_EVENT, download);
      this.notificationService.send(notification);
    }



    public getDataTypeByFileExtension(extension: string)
    {
      let dataType: string = "";
      if(extension=='pdf')
      {
        dataType = 'application/pdf';
      } else if(extension=='jpg')
      {
        dataType = 'image/jpg';
      } else if(extension=='png')
      {
        dataType = 'image/png';
      }else if(extension=='tif')
      {
        dataType = 'image/tif';
      }else if(extension=='doc' || extension=='docx')
      {
        dataType = 'application/msword';
      }

      return dataType;
    }

      /**
   * Adiciona zero na frente de cada numero da inscrição de maneira recursiva.
   * @param length quantidade máxima de digitos 
   * @param value valor atual
   */
  public fillZero(length: number, value: string)
  {
    if(value.length!=length)
    {
      if(value.length<length)
      {
        value="0"+value;
        return this.fillZero(length,value);
      }
    }
    return value;

  }

  public trimSize(limit: number, value: string)
  {
    if(value!=null)
    {
      value = value.toString();
      value = value.substring(0, limit);

    }
    
    return value;
  }

  public removeNAN(value: string)
  {
    if(value!=null)
    {
      value = value.replace(/\D/g, '');
    }
    return value;
  }
  public formatDate(date: Date, format: string) : string
  {
    let data="";
    if(date)
    {
      let datePipe = new DatePipe('pt-BR');
      data = datePipe.transform(date.toString(), format);
    }
 

    return data;
  }

  public removeAccents(string: string){
    let r=string.toLowerCase();
    r = r.replace(new RegExp("\\s", 'g'),"");
    r = r.replace(new RegExp("[àáâãäå]", 'g'),"a");
    r = r.replace(new RegExp("æ", 'g'),"ae");
    r = r.replace(new RegExp("ç", 'g'),"c");
    r = r.replace(new RegExp("[èéêë]", 'g'),"e");
    r = r.replace(new RegExp("[ìíîï]", 'g'),"i");
    r = r.replace(new RegExp("ñ", 'g'),"n");                            
    r = r.replace(new RegExp("[òóôõö]", 'g'),"o");
    r = r.replace(new RegExp("œ", 'g'),"oe");
    r = r.replace(new RegExp("[ùúûü]", 'g'),"u");
    r = r.replace(new RegExp("[ýÿ]", 'g'),"y");
    r = r.replace(new RegExp("\\W", 'g'),"");
    return r;
};


} 