import { Coordinate } from "ol/coordinate";

export class EditionSegment
 {
    index: number;
    distance: number;
    angle: number;
    firstCoordinate: Coordinate;
    lastCoordinate: Coordinate;
    changed: boolean;

    constructor(index: number, distance: number, angle: number, firstCoordinate: Coordinate, lastCoordinate: Coordinate)
    {
        this.index=index;
        this.distance=distance;
        this.angle=angle;
        this.firstCoordinate=firstCoordinate;
        this.lastCoordinate=lastCoordinate;
    }

}
