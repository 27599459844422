import {GeomType} from './geomtype';
import { Operation } from './operation';

export class EditionOperation 
{
    id: any;
    feature: any;
    geomType: GeomType;
    operation: Operation;

    constructor(id: any, feature: any, geomType: GeomType, operation: Operation)
    {
        this.id=id;
        this.feature=feature;
        this.geomType=geomType;
        this.operation=operation;
    }

}
