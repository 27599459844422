import { Component, Inject, Input, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Certidaoemplacamentoconfig, Certidoescamadasconfig, Userinfo } from '@funcate/sigweb-cti-api';
import { Subscription } from 'rxjs';
import { LayersConfigService } from '../../../layers/layers.config.service';
import { BackendService } from '../../../../service/backend.service';
import { ToastrService } from 'ngx-toastr';
import { ToolsService } from '../../tools.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { GeneralService } from 'src/app/service/general.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../../../vo/notification';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { Canvas } from 'leaflet';
import * as L from 'leaflet';
import { FeatureService } from 'src/app/service/feature.service';
import { Inscricao } from 'src/app/map/vo/inscricao';
import { CertidoesService } from 'src/app/service/certidoes.service';


@Component({
  selector: 'app-generate-certidao-emplacamento',
  templateUrl: './generate-certidao-emplacamento.component.html',
  styleUrls: ['./generate-certidao-emplacamento.component.css']
})
export class GenerateCertidaoEmplacamentoComponent implements OnInit {

  tool:any;
  subscriptionTool:Subscription;
  @Input() ics: string;
  @Input() feature: any;
  @Input() featureSRID: string;
  @Input() lotesLayer: LayerConfig;
  ic: string;
  interessado: string;
  numero_doc: string;
  processo: string;
  loteamento: string;
  quadra: string;
  lote: string;
  notificationSubscrition: Subscription;
  map1LayerAppConfig: string = "certidao_emplacamento_mapa1";
  map2LayerAppConfig: string = "certidao_emplacamento_mapa2";
  map1Layers: LayerConfig[]=[];
  map2Layers: LayerConfig[]=[];
  map1Width: number=1200
  map1Height: number=600
  map2Width: number=1200
  map2Height: number=600
  downloading: boolean = false;
  selectedLoteFeatureNeighborhoods: any;

  constructor(private notificationService: NotificationService, private toastService: ToastrService,
   private toolsService: ToolsService, private featureService: FeatureService,
    private backendService: BackendService, private layersConfigService: LayersConfigService, 
    private authenticationService: AuthenticationService, private generalService: GeneralService, private certidoesService: CertidoesService) {

    //this.inscricao=data.inscricao
    this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (tool:any) => {
        this.tool=tool;
      }
    );

    this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
      if (notification.event==AppNotification.ON_DOWNLOAD_FINISHED_EVENT)
      {
          console.log("Download from '"+notification.object.url+"' is done to out file '"+notification.object.outputFilename+"'.");
          this.downloading = false;
      } 
  });
    
   }

  ngOnInit(): void 
  {    
    if(this.ics.length==1)
    {
      this.ic=this.ics[0];      
    }       
  }

  createCertidaoEmplacamentoConfig() : Certidaoemplacamentoconfig
  {
    this.map1Layers = this.layersConfigService.getLayersWithAppConfig(this.map1LayerAppConfig);
    this.map2Layers = this.layersConfigService.getLayersWithAppConfig(this.map2LayerAppConfig);

    if(this.map1Layers.length>0 && this.map2Layers.length>0)
    {
      let bounds = null;
      if(this.selectedLoteFeatureNeighborhoods)    
      {
        bounds = this.featureService.getFeaturesBounds(this.selectedLoteFeatureNeighborhoods);
      }      

      let map1URL = this.certidoesService.getMapURL(this.feature, this.map1Layers, this.map1Width, this.map1Height, bounds);
      let map2URL = this.certidoesService.getMapURL(this.feature, this.map2Layers, this.map2Width, this.map2Height, bounds);

      console.log(map1URL);
      console.log(map2URL);

      let inscricao : Inscricao = new Inscricao(this.ic);

      let certidaoEmplacamentoConfig: Certidaoemplacamentoconfig = new Object();
      certidaoEmplacamentoConfig['numero_doc']=this.numero_doc;
      certidaoEmplacamentoConfig['processo']=this.processo;
      certidaoEmplacamentoConfig['interessado']=this.interessado;
      certidaoEmplacamentoConfig['loteamento']=" ";
      certidaoEmplacamentoConfig['quadra']=inscricao.quadra;
      certidaoEmplacamentoConfig['lote']=inscricao.lote;
      certidaoEmplacamentoConfig['inscricao']=this.ic;
      certidaoEmplacamentoConfig['url_mapa1']=map1URL;
      certidaoEmplacamentoConfig['url_mapa2']=map2URL;

      return certidaoEmplacamentoConfig;
    }  
  }  

  generate()
  {
    if(!this.interessado)
    {
      this.toastService.error("Favor selecionar uma inscrição.")
    }
    else
    if(!this.interessado)
    {
      this.toastService.error("Favor preencher o campo interessado.")
    }
    else  if(!this.numero_doc)
    {
      this.toastService.error("Favor preencher o número do documento.")
    }
    else  if(!this.processo)
    {
      this.toastService.error("Favor preencher o número do processo.")
    } else
    {
      let user = this.authenticationService.getCurrentUser();
      if(!user)
      {
        this.toastService.error("Usuário não está autenticado corretamente. Efetue o login novamente.", "Problemas ao gerar certidão.");
      }
      else
      {
        this.downloading = true;
        this.generatePolygonNeighborhood(user);        
      }
    }
  } 

  private generatePolygonNeighborhood(user: Userinfo)
  {
    this.featureService.getPolygonNeighborhood(this.lotesLayer, '', this.feature.geometry).then(lotesFeatures => 
      {                                
        if(lotesFeatures && lotesFeatures.length>0)
        {        
          this.selectedLoteFeatureNeighborhoods = lotesFeatures;          
        }
        //Se conseguir buscar os vizinhos
        this.generateCertidao(user);
        
      }
    ).catch(()=>
    {
      //Se não conseguir buscar os vizinhos
      this.generateCertidao(user);
    });
  }
  private generateCertidao(user: Userinfo)
  {
    let certidaoConfig = this.createCertidaoEmplacamentoConfig();

    let filename = "certidao-emplacamento-"+this.ic+".docx";
    let url = environment.BACKEND_API_BASE_PATH+'/certidoes/emplacamento/get';
    
    this.generalService.downloadFilePostMethod(url, certidaoConfig, filename, user.token);
  }
 
}

