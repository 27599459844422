import { Component, Inject, Input, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Certidoescamadasconfig } from '@funcate/sigweb-cti-api';
import { Subscription } from 'rxjs';
import { LayersConfigService } from '../../../layers/layers.config.service';
import { BackendService } from '../../../../service/backend.service';
import { ToastrService } from 'ngx-toastr';
import { ToolsService } from '../../tools.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { GeneralService } from 'src/app/service/general.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../../../vo/notification';


@Component({
  selector: 'app-generate-certidao-uso',
  templateUrl: './generate-certidao-uso.component.html',
  styleUrls: ['./generate-certidao-uso.component.css']
})
export class GenerateCertidaoUsoComponent implements OnInit {

  tool:any;
  subscriptionTool:Subscription;
  @Input() ics: string;
  ic: string;
  solicitante: string;
  notificationSubscrition: Subscription;
  downloading: boolean = false;

  constructor(private notificationService: NotificationService, private toastService: ToastrService,
   private toolsService: ToolsService, private authenticationService: AuthenticationService, private generalService: GeneralService) {

    this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (tool:any) => {
        this.tool=tool;
      }
    );

    this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
      if (notification.event==AppNotification.ON_DOWNLOAD_FINISHED_EVENT)
      {
          console.log("Download from '"+notification.object.url+"' is done to out file '"+notification.object.outputFilename+"'.");
          this.downloading = false;
      } 
  });
    
   }

  ngOnInit(): void 
  {    
    if(this.ics.length==1)
    {
      this.ic=this.ics[0];
    }
  }

  generate()
  {
    if(this.solicitante)
    {
      if(this.ic)
      {
        let user = this.authenticationService.getCurrentUser();
        if(!user)
        {
          this.toastService.error("Usuário não está autenticado corretamente. Efetue o login novamente.", "Problemas ao gerar certidão.");
        }
        else
        {
          let filename = "certidao-uso-"+this.ic+".docx";
          let url = environment.BACKEND_API_BASE_PATH+'/certidoes/uso/get/' + this.ic + "?solicitante=" + encodeURI(this.solicitante);
          this.downloading = true;
          this.generalService.downloadFileGetMethod(url, filename, user.token);
        }
        
      }
      else
      {
        this.toastService.error("Favor selecionar uma inscrição.")
      }
    }
    else
    {
      this.toastService.error("Favor preencher o campo Solicitante.")
    }
  }
 
}
