import { CanvasService } from '../../canvas/canvas.service';
import { LayerConfig } from '../../layers/layers.config';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { Tool } from '../tool';
import { ToolsService } from '../tools.service';
import { CapabilitiesService } from '../../../service/capabilities.service';
import { i18nMetaToJSDoc } from '@angular/compiler/src/render3/view/i18n/meta';
import { LayersConfigService } from '../../layers/layers.config.service';

export class FitLayerTool extends AbstractLayerTool {

    cursorType='tool-cursor-wait';
    id='fit-layer-tool';
    enabled=false;
    protected visible:boolean=true;
    name='Fit Layer Tool';
    title='Ajustar mapa à camada';
    type='click';
    icon='fit_screen';
    layerTool: boolean=true;
    customParameter=new Map<String, any>();
    
    constructor(private canvasService: CanvasService, toolsService: ToolsService, private capabilitiesService: CapabilitiesService, private layerConfigService: LayersConfigService) {
        super(toolsService)
    }

    public execAction=(event:L.LeafletMouseEvent)=>{}

    public enableImpl()
    {
        this.fitLayer();
        this.toolsService.toogleTool(this);
    }
    public disableImpl()
    {
    }

    private fitLayer()
    {
        let currentLayerTool: LayerConfig = this.toolsService.getToolParameter(this, "layer");
        
        if(currentLayerTool)
        {
            this.layerConfigService.enableLayer(currentLayerTool);
            this.canvasService.fitLayer(currentLayerTool);
        }   
        
    }

    public isVisibleForLayer(layer :LayerConfig) : boolean
    {
        return this.layerTool;
    }

    public isEnabled(layer :LayerConfig) : boolean
    {
        let currentLayerTool = this.toolsService.getToolParameter(this, "layer");
        if(this.enabled && currentLayerTool.getId()==layer.getId() )
        {
            return true;
        }
        else
        {
            return false;
        }
    }
}
