import { CanvasService } from '../../canvas/canvas.service';
import { Tool } from '../tool';
import { ToolsService } from '../tools.service';

export class MeasureTool extends Tool {

    cursorType=null;
    id='measure-tool';
    enabled=false;
    name='Measure Tool';
    title='Ferramenta de Medida';
    type='click';
    protected visible:boolean=true;
    icon='straighten';
    canvasService: CanvasService;
    layerTool: boolean=false;
    customParameter=new Map<String, any>();

    constructor(toolsService: ToolsService, canvasService: CanvasService) 
    { 
        super(toolsService)
        this.canvasService=canvasService;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{

    };

    
    public enableImpl()
    {
        this.canvasService.toogleMeasureControl(true);
    }
    public disableImpl()
    {
        this.canvasService.toogleMeasureControl(false);
    }
}
