<mat-accordion class="edit-fotos-fachada-accordion">
    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="layername">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header">
                    <mat-icon class="material-icons layer-icon">layers</mat-icon>{{acao}} Fotos de Fachadas
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
                <div class="form-line">
                    <div *ngIf="new" class="half-width">
                                <mat-label> Arquivo: </mat-label>
                                <div class="fotos-fachada-upload">
                                    <input type="file" name="file" (change)="onFileSelect($event)" accept="image/png, image/jpeg"/>
                                </div>
                        </div>
                    <div>
                            <mat-form-field appearance="fill">
                                <mat-label>Data da captura:</mat-label>
                                <input matInput [matDatepicker]="picker" class="property-value"  [(ngModel)]="dataCaptura" [max]="maxDate" required>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker> 
                            </mat-form-field>
                        </div>
                </div>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>

<div class="buttons-div">
    <div class="save-button">
        <button mat-raised-button color="primary" (click)="save()">Salvar</button>

    </div>
    <div class="cancel-button">
        <button mat-raised-button color="basic" (click)="close()">Cancelar</button>
    </div>
</div>