<mat-accordion class="edit-historico-accordion">
    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="edit-historico-panel">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header" class="layers-col">
                    <mat-icon class="material-icons layer-icon">format_list_numbered</mat-icon>Adicionar Histórico de Processo
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
              
            </mat-expansion-panel-header>
            <div class="form">
                  <mat-form-field appearance="fill">
                    <mat-label>Processo </mat-label>
                    <input matInput type="text" class="property-value"  [(ngModel)]="historico.processo" required>
                 </mat-form-field>   
                 <mat-form-field appearance="fill">
                  <mat-label>Observações </mat-label>
                  <input matInput type="text" class="property-value"  [(ngModel)]="historico.observacoes" >
               </mat-form-field>  
               <mat-form-field appearance="fill">
                <mat-label>Assunto </mat-label>
                <input matInput type="text" class="property-value"  [(ngModel)]="historico.assunto" >
             </mat-form-field>    

               <div class="salvar-button-div">
                <button type="button" (click)="save()" mat-raised-button color="primary" id="save-button">
                    <span class="material-icons">save</span> Salvar
                </button>

              </div>  
            </div> 

            
            </mat-expansion-panel>

    </ng-container>
</mat-accordion>