<mat-accordion>    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="">
            <mat-expansion-panel-header>
                <mat-panel-title id="user-header" class="user-col">
                    <mat-icon class="material-icons">search</mat-icon> Filtro
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-card>
                <mat-card-header>
                    <mat-card-title> Documentos </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <form id="ficha-search-tool" [formGroup]="fichaSearchControl" (ngSubmit)="onFormSubmit()">
                        <div class="filtro-fichas">
                            <div class="ficha-id">
                                <mat-form-field>
                                    <input matInput
                                    placeholder="Id:"
                                    name="id"
                                    formControlName="id"
                                    value="" (blur)="validateLength('id', $event)">
                                </mat-form-field>
                            </div>
                            <div  class="ficha-logradouro">
                                <mat-form-field>
                                    <input matInput
                                    placeholder="Logradouro:"
                                    name="logradouro"
                                    formControlName="logradouro"
                                    value="" (blur)="validateLength('logradouro', $event)">
                                </mat-form-field>
                            </div>
                            <div class="ficha-nome-arquivo">
                                <mat-form-field>
                                    <input matInput
                                    placeholder="Nome Arquivo:"
                                    name="nomeArquivoCompleto"
                                    formControlName="nomeArquivoCompleto"
                                    value="">
                                </mat-form-field>
                            </div>    
                            <div class="filtro-fichas-desassociadas">
                                <mat-checkbox formControlName="desassociadas">Somente documentos desassociadas</mat-checkbox>
                            </div>
                        </div>

                        <div class="filtro-botoes">  
                            <button type="submit" mat-raised-button color="primary">
                                <span class="material-icons">search</span> Pesquisar
                            </button>
                            &nbsp;
                            <button type="button" (click)="clearFields()" mat-raised-button color="primary">
                                <span class="material-icons">backspace</span> Limpar
                            </button>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
  
 
  <mat-accordion>    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="">
            <mat-expansion-panel-header>
                <mat-panel-title id="user-header" class="user-col">
                    <mat-icon class="material-icons">view_list</mat-icon> Resultados da Pesquisa
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <button type="button" (click)="salvarSelecionados()" class="button-salvar-fichas" title="Associar documentos selecionados à inscrição" mat-raised-button color="primary" *ngIf="fichasSelecionadas.size>0">
                    <span class="material-icons">save</span> Associar Documentos Selecionados
                </button>
                <button type="button" (click)="salvarSelecionados()" class="button-salvar-fichas" title="Selecione ao menos um documento para associação à inscrição" mat-raised-button color="primary" disabled *ngIf="fichasSelecionadas.size==0">
                    <span class="material-icons">save</span> Associar Documentos Selecionados
                </button>
                    <table mat-table [dataSource]="fichasPesquisadas" class="mat-elevation-z8 example-full-width">

                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> Id </th>
                            <td mat-cell *matCellDef="let ficha"> {{ficha.id}} </td>
                        </ng-container>

                        <ng-container matColumnDef="idLogradouro">
                            <th mat-header-cell *matHeaderCellDef> Código Logradouro </th>
                            <td mat-cell *matCellDef="let ficha"> {{ficha.id_logradouro}} </td>
                        </ng-container>

                        <ng-container matColumnDef="nomeLogradouro">
                            <th mat-header-cell *matHeaderCellDef> Nome Logradouro </th>
                            <td mat-cell *matCellDef="let ficha"> {{ficha.nome_logradouro}} </td>
                        </ng-container>


                        <ng-container matColumnDef="numeroOuComplemento">
                            <th mat-header-cell *matHeaderCellDef> Número </th>
                            <td mat-cell *matCellDef="let ficha"> {{ficha.numero_ou_complementos}} </td>
                        </ng-container>
                        
                        <!-- Weight Column -->
                        <ng-container matColumnDef="nomeArquivoCompleto">
                            <th mat-header-cell *matHeaderCellDef> Arquivo Origem </th>
                            <td mat-cell *matCellDef="let ficha"> {{ficha.nome_arquivo_completo}} </td>
                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="tipo">
                            <th mat-header-cell *matHeaderCellDef> Tipo Arquivo </th>
                            <td mat-cell *matCellDef="let ficha"> {{ficha.tipo}} </td>
                        </ng-container>

                        <!-- Weight Column -->
                        <ng-container matColumnDef="associada">
                            <th mat-header-cell *matHeaderCellDef> Associado </th>
                            <td mat-cell *matCellDef="let ficha"> <mat-icon *ngIf="ficha.associada==true">check</mat-icon><mat-icon *ngIf="ficha.associada==false">clear</mat-icon> </td>
                        </ng-container>
                        
                        <!-- Symbol Column -->
                        <ng-container matColumnDef="acoes">
                            <th mat-header-cell *matHeaderCellDef> Ações</th>
                            <td mat-cell *matCellDef="let ficha" > 
                                <mat-icon (click)="getFicha(ficha.id, ficha.tipo)" title="Visualizar Documento" class="btn-icon download-ficha" aria-hidden="false" aria-label="Visualizar Documento">{{ getFichaIcon(ficha) }}</mat-icon> 
                            </td>
                        </ng-container>

                            <!-- Symbol Column -->
                        <ng-container matColumnDef="selecao">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let ficha" > 
                                <mat-checkbox (change)="onCheckboxChange($event, ficha)"></mat-checkbox>
                            </td>
                        </ng-container>
                        
                        <tr mat-header-row *matHeaderRowDef="fichasDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: fichasDisplayedColumns;"></tr>
                    </table>
            </div>
        </mat-expansion-panel>
    </ng-container>
  </mat-accordion>