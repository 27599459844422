import { options } from 'less';
import { Snap } from 'ol/interaction';

export class LayerSnapInteraction extends Snap
{
    private id: string ;

    constructor(id: string, opt_options: any)
    {
        super(opt_options);

        this.id=id;
    }

    getId()
    {
        return this.id;
    }

  
}



