// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
declare const require: any;
export const environment = {
  production: false,
  version: require('../../package.json').version,
  title: 'GEOSV',
  description: 'Prefeitura Municipal de São Vicente - SP',
  
  /*localhost*/
    //  PROXY_URL: 'http://localhost/cgi-bin/proxy.cgi',
    //  BACKEND_API_BASE_PATH: 'http://localhost/v1',
    //  //BACKEND_API_BASE_PATH: 'http://localhost/cgi-bin/proxy.cgi?url=http%3A%2F%2Fpmsv.funcate.org.br%2Fcti%2Fv1',
    //  //GEOSERVER_URL_DOMAIN: 'http://pmsv-hom.funcate.org.br',
    //  GEOSERVER_URL_DOMAIN: 'http://localhost',
    //  GEOSERVER_URL_PATH: '/geoserver/ows',
    //  HELP_URL: 'http://pmsv-hom.funcate.org.br/doc/'

    /*
    PMSV Produção
    */
    PROXY_URL: 'https://geosv.saovicente.sp.gov.br/cgi-bin/proxy.cgi',
    BACKEND_API_BASE_PATH: 'https://geosv.saovicente.sp.gov.br/cti/v1',
    GEOSERVER_URL_DOMAIN: 'https://geosv.saovicente.sp.gov.br',
    GEOSERVER_URL_PATH: '/geoserver/ows',
    HELP_URL: 'https://geosv.saovicente.sp.gov.br/doc/'

    /*
    PMSV Homologação
    */
      // PROXY_URL: 'https://homolgeosv.saovicente.sp.gov.br/cgi-bin/proxy.cgi',
      // BACKEND_API_BASE_PATH: 'https://homolgeosv.saovicente.sp.gov.br/cti/v1',
      // GEOSERVER_URL_DOMAIN: 'https://homolgeosv.saovicente.sp.gov.br',
      // GEOSERVER_URL_PATH: '/geoserver/ows',
      // HELP_URL: 'https://homolgeosv.saovicente.sp.gov.br/doc/'

         /*
    FUNCATE Homologação
    */
      // PROXY_URL: 'http://pmsv-hom.funcate.org.br/cgi-bin/proxy.cgi',
      // BACKEND_API_BASE_PATH: 'http://pmsv-hom.funcate.org.br/cti/v1',
      // GEOSERVER_URL_DOMAIN: 'http://pmsv-hom.funcate.org.br',
      // GEOSERVER_URL_PATH: '/geoserver/ows',
      // HELP_URL: 'http://pmsv-hom.funcate.org.br/doc/'

      ,CODIGO_IBGE_SINTER: 3551009
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
