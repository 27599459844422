import { AbstractDocumentType } from './abstract-document-type';
import { AbstractDocumentAuxiliarField } from './auxiliar-fields/abstract-document-auxiliar-field';
export class SettlementPropertyDocumentType implements AbstractDocumentType
{
    type: number;
    constructor(type: number)
    {
        this.type = type;
    }
    getSupportedExtensions()
    {
        return ["dwg", "pdf", "zip", "doc", "docx", "xls", "xlsx", "odt", "ods", "skp"]    
    }
    getAuxiliarComponents() : AbstractDocumentAuxiliarField[]
    {
        return [];
    }
}