import { Component, OnInit } from '@angular/core';
import { LayersConfigService } from '../../layers/layers.config.service';
import { LayerConfig } from '../../layers/layers.config'
import { Subscription } from 'rxjs';
import { ToolsService } from '../tools.service';
import { GeneralService } from 'src/app/service/general.service';
import { DownloadFormat } from 'src/app/map/vo/download-format';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../../vo/notification';


@Component({
  selector: 'app-download-tool',
  templateUrl: './download-tool.component.html',
  styleUrls: ['./download-tool.component.css']
})
export class DownloadToolComponent implements OnInit {

  overlayers: LayerConfig[]=[];
  downloadTool:any;
  subscriptionTool:Subscription;
  layer: LayerConfig;
  downloadFormat: string;
  formats: Array<DownloadFormat>;
  notificationSubscrition: Subscription;
  downloading: boolean = false;
  

  constructor(private toolsService: ToolsService,
    private generalService: GeneralService,
    private layerService: LayersConfigService,
    private notificationService: NotificationService) 
  { 
    this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (downloadTool:any) => {
        this.downloadTool=downloadTool;
        this.update();
      }
    );

    this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
      if (notification.event==AppNotification.ON_DOWNLOAD_FINISHED_EVENT)
      {
          console.log("Download from '"+notification.object.url+"' is done to out file '"+notification.object.outputFilename+"'.");
          this.downloading = false;
      } 
  });
  }

  ngOnInit(): void 
  {
  
  }
  private update()
  {
    if(this.downloadTool)
    {
      this.layer = this.toolsService.getToolParameter(this.downloadTool, "layer");
      
      this.formats=[];
      if(this.layerService.isVectorLayer(this.layer))
      {
        //Default initial export format
        this.downloadFormat = "SHAPE-ZIP";
        this.formats.push(new DownloadFormat("SHAPE-ZIP","ShapeFile", "zip", true));
        this.formats.push(new DownloadFormat("KML","KML", "kml", true));
        this.formats.push(new DownloadFormat("GML2","GML2", "gml", true));
        this.formats.push(new DownloadFormat("GML3","GML3", "gml", true));
        this.formats.push(new DownloadFormat("json","GeoJSON", "json", true));
        this.formats.push(new DownloadFormat("DXF","DXF", "dxf", true));
      }
      else
      {
        //Default initial export format
        this.downloadFormat = "image/png";
        this.formats.push(new DownloadFormat("image/tiff","GeoTIFF", "tif", false));
        this.formats.push(new DownloadFormat("image/png","PNG", "png", false));
        this.formats.push(new DownloadFormat("image/jpeg","JPEG", "jpeg", false));
        
      }
    }
  }

  private getFormatByName(formatName: string) : DownloadFormat
  {
    let fileFormat = null;
    this.formats.forEach(format => {
      if(format.name==formatName)
      {
        fileFormat=format;
      }
    });

    return fileFormat;
  }

  public download()
  {
    this.downloading = true;
    if(this.layer)
    {
      let format = this.getFormatByName(this.downloadFormat)
      if(format!=null)
      {
        let outputFilename=this.layer.getId()+"."+format.extension;
        
        let url = null;

        if(this.layerService.isVectorLayer(this.layer))
        {
          url = this.getFeaturesDownloadURL(outputFilename,format.name);
        }
        else
        {
          url = this.getRasterDownloadURL(outputFilename,format.name);
        }
        this.generalService.downloadFileGetMethod(url, outputFilename);
      }

    }

  }

  private getFeaturesDownloadURL(outputFilename, format)
  {
    
      let geoserverURL = environment.GEOSERVER_URL_DOMAIN + environment.GEOSERVER_URL_PATH;
      

      let url = `${geoserverURL}?service=wfs&request=getfeature&version=1.0.0`;
      url+= `&typename=${this.layer.getWorkspace()}:${this.layer.getId()}&outputformat=${format}`;

      if(outputFilename)
      {
          url+=`&format_options=filename:${outputFilename}`
      }


      
      let url_proxy=environment.PROXY_URL+"?url="+encodeURIComponent(url);

      return url_proxy;

  }

  private getRasterDownloadURL(outputFilename, format)
  {
    
      let geoserverURL = environment.GEOSERVER_URL_DOMAIN + environment.GEOSERVER_URL_PATH;
      
      let url = `${geoserverURL}?request=GetCoverage&service=WCS&version=2.0.0`;
      url+= `&coverageId=${this.layer.getId()}&format=${format}`;

      if(outputFilename)
      {
          url+=`&format_options=filename:${outputFilename}`
      }      
      let url_proxy=environment.PROXY_URL+"?url="+encodeURIComponent(url);;

      return url_proxy;

  }



}
