import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { LayerConfig } from '../../../layers/layers.config';
import { Property } from '../../../vo/property';
import { ColetaPGV } from '../../../vo/coleta-pgv';
import { ToolsService } from '../../tools.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManageColetaPGVTool } from './manage-coleta-pgv-tool';
import { EditionService } from 'src/app/service/edition.service';
import { FeatureService } from 'src/app/service/feature.service';
import { Userinfo } from '@funcate/sigweb-cti-api';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { DatePipe } from '@angular/common';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { DialogComponent } from 'src/app/map/dialog/dialog.component';
import { GenericConfirmationDialogComponent } from 'src/app/map/dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { Operation } from 'src/app/map/vo/operation';
import { GeomType } from 'src/app/map/vo/geomtype';
import { EditionOperation } from 'src/app/map/vo/edition-operation';
import { Toast } from 'ngx-toastr';
import { ToastService } from 'src/app/service/toast.service';
import { EditColetaPGVToolComponent } from '../edit-coleta-pgv-tool/edit-coleta-pgv-tool.component';
import { UserGroup } from 'src/app/map/vo/user-group';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Imovel } from 'src/app/map/vo/imovel';

@Component({
  selector: 'manage-add-pgv-tool-tool',
  templateUrl: './manage-coleta-pgv-tool.component.html',
  styleUrls: ['./manage-coleta-pgv-tool.component.css']
})


export class ManageColetaPGVToolComponent implements AfterViewInit
{
  private tool:ManageColetaPGVTool;
  private subscriptionTool:Subscription;
  public layer: LayerConfig;
  public idAttribute: string;
  displayedColumns: string[] = ['fonte','nome_user','natureza_transacao' ,'data_coleta', 'data_registro', 'area_terreno', 'area_construcao','valor_terreno', 'valor_construcao', 'valor_total', 'tipo_da_construcao', 'endereco', 'obs', 'acoes' ];     
  public attributes: Array<Property>=[];
  public pgvSources: Array<any>=[];
  public coletasPGV: Array<ColetaPGV>;
  private confirmDialogRef: any;
  private editDialogRef: any;
  dataSource : MatTableDataSource<ColetaPGV>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    private toolsService: ToolsService, private featureService: FeatureService, 
    private layerService: LayersConfigService, private authenticationService: AuthenticationService, private canvasService: CanvasService, private editionService: EditionService, private toastService: ToastService, private dialog: MatDialog) {
      this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (tool:ManageColetaPGVTool) => {
        this.tool=tool;
        this.update();
      },()=> {
      }
    );
   }

  ngOnInit(): void
  {
  }

  ngAfterViewInit() 
  {
   
  }  

  private update() 
  {
    if (this.tool) 
    {     
      this.getColetasForSource(this.authenticationService.getCurrentUser());     
    }
  }

  private getColetasForSource(user: Userinfo)
  {
    this.layer = this.layerService.getColetaPGVLayer()
    let sourceAttribute = this.layerService.getLayerAppConfig(this.layer, "source_attribute"); 

    if(sourceAttribute)
    {

      let attributesFilterList: Array<Array<Property>>=[];
      let attributeFilterList: Array<Property>=[];

        if(this.tool.isPGVUser())
        {
          attributeFilterList.push(new Property(sourceAttribute, user['user_pgv_source']));  
          attributesFilterList.push(attributeFilterList);
        }        

        this.featureService.getFeatures(this.layer, "" , attributesFilterList).then(features => 
        {
      
          if(features && features.length>0)
          {
            this.coletasPGV = new Array<ColetaPGV>();

            features.forEach(feature => {

              if(feature.properties)
              {
                if(feature.properties)
                {
                  let properties : Array<Property> = this.featureService.getWFSPropertiesObjectAsPropertyArray(feature.properties);

                  let coletaPGV = new ColetaPGV();

                  coletaPGV.fromProperties(properties);
                  coletaPGV.setFeatureGeoJSON(feature);
                  
                  this.coletasPGV.push(coletaPGV);

                  this.dataSource = new MatTableDataSource<ColetaPGV>(this.coletasPGV);
                  this.dataSource.paginator = this.paginator;

                  this.defineFilterPredicate();
                }
              }
            });
          }
          else
          {
            this.coletasPGV = [];
            this.dataSource = new MatTableDataSource<ColetaPGV>(this.coletasPGV);
            this.dataSource.paginator = this.paginator;
          }
        });
    }
  }

  public formatDate(date: Date, format: string) : string
  {
    let data="";
    if(date)
    {
      let datePipe = new DatePipe('pt-BR');
      data = datePipe.transform(date.toString(), format);
    }
 

    return data;
  }

  public fit(coleta: ColetaPGV)
  {
    
    this.canvasService.fitFeatures([coleta.getFeatureGeoJSON()]);
  }

  public confirmDelete(coleta: ColetaPGV)
  {

 
    this.confirmDialogRef = this.dialog.open(DialogComponent, {
      height: '30%',
      width: '20%',
      data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente apagar a coleta?" }
    });

    this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
      if(confirmResult)
      {
         this.delete(coleta);
      }

    });
  }

  private delete(coleta: ColetaPGV)
  {
    if(this.tool.isPGVUser())
    {
      let editionOperation: EditionOperation = new EditionOperation(coleta.getId(), coleta.getFeatureGeoJSON(), GeomType.Point, Operation.Delete);
      
      this.editionService.deleteGeom(editionOperation, this.layer).then((operation)=>{

        this.update();
        this.toastService.sucess("Ponto de coleta PGV removido com sucesso!", "Ponto removido!!");
        this.canvasService.redrawLayer(this.layer);

      });
    }
  }

  public edit(coleta: ColetaPGV)
  {
    if(this.tool.isPGVUser())
    {
      this.editDialogRef = this.dialog.open(DialogComponent, {
        height: '60%',
        width: '40%',
        data: { component: EditColetaPGVToolComponent, title: "Editar dados coleta PGV", coleta: coleta, layer: this.layer, parent: this  }
      });
    }   
  }

  public closeEditColeta()
  {
    this.editDialogRef.close();
  }

  sortData(sort: Sort) {

    this.dataSource.sort = this.sort;
    const data = this.coletasPGV.slice();
    if (!sort.active || sort.direction === '') {
      this.coletasPGV = data;
      return;
    }

    this.coletasPGV.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'fonte': return this.compare(a.getFonte(), b.getFonte(), isAsc);
        case 'nome_user': return this.compare(a.getNomeUser(), b.getNomeUser(), isAsc);
        case 'natureza_transacao': return this.compare(a.getNaturezaTransacao(), b.getNaturezaTransacao(), isAsc);
        case 'data_coleta': return this.compare(a.getDataColeta().getTime(), b.getDataColeta().getTime(), isAsc);
        case 'data_registro': return this.compare(a.getDataRegistro().getTime(), b.getDataRegistro().getTime(), isAsc);
        case 'area_terreno': return this.compare(a.getAreaTerreno(), b.getAreaTerreno(), isAsc);
        case 'area_construcao': return this.compare(a.getAreaConstrucao(), b.getAreaConstrucao(), isAsc);
        case 'valor_terreno': return this.compare(a.getValorTerreno(), b.getValorTerreno(), isAsc);
        case 'valor_construcao': return this.compare(a.getValorConstrucao(), b.getValorConstrucao(), isAsc);
        case 'valor_total': return this.compare(a.getValorTotal(), b.getValorTotal(), isAsc);        
        case 'endereco': return this.compare(a.getEndereco(), b.getEndereco(), isAsc);
        case 'obs': return this.compare(a.getObs(), b.getObs(), isAsc);
        case 'tipo_da_construcao': return this.compare(a.getTipoConstrucao(), b.getTipoConstrucao(), isAsc);
        default: return 0;
      }
    });
    this.dataSource = new MatTableDataSource<ColetaPGV>(this.coletasPGV);
    this.dataSource.paginator = this.paginator;
    this.defineFilterPredicate();
  } 
  private compare(a: number | string, b: number | string, isAsc: boolean) 
  {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private defineFilterPredicate()
  {
    this.dataSource.filterPredicate = function(data, filter: string): boolean 
    {
      let datePipe = new DatePipe('pt-BR');

      if(data.getFonte() && data.getFonte().toLowerCase().includes(filter))
      {
        return true;
      }

      if(data.getNomeUser() && data.getNomeUser().toLowerCase().includes(filter))
      {
        return true;
      }

      if(data.getNaturezaTransacao() && data.getNaturezaNameById(data.getNaturezaTransacao()).toLowerCase().includes(filter))
      {
        return true;
      }

      if(data.getDataColeta() && datePipe.transform(data.getDataColeta(), 'dd/MM/yyyy').includes(filter))
      {
        return true;
      } 
      if(data.getDataRegistro() && datePipe.transform(data.getDataRegistro(), 'dd/MM/yyyy').includes(filter))
      {
        return true;
      }

      if(data.getAreaTerreno() && data.getAreaTerreno().toString().includes(filter))
      {
        return true;
      }
      if(data.getAreaConstrucao() && data.getAreaConstrucao().toString().includes(filter))
      {
        return true;
      }

      if(data.getValorTerreno() &&  data.getValorTerreno().toString().includes(filter))
      {
        return true;
      }
      if(data.getValorConstrucao() && data.getValorConstrucao().toString().includes(filter))
      {
        return true;
      }
      if(data.getValorTotal() && data.getValorTotal().toString().includes(filter))
      {
        return true;
      }
      if(data.getEndereco() && data.getEndereco().toLowerCase().includes(filter))
      {
        return true;
      }
      if(data.getObs() && data.getObs().toLowerCase().includes(filter))
      {
        return true;
      }
      if(data.getTipoConstrucao() && data.getTipoConstrucao().toLowerCase().includes(filter))
      {
        return true;
      }
      return false;

    };
  }



  
}
