import { CanvasService } from '../../canvas/canvas.service';
import { Tool } from '../tool';
import { ToolsService } from '../tools.service';

export class SavemapTool extends Tool {

    cursorType='tool-cursor-wait';
    id='savemap-tool';
    enabled=false;
    protected visible:boolean=true;
    name='Save Map Tool';
    title='Salvar área do mapa';
    type='click';
    icon='image';
    layerTool: boolean=false;
    customParameter=new Map<String, any>();

    constructor(private canvasService: CanvasService, toolsService: ToolsService) 
    {
        super(toolsService)
    }

    public execAction=(event:L.LeafletMouseEvent)=>{}

    public enableImpl()
    {
        this.canvasService.map.on('easyPrint-start',()=>{
            window.document.body.style.cursor='wait';
            this.icon='hourglass_empty';
        });
        this.canvasService.map.on('easyPrint-finished',()=>{
            window.document.body.style.cursor='default';
            this.icon='get_app';
        });
        this.canvasService.saveMapToPng();
        this.toolsService.toogleTool(this);
    }
    public disableImpl()
    {
    }
    
}
