import { Pipe, PipeTransform } from '@angular/core';
import * as Converter from 'xml-js';

export class StringUtils {

  constructor() 
  { }

  public static isNullOrEmpty(text: string) : boolean
  {  
    if(text!=null && text.trim()!="")
    {
        return false;
    }
    
    return true;
  }
}