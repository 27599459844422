<mat-accordion >
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="layername">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header" class="layers-col">
                    <mat-icon class="material-icons layer-icon">palette</mat-icon>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>


            <mat-card class="import-layers-cards-background" id="upload-sld-file-card">
                <mat-card-subtitle class="create-layer-card-subtitle"><mat-icon class="material-icons">format_paint</mat-icon>Estilo</mat-card-subtitle>
                  <mat-card-content>
                    <div class="style-id-div">
                        <div class="label-div"><label for="style-id">Id</label></div>
                        <div class="field-div"><input matInput class="form-control style-input-fields" type="text" id="style-id" [(ngModel)]="currentStyle.id" (input)="clearStyleName($event)" (onPaste)="clearStyleName($event)" pattern="[a-zA-Z]" [disabled]="newStyle==false"/></div>
                    </div>
                    <div class="style-title-div">
                        <div class="label-div"><label for="style-title">Nome</label></div>
                        <div class="field-div"><input matInput class="form-control style-input-fields" type="text" id="style-title" [(ngModel)]="currentStyle.name"/></div>
                    </div>
                    <div class="style-abstract-div">
                        <div class="label-div"><label for="style-abstract">Descrição</label></div>
                        <div class="field-div"><input matInput class="form-control style-input-fields" type="text" id="style-abstract" [(ngModel)]="currentStyle.title" /></div>
                    </div>
                    <div class="import-layer-sld-preview" *ngIf="legendPreviewURL!=null">
                        <div  class="label-div"><label for="import-layer-apply-style" class="">Pré-visualização</label></div>                      
                        <div class="field-div"><img [src]="legendPreviewURL" id="legend-preview" ></div>                      
                    </div>
                    <div class="import-layer-file-div">
                        <div  class="label-div">
                            <label for="import-layer-sld-file-name-display">Arquivo</label>
                        </div>
                        <div class="field-div">
                            <input matInput class="form-control" type="text" disabled id="import-layer-sld-file-name-display" [value]="currentSLDFilename" />
                            <button mat-raised-button color="primary"  [ngClass]="{'spinner' : uploadingFile==true}" (click)="importLayerFileInput.click()" [disabled]="uploadingFile">Procurar</button>
                        </div>
                    </div>
                    <input #importLayerFileInput id="import-layer-file-input" type="file" style="display: none;" accept=".sld" (change)="onFileSelect($event)"/>
                  </mat-card-content>
              </mat-card>

        </mat-expansion-panel>
    </ng-container>
</mat-accordion>


<div class="buttons-div">
    <div class="cancel-button">
        <button mat-raised-button color="basic" (click)="close()">Cancelar</button>
    </div>
    <div class="apply-button">
        <button mat-raised-button color="primary" (click)="validate()">Salvar</button>

    </div>
</div>



