
<div class="pmsv-logo">
    <img src="assets/logotipo.png" alt="Logo de cabeçalho para SIGWeb" > <img src="assets/logo-funcate.png" alt="Logo de cabeçalho para SIGWeb" >
</div>

<mat-card>
    <mat-card-content class="-card">
        <p>Prezado usuário!</p>

        <p>As funcionalidades e dados disponível no sistema GEOSV foram desenvolvidas para atendimento específico de conjunto de necessidades da Prefeitura Municipal de São Vicente.</p>

        <p>Para sugestões de melhorias, ajustes ou críticas, gentileza encaminhar email para:</p>

        <p><a href="mailto:atendimento.geo@saovicente.sp.gov.br">atendimento.geo@saovicente.sp.gov.br</a></p>  

        <p>Atenciosamente</p>

        <p>Nucleo de Geoprocessamento da Prefeitura</p>
        
    </mat-card-content>
</mat-card>