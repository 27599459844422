import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { ToastService } from 'src/app/service/toast.service';
import {SafePipe} from '../../../../util/safe.pipe';

@Component({
  selector: 'app-foto-fachada-preview',
  templateUrl: './foto-fachada-preview.component.html',
  styleUrls: ['./foto-fachada-preview.component.css']
})
export class FotoFachadaPreviewComponent implements OnInit 
{

  currentIndex: number;
  albumFotosFachada: any[];
  fotoData: any;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, 
  private authenticationService: AuthenticationService, private toastService: ToastService,  public sanitizer: DomSanitizer) {
    this.currentIndex = data.currentIndex;
    this.albumFotosFachada = data.albumFotosFachada;
  }

  private loadFoto(foto)
  {
    let user = this.authenticationService.getCurrentUser();
    if(!user)
    {
      this.toastService.error("Usuário não está autenticado corretamente. Efetue o login novamente.", "Problemas ao baixar foto de fachada do imovel.");
    }

    let httpOptions = {};
              
    if(user.token)
    {
      let header = new HttpHeaders().set('Authorization', 'Bearer ' + user.token );
     
      httpOptions = {
        headers: header
      };        
    }

    httpOptions['observe'] = 'body';
    httpOptions['params'] = {};
    httpOptions['reportProgress'] = false;
    httpOptions['responseType'] = 'blob';

    this.http.get(foto.src, httpOptions).subscribe((res:any ) => {
    
      const file = new Blob([res]);
      const blob = window.URL.createObjectURL(file);
      
      this.fotoData=this.sanitizer.bypassSecurityTrustResourceUrl(blob)
    });
  }

  ngOnInit(): void 
  {
    this.update();
  }

  private update()
  {
    let foto = this.albumFotosFachada[this.currentIndex];

    if(foto)
    {
      this.loadFoto(foto);
    }
  }

  next()
  {
    let nextIndex = this.currentIndex+1;
    if(nextIndex < this.albumFotosFachada.length)
    {
      this.currentIndex = nextIndex;

      this.update();    
    }

  }

  back()
  {
    let backIndex = this.currentIndex-1;
    if(backIndex >= 0)
    {
      this.currentIndex = backIndex;

      this.update();
    }
  }

}
