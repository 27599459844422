import {ToolsService} from '../tools.service';
import GeoJSON from 'ol/format/GeoJSON';

import { LayersInfo } from '../../vo/layersinfo';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
//import { FetureInfoToolComponent } from '../fetureinfo-tool.component';
import { DialogComponent } from '../../dialog/dialog.component';
import { Tool } from '../tool';
import { Feature } from 'src/app/map/vo/feature';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';

import { PropertysearchToolComponent } from '../propertysearch-tool/propertysearch-tool.component';
import { Documentos, EditionoperationsInner, Featureattributes, Historicoprocessoimovel, HistoricoprocessoimovelInner, Imoveisrelations } from '@funcate/sigweb-cti-api';
import { EditHistoricoComponent } from '../property-registration/edit-historico/edit-historico.component';
import { FeatureService } from 'src/app/service/feature.service';
import Point from 'ol/geom/Point';
import { Property } from '../../vo/property';
import { BackendService } from 'src/app/service/backend.service';
import { DocumentService } from 'src/app/service/document.service';
import { ToastService } from 'src/app/service/toast.service';
import { Imovel } from '../../vo/imovel';
import { Inscricao } from '../../vo/inscricao';
import MultiPoint from 'ol/geom/MultiPoint';
import { GeometryService } from 'src/app/service/geometry.service';
import { GenericConfirmationDialogComponent } from '../../dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { SettlementComponent } from './settlement.component';
import { EditionService } from 'src/app/service/edition.service';

export class SettlementTool extends AbstractLayerTool {

    private dialog: MatDialog;

    cursorType='tool-cursor-pointer';
    id='settlement-tool';
    enabled=false;
    name='Settlement Tool';
    title='Consulta de Área Irregular';
    type='click';
    map:L.Map=null;
    ll: any; 
    protected visible:boolean=true;
    icon='info';
    layerTool: boolean=true;
    customParameter=new Map<String, any>();    
    public imoveisICAttributeConfig: string = "inscricao_attribute";
    currentFeature: any;
    layer: LayerConfig;
    loadingFeature: boolean;

    editSettlementDialogRef: MatDialogRef<DialogComponent>;

    constructor(toolService: ToolsService, private layerConfigService: LayersConfigService, 
        private toastService: ToastService, private canvasService: CanvasService, private featureService: FeatureService, 
        private editionService: EditionService ,dialog: MatDialog) 
    { 
        super(toolService)
        this.dialog = dialog;
        this.loadingFeature=false;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{
        this.map=event.sourceTarget;
        this.ll=event.latlng;

        if(this.loadingFeature == false)
        {
            this.toastService.sucess("Localizando feição!", "Carregando...");
            this.loadFeature();
        }
        else
        {
            this.toastService.warning("Buscando feição apontada, por favor aguarde!", "Carregando...");
        }   
    };    

    public enableImpl()
    {
        let currentLayerTool = this.toolsService.getToolParameter(this, "layer");
        if(currentLayerTool)
        {
            this.layerConfigService.enableLayer(currentLayerTool);
        }                  
    }
    public disableImpl()
    {
    }

    public isVisibleForLayer(layer :LayerConfig) : boolean
    {
        return this.layerTool;
    }



    public isEnabled(layer :LayerConfig) : boolean
    {
        this.layer = this.toolsService.getToolParameter(this, "layer");

        if(this.enabled && layer.getId()==layer.getId() )
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    public openEditSettlement()
    {
        this.editSettlementDialogRef = this.dialog.open(DialogComponent, {
            height: '80%',
            width: '90%',
            data: { component: SettlementComponent, title: "Consultar Área Irregular", currentFeature: this.currentFeature, tool: this, dialog: this.dialog, readOnly: true}
        });  
        return this.editSettlementDialogRef;    
    }

    private loadFeature() 
    {

      this.layer = this.toolsService.getToolParameter(this, "layer");

      if (this.layerConfigService.isVectorLayer(this.layer))
      {
        let idAttribute=this.layerConfigService.getLayerIdAttribute(this.layer);
        //this.tool.setFeature(null);
        this.canvasService.getMap().eachLayer((l) => {
          if (l["wmsParams"])
          {

            if (l["id"] == this.layer.getId())
            {
              this.featureService.getFeatureInfo(this.ll, l, this.canvasService.getMap()).then(featureInfo => {

                if (featureInfo)
                {
                   let features=[];

                  featureInfo.features.forEach(feature => {                    

                    let featureObject = new Feature();                    

                    let idValueProperty = this.featureService.getPropertyByKey(feature.properties, idAttribute);                   

                    
                    if(idValueProperty)
                    {
                      featureObject.id = idValueProperty.value.toString();                      

                      let layerTableName = this.layerConfigService.getLayerTable(this.layer);

                      this.editionService.getFeatureAttributes(layerTableName, idAttribute, idValueProperty.value.toString()).then((featureattributes: Featureattributes)=>
                      {

                        let properties : Array<Property> = this.featureService.getFeatureProperties(featureattributes, this.layer);

                        featureObject.properties=properties;

                         features.push(featureObject);     
                        
                        if(featureObject!=null)
                        {
                          this.currentFeature=featureObject;

                          this.openEditSettlement();

                        }
                                                
                      });                 

                    }
                    
                  });

                }
              });

            }

          }
        });
      }

    

  }
  closeDialog()
  {
    this.editSettlementDialogRef.close();
  }
 
}
