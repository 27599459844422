import { Type } from '@angular/core';
import { AbstractDocumentType } from './abstract-document-type';
import { AbstractDocumentAuxiliarField } from './auxiliar-fields/abstract-document-auxiliar-field';
export class SettlementDocumentType implements AbstractDocumentType
{
    type: number;

    constructor(type: number)
    {
        this.type = type;
    }

    getSupportedExtensions()
    {
        return ["jpg", "png", "tif", "pdf", "dwg", "zip", "doc", "docx", "xls", "xlsx", "odt", "ods"]    
    }
    getAuxiliarComponents() : AbstractDocumentAuxiliarField[]
    {
        return [];
    }
}