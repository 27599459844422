import { Imovel } from "./imovel";

export class Inscricao {
    public setor:string;
    public quadra:string;
    public logradouro:string;
    public lote:string;
    public sublote:string;

    constructor(inscricao: string)
    {
        this.setor=inscricao.substring(0,2)
        this.quadra=inscricao.substring(2,7)
        this.logradouro=inscricao.substring(7,11)
        this.lote=inscricao.substring(11,16)
        if(inscricao.length>=19)
        {
            this.sublote=inscricao.substring(16,19)
        }
    }
    public static fromImovel(imovel: Imovel)
    {
        let inscricao=imovel.setor+imovel.quadra+imovel.logradouro+imovel.lote+( (imovel.sublote)?((imovel.sublote)):("") );
        return new Inscricao(inscricao);
    }

    public getInscricaoSeparadaPorPonto() : string
    {
        return this.setor+"."+this.quadra+"."+this.logradouro+"."+this.lote+"."+this.sublote;
    }
    public getInscricaoCompleta() : string
    {
        return this.setor+this.quadra+this.logradouro+this.lote+this.sublote;
    }

    public isValid()
    {
        if(this.setor &&
            this.quadra &&
            this.logradouro &&
            this.lote &&
            this.sublote)
            {
                return true;
            }
            return false;
    }

}
