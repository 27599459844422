import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LatLng, Layer, Util } from 'leaflet';
import { environment } from 'src/environments/environment';
import { CanvasService } from '../map/canvas/canvas.service';
import { LayerConfig } from '../map/layers/layers.config';
import { LayersConfigService } from '../map/layers/layers.config.service';
import { Feature } from '../map/vo/feature';
import { FeatureInfo } from '../map/vo/featureinfo';
import { Property } from '../map/vo/property';
import * as L from 'leaflet';
import { BackendService } from './backend.service';
import * as genericDomainOptions from "../../assets/app-generic-domain-options.json";
import { 
    Annotation
    } from '@funcate/sigweb-cti-api';

@Injectable({
    providedIn: 'root'
  })

export class GenericDomainOptionsService {
   
    /** start http service client */
    constructor(private backendService: BackendService)
    {
    }

    public getOptionsForDomain(domainId: string) : any[]
    {
        let options : any = []
        genericDomainOptions.domains.forEach(domain => {
            if(domain.id==domainId)
            {
                options = domain.options;
            }
        });
        return options;
    }
    
} 