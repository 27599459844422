import { MatDialog } from '@angular/material/dialog';
import { StreetSearchToolComponent } from './street-search-tool.component';
import { DialogComponent } from '../../dialog/dialog.component';
import { ToolsService } from '../tools.service';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { LayerConfig } from '../../layers/layers.config';

export class StreetSearchTool extends AbstractLayerTool {

    private dialog: MatDialog;

    cursorType=null;
    id='street-search-tool';
    enabled=false;
    name='Street Search Tool';
    title='Pesquisa de Logradouro';
    type='click';
    map:L.Map=null;
    icon='search';
    ll: any;
    protected visible:boolean=true;
    layerTool: boolean=true;
    customParameter=new Map<String, any>();

    constructor(dialog: MatDialog, toolsService: ToolsService) 
    { 
        super(toolsService)
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{};

    public enableImpl()
    {
        let dialogRef = this.dialog.open(DialogComponent, {
            maxHeight: '80%',
            maxWidth: '80%',
            data: { component: StreetSearchToolComponent, title: "Pesquisa de Logradouros", dialog: this.dialog }
        });

        let whenDialogIsOpen=dialogRef.afterOpened().toPromise();
        whenDialogIsOpen.then(
            ()=>{
                this.toolsService.dispatchTool(this);
            }
        );

        dialogRef.afterClosed().toPromise().then(()=>{
            this.toolsService.toogleTool(this);
        });
    }
    public disableImpl()
    {
    }

    public isVisibleForLayer(layer :LayerConfig) : boolean
    {
        return this.layerTool;
    }

    public isEnabled(layer :LayerConfig) : boolean
    {
        let currentLayerTool = this.toolsService.getToolParameter(this, "layer");
        if(this.enabled && currentLayerTool.getId()==layer.getId() )
        {
            return true;
        }
        else
        {
            return false;
        }
    }
    
}
