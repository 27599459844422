<!-- Header print -->
<div id="header-print">
  <div id="logo-print">
    <img width="120px" alt="Logo de cabeçalho para {{title}}" src="assets/logotipo.png" />
  </div>
  <div id="title-print">
    <span>{{toolbarPrintTxt}}</span>
  </div>
</div>
<!-- end header print -->
<!-- rosa dos ventos -->
<div id="rosa-print">
  <img width="80px" alt="Rosa dos ventos impressão" src="assets/img/rosa_dos_ventos.png" />
</div>
<!-- end rosa dos ventos -->

<div class="container-fluid h-100 d-flex flex-column" id="mapContent">
<div id="safe-div" (mousemove)="onMouseMove($event)" (mouseup)="onMouseUp($event)"></div>

<!-- Header -->
  <div class="row flex-shrink-0" role="banner"  id="header">
    <div class="col">
      <img width="120px" alt="Logo de cabeçalho para {{title}}" src="assets/logotipo.png" (click)="tempTest()" />
      <span>{{toolbarTxt}}</span>
    
      <div class="spacer"></div>
      
    </div>
    <div class="col">
      <div class="row">
        <div class="col search-icon-div"><span class="material-icons">
          search
          </span>
        </div>
        <div class="col header-right">
            <app-quicksearch-tool class="text-center" id="quicksearch-tool"></app-quicksearch-tool>
            <div id="header-right-buttons">
              <div id="help-div">
                <button mat-icon-button aria-label="Ajuda" (click)="openHelp()" title="Ajuda">
                  <mat-icon class="help-icon">help</mat-icon>              
                </button>
              </div>
              <div id="help-div">
                <button mat-icon-button aria-label="Ajuda" (click)="showVersion()" title="Sobre">
                  <mat-icon class="help-icon">info</mat-icon>              
                </button>
              </div>
              <div id="help-div">
                <button mat-icon-button aria-label="Ajuda" (click)="showContact()" title="Fale Conosco">
                  <mat-icon class="help-icon">mail</mat-icon>              
                </button>
              </div>
              <div id="user-auth-div" *ngIf="userAuthenticated && userAuthenticated.authenticated">
                <button mat-icon-button [matMenuTriggerFor]="userMenu" aria-label="User Menu">
                  <mat-icon class="auth-icon">account_circle</mat-icon>
                </button>
                <mat-menu #userMenu="matMenu" class="auth-menu">
                  <button mat-menu-item disabled >
                    <mat-icon>person</mat-icon>
                    <span>{{userAuthenticated.name}} </span><span>({{getUserGroupName(userAuthenticated)}})</span>
                  </button>

                  <button mat-menu-item (click)="logout()">
                    <mat-icon>logout</mat-icon>
                    <span>Sair</span>
                  </button>
                </mat-menu>
              </div>
              <div id="user-auth-div" *ngIf="!userAuthenticated || !userAuthenticated.authenticated">
                <button mat-icon-button [matMenuTriggerFor]="userMenu" aria-label="User Menu">
                  <mat-icon class="not-auth-icon">account_circle</mat-icon>
                </button>
                <mat-menu #userMenu="matMenu" class="auth-menu">
                  <button mat-menu-item (click)="showVersion()">
                    <mat-icon>info</mat-icon>
                    <span>Sobre</span>
                  </button>
                  <button mat-menu-item (click)="login()">
                    <mat-icon>login</mat-icon>
                    <span>Entrar</span>
                  </button>                  
                </mat-menu>
              </div>
            </div>            
        </div>
      </div>
      </div>
  </div>
<!-- Content -->
  <div class="row flex-fill map-content-print" style="min-height:90%" >
    <div class="col-sm-4 col-md-3" id="sidebar" [style.width.px]='width'>
      <app-tools></app-tools>
      <app-layers></app-layers>
    </div>
    <div class="resize-menu col-sm-1 col-md-1" (mousemove)="onMouseMove($event)" (mousedown)="onMouseDown($event)" (mouseup)="onMouseUp($event)"></div>
    <div style="width: 100%;" id="canvas">
      <app-canvas></app-canvas>
    </div>
  </div>
  <!-- right area for legend and feature info -->
  <div class="rightbar" [class.rightbar-enabled]="viewerEnabled">
    <div class="row flex-fill rightbar-inner">
      <div class="col-sm-12 col-md-12" id="legendbar">
        <div>
          <mat-slide-toggle [checked]="viewerEnabled" ([ngModel])="viewerEnabled" 
              (change)="onChangeLegendViewer($event)" color="primary">Legenda das camadas</mat-slide-toggle>
        </div>
        <app-legend *ngIf="viewerEnabled" ></app-legend>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <div class="row flex-shrink-0 footer-container">
    <div class="col-12" id="footer">
      <div class="map-coordinates" [title]="currentMapCoordDisplaySRIDDescription" >
          <div class="coordinates-display-container">
            <div id="map-coordinates"  class="coordinates-display">Y: <span id="coordinates-display-current-y" >00.0000000</span> / X: <span id="coordinates-display-current-x" >00.0000000</span></div>
              <input matInput [(ngModel)]="currentMapCoordDisplaySRID" id="coordinates-display-current-srid" class="hide-map-coord-display-control"/>
              <mat-select [(ngModel)]="currentMapCoordDisplaySRID" panelClass="coordinates-display-srid-combo-panel" name="srid" class="coordinates-display-srid-combo" (selectionChange)="currentMapDisplaySRIDChanged($event)" >
              <mat-option *ngFor="let srid of mapCoordinatesSRIDList" [value]="srid.id">
                {{srid.description}}
              </mat-option>
            </mat-select>            
          </div>          
      </div>
      <div>{{footerTxt}}</div>
      <div (click)="showVersion()" class="show-version" title="Sobre essa aplicação">versão: v{{appVersion}}</div>      
    </div>
  </div>
</div>

<!-- Footer print -->
<div id="footer-print">
    <h3>{{footerPrintTxt}}</h3>
    <h5>Data:{{datePrint}} - versão: v{{appVersion}}</h5>
</div>
<!-- end Footer print -->