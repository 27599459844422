import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { ToolsService } from '../tools.service';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { LayerConfig } from '../../layers/layers.config';
import { ManageAnnotationToolComponent } from './manage-annotation-tool/manage-annotation-tool.component';
import { Annotation, Userinfo } from '@funcate/sigweb-cti-api';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { Property } from 'src/app/map/vo/property';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from 'src/app/map/vo/notification';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { UserGroup } from 'src/app/map/vo/user-group';
import { Tool } from '../tool';
import { AnnotationService } from 'src/app/service/annotation.service';
import * as L from 'leaflet';
import { group } from '@angular/animations';
import { ToastService } from 'src/app/service/toast.service';
import { AnnotationNameComponent } from './annotation-name/annotation-name.component';
import { GenericConfirmationDialogComponent } from '../../dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';

export class AnnotationTool extends Tool {

    cursorType=null;
    id='annotation-tool';
    enabled=false;
    name='Anotações Tool';
    title='Ferramenta de Anotações';
    type='click';
    map:L.Map=null;
    icon='draw';
    ll: any;
    protected visible:boolean=true;
    layerTool: boolean=false;
    customParameter=new Map<String, any>();
    notificationSubscrition: any;
    dialog: any;
    currentEditLabelLayer : any;


    private dialogRef: any;

    constructor(dialog: MatDialog, toolsService: ToolsService, 
        private canvasService: CanvasService, private layerService: LayersConfigService, private notificationService: NotificationService,
         private authenticationService: AuthenticationService, private annotationService: AnnotationService, private toastService: ToastService) 
    { 
        super(toolsService)
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{
    };

    public enableImpl()
    {

        this.canvasService.enableAnnotation(this);

        let user: Userinfo = this.authenticationService.getCurrentUser();
        let groupId = user['usergroup_id'].toString();
        
        this.annotationService.getAnnotation(groupId).then((annotation: Annotation)=>{
            if(annotation && annotation.data)
            {
                let featureCollection = JSON.parse(annotation.data);

                if(featureCollection && featureCollection.features && featureCollection.features.length>0)                
                {
                  featureCollection.features.forEach(feature => {

                  let layer = this.canvasService.getAnnotationLayers(feature);
                    
                  this.canvasService.getAnnotationLayer().addLayer(layer);                  
                });

                this.toastService.sucess(featureCollection.features.length +  " Anotações Carregadas.", "Sucesso");
              }
            }
        });        

        this.addToolControl();

    }
    public disableImpl()
    {
        this.canvasService.disableAnnotation();
    }

    public addToolControl() {
      
        let saveButton = this.canvasService.getMap().pm["Toolbar"]["getButtons"]()["save-annotation-action"];
        
        if(!saveButton)
        {
          const actions = [
            {
              text: 'Salvar Anotações', id:'save-annotation-action' ,onClick: () => {
                
              }
            }];
          let options = {
            name: 'save-annotation-action',
            block: 'save',
            title: 'Salvar estado anotações',
            className: 'save-edition-leaflet-control',
            onClick: ()=>{
              this.save();
            },
            actions: []
          };
          this.canvasService.getMap().pm["Toolbar"].createCustomControl(
            options
          );
        }

      }
      public editAnotation()
      {

      }
      public save()
      {
        let featureCollection = { type: 'FeatureCollection', features: Array(0) }
        featureCollection.features=[];
        var fg = L.featureGroup();
        this.canvasService.getMap().eachLayer((layer)=>{
           if((layer instanceof L.Path || layer instanceof L.Marker  || layer instanceof L.Circle 
            || layer instanceof L.Point || layer instanceof L.Polygon || layer instanceof L.Polyline))
           {

            //let json = layer.toGeoJSON();

           // fg.addLayer(layer);

            
            let feature = layer["toGeoJSON"]();
            if(feature && layer instanceof L.Circle)
            {
              feature.properties["radius"] = layer.getRadius();
            }
             featureCollection.features.push(feature);
             
          }
        });        
        let annotation : Annotation= new Object();

        let user: Userinfo = this.authenticationService.getCurrentUser();
        let groupId = user['usergroup_id'].toString();

        annotation.id=-1;
        annotation['user_group_id']=groupId;
        annotation.data = JSON.stringify(featureCollection);

        this.annotationService.setAnnotation(annotation).then(()=>
        {
            this.toastService.sucess("Anotações Salvas!!", "Sucesso");
        });
      } 

      public async createCallBack(event)
      {
        this.currentEditLabelLayer = event.layer
         this.dialogRef = this.dialog.open(AnnotationNameComponent, {
           height: '30%',
           width: '30%',
           data: { title: "Definir nome", layer: this.currentEditLabelLayer, name:"", parent: this }
         });
      }

      public closeCreateCallback()
      {
        this.dialogRef.close();
        this.dialogRef=null;
      }
    
}
