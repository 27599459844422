import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { Documentos, Geooperationarea, GeooperationareaLayer, Geooperationcontains, GeooperationcontainsLayer1, GeooperationcontainsLayer2, Userinfo } from '@funcate/sigweb-cti-api';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { FichaService } from 'src/app/service/ficha.service';
import { LayersConfigService } from '../../layers/layers.config.service';
import { FeatureService } from 'src/app/service/feature.service';
import { Settlement } from '../../vo/settlement';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { Moment } from 'moment';
import * as moment from 'moment';
import { ToastService } from 'src/app/service/toast.service';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EditSettlementTool } from './edit-settlement-tool';
import { DialogComponent } from '../../dialog/dialog.component';
import { GenericConfirmationDialogComponent } from '../../dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { EditionService } from 'src/app/service/edition.service';
import { DocumentService } from 'src/app/service/document.service';
import { DocumentUploadComponent } from '../../dialog/document-upload-dialog/document-upload.component';
import { DatePipe } from '@angular/common';
import { UserGroup } from '../../vo/user-group';
import { AbstractDocumentType } from '../../vo/document/abstract-document-type';
import { BackendService } from 'src/app/service/backend.service';
import { ToolsService } from '../tools.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};



@Component({
  selector: 'app-settlement',
  templateUrl: './settlement.component.html',
  styleUrls: ['./settlement.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class SettlementComponent implements OnInit {

  public poligonalList: string[];
  public inadequacaoList: string[];
  public propriedadeList: string[];
  public tipologiaList: string[];
  public settlement : Settlement;
  documentosDisplayedColumns: string[] = ['id', 'nome', 'dataInsercao', 'tipo','acoes'];
  historicosQtdDomiciliosDisplayedColumns: string[] = ['ano', 'qtd', 'obs', 'acoes'];
  maxDate = new Date();
  newHistoricoDomicilio = null;
  ano = new FormControl();
  qtd = new FormControl();
  obs = new FormControl();
  historicoDataSource = new MatTableDataSource();
  tool : EditSettlementTool;
  currentFeature: any;
  confirmDialogRef: any;
  dialog: any;
  newDocumentoUploadDialog: any;
  calculatingArea: boolean;
  calculatingCoberturas: boolean;
  readonly toolSettlementEdificationLayerNameAttributeConfig: string = "assentamentos_precarios_edificacoes_layername";
  settlementDocumentType: number = AbstractDocumentType.SETTLEMENT_DOCUMENTS;
  settlementProjectDocumentType: number = AbstractDocumentType.SETTLEMENT_PROJECTS_DOCUMENTS;
  settlementJudicialDocumentType: number = AbstractDocumentType.SETTLEMENT_JUDICIAL_DOCUMENTS;
  readOnly: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private layersConfigService: LayersConfigService, 
    private authenticationService: AuthenticationService, private documentService: DocumentService, private dateAdapter: DateAdapter<Date>,
    private toastService: ToastService, private featureService: FeatureService, private editionService: EditionService, private backendService: BackendService, private toolsService: ToolsService, ) 
  { 
    this.tool = data.tool;
    this.dialog = data.dialog;
    this.currentFeature = data.tool.currentFeature;
    this.poligonalList = ["Complexo Sudoeste",
    "Complexo Nordeste",
    "Complexo Central",
    "Complexo Leste",
    "Complexo Norte",
    "Vetor Japuí",
    "Vetor Vale Novo",
    "Complexo Humaitá",
    "Complexo Quarentenário",
    "Complexo Jardim Rio Branco Público",
    "Complexo Vila Nova São Vicente",
    "Complexo Vila Nova Samaritá"]

    this.inadequacaoList = ["Loteamento Irregular",
    "Favela"]

    this.propriedadeList = ["Particular",
      "Pública Municipal",
      "Pública Estadual",
      "Pública Federal",
      "Pública Mista: Municipal/Estadual/Federal",
      "Pública Mista: Municipal/Estadual",
      "Pública Mista: Municipal/Federal",
      "Pública Mista: Estadual/Federal",
      "Mista: Particular/Municipal/Estadual/Federal",
      "Mista: Particular/Municipal/Estadual",
      "Mista: Particular/Municipal/Federal",
      "Mista: Particular/Estadual/Federal",
      "Mista: Particular/Municipal",
      "Mista: Particular/Estadual",
      "Mista: Particular/Federal"]

      this.tipologiaList = ["Assentamento Urbanizável",
        "Assentamento Não Urbanizável",
        "Assentamento Já Urbanizado",
        "Parcialmente Urbanizável"]

      this.loadSettlementFromFeature();
      this.dateAdapter.setLocale('pt-BR');

      this.calculatingArea = false;
      this.calculatingCoberturas = false;
      this.readOnly = data.readOnly;
  }

  loadSettlementFromFeature()
  {
    this.settlement = new Settlement();
    
    {
      let id = this.featureService.getPropertyByKey(this.currentFeature.properties, "id").value;    
      this.settlement.id = id!=null?parseInt(id.toString()):null;      
    }
    {
      let sigla = this.featureService.getPropertyByKey(this.currentFeature.properties, "sigla_nucleo").value;    
      this.settlement.sigla = sigla!=null?sigla.toString():"";
    }
    {
      let codigoPLHIS = this.featureService.getPropertyByKey(this.currentFeature.properties, "codigo_phlis").value;
      this.settlement.codigoPLHIS = codigoPLHIS!=null?codigoPLHIS.toString():"";
    }
    {
      let nucleo = this.featureService.getPropertyByKey(this.currentFeature.properties, "nucleo").value;
      this.settlement.nucleo = nucleo!=null?nucleo.toString():"";
    }
    {
      let poligonal = this.featureService.getPropertyByKey(this.currentFeature.properties, "poligonal").value;
      this.settlement.poligonal = poligonal!=null?poligonal.toString():"";
    }    
    {
      let tipoInadequacao = this.featureService.getPropertyByKey(this.currentFeature.properties, "tipo_inadequacao").value;
      this.settlement.tipoInadequacao = tipoInadequacao!=null?tipoInadequacao.toString():"";
    } 
    {
      let origem = this.featureService.getPropertyByKey(this.currentFeature.properties, "origem").value;
      this.settlement.origem = origem!=null?origem.toString():"";
    } 
    {
      let areaTotal = this.featureService.getPropertyByKey(this.currentFeature.properties, "area_total").value;
      this.settlement.areaTotal = areaTotal!=null?parseInt(areaTotal.toString()).toString():"";
    } 
    {
      let propriedade = this.featureService.getPropertyByKey(this.currentFeature.properties, "propriedade").value;
      this.settlement.propriedade = propriedade!=null?propriedade.toString():"";
    } 
    {
      let tipologia = this.featureService.getPropertyByKey(this.currentFeature.properties, "tipologia").value;
      this.settlement.tipologia = tipologia!=null?tipologia.toString():"";
    } 
    {
      let nroDomicilios = this.featureService.getPropertyByKey(this.currentFeature.properties, "nro_domicilios").value;
      this.settlement.nroDomicilios = nroDomicilios!=null?nroDomicilios.toString():"";
    } 
    {
      let nroCoberturas = this.featureService.getPropertyByKey(this.currentFeature.properties, "nro_coberturas").value;
      this.settlement.nroCoberturas = nroCoberturas!=null?nroCoberturas.toString():"";
    }  
    {
      let historicoQtdDomicilios = this.featureService.getPropertyByKey(this.currentFeature.properties, "historico_contagem_domicilios").value;
      if(historicoQtdDomicilios)
      {
        this.settlement.historicoQtdDomicilios = JSON.parse(historicoQtdDomicilios.toString());
      }
      else
      {
        this.settlement.historicoQtdDomicilios = [];
      }      
    }  

    this.loadAllDocuments();
  }

  private loadAllDocuments()
  {    
    this.loadDocuments();
    this.loadProjectsDocuments();
    this.loadJudicialEncumbrancesDocuments();
  }

  private loadDocuments()
  {    
    this.documentService.getDocumentos(AbstractDocumentType.SETTLEMENT_DOCUMENTS,this.currentFeature.id).then((documentos:Documentos)=>
    {
      this.settlement.documentos=documentos;
    });
  }

  private loadProjectsDocuments()
  {
    this.documentService.getDocumentos(AbstractDocumentType.SETTLEMENT_PROJECTS_DOCUMENTS,this.currentFeature.id).then((documentos:Documentos)=>
    {
      this.settlement.projetos=documentos;
    });
  }
  private loadJudicialEncumbrancesDocuments()
  {
    this.documentService.getDocumentos(AbstractDocumentType.SETTLEMENT_JUDICIAL_DOCUMENTS,this.currentFeature.id).then((documentos:Documentos)=>
    {
      this.settlement.gravames=documentos;
    });
  }


  calculateArea(id: string)
  {   

    this.calculatingArea = true;
    

    let geomAttribute: string = this.layersConfigService.getLayerGeomAttribute(this.tool.layer);
    let idAttribute: string = this.layersConfigService.getLayerIdAttribute(this.tool.layer);    
    let table: string = this.layersConfigService.getLayerTable(this.tool.layer);        

    let geooperationarea: Geooperationarea = new Object();
    let layer: GeooperationareaLayer = new Object();
    layer.arealayerGeomAttribute = geomAttribute;
    layer.arealayerIdAttribute = idAttribute;
    layer.arealayerIdValue = id;
    layer.arealayerTable = table;
    geooperationarea.layer = layer;

    this.backendService.calculateArea(geooperationarea).then((area_total)=>{
      this.toastService.sucess("Área calculada com sucesso.", "Sucesso");
      this.calculatingArea = false;
      this.settlement.areaTotal = area_total['area']

    }).catch(()=>{
      this.toastService.error("Erro ao recalcular área.", "Erro");
      this.calculatingArea = false;
    });
  }
  calculateNroCoberturas(id: string)
  { 

    let settlementEdificationLayerName = this.layersConfigService.getLayerAppConfig(this.tool.layer, this.toolSettlementEdificationLayerNameAttributeConfig);

    let settlementEdificationLayer = this.layersConfigService.getOverLayerById(settlementEdificationLayerName);

    if(settlementEdificationLayerName && settlementEdificationLayer)
    {
      this.calculatingCoberturas = true;
      
  
      let geooperationcontains: Geooperationcontains = new Object();
      let layer1: GeooperationcontainsLayer1 = new Object();
      let layer2: GeooperationcontainsLayer2 = new Object();
  
      {
        let geomAttribute: string = this.layersConfigService.getLayerGeomAttribute(this.tool.layer);
        let idAttribute: string = this.layersConfigService.getLayerIdAttribute(this.tool.layer);    
        let table: string = this.layersConfigService.getLayerTable(this.tool.layer);        

        layer1.containslayer1GeomAttribute = geomAttribute;
        layer1.containslayer1IdAttribute = idAttribute;
        layer1.containslayer1IdValue = id;
        layer1.containslayer1Table = table;
      }      

      {
        let edificationGeomAttribute: string = this.layersConfigService.getLayerGeomAttribute(settlementEdificationLayer);
        let edificationTable: string = this.layersConfigService.getLayerTable(settlementEdificationLayer);             
    
        layer2.containslayer2GeomAttribute = edificationGeomAttribute;
        layer2.containslayer2Table = edificationTable;
      }
      
      geooperationcontains.layer1 = layer1;
      geooperationcontains.layer2 = layer2;
  
  
      this.backendService.containsOperation(geooperationcontains).then((features)=>{
        this.toastService.sucess("Quantidade de coberturas recalculadas com sucesso.", "Sucesso");
        this.calculatingCoberturas = false;
        this.settlement.nroCoberturas = features.length
  
      }).catch(()=>{
        this.toastService.error("Erro ao recalcular coberturas.", "Erro");
        this.calculatingCoberturas = false;
      });
    }
    else
    {
      this.toastService.error("Faltando configuração da camada de edificações de assentamento precário ou camada não encontrada.", "Erro");
      console.log("Missing " + this.toolSettlementEdificationLayerNameAttributeConfig + " variable on the tool layer application config.")
    }

   
  }
  public formatDate(date: Date, format: string) : string
  {
    let data="";
    if(date)
    {
      let datePipe = new DatePipe('pt-BR');
      data = datePipe.transform(date.toString(), format);
    }
 

    return data;
  }
  

  ngOnInit(): void 
  {
    this.historicoDataSource = new MatTableDataSource(this.settlement.historicoQtdDomicilios);
  }

  downloadDocument(id:string, nomeArquivo: string, extension:string)
  {
    this.documentService.getDocument(id, nomeArquivo, extension, this.dialog, true);
  }

  previewDocument(id:string, extension:string)
  {
    let nomeArquivo="";
    this.documentService.getDocument(id, nomeArquivo, extension, this.dialog);
  }

  hasDocumentPreview(document:Document)
  {
    return this.documentService.hasPreview(document)
  }


  public hasEditionPermition()
  {
    let user: Userinfo = this.authenticationService.getCurrentUser();

    if(user)
    {
      //Verifica se o usuário tem permissão de escrita na camada de imoveis
      if(this.tool.layer.isWritableForUser(user))
      {
        //Verifica se o usuário tem perfil editor ou admin
        if(user['usergroup_id']==UserGroup.EDITION.id || user['usergroup_id']==UserGroup.ADMINISTRATOR.id || user['usergroup_id']==UserGroup.DOC_EDITION.id || user['usergroup_id']==UserGroup.SEHAB_READONLY.id)
        {
          return true;
        }
      }
    }
    return false;


    return true;
  }
  
  newDocumentUpload(documentType: number, title: string)
  {    
    
    let extensions = AbstractDocumentType.getInstance(documentType).getSupportedExtensions();
    this.newDocumentoUploadDialog = this.dialog.open(DialogComponent, {
      height: '70%',
      width: '75%',
      data: { component: DocumentUploadComponent, title: title, featureId: this.currentFeature.id, documentType: documentType, supportedExtensions: extensions, tool: this, parentDialog: this}
  });   
  }
  closeDocumentUpload()
  {
    this.loadAllDocuments();
    this.newDocumentoUploadDialog.close();
  }
  deleteDocument(id: string)
  {
    this.documentService.deleteDocument(id).then(()=>
    {      
      this.toastService.sucess("Documento apagado com sucesso.", "Sucesso");
      this.loadAllDocuments();
    }).catch(()=>
    {
      this.toastService.error("Erro ao apagar documento.", "Problemas ao apagar documento.");
    });
  }  
  showNewHistorico()
  {
    this.newHistoricoDomicilio = {

    }
  }
  hideNewHistorico()
  {
    this.newHistoricoDomicilio = null;
  }
  saveHistorico()
  { 
    if(this.ano.value && this.qtd.value)
    {
      this.newHistoricoDomicilio.ano = this.ano.value.year();
      this.newHistoricoDomicilio.qtd = this.qtd.value;
      this.newHistoricoDomicilio.obs = "";
      if(this.obs.value)
      {
        this.newHistoricoDomicilio.obs = this.obs.value;
      }

      for (let i = 0; i < this.settlement.historicoQtdDomicilios.length; i++) {
        const historico = this.settlement.historicoQtdDomicilios[i];
        if(historico.ano == this.newHistoricoDomicilio.ano)        
        {
          this.settlement.historicoQtdDomicilios.splice(i, 1);
        }
      }
      
      this.settlement.historicoQtdDomicilios.push(this.newHistoricoDomicilio);

      this.refresHistoricoTable();
      
      this.newHistoricoDomicilio = null;
      this.ano.setValue("");
      this.qtd.setValue("");
      this.obs.setValue("");
      this.toastService.sucess("Histórico adicionado", "Sucesso");


    }
    else
    {
      this.toastService.error("Adicionar valores válidos de ano e quantidade de domicílios", "Valores inválidos");
    }
    
  }
  chosenYearHandler(normalizedYear: Moment, dp: any) 
  {
    this.ano = new FormControl(moment());
    const ctrlValue = this.ano.value;
    ctrlValue.year(normalizedYear.year());
    this.ano.setValue(ctrlValue);
    dp.close();
  }
  deleteQtdDomicilios(ano)
  {
    let qtdDomicilios = [];
    
    this.settlement.historicoQtdDomicilios.forEach(qtdDomicilio => {
      if(qtdDomicilio.ano != ano)
      {
        qtdDomicilios.push(qtdDomicilio);
      }      
    });
    this.settlement.historicoQtdDomicilios = qtdDomicilios;
    this.refresHistoricoTable();
  }
  refresHistoricoTable()
  {
    this.settlement.historicoQtdDomicilios.sort(function (a, b) {
      return b.ano - a.ano;
    });

    this.historicoDataSource = new MatTableDataSource(this.settlement.historicoQtdDomicilios);
  }
  confirmDeleteDocument(id: string)
  {
    this.confirmDialogRef = this.dialog.open(DialogComponent, {
      height: '30%',
      width: '20%',
      data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente apagar o documento selecionado?" }
    });

    this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
      if (confirmResult) {
        this.deleteDocument(id);
      }
    });
  }
  confirmSave()
  {
    this.confirmDialogRef = this.dialog.open(DialogComponent, {
      height: '30%',
      width: '20%',
      data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente salvar os dados para o assentamento precário?" }
    });

    this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
      if (confirmResult) {
        this.save();
      }
    });

  }
  close()
  {
    this.tool.closeDialog();
  }
  public save()
  {
    if(this.settlement!=null)
    {
      let properties = this.settlement.toProperties();

      this.editionService.updateAttributes(properties, this.tool.layer).then(() => {
        this.toastService.sucess("Sucesso em salvar em Área Irregular.","Sucesso");
        this.close();
      }).catch(()=>{
        this.toastService.error("Falha ao salvar em Área Irregular.","Erro");
      });
    }
    else
    {
      console.log("Current feature não está definido.");
    }
  }
  
  editQtdDomicilios(qtdDomicilio)
  {  
      if(qtdDomicilio)
      {
        this.newHistoricoDomicilio = {};        
        var anoDate = new Date();
        anoDate.setFullYear(qtdDomicilio.ano);
        
        this.newHistoricoDomicilio.ano = qtdDomicilio.ano;
        this.newHistoricoDomicilio.qtd = qtdDomicilio.qtd;
        this.newHistoricoDomicilio.obs = qtdDomicilio.obs;
        this.ano.setValue(moment(anoDate));
        this.qtd.setValue(qtdDomicilio.qtd);
        this.obs.setValue(qtdDomicilio.obs);
      }      

  }
}
