import { FormControl, FormGroup } from "@angular/forms";
import { Importlayercheckinforesult } from "@funcate/sigweb-cti-api";
import { LayerConfig, UserGroupPermission } from "src/app/map/layers/layers.config";
import { LayerTool } from "src/app/map/vo/layertool";
import { UserGroup } from "src/app/map/vo/user-group";
import { UserGroupPermissionConfig } from "src/app/map/vo/user-group-permission";
import { BackendService } from "src/app/service/backend.service";
import { ToastService } from "src/app/service/toast.service";
import { Step } from "../../../../vo/step";
import { ToolsService } from "../../../tools.service";
import { ImportLayerComponent } from "../import-layer.component";

export class ImportLayerPermissionsInfoStep extends Step
 {
    public currentLayerUserGroupsPermissions: UserGroupPermissionConfig[];
    constructor(index: number, private importLayerComponent: ImportLayerComponent, private toastService: ToastService, private toolsService: ToolsService)
    {       
        super(index);        
        this.setTitle("Passo 6: Informações de permissões para a camada.");
        this.setDescription("Defina as permissões de leitura e escrita por grupo de usuários.");  
    }
    execute()
    {      
      this.loadLayerUserGroupsPermission();
    }
    callback(object: any)
    {

    }
    rollback()
    {
    }
    finish()
    {
        
    }
    valid()
    {
      return true;
    } 

  loadLayerUserGroupsPermission() : void
  {
     let allUserGroups = UserGroup.USER_GROUPS;
     this.currentLayerUserGroupsPermissions =[];

     allUserGroups.forEach(userGroup => 
    {
      if(userGroup.id!=UserGroup.NONE.id)
      {
        let layerUserGroupPermission = new UserGroupPermissionConfig(userGroup, false, false);
        this.importLayerComponent.newLayerConfig.getPermission().usergroups.forEach(layerUserGroup => 
        {
          if(userGroup.id==layerUserGroup.id)
          {
            layerUserGroupPermission.read = layerUserGroup.read;
            layerUserGroupPermission.write = layerUserGroup.write;
          }
        });
        this.currentLayerUserGroupsPermissions.push(layerUserGroupPermission);
      }
     });
  }
  onGrupoPermissionChange(userGroupPermissionConfig: UserGroupPermissionConfig)
  {
    let layerPermission: UserGroupPermission=null;
    this.importLayerComponent.newLayerConfig.getPermission().usergroups.forEach((layerUserGroupPermission: UserGroupPermission) => {
      if(layerUserGroupPermission.id==userGroupPermissionConfig.userGroup.id)
      {
        layerPermission=layerUserGroupPermission;
      }
    });

    if(layerPermission!=null)
    {
      //If already exist entry, just update permission
      layerPermission.read = userGroupPermissionConfig.read
      layerPermission.write = userGroupPermissionConfig.write
    }
    else
    {
      //If don't exists entry, add a new UserGroupPermission
      let newPermission: UserGroupPermission =
      {
        id:userGroupPermissionConfig.userGroup.id,
        read:userGroupPermissionConfig.read,
        write:userGroupPermissionConfig.write
      };

      this.importLayerComponent.newLayerConfig.getPermission().usergroups.push(newPermission);
    }
  }
    
}

