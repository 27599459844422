import { ToolsService } from '../tools.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PropertysearchToolComponent } from './propertysearch-tool.component'
import { DialogComponent } from '../../dialog/dialog.component';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../../vo/notification';
import { Subscription } from 'rxjs';
import { Property } from '../../vo/property';
import { Tool } from '../tool';

export class PropertySearchTool extends Tool {

    private dialog: MatDialog;

    cursorType=null;
    id='propertysearch-tool';
    enabled=false;
    name='Search for property info';
    title='Pesquisa de imóveis';
    type='click';
    protected visible:boolean=true;
    icon='house';
    layerTool: boolean=false;
    customParameter=new Map<String, any>();

    // map:L.Map=null;
    // ll: any;

    dialogRef: MatDialogRef<DialogComponent>;

    notificationSubscrition: Subscription;
    private featureId:Property;

    constructor(private notificationService: NotificationService,
        toolsService: ToolsService,
        dialog: MatDialog) 
    {
        super(toolsService)
        this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
            if (notification.event==AppNotification.ON_GEO_ASSOCIATION_EVENT)
            {
                if(notification.object.key && notification.object.value){
                    this.featureId=new Property(notification.object.key,notification.object.value);
                    this.enabled=true;
                    this.toogleAction();
                }
                console.log("Receive the feature id:"+this.featureId.value);
            } 
        });
         
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{
        // this.map=event.sourceTarget;
        // this.ll=event.latlng;
    };

    public toogleAction=()=>{
        if(this.enabled)
        {
          
        }
    }

    public hasAssociation():boolean{
        return (this.featureId?true:false);
    }

    public getFeature():Property{
        return this.featureId;
    }

    public cancelAssociation(){
        this.featureId=null;
    }

    public enableImpl()
    {
        this.dialogRef = this.dialog.open(DialogComponent, {
            height: '80%',
            width: '90%',
            data: { component: PropertysearchToolComponent, title: "Pesquisa de Imóveis", parent: this.dialog }
        });
        
        let whenDialogIsOpen=this.dialogRef.afterOpened().toPromise();
        whenDialogIsOpen.then(
            ()=>{
                this.toolsService.dispatchTool(this);
            }
        );
    }
    public disableImpl()
    {
        
    }
}
