import { ToolsService } from '../tools.service';
import { MatDialog } from '@angular/material/dialog';
import { MergeToolComponent } from './merge-tool.component';
import { Feature } from '../../vo/feature';
import { DialogComponent } from '../../dialog/dialog.component';
import { Tool } from '../tool';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { LayerConfig } from '../../layers/layers.config';
import { LayersConfigService } from '../../layers/layers.config.service';
import { FeatureService } from 'src/app/service/feature.service';
import { ToastService } from 'src/app/service/toast.service';
import { CanvasService } from '../../canvas/canvas.service';
import { Property } from '../../vo/property';


export class MergeTool extends AbstractLayerTool {

    private dialog: MatDialog;

    cursorType='pointer';
    id='merge-layer-tool';
    enabled=false;
    name='Merge Geometry Tool';
    title='Unificação';
    type='click';
    map:L.Map=null;
    icon='queue';
    ll: any;
    protected visible:boolean=true;
    selectedFeature: Feature;
    layerTool: boolean=true;
    customParameter=new Map<String, any>();
    dialogRef: any;

    currentFeature1: any;
    currentFeature2: any;
    featureSRID: string;

    constructor(toolsService: ToolsService, private layerConfigService: LayersConfigService, private featureService: FeatureService, private toastService: ToastService, 
        private canvasService: CanvasService,dialog: MatDialog) 
    { 
        super(toolsService)
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{
        this.map=event.sourceTarget;
        this.ll=event.latlng;
        
        this.loadFeature();
     
    };
    public enableImpl()
    {
        this.currentFeature1=null;
        this.currentFeature2=null;

        let currentLayerTool = this.toolsService.getToolParameter(this, "layer");
        if(currentLayerTool)
        {
            this.layerConfigService.enableLayer(currentLayerTool);
        }  
        this.toastService.sucess("Aponte a primeira geometria para unificação.", "Ferramenta de Unificação Habilitada"); 
    }
    public disableImpl()
    {
    }
    

    public isVisibleForLayer(layer :LayerConfig) : boolean
    {
        return this.layerTool;
    }

    private openMergeDialog()
    {
        this.dialogRef = this.dialog.open(DialogComponent, {
            height: '80%',
            width: '80%',
            data: { component: MergeToolComponent, title: "Unificação", currentFeature1: this.currentFeature1, currentFeature2: this.currentFeature2, featureSRID: this.featureSRID }
        });

        let whenDialogIsOpen=this.dialogRef.afterOpened().toPromise();
        whenDialogIsOpen.then(
            ()=>{
                this.toolsService.dispatchTool(this);
            }
        );

        this.dialogRef.afterClosed().toPromise().then(()=>{
            this.currentFeature1=null;
            this.currentFeature2=null;
        });
    }

    private loadFeature()
    {
        let layer = this.toolsService.getToolParameter(this, "layer");

        let idAttribute = this.layerConfigService.getLayerIdAttribute(layer);

        if(layer)
        {
            this.map.eachLayer( (l)=>{
                if(l["wmsParams"]) {
        
                    if(l["id"]==layer.getId())
                    {
                        this.featureService.getFeatureInfo(this.ll, l, this.map).then(featureInfo => {
            
                            if(featureInfo)
                            {
                                if(featureInfo.features.length>0)
                                {
                                    this.selectedFeature=featureInfo.features[0];
                                    console.log(this.selectedFeature);
                
                
                                    let attributesFilterList: Array<Array<Property>>=[];
                                    let attributeFilterList: Array<Property>=[];
                
                                    let idProperty = this.featureService.getPropertyByKey(this.selectedFeature.properties, idAttribute);

                                    if(idProperty)
                                    {
                                        attributeFilterList.push(idProperty);
                                        attributesFilterList.push(attributeFilterList);

                                        this.featureSRID=featureInfo.features[0].srs.replace("EPSG:","");                                    

                                        this.featureService.getFeatures(layer, featureInfo.features[0].srs , attributesFilterList).then(features => 
                                            {
                                            if(features && features.length>0)
                                            {
                                                if(this.currentFeature1==null)
                                                {
                                                    this.currentFeature1=features[0];    

                                                    this.toastService.sucess("Primeira geometria selecionada, favor apontar a segunda geometria.", "Sucesso");
                                                }
                                                else
                                                {
                                                    if(this.currentFeature1.id==features[0].id)
                                                    {
                                                        this.toastService.warning("Por favor selecione uma geometria diferente que toque a primeira geometria.", "Atenção");
                                                    }
                                                    else
                                                    {
                                                        this.currentFeature2=features[0];  

                                                        this.openMergeDialog();                                                        
                                                    }
                                                }
                                            }
                                            
                                            });
                                    }
                                    else
                                    {
                                    console.log("Property "+ idAttribute +" not found...")
                                    }
                                }
                            }
                        
                        });
                    }
                }
            });
        }
    }


    public isEnabled(layer :LayerConfig) : boolean
    {
        let currentLayerTool = this.toolsService.getToolParameter(this, "layer");
        if(this.enabled && currentLayerTool.getId()==layer.getId() )
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    public close()
    {
        this.dialogRef.close();
    }

}
