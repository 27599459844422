import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-layer-tool',
  templateUrl: './edit-layer-tool.component.html',
  styleUrls: ['./edit-layer-tool.component.css']
})
export class EditLayerToolComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
