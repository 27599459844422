
<div class="loading">
        <div class="loading-inside">
                <mat-spinner></mat-spinner>      
                Gerando relatório...
        </div>
        
</div>


<div id="temp-map-div" style="display: none;"></div>