<mat-accordion class="manage-annotation-accordion">
    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="layername">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header" class="layers-col">
                    <mat-icon class="material-icons layer-icon">format_list_numbered</mat-icon>Anotações
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            
            <mat-form-field class="filtro">
              <mat-label>Filtro</mat-label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Ex. fonte" #input>
            </mat-form-field>

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 annotation-table" matSort *ngIf="dataSource" (matSortChange)="sortData($event)">
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="id"> Id </th>
                <td mat-cell *matCellDef="let element">
                    {{element.id}}
                </td>
              </ng-container>  
              <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="title"> Title </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.title}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="desc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="desc"> Descrição </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.description}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="count">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header="count"> Elementos </th>
                    <td mat-cell *matCellDef="let element">
                      {{ getElementCount(element.desc)}}
                    </td>
                  </ng-container>                

                  <ng-container matColumnDef="acoes">
                    <th mat-header-cell *matHeaderCellDef> Ações </th>
                    <td mat-cell *matCellDef="let element" class=""> 
                        <mat-icon title="Ver no mapa" (click)="fit(element)">fit_screen</mat-icon>
                        <mat-icon title="Editar coleta" (click)="edit(element)">edit</mat-icon>
                        <mat-icon title="Remover coleta"  (click)="confirmDelete(element)">delete</mat-icon>
                        <mat-icon></mat-icon>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                 
            </table>

            <mat-paginator [length]="100"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
            aria-label="Selecione a página" showFirstLastButtons></mat-paginator>

            <div *ngIf="!annotations" class="no-annotation">
                Não existem anotações. Use a ferramenta de "Adicionar anotações".
            </div>
  

        </mat-expansion-panel>
    </ng-container>
</mat-accordion>