import { ClonableObject } from "./clonable-object";
import { Property } from "./property";
import { SettlementPropertyMembroFonteRenda } from "./settlement-property-membro-fonte-renda";
import { SettlementPropertyMembroProgramaSocial } from "./settlement-property-membro-programa-social";

export class SettlementPropertyMembro extends ClonableObject
{
    id: number;
    nome: string;
    data_nascimento: Date;
    naturalidade: string;
    sexo: string;
    estado_civil: string;
    cpf: string;
    rg: string;
    rg_data_exp: Date;
    orgao_expedidor: string;
    escolaridade: string;
    profissao: string;
    telefone: string;
    celular: string;
    telefone_recado: string;
    email: string;
    possui_outro_imovel: boolean;
    renda_individual: number;
    ja_atendido_programa_his: boolean; 

    possuidor: boolean;
    conjuge: boolean;

    deficiencia: string;

    cidpcd: string;

    fonte_renda : SettlementPropertyMembroFonteRenda[];

    //Caso possuidor
    programa_social : SettlementPropertyMembroProgramaSocial[];

    constructor()
    {
        super();
        this.fonte_renda=[];
        this.programa_social=[];
        this.possuidor=true;
        this.id=(Math.floor( Math.random() * 2000000 ) + 1)*-1;
    }

    // clone()
    // {
    //     let newMembro= new SettlementPropertyMembro();

    //     newMembro.id=this.id;
    //     newMembro.nome=this.nome;
    //     newMembro.data_nascimento=this.data_nascimento;
    //     newMembro.naturalidade=this.naturalidade;
    //     newMembro.sexo=this.sexo;
    //     newMembro.estado_civil=this.estado_civil;
    //     newMembro.cpf=this.cpf;
    //     newMembro.rg=this.rg;
    //     newMembro.rg_data_exp=this.rg_data_exp;
    //     newMembro.orgao_expedidor=this.orgao_expedidor;
    //     newMembro.escolaridade=this.escolaridade;
    //     newMembro.profissao=this.profissao;
    //     newMembro.telefone=this.telefone;
    //     newMembro.celular=this.celular;
    //     newMembro.telefone_recado=this.telefone_recado;
    //     newMembro.email=this.email;
    //     newMembro.possui_outro_imovel=this.possui_outro_imovel;
    //     newMembro.renda_individual=this.renda_individual;
    //     newMembro.ja_atendido_programa_his=this.ja_atendido_programa_his;    
    //     newMembro.possuidor=this.possuidor;
    //     newMembro.conjuge=this.conjuge;    
    //     newMembro.deficiencia=this.deficiencia;    
    //     newMembro.cidpcd=this.cidpcd;
    
    //     fonte_renda : SettlementPropertyMembroFonteRenda[];
    
    //     //Caso possuidor
    //     programa_social : SettlementPropertyMembroProgramaSocial[];
    // }

    fromProperties(properties: Array<Property>)
    {
        properties.forEach(property => 
        {
            if(property.key.toString()=="fonte_renda")
            {
                this.fromFonteRendaAuxiliarTable(property.value);
            } else if(property.key.toString()=="programa_social")
            {
                this.fromProgramaSocialAuxiliarTable(property.value);
            } else
            {
                let value : any = property.value;

                this[property.key.toString()] = value;    
            }
            
        });
    }

    private fromFonteRendaAuxiliarTable(tableRows: any)
    {
        this.fonte_renda=[];
        tableRows.forEach(fonte_renda => {
            let newFonteRenda: SettlementPropertyMembroFonteRenda = new SettlementPropertyMembroFonteRenda();
            newFonteRenda.fromProperties(fonte_renda);
            this.fonte_renda.push(newFonteRenda);
        });
    }

    private fromProgramaSocialAuxiliarTable(tableRows: any)
    {
        this.programa_social=[];
        tableRows.forEach(programa_social => {
            let newProgramaSocial: SettlementPropertyMembroProgramaSocial = new SettlementPropertyMembroProgramaSocial();
            newProgramaSocial.fromProperties(programa_social);
            this.programa_social.push(newProgramaSocial);
        });
    }   

    getProgramasSociaisAsString() : string[]
    {
        let itens : string[] = [];

        this.programa_social.forEach(item => {
            itens.push(item.programa_social);
        });

        return itens;
    }

    getFontesRendaAsString() : string[]
    {
        let itens : string[] = [];

        this.fonte_renda.forEach(item => {
            itens.push(item.fonte_renda);
        });

        return itens;
    }
    setFontesRendaAsString(value: string, selecionado: boolean)
    {
        let selected : SettlementPropertyMembroFonteRenda;
        
        for (let i = 0; i < this.fonte_renda.length; i++) {
            const item = this.fonte_renda[i];            
        
            if(item.fonte_renda == value)
            {
                selected = item;
            }
        };

        if(selecionado==false)
        {
            if(selected)
            {
                const index = this.fonte_renda.indexOf(selected);
                this.fonte_renda.splice(index,1);
            }
        }
        else
        {
            if(selected==null)
            {
                let newUso = new SettlementPropertyMembroFonteRenda();
                newUso.fonte_renda=value;
                newUso.id_cadastro_social_imovel_area_irregular=this.id;
                this.fonte_renda.push(newUso);
            }
        }
    }
    setProgramasSociaisAsString(value: string, selecionado: boolean)
    {
        let selected : SettlementPropertyMembroProgramaSocial;
        
        for (let i = 0; i < this.programa_social.length; i++) {
            const item = this.programa_social[i];            
        
            if(item.programa_social == value)
            {
                selected = item;
            }
        };

        if(selecionado==false)
        {
            if(selected)
            {
                const index = this.programa_social.indexOf(selected);
                this.programa_social.splice(index,1);
            }
        }
        else
        {
            if(selected==null)
            {
                let newItem = new SettlementPropertyMembroProgramaSocial();
                newItem.programa_social=value;
                newItem.id_cadastro_social_imovel_area_irregular=this.id;
                this.programa_social.push(newItem);
            }
        }
    }

    getTipoMembro() : string
    {
        if(this.possuidor)
        {
            return "Possuidor";
        } else if(this.conjuge)
        {
            return "Cônjuge";
        } else if(this.conjuge)
        {
            return "Outros Membros";
        }
    }
}
