import { LayerConfig } from "../layers/layers.config";

export class LayerFeatures
 {
    layer: LayerConfig;
    features: any[];
    isVector: boolean;
    
    constructor(layer: LayerConfig, features: any[], isVector: boolean)
    {
        this.layer=layer;
        this.features=features;
        this.isVector=isVector;
    }

    
}
