import { Cadastroimobiliario, Copyadditionalimoveldata, EditionoperationsInner, Historicoprocessoimovel, HistoricoprocessoimovelInner } from "@funcate/sigweb-cti-api";
import { Collection, Feature } from "ol";
import GeoJSON from 'ol/format/GeoJSON';
import Polygon from "ol/geom/Polygon";
import VectorSource from "ol/source/Vector";
import { DialogComponent } from "src/app/map/dialog/dialog.component";
import { GenericConfirmationDialogComponent } from "src/app/map/dialog/generic-confirmation-dialog/generic-confirmation-dialog.component";
import { LayersConfigService } from "src/app/map/layers/layers.config.service";
import { NewImovelLink } from "src/app/map/vo/new-imovel-link";
import { Operation } from "src/app/map/vo/operation";
import { BackendService } from "src/app/service/backend.service";
import { FeatureService } from "src/app/service/feature.service";
import { GeometryService } from "src/app/service/geometry.service";
import { ToastService } from "src/app/service/toast.service";
import { Step } from "../../../vo/step";
import { AddInteraction } from "../../tools-utils/custom-edition-interaction/interaction/add-interaction";
import { CaptureCoordinateInteraction } from "../../tools-utils/custom-edition-interaction/interaction/capture-coord-interaction";
import { SelectInteraction } from "../../tools-utils/custom-edition-interaction/interaction/select-interaction";
import { GetFeatureInfoInteraction } from "../../tools-utils/custom-edition-interaction/interaction/get-feature-info-interaction";
import { SetupImoveisLinkToolComponent } from "../setup-imoveis-link-tool.component";
import { Property } from "src/app/map/vo/property";
import { EditHistoricoComponent } from "../../property-registration/edit-historico/edit-historico.component";
import { Inscricao } from "src/app/map/vo/inscricao";
import { Imoveisrelations } from "@funcate/sigweb-cti-api/model/imoveisrelations";

export class AnexacaoSearchInscricaoSpatilizeStep extends Step
 {
    private confirmDialogRef: any;
    private selectedFeatures: Collection<Feature<any>>;
    private sequentialNewFeatureIndex : number;
    private geoJSONDefaultSRID = '4326'
    addHistoricoDialog: any;
    private historicoProcesso: Historicoprocessoimovel;

    constructor(index: number, private setupImoveisLinkToolComponent: SetupImoveisLinkToolComponent, private toastService: ToastService, 
      private backendService: BackendService, private layersConfigService: LayersConfigService, private featureService: FeatureService)    {       
        super(index);
        this.setupImoveisLinkToolComponent=setupImoveisLinkToolComponent;
        this.setTitle("Passo 2: Indicar e espacializar inscrições");
        this.setDescription("Indicar as inscrições a serem anexadas e inativadas, e buscar e espacializar a nova inscrição");
        this.selectedFeatures=new Collection<Feature<any>>();
        this.sequentialNewFeatureIndex=0;
    }
    execute()
    {

      let layers = [];
      layers.push(this.setupImoveisLinkToolComponent.olImoveisLayer);
      var selectInteraction = new SelectInteraction(this.setupImoveisLinkToolComponent.selectInterectionId,{
        layers: layers,
        features: this.selectedFeatures,
        currentStep: this,
        style: this.setupImoveisLinkToolComponent.imoveisSelectedStyleWithLabelFunction
      });

      this.setupImoveisLinkToolComponent.anexacaoSelectedImoveis = [];
      
      let selectedImovel: NewImovelLink = new NewImovelLink(1, this.setupImoveisLinkToolComponent.currentCadImob.imovel, this.setupImoveisLinkToolComponent.currentFeature);

      this.setupImoveisLinkToolComponent.anexacaoSelectedImoveis.push(selectedImovel);

      const getFeatureInfoInteraction = new GetFeatureInfoInteraction(this.setupImoveisLinkToolComponent.getFeatureInfoInteractionId, this.setupImoveisLinkToolComponent.olImoveisWMSLayer, this.setupImoveisLinkToolComponent.customEditionController.getMap(), this);
      
      this.setupImoveisLinkToolComponent.customEditionController.addInteraction(this.setupImoveisLinkToolComponent.getFeatureInfoInteractionId,getFeatureInfoInteraction);

      const addInteraction = new AddInteraction(this.setupImoveisLinkToolComponent.addInterectionId, {currentStep: this, type: 'MultiPoint', source: this.setupImoveisLinkToolComponent.olImoveisLayer.getSource()});
      this.setupImoveisLinkToolComponent.customEditionController.addInteraction(this.setupImoveisLinkToolComponent.addInterectionId,addInteraction);
     
      //SELECT
      this.setupImoveisLinkToolComponent.customEditionController.addInteraction(this.setupImoveisLinkToolComponent.selectInterectionId,selectInteraction);
    }
    callback(object: any)
    {
      if(object.edificacoesChanged)
      {
        this.updateFeatureSelection(object.selectedFeature);
      }
      else
      {
        if(object.enableInteractionId)
        {
           this.enableInterection(object.enableInteractionId)
        }
        
      }  
    }
    rollback()
    {

    }
    finish()
    {
      if(this.valid())
      {
        this.confirm();
      }
    }
    valid()
    {
      //Pelo menos 2 novos imoveis
      if(this.setupImoveisLinkToolComponent.anexacaoSelectedImoveis.length<2)
      {
        this.toastService.error("Para continuar é necessário selecionar pelo menos 2 imoveis para anexar.","Erro");
        return false;
      }
      if(this.setupImoveisLinkToolComponent.anexacaoNewLinkImoveis.length!=1)
      {
        this.toastService.error("Para continuar é necessário selecionar 1 inscrição como resultado da unificação dos imoveis.","Erro");
        return false
      }
      //Todos imoveis devem possuir Geometria
      for (let i = 0; i < this.setupImoveisLinkToolComponent.anexacaoNewLinkImoveis.length; i++) 
      {
        const newImovelLink = this.setupImoveisLinkToolComponent.anexacaoNewLinkImoveis[i];
        if(newImovelLink.getFeature()==null)        
          {
            this.toastService.error("Para continuar todas as novas inscrições devem possuir um ponto georeferenciado no mapa.","Erro");
            return false;
          }                  
      }      
      return true;  
      
    }

    private enableInterection(interactionId)
    {
    
      this.setupImoveisLinkToolComponent.currentEditionInteraction=null;
     if(interactionId==this.setupImoveisLinkToolComponent.addInterectionId)
      {
        this.setupImoveisLinkToolComponent.customEditionController.disableInteraction(this.setupImoveisLinkToolComponent.selectInterectionId);
        this.setupImoveisLinkToolComponent.customEditionController.disableInteraction(this.setupImoveisLinkToolComponent.deleteInterectionId);
        this.setupImoveisLinkToolComponent.customEditionController.disableInteraction(this.setupImoveisLinkToolComponent.getFeatureInfoInteractionId);
        this.setupImoveisLinkToolComponent.customEditionController.enableInteraction(this.setupImoveisLinkToolComponent.addInterectionId);
      }
      else if(interactionId==this.setupImoveisLinkToolComponent.deleteInterectionId)
      {        
        this.deleteCurrentFeature();

      } else if(interactionId==this.setupImoveisLinkToolComponent.selectInterectionId)
      {
        this.setupImoveisLinkToolComponent.customEditionController.disableInteraction(this.setupImoveisLinkToolComponent.selectInterectionId);
        this.setupImoveisLinkToolComponent.customEditionController.disableInteraction(this.setupImoveisLinkToolComponent.addInterectionId);
        this.setupImoveisLinkToolComponent.customEditionController.disableInteraction(this.setupImoveisLinkToolComponent.getFeatureInfoInteractionId);
        this.setupImoveisLinkToolComponent.customEditionController.enableInteraction(this.setupImoveisLinkToolComponent.selectInterectionId);
      } 
      else if(interactionId==this.setupImoveisLinkToolComponent.getFeatureInfoInteractionId)
      {
        this.setupImoveisLinkToolComponent.customEditionController.disableInteraction(this.setupImoveisLinkToolComponent.selectInterectionId);
        this.setupImoveisLinkToolComponent.customEditionController.disableInteraction(this.setupImoveisLinkToolComponent.addInterectionId);
        this.setupImoveisLinkToolComponent.customEditionController.disableInteraction(this.setupImoveisLinkToolComponent.selectInterectionId);
        this.setupImoveisLinkToolComponent.customEditionController.enableInteraction(this.setupImoveisLinkToolComponent.getFeatureInfoInteractionId);
        
      }
    }    

    private updateFeatureSelection(selectedFeature: NewImovelLink)
    {
      this.selectedFeatures.clear();
      if(selectedFeature)
      {
        this.selectedFeatures.push(selectedFeature.getFeature());
      }
      this.setupImoveisLinkToolComponent.setCurrentSelectedFeature(selectedFeature);
    }

    public onFeatureSelected(selectedFeature: Feature<any>)
    {
      this.setupImoveisLinkToolComponent.anexacaoSelectedImoveis.forEach(newImovelLink => {
        if(newImovelLink.getFeature()==selectedFeature)
        {
          this.setupImoveisLinkToolComponent.setCurrentSelectedFeature(newImovelLink);  
        }        
      });      
      this.setupImoveisLinkToolComponent.anexacaoNewLinkImoveis.forEach(newImovelLink => {
        if(newImovelLink.getFeature()==selectedFeature)
        {
          this.setupImoveisLinkToolComponent.setCurrentSelectedFeature(newImovelLink);  
        }        
      });      
    }

    private deleteCurrentFeature()
    {
       let currentSelectedFeature = this.setupImoveisLinkToolComponent.getCurrentSelectedFeature();
      
       if(currentSelectedFeature)
       {
         this.confirmDialogRef = this.setupImoveisLinkToolComponent.dialog.open(DialogComponent, {
           height: '30%',
           width: '20%',
           data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente apagar a inscrição selecionada?" }
         });
  
         this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
           if(confirmResult)
           {
            //Criando novo array de features
            let deletedSelectedImoveis : NewImovelLink[]  = []    
            let index = 1;        
            this.setupImoveisLinkToolComponent.anexacaoSelectedImoveis.forEach(newImovelLink => {
              if(newImovelLink != currentSelectedFeature)
              {
                deletedSelectedImoveis.push(newImovelLink);
                index++;
              }
            });

            //Remove Geometry
            let imoveisSource : VectorSource<Polygon> = this.setupImoveisLinkToolComponent.olImoveisLayer.getSource();            
            imoveisSource.removeFeature(currentSelectedFeature.getFeature()); 

            this.setupImoveisLinkToolComponent.anexacaoSelectedImoveis = deletedSelectedImoveis;  
            this.setupImoveisLinkToolComponent.currentEditionInteraction=null;          
          }
          else
          {
            this.setupImoveisLinkToolComponent.currentEditionInteraction=null;
          }
        });
      }      
    }
    public onFeatureInserted(feature: Feature<any>)
    {
      if(this.setupImoveisLinkToolComponent.currentEditionInteraction==this.setupImoveisLinkToolComponent.addInterectionId)
      {
        if(feature)
        {
          let geom = feature.getGeometry().clone();
  
          feature.setProperties(this.setupImoveisLinkToolComponent.currentFeature.getProperties());
  
          let imoveisICAttribute = this.layersConfigService.getLayerAppConfig(this.setupImoveisLinkToolComponent.imoveisLayer, this.setupImoveisLinkToolComponent.imoveisICAttributeConfig);          
  
          feature.setGeometry(geom);
  
          //Setting default new attribute id
          feature.set(this.setupImoveisLinkToolComponent.imoveisIdAttribute, "*");
  
          feature.set('index', this.setupImoveisLinkToolComponent.getCurrentSelectedFeature().getIndex());
  
          let ic = this.setupImoveisLinkToolComponent.imovelToInscricaoCompleta(this.setupImoveisLinkToolComponent.getCurrentSelectedFeature().getImovel());
          let setor = this.setupImoveisLinkToolComponent.getCurrentSelectedFeature().getImovel().setor;
          let quadra = this.setupImoveisLinkToolComponent.getCurrentSelectedFeature().getImovel().quadra;
          let logradouro = this.setupImoveisLinkToolComponent.getCurrentSelectedFeature().getImovel().logradouro;
          let lote = this.setupImoveisLinkToolComponent.getCurrentSelectedFeature().getImovel().lote;
          let sublote = this.setupImoveisLinkToolComponent.getCurrentSelectedFeature().getImovel().sublote;
  
          feature.set(imoveisICAttribute, ic);
          feature.set("setor", setor);
          feature.set("quadra", quadra);
          feature.set("logradouro", logradouro);
          feature.set("lote", lote);
          feature.set("sublote", sublote);
  
          this.setupImoveisLinkToolComponent.getCurrentSelectedFeature().setFeature(feature);
  
          this.enableInterection(this.setupImoveisLinkToolComponent.selectInterectionId);
  
        }
      }      
    }

    private confirm()
    { 
          this.confirmDialogRef = this.setupImoveisLinkToolComponent.dialog.open(DialogComponent, {
            height: '30%',
            width: '20%',
            data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente salvar as modificações selecionadas?" }
          });
    
          this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
            if(confirmResult)
            {
              
              //Commit das operações
              this.commitEditionChanges();
          
            }
          });        
    }

    private getOperation(newImoveisLink: NewImovelLink)
    {
      let feature = newImoveisLink.getFeature();

      let layerTable = this.layersConfigService.getLayerTable(this.setupImoveisLinkToolComponent.imoveisLayer);
  
      let layerGeomAttribute = this.layersConfigService.getLayerGeomAttribute(this.setupImoveisLinkToolComponent.imoveisLayer);
  
      let layerIdAttribute = this.setupImoveisLinkToolComponent.imoveisIdAttribute;
  
      let idValue = this.setupImoveisLinkToolComponent.getAttributeValue(feature, layerIdAttribute);

      let format = new GeoJSON();

      let geometry = feature.getGeometry();
      if(geometry.getType()=="Point")
      {
        geometry = GeometryService.convertPointToMultiPoint(geometry);
      }          

      let geoJSON = format.writeGeometry(geometry, {featureProjection: "EPSG:"+this.setupImoveisLinkToolComponent.inputFeatureSRID});

      let currentOperation : EditionoperationsInner = new Object();

      //Verificar se a feição é uma das novas
      if(feature.get("index")!=this.setupImoveisLinkToolComponent.imovelDesativadoLabelKey)
      {
        currentOperation.operationtype=1;    
      }
      else
      {
        currentOperation.operationtype=3;
      }

      let newProperties = [];    

     let properties = this.featureService.convertGJSONPropertiesToPropertyList(feature.getProperties());      

     let imoveisICAttribute = this.layersConfigService.getLayerAppConfig(this.setupImoveisLinkToolComponent.imoveisLayer, this.setupImoveisLinkToolComponent.imoveisICAttributeConfig);          

      properties.forEach(property => {
        if(property.key==imoveisICAttribute
          ||property.key=="setor"
          ||property.key=="quadra"
          ||property.key=="logradouro"
          ||property.key=="lote"
          ||property.key=="sublote")
        {
          newProperties.push(property);
        }
      });

      currentOperation.table=layerTable;
      currentOperation.geomattribute=layerGeomAttribute;
      currentOperation.geom=geoJSON;
      currentOperation.idattribute=layerIdAttribute;
      currentOperation.idvalue=idValue.toString();
      currentOperation.srid=this.geoJSONDefaultSRID;
      currentOperation.properties=newProperties;

      return currentOperation;
    }

    private commitEditionChanges()
    {
      let operations : Array<EditionoperationsInner> = []      

      this.setupImoveisLinkToolComponent.anexacaoNewLinkImoveis.forEach(newImoveisLink => 
      {
        let operation = this.getOperation(newImoveisLink);
        operations.push(operation);                 
      });

      this.setupImoveisLinkToolComponent.anexacaoSelectedImoveis.forEach(newImoveisLink => 
      {
        let operation = this.getOperation(newImoveisLink);
        operations.push(operation);                 
      });

      this.backendService.executeEditionOperations(operations).then((operations)=>
      {

        //Setting newIds on features
        if(operations)
        {
          operations.forEach(operation => 
            {
              if(operation.operationtype==1)
              {
                this.setupImoveisLinkToolComponent.anexacaoNewLinkImoveis[0].getFeature().set(this.setupImoveisLinkToolComponent.imoveisIdAttribute, operation.sucess);
              }
          });
        }
        this.commitLinkChanges();

      }).catch((exception: any)=>
      {
        this.setupImoveisLinkToolComponent.toastService.error(exception.errorMessage, "Erro");
        //this.rollbackHistorico();
        console.log(exception);
      });

    }

    private commitLinkChanges()
    {

      let newImoveisLink : NewImovelLink[] = this.setupImoveisLinkToolComponent.anexacaoNewLinkImoveis;

      let oldImoveisLink : NewImovelLink[] = this.setupImoveisLinkToolComponent.anexacaoSelectedImoveis;

      let copyadditionalimoveldata: Copyadditionalimoveldata = new Object();
      copyadditionalimoveldata['inscricoes_origem']=[];      

      copyadditionalimoveldata['inscricoes_destino']=[];  

      oldImoveisLink.forEach(oldImovelLink => 
        {
          let ic = this.setupImoveisLinkToolComponent.imovelToInscricaoCompleta(oldImovelLink.getImovel());
          copyadditionalimoveldata['inscricoes_origem'].push(ic);
        });      


      newImoveisLink.forEach(newImovelLink => 
      {
        let ic = this.setupImoveisLinkToolComponent.imovelToInscricaoCompleta(newImovelLink.getImovel());
        copyadditionalimoveldata['inscricoes_destino'].push(ic);
      });      

      this.backendService.copyAdditionalImovelData(copyadditionalimoveldata).then(()=>
      {
        this.toastService.sucess("Unificação concluída e vínculos de imóveis criados.", "Sucesso");

        this.updateImoveisRelation();

      }).catch((exception: any)=>{
        //this.rollbackHistorico();
      });

    }

    private updateImoveisRelation()
    {
      let imoveisRelations: Imoveisrelations = [];

      let newImoveisLink : NewImovelLink[] = this.setupImoveisLinkToolComponent.anexacaoNewLinkImoveis;

      newImoveisLink.forEach(newImovelLink => 
        {
        let ic = this.setupImoveisLinkToolComponent.imovelToInscricaoCompleta(newImovelLink.getImovel());
        let imovelRelation = new Object();
        imovelRelation['inscricao'] = ic;
        let imovelId = this.setupImoveisLinkToolComponent.getAttributeValue(newImovelLink.getFeature(), this.setupImoveisLinkToolComponent.imoveisIdAttribute);

        imovelRelation['imovel_id'] = imovelId;

        imoveisRelations.push(imovelRelation);
        
      });

      this.backendService.updateImoveisRelation(imoveisRelations).then((updatedImoveisRelations)=>
      {
        console.log(updatedImoveisRelations);
        
        this.addHistorico(newImoveisLink);        
      });
    }

    public onGetFeatureInfo(featureInfo: any)
    {
      if(featureInfo && featureInfo.features.length>0)
      {
          let imoveisIdAttribute = this.layersConfigService.getLayerIdAttribute(this.setupImoveisLinkToolComponent.imoveisLayer);

          let imoveisICAttribute = this.layersConfigService.getLayerInscricaoAttribute(this.setupImoveisLinkToolComponent.imoveisLayer);

          let imoveisAttributesFilterList: Array<Array<Property>>=[];
          let imoveisAttributeFilterList: Array<Property>=[];

          let properties = this.featureService.convertGJSONPropertiesToPropertyList(featureInfo.features[0].properties);
  
          let imovelIdProperty = this.featureService.getPropertyByKey(properties, imoveisIdAttribute);

          let imovelIcProperty = this.featureService.getPropertyByKey(properties, imoveisICAttribute);
  
          if(imovelIcProperty 
              && imovelIcProperty.value 
              && imovelIcProperty.value.toString().length > 0 )
              {
                  if(imovelIdProperty)
                  {
                      let currentInscricao = imovelIcProperty.value.toString();
                      imoveisAttributeFilterList.push(imovelIdProperty);
                      imoveisAttributesFilterList.push(imoveisAttributeFilterList);
              
                      this.featureService.getFeatures(this.setupImoveisLinkToolComponent.imoveisLayer, 'EPSG:3857' , imoveisAttributesFilterList).then(features => 
                      {
                          if(features && features.length>0)
                          {                             

                              this.backendService.getCadImob(currentInscricao).then((cadImob: Cadastroimobiliario)=>{
                                let index = this.setupImoveisLinkToolComponent.anexacaoSelectedImoveis.length+1;

                                let feature = this.setupImoveisLinkToolComponent.loadFeature(features[0], 'EPSG:3857');

                                let newImoveis : NewImovelLink = new NewImovelLink(index, cadImob.imovel, feature);

                                this.setupImoveisLinkToolComponent.addAnexacaoSelectedImovel(newImoveis);
                                
                              });

                              
                          }
                      });
                  }
                  else
                  {
                      console.log("Property "+ imoveisIdAttribute +" not found...")
                  }
              }
              else
              {
                  this.toastService.error("Imóvel selecionado não possui inscrição cadastral válida.", "Erro")
              }

          
      }
      else
      {
        this.toastService.warning("Não foram encontrados imoveis na localização apontada.", "Aviso");
      }    
      this.enableInterection(this.setupImoveisLinkToolComponent.selectInterectionId);
    }

    private addHistorico(newImoveisLink : NewImovelLink[])
    {  
      if(newImoveisLink && newImoveisLink.length>0)
      {
        let icList : Inscricao[]= [];

        
        newImoveisLink.forEach(newImovelLink => 
        {
          let icStr = this.setupImoveisLinkToolComponent.imovelToInscricaoCompleta(newImovelLink.getImovel());

          let ic =  new Inscricao(icStr);

          icList.push(ic);
          
        });  

        let item : HistoricoprocessoimovelInner = new Object;
  
        this.addHistoricoDialog = this.setupImoveisLinkToolComponent.dialog.open(DialogComponent, {
          height: '60%',
          maxHeight: '60%',
          maxWidth: '80%',
          data: { component: EditHistoricoComponent, title: "Adicionar histórico de processo para o imóvel unificado", historico: item, icList: icList, parent: this  }
        });
    
        this.addHistoricoDialog.afterClosed().toPromise().then(()=>{
          
        });
      }
      else
      {
        this.toastService.error("Esse lote não possui inscrição associada, sendo assim, não é possível realizar a unificação, nem a criação do histório de processo.", "Erro ao Salvar")
      }  
  
    }
  
    //Após fechar a adição do historico do processo, se voltar um o ID, passamos pro proxima passo e guardamos o ID para caso de precisar voltar atrás
    public closeEditHistorico(historicoProcesso: Historicoprocessoimovel)
    {
      if(historicoProcesso.length>0)
      {
        if(historicoProcesso[0].id)
        {
          if(this.addHistoricoDialog)
          {
            this.addHistoricoDialog.close();

            this.historicoProcesso = historicoProcesso;

            this.setupImoveisLinkToolComponent.tool.close();
          }
        }
      }
      
    }

}
