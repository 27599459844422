import { FormControl, FormGroup } from "@angular/forms";
import { Importlayercheckinforesult } from "@funcate/sigweb-cti-api";
import { JsonEditorOptions } from "ang-jsoneditor";
import { DialogComponent } from "src/app/map/dialog/dialog.component";
import { GenericConfirmationDialogComponent } from "src/app/map/dialog/generic-confirmation-dialog/generic-confirmation-dialog.component";
import { LayerConfig, UserGroupPermission } from "src/app/map/layers/layers.config";
import { LayerTool } from "src/app/map/vo/layertool";
import { UserGroup } from "src/app/map/vo/user-group";
import { UserGroupPermissionConfig } from "src/app/map/vo/user-group-permission";
import { BackendService } from "src/app/service/backend.service";
import { ToastService } from "src/app/service/toast.service";
import { Step } from "../../../../vo/step";
import { ToolsService } from "../../../tools.service";
import { ImportLayerComponent } from "../import-layer.component";

export class ImportLayerAdvancedConfigInfoStep extends Step
 {
    public layerAdvancedConfigJson: any;
    layerAdvancedConfigEditorOptions = new JsonEditorOptions();
    private confirmDialogRef: any;
    constructor(index: number, private importLayerComponent: ImportLayerComponent, private toastService: ToastService, private toolsService: ToolsService)
    {       
        super(index);        
        this.setTitle("Passo 7: Informações avançadas da camada.");
        this.setDescription("Revise as configurações avançadas para a camada.");  
    }
    execute()
    {      
      this.layerAdvancedConfigEditorOptions.mode = 'code';
      this.layerAdvancedConfigEditorOptions.modes = ['code'];
      this.layerAdvancedConfigEditorOptions.statusBar = true;
      this.layerAdvancedConfigEditorOptions.onChange = () => this.onAdvancedLayerConfigChanged();
      this.loadLayerApplicationConfigs();
    }
    callback(object: any)
    {

    }
    rollback()
    {
    }
    finish()
    {
      this.confirmDialogRef = this.importLayerComponent.parent.open(DialogComponent, {
        height: '30%',
        width: '20%',
        data: { component: GenericConfirmationDialogComponent, title: "Importar Camada", confirmTitle: "", confirmMessage: "Deseja realmente importar a camada?" }
      });
  
      this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
        if (confirmResult) {
          this.importLayerComponent.importLayer();
        }
  
      });
    }
    valid()
    {
      return true;
    }  
    loadLayerApplicationConfigs()
    {
      this.layerAdvancedConfigJson={};
      if(this.importLayerComponent.newLayerConfig.getApplicationconfigs().length>0)
      {
        
        this.layerAdvancedConfigJson=this.importLayerComponent.newLayerConfig.getApplicationconfigs()[0];
      }    
    }
    onAdvancedLayerConfigChanged()
    {
      if(this.importLayerComponent.newLayerConfig.getApplicationconfigs().length==0)
      {
        this.importLayerComponent.newLayerConfig.getApplicationconfigs().push({});
      }
      this.importLayerComponent.newLayerConfig.getApplicationconfigs()[0] = this.importLayerComponent.layerAdvancedConfigEditor.get();
      
    }
    
}

