import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/map/dialog/dialog.component';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';
import { LayersInfo } from 'src/app/map/vo/layersinfo';
import { FeatureService } from 'src/app/service/feature.service';
import { Feature } from '../../../vo/feature';
import { FetureInfoToolComponent } from '../../fetureinfo-tool/fetureinfo-tool.component';
import { SettlementTool } from '../../settlement-tools/settlement-tool';
import { SettlementComponent } from '../../settlement-tools/settlement.component';
import { AttributeSearchFeatureInfo } from './attribute-search-feature-info';

export class AttributeSearchSettlementPropertyInfo extends AttributeSearchFeatureInfo
{
    static customizedFeatureInfo: string = "attribute-search-settlement-property-info";

    constructor(parent: MatDialog,  tool: any, feature: Feature, layerConfig: LayerConfig, private layerConfigService: LayersConfigService,
        private featureService: FeatureService)
    {
        super(parent, tool, feature, layerConfig);        
    }
    showFeatureInfo()
    {
        let idAttribute=this.layerConfigService.getLayerIdAttribute(this.layerConfig);
        let layerTableName = this.layerConfigService.getLayerTable(this.layerConfig);
        let idValueProperty = this.featureService.getPropertyByKey(this.feature.properties, idAttribute);  

        this.tool.currentFeature = this.feature;
        this.tool.layer = this.layerConfig;

        return  this.tool.loadFeatureAttributes(layerTableName, idAttribute, idValueProperty.value.toString());
        
        
        //this.tool.openSettlementProperty();       
    }
}
