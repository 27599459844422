import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator} from '@angular/material/paginator';
import { Imoveis } from '@funcate/sigweb-cti-api/model/imoveis';
import { Subscription } from 'rxjs';
import { BackendService } from '../../../service/backend.service';
import { ToolsService } from '../tools.service';
import { Imovel } from '../../vo/imovel';
import { Inscricao } from '../../vo/inscricao';
import { ImovelService } from 'src/app/service/imovel.service';
import { ToastService } from 'src/app/service/toast.service';
import { MatTableDataSource } from '@angular/material/table';
import { PropertySearchTool } from './propertysearch-tool';
import { Property } from '../../vo/property';
import { GeneralService } from 'src/app/service/general.service';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { PropertyRegistrationComponent } from '../property-registration/property-registration.component';
import { Fotosfachada, Historicoprocessoimovel, HistoricoprocessoimovelInner } from '@funcate/sigweb-cti-api';
import { EditHistoricoComponent } from '../property-registration/edit-historico/edit-historico.component';
import { FotosfachadaManagerComponent } from '../fotosfachada-manager/fotosfachada-manager.component';
import { FotosFachadaManagerTool } from '../fotosfachada-manager/fotosfachada-manager-tool';
import { FichaUploadComponent } from '../property-registration/ficha-upload/ficha-upload.component';
import { MatSort, Sort } from '@angular/material/sort';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
//import { FilterImp } from '../../vo/filter';

@Component({
  selector: 'app-propertysearch-tool',
  templateUrl: './propertysearch-tool.component.html',
  styleUrls: ['./propertysearch-tool.component.scss']
})
export class PropertysearchToolComponent implements OnInit, OnDestroy {

  // for data table header
  displayedColumns: string[] = ['id','setor','quadra','logradouro','lote','sublote','endereco_imovel','numero_imovel','complemento_imovel','cep_imovel','bairro_imovel', 'status','select'];
  dataSource:MatTableDataSource<Imovel>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  selection = new SelectionModel<Imovel>(true, []);
  mtpLength=0;// for table paginator
  propertyRegistrationDialogRef: MatDialogRef<DialogComponent>;
  parent: any;
  
  propertySearchTool:PropertySearchTool;
  subscriptionTool:Subscription;
  message:string;
  associationMessage:string;
  errorMessage:string;
  ctrlLoading:boolean;
  ctrlAssociate:boolean;
  isValidFormSubmitted:boolean;
  validationMessage:string;
  editHistoricoDialog: any;
  fotosFachadasManagerDialog: any;
  addDocumentDialog: any;

  setorLength: number = 2;
  quadraLength: number = 5;
  logradouroLength: number = 4;
  loteLength: number = 5;
  subloteLength: number = 3;
  exporting: boolean;

  propertySearchControl: FormGroup = new FormGroup(
    {
      setor: new FormControl(),
      quadra: new FormControl(),
      logradouro: new FormControl(),
      lote: new FormControl(),
      sublote: new FormControl(),
      endereco_imovel: new FormControl(),
      numero_imovel: new FormControl(),
      complemento_imovel: new FormControl(),
      bairro_imovel: new FormControl(),
      cep_imovel: new FormControl(),
      without_geometry: new FormControl(),
      status: new FormControl()
    }
  );

  // identificacao
  setor:number;
  quadra:number;
  logradouro:number;
  lote:number;
  sublote:number;

  // endereco
  endereco_imovel:string;
  numero_imovel:string;
  complemento_imovel:string;
  bairro_imovel:string;
  cep_imovel:string;

  // association feature
  featureId:Property;

  //start variables
  searchOnly: boolean;
  oneOnly: boolean;
  withoutGeometry: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  private toolService: ToolsService,
    private backendService: BackendService,
    private imovelService: ImovelService,
    private toastService : ToastService,
    private generalService: GeneralService) {
    this.dataSource=new MatTableDataSource<Imovel>();
    this.ctrlLoading=false;
    this.ctrlAssociate=false;
    this.isValidFormSubmitted=true;
    this.subscriptionTool=this.toolService.toolsObservable.subscribe(
      (propertySearchTool:PropertySearchTool) => {
        this.propertySearchTool=propertySearchTool;
        this.message="";

        // TODO: insert authentication user control
        if(this.propertySearchTool.hasAssociation()){
          this.featureId=this.propertySearchTool.getFeature();
          this.associationMessage="ATENÇÃO: Há um lote selecionado para associação com o cadastro. Ao encontrar o item no cadastro, selecione-o e use o botão associar.";
          this.swapSelectionColum('select','radio');
          this.ctrlAssociate=true;
        }

      }      
    );
    this.exporting = false;
    this.parent=data.parent;
    if(data.searchOnly && data.searchOnly==true)
    {
      this.searchOnly = true;

      if(data.oneOnly && data.oneOnly==true)
      {
        this.oneOnly = true;
      }
      else
      {
        this.oneOnly = false;
      }
    }
    else
    {
      this.searchOnly = false;
      this.oneOnly = false;
    }

    if(data.withoutGeometry && data.withoutGeometry==true)
    {
      this.withoutGeometry = true;
    }
    else
    {
      this.withoutGeometry = false;
    }
    this.propertySearchControl.get('without_geometry').setValue(this.withoutGeometry);
    this.propertySearchControl.get('status').setValue("active");
  }

  private swapSelectionColum(old:string, aNew:string){
    let pos=this.displayedColumns.findIndex((i)=>{
      if(i==old) return true;
    });
    this.displayedColumns.splice(pos,1,aNew);
  }

  public validateLength(field: string, value: any)
  {
    if(value.target.value)
    {
      let v: any;
      switch (field) {
        case 'setor':
          v = this.generalService.fillZero(2, value.target.value);
          this.propertySearchControl.get('setor').setValue(v, { emitEvent: false} );
          break;
        case 'quadra':
          v = this.generalService.fillZero(5, value.target.value);
          this.propertySearchControl.get('quadra').setValue(v, { emitEvent: false} );
          break;
        case 'logradouro':
          v = this.generalService.fillZero(4, value.target.value);
          this.propertySearchControl.get('logradouro').setValue(v, { emitEvent: false} );
          break;
        case 'lote':
          v = this.generalService.fillZero(5, value.target.value);
          this.propertySearchControl.get('lote').setValue(v, { emitEvent: false} );
          break;
        case 'sublote':
          v = this.generalService.fillZero(3, value.target.value);
          this.propertySearchControl.get('sublote').setValue(v, { emitEvent: false} );
          break;

        default:
          break;
      }
    }
  }

  /** Search with the filters from form. */
  public onFormSubmit() {
    
    this.isValidFormSubmitted = false;
    if (this.propertySearchControl.invalid) {
      this.validationMessage="O campo CEP deve conter 8 digitos";
      return;
    }
    
    let formValues=this.propertySearchControl.value;
    for(let prop in formValues){
      if(formValues.hasOwnProperty(prop) && formValues[prop]) this.isValidFormSubmitted = true;
    }
    if(!this.isValidFormSubmitted) this.validationMessage="Forneça valor para ao menos um campo";
    else {
      // TODO: Use mapped object from swagger package
      //let filter=new FilterImp(formValues);

      // clean old results before search
      this.mtpLength=0;
      this.dataSource=new MatTableDataSource<Imovel>();
      this.loadDataFromAPI(formValues);
    }
  }

  public loadDataFromAPI(filter:any){
    this.ctrlLoading=true;
    this.message="Consultando...";
    if(this.backendService)
    {
      for (var prop in filter) {
        if (Object.prototype.hasOwnProperty.call(filter, prop)) {
            if (!filter[prop]){
              filter[prop]=(prop=="without_geometry")?(false):("");
            }
        }
      }
      this.backendService.getImoveis(filter)
      .then((imoveis: Imoveis)=>{
        this.onServiceResponse(imoveis);
      }).catch((error)=>{
        this.errorMessage="Ops, algo não ocorreu como o esperado com o serviço de informações de cadastro imobiliário.";
        console.error(error);
      }).finally(()=>{
        this.message="";
        this.ctrlLoading=false;
      });
    }
  }

  public onServiceResponse(imoveis: Imoveis){
    let _imoveis=[];
    imoveis.some((imovel,id)=>{
      _imoveis.push(new Imovel(id,imovel));
    });
    this.dataSource = new MatTableDataSource<Imovel>(_imoveis);
    this.dataSource.paginator = this.paginator;
    this.mtpLength=this.dataSource.data.length;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    if(!this.dataSource) return;
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Imovel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  public onHighlightSelectedFeatures()
  {
    this.toastService.info("Localizando...", "");
    if(!this.selection.hasValue()){
      return;
    }

    let selectedImoveis:Imovel[]=this.getSelectedImoveis();

    let inativos: boolean = false;
    selectedImoveis.forEach(imovel => {
      if(imovel.ativo==false)
      {
        inativos = true;
      }
    });
    if(inativos)
    {
      this.toastService.warning("Um ou mais imóveis selecionados estão inativos.","Aviso");   
    }
    
    let selectedItens:Inscricao[]=this.getSelectedInscricoes();

    if(selectedItens.length)
    {           
      this.imovelService.fitFeatures(selectedItens);
    }

  }
  public onFieldsClean(){
    this.propertySearchControl.reset();
  }

  public onAssociationAdd(){
    let selectedItem:Inscricao;
    this.dataSource.filteredData.some(row => {
      if(this.selection.isSelected(row)){
        let inscricao=Inscricao.fromImovel(row);
        selectedItem=inscricao;
        return true;
      }
    });
    let cadImov=selectedItem.setor+selectedItem.quadra+selectedItem.logradouro+selectedItem.lote+selectedItem.sublote;
    console.log("Associar cadastro imovel: "+cadImov+" com geo: "+this.featureId.key+"="+this.featureId.value);
    if(this.backendService)
    {
      this.backendService.associateGeo(cadImov,this.featureId.value.toString())
      .then(()=>{
        this.message="Associação concluida.";
        this.onAssociationCancel();
      }).catch((error)=>{
        this.errorMessage="Ops, algo não ocorreu como o esperado com o serviço de informações de cadastro imobiliário.";
        console.error(error);
      }).finally(()=>{
        this.message="";
        this.ctrlLoading=false;
      });
    }
  }

  public onAssociationCancel(){
    this.ctrlAssociate=false;
    this.swapSelectionColum('radio','select');
    this.featureId=null;
    this.associationMessage=null;
    this.propertySearchTool.cancelAssociation();
  }

  public enableAssociate(){
    // TODO: insert authentication user control
    return this.ctrlAssociate;
  }

  public hasResult():boolean{
    return this.mtpLength>0;
  }

  ngOnInit(): void 
  {
    this.propertySearchControl.get('setor').valueChanges.subscribe(value => {
      value = this.generalService.removeNAN(value);
      let v = this.generalService.trimSize(this.setorLength, value);
      this.propertySearchControl.get('setor').setValue(v, { emitEvent: false} );
    });
    this.propertySearchControl.get('quadra').valueChanges.subscribe(value => {
     value = this.generalService.removeNAN(value);
     let v = this.generalService.trimSize(this.quadraLength, value);
     this.propertySearchControl.get('quadra').setValue(v, { emitEvent: false} );
   });
   this.propertySearchControl.get('logradouro').valueChanges.subscribe(value => {
     value = this.generalService.removeNAN(value);
     let v = this.generalService.trimSize(this.logradouroLength, value);
     this.propertySearchControl.get('logradouro').setValue(v, { emitEvent: false} );
   });
   this.propertySearchControl.get('lote').valueChanges.subscribe(value => {
     value = this.generalService.removeNAN(value);
     let v = this.generalService.trimSize(this.loteLength, value);
     this.propertySearchControl.get('lote').setValue(v, { emitEvent: false} );
   });
   this.propertySearchControl.get('sublote').valueChanges.subscribe(value => {
     value = this.generalService.removeNAN(value);
     let v = this.generalService.trimSize(this.subloteLength, value);
     this.propertySearchControl.get('sublote').setValue(v, { emitEvent: false} );
   });
  }

  ngOnDestroy(): void {
    this.subscriptionTool.unsubscribe();
  }

  onPaste(event)
  {
    if(event.target &&
      (event.target.id='propertysearch-tool-setor') ||
      (event.target.id='propertysearch-tool-quadra') ||
      (event.target.id='propertysearch-tool-logradouro') ||
      (event.target.id='propertysearch-tool-lote') ||
      (event.target.id='propertysearch-tool-sublote'))
      {
        let clipboardData = event.clipboardData;

        if(clipboardData)
        {
          let clipboardText = clipboardData.getData("text")
          if(clipboardText)
          {
            //Tirando os pontos se existirem
            clipboardText=clipboardText.replaceAll(".", "");

            if(clipboardText.length==19)
            {
              let inscricao: Inscricao = new Inscricao(clipboardText);

              this.propertySearchControl.get('setor').setValue(inscricao.setor);
              this.propertySearchControl.get('quadra').setValue(inscricao.quadra);
              this.propertySearchControl.get('logradouro').setValue(inscricao.logradouro);
              this.propertySearchControl.get('lote').setValue(inscricao.lote);
              this.propertySearchControl.get('sublote').setValue(inscricao.sublote);

              this.onFormSubmit();
            }            
          }
        }
      }
  }

  getSelectedInscricoes() : Inscricao[]
  {
    let selectedItens:Inscricao[]=[];
    this.dataSource.filteredData.forEach(row => {
      if(this.selection.isSelected(row)){
        let inscricao=Inscricao.fromImovel(row);
        selectedItens.push(inscricao);
      }
    });

    return selectedItens;
  }
  getSelectedImoveis() : Imovel[]
  {
    let selectedImoveis:Imovel[]=[];
    this.dataSource.filteredData.forEach(imovel => {
      if(this.selection.isSelected(imovel)){
        selectedImoveis.push(imovel);
      }
    });
    return selectedImoveis;    
  }

  

  showInfo()
  {

    let selectedItens:Inscricao[]=this.getSelectedInscricoes();

    //Only one selection at time
    if(selectedItens.length!=1)
    {
      return;
    }       
    
    let inscricao : Inscricao = selectedItens[0];

    this.propertyRegistrationDialogRef = this.parent.open(DialogComponent, {
      height: '90%',
      width: '95%',
      data: { component: PropertyRegistrationComponent, title: "Informações da inscrição imobiliária", ic: inscricao, featureId: new Property("",""), parent: this.parent }
  });

  }

  public addHistoricoProcesso()
  {
    let selectedItens:Inscricao[]=this.getSelectedInscricoes();

    if(selectedItens.length<1)
    {
      return;
    } 

    let item : HistoricoprocessoimovelInner = new Object;

    this.editHistoricoDialog = this.parent.open(DialogComponent, {
      height: '60%',
      maxHeight: '60%',
      maxWidth: '80%',
      data: { component: EditHistoricoComponent, title: "Adicionar histórico de processo para o imóvel", historico: item, parent: this, icList: selectedItens }
    });

  }


  public showFotosFachadaManager() : boolean
  {
    let selectedItens:Inscricao[]=this.getSelectedInscricoes();
    if(selectedItens.length==0)
    {
      return false;
    }
    else
    {
      if(this.isAllSelectedSameLote())
      {
        let fotosManagerTool = this.toolService.getToolByType(FotosFachadaManagerTool);
        return this.toolService.isToolVisibleForCurrentUser(fotosManagerTool);
      }
      else
      {
        return false;
      }
    }

  }

  public isAllSelectedSameLote() : boolean
  {
    let selectedItens:Inscricao[]=this.getSelectedInscricoes();
    if(selectedItens.length==0)
    {
      return false;
    }

    if(selectedItens.length==1)
    {
      return true;
    }
    else
    {
      let currentSqll: string = "";
      for (let i = 0; i < selectedItens.length; i++) {
        const ic = selectedItens[i];

        if(currentSqll=="")
        {
          currentSqll=ic.setor+ic.quadra+ic.logradouro+ic.lote;
        }
        else
        {
          if(currentSqll!=ic.setor+ic.quadra+ic.logradouro+ic.lote)
          {
            return false;
          }
        }        
      }
    }    
    
    return true;
  }

  public gerenciarFotosFachada()
  {
    let selectedItens:Inscricao[]=this.getSelectedInscricoes();

    if(selectedItens.length<1)
    {
      return;
    } 

    this.fotosFachadasManagerDialog = this.parent.open(DialogComponent, {
      height: '60%',
      width: '80%',
      maxHeight: '60%',
      maxWidth: '80%',
      data: { component: FotosfachadaManagerComponent, title: "Gerenciar fotos de fachadas", parent: this.parent, icList: selectedItens }
    });

  }

  public closeEditHistorico(historicoProcesso: Historicoprocessoimovel)
  {
    if(this.editHistoricoDialog)
    {
      this.editHistoricoDialog.close();
    }
  }

  public selectInscricoes() 
  {
    let selectedImoveis = this.getSelectedImoveis();

    if(this.oneOnly && selectedImoveis.length>1)
    {
      this.toastService.error("Selecionar somente uma inscrição.", "Erro");
      return;
    }

    this.parent.closeSearchInscricoes(selectedImoveis);
    
  }

  public addDocuments()
  {
    let selectedItens:Inscricao[]=this.getSelectedInscricoes();

    if(selectedItens.length<1)
    {
      return;
    } 

    let inscricoes: string[]=[];

    selectedItens.forEach(item => {
      inscricoes.push(item.getInscricaoCompleta());
    });

    this.addDocumentDialog = this.parent.open(DialogComponent, {
      height: '70%',
      width: '75%',
      data: { component: FichaUploadComponent, title: "Associar novo documento ao imóvel", inscricoes: inscricoes, parentDialog: this }
    });
  }

  public closeNewFichaUploadDialog()
  {
    if(this.addDocumentDialog)
    {
      this.addDocumentDialog.close();
    }
  }

  sortData(sort: Sort) {

    this.dataSource.sort = this.sort;
    let imoveis : Imovel[] = this.dataSource.data;

    const data = imoveis.slice();
    if (!sort.active || sort.direction === '') {
      imoveis = data;
      return;
    }

    imoveis.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'setor': return this.compare(a.setor, b.setor, isAsc);
        case 'quadra': return this.compare(a.quadra, b.quadra, isAsc);
        case 'logradouro': return this.compare(a.logradouro, b.logradouro, isAsc);
        case 'lote': return this.compare(a.lote, b.lote, isAsc);
        case 'sublote': return this.compare(a.sublote, b.sublote, isAsc);
        case 'endereco_imovel': return this.compare(a.endereco_imovel, b.endereco_imovel, isAsc);
        case 'numero_imovel': return this.compare(a.numero_imovel, b.numero_imovel, isAsc);
        case 'complemento_imovel': return this.compare(a.complemento_imovel, b.complemento_imovel, isAsc);
        case 'cep_imovel': return this.compare(a.cep_imovel, b.cep_imovel, isAsc);        
        case 'bairro_imovel': return this.compare(a.bairro_imovel, b.bairro_imovel, isAsc);
        default: return 0;
      }
    });
    this.dataSource = new MatTableDataSource<Imovel>(imoveis);
    this.dataSource.paginator = this.paginator;
  } 
  private compare(a: number | string, b: number | string, isAsc: boolean) 
  {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private addExportTableHeader(tr, text)
  {
    let th = document.createElement("th");
    th.innerHTML=text;
    tr.appendChild(th);
   
  }

  private addExportTableColumn(tr, text)
  {
    let td = document.createElement("td");
    td.innerHTML=text;
    tr.appendChild(td);
  }

  public onExportSelecteds()
  {
    this.exporting = true;
    
    let table = document.getElementById('export-table-pesquisa-imovel');

    let selectedItens:Imovel[]=this.getSelectedImoveis();

    let trHeader = document.createElement("tr");      
    
    this.addExportTableHeader(trHeader, "Id");
    this.addExportTableHeader(trHeader, "Setor");
    this.addExportTableHeader(trHeader, "Quadra");
    this.addExportTableHeader(trHeader, "Logradouro");
    this.addExportTableHeader(trHeader, "Lote");
    this.addExportTableHeader(trHeader, "Sublote");
    this.addExportTableHeader(trHeader, "Endereço Imóvel");
    this.addExportTableHeader(trHeader, "Número Imóvel");
    this.addExportTableHeader(trHeader, "Complemento Imóvel");
    this.addExportTableHeader(trHeader, "CEP Imovel");
    this.addExportTableHeader(trHeader, "Bairro Imóvel");
    table.appendChild(trHeader);

    selectedItens.forEach((imovel: Imovel) => 
    {
      let trBody = document.createElement("tr");     

      this.addExportTableColumn(trBody,imovel['id']);
      this.addExportTableColumn(trBody,imovel['setor']);
      this.addExportTableColumn(trBody,imovel['quadra']);
      this.addExportTableColumn(trBody,imovel['logradouro']);
      this.addExportTableColumn(trBody,imovel['lote']);
      this.addExportTableColumn(trBody,imovel['sublote']);
      this.addExportTableColumn(trBody,imovel['endereco_imovel']);
      this.addExportTableColumn(trBody,imovel['numero_imovel']);
      this.addExportTableColumn(trBody,imovel['complemento_imovel']);
      this.addExportTableColumn(trBody,imovel['cep_imovel']);
      this.addExportTableColumn(trBody,imovel['bairro_imovel']);
      

      table.appendChild(trBody);
    });
    
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(table);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'GEOSV - Pesquisa de Imóveis');
 
    let datePipe = new DatePipe('pt-BR');
    let dataCapturaStr = datePipe.transform(new Date(), 'dd-MM-yyyy_HH-mm-ss');   

    XLSX.writeFile(wb, "geosv-pesquisa-avancada-imovel-export"+dataCapturaStr+".xlsx");

    this.exporting = false;
  }

}
