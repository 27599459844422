import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { ToolsService } from '../tools.service';
import { Tool } from '../tool';
import { ExportSinterToolComponent } from './export-sinter-tool.component';

export class ExportSinterTool extends Tool {

    private dialog: MatDialog;

    cursorType=null;
    id='export-sinter-tool';
    enabled=false;
    name='Exportar Formato Sinter';
    title='Exportar Formato Sinter';
    type='click';
    map:L.Map=null;
    icon='outbox';
    ll: any;
    protected visible:boolean=true;
    layerTool: boolean=false;
    customParameter=new Map<String, any>();
    notificationSubscrition: any;

    private dialogRef: any;

    constructor(dialog: MatDialog, toolsService: ToolsService) 
    { 
        super(toolsService)
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{};

    public enableImpl()
    {
        this.dialogRef = this.dialog.open(DialogComponent, {
            maxHeight: '100%',
            maxWidth: '100%',
            data: { component: ExportSinterToolComponent, title: "Exportar Pacote Sinter" }
        });      
    }
    public disableImpl()
    {
    }
    
    public closeDialog()
    {
        if(this.dialogRef)
        {
            this.dialogRef.close();
        }
    }
    
}
