import { AbstractDocumentType } from './abstract-document-type';
import { AbstractDocumentAuxiliarField } from './auxiliar-fields/abstract-document-auxiliar-field';
import { DocumentAuxiliarSelectField } from './auxiliar-fields/document-auxiliar-select-field';
import { DocumentAuxiliarDateField } from './auxiliar-fields/document-auxiliar-date-field';
import { DocumentAuxiliarTextField } from './auxiliar-fields/document-auxiliar-text-field';
export class SettlementPropertyCadSocialSEHABDocumentType implements AbstractDocumentType
{
    type: number;
    constructor(type: number)
    {
        this.type = type;
    }
    getSupportedExtensions()
    {
        return ["jpg", "png", "tif", "pdf","zip", "doc", "docx", "xls", "xlsx"];
    }
    getAuxiliarComponents() : AbstractDocumentAuxiliarField[]
    {
        let auxiliarComponents = [];

        let tipoDocumento = new DocumentAuxiliarSelectField();

        tipoDocumento.id="sehab-tipo-documento"
        tipoDocumento.label = "Tipo:";
        tipoDocumento.options.push("RELATÓRIOS");
        tipoDocumento.options.push("ENCAMINHAMENTOS");
        tipoDocumento.options.push("LAUDO SOCIAL");
        tipoDocumento.options.push("PARECER SOCIAL");
        tipoDocumento.options.push("OUTROS");

        let dataEmissao = new DocumentAuxiliarDateField();

        dataEmissao.id="sehab-data-emissao"
        dataEmissao.label="Data Emissão:"

        let tecnicoResp = new DocumentAuxiliarTextField();

        tecnicoResp.id="sehab-tecnico-responsavel"
        tecnicoResp.label="Técnico Responsável:"

        auxiliarComponents.push(tipoDocumento);
        auxiliarComponents.push(dataEmissao);
        auxiliarComponents.push(tecnicoResp);
        
        return auxiliarComponents;
    }
}