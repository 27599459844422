import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { ToolsService } from '../tools.service';
import { CanvasService } from '../../canvas/canvas.service';
import { LayersConfigService } from '../../layers/layers.config.service';
import { Tool } from '../tool';
import { NotificationService } from 'src/app/service/notification.service';
import { EditLayerLoadGeomTool } from '../edit-layer-load-geom-tool/edit-layer-load-geom-tool';
import { AbstractEditionTool } from './abstract-edition-tool';
import { Subscription } from 'rxjs';
import { AppNotification } from '../../vo/notification';
import { FeatureService } from 'src/app/service/feature.service';
import { EditionOperation } from '../../vo/edition-operation';
import { Operation } from '../../vo/operation';
import { GeomType } from '../../vo/geomtype';
import { LayerConfig } from '../../layers/layers.config';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { SaveEditionLayerTool } from '../save-edition-layer-tool/save-edition-layer-tool';
import { AddGeomByCoordsEditionLayerTool } from '../add-geom-by-coords-edition-layer-tool/add-geom-by-coords-edition-layer-tool';
import * as L from 'leaflet';
import { EditionService } from 'src/app/service/edition.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ToastService } from 'src/app/service/toast.service';


export class EditLayerTool extends AbstractLayerTool {

    private dialog: MatDialog;

    cursorType=null;
    id='edit-layer-tool';
    enabled=false;
    name='Edit Layer Tool';
    title='Editar Camada';
    type='click';
    map:L.Map=null;
    icon='mode_edit';
    ll: any;
    protected visible:boolean=true;
    layerTool: boolean=true;
    customParameter=new Map<String, any>();
    editionTools: Array<AbstractEditionTool> = [];
    currentTool: AbstractEditionTool;
    notificationSubscrition: Subscription;
    operations: Map<String,EditionOperation>;
    geomCount: number;
    leafletGeomanDrawLayers: Array<any>=[];
    onInsertFeature: any;


    constructor(dialog: MatDialog, private canvasService: CanvasService, 
        toolsService: ToolsService, private notificationService: NotificationService, private featureService: FeatureService, 
        private layersConfigService: LayersConfigService, private editionService: EditionService, private toastService: ToastService) 
    { 
        super(toolsService)
        this.dialog = dialog;
        this.editionTools.push(new AddGeomByCoordsEditionLayerTool(this, dialog, toolsService, this.notificationService, this.canvasService, this.layersConfigService, this.toastService));
        this.editionTools.push(new EditLayerLoadGeomTool(this.canvasService, toolsService, notificationService, featureService, layersConfigService));
        this.editionTools.push(new SaveEditionLayerTool(toolsService, this.notificationService, this.canvasService, editionService));

        
        this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
            if (notification.event==AppNotification.ENABLE_LAYER_EDITION_TOOL_CONTROL_EVENT) 
            {
              this.enableEditionTool(notification.object);
            } else if (notification.event==AppNotification.CLEAR_EDITION_CONTEXT) 
            {
              this.removeTempGeomanLeafletLayers();
              this.operations.clear();
              this.canvasService.clearMemoryEditionLayer();
            } 

          });
    }

    public execAction=(event:L.LeafletMouseEvent)=>
    {
        if(this.currentTool!=null)
        {
            return this.currentTool.execAction(event);
        }
    };

    public enableEditionTool(tool: AbstractEditionTool)
    {
        this.currentTool=tool;
        tool.enable();
        
    }

    public enableImpl()
    {
        let layer = this.toolsService.getToolParameter(this, "layer");
        let idAttribute = this.layersConfigService.getLayerIdAttribute(layer)
        if(idAttribute==null)
        {
            console.log("Missing id_attribute configuration on layer: " + layer.getId());
            this.disable();
            return;
        }
        this.canvasService.enableEdition(layer);
        this.addEditionTools();        

        this.prepareOperationListeners();

        this.geomCount=-1;
         
        this.addToolbarControlListener();
     }

     private prepareOperationListeners()
     {

        this.operations = new Map<String, EditionOperation>();


        this.onInsertFeature = (e) => {
            this.leafletGeomanDrawLayers.push(e.layer);
            let feature = this.newFeature(e);
            this.onEditionOperationEvent(feature, Operation.Insert);
        };

        this.canvasService.getMap().on("pm:create", this.onInsertFeature);

        this.canvasService.getMemoryEditionLayer().on("pm:edit", (e: any) => {
           
          //  let feature = this.newFeature(e);
           
           let feature = this.readFeatureFromEditionEvent(e);

           if(feature)
           {
            this.onEditionOperationEvent(feature, Operation.Update);
           }
           else
           {
               console.log("Problema ao ler feature a partir do evento de atualização de geometria.");
               console.log("Evento: " + e);
           }
         });
         this.canvasService.getMemoryEditionLayer().on("pm:remove", (e: any) => {
           
           // let feature = this.newFeature(e);
           let feature = this.readFeatureFromEditionEvent(e);
           
           if(feature)
           {
            this.onEditionOperationEvent(feature, Operation.Delete);
           }
           else
           {
               console.log("Problema ao ler feature a partir do evento de remoção de geometria.");
               console.log("Evento: " + e);
           }
           this.canvasService.getMemoryEditionLayer().on("pm:dragend", (e: any) => {
               console.log(e);
           });

           
           
         });
     }
     private readFeatureFromEditionEvent(event: any)
     {
        let geomType = this.canvasService.getGeomTypeFromLeafletType(event.shape);
        let feature=null;

        let geom = this.getGeomFromEditionEvent(event);
        let coordinates = geom.geometry.coordinates;

        if((geomType==GeomType.Polygon) || (geomType==GeomType.Line))
        {
            //Polygon and Line return feature
            feature=event.layer.feature;
        }
        else
        {
            //When Point/Marker GeoJSON layer creates a sublayer for each geometric point, searching modified marker layer.
            let markerLayers: any = this.canvasService.getMemoryEditionLayer().getLayers();
             let innerFeature = null;
             markerLayers.forEach(layer => {
                 if(event.layer._eventParents[layer._leaflet_id])
                 {
                     innerFeature = event.layer._eventParents[layer._leaflet_id].feature;                    
                 }
             });
             feature=innerFeature;
             coordinates=[coordinates];
        }

        

        //Updating current geometry with the new coordinates
        feature.geometry={
                 "type": GeomType.getMultipleGeomType(geomType),
                 "coordinates": coordinates,
                 "crs":{
                     "type":"name",
                     "properties":{
                         "name":"EPSG:4326"
                        }
                    }
             };

        return feature;
        
     }

     private getGeomFromEditionEvent(event: any)
     {
        let geomType = this.canvasService.getGeomTypeFromLeafletType(event.shape);

        let geom=null;

        if(geomType==GeomType.Polygon)
        {
           geom = (new L.Polygon(event.layer.getLatLngs())).toGeoJSON();
        } else if(geomType==GeomType.Line)
        {
           geom = (new L.Polyline(event.layer.getLatLngs())).toGeoJSON();
        } else if(geomType==GeomType.Point)
        {
           geom = (new L.Marker(event.layer._latlng)).toGeoJSON();
        }
        return geom;
     }
 

     private newFeature(event: any)
     {
         console.log("Id: "+this.geomCount+" Operação: " + event.type + " - Shape: " + event.shape + " - Objeto: " + event.layer.feature);
         let geomType = this.canvasService.getGeomTypeFromLeafletType(event.shape);

         let geom=this.getGeomFromEditionEvent(event);
                 
         var feature = {
             "id":this.geomCount,
             "type": "Feature",
             "properties": {},
             "geometry": {
                 "type": GeomType.getMultipleGeomType(geomType),
                 "coordinates": [geom.geometry.coordinates],
                 "crs":{
                     "type":"name",
                     "properties":{
                         "name":"EPSG:4326"
                        }
                    }
             }
         };

         this.geomCount--;

        return feature;
     }
     
    /**
     * Adding edition tools controls do leaflet toolbar.
     */
    private addEditionTools()
    {
        this.editionTools.forEach(editionTool => 
        {
            editionTool.addToolControl();    
        });
    }
    private removeEditionTools()
    {
        this.editionTools.forEach(editionTool => 
        {
            editionTool.removeToolControl();    
        });
    }
    public disableImpl()
    {
       
        let toolLayer = this.toolsService.getToolParameter(this, "layer");
        let currentEditionLayer = this.canvasService.getCurrentEditionLayer();
        
        this.removeEditionTools();

        if(currentEditionLayer!=null && toolLayer!=currentEditionLayer)
        {
            //Enabling new current edition layer if it is already in edition and the requested tool layer is diferent of the current edition layer.
            //Refactor the tool mechanism to avoid this HACK

            this.canvasService.disableEdition();
            
            this.toolsService.setCurrentTool(null);

            this.toolsService.toogleTool(this);            
        }
        else
        {
            this.canvasService.disableEdition();    
        }
        
        if(this.onInsertFeature)
        {
            //Disabling map new geom event listener
            this.canvasService.getMap().off("pm:create", this.onInsertFeature);
        }
        
        this.removeTempGeomanLeafletLayers();  
    }

    private removeTempGeomanLeafletLayers()
    {
        this.leafletGeomanDrawLayers.forEach(leafletlayer => {
            if(leafletlayer)
            {
                leafletlayer.remove()
            }
        });
        this.leafletGeomanDrawLayers=[];
        
    }

    private addToolbarControlListener()
    {
        this.canvasService.getMap().on('pm:actionclick', (e) => 
        {
            this.editionTools.forEach(tool => {
                tool.toolbarActionClicked(e);    
            });
            
        });
        this.canvasService.getMap().on('pm:buttonclick', (e) => 
        {
            this.editionTools.forEach(tool => {
                tool.toolbarButtonClicked(e);    
            });
        });
        
    }
    public onEditionOperationEvent(feature: any, operation: Operation)
    {
        let geomType = this.layersConfigService.getLayerGeomType(this.canvasService.getCurrentEditionLayer());

        //TODO: Test for wrong order when memory, example: Insert, Update and Save (In memory). When in memory need to handle here 

        let editOperation = new EditionOperation(feature.id,feature,geomType, operation);
        
        this.operations.set(feature.id, editOperation);

        let notification = new AppNotification(AppNotification.ON_EDITION_OPERATIONS_CHANGE_EVENT, this.operations);
        this.notificationService.send(notification);
    }
    public isVisibleForLayer(layer :LayerConfig) : boolean
    {
        return this.layerTool;
    }

    public isEnabled(layer :LayerConfig) : boolean
    {
        let currentLayerTool = this.toolsService.getToolParameter(this, "layer");
        if(this.enabled && currentLayerTool.getId()==layer.getId() )
        {
            return true;
        }
        else
        {
            return false;
        }
    }
    
}
