import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToolsService } from '../tools.service';
import { SplitTool } from './split-tool';
import { FeatureService } from 'src/app/service/feature.service';
import { LayerConfig } from '../../layers/layers.config';
import { Tool } from '../tool';
import { LayersConfigService } from '../../layers/layers.config.service';
import { FormControl, FormGroup } from '@angular/forms';
import { EditionService } from 'src/app/service/edition.service';
import { ToastService } from 'src/app/service/toast.service';
import { CanvasService } from '../../canvas/canvas.service';
import { Property } from '../../vo/property';
import { Historicoprocessoimovel, HistoricoprocessoimovelInner, Insertoperation } from '@funcate/sigweb-cti-api';
import { DialogComponent } from '../../dialog/dialog.component';
import { EditHistoricoComponent } from '../property-registration/edit-historico/edit-historico.component';
import { BackendService } from 'src/app/service/backend.service';
import { EditionOperation } from '../../vo/edition-operation';
import { GeomType } from '../../vo/geomtype';
import { Operation } from '../../vo/operation';


@Component({
  selector: 'app-split-tool',
  templateUrl: './split-tool.component.html',
  styleUrls: ['./split-tool.component.css']
})
export class SplitToolComponent implements OnInit {

  splitTool:SplitTool;
  subscriptionTool:Subscription;
  layerConfig: LayerConfig;
  splitToolBaseLayer: LayerConfig;
  currentFeatureId: any;
  currentFeatureSRID: any;
  currentHistoricoId: string;
  addHistoricoDialog: any;
  outGeom1: any;
  outGeom2: any;
  outGeomId: any;
  toolLotesLayerICAttributeConfig: string = "ic_attribute";
  edificationLoteIdAttributeConfig: string = "lote_id_attribute";
  toolEdificationLayerIdConfig: string = "edification_layer_id";
  loteId: any;
  edificationFeatures: any;
  newEdificationFeaturesJSON: string;
  removedEdificationFeaturesJSON: string;

  constructor(private toolService: ToolsService, private featureService: FeatureService,
     private layerConfigService: LayersConfigService, private editionService: EditionService
    , private toastService: ToastService, private canvasService: CanvasService, private backendService: BackendService)
    {
    this.subscriptionTool=this.toolService.toolsObservable.subscribe(
      (splitTool:SplitTool) => {
        this.splitTool=splitTool;
        this.update();
        console.log("Tool acionou selecão de geometria");
      },()=> {
        console.log("Falhou ao selecionar geometria para desdobro");
      }
    );
   }

  update()
  {
    if(this.splitTool)
    {
      this.layerConfig = this.toolService.getToolParameter(this.splitTool, "layer");

      let idAttribute = this.layerConfigService.getLayerIdAttribute(this.layerConfig);

      let lotesLayerICAttribute = this.layerConfigService.getLayerAppConfig(this.layerConfig, this.toolLotesLayerICAttributeConfig);

      if(this.layerConfig)
      {
        this.splitTool.selectedFeature=null;
        this.splitTool.map.eachLayer( (l)=>{
          if(l["wmsParams"]) {
  
            if(l["id"]==this.layerConfig.getId())
            {
              this.featureService.getFeatureInfo(this.splitTool.ll, l, this.splitTool.map).then(featureInfo => {

                if(featureInfo)
                {
                
                  let selectedFeature=featureInfo.features[0];

                  let attributesFilterList: Array<Array<Property>>=[];
                  let attributeFilterList: Array<Property>=[];

                  let idProperty = this.featureService.getPropertyByKey(selectedFeature.properties, idAttribute);
                  let icProperty = this.featureService.getPropertyByKey(selectedFeature.properties, lotesLayerICAttribute);

                  this.loteId = idProperty.value;

                  if(idProperty)
                  {
                      attributeFilterList.push(idProperty);
                      attributesFilterList.push(attributeFilterList);

                      this.featureService.getFeatures(this.layerConfig, featureInfo.features[0].srs , attributesFilterList).then(features => 
                      {
                    
                        if(features && features.length>0)
                        {

                          
                          let layerIdAttribute = this.layerConfigService.getLayerIdAttribute(this.layerConfig);
                          this.currentFeatureId=this.featureService.getPropertyByKey(selectedFeature.properties,layerIdAttribute).value;
                          this.currentFeatureSRID=featureInfo.features[0].srs.replace("EPSG:","");     
                                 
                          this.loadEdificationsFeatures().then((edifFeatures)=>{
                            if(edifFeatures && edifFeatures.length>0)
                            {
                              this.edificationFeatures=JSON.stringify(edifFeatures);
                            }
                            this.splitTool.selectedFeature=selectedFeature;
                          });

                          
                        }
                      });
                      
                  }
                  else
                  {
                    console.log("Property "+ idAttribute +" not found...")
                  }
            
                }
            });
              
            }
  
          }
      });
      }


    }
    
  }

  public loadEdificationsFeatures() : Promise<any>
  {
    if(this.loteId)
    {
      let edificationLayerId = this.layerConfigService.getLayerAppConfig(this.layerConfig, this.toolEdificationLayerIdConfig);

      let edificationLayerConfig = this.layerConfigService.getOverLayerById(edificationLayerId);

      let edificationLoteIdAttribute = this.layerConfigService.getLayerAppConfig(edificationLayerConfig, this.edificationLoteIdAttributeConfig);      

      let attributesFilterList: Array<Array<Property>>=[];
      let attributeFilterList: Array<Property>=[];

      let idProperty : Property = new Property(edificationLoteIdAttribute, this.loteId);

      attributeFilterList.push(idProperty);
      attributesFilterList.push(attributeFilterList);

      let srs = this.splitTool.map.options.crs.code;


      return this.featureService.getFeatures(edificationLayerConfig, srs , attributesFilterList);
    }
  }

  public getBaseLayerURL()
  {
    let url = "";
    if(this.splitToolBaseLayer)
    {
      //let randint = (Math.floor( Math.random() * 2000000 ) + 1);
      url+=this.splitToolBaseLayer.getSource()+this.splitToolBaseLayer.getPath();

    }
    return url;
  }

  public getBaseLayerName()
  {
    let name = "";
    if(this.splitToolBaseLayer)
    {
      name= this.layerConfigService.getLayerId(this.splitToolBaseLayer);
    }
    return name;
  }

  public saveSplit(id: string, geom1: string, geom2: string)
  {
    this.outGeom1=geom1;
    this.outGeom2=geom2;
    this.outGeomId=id;
    if(this.layerConfig)
    {
      //this.addHistorico();
      this.commitSplit();
    }
  }

  private commitSplit()
  {
    this.editionService.split(this.outGeomId, this.outGeom1, this.outGeom2, this.layerConfig).then((result)=>
      {
        this.toastService.sucess("Geometria desdobrada!", "Sucesso");

        this.commitEdificationsSplit();

        this.updateImoveisRelation(result);

        let edificationLayerId = this.layerConfigService.getLayerAppConfig(this.layerConfig, this.toolEdificationLayerIdConfig);

        let edificationLayerConfig = this.layerConfigService.getOverLayerById(edificationLayerId);
        
        this.canvasService.redrawLayer(this.layerConfig);

        this.canvasService.redrawLayer(edificationLayerConfig);

        this.splitTool.close();
      }).catch(()=>
      {
        //this.rollbackHistorico();
        this.toastService.error("Não foi possível concluir o processo de desdobro!", "Erro");
      })
  }

  private commitEdificationsSplit()
  {

    let edificationLayerId = this.layerConfigService.getLayerAppConfig(this.layerConfig, this.toolEdificationLayerIdConfig);

    let edificationLayerConfig = this.layerConfigService.getOverLayerById(edificationLayerId);

    let edificationIdAttribute = this.layerConfigService.getLayerIdAttribute(edificationLayerConfig);    

    this.newEdificationFeaturesJSON = document.getElementById('split-tool-new-edifications-features-json')['value'];
    this.removedEdificationFeaturesJSON = document.getElementById('split-tool-removed-edifications-features-json')['value'];
    if(this.newEdificationFeaturesJSON)
    {
      let newFeatures = JSON.parse(this.newEdificationFeaturesJSON);
      newFeatures.features.forEach(newFeature => {

        let editionOperation: EditionOperation = new EditionOperation(-1, newFeature, GeomType.Polygon, Operation.Insert);

        this.editionService.insertGeom(editionOperation, edificationLayerConfig).then((operation)=>{
        
          newFeature.properties[edificationIdAttribute] = operation.newid;

          delete newFeature.properties["lote_id"];

          let properties = this.featureService.convertGJSONPropertiesToPropertyList(newFeature.properties);

 
          this.editionService.updateAttributes(properties, edificationLayerConfig).then(()=>
          {

          });
  
        });
      });      
    }
    if(this.removedEdificationFeaturesJSON)
    {
      let removedFeatures = JSON.parse(this.removedEdificationFeaturesJSON);

      removedFeatures.features.forEach(removedFeature => {
        
        let editionOperation: EditionOperation = new EditionOperation(-1, removedFeature, GeomType.Point, Operation.Delete);

        this.editionService.deleteGeom(editionOperation, edificationLayerConfig).then((operation)=>{

        });

      });      
    }
    
  }

  ngOnInit(): void 
  {
    this.splitToolBaseLayer=this.layerConfigService.getSplitToolBaseLayer();
  }

  ngOnDestroy(): void {
    this.subscriptionTool.unsubscribe();
  }

  private updateImoveisRelation(result)
  {    
   
    let imoveisRelations = [];

    {
      let imovelRelation = new Object();

      imovelRelation['lote_id'] = this.outGeomId;
      imovelRelation['inscricao'] = null;
      imovelRelation['imovel_id'] = null;
  
      imoveisRelations.push(imovelRelation);  
    }          

    {
      let imovelRelation = new Object();

      imovelRelation['lote_id'] = result.insertOperation['newid'];
      imovelRelation['inscricao'] = null;
      imovelRelation['imovel_id'] = null;
  
      imoveisRelations.push(imovelRelation);  
    }          

   
    this.backendService.updateImoveisRelation(imoveisRelations).then((updatedImoveisRelations)=>
    {

    });
  }
   

}
