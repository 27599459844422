import { ImoveisInner } from '@funcate/sigweb-cti-api/model/imoveisInner';

export class Imovel implements ImoveisInner {
    id?: number;
    setor?: string;
    quadra?: string;
    logradouro?: string;
    lote?: string;
    sublote?: string;
    endereco_imovel?: string;
    numero_imovel?: string;
    complemento_imovel?: string;
    cep_imovel?: string;
    bairro_imovel?: string;
    ativo?: boolean;

    constructor(id:number, imovel:any)
    {
        this.id=id;
        this.setor=imovel.setor;
        this.quadra=imovel.quadra;
        this.logradouro=imovel.logradouro;
        this.lote=imovel.lote;
        this.sublote=imovel.sublote;
        this.endereco_imovel=imovel.endereco_imovel;
        this.numero_imovel=imovel.numero_imovel;
        this.complemento_imovel=imovel.complemento_imovel;
        this.cep_imovel=imovel.cep_imovel;
        this.bairro_imovel=imovel.bairro_imovel;
        this.ativo=imovel.ativo=='S'?true:false;
    }

    toString(): string
    {
        let imovel = JSON.stringify(this);
        return imovel;
    }


    public static getTiposDeConstrucao()
    {
        let tipoConstrucao = [
            {id: 1, name:	"TERRENO"},
            {id: 1, name:	"A1- RESIDENCIA"},
            {id: 2, name:	"B1- APARTAMENTO COM ELEVADOR"},
            {id: 3, name:	"B2- APARTAMENTO SEM ELEVADOR"},
            {id: 4, name:	"C1- ESCRIT/SALA/CONJUNTO C/ELE"},
            {id: 5, name:	"C2- ESCRIT/SALA/CONJUNTO S/ELE"},
            {id: 6, name:	"D1- LOJAS COMERCIO/PREST SERV"},
            {id: 7, name:	"E1- ARMAZENS GALPOES USO COMER"},
            {id: 8, name:	"F1- SUPERMERCADOS (EDIF ESPEC)"},
            {id: 9, name:	"G1- POSTOS DE GASOLINA/SERVICO"},
            {id: 10, name:	"H1- HOTEIS E MOTEIS COM ELEVAD"},
            {id: 11, name:	"H2- HOTEIS E MOTEIS SEM ELEVAD"},
            {id: 12, name:	"H3- PENSOES E ALBERGUES"},
            {id: 13, name:	"I1- HOSPITAIS E SIMILAR C/ELEV"},
            {id: 14, name:	"I2- HOSPITAIS E SIMILAR S/ELEV"},
            {id: 15, name:	"J1- ESCOLAS COM ELEVADOR"},
            {id: 16, name:	"J2- ESCOLAS SEM ELEVADOR"},
            {id: 17, name:	"K1- CLUBES E CENTROS ESPORTIVO"},
            {id: 18, name:	"L1- CINEMAS/TEATROS/ANFIT/SIMI"},
            {id: 19, name:	"M1- BANCOS E SIMILARES (EDIFIC"},
            {id: 20, name:	"N1- EDIFICIOS P/ GARAGENS"},
            {id: 21, name:	"N2- GARAGENS E SIMILAR EM EDIF"},
            {id: 22, name:	"N3- GARAGENS E SIMILAR EM EDIF"},
            {id: 23, name:	"N4- ABRIGOS P/AUTO MENOS DE CO"},
            {id: 24, name:	"O1- INDUSTRIA COMUM GALPAO ABE"},
            {id: 25, name:	"O2- INDUSTRIA ESPECIAL ED.IND."},
            {id: 26, name:	"P1- DEPOSITO E AREAS COBERTAS"}
          ];

          return tipoConstrucao;
    } 

}
