import { Component, Inject, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { CanvasService } from '../../canvas/canvas.service';
import { LayerConfig } from '../../layers/layers.config';

import { Style } from '../../vo/style';
import { ToolsService } from '../tools.service';

import { StyleService } from 'src/app/service/style.service';
import { EditStyleLayerTool } from './edit-style-layer-tool';
import { Layer } from 'leaflet';
import { CapabilitiesService } from 'src/app/service/capabilities.service';
import { ToastService } from 'src/app/service/toast.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { GeneralService } from 'src/app/service/general.service';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../../vo/notification';
import { Userinfo } from '@funcate/sigweb-cti-api';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { UploadStyleSldFileComponent } from './upload-style-sld-file/upload-style-sld-file.component';
import { BackendService } from 'src/app/service/backend.service';
import { GenericConfirmationDialogComponent } from '../../dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-edit-style-layer-tool',
  templateUrl: './edit-style-layer-tool.component.html',
  styleUrls: ['./edit-style-layer-tool.component.css']
})

export class EditStyleLayerToolComponent implements OnInit 
{
  private tool:EditStyleLayerTool;
  private subscriptionTool:Subscription;
  public layer: LayerConfig;
  public stylesMap: Map<string, Style>;
  public styles: MatTableDataSource<Style>;
  public styleList: Array<Style>;
  public currentStyle: Style;
  notificationSubscrition: Subscription;
  downloading: boolean;
  currentFilter: string;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  
  dialogRef: any; 
  dialog: any;
  deleteConfirmDialogRef: any;
  isLoading: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private toolsService: ToolsService,
    private canvasService:CanvasService,
    private styleService:StyleService ,
    private capabilitiesService: CapabilitiesService,
    private toastService: ToastService,
    private authenticationService: AuthenticationService,
    private generalService: GeneralService,
    private notificationService: NotificationService,
    private backendService: BackendService) 
    {
      this.dialog = data.dialog;
      this.downloading = false;
      this.isLoading = false;
      this.stylesMap = new Map<string, Style>();
      this.styles = new MatTableDataSource();
    this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (tool:EditStyleLayerTool) => {
        this.tool=tool;
        this.update();
      },()=> {
      }
      
    );

    this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
      if (notification.event==AppNotification.ON_DOWNLOAD_FINISHED_EVENT)
      {
          console.log("Download from '"+notification.object.url+"' is done to out file '"+notification.object.outputFilename+"'.");
          this.downloading = false;
      } 
  });
    this.defineFilterPredicate();
   }

  ngOnInit(): void {
  }

  private update() 
  {    
    if (this.tool) 
    {
      this.layer = this.toolsService.getToolParameter(this.tool, "layer");
      this.canvasService.getMap().eachLayer((l) => {
        if (l["wmsParams"])
        {

          if (l["id"] == this.layer.getId())
          {
            this.isLoading = true;
            this.styles = new MatTableDataSource();
            this.stylesMap = new Map<string, Style>();
            this.loadStyleForLayer(l);
          }
        }
      });
      

    }

  }

  private defineFilterPredicate()
  {
    this.styles.filterPredicate = function(data, filter: string): boolean 
    {
        if(data.id  && data.id.toLowerCase().includes(filter))
        {
          return true;
        } else if(data.name  && data.name.toLowerCase().includes(filter))
        {
          return true;
        } else if(data.title  && data.title.toLowerCase().includes(filter))
        {
          return true;
        }           
        return false;
    };
  }  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.currentFilter = filterValue.trim().toLowerCase();
    this.styles.filter = this.currentFilter;
  }

  public close()
  {
    this.tool.closeDialog();
  }

  public loadStyleForLayer(layer: Layer) 
  {
    
      this.capabilitiesService.getCapabilitiesforLayer(layer).then(capabilities => {

          if(capabilities)
          {
            try
            {
              
               let styles = this.styleService.getStylesFromCapabilitesObject(capabilities);
              
              if(!this.layer.getStyle())
              {
                //Selecting first style on capabilities if there is no style configured on layer yet
                this.layer.setStyle(styles[0].id);
              }

              styles.forEach(style => {
                if(style.id==this.layer.getStyle())
                {
                  style.enabled =true;
                  this.currentStyle=style;
                }
                else
                {
                  style.enabled =false;
                }
                this.stylesMap.set(style.id, style);
              });              
              this.styleList = []
              this.stylesMap.forEach(style => {
                this.styleList.push(style);
              });

              this.styles = new MatTableDataSource(this.styleList);
              this.isLoading = false;

            }catch(err)
            {              
              this.styles = new MatTableDataSource();
              this.toastService.error("Problema ao obter estilos da camada solicitada.", "Erro");
              console.log("Problema ao obter estilos da camada via capabilities.");
              this.isLoading = false;
            }
            
          }
      });
  
  }
  public downloadStyle(style)
  {
    this.downloading = true;

    let outputFilename=style.id.replace(":", "_") +".sld";

    let url = environment.BACKEND_API_BASE_PATH+'/geo/style/sld/get/' + style.id;

    let user = this.authenticationService.getCurrentUser();
    if(!user)
    {
      this.toastService.error("Usuário não está autenticado corretamente. Efetue o login novamente.", "Problemas ao realizar o download do estilo.");
    }
    else
    {
      this.generalService.downloadFileGetMethod(url, outputFilename, user.token);
    }
  }
  public apply()
  {
    this.layer.setStyle(this.currentStyle.id);
    this.layer.setStyleTitle(this.currentStyle.name);
    this.canvasService.redrawLayer(this.layer);
  }
  public save()
  {
    this.apply();
    this.tool.closeDialog();
  }

  public showUpdateStyle(style: Style)
  {
    let currentLayerTool = this.toolsService.getToolParameter(this.tool, "layer");

    this.dialogRef = this.dialog.open(DialogComponent, {
        height: '80%',
        width: '50%',
        data: { component: UploadStyleSldFileComponent, title: "Alterar estilo SLD", layer: currentLayerTool, dialog: this.dialog, currentStyle: style, parent: this }
    });

    this.dialogRef.afterClosed().toPromise().then(()=>{
    });
  }
  public showAddStyle()
  {
    let currentLayerTool = this.toolsService.getToolParameter(this.tool, "layer");

    this.dialogRef = this.dialog.open(DialogComponent, {
        height: '80%',
        width: '50%',
        data: { component: UploadStyleSldFileComponent, title: "Adicionar novo arquivo de estilo SLD", layer: currentLayerTool, dialog: this.dialog, parent: this }
    });

    this.dialogRef.afterClosed().toPromise().then(()=>{
    });
  }
  public confirmDeleteStyle(style: Style)
  {
    this.deleteConfirmDialogRef = this.dialog.open(DialogComponent, {
      height: '30%',
      width: '20%',
      data: { component: GenericConfirmationDialogComponent, title: "Excluir Estilo", confirmTitle: "", confirmMessage: "Deseja realmente excluir o estilo?" }
    });

    this.deleteConfirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
      if (confirmResult) {
        this.deleteStyle(style);
      }
    });
  }
  public deleteStyle(style: Style)
  {
    let styleId=style.id;
    if(style.id.includes(":"))
    {
      styleId = style.id.split(":")[1];
    }
    
    this.backendService.deleteStyle(styleId).then(()=>{
      this.toastService.sucess("Estilo excluído com sucesso.", "Sucesso");
      this.update();
    }).catch(()=>{
      this.toastService.error("Falha o tentar excluir estilo.", "Erro")
    })
  }
  public closeUploadStyleDialog()
  {
    if(this.dialogRef)
    {
      this.dialogRef.close();
      this.update();
    }
  }

  public hasEditionPermition()
  {
    let user: Userinfo = this.authenticationService.getCurrentUser();

    if(user)
    {
      let layer = this.toolsService.getToolParameter(this.tool, "layer");
      //Verifica se o usuário tem permissão de escrita na camada de imoveis
      if(layer.isWritableForUser(user))
      {
        //Verifica se o usuário tem perfil editor ou admin
      
        return true;
      
      }
    }
    return false;
  }

  public getStyleId(style: Style)
  {
    return this.styleService.getStyleIdWithoutWorkspace(style);
  }

  public getDisplayedColumns()
  {
    if(this.hasEditionPermition())
    {
      return ['enabled', 'id','name', 'title', 'legend', 'acoes']; 
    }
    else
    {
      return ['enabled', 'name', 'title', 'legend', 'acoes']; 
    }
  }
  sortData(sort: Sort) {

    this.styles.sort = this.sort;
    const data = this.styleList.slice();
    if (!sort.active || sort.direction === '') {
      this.styleList = data;
      return;
    }

    this.styleList.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'title': return this.compare(a.title, b.title, isAsc);
        default: return 0;
      }
    });
    this.styles = new MatTableDataSource<Style>(this.styleList);
    this.defineFilterPredicate();
  } 
  private compare(a: number | string, b: number | string, isAsc: boolean) 
  {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
