export class Property {
    key:String;
    value:String;

    constructor(key:string, value:string)
    {
        this.key = key;
        this.value = value;

        return this;
    }
}
