import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

import Map from 'ol/Map';
import View from 'ol/View';
import { fromLonLat } from 'ol/proj';
import { Attribution, defaults as defaultControls, Zoom } from 'ol/control';
import { defaults as defaultInteractions, Extent, Interaction, Modify, PinchZoom, Select } from 'ol/interaction';

import TileLayer from 'ol/layer/Tile';


import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';
import {get as GetProjection} from 'ol/proj'

import Vector from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import TileWMS from 'ol/source/TileWMS';
import Projection from 'ol/proj/Projection';
import { EditEdificacoesTool } from './edit-edificacoes-tool';
import { EditEdificacoesRotationStep } from './edit-edificacoes-steps/edit-edificacoes-rotation-step';
import { EditEdificacoesRecuoStep } from './edit-edificacoes-steps/edit-edificacoes-recuo-step';
import { EditEdificacoesEdicoesStep } from './edit-edificacoes-steps/edit-edificacoes-edicoes-step';
import { EditEdificacoesCommitStep } from './edit-edificacoes-steps/edit-edificacoes-commit-step';
import { Observable, Subscription } from 'rxjs';
import { ToolsService } from '../tools.service';
import { GeometryService } from '../../../service/geometry.service';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import { LayersConfigService } from '../../layers/layers.config.service';
import { CustomEditionController } from '../tools-utils/custom-edition-interaction/custom-edition-controller'
import { AppNotification } from '../../vo/notification';
import { NotificationService } from 'src/app/service/notification.service';
import { StepsController } from '../tools-utils/steps-controller'
import { Step } from '../../vo/step';
import LineString from 'ol/geom/LineString';
import { EditionSegment } from '../../vo/edition-segment';
import { FeatureService } from 'src/app/service/feature.service';
import { LayerConfig } from '../../layers/layers.config';
import { ToastService } from 'src/app/service/toast.service';
import Text from 'ol/style/Text';
import Circle from 'ol/geom/Circle';
import Geometry from 'ol/geom/Geometry';
import Polygon from 'ol/geom/Polygon';
import { BackendService } from 'src/app/service/backend.service';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-edit-edificacoes-tool',
  templateUrl: './edit-edificacoes-tool.component.html',
  styleUrls: ['./edit-edificacoes-tool.component.css']
})
export class EditEdificacoesToolComponent implements OnInit {

  public tool:EditEdificacoesTool;
  private subscriptionTool:Subscription;
  projection: Projection;
  extent: Extent;
  
  inputLotesFeature: any;
  inputEdificacoesFeatures: any[];
  inputFeatureSRID: string;

  currentEdificacoesFeatures: any[];
  currentLotesFeature: any;
  lotesLayer: any;
  edificacoesLayer: any;
  baseLayer: any;
  recuoLayer: any;
  customEditionController: CustomEditionController;
  notificationSubscrition: Subscription;
  readonly lotesSnapInterectionId: string = 'lotes-snap-interection-id';
  readonly edificacoesSnapInterectionId: string = 'edificacoes-snap-interection-id';
  readonly recuoSnapInterectionId: string = 'recuo-snap-interection-id';
  readonly edificacoesSelectInterectionId: string = 'edificacoes-select-interection-id';
  readonly edificacoesModifyInterectionId: string = 'edificacoes-modify-interection-id';
  readonly edificacoesTranslateInterectionId: string = 'edificacoes-translate-interection-id';
  readonly edificacoesAddInterectionId: string = 'edificacoes-add-interection-id';
  readonly edificacoesDeleteInterectionId: string = 'edificacoes-delete-interection-id';
  readonly edificacoesDuplicateInterectionId: string = 'edificacoes-duplicate-interection-id';
  readonly selectedEdificacoesSnapInterectionId: string = 'selected-edificacoes-snap-interection-id';
  progressBarPercent: number =0;
  progressBarStep: string;
  stepsController: StepsController;
  edificacoesLayerConfig: LayerConfig; 
  


  readonly toolEdificacoesPavimentosAttributeConfig: string = "edificacoes_pavimentos_attribute";
  readonly toolEdificacoesClasseAttributeConfig: string = "edificacoes_classe_attribute";
  readonly toolEdificacoesAreaAttributeConfig: string = "edificacoes_area_attribute";
  readonly toolEdificacoesListaClassesValidasConfig: string = "edificacoes_lista_classes_validas";
  readonly toolEdificacoesListaPavimentosValidosConfig: string = "edificacoes_lista_pavimentos_validos";
  

  //RECUO
  private frontSelectedSegment: LineString;
  private selectedRecuoSegment: LineString;
  currentRecuoDistance: number;
  public useRecuo: boolean;

  //EDITION
  edificaoSegmentsTableRowsColumns:  string[] = ['segment','distance', 'angle'];  ;
  edificoesFeaturesTableRowsColumns:  string[] = ['edificacoes-radio','index','pavimento', 'classe', 'aream2'];  ;
  commitEdificoesFeaturesTableRowsColumns:   string[] = ['commit-edificacoes-checkbox','index','pavimento', 'classe', 'aream2', 'situacao', 'operacao'];  ;

  edificacaoSegmentList: EditionSegment[];
  selectedEditionFeature: Feature<any>;
  currentEditionInteraction: string;
  deletedEdificacoes: Feature<any>[];

  commitEdificacoesFeatures: Feature<any>[];

  // Nome da chave adicionada a feature quando a geometria é modificada
  modifiedFeatureKey: string = 'modified-feature';

  operationIdFeatureKey: string = 'operation-id';


  // Nome do campo pavimentos para a camada Edificacoes
  edificacoesPavimentosAttribute: string;
  // Nome do campo classe para a camada Edificacoes
  edificacoesClasseAttribute: string;
  // Nome do campo id para a camada Edificacoes
  edificacoesIdAttribute: string;
  // Nome do campo area para a camada Edificacoes
  edificacoesAreaAttribute: string;
  //Lista de classes válidas para a camada de edificações na tool de edição
  edificacoesListaClassesValidas: any;
  //Lista de pavimentos válidas para a camada de edificações na tool de edição
  edificacoesListaPavimentosValidos: any;

  //Nome do campo IC para a camada Lotes
  lotesICAttribute: string;
  //Nome do campo IC para a camada Edificações
  edificacoesICAttribute: string;

  //IC corrente dos lotes e das edificações
  currentIC: string;


  dialog: MatDialog;

  //Layer Styles
  lotesStyle: Style;
  edificacoesStyle: Style;
  edificacoesStyleWithLabelFunction: any;
  edificacoesSelectedStyle: Style;
  edificacoesSelectedStyleWithLabelFunction: any;

  areaTotal: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private toolsService: ToolsService, 
  private layersService: LayersConfigService, private notificationService: NotificationService,
   private backendService: BackendService, public toastService: ToastService,  dialog: MatDialog) 
  {
    this.deletedEdificacoes=[];
    this.dialog=dialog;
     this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
       (tool:EditEdificacoesTool) => {
         this.tool=tool;
        
       },()=> {
      }
    );

    this.useRecuo=false;
    this.inputLotesFeature=data.lotesFeature;
    this.inputEdificacoesFeatures=data.edificacoesFeatures;
    this.inputFeatureSRID=data.featureSRID;
    this.edificacoesLayerConfig=data.edificacoesLayerConfig;
    this.commitEdificacoesFeatures=[];
    this.lotesICAttribute=data.lotesICAttribute
    this.edificacoesICAttribute=data.edificacoesICAttribute
    this.currentIC=data.currentIC

    this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
      if (notification.event==AppNotification.FINISH_CUSTOM_EDITION_INTERACTION)
      {
        if(notification.object.interaction)
        {
          this.onCustomEditionInterectionFinished(notification.object.interaction);
        }
      } else if (notification.event==AppNotification.ON_CUSTOM_EDITION_STEP_CHANGED) 
      {
        this.updateProgressBar(this.stepsController.getPercent());
      }

  });
    
  }
  
  loadFeature(inputFeature: any, featureSRID: string)
  {
    let format = new GeoJSON();
    
    let feature = format.readFeature(inputFeature, {dataProjection: featureSRID });

    let geometry;

    if(feature.getGeometry().getType() == 'GeometryCollection')
    {
      geometry = feature.getGeometry().getGeometries()[0];
    } 
    else if(feature.getGeometry().getType() == 'MultiPolygon')
    {
      geometry = feature.getGeometry().getPolygons()[0];
    }
    
    if(geometry.getType() != 'Polygon')
    {
      console.log('Invalid geometric type! ' + geometry.getType());
      return;
    }

    geometry = GeometryService.adjustPolygonOrientation(geometry);

    feature.setGeometry(geometry);
    return feature;
  }

  loadFeatures(inputFeatures: any, featureSRID: string) : any[]
  {
    let features : any[] = [];

    inputFeatures.forEach(inputFeature => {

      let feature = this.loadFeature(inputFeature, featureSRID);

      this.updateFeatureArea(feature);

      features.push(feature);

    });
    
    return features;
  }

  //Atualizar Atributo de Indice de cada Feature para carater de identificação visual (Label)
  updateEdificacoesFeaturesIndex()
  {
    for (let i = 0; i < this.currentEdificacoesFeatures.length; i++) {
      const feature = this.currentEdificacoesFeatures[i];
      
      let index = i+1;

      feature.set('index', index);  
    }
  }

  initMap() 
  {
      this.customEditionController = new CustomEditionController('ol_map', this.notificationService);
  }

  defineLayerStyles()
  {

    this.lotesStyle = new Style({
      stroke: new Stroke({
        color: 'rgba(255,0,0,1)',
        width: 1,
      }),
      fill: new Fill({
        color: 'rgba(255,0,0,0.1)',
      })
    });

    this.edificacoesStyle = new Style({
      stroke: new Stroke({
        color: 'rgba(255,255,0,1)',
        width: 1,
      }),
      fill: new Fill({
        color: 'rgba(255,255,0,0.1)',
      }),
      text: new Text({
        font: '18px Calibri,sans-serif',
        fill: new Fill({
          color: 'rgba(0, 0, 0, 1)'
        }),
        stroke: new Stroke({
          color: 'rgba(255, 255, 255, 1)',
          width: 3
        })
      })
    });

    this.edificacoesSelectedStyle = new Style({
      stroke: new Stroke({
        color: 'rgba(51, 87, 214, 1)',
        width: 3,
      }),
      fill: new Fill({
        color: 'rgba(51, 87, 214, 0.4)',
      }),
            
    });

    this.loadEdificacoesStyleWithLabel(this.edificacoesStyle);
    this.loadEdificacoesSelectedStyleWithLabel(this.edificacoesSelectedStyle);
  }

  loadLayers()
  {
    if(this.customEditionController)
    {
    
      this.defineLayerStyles();

      this.edificacoesPavimentosAttribute = this.layersService.getLayerAppConfig(this.edificacoesLayerConfig, this.toolEdificacoesPavimentosAttributeConfig);
      this.edificacoesClasseAttribute = this.layersService.getLayerAppConfig(this.edificacoesLayerConfig,  this.toolEdificacoesClasseAttributeConfig);
      this.edificacoesAreaAttribute = this.layersService.getLayerAppConfig(this.edificacoesLayerConfig,  this.toolEdificacoesAreaAttributeConfig);
      this.edificacoesListaClassesValidas = this.layersService.getLayerAppConfig(this.edificacoesLayerConfig, this.toolEdificacoesListaClassesValidasConfig);
      this.edificacoesListaPavimentosValidos = this.layersService.getLayerAppConfig(this.edificacoesLayerConfig, this.toolEdificacoesListaPavimentosValidosConfig);
      this.edificacoesIdAttribute = this.layersService.getLayerIdAttribute(this.edificacoesLayerConfig);
    
      this.currentLotesFeature = this.loadFeature(this.inputLotesFeature, this.inputFeatureSRID);
      this.currentEdificacoesFeatures = this.loadFeatures(this.inputEdificacoesFeatures, this.inputFeatureSRID);
      
      this.updateEdificacoesFeaturesIndex();


      //LOTES
      let lotesReferenceSource = new Vector();
      this.lotesLayer = new VectorLayer({ source: lotesReferenceSource });
      lotesReferenceSource.addFeature( this.currentLotesFeature );
      
      this.lotesLayer.setStyle(this.lotesStyle);

      //EDIFICACOES
      var edificacoesReferenceSource = new Vector();
      this.edificacoesLayer = new VectorLayer({ source: edificacoesReferenceSource });
      edificacoesReferenceSource.addFeatures( this.currentEdificacoesFeatures );
      this.edificacoesLayer.setStyle(this.edificacoesStyle);

      //BASE ORTOFOTO
      this.baseLayer = this.getBaseLayer();

      //Adding layers to map
      this.customEditionController.addLayer(this.baseLayer);      
      this.customEditionController.addLayer(this.lotesLayer);
      this.customEditionController.addLayer(this.edificacoesLayer);      

      this.customEditionController.fitFeature(this.currentLotesFeature);

    }    
  }

  update()
  {
    this.initMap();

    this.loadLayers();    

    this.initStepController();
        
  }

  private updateProgressBar(percent: number)
  {
    this.progressBarPercent=percent;
  }
 
  ngOnInit(): void 
  {
    this.update();
  }
  private getBaseLayer()
  {
    let baseLayerConfig=this.layersService.getEdificacoesEditionToolBaseLayer();

    var baseLayerURL =  this.layersService.getLayerURL(baseLayerConfig);
    var baseLayerName =  this.layersService.getLayerId(baseLayerConfig);
   
    var newbaselayer = new TileLayer({
      source: new TileWMS({
        hidpi : false,
        url: baseLayerURL,
        params: {'LAYERS': baseLayerName, 'TILED': true, 'SRS': 'EPSG:'+this.inputFeatureSRID},
        serverType: 'geoserver'
      }),
      });

    return newbaselayer;
  }

  private onCustomEditionInterectionFinished(interaction: Interaction)
  {
     let step: Step = this.stepsController.getCurrentStep();

     if(step instanceof EditEdificacoesRecuoStep)
    {
      this.setRecuoDistance(null);
    }

     if(step)
     {
      step.callback(interaction);
     }
  }

  private initStepController()
  {
    this.stepsController = new StepsController(this.notificationService);

    let step1 = new EditEdificacoesRotationStep(1, this);

    this.stepsController.addNewStep(step1);

    let step2 = new EditEdificacoesRecuoStep(2, this);

    this.stepsController.addNewStep(step2);

    let step3 = new EditEdificacoesEdicoesStep(3, this);

    this.stepsController.addNewStep(step3);

    let step4 = new EditEdificacoesCommitStep(4, this, this.layersService, this.backendService);

    this.stepsController.addNewStep(step4);

    // {
    //   let step3 = new Function()
    //   {
    //     let layers = [];
    //     layers.push(this.edificacoesLayer);
  
    //     var selectInteraction = new Select({
    //       layers: layers
    //     });
        
    //     this.customEditionController.addInteraction('teste',selectInteraction);
    //     this.customEditionController.enableInteraction('teste');
        
    //   }
    //   this.stepsController.addNewStep(step3);
    // }
    
    this.stepsController.next();

  }
  public isCardVisible(id: string)
  {
    let currentStep: Step = this.stepsController.getCurrentStep();
    if(id=="feature-card")
    {
      if(currentStep instanceof EditEdificacoesEdicoesStep)
      {
        return true;
      }

    } else if(id=="tabela-card")
    {
      if(currentStep instanceof EditEdificacoesEdicoesStep)      
      {
        return true;
      }
    } else if(id=="botoes-card")
    {
      if(currentStep instanceof EditEdificacoesEdicoesStep 
        || currentStep instanceof EditEdificacoesRecuoStep
        || currentStep instanceof EditEdificacoesCommitStep)
      {
        return true;
      }
    } else if(id=="recuo-card")
    {
      if(currentStep instanceof EditEdificacoesRecuoStep)
      {
        return true;
      }
    }
    else if(id=="tools-card")
    {
      if(currentStep instanceof EditEdificacoesEdicoesStep)
      {
        return true;
      }
    }else if(id=="commit-edificacoes-features-card")
    {
      if(currentStep instanceof EditEdificacoesCommitStep)
      {
        return true;
      }
    }
    
    return false;
  }

  public getFrontSelectedSegment() : LineString
  {
      return this.frontSelectedSegment;
  }

  public setFrontSelectedSegment(frontSelectedSegment: LineString)
  {
    this.frontSelectedSegment = frontSelectedSegment;
  }  

  public getSelectedRecuoSegment() : LineString
  {
      return this.selectedRecuoSegment;
  }

  public setSelectedRecuoSegment(selectedRecuoSegment: LineString)
  {
    this.selectedRecuoSegment = selectedRecuoSegment;
  }

  public onUseRecuoChange(event: any, useRecuo: boolean)
  {
    this.useRecuo=useRecuo;
    let currentStep: Step = this.stepsController.getCurrentStep();
    if(currentStep instanceof EditEdificacoesRecuoStep)
    {
      currentStep.onUseRecuoChange();
    }
  }

  public validateRecuo(input)
  {
    let recuo = input.value;
    if(recuo)
    {      
      return true;
    }
    return false;
  }

  public applyRecuo(input)
  {
    let distance = input.value

    let currentStep: Step = this.stepsController.getCurrentStep();
    if(currentStep instanceof EditEdificacoesRecuoStep)
    {
      currentStep.callback({apply: true, distance: distance})
    }
  }

  public setRecuoDistance(distance: number)
  {
    let newDistance : any= null;

    if(distance!=null)
    {
      newDistance = new Number(distance);
    }    

    this.currentRecuoDistance=newDistance;
  }

  public next()
  {
    this.stepsController.next();
  }

  public back()
  {
    this.stepsController.back();
  }

  public finish()
  {
    this.stepsController.finish();
  }

  public setCurrentSelectedFeature(selectedEditionFeature: Feature<any>)
  {
    this.selectedEditionFeature=selectedEditionFeature;  
  }

  public getCurrentSelectedFeature() : Feature<any>
  {
    return this.selectedEditionFeature;  
  }

  public onEdificacoesChange(event)
  {
    let currentStep: Step = this.stepsController.getCurrentStep();
    if(currentStep instanceof EditEdificacoesEdicoesStep)
    {
      currentStep.callback({edificacoesChanged: true, selectedFeature: event.value})
    }
  }

  public applySegmentChanges()
  {
    
    var viewRotationInRadians = this.customEditionController.getRotation();
    let coords = this.selectedEditionFeature.getGeometry().getCoordinates();
    let outLinearRingCoords = coords[0];
    let oldLinearRingCoords = coords[0];
    
    for(var i = 0; i < this.edificacaoSegmentList.length; ++i)
    {
      //adjusts the angle of the segment
      var olLastCoordinate = outLinearRingCoords[ i ];
      var olCurrentCoordinate = outLinearRingCoords[ i + 1 ];

      if(this.edificacaoSegmentList[i].changed)
      {
        var lengthValue = this.edificacaoSegmentList[i].distance;
        var newAngleInDegrees = this.edificacaoSegmentList[i].angle;
        
        var currentAngleInRadians = GeometryService.getAngleInRadians(olLastCoordinate, olCurrentCoordinate);
        var newAngleInRadians = newAngleInDegrees * GeometryService.CDR;
        newAngleInRadians += viewRotationInRadians;
            
         var adjustInRadians = currentAngleInRadians - newAngleInRadians;
          
        //1 - now we adjust the rotation of the segment
        var lineSegment = new LineString([ olLastCoordinate, olCurrentCoordinate ]);
        lineSegment.rotate(-adjustInRadians, olLastCoordinate);
        
        olCurrentCoordinate = lineSegment.getLastCoordinate();
        
        //2 - then we adjust the length of the segment
        olCurrentCoordinate = GeometryService.adjustLineLength(olLastCoordinate, olCurrentCoordinate, lengthValue);
        
      }
      outLinearRingCoords[ i + 1 ] = olCurrentCoordinate;

    }


    console.log("Coord: " + oldLinearRingCoords);
    console.log("New Coord: " + outLinearRingCoords);
    coords = [];
    coords.push(outLinearRingCoords);
    this.selectedEditionFeature.getGeometry().setCoordinates(coords);

    this.toastService.info("Geometria ajustada para os valores informados!!", "Ajuste Vetorial de Feição")
    
  }

  public enableEditionInteraction(edificacoesInterectionId: string)
  {
    if(this.currentEditionInteraction!=edificacoesInterectionId)
    {
      let currentStep: Step = this.stepsController.getCurrentStep();
      if(currentStep instanceof EditEdificacoesEdicoesStep)
      {
        currentStep.callback({enableInteractionId: edificacoesInterectionId})
        this.currentEditionInteraction=edificacoesInterectionId;
      }
    }
  }

  public refreshFeaturesList()
  {
    this.currentEdificacoesFeatures = this.edificacoesLayer.getSource().getFeatures();
  }

  public onAttributeValueChanged(event, element, currentAttribute)
  {
    let value;
    if(event.target)
    {
      value = event.target.value;
    }
    else
    {
      value = event.source.value;
    }
    element.set(currentAttribute, value);
    this.setModifiedFeature(element, true);
  }

  public getAttributeValue(feature : Feature<any>, attributeName: string)
  {
    let value="";
    if(feature.get(attributeName))
    {
      value=feature.get(attributeName);
    }
    return value;
  }

  public getAttributeValueNumberFixed(feature : Feature<any>, attributeName: string, digits: number) : string
  {
    return   this.getNumberFixedValue(this.getAttributeValue(feature, attributeName), digits);
  }

  public getNumberFixedValue(value: any, digits: number) : string
  {
    let numberValue: number =  Number(value);

    return numberValue.toFixed(digits);
  }

  public loadEdificacoesStyleWithLabel(defaultStyle: Style)
  {   
    this.edificacoesStyleWithLabelFunction = function(feature) 
    {
      defaultStyle.getText().setText(feature.get('index')+'');
      return defaultStyle;
    }
  }


  public loadEdificacoesSelectedStyleWithLabel(defaultStyle: Style)
  {   

    this.edificacoesSelectedStyleWithLabelFunction = function (feature, drawType) {

      var labelStyleCache = [];

      var labelStyle = new Style({
        text: new Text({
          font: '16px Calibri,sans-serif',
          fill: new Fill({
            color: 'rgba(0, 0, 0, 1)'
          }),
          stroke: new Stroke({
            color: 'rgba(255, 255, 255, 1)',
            width: 3
          })
        })
      });

      var styles = [defaultStyle];
      var geometry = feature.getGeometry();
      var type = geometry.getType();
      var lineString;

      if (type === 'Polygon') {
        lineString = new LineString(geometry.getCoordinates()[0]);
      } else if (type === 'LineString') {
        lineString = geometry;
      }
      
      if (lineString) {
        var count = 0;
        lineString.forEachSegment(function(a, b) {
          var segment = new LineString([a, b]);
          var label = count+1 + "";
          if (labelStyleCache.length - 1 < count) {
            labelStyleCache.push(labelStyle.clone());
          }
          labelStyleCache[count].setGeometry(segment);
          labelStyleCache[count].getText().setText(label);
          styles.push(labelStyleCache[count]);
          count++;
        });
      }
      return styles;
        
    }    
  }

  public onSelectedCommitEdificacoesChange(event)
  {
    let currentStep: Step = this.stepsController.getCurrentStep();
    if(currentStep instanceof EditEdificacoesCommitStep)
    {
      currentStep.callback({selectionChanged: true, changedFeature: event.source.value})
    }
  }

  public getSituacaoFeature(feature: Feature<any>)
  {
    let loteGeom : Polygon = this.currentLotesFeature.getGeometry();
    //loteGeom.intersectsCoordinate()
    //loteGeom.

    let loteJSTSGeom = GeometryService.convertOLGeom2JSTSGeom(loteGeom);

    let edificacaoJSTSGeom = GeometryService.convertOLGeom2JSTSGeom(feature.getGeometry());

    if(loteJSTSGeom.covers(edificacaoJSTSGeom))
    {
      return {icon: 'check_circle', title: 'A feição está coerente dentro da geometria do Lote'};
    }
    else
    {
      if(edificacaoJSTSGeom.intersects(loteJSTSGeom))
      {
        return {icon: 'warning', title: 'A feição está parcialmente dentro da geometria do Lote'};
      }
      else
      {
        return {icon: 'error' , title: 'A feição está totalmente fora da geometria do Lote'};
      }
    }
  }
  public getEdificacaoOperacao(feature: Feature<any>)
  {
    if(feature.get(this.operationIdFeatureKey)==1)
    {
      return {title: 'Essa feição foi inserida e será persistida.', icon: 'add_circle_outline', class: 'insert-edificacoes-icon'}
    }
    else if(feature.get(this.operationIdFeatureKey)==2)
    {
      return {title: 'Essa feição foi modificada e será persistida.', icon: 'arrow_circle_up', class: 'update-edificacoes-icon'}

    } else if(feature.get(this.operationIdFeatureKey)==3)
    {
      return {title: 'Essa feição foi apagada e será removida da base de dados.', icon: 'cancel', class: 'delete-edificacoes-icon'}
    }
    else
    {
      return {title: 'Essa feição não foi modificada.', icon: 'radio_button_unchecked', class: 'nochanges-edificacoes-icon'}
    }

  }  
  public setModifiedFeature(feature : Feature<any>, modified: boolean)
  {
    feature.set(this.modifiedFeatureKey, modified);
  }

  public updateFeatureArea(feature : Feature<any>)
  {
    let newArea = feature.getGeometry().getArea();
    feature.set(this.edificacoesAreaAttribute, newArea);

    this.calcularAreaTotal();
  }

  public calcularAreaTotal()
  {
    this.areaTotal = 0;
    if(this.currentEdificacoesFeatures)
    {
      this.currentEdificacoesFeatures.forEach(feature => {
        let currentArea : number = Number(feature.get(this.edificacoesAreaAttribute));
        this.areaTotal=this.areaTotal+currentArea;
      });
    }   
  }

  public getValoresPossiveisText(lista: any)
  {
    let text: string="";
    for (let i = 0; i < lista.length; i++) {
      const element = lista[i];
      if(i!=0)
      {
        text+=", "
      }
      text+=element      
    }

    return text
  }

  
}