import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import TileLayer from 'ol/layer/Tile';


import GeoJSON from 'ol/format/GeoJSON';

import Vector from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import TileWMS from 'ol/source/TileWMS';
import { SetupImoveisLinkTool } from './setup-imoveis-link-tool';
import { ToolsService } from '../tools.service';
import { GeometryService } from '../../../service/geometry.service';
import { LayersConfigService } from '../../layers/layers.config.service';
import { NotificationService } from 'src/app/service/notification.service';
import { StepsController } from '../tools-utils/steps-controller'
import { Step } from '../../vo/step';
import { LayerConfig } from '../../layers/layers.config';
import { ToastService } from 'src/app/service/toast.service';

import { BackendService } from 'src/app/service/backend.service';
import { CustomEditionController } from '../tools-utils/custom-edition-interaction/custom-edition-controller';
import { SelectOperationStep } from './steps/select-operation-step';
import { SearchInscricaoSpatilizeStep } from './steps/search-inscricao-spatialize';
import { Subscription } from 'rxjs';
import { Cadastroimobiliario } from '@funcate/sigweb-cti-api';
import { DialogComponent } from '../../dialog/dialog.component';
import { PropertysearchToolComponent } from '../propertysearch-tool/propertysearch-tool.component';
import { Imovel } from '../../vo/imovel';
import { NewImovelLink } from '../../vo/new-imovel-link';
import { Inscricao } from '../../vo/inscricao';
import { Feature } from 'ol';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import Text from 'ol/style/Text';
import LineString from 'ol/geom/LineString';
import Circle from 'ol/style/Circle';
import { AppNotification } from '../../vo/notification';
import CircleStyle from 'ol/style/Circle';
import { FeatureService } from 'src/app/service/feature.service';
import { DesdobroSearchInscricaoSpatilizeStep } from './steps/desdobro-search-inscricao-spatialize';
import { AnexacaoSearchInscricaoSpatilizeStep } from './steps/anexacao-search-inscricao-spatialize';
import { SubstituicaoSearchInscricaoSpatilizeStep } from './steps/substituicao-search-inscricao-spatialize';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-setup-imoveis-link-tool',
  templateUrl: './setup-imoveis-link-tool.component.html',
  styleUrls: ['./setup-imoveis-link-tool.component.css']
})
export class SetupImoveisLinkToolComponent implements OnInit {

  public tool:SetupImoveisLinkTool;
  private subscriptionTool:Subscription;
  dialog: MatDialog;

  progressBarPercent: number =0;
  progressBarStep: string;
  stepsController: StepsController;
  customEditionController: CustomEditionController;
  inputCurrentFeature: any;
  inputLotesCurrentFeature: any;
  inputFeatureSRID: string;
  currentFeature: any;
  currentInscricao: string;
  currentCadImob: Cadastroimobiliario;
  lotesCurrentFeature: any;
  olBaseLayer: any;
  olImoveisLayer: any;
  olLotesLayer: any;
  olImoveisWMSLayer: any;
  lotesLayer: LayerConfig;
  imoveisLayer: LayerConfig;
  searchPropertyDialogRef: MatDialogRef<DialogComponent>;
  desdobroNewImoveisLink: NewImovelLink[];
  selectedEditionFeature: NewImovelLink;
  currentEditionInteraction: string;
  notificationSubscrition: Subscription;

  desdobroNewImoveisTableRowsColumns:  string[] = ['desdobro-new-imoveis-radio','index','ic', 'endereco', 'acoes'];  ;
  
  imovelDesativadoLabelKey: string = "Inativada";


  //Step1

  selectedOperation: any;

  //Step2
  readonly addInterectionId: string = 'add-interection-id';
  readonly deleteInterectionId: string = 'delete-interection-id';
  readonly selectInterectionId: string = 'edificacoes-select-interection-id';
  readonly getFeatureInfoInteractionId: string = 'get-feature-info-interection-id';
  // Nome do campo id para a camada Edificacoes
  imoveisIdAttribute: string;

  imoveisStyle: Style;
  imoveisStyleWithLabelFunction: any;
  imoveisSelectedStyle: Style;
  imoveisSelectedStyleWithLabelFunction: any;
  imoveisInativadosStyle: any;
  imoveisInativadosSelectedStyle: any;
  public imoveisICAttributeConfig: string = "inscricao_attribute";

  //Substituicao

  substituicaoNewLinkImoveis : NewImovelLink[];

  //Anexacao

  anexacaoSelectedImoveisTableRowsColumns:  string[] = ['anexacao-selected-imoveis-radio','ic', 'endereco', 'acoes'];  
  anexacaoNewLinkImoveisTableRowsColumns:  string[] = ['anexacao-new-imoveis-radio','index','ic', 'endereco', 'acoes'];  
  substituicaoNewLinkImoveisTableRowsColumns:  string[] = ['substituicao-new-imoveis-radio','index','ic', 'endereco', 'acoes'];  
  anexacaoNewLinkImoveis : NewImovelLink[];
  anexacaoSelectedImoveis : NewImovelLink[];
  substituicaoSelectedImoveis : NewImovelLink[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private toolsService: ToolsService, 
  private layersService: LayersConfigService, private notificationService: NotificationService,
   private backendService: BackendService, public toastService: ToastService,  private featureService: FeatureService, dialog: MatDialog) 
  {
    this.dialog=dialog;
    this.inputCurrentFeature = data.currentFeature;
    this.inputFeatureSRID = data.currentFeatureSRID;
    this.inputLotesCurrentFeature = data.lotesCurrentFeature;
    this.lotesLayer = data.lotesLayer;
    this.imoveisLayer = data.imoveisLayer;
    this.currentInscricao = data.currentInscricao;
     this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
       (tool:SetupImoveisLinkTool) => {
         this.tool=tool;
        
       },()=> {
      }
    ); 
    this.desdobroNewImoveisLink = [];    
    this.substituicaoSelectedImoveis = [];
    this.anexacaoSelectedImoveis = [];
    this.anexacaoNewLinkImoveis = [];
    this.substituicaoNewLinkImoveis = [];
    this.loadCadICData();
    this.imoveisIdAttribute = this.layersService.getLayerIdAttribute(this.imoveisLayer);

    this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
      if (notification.event==AppNotification.ON_CUSTOM_EDITION_STEP_CHANGED) 
      {
        this.updateProgressBar(this.stepsController.getPercent());
      }
  });
  }

  initMap() 
  {
      this.customEditionController = new CustomEditionController('ol_map', this.notificationService);
  }

  loadFeature(inputFeature: any, featureSRID: string)
  {
    let format = new GeoJSON();
    
    let feature = format.readFeature(inputFeature, {dataProjection: featureSRID });

    let geometry;

    if(feature.getGeometry().getType() == 'GeometryCollection')
    {
      geometry = feature.getGeometry().getGeometries()[0];
    } 
    else if(feature.getGeometry().getType() == 'MultiPoint')
    {
      geometry = feature.getGeometry().getPoints()[0];

    } else if(feature.getGeometry().getType() == 'MultiPolygon')
    {
      geometry = feature.getGeometry().getPolygons()[0];
    }
    
    if(geometry.getType() != 'Point' && geometry.getType() != 'Polygon')
    {
      console.log('Invalid geometric type! ' + geometry.getType());
      return;
    }

    geometry = GeometryService.adjustPolygonOrientation(geometry);

    feature.setGeometry(geometry);
    return feature;
  }
  
  loadLayers()
  {
    if(this.customEditionController)
    {
          
      this.defineLayerStyles();
    
      this.currentFeature = this.loadFeature(this.inputCurrentFeature, this.inputFeatureSRID);

      if(this.inputLotesCurrentFeature)
      {
        this.lotesCurrentFeature = this.loadFeature(this.inputLotesCurrentFeature, this.inputFeatureSRID);
      }      

      this.currentFeature.set('index', this.imovelDesativadoLabelKey)
      
      let imoveisReferenceSource = new Vector();
      this.olImoveisLayer = new VectorLayer({ source: imoveisReferenceSource });
      imoveisReferenceSource.addFeature( this.currentFeature );
      this.olImoveisLayer.setStyle(this.imoveisStyleWithLabelFunction);

      //BASE ORTOFOTO
      
      let baseLayerConfig=this.layersService.getEdificacoesEditionToolBaseLayer();
      this.olBaseLayer = this.getWMSLayer(baseLayerConfig);

      //Lotes Layer      
      this.olLotesLayer = this.getWMSLayer(this.lotesLayer);

      //Imoveis WMS Layer      
      this.olImoveisWMSLayer = this.getWMSLayer(this.imoveisLayer);
      

      //Adding layers to map
      this.customEditionController.addLayer(this.olBaseLayer);
      this.customEditionController.addLayer(this.olLotesLayer);
      this.customEditionController.addLayer(this.olImoveisWMSLayer);
      this.customEditionController.addLayer(this.olImoveisLayer);

      if(this.lotesCurrentFeature)
      {
        this.customEditionController.fitFeature(this.lotesCurrentFeature);
      }
      else
      {
        this.customEditionController.fitFeature(this.currentFeature);
      }
      

    }    
  }

  update()
  {
    this.initMap();

    this.loadLayers();    

    this.initStepController();        
  }

  private updateProgressBar(percent: number)
  {
    this.progressBarPercent=percent;
  }
 
  ngOnInit(): void 
  {
    this.update();
  }

  private getWMSLayer(layer: LayerConfig)
  {
    var layerURL =  this.layersService.getLayerURL(layer);
    var layerName =  this.layersService.getLayerId(layer);
    let anticfloat = (Math.floor( Math.random() * 2000000 ) + 1)/21;
    var newLayer = new TileLayer({
      source: new TileWMS({
        hidpi : false,
        url: layerURL,
        params: {'LAYERS': layerName, 'TILED': true, 'SRS': 'EPSG:'+this.inputFeatureSRID, 'anticfloat': anticfloat},
        serverType: 'geoserver'
      }),
      });

    return newLayer;
  }


  private initStepController()
  {
    this.stepsController = new StepsController(this.notificationService);

     let step1 = new SelectOperationStep(1, this, this.toastService);

     this.stepsController.addNewStep(step1);

     let step2 = new SearchInscricaoSpatilizeStep(2, this, this.toastService, this.backendService, this.layersService, this.featureService);

     this.stepsController.addNewStep(step2);

    // let step3 = new EditEdificacoesEdicoesStep(3, this);

    // this.stepsController.addNewStep(step3);

    // let step4 = new EditEdificacoesCommitStep(4, this, this.layersService, this.backendService);

    // this.stepsController.addNewStep(step4);
    
    this.stepsController.next();

  }
  public isCardVisible(id: string)
  {
     let currentStep: Step = this.stepsController.getCurrentStep();
     if(id=="select-operation-card")
     {
       if(currentStep instanceof SelectOperationStep)
       {
         return true;
       }
    
     } else if(id=="botoes-card")
     {
       if(currentStep instanceof SelectOperationStep
        ||
        currentStep instanceof SearchInscricaoSpatilizeStep)
       {
         return true;
       }
       
     } else if(id=="selected-operation-card")
     {
       if(currentStep instanceof SearchInscricaoSpatilizeStep)
       {
         return true;
       }
     } else if(id=="desdobro-selected-inscricao-card")
     {
       if(currentStep instanceof SearchInscricaoSpatilizeStep
        && currentStep.currentStep instanceof DesdobroSearchInscricaoSpatilizeStep)
       {
         return true;
       }
    } else if(id=="desdobro-new-inscricoes-card")
      {
        if(currentStep instanceof SearchInscricaoSpatilizeStep
          && currentStep.currentStep instanceof DesdobroSearchInscricaoSpatilizeStep)
        {
          return true;
        }
      }
      else if(id=="anexacao-selected-inscricoes-card")
      {
        if(currentStep instanceof SearchInscricaoSpatilizeStep
          && currentStep.currentStep instanceof AnexacaoSearchInscricaoSpatilizeStep)
        {
          return true;
        }
      } else if(id=="substituicao-selected-inscricao-card")
      {
        if(currentStep instanceof SearchInscricaoSpatilizeStep
          && currentStep.currentStep instanceof SubstituicaoSearchInscricaoSpatilizeStep)
        {
          return true;
        }
      }
      
      else if(id=="substituicao-new-inscricao-card")
      {
        if(currentStep instanceof SearchInscricaoSpatilizeStep
          && currentStep.currentStep instanceof SubstituicaoSearchInscricaoSpatilizeStep)
        {
          return true;
        }
      }    
      else if(id=="anexacao-new-inscricao-card")
      {
        if(currentStep instanceof SearchInscricaoSpatilizeStep
          && currentStep.currentStep instanceof AnexacaoSearchInscricaoSpatilizeStep)
        {
          return true;
        }
      }    
    
    
     return false;
  }

  public onOperationChanged(event)
  {
    this.selectedOperation = event.value;
  }

  public next()
  {
    this.stepsController.next();
  }

  public back()
  {
    this.stepsController.back();
  }

  public finish()
  {
    this.stepsController.finish();
  }

  public enableEditionInteraction(interectionId: string, newImovelLink: NewImovelLink)
  {
    this.setCurrentSelectedFeature(newImovelLink);
    if(this.currentEditionInteraction!=interectionId)
    {     
      let currentStep: Step = this.stepsController.getCurrentStep();
      if(currentStep instanceof SearchInscricaoSpatilizeStep)
      {
        currentStep.callback({enableInteractionId: interectionId})
        this.currentEditionInteraction=interectionId;
      }
    }
    
  }

  public loadCadICData()
  {
    this.backendService.getCadImob(this.currentInscricao).then((cadImob: Cadastroimobiliario)=>{
      this.currentCadImob=cadImob;
    });
  }

  public searchInscricoes()
  {

    let oneOnly = true;
    if(this.selectedOperation=='desdobramento')
    {
      oneOnly=false;
    }

      this.searchPropertyDialogRef = this.dialog.open(DialogComponent, {
          height: '80%',
          width: '90%',
          data: { component: PropertysearchToolComponent, title: "Pesquisa de Imóveis", parent: this, searchOnly: true, withoutGeometry: true, oneOnly: oneOnly}
      });      
  }

  public closeSearchInscricoes(imoveis: Imovel[])
  {

    let newImoveisDesdobro: NewImovelLink[] = [];
    let newImoveisAnexacao: NewImovelLink[] = [];
    let newImoveisSubstituicao: NewImovelLink[] = [];

    if(this.selectedOperation=='desdobramento')
    {
      this.desdobroNewImoveisLink.forEach(imovel => {
        newImoveisDesdobro.push(imovel);
      });
    }    

    imoveis.forEach(imovel => {      

      if(this.selectedOperation=='desdobramento')
      {
        let index = this.desdobroNewImoveisLink.length + 1;
        newImoveisDesdobro.push(new NewImovelLink(index, imovel, null));      

      } else if(this.selectedOperation=='substituicao')
      {
        let index = this.substituicaoNewLinkImoveis.length + 1;
        newImoveisSubstituicao.push(new NewImovelLink(index, imovel, null));  
      } else
      {
        this.anexacaoNewLinkImoveis=[];
        let index = this.anexacaoNewLinkImoveis.length + 1;
        newImoveisAnexacao.push(new NewImovelLink(index, imovel, null));
      }
    });

    this.desdobroNewImoveisLink = newImoveisDesdobro;
    this.anexacaoNewLinkImoveis = newImoveisAnexacao;
    this.substituicaoNewLinkImoveis = newImoveisSubstituicao;
    this.searchPropertyDialogRef.close();
  }

  public addAnexacaoSelectedImovel(imovel: NewImovelLink)
  {

    let newList : NewImovelLink[] = []    
    this.anexacaoSelectedImoveis.forEach(element => {
      newList.push(element);
    });
    
    imovel.getFeature().set('index', this.imovelDesativadoLabelKey);
    
    newList.push(imovel);
    
    this.olImoveisLayer.getSource().addFeature(imovel.getFeature());
    this.anexacaoSelectedImoveis=newList;
  }

  public imovelToInscricaoCompleta(imovel: Imovel)
  {
    let ic = Inscricao.fromImovel(imovel).getInscricaoCompleta();
    return ic;
  }

  public onImovelChange(event)
  {
    let currentStep: Step = this.stepsController.getCurrentStep();
    if(currentStep instanceof SearchInscricaoSpatilizeStep)
    {
      currentStep.callback({edificacoesChanged: true, selectedFeature: event.value});
    }
  }

  public getCurrentSelectedFeature() : NewImovelLink
  {
    return this.selectedEditionFeature;  
  }

  public setCurrentSelectedFeature(selectedEditionFeature: NewImovelLink)
  {
    this.selectedEditionFeature=selectedEditionFeature;  
  }

  defineLayerStyles()
  {
    this.imoveisStyle = new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({color: 'rgba(255,0,0,0.3)'}),
        stroke: new Stroke({
          color: 'rgba(255,0,0,1)',
          width: 2
        })
      }),
       text: new Text({
         font: '18px Calibri,sans-serif',
         fill: new Fill({
           color: 'rgba(0, 0, 0, 1)'
         }),
         stroke: new Stroke({
           color: 'rgba(255, 255, 255, 1)',
           width: 3
         }),
         offsetY: -20

       })
    });  

    this.imoveisSelectedStyle = new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({color: 'rgba(255,20,0,0.9)'}),
        stroke: new Stroke({
          color: 'rgba(255,20,0,1)',
          width: 2
        })
      }),
       text: new Text({
         font: '18px Calibri,sans-serif',
         fill: new Fill({
           color: 'rgba(0, 0, 0, 1)'
         }),
         stroke: new Stroke({
           color: 'rgba(255, 255, 255, 1)',
           width: 3
         }),
         offsetY: -20

       })
    });  

    this.imoveisInativadosStyle = new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({color: 'rgba(0,0,0,0.6)'}),
        stroke: new Stroke({
          color: 'rgba(0,0,0,1)',
          width: 2
        })
      }),
       text: new Text({
         font: '18px Calibri,sans-serif',
         fill: new Fill({
           color: 'rgba(0, 0, 0, 1)'
         }),
         stroke: new Stroke({
           color: 'rgba(255, 255, 255, 1)',
           width: 3
         }),
         offsetY: -20

       })
    });  

    this.imoveisInativadosSelectedStyle = new Style({
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({color: 'rgba(0,0,0,0.9)'}),
        stroke: new Stroke({
          color: 'rgba(0,0,0,1)',
          width: 2
        })
      }),
       text: new Text({
         font: '18px Calibri,sans-serif',
         fill: new Fill({
           color: 'rgba(0, 0, 0, 1)'
         }),
         stroke: new Stroke({
           color: 'rgba(255, 255, 255, 1)',
           width: 3
         }),
         offsetY: -20

       })
    });  

    this.imoveisStyleWithLabelFunction = this.getStyleWithLabel(this.imoveisStyle, this.imoveisInativadosStyle, this.imovelDesativadoLabelKey);
    this.imoveisSelectedStyleWithLabelFunction = this.getStyleWithLabel(this.imoveisSelectedStyle, this.imoveisInativadosSelectedStyle, this.imovelDesativadoLabelKey);
  }

  public getStyleWithLabel(defaultStyle: Style, inativadoStyle: Style, imovelDesativadoLabelKey: string)
  {   
    let styleFunction = function(feature) 
    {
      let outStyle = defaultStyle;
      
      if(feature.get('index')==imovelDesativadoLabelKey)
      {
        outStyle = inativadoStyle;
      }
      
      outStyle.getText().setText(feature.get('index')+'')      
      
      return outStyle;
    }

    return styleFunction;
  }

  public getAttributeValue(feature : Feature<any>, attributeName: string)
  {
    let value="";
    if(feature.get(attributeName))
    {
      value=feature.get(attributeName);
    }
    return value;
  }
  
}