import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inscricao } from 'src/app/map/vo/inscricao';
import { BackendService } from 'src/app/service/backend.service';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-ficha-upload',
  templateUrl: './ficha-upload.component.html',
  styleUrls: ['./ficha-upload.component.css']
})
export class FichaUploadComponent implements OnInit 
{
  fichaUploadControl: FormGroup;
  inscricoes: string[];
  parentDialog: any;
  
  inscricoesCSV: string;

  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private backendService: BackendService, private toastService: ToastService) 
  {
    this.fichaUploadControl = new FormGroup(
      {
        file: new FormControl(),
        inscricoesCSV: new FormControl()
      }
    );     
     
      this.inscricoes = this.data.inscricoes;
      this.inscricoesCSV="";
      this.parentDialog = this.data.parentDialog;
      
  }

  ngOnInit(): void 
  {
    this.inscricoes.forEach(ic => {
      if(this.inscricoesCSV!="")
      {
        this.inscricoesCSV+=",";
      }
      this.inscricoesCSV+=ic;
    });
  }
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fichaUploadControl.get('file').setValue(file);
    }
  }

  onFormSubmit()
  {
 
    let file = this.fichaUploadControl.get('file').value;
    let ficha: Blob = new Blob([file], { type: 'application/octet-stream' });
    
    if(file)
    {
      this.backendService.uploadFicha(this.inscricoesCSV, file.name, ficha).then(()=>
      {
        this.toastService.sucess("Documento associado.","Sucesso");
        this.parentDialog.closeNewFichaUploadDialog();
      }).catch(()=>
      {
        this.toastService.error("Problema ao associar documento.","Erro");
      })
    }
    else
    {
      this.toastService.error("Favor selecionar primeiramente um arquivo.","Erro");
    }

  }

}
