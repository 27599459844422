import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LatLng, Layer, Util } from 'leaflet';
import { environment } from 'src/environments/environment';
import { LayersConfigService } from '../map/layers/layers.config.service';
import { Feature } from '../map/vo/feature';
import { FeatureInfo } from '../map/vo/featureinfo';
import { Property } from '../map/vo/property';

@Injectable({
    providedIn: 'root'
  })

export class ScriptService {
   
    /** start http service client */
    constructor() { }



    public static loadExternalScript(url)
    {
        const node = document.createElement('script');
        node.src = url;
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        document.getElementsByTagName('head')[0].appendChild(node);

    }

    
} 