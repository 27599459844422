<mat-accordion>    
  <ng-container >
      <mat-expansion-panel [expanded]="true" class="exp-panel">
          <mat-expansion-panel-header>
              <mat-panel-title id="user-header" class="user-col">
                  <mat-icon class="material-icons layer-icon">layers</mat-icon>Associação de Usuários a Grupo
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
          </mat-expansion-panel-header>
          <div *ngFor="let appUser of appUsers">{{appUsers.name}}</div>
          <div>
            <form [formGroup]="permissionsFormGroup" >
              <mat-form-field class="filtro">
                <mat-label>Filtro</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. fonte" #input>
              </mat-form-field>  
              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)">
                  <ng-container matColumnDef="user">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="usuario"> Usuário </th>
                      <td mat-cell *matCellDef="let appUser"> {{appUser.name}} </td>
                    </ng-container>
                    <ng-container matColumnDef="group">
                      <th mat-header-cell *matHeaderCellDef> Grupo </th>
                      <td mat-cell *matCellDef="let appUser" >
                        <div class="group-config-class">
                            <mat-select id="user-{{appUser.id}}-group" formControlName="user-{{appUser.id}}-group">
                                <mat-option *ngFor="let userGroup of userGroups" [value]="userGroup" >
                                  {{userGroup.name}}
                                </mat-option>
                            </mat-select>
                            <div class="commit-changes">
                                <mat-icon class="icon-done" title="Salvar configuração de grupo de usuário" (click)="saveItem(appUser.id)">done</mat-icon>
                                <mat-icon class="icon-clear" title="Restaurar configuração" (click)="cancelItem(appUser.id)">clear</mat-icon>
                            </div>                      
                        </div>
                    </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
              </form>
          </div>
      </mat-expansion-panel>
  </ng-container>
</mat-accordion>


