import { UserGroup } from '../vo/user-group';
import { ToolsService } from './tools.service';

export abstract class Tool {
    id: String;
    type: String;
    name: String;
    title: string;
    enabled: boolean;
    execAction: Function;
    //toogleAction: Function;
    abstract enableImpl(): void ;
    abstract disableImpl(): void;
    cursorType: string;
    protected visible?: boolean;
    icon: string;
    //True if is a tool to be used over the layer toolbar (on the treeview).  False if is a generic tool over the toolbar
    layerTool: boolean=false;
    customParameter: Map<String, any>;

    //User control config
    publicTool: boolean=true;
    allowedUserGroups: Array<UserGroup> = [];
    toolsService: ToolsService;

    constructor(toolsService: ToolsService)
    {
        this.toolsService=toolsService;
    }

    public enable()
    {
        console.log("Enabling tool: " + this.id);
        this.enabled=true;
        this.enableImpl();
    }
    public disable()
    {
        console.log("Disable tool: " + this.id);
        this.enabled=false;
        this.disableImpl();
    }
    public setCustomParameter(customParameter: Map<String, any>)
    {
        this.customParameter=customParameter;
    }

    public isVisible()
    {
        
        if(this.visible)
        {
            let visible = this.toolsService.isToolVisibleForCurrentUser(this);
            return visible;
        }
        else
        {
            return false;
        }
    };

}