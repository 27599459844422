
import { DatePipe } from '@angular/common';
import { LatLng } from 'leaflet';
import { Property } from 'src/app/map/vo/property';
import { Imovel } from './imovel';

export class ColetaPGV
 {
    private id: number;
    private fonte: string;
    private dataColeta: Date;
    private dataRegistro: Date;
    private areaTerreno: number;
    private areaConstrucao: number;
    private valorTerreno: number;
    private valorConstrucao: number;
    private valorTotal: number;
    private obs: string;
    private idUser: number;
    private loginUser: string;
    private nomeUser: string;
    private position: LatLng;
    private featureGeoJSON: any;
    private naturezaTransacao: number;
    private naturezasTransacao: any;
    private tipoConstrucao: string;
    private endereco: string; 

    private tiposConstrucao: any[];
    

    constructor()
    {
        this.loadNaturezasTransacao();
        this.loadTiposDeConstrucao();
    }

    public getNaturezaTransacao(): number {
        return this.naturezaTransacao;
    }

    public setNaturezaTransacao(naturezaTransacao: number): void {
        this.naturezaTransacao = naturezaTransacao;
    }

    public getDataRegistro(): Date {
        return this.dataRegistro;
    }

    public setDataRegistro(dataRegistro: Date): void {
        this.dataRegistro = dataRegistro;
    }

    public getId(): number {
        return this.id;
    }

    public setId(id: number): void {
        this.id = id;
    }

    public getFonte(): string {
        return this.fonte;
    }

    public setFonte(fonte: string): void {
        this.fonte = fonte;
    }

    public getDataColeta(): Date {
        return this.dataColeta;
    }

    public setDataColeta(dataColeta: Date): void {
        this.dataColeta = dataColeta;
    }

    public getAreaTerreno(): number {
        return this.areaTerreno;
    }

    public setAreaTerreno(areaTerreno: number): void {
        this.areaTerreno = areaTerreno;
    }

    public getAreaConstrucao(): number {
        return this.areaConstrucao;
    }

    public setAreaConstrucao(areaConstrucao: number): void {
        this.areaConstrucao = areaConstrucao;
    }

    public getValorTerreno(): number {
        return this.valorTerreno;
    }

    public setValorTerreno(valorTerreno: number): void {
        this.valorTerreno = valorTerreno;
    }

    public getValorConstrucao(): number {
        return this.valorConstrucao;
    }

    public setValorConstrucao(valorConstrucao: number): void {
        this.valorConstrucao = valorConstrucao;
    }

    public getValorTotal(): number {
        return this.valorTotal;
    }

    public setValorTotal(valorTotal: number): void {
        this.valorTotal = valorTotal;
    }

    public getObs(): string {
        return this.obs;
    }

    public setObs(obs: string): void {
        this.obs = obs;
    }

    public getIdUser(): number {
        return this.idUser;
    }

    public setIdUser(idUser: number): void {
        this.idUser = idUser;
    }

    public getLoginUser(): string {
        return this.loginUser;
    }

    public setLoginUser(loginUser: string): void {
        this.loginUser = loginUser;
    }

    public getNomeUser(): string {
        return this.nomeUser;
    }

    public setNomeUser(nomeUser: string): void {
        this.nomeUser = nomeUser;
    }

    public getPosition(): LatLng {
        return this.position;
    }

    public setPosition(position: LatLng): void {
        this.position = position;
    }


    public getFeatureGeoJSON(): any {
        return this.featureGeoJSON;
    }

    public setFeatureGeoJSON(featureGeoJSON: any): void {
        this.featureGeoJSON = featureGeoJSON;
    }

    public getTiposConstrucao(): any[] {
        return this.tiposConstrucao;
    }

    public setTiposConstrucao(tiposConstrucao: any[]): void {
        this.tiposConstrucao = tiposConstrucao;
    }

    public getEndereco(): string {
        return this.endereco;
    }

    public setEndereco(endereco: string): void {
        this.endereco = endereco;
    }
    
    
    public toProperties() : Array<Property>
    {

        let properties : Array<Property> = [];

        if(this.getId())
        {
            //id
            properties.push(new Property("id",this.getId().toString()));
        }
        if(this.getFonte())
        {
            //fonte
            properties.push(new Property("fonte",this.getFonte()));
        }

        if(this.getNaturezaTransacao())
        {
            //fonte
            properties.push(new Property("natureza_transacao",this.getNaturezaTransacao().toString()));
        }
        if(this.getDataRegistro())
        {
            let datePipe = new DatePipe('en-US');
            let dataRegistro = datePipe.transform(this.getDataRegistro().toString(), 'dd/MM/yyyy HH:mm');
            
            //data_registro
            properties.push(new Property("data_registro",dataRegistro));
        }
        if(this.getDataColeta())
        {
            let datePipe = new DatePipe('en-US');
            let dataColeta = datePipe.transform(this.getDataColeta().toString(), 'dd/MM/yyyy');
            
            //data_coleta
            properties.push(new Property("data_coleta",dataColeta));
        }
        if(this.getAreaTerreno())
        {
            //area_terreno
            properties.push(new Property("area_terreno",this.getAreaTerreno().toString()));
        }
        if(this.getAreaConstrucao())
        {
            //area_construcao
            properties.push(new Property("area_construcao",this.getAreaConstrucao().toString()));
        }
        if(this.getValorTerreno())
        {
            //valor_terreno
            properties.push(new Property("valor_terreno",this.getValorTerreno().toString()));
        }
        if(this.getValorConstrucao())
        {
            //valor_construcao
            properties.push(new Property("valor_construcao",this.getValorConstrucao().toString()));
        }
        if(this.getValorTotal())
        {
            //valor_total
            properties.push(new Property("valor_total",this.getValorTotal().toString()));
        }
        if(this.getObs())
        {
            //obs
            properties.push(new Property("obs",this.getObs()));
        }
        if(this.getIdUser())
        {
            //id_user
            properties.push(new Property("id_user",this.getIdUser().toString()));
        }
        if(this.getLoginUser())
        {
            //login_user
            properties.push(new Property("login_user",this.getLoginUser()));
        }
        if(this.getNomeUser())
        {
            //nome_user
            properties.push(new Property("nome_user",this.getNomeUser()));
        }
        if(this.getTipoConstrucao())
        {
            //nome_user
            properties.push(new Property("tipo_da_construcao",this.getTipoConstrucao()));
        }
        if(this.getEndereco())
        {
            //nome_user
            properties.push(new Property("endereco",this.getEndereco()));
        }

        return properties;
    } 
    public fromProperties(properties: Array<Property>)
    {
        properties.forEach(property => {
            if(property.key=="id")
            {
                if(property.value)
                {
                    this.setId(Number.parseInt(property.value.toString()));
                }                
            }
            if(property.key=="fonte")
            {
                if(property.value)
                {
                    this.setFonte(property.value.toString());
                }
            }
            if(property.key=="natureza_transacao")
            {
                if(property.value)
                {
                    this.setNaturezaTransacao(Number.parseInt(property.value.toString()));
                }
            }
            if(property.key=="data_registro")
            {
                if(property.value)
                {                 
                    this.setDataRegistro(new Date(property.value.toString()));
                }
            }
            if(property.key=="data_coleta")
            {
                if(property.value)
                {  
                    let strDate = this.removeDateTimezone(property.value.toString())                   
                    this.setDataColeta(new Date(strDate));
                }
            } 
            if(property.key=="area_terreno")
            {
                if(property.value)
                {
                    this.setAreaTerreno(Number.parseFloat(property.value.toString()));
                }
            }
            if(property.key=="area_construcao")
            {
                if(property.value)
                {
                    this.setAreaConstrucao(Number.parseFloat(property.value.toString()));
                }
            }
            if(property.key=="valor_terreno")
            {
                if(property.value)
                {
                    this.setValorTerreno(Number.parseFloat(property.value.toString()));
                }
            }
            if(property.key=="valor_construcao")
            {
                if(property.value)
                {
                    this.setValorConstrucao(Number.parseFloat(property.value.toString()));
                }
            }
            if(property.key=="valor_total")
            {
                if(property.value)
                {
                    this.setValorTotal(Number.parseFloat(property.value.toString()));
                }
            }
            if(property.key=="obs")
            {
                if(property.value)
                {
                    this.setObs(property.value.toString());
                }
            }
            if(property.key=="id_user")
            {
                if(property.value)
                {
                    this.setIdUser(Number.parseInt(property.value.toString()));
                }                
            }
            if(property.key=="id_user")
            {
                if(property.value)
                {
                    this.setIdUser(Number.parseInt(property.value.toString()));
                }                
            }
            if(property.key=="login_user")
            {
                if(property.value)
                {
                    this.setLoginUser(property.value.toString());
                }
            }
            if(property.key=="nome_user")
            {
                if(property.value)
                {
                    this.setNomeUser(property.value.toString());
                }
            }
            if(property.key=="tipo_da_construcao")
            {
                if(property.value && property.value.toString())
                {
                    this.setTipoConstrucao(property.value.toString());
                }
                else
                {
                    this.setTipoConstrucao(this.getEmptyTipoConstrucao());
                }
                
            }
            if(property.key=="endereco")
            {
                if(property.value)
                {
                    this.setEndereco(property.value.toString());
                }
            }
              
        });
    }  

    public getTipoConstrucao(): string {
        return this.tipoConstrucao;
    }

    public setTipoConstrucao(tipoConstrucao: string): void {
        this.tipoConstrucao = tipoConstrucao;
    }

    public loadNaturezasTransacao()
    {
        this.naturezasTransacao = [
            {id: 1, name:	"Anúncio" },
            {id: 2, name:	"Adjudicação"},
            {id: 3, name:	"Arrematação"},
            {id: 4, name:	"Cessão de Direitos"},
            {id: 5, name:	"Compra e Venda Instrumento Particular"},
            {id: 6, name:	"Compra e Venda Instrumento Particular com Força de Escritura"},
            {id: 7, name:	"Compra e Venda Instrumento Público"},
            {id: 8, name:	"Financiamento (Parcial/Total)"},
            {id: 9, name:	"Outros"},
            {id: 10, name:	"Permuta"},
            {id: 11, name:	"Remissão"},
            {id: 12, name:	"Separação/Divórcio"},
            {id: 13, name:	"Transmissão de Domínio Útil"},
            {id: 14, name:	"Usufruto/Uso/Habitação"}
          ];
    }     

    public getNaturezaNameById(id: number)
    {
        for (let i = 0; i < this.naturezasTransacao.length; i++) {
            const natureza = this.naturezasTransacao[i];
            if(natureza.id==id)
            {
                return natureza.name;
            }
        }           

        return ""       
    }

    public loadTiposDeConstrucao()
    {
        this.tiposConstrucao = Imovel.getTiposDeConstrucao();
    }


    private removeDateTimezone(dateString: string)
    {
        if(dateString)
        {
            return dateString.replace("Z","");
        }

    }

    private getEmptyTipoConstrucao()
    {
        return "TERRENO";
    }



}
