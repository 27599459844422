<mat-accordion class="export-sinter-accordion">
    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="layername" id="export-sinter-mat-expansion-panel">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header" class="layers-col">
                    <mat-icon class="material-icons layer-icon">format_list_numbered</mat-icon>Lista de atributos
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="download-div" >
                <div class="download-layer">
                    <div class="setor-quadra-filter-div">
                        <form [formGroup]="exportSinterFormControl" (ngSubmit)="onFormSubmit()">
                            <mat-form-field>
                                <input matInput
                                class="property-value"
                                placeholder="Setor:"
                                name="setor"
                                formControlName="setor"
                                (paste)="onPaste($event)"
                                value="" (blur)="validateLength('setor', $event)">
                            </mat-form-field>
                            <mat-form-field>
                                <input matInput
                                class="property-value"
                                placeholder="Quadra:"
                                name="quadra"
                                formControlName="quadra"
                                (paste)="onPaste($event)"
                                value="" (blur)="validateLength('quadra', $event)">
                            </mat-form-field>
                        </form>
                    </div>
                    <div class="download-layer-button">
                        <div class="download-button">
                            <button *ngIf="downloading == false" type="button" (click)="onFormSubmit()" mat-raised-button color="primary" id="download-layer-button">
                                <span class="material-icons">get_app</span> Baixar
                            </button>
                            <button *ngIf="downloading == true" type="button" (click)="onFormSubmit()" mat-raised-button color="disabled" id="download-layer-button" disabled="disabled">
                                Baixando
                            </button>                
                        </div>
                        <div  class="transfer-button">
                            <button *ngIf="downloading == false" type="button" (click)="transfer()" mat-raised-button color="primary" id="download-layer-button">
                                <span class="material-icons">outbox</span> Transmitir
                            </button>
                            <button *ngIf="downloading == true" type="button" (click)="transfer()" mat-raised-button color="disabled" id="download-layer-button" disabled="disabled">
                                Transmitindo
                            </button>                
                        </div>
                        <mat-spinner *ngIf="downloading == true" [diameter]="15" strokeWidth="2"></mat-spinner>  
                    </div>    
                </div>
            </div>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>



