import { Component, OnInit } from '@angular/core';
import { CanvasService } from '../canvas/canvas.service';
import { Observable, Subscription } from 'rxjs';
import { Tool } from '../tools/tool';
import { ToolsService } from '../tools/tools.service';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../vo/notification';
import * as L from 'leaflet';



@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss']
})

export class CanvasComponent implements OnInit {

  options:any;
  toolbar: Observable<Tool>;
  private defaultCursor: string = "tool-cursor-default";
  currentCursor: string = this.defaultCursor;
  notificationSubscrition: Subscription;  

  constructor(private canvasService:CanvasService, private toolsService: ToolsService, private notificationService: NotificationService) 
  {

    this.options=this.canvasService.getOptions();

    this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
      if (notification.event==AppNotification.CHANGE_TOOL_EVENT)
      {
         this.setCurrentToolCursor(notification.object);
      } 
    });


    //this.layersControlOptions=this.canvasService.getControlOptions();
  }

  ngOnInit(): void {
  }

  onGetGeomsFromMap() {
    this.canvasService.getGeomsFromMap();
  }

  onMapReady(map: L.Map) 
  {
    this.translateLeafletZoomControl(map);
    this.canvasService.setMapReference(map);
    //this.canvasService.addGeomanControls();
    //this.canvasService.addMarker();
    this.canvasService.leafletEasyPrint();
    this.canvasService.addScaleBar();
    this.canvasService.addScaleSelectorBar();
    this.toolbar=this.canvasService.getToolbar();
    
    map.pm.setLang('pt_br', this.getCustomTranslation(), 'pt_br');

    window.dispatchEvent(new Event('resize'));

    let mapReadyNotification = new AppNotification(AppNotification.ON_MAP_READY, map);

    this.notificationService.send(mapReadyNotification);

      L.Marker.prototype.options.icon = L.icon({
        iconUrl: "assets/marker-icon.png",
        shadowUrl: "assets/marker-shadow.png",
        iconRetinaUrl: "assets/marker-icon-2x.png",
        iconSize: [ 25, 41 ],
        iconAnchor: [ 13, 41 ],
        popupAnchor: [0, -30]
    });

  L.Icon.Default.prototype.options.iconUrl = 'assets/marker-icon.png';
  L.Icon.Default.prototype.options.shadowUrl = 'assets/marker-shadow.png';
  L.Icon.Default.prototype.options.iconRetinaUrl = 'assets/marker-icon-2x.png';
  L.Icon.Default.prototype.options.iconSize= [ 25, 41 ];
  L.Icon.Default.prototype.options.iconAnchor= [ 13, 41 ];
  L.Icon.Default.prototype.options.popupAnchor= [0, -30];

  }

  private translateLeafletZoomControl(map: L.Map)
  {
    map.zoomControl['_zoomInButton'].title='Aproximar';
    map.zoomControl['_zoomOutButton'].title='Afastar';
  }

  getCustomTranslation()
  {
    const customTranslation = {
      buttonTitles: {
        drawMarkerButton: "Desenhar um marcador",
        drawPolyButton: "Desenhar um polígono",
        drawLineButton: "Desenhar uma geometria (polilinha)",
        drawCircleButton: "Desenhar um círculo",
        drawRectButton: "Desenhar um retângulo",
        editButton: "Editar vertice(s) da geometria",
        dragButton: "Mover geometria",
        cutButton: "Recortar camada(s)",
        deleteButton: "Remover geometria",
        drawCircleMarkerButton: "Marcador de círculos de desenho",
        snappingButton: "Marcador arrastado para outras camadas e vértices",
        pinningButton: "Vértices compartilhados de pinos juntos"
      }
    };
    return customTranslation;
  }

  onMapClick(event:L.LeafletMouseEvent)
  {
    if(this.toolsService.getCurrentTool())
    {
      if(this.toolsService.getCurrentTool().enabled)
      {
        this.toolsService.getCurrentTool().execAction(event);
      }
    }
  }

  setCurrentToolCursor(currentTool)
  {
    if(currentTool && currentTool.cursorType)
    {
      this.currentCursor = currentTool.cursorType;
    }
    else
    {
      this.currentCursor = this.defaultCursor;
    }

  }

}
