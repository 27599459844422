import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Documentos } from '@funcate/sigweb-cti-api/model/documentos';
import { environment } from 'src/environments/environment';
import { DialogComponent } from '../map/dialog/dialog.component';
import { DocumentPreviewComponent } from '../map/dialog/document-preview-dialog/document-preview.component';
import { AuthenticationService } from './authentication.service';
import { BackendService } from './backend.service';
import { GeneralService } from './general.service';
import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root'
  })

export class DocumentService 
{ 
  

  constructor(private backendService: BackendService, private generalService: GeneralService,
     private authenticationService: AuthenticationService, private toastService: ToastService) 
  {
  }    

  public getDocumentos(tipo: number, featureId: string) : Promise<Documentos> 
  {
    return this.backendService.getDocumentos(tipo, featureId);
  };

  public getDocumentoById(id: string, extension: string) : Promise<Blob> 
  {
    return this.backendService.getDocumentoById(id, extension);
  };

  public uploadDocumento(nomeArquivo: string, extensaoArquivo: string, tipo: string, dataInsercao: string, featureId: string, auxData: string, arquivo: Blob) : Promise<any> 
  {
    return this.backendService.uploadDocumento(nomeArquivo, extensaoArquivo, tipo, dataInsercao, featureId, auxData, arquivo);
  };

  public getPreviewIcon(document: any)
  {
    let icon = "download"
    let tipo = (document.extensao_arquivo+"").toLowerCase();
    if(tipo=='jpg' || tipo=='png' || tipo=='pdf' || tipo=='tiff')
    {
      return "preview";
    }
  }
  public hasPreview(document: any)
  {
    let tipo = (document.extensao_arquivo+"").toLowerCase();
    if(tipo=='jpg' || tipo=='png' || tipo=='pdf' || tipo=='tiff')
    {
      return true;
    }
    return false;
  }

  public getDocument(id:string, name:string, extension:string, dialog: MatDialog, forceDownload: boolean=false)
  {
    let docid=id+"."+extension.trim().toLowerCase();
    let outputFilename=docid;
    if(name)
    {
      outputFilename=name+"."+extension.trim().toLowerCase();
    }
    let url = environment.BACKEND_API_BASE_PATH+'/cadastro/documentos/'+docid;
    if(forceDownload==false && (extension=="pdf" || extension=="jpg" || extension=="png" || extension=="tif"))
    {
      let dataType = this.generalService.getDataTypeByFileExtension(extension.toLowerCase());

      let dialogRef = dialog.open(DialogComponent, {
        height: '90%',
        width: '95%',
        data: { component: DocumentPreviewComponent, title: "Pré visualização de Documento", documentUrl: url, documentDataType: dataType}
      });
    }
    else
    {
      let user = this.authenticationService.getCurrentUser();
      if(!user)
      {
        this.toastService.error("Usuário não está autenticado corretamente. Efetue o login novamente.", "Problemas ao baixar documento.");
      }
      else
      {
        this.generalService.downloadFileGetMethod(url, outputFilename, user.token);
      }
      
    }
  }
  public deleteDocument(id: any) : Promise<any> 
  {
    return this.backendService.deleteDocumento(id);
  }

  public getDocumentType()
  {

  }


} 
