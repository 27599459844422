import { UserGroup } from "./user-group";

export class UserGroupPermissionConfig
{
    read: boolean;
    write: boolean;
    userGroup: UserGroup;

    constructor(userGroup: UserGroup, read: boolean, write: boolean)
    {
        this.read = read;
        this.write = write;
        this.userGroup = userGroup;
    }   
}
