<mat-accordion>    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="">
            <mat-expansion-panel-header>
                <mat-panel-title id="user-header" class="user-col">
                    <mat-icon class="material-icons">file_upload</mat-icon> Envio e Associação de Documento
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-card>
                <mat-card-header>
                    <mat-card-title> Documentos </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <form id="ficha-upload-tool" [formGroup]="fichaUploadControl" (ngSubmit)="onFormSubmit()">
                        <div class="inscricao-div">
                            <div class="inscricoes-list-div">
                                <mat-form-field>
                                    <input matInput
                                        disabled
                                        name="inscricoesCSV"
                                        placeholder="Inscrições:"
                                        [value]="inscricoesCSV">
                                </mat-form-field>
                                
                            </div>
                            <div class="ficha-upload">
                                <input type="file" name="file" (change)="onFileSelect($event)" />
                            </div>
                            <div class="filtro-botoes">  
                                <button type="submit" mat-raised-button color="primary">
                                    <span class="material-icons">search</span> Associar
                                </button>
                                &nbsp;
                            </div>
                        </div>
                    </form>
                </mat-card-content>
            </mat-card>
        </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
  
 