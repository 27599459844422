import { Component, Inject, Input, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Certidoescamadasconfig } from '@funcate/sigweb-cti-api';
import { Subscription } from 'rxjs';
import { LayersConfigService } from '../../../layers/layers.config.service';
import { BackendService } from '../../../../service/backend.service';
import { ToastrService } from 'ngx-toastr';
import { ToolsService } from '../../tools.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { GeneralService } from 'src/app/service/general.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../../../vo/notification';
import { Relatoriogeraldoimovelconfig } from '@funcate/sigweb-cti-api';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { CertidoesService } from 'src/app/service/certidoes.service';
import { Inscricao } from 'src/app/map/vo/inscricao';


@Component({
  selector: 'app-generate-relatorio-geral-imovel',
  templateUrl: './generate-relatorio-geral-imovel.component.html',
  styleUrls: ['./generate-relatorio-geral-imovel.component.css']
})
export class GenerateRelatorioGeralImovelComponent implements OnInit {

  tool:any;
  subscriptionTool:Subscription;
  @Input() ics: string;
  @Input() feature: any;
  @Input() featureSRID: string;
  ic: string;
  solicitante: string;
  notificationSubscrition: Subscription;
  downloading: boolean = false;
  map1Layers: LayerConfig[]=[];
  map2Layers: LayerConfig[]=[];
  map1LayerAppConfig: string = "relatorio_geral_imovel_mapa1";
  map2LayerAppConfig: string = "relatorio_geral_imovel_mapa2";
  map1Width: number=1200
  map1Height: number=600
  map2Width: number=1200
  map2Height: number=600

  constructor(private notificationService: NotificationService, private toastService: ToastrService,
   private toolsService: ToolsService, private authenticationService: AuthenticationService, 
   private generalService: GeneralService, private layerConfigService: LayersConfigService, private certidoesService: CertidoesService) {

    this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (tool:any) => {
        this.tool=tool;
      }
    );

    this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
      if (notification.event==AppNotification.ON_DOWNLOAD_FINISHED_EVENT)
      {
          console.log("Download from '"+notification.object.url+"' is done to out file '"+notification.object.outputFilename+"'.");
          this.downloading = false;
      } 
  });
    
   }

  ngOnInit(): void 
  {    
    if(this.ics.length==1)
    {
      this.ic=this.ics[0];
    }
  }

  generate()
  {
      if(this.ic)
      {
        let user = this.authenticationService.getCurrentUser();
        if(!user)
        {
          this.toastService.error("Usuário não está autenticado corretamente. Efetue o login novamente.", "Problemas ao gerar certidão.");
        }
        else
        {
          let certidaoConfig = this.createRelatorioGeralImovelConfig();

          if(certidaoConfig==null)
          {
            this.toastService.error("Não existe camadas configuradas pro relatório para exibição de mapa base e ortofo.")            
            return;
          }


          let filename = "relatorio-geral-imovel-"+this.ic+".pdf";
          let url = environment.BACKEND_API_BASE_PATH+'/relatorios/geral/imovel';
          this.downloading = true;
          this.generalService.downloadFilePostMethod(url, certidaoConfig, filename, user.token);
        }
        
      }
      else
      {
        this.toastService.error("Favor selecionar uma inscrição.")
      }
  }

  createRelatorioGeralImovelConfig() : Relatoriogeraldoimovelconfig
  {
    this.map1Layers = this.layerConfigService.getLayersWithAppConfig(this.map1LayerAppConfig);
    this.map2Layers = this.layerConfigService.getLayersWithAppConfig(this.map2LayerAppConfig);

    if(this.map1Layers.length>0 && this.map2Layers.length>0)
    {
      let map1URL = this.certidoesService.getMapURL(this.feature, this.map1Layers, this.map1Width, this.map1Height);
      let map2URL = this.certidoesService.getMapURL(this.feature, this.map2Layers, this.map2Width, this.map2Height);

      let certidaoUnificacaoConfig: Relatoriogeraldoimovelconfig = new Object();
      certidaoUnificacaoConfig['inscricao']=this.ic;
      certidaoUnificacaoConfig['url_mapa1']=map1URL;
      certidaoUnificacaoConfig['url_mapa2']=map2URL;


      return certidaoUnificacaoConfig;
    }
    console.log("Missing configuration on layers: " + this.map1LayerAppConfig + " - " + this.map2LayerAppConfig);
    return null;
  }
 
}


