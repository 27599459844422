import { Component, OnInit, OnDestroy } from '@angular/core';
import {ToolsService} from '../tools.service';
import { Subscription } from 'rxjs';
import { FeatureService } from '../../../service/feature.service';
import { LayersInfo } from '../../vo/layersinfo';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LayersConfigService } from '../../layers/layers.config.service';

@Component({
  selector: 'app-photo360-layer-tool',
  templateUrl: './photo360-layer-tool.component.html',
  styleUrls: ['./photo360-layer-tool.component.scss']
})
export class Photo360LayerToolComponent implements OnInit, OnDestroy {

  photo360LayerTool:any;
  subscriptionTool:Subscription;
  message: string;
  ctrlLoading:boolean;
  featureInfo: any;
  displayedColumns: string[] = ['key', 'value'];
  photo360FilePath: string;
  pannellumExternalUrl: SafeResourceUrl;
 
  constructor(private toolsService: ToolsService, private layersService: LayersConfigService,
    private featureService: FeatureService, public sanitizer: DomSanitizer) 
    {
    this.photo360FilePath="";
    this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (photo360LayerTool:any) => {
        this.photo360LayerTool=photo360LayerTool;
        this.update();
      },()=> {
        console.log("Falhou ao obter tool de photo 360");
      }
    );
  }

  update()
  {
    let currentLayerTool = this.layersService.getPhoto360Layer();

    if(this.photo360LayerTool && currentLayerTool)
    {
      this.photo360LayerTool.layersInfo=new LayersInfo();
      this.photo360LayerTool.map.eachLayer( (l:any)=>{
        if(l["wmsParams"]) 
        {
          if(l["id"]==currentLayerTool.getId())
          {
            this.featureService.getFeatureInfo(this.photo360LayerTool.ll, l, this.photo360LayerTool.map)
            .then(featureInfo => {
              if(featureInfo && featureInfo.features.length>0)
              {
                this.featureInfo = featureInfo;
                this.message="";
                this.loadPhoto360();
              }
              else
              {
                this.photo360FilePath=null;
              }
            }).catch(error => {
              this.message="Falha ao obter dados da camada de fotos 360.";
              console.log(error);
            });
          }
        }
      });
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptionTool.unsubscribe();
  }

  private loadPhoto360()
  {
    //http://pmsv.funcate.org.br/cti/v1/cadastro/photos360/get?filepath=dia_01_02_2021/Ladybug_Panoramic/IMG0018.jpg
    if(this.featureInfo && this.featureInfo.features.length>0)
    {
      this.featureInfo.features.forEach(feature => {
        
        let property = this.featureService.getPropertyByKey(feature.properties, "filepath");
        let id = this.featureService.getPropertyByKey(feature.properties, "id");
        if(property)
        {
          this.photo360FilePath=property.value.toString();
          let url=window.location.origin+"/assets/external/photo360-tools/pannellum/index.htm?autoLoad=true&panorama="
          url+=encodeURIComponent(environment.BACKEND_API_BASE_PATH+"/cadastro/photos360/get?filepath=" + this.photo360FilePath);
          url+="&imageId=" + id.value.toString();
          this.pannellumExternalUrl= this.sanitizer.bypassSecurityTrustResourceUrl(url);
        }
        
        
      });  
    }
  }
  
}
