<mat-card>
    <mat-card-header>
        <mat-card-title>Informações sobre Imóvel Irregular</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <table class="example-full-width edit-settlement-property-table" cellspacing="0" id="edit-settlement-property-table">
            <tr>
                <td colspan="5">
                    <mat-form-field class="example-full-width">
                        <mat-label>Inscrição:</mat-label>
                        <input matInput [(ngModel)]="settlementProperty.inscricao" class="property-value" [readonly]="readOnly">
                    </mat-form-field>           
                </td>
            </tr>
            <tr>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Núcleo:</mat-label>
                        <input matInput [(ngModel)]="settlementProperty.nucleo" class="property-value"  [readonly]="readOnly">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Quadra:</mat-label>
                        <input matInput [(ngModel)]="settlementProperty.quadra" class="property-value"  [readonly]="readOnly">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Logradouro:</mat-label>
                        <input matInput [(ngModel)]="settlementProperty.logradouro" class="property-value"  [readonly]="readOnly">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Lote:</mat-label>
                        <input matInput [(ngModel)]="settlementProperty.edif" class="property-value"  [readonly]="readOnly">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>SubLote:</mat-label>
                        <input matInput [(ngModel)]="settlementProperty.subedif" class="property-value"  [readonly]="readOnly">
                    </mat-form-field>
                </td>
            </tr>            
        </table>
    </mat-card-content>
</mat-card>

<mat-card>
    <mat-card-header>
        <mat-card-title>Endereço</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <table class="example-full-width edit-settlement-property-table" cellspacing="0" id="edit-settlement-property-address-table">
            <tr>
                <td colspan="2">
                    <mat-form-field class="example-full-width">
                        <mat-label>Logradouro:</mat-label>
                        <input matInput [(ngModel)]="settlementProperty.nome_logradouro" class="property-value" [readonly]="readOnly">
                    </mat-form-field>           
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Número Oficial:</mat-label>
                        <input matInput [(ngModel)]="settlementProperty.numero_oficial" class="property-value"  [readonly]="readOnly">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Número Cadastro:</mat-label>
                        <input matInput [(ngModel)]="settlementProperty.numero_cadastro" class="property-value"  [readonly]="readOnly">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Emplacamento Sabesp:</mat-label>
                        <input matInput [(ngModel)]="settlementProperty.emplacamento_sabesp" class="property-value"  [readonly]="readOnly">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Emplacamento CPFL:</mat-label>
                        <input matInput [(ngModel)]="settlementProperty.emplacamento_cpfl" class="property-value"  [readonly]="readOnly">
                    </mat-form-field>
                </td>
            </tr>
            <tr>                
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Complemento:</mat-label>
                        <input matInput [(ngModel)]="settlementProperty.complemento" class="property-value"  [readonly]="readOnly">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Bairro:</mat-label>
                        <input matInput [(ngModel)]="settlementProperty.bairro" class="property-value"  [readonly]="readOnly">
                    </mat-form-field>
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>CEP:</mat-label>
                        <input matInput [(ngModel)]="settlementProperty.cep" class="property-value"  [readonly]="readOnly">
                    </mat-form-field>
                </td>
            </tr>            
        </table>
    </mat-card-content>
</mat-card>

<mat-card>
    <mat-tab-group mat-align-tabs="start">
        <mat-tab label="Características">
            <mat-card>
                <mat-card-content>
                    <table class="example-full-width edit-settlement-property-table" cellspacing="0" id="edit-settlement-property-characteristics">
                        <tr>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Área Construida (m2) - Levantada:</mat-label>
                                    <input matInput [(ngModel)]="settlementProperty.area_construida_levantada" class="property-value" [readonly]="readOnly" [allowNegativeNumbers]="false" suffix=" m²" mask="separator.2" [thousandSeparator]="'.'" [decimalMarker]="','" >
                                </mat-form-field>           
                            </td>
                            <td>
                                <div class="areaDiv">
                                    <div class="flex1">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>Área Construida (m2) - Geo:</mat-label>
                                            <input matInput [(ngModel)]="settlementProperty.area_construida_geo" class="property-value"  [readonly]="readOnly" [allowNegativeNumbers]="false" suffix=" m²" mask="separator.2" [thousandSeparator]="'.'" [decimalMarker]="','" >
                                        </mat-form-field>
                                    </div>
                                    <div class="refresh-button-div" *ngIf="readOnly==false">
                                        <button *ngIf="calculatingArea==false" type="button" (click)="calculateArea(settlementProperty.id)" mat-raised-button color="basic" title="Recalcular Área Construida">
                                            <span class="material-icons">refresh</span> 
                                        </button>
                                        <button *ngIf="calculatingArea==true" disabled type="button" mat-raised-button color="basic" title="Recalculando Área Construida">
                                            <span class="material-icons">refresh</span> 
                                        </button>
                                    </div>
                                </div>

                                
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Uso do Imóvel:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="settlementProperty.uso_imovel"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsUsoImovel" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Ocupação:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="settlementProperty.ocupacao"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsOcupacao" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Acesso ao Lote:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="settlementProperty.acesso_lote"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsAcessoLote" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Acesso ao Domicílio:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="settlementProperty.acesso_domicilio"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsAcessoDomicilio" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Tipologia da Edificação:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="settlementProperty.tipologia_edificacao"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsTipologiaEdificacao" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Status da Construção:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="settlementProperty.status_construcao"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsStatusConstrucao" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Situação da Consolidação:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="settlementProperty.situacao_consolidacao"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsSituacaoConsolidacao" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Material Construtivo:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="settlementProperty.material_construtivo"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsMaterialConstrutivo" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Revestimento Externo:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="settlementProperty.revestimento_externo"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsRevestimentoExterno" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Material Cobertura:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="settlementProperty.material_cobertura"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsMaterialCobertura" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </td>                           
                        </tr>
                        <tr>         
                           <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Lote:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="settlementProperty.tipo_lote"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsTipoLote" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                           </td>
                           <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Total de Quartos:</mat-label>
                                    <input matInput [(ngModel)]="settlementProperty.total_quartos" class="property-value"  [readonly]="readOnly" type="number">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Quantidade de Pessoas por Quarto:</mat-label>
                                    <input matInput [(ngModel)]="settlementProperty.quantidade_pessoas_cada_quarto" class="property-value"  [readonly]="readOnly" type="number">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Total de Banheiros:</mat-label>
                                    <input matInput [(ngModel)]="settlementProperty.total_banheiros" class="property-value"  [readonly]="readOnly" type="number">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Cavalete de Água:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="settlementProperty.cavalete_agua"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsCavaleteAgua" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                           </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Poste Padrão de Energia:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="settlementProperty.poste_padrao_energia"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsPostePadraoEnergia" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Abastecimento de Água:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="settlementProperty.abastecimento_agua"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsAbastecimentoAgua" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Esgotamento Sanitário:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="settlementProperty.esgotamento_sanitario"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsEsgotamentoSanitario" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Energia Elétrica:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="settlementProperty.energia_eletrica"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsEnergiaEletrica" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-checkbox [(ngModel)]="settlementProperty.possui_tsu" [disabled]="readOnly">Há Cômodos Cedidos/Alugados para Outras Famílias</mat-checkbox>    
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3">
                                <mat-form-field class="example-full-width">
                                    <mat-label class="edit-settlement-property-checkbox-option-label">Patologias Aparentes:</mat-label>
                                    <div class="edit-settlement-property-checkbox-form-field-div">
                                        <input hidden=true matInput>
                                        
                                        <div *ngFor="let item of optionsPatologiasAparentes" class="edit-settlement-property-checkbox-option-div">
                                            <mat-checkbox 
                                            [checked]="isSelected(settlementProperty.getPatologiasAparentesAsString(), item.value)" 
                                            (change)="settlementProperty.setPatologiasAparentesAsString(item.value, $event.checked)" [disabled]="readOnly">{{item.value}}</mat-checkbox>    
                                        </div>                                
                                    </div>                                    
                                </mat-form-field>
                                
                            </td>
                            <td colspan="3">
                                <mat-form-field class="example-full-width" >
                                    <mat-label class="edit-settlement-property-checkbox-option-label">Usos Externos:</mat-label>
                                    <div class="edit-settlement-property-checkbox-form-field-div">
                                        <input hidden=true matInput>
                                        
                                        <div *ngFor="let item of optionsUsosExternos" class="edit-settlement-property-checkbox-option-div">
                                            <mat-checkbox 
                                            [checked]="isSelected(settlementProperty.getUsoExternoAsString(), item.value)" 
                                            (change)="settlementProperty.setUsoExternoAsString(item.value, $event.checked)" [disabled]="readOnly">{{item.value}}</mat-checkbox>    
                                        </div>    
                                    </div>
                                
                                </mat-form-field>                            
                            </td>
                        </tr>
                    </table>
                </mat-card-content>
            </mat-card>
        </mat-tab>
        <mat-tab label="Possuidor">
            <mat-card>
                <mat-card-content>
                    <table class="example-full-width edit-settlement-property-table" cellspacing="0" id="edit-settlement-property-possuidor" *ngIf="getCurrentPossuidorMember()!=null">
                        <tr>
                            <td colspan="4">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Nome:</mat-label>
                                    <input matInput [(ngModel)]="getCurrentPossuidorMember().nome" class="property-value" [readonly]="readOnly">
                                </mat-form-field>           
                            </td>                            
                        </tr>
                        <tr>
                            <td colspan="2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>CPF/CNPJ:</mat-label>
                                    <input matInput [(ngModel)]="getCurrentPossuidorMember().cpf" class="property-value" [readonly]="readOnly" mask="000.000.000-00||00.000.000/0000-00">
                                </mat-form-field>           
                            </td>  
                            <td colspan="2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>RG:</mat-label>
                                    <input matInput [(ngModel)]="getCurrentPossuidorMember().rg" class="property-value" [readonly]="readOnly">
                                </mat-form-field>           
                            </td>                            
                        </tr>
                        <tr>
                            <td colspan="1">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Telefone:</mat-label>
                                    <input matInput [(ngModel)]="getCurrentPossuidorMember().telefone" class="property-value" [readonly]="readOnly" [readonly]="readOnly" mask="(00) 0000-0000">
                                </mat-form-field>           
                            </td>  
                            <td colspan="1">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Celular:</mat-label>
                                    <input matInput [(ngModel)]="getCurrentPossuidorMember().celular" class="property-value" [readonly]="readOnly" [readonly]="readOnly" mask="(00) 00000-0000">
                                </mat-form-field>           
                            </td>
                            <td colspan="2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>E-Mail:</mat-label>
                                    <input matInput [(ngModel)]="getCurrentPossuidorMember().email" class="property-value" [readonly]="readOnly" >
                                </mat-form-field>           
                            </td>        
                        </tr>
                    </table>
                    <div *ngIf="getCurrentPossuidorMember()==null && readOnly == false" class="nenhum-possuidor-div">Adicione um membro Possuidor ao cadastro social.</div>
                    <div *ngIf="getCurrentPossuidorMember()==null && readOnly == true" class="nenhum-possuidor-div">Nenhum membro Possuidor no cadastro social.</div>
                </mat-card-content>
            </mat-card>
        </mat-tab>
        <mat-tab label="Cadastro Social">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Membros</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="cadastroSocialDataSource" class="mat-elevation-z8 example-full-width">
            
                        <ng-container matColumnDef="nome">
                            <th mat-header-cell *matHeaderCellDef> Nome </th>
                            <td mat-cell *matCellDef="let item"> {{item.nome}} </td>
                        </ng-container>
            
                        <ng-container matColumnDef="cpf">
                            <th mat-header-cell *matHeaderCellDef> CPF </th>
                            <td mat-cell *matCellDef="let item"> {{item.cpf}} </td>
                        </ng-container>
            

                        <ng-container matColumnDef="rg">
                            <th mat-header-cell *matHeaderCellDef> RG </th>
                            <td mat-cell *matCellDef="let item"> {{item.rg}} </td>
                        </ng-container>


                        <ng-container matColumnDef="tipoMembro">
                            <th mat-header-cell *matHeaderCellDef> Tipo </th>
                            <td mat-cell *matCellDef="let item"> {{item.getTipoMembro()}} </td>
                        </ng-container>

                        <!-- Symbol Column -->
                        <ng-container matColumnDef="acoes">
                            <th  mat-header-cell *matHeaderCellDef="let item"> Ações 
                                <mat-icon *ngIf="hasEditionPermition() && readOnly==false" title="Inserir membro ao cadastro social" (click)="newCadastroSocial()" class="add-cadastro-social-icon">add</mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let item" > 
                                <mat-icon *ngIf="readOnly==false"  (click)="editCadastroSocial(item)" title="Editar Cadastro Social" class="btn-icon edit-cadastro-social-icon" aria-hidden="false" aria-label="Editar Cadastro Social">edit</mat-icon> 
                                <mat-icon *ngIf="readOnly==true"  (click)="editCadastroSocial(item)" title="Consultar Cadastro Social" class="btn-icon edit-cadastro-social-icon" aria-hidden="false" aria-label="Editar Cadastro Social">preview</mat-icon> 
                                <mat-icon *ngIf="hasEditionPermition() && readOnly==false" (click)="confirmDeleteCadastroSocial(item)" title="Apagar Cadastro Social" class="btn-icon apagar-cadastro-social" aria-hidden="false" aria-label="Apagar Cadastro Social">indeterminate_check_box</mat-icon> 
                            </td>
                        </ng-container>
                        
                        <tr mat-header-row *matHeaderRowDef="cadastroSocialDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: cadastroSocialDisplayedColumns;"></tr>
                        </table>
                </mat-card-content>
            </mat-card>
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Documentação Titular</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="documentosTitular" class="mat-elevation-z8 example-full-width">
            
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> Id </th>
                            <td mat-cell *matCellDef="let item"> {{item.id}} </td>
                        </ng-container>

                        <ng-container matColumnDef="titular_tipo_documento">
                            <th mat-header-cell *matHeaderCellDef> Tipo de Documento </th>
                            <td mat-cell *matCellDef="let item"> {{getAuxAttribute(item, 'titular-tipo-documento')}} </td>
                        </ng-container>

                        <ng-container matColumnDef="nome">
                            <th mat-header-cell *matHeaderCellDef> Nome </th>
                            <td mat-cell *matCellDef="let item"> {{item.nome_arquivo}} </td>
                        </ng-container>
            
                        <ng-container matColumnDef="dataInsercao">
                            <th mat-header-cell *matHeaderCellDef> Data de Inserção </th>
                            <td mat-cell *matCellDef="let item"> {{formatDate(item.data_insercao,'dd/MM/yyyy')}} </td>
                        </ng-container>
            
                        <!-- Weight Column -->
                        <ng-container matColumnDef="tipo">
                            <th mat-header-cell *matHeaderCellDef> Tipo Arquivo </th>
                            <td mat-cell *matCellDef="let item"> {{item.extensao_arquivo}} </td>
                        </ng-container>
                        
                        <!-- Symbol Column -->
                        <ng-container matColumnDef="acoes">
                            <th  mat-header-cell *matHeaderCellDef="let item"> Ações 
                                <mat-icon *ngIf="hasEditionPermition() && readOnly==false" title="Inserir e associar novo documento " (click)="newDocumentUpload(settlementPropertyCadSocialTitularDocumentType,'Enviar Documento de Assentamento')" class="addDocumentoUpload">file_upload</mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let item" > 
                                <mat-icon *ngIf="hasDocumentPreview(item)" (click)="previewDocument(item.id, item.extensao_arquivo)" title="Visualizar Documento" class="btn-icon download-documento" aria-hidden="false" aria-label="Visualizar Documento">preview</mat-icon> 
                                <mat-icon (click)="downloadDocument(item.id, item.nome_arquivo, item.extensao_arquivo)" title="Visualizar Documento" class="btn-icon download-documento" aria-hidden="false" aria-label="Visualizar Documento">download</mat-icon> 
                                <mat-icon *ngIf="hasEditionPermition() && readOnly==false" (click)="confirmDeleteDocument(item.id)" title="Apagar Documento" class="btn-icon apagar-documento" aria-hidden="false" aria-label="Apagar Documento">indeterminate_check_box</mat-icon> 
                            </td>
                        </ng-container>
                        
                        <tr mat-header-row *matHeaderRowDef="documentosTitularDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: documentosTitularDisplayedColumns;"></tr>
                        </table>
                </mat-card-content>
            </mat-card>
            <mat-card *ngIf="hasSEHABReadPermition()">
                <mat-card-header>
                    <mat-card-title>Documentação SEHAB</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="documentosSEHAB" class="mat-elevation-z8 example-full-width">
            
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> Id </th>
                            <td mat-cell *matCellDef="let item"> {{item.id}} </td>
                        </ng-container>

                        <ng-container matColumnDef="sehab_tipo_documento">
                            <th mat-header-cell *matHeaderCellDef> Tipo de Documento </th>
                            <td mat-cell *matCellDef="let item"> {{getAuxAttribute(item, 'sehab-tipo-documento')}} </td>
                        </ng-container>

                        <ng-container matColumnDef="sehab_data_emissao">
                            <th mat-header-cell *matHeaderCellDef> Data de Emissão </th>
                            <td mat-cell *matCellDef="let item"> {{  getDateAuxAttribute(item, 'sehab-data-emissao', 'dd/MM/yyyy')}} </td>
                        </ng-container>

                        <ng-container matColumnDef="sehab_tecnico_responsavel">
                            <th mat-header-cell *matHeaderCellDef> Técnico Responsável </th>
                            <td mat-cell *matCellDef="let item"> {{getAuxAttribute(item, 'sehab-tecnico-responsavel')}} </td>
                        </ng-container>

            
                        <ng-container matColumnDef="nome">
                            <th mat-header-cell *matHeaderCellDef> Nome </th>
                            <td mat-cell *matCellDef="let item"> {{item.nome_arquivo}} </td>
                        </ng-container>
            
                        <ng-container matColumnDef="dataInsercao">
                            <th mat-header-cell *matHeaderCellDef> Data de Inserção </th>
                            <td mat-cell *matCellDef="let item"> {{formatDate(item.data_insercao,'dd/MM/yyyy')}} </td>
                        </ng-container>
            
                        <!-- Weight Column -->
                        <ng-container matColumnDef="tipo">
                            <th mat-header-cell *matHeaderCellDef> Tipo Arquivo </th>
                            <td mat-cell *matCellDef="let item"> {{item.extensao_arquivo}} </td>
                        </ng-container>
                        
                        <!-- Symbol Column -->
                        <ng-container matColumnDef="acoes">
                            <th  mat-header-cell *matHeaderCellDef="let item"> Ações 
                                <mat-icon *ngIf="hasEditionPermition() && readOnly==false" title="Inserir e associar novo documento " (click)="newDocumentUpload(settlementPropertyCadSocialSehabDocumentType,'Enviar Documento de Assentamento')" class="addDocumentoUpload">file_upload</mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let item" > 
                                <mat-icon *ngIf="hasDocumentPreview(item)" (click)="previewDocument(item.id, item.extensao_arquivo)" title="Visualizar Documento" class="btn-icon download-documento" aria-hidden="false" aria-label="Visualizar Documento">preview</mat-icon> 
                                <mat-icon (click)="downloadDocument(item.id, item.nome_arquivo, item.extensao_arquivo)" title="Visualizar Documento" class="btn-icon download-documento" aria-hidden="false" aria-label="Visualizar Documento">download</mat-icon> 
                                <mat-icon *ngIf="hasEditionPermition() && readOnly==false" (click)="confirmDeleteDocument(item.id)" title="Apagar Documento" class="btn-icon apagar-documento" aria-hidden="false" aria-label="Apagar Documento">indeterminate_check_box</mat-icon> 
                            </td>
                        </ng-container>
                        
                        <tr mat-header-row *matHeaderRowDef="documentosSEHABDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: documentosSEHABDisplayedColumns;"></tr>
                        </table>
                </mat-card-content>
            </mat-card>            
        </mat-tab>
        <mat-tab label="TSU">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Informações</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table class="example-full-width edit-settlement-property-table" cellspacing="0" id="edit-settlement-property-tsu">
                        <tr>
                            <td  colspan="2">
                                <mat-checkbox [(ngModel)]="settlementProperty.possui_tsu">Possui TSU</mat-checkbox>    
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Possuidor (Que consta no carnê):</mat-label>
                                    <input matInput [(ngModel)]="settlementProperty.tsu_possuidor_carne" class="property-value"  [readonly]="readOnly" [disabled]="settlementProperty.possui_tsu==false">
                                </mat-form-field>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Inscrição TSU:</mat-label>
                                    <input matInput [(ngModel)]="settlementProperty.tsu_inscricao" class="property-value"  [readonly]="readOnly" [disabled]="settlementProperty.possui_tsu==false">
                                </mat-form-field>        
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>CPF do Possuidor:</mat-label>
                                    <input matInput [(ngModel)]="settlementProperty.tsu_cpf_possuidor" class="property-value"  [readonly]="readOnly" [disabled]="settlementProperty.possui_tsu==false">
                                </mat-form-field>
                            </td>
                        </tr>                        
                    </table>                    
                </mat-card-content>
            </mat-card>
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Endereço de Entrega</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table class="example-full-width edit-settlement-property-table" cellspacing="0" id="edit-settlement-property-endereco-entrega">
                        <tr>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Logradouro:</mat-label>
                                    <input matInput [(ngModel)]="settlementProperty.tsu_logradouro" class="property-value"  [readonly]="readOnly">
                                </mat-form-field>      
                            </td>
                            <td >
                                <mat-form-field class="example-full-width">
                                    <mat-label>Número:</mat-label>
                                    <input matInput [(ngModel)]="settlementProperty.tsu_numero" class="property-value"  [readonly]="readOnly">
                                </mat-form-field>
                            </td>
                            <td >
                                <mat-form-field class="example-full-width">
                                    <mat-label>Complemento:</mat-label>
                                    <input matInput [(ngModel)]="settlementProperty.tsu_complemento" class="property-value"  [readonly]="readOnly">
                                </mat-form-field>        
                            </td>
                        </tr>
                        <tr>
                            <td >
                                <mat-form-field class="example-full-width">
                                    <mat-label>Bairro:</mat-label>
                                    <input matInput [(ngModel)]="settlementProperty.tsu_bairro" class="property-value"  [readonly]="readOnly">
                                </mat-form-field>        
                            </td>
                            <td >
                                <mat-form-field class="example-full-width">
                                    <mat-label>Cidade:</mat-label>
                                    <input matInput [(ngModel)]="settlementProperty.tsu_cidade" class="property-value"  [readonly]="readOnly">
                                </mat-form-field>        
                            </td>
                            <td >
                                <mat-form-field class="example-full-width">
                                    <mat-label>CEP:</mat-label>
                                    <input matInput [(ngModel)]="settlementProperty.tsu_cep" class="property-value"  [readonly]="readOnly">
                                </mat-form-field>        
                            </td>
                        </tr>
                        <tr>
                            
                        </tr>
                    </table>                    
                </mat-card-content>
            </mat-card>

        </mat-tab>   
        <mat-tab label="Foto Fachada">
            <mat-card>
                <mat-card-content >
                    <mat-grid-list cols="5" rowHeight="200px" *ngIf="isInscricaoValid()==true && albumFotosFachada.length>0">
                        <mat-grid-tile *ngFor="let albumFotofachada of albumFotosFachada; let i=index">
                            <img [src]="albumFotofachada.src" (click)="openFotoFachada(i)">
                        </mat-grid-tile>
                    </mat-grid-list>
                    <div class="fotos-fachada-message" *ngIf="isInscricaoValid()==false">Imóvel não tem inscrição. Para o uso de fotos de fachada é necessário que o imóvel irregular tenha uma inscrição irregular associada.</div>
               
                    <div class="fotos-fachada-message" *ngIf="isInscricaoValid()==true && albumFotosFachada.length==0">Não existem fotos de fachada associadas.</div>
                    <div class="fotos-manager-button-div">
                        <button type="button" title="Gerenciar fotos de fachadas para as incrições selecionadas." *ngIf="showFotosFachadaManager()" (click)="gerenciarFotosFachada()" mat-raised-button color="primary">
                            <span class="material-icons">image</span> Gerenciar Fotos de Fachada
                        </button>
                    </div>
                    
                </mat-card-content>

            </mat-card>
        </mat-tab>      
        <mat-tab label="Documentação">
            <mat-card>

                <mat-card-header>
                    <mat-card-title>Documentação Imóvel</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="documentosImovel" class="mat-elevation-z8 example-full-width">
            
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> Id </th>
                            <td mat-cell *matCellDef="let item"> {{item.id}} </td>
                        </ng-container>

                        <ng-container matColumnDef="nome">
                            <th mat-header-cell *matHeaderCellDef> Nome </th>
                            <td mat-cell *matCellDef="let item"> {{item.nome_arquivo}} </td>
                        </ng-container>
            
                        <ng-container matColumnDef="dataInsercao">
                            <th mat-header-cell *matHeaderCellDef> Data de Inserção </th>
                            <td mat-cell *matCellDef="let item"> {{formatDate(item.data_insercao,'dd/MM/yyyy')}} </td>
                        </ng-container>
            
                        <!-- Weight Column -->
                        <ng-container matColumnDef="tipo">
                            <th mat-header-cell *matHeaderCellDef> Tipo Arquivo </th>
                            <td mat-cell *matCellDef="let item"> {{item.extensao_arquivo}} </td>
                        </ng-container>
                        
                        <!-- Symbol Column -->
                        <ng-container matColumnDef="acoes">
                            <th  mat-header-cell *matHeaderCellDef="let item"> Ações 
                                <mat-icon *ngIf="hasEditionPermition() && readOnly==false" title="Inserir e associar novo documento " (click)="newDocumentUpload(settlementPropertyDocumentType,'Enviar Documento do Imóvel Irregular')" class="addDocumentoUpload">file_upload</mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let item" > 
                                <mat-icon *ngIf="hasDocumentPreview(item)" (click)="previewDocument(item.id, item.extensao_arquivo)" title="Visualizar Documento" class="btn-icon download-documento" aria-hidden="false" aria-label="Visualizar Documento">preview</mat-icon> 
                                <mat-icon (click)="downloadDocument(item.id, item.nome_arquivo, item.extensao_arquivo)" title="Visualizar Documento" class="btn-icon download-documento" aria-hidden="false" aria-label="Visualizar Documento">download</mat-icon> 
                                <mat-icon *ngIf="hasEditionPermition() && readOnly==false" (click)="confirmDeleteDocument(item.id)" title="Apagar Documento" class="btn-icon apagar-documento" aria-hidden="false" aria-label="Apagar Documento">indeterminate_check_box</mat-icon> 
                            </td>
                        </ng-container>
                        
                        <tr mat-header-row *matHeaderRowDef="documentosImovelDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: documentosImovelDisplayedColumns;"></tr>
                        </table>
                </mat-card-content>
            </mat-card>
        </mat-tab>      
        <mat-tab label="Histórico de Processo">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Histórico de Processo</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table mat-table [dataSource]="historicoProcesso" class="mat-elevation-z8 example-full-width">

                        <ng-container matColumnDef="processo">
                            <th mat-header-cell *matHeaderCellDef> Processo </th>
                            <td mat-cell *matCellDef="let item"> {{item.processo}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="observacoes">
                            <th mat-header-cell *matHeaderCellDef> Observações </th>
                            <td mat-cell *matCellDef="let item"> {{item.observacoes}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="assunto">
                            <th mat-header-cell *matHeaderCellDef> Assunto </th>
                            <td mat-cell *matCellDef="let item"> {{item.assunto}} </td>
                        </ng-container>
    
                        <ng-container matColumnDef="acoes">
                            <th mat-header-cell *matHeaderCellDef="let item"> <span *ngIf="hasEditionPermition()">Ações</span>  
                                <mat-icon *ngIf="hasEditionPermition()" title="Inserir histórico" (click)="addHistorico(logradouro,inscricao)" class="add-historico">add_box</mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let item" > 
                                <mat-icon *ngIf="hasEditionPermition()" (click)="editHistorico(item)" title="Editar Histórico" class="btn-icon edit-historico" aria-hidden="false" aria-label="Editar Histórico">edit</mat-icon> 
                                <mat-icon *ngIf="hasEditionPermition()" (click)="confirmDeleteHistorico(item)" title="Apagar Histórico" class="btn-icon delete-historico" aria-hidden="false" aria-label="Apagar Histórico">delete</mat-icon> 
                            </td>
                        </ng-container>
                        
                        <tr mat-header-row *matHeaderRowDef="historicoProcessoDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: historicoProcessoDisplayedColumns;"></tr>
                        </table>
                </mat-card-content>
            </mat-card>
        </mat-tab>      
    </mat-tab-group>
</mat-card>
<mat-card *ngIf="hasEditionPermition() && readOnly==false">
    <mat-card-content>
        <div class="align-row buttons-div">

            <button type="button" (click)="close()" mat-raised-button color="primary" title="Fechar">
              <span class="material-icons">close</span> Fechar
            </button>    
            <button type="button" (click)="confirmSave()" mat-raised-button color="primary" title="Salvar as Informações">
              <span class="material-icons">save</span> Salvar
            </button>           
        
        </div> 
    </mat-card-content>
</mat-card>