import { MatDialog } from '@angular/material/dialog';
import { LayerConfig } from '../layers/layers.config';
import { Tool } from './tool';

export abstract class AbstractLayerTool extends Tool 
{
    public abstract isVisibleForLayer(layer :LayerConfig) : boolean;

    public abstract isEnabled(layer :LayerConfig) : boolean;

    
}
