import {ToolsService} from '../tools.service';
import { LayersInfo } from '../../vo/layersinfo';
import { MatDialog } from '@angular/material/dialog';
import { FetureInfoToolComponent } from './fetureinfo-tool.component';
import { DialogComponent } from '../../dialog/dialog.component';
import { Tool } from '../tool';

export class FeatureInfoTool extends Tool {

    private dialog: MatDialog;

    cursorType='tool-cursor-pointer';
    id='featureinfo-tool';
    enabled=false;
    name='Get Feature Info';
    title='Obter informações por apontamento';
    type='click';
    map:L.Map=null;
    ll: any; 
    protected visible:boolean=true;
    icon='info';
    layersInfo: LayersInfo;
    layerTool: boolean=false;
    customParameter=new Map<String, any>();

    constructor(toolService: ToolsService, dialog: MatDialog) 
    { 
        super(toolService)
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{
        this.map=event.sourceTarget;
        this.ll=event.latlng;

        let dialogRef = this.openFeatureInfo();

        let whenDialogIsOpen=dialogRef.afterOpened().toPromise();
        whenDialogIsOpen.then(
            ()=>{
                this.toolsService.dispatchTool(this);
            }
        );
    };

    public openFeatureInfo(infoIntegrationObject: any=null)
    {
        let dialogRef = this.dialog.open(DialogComponent, {
            height: '90%',
            width: '95%',
            data: { component: FetureInfoToolComponent, title: "Informações das Camadas", infoIntegrationObject: infoIntegrationObject }            
        });
        return dialogRef;
    }

    public enableImpl()
    {
    }
    public disableImpl()
    {
    }
}
