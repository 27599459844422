import { ToolsService } from '../tools.service';
import { LayersInfo } from '../../vo/layersinfo';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PropertyRegistrationComponent } from './property-registration.component'
import { DialogComponent } from '../../dialog/dialog.component';
import { LayersConfigService } from '../../layers/layers.config.service';
import { Tool } from '../tool';
import { LayerConfig } from '../../layers/layers.config';
import { FeatureService } from 'src/app/service/feature.service';
import { Property } from '../../vo/property';
import { ToastService } from 'src/app/service/toast.service';
import { Inscricao } from '../../vo/inscricao';

export class PropertyRegistrationTool extends Tool {

    private dialog: MatDialog;

    cursorType='tool-cursor-pointer';
    id='property-registration-tool';
    enabled=false;
    name='Get property registration info';
    title='Obter informações do cadastro imobiliário';
    type='click';
    map:L.Map=null;
    icon='assignment';
    ll: any;  
    protected visible:boolean=true;
    layersInfo: LayersInfo;
    dialogRef: MatDialogRef<DialogComponent>;
    layerTool: boolean=false;
    customParameter=new Map<String, any>();

    constructor(toolsService: ToolsService,
        private layerConfigService: LayersConfigService,
        private featureService: FeatureService,
        private toastService: ToastService,
         dialog: MatDialog) 
    { 
        super(toolsService)
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{
        this.map=event.sourceTarget;
        this.ll=event.latlng;

      this.loadImovel();

    };

    public displayDialog(ic: Inscricao, featureId: Property)
    {
        this.dialogRef = this.dialog.open(DialogComponent, {
            height: '90%',
            width: '95%',
            data: { component: PropertyRegistrationComponent, title: "Informações da inscrição imobiliária", ic: ic, featureId: featureId, parent: this }
        });
    }

    public enableImpl()
    {
        let layerConfig = this.layerConfigService.getCtiLayer();
        if(layerConfig)
        {
            this.layerConfigService.enableLayer(layerConfig);
        }            
    }
    public disableImpl()
    {
        
    }
    private loadImovel() {
          let currentLayer: LayerConfig = this.layerConfigService.getCtiLayer();
    
          if(currentLayer)
          {
            this.map.eachLayer( (l:any)=>{
              // TODO: Filter only the wanted layer before call GetFeatureInfo
              if(l["wmsParams"] && l["wmsParams"].layers==currentLayer.getId())
              {
                this.featureService.getFeatureInfo(this.ll, l, this.map).then(
                  featureInfo => {
                    if(featureInfo && featureInfo.features.length>0 && featureInfo.features[0] && featureInfo.features[0].properties)
                    {

                      //Getting IC and Id attribute config
                      let inscricaoAttribute = this.layerConfigService.getLayerInscricaoAttribute(currentLayer);
                      let idAttribute = this.layerConfigService.getLayerIdAttribute(currentLayer);                  

                      //Getting properties from FeatureInfo
                      let featureIdProperty = this.featureService.getPropertyByKey(featureInfo.features[0].properties, idAttribute);
                      let icProperty = this.featureService.getPropertyByKey(featureInfo.features[0].properties, inscricaoAttribute);


                      if(icProperty.value && icProperty.value!="")
                      {
                        let inscricao=icProperty.value.trim();
      
                        this.toastService.sucess("Imóvel Encontrado!", "");

                        
                        this.displayDialog(new Inscricao(icProperty.value.replace('\n','')), featureIdProperty);
                      }
                      else if(featureIdProperty)
                      {      
                        this.toastService.warning("Não foi encontrada uma inscrição imobiliária para este imóvel.", "Atenção");
                       // let ctrlAssociate=true;

                       this.displayDialog(null, featureIdProperty);
                      }
                      else
                      {
                        console.log("Ops, temos um problema com o retorno da consulta ao serviço de informações do mapa!");
                      }
                    }else                    
                    {
                      this.toastService.error("Não foi encontrado um imóvel neste local.", "Erro");
                    }
      
                  }, error => {
                      console.log(error);
                      this.toastService.error("Ops, algo não ocorreu como o esperado com o serviço de informações do mapa.", "Erro");
                  }
                );
              }
            });
          }                
      }
      
    public closePropertyRegistration()
    {
      if(this.dialogRef)
      {
        this.dialogRef.close();
      }      
    }

    public reloadPropertyRegistration()
    {
      this.closePropertyRegistration();
      this.loadImovel();
    }

}
