import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { Featureattributes, Logradouros, LogradourosInner } from '@funcate/sigweb-cti-api';
import { map, startWith } from 'rxjs/operators';
import { FeatureService } from 'src/app/service/feature.service';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';
import { Property } from 'src/app/map/vo/property';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { EditionService } from 'src/app/service/edition.service';
import TileSource from 'ol/source/Tile';
import { Feature } from 'src/app/map/vo/feature';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-edit-street-attributes',
  templateUrl: './edit-street-attributes.component.html',
  styleUrls: ['./edit-street-attributes.component.css']
})
export class EditStreetAttributesComponent implements OnInit {


  private layer: LayerConfig;
  public attributes: Array<Property>=[];
  private nomeLogradouroAttributeConfig: string = "nome_logradouro_attribute";
  private codLogradouroAttributeConfig: string = "codigo_logradouro_attribute";
  private numeroLeiLogradouroAttributeConfig: string = "numero_lei_logradouro_attribute";
  private codLogradouro: string;
  private parent: any;
  displayedColumns: string[] = ['key', 'value'];
  private codLogradouroAttributeName: string;
  private nomeAttributeName: string;
  private numeroLeiAttributeName: string;
  private logradouroFeatures : Array<Feature> = []; 
  @ViewChildren('attributesElements') attributesElements;
   

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private layerConfigService: LayersConfigService, private canvasService: CanvasService, 
  private featureService: FeatureService, private editionService: EditionService, private toastService: ToastService) 
  { 
    this.layer = data.layer;
    this.codLogradouro = data.codLogradouro;
    this.parent = data.parent;

    this.codLogradouroAttributeName = this.layerConfigService.getLayerAppConfig(this.layer, this.codLogradouroAttributeConfig);
    this.nomeAttributeName = this.layerConfigService.getLayerAppConfig(this.layer, this.nomeLogradouroAttributeConfig);   
    this.numeroLeiAttributeName = this.layerConfigService.getLayerAppConfig(this.layer, this.numeroLeiLogradouroAttributeConfig);  
  }

  ngOnInit(): void 
  {
    this.loadStreetAttributes();
  }

  public loadFirstFeatureProperties(featureattributes: Array<Property>)
  {

    let properties :Array<Property>=[];
    featureattributes.forEach(featureattribute=> {
      if(featureattribute.key==this.codLogradouroAttributeName
        ||featureattribute.key==this.nomeAttributeName
        ||featureattribute.key==this.numeroLeiAttributeName)
      {
        if(featureattribute.value==null)
        {
          featureattribute.value="";
        }

        let property = new Property(featureattribute.key.toString(), featureattribute.value.toString());
        properties.push(property);
      }      
    });
    
    this.attributes=properties;
  }

  private loadStreetAttributes() 
  {      
    let attributesFilterList: Array<Array<Property>>=[];
    let attributeFilterList: Array<Property>=[];

    attributeFilterList.push(new Property(this.codLogradouroAttributeName, this.codLogradouro));  
    attributesFilterList.push(attributeFilterList);

    this.featureService.getFeatures(this.layer, "" , attributesFilterList).then(features => 
      {
    
        if(features && features.length>0)
        {
          let isFirstFeature=true;
          
         features.forEach(feature => {
          if(feature.properties)
          {
            let featureObject = new Feature();
            
            let properties : Array<Property> = this.featureService.getWFSPropertiesObjectAsPropertyArray(feature.properties);

            featureObject.properties=properties;

            this.logradouroFeatures.push(featureObject);
            
            if(isFirstFeature)
            {
              this.loadFirstFeatureProperties(properties);
              isFirstFeature = false;
            }              
        }
       });
      }
      
          
      });
  }

  public save()
  {
    let inputs = this.attributesElements.toArray();

    let featuresLength=this.logradouroFeatures.length;
    let featuresSaved=0;
    let featuresProcessed=0;

    //Atualizando as features de todos os logradouros encontrados com o mesmo codigo e nome
    this.logradouroFeatures.forEach((feature: Feature) => {


      inputs.forEach(input => {
         feature.properties.forEach((property: Property)=> {
          if(input.nativeElement.id==property.key)
          {
            property.value=input.nativeElement.value;
          }
        });      
      });      

      this.editionService.updateAttributes(feature.properties, this.layer).then(featureInfo => {
        featuresSaved++;
        
        console.log('Saved Street Feature Attributes: ' + featuresSaved + '/' + featuresLength);
      }).finally(()=>
        {
          //Verificar se todos os elementos já foram processados.
          featuresProcessed++;
          if(featuresProcessed==featuresLength)
          {
            //Verificar quantos elementos foram salvos.
            if(featuresLength==featuresSaved)
            {
              this.toastService.sucess("Um total de "+featuresLength+" de trechos de logradouros foram atualizados.", "Atributos salvos com sucesso.");

              this.close();
            }
            else
            {
              this.toastService.warning("Apenas "+featuresSaved+" trechos de um total de "+featuresLength+" dos logradouros foram atualizados.", "Atributos salvos com sucesso.");
            }
          }
        }
      );
    });    

  }

  public close()
  {
    if(this.parent)
    {
      this.parent.closeEditStreet();
    }
  }




}
