export abstract class Step
 {
    private index: number;
    private title: string;
    private description: string;

    protected constructor(index: number)
    {
        this.index=index;
    }
    abstract execute();

    abstract rollback();

    abstract callback(object: any);

    abstract finish();

    public getIndex() : number
    {
        return this.index;
    }

    public setTitle(title: string)
    {
        this.title = title;
    }

    public setDescription(desc: string)
    {
        this.description = desc;
    }

    public getTitle()
    {
        return this.title;
    }

    public getDescription()
    {
        return this.description;
    }

    abstract valid()
    
}
