import LineString from 'ol/geom/LineString';
import { AppNotification } from 'src/app/map/vo/notification';
import { GeometryService } from 'src/app/service/geometry.service';
import { NotificationService } from 'src/app/service/notification.service';
import { CustomEditionController } from '../custom-edition-controller';
import {CaptureCoordinateInteraction} from './capture-coord-interaction'

export class FrontSelectionInteraction extends CaptureCoordinateInteraction
{
    static id: string = "front-selection-tool";
    private currentFeature: any;
	private selectedSegment: LineString;

    constructor(private editionController: CustomEditionController, private notificationService: NotificationService, feature: any)
    {
        super(FrontSelectionInteraction.id);
        this.setup({handlePointerUp: this.frontSelectionToolCallback})
        this.currentFeature=feature;
    }

    public frontSelectionToolCallback(event: any)
	{
        if(this.isDragging() == true)
        {
            return true;
        }
        
        var coordAccepted = this.handleSelectFront(event.coordinate);		
        return coordAccepted;	
        
	}


    private getIntersectionSegment(outerLinearRing: any, olCoordinate: any)
    {


        var index = GeometryService.findIntersectionSegment(olCoordinate, outerLinearRing);

		return index;

    }

    private handleSelectFront(olCoordinate)
	{

        let polygon = this.currentFeature.getGeometry();
		let outerLinearRing = polygon.getLinearRing(0);
        
		var segmentIndex = this.getIntersectionSegment(outerLinearRing, olCoordinate);

		if(segmentIndex == -1)
		{
			console.log("Você deve clicar na borda do elemento que gostaria que ficasse virado para a frente.");
			return false;
		}
		
		//this.m_splitStartSegmentIndex = segmentIndex;
		
		var olSegmentStartCoord = GeometryService.getSegmentStartCoord(outerLinearRing, segmentIndex);
		var olSegmentEndCoord = GeometryService.getSegmentEndCoord(outerLinearRing, segmentIndex);
		
		var angleInRadians = GeometryService.getAngleInRadians(olSegmentStartCoord, olSegmentEndCoord);
		
		this.getMap().getView().setRotation(angleInRadians);
				
		console.log('View Rotation changed: ' + (angleInRadians * GeometryService.CRD));

		this.selectedSegment = new LineString([olSegmentStartCoord, olSegmentEndCoord]);

		//we add the feature to the layer that will highlist the selected segment
		// var segmentFeature = new ol.Feature( { name: "SegmentFeature", geometry: this.m_splitFeature.getSegment(segmentIndex) } );
		// this.m_segmentLayer.getSource().addFeature(segmentFeature);
		
		// this.m_splitStepController.setCurrentStep(STEP_FIRST_COORDINATE);
		// this.captureCoordinateInteration.clear();
		// this.m_drawLineTool = new DrawLine({map: this.m_map, canAddCoordinate: this.canAddCoordinateCallback, coordinateAdded: this.coordinateAddedCallback, sender: this});
		
		//we must assure that the snap interaction is always the last added interaction
		// this.m_map.removeInteraction(this.m_snap);
		// this.m_map.addInteraction(this.m_snap);
		
		// this.m_map.getView().fit(m_currentSplitGeometry);
		
		// if(this.onExtentChangedCallback != null)
		// {
		// 	this.onExtentChangedCallback();
		// }
		
		// return true;

        let notification = new AppNotification(AppNotification.FINISH_CUSTOM_EDITION_INTERACTION);        
        notification.object= {interaction:this}
        this.notificationService.send(notification);
	}

	public getSelectedSegment()
	{
		return this.selectedSegment;
	}

}



