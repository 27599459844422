<h2 *ngIf="featureInfoTool && message"> {{message}} </h2>
<mat-progress-bar *ngIf="ctrlLoading" mode="indeterminate"></mat-progress-bar>
<mat-accordion *ngIf="featureInfoTool && featureInfoTool.layersInfo">
    <ng-container #featureinfo *ngFor="let featureInfo of featureInfoTool.layersInfo.layersFeatures; first as isFirst">
        <mat-expansion-panel [expanded]="isFirst" class="layername">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header" class="layers-col">
                    <mat-icon class="material-icons layer-icon">layers</mat-icon>{{featureInfo.layerConfig.name}}
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div *ngFor="let feature of featureInfo.features;" class="attributes-div">
                <table mat-table [dataSource]="feature.properties" class="mat-elevation-z8">
                    <ng-container matColumnDef="key">
                        <th mat-header-cell *matHeaderCellDef class="attribute-column"> Campo </th>
                        <td mat-cell *matCellDef="let property"> {{property.key}} </td>
                      </ng-container>
                      <ng-container matColumnDef="value">
                        <th mat-header-cell *matHeaderCellDef class="attribute-column" class="attribute-column"> Valor </th>
                        <td mat-cell *matCellDef="let property" class="attribute-column"> {{property.value}} </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>