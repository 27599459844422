import { Interaction } from 'ol/interaction';
export class InteractionController
{

	private interections: Map<string, Interaction>;

	constructor()
	{
		this.interections = new Map<string, Interaction>();
	}

	public addInteraction(id: string, interaction: Interaction)
	{
		this.interections.set(id, interaction);
		interaction.setActive(false);
	}

	public getInteraction(id: string)
	{
		return this.interections.get(id);
	}

	public removeInteraction(id: string)
	{
		let interaction = this.getInteraction(id);
		if(interaction)
		{
			interaction.setActive(false);
			this.interections.delete(id);
		}
		
	}
    
};
