import { HostListener, Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { FeatureInfoTool } from './fetureinfo-tool/featureinfo-tool';
import { SplitTool } from './split-tool/split-tool';
import { MergeTool } from './merge-tool/merge-tool';
import { PropertyRegistrationTool } from './property-registration/property-registration-tool';
import { PropertySearchTool } from './propertysearch-tool/propertysearch-tool';
import { PdfprintingTool } from './pdfprinting/pdfprinting-tool';
import { EditAttributeLayerTool } from './edit-attribute-layer-tool/edit-attribute-layer-tool';
import { DownloadTool } from './download-tool/download-tool';
import { CanvasService } from '../canvas/canvas.service';
import { LayerConfig } from '../layers/layers.config';
import { Tool } from './tool';
import { EditLayerLoadGeomTool } from './edit-layer-load-geom-tool/edit-layer-load-geom-tool';
import { Photo360LayerTool } from './photo360-layer-tool/photo360-layer-tool';
import { EditStyleLayerTool } from './edit-style-layer-tool/edit-style-layer-tool';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../vo/notification';
import * as toolsPermissionsJson from "../../../assets/tools-permissions.config.json";
import { AuthenticationService } from 'src/app/service/authentication.service';
import { SecurityManagementTool } from './security-management-tool/security-management-tool';
import { StreetSearchTool } from './street-search-tool/street-search-tool';
import { EditEdificacoesTool } from './edit-edificacoes-tool/edit-edificacoes-tool';
import { AddColetaPgvTool } from './coleta-pgv-tool/add-coleta-pgv-tool/add-coleta-pgv-tool';
import { ManageColetaPGVTool } from './coleta-pgv-tool/manage-coleta-pgv-tool/manage-coleta-pgv-tool';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  toolsObservable:Observable<any>;
  toolsSubs:Subscriber<any>;
  tools: Array<Tool>;
  layerTools: Array<Tool>;
  currentTool: Tool=null;

  constructor(private canvasService:CanvasService, private notificationService: NotificationService, private authenticationService: AuthenticationService) 
  {
    this.toolsObservable=new Observable((observer:any) => {
      this.toolsSubs=observer;
    });

    this.layerTools=[];
  }

  setTools(tools: Array<Tool>)
  {
    this.tools = tools;
    this.loadLayerTools();
  }

  dispatchTool(tool: Tool){
    this.toolsSubs.next(tool);
  }

  getTools()
  {
    return this.tools;
  }

  getToolByType(type: any)
  {
    let foundTool = null;
    this.tools.forEach(tool => 
      {
        if(tool instanceof type)
        {
          foundTool = tool;
        }
      
    });
    return foundTool;
  }

  toogleTool(toogleTool: Tool)
  {
    if(this.getCurrentTool()!=null)
    {
      if(this.getCurrentTool()!=toogleTool)
      {
        //if there is a tool enable, and is not the current, change to the new tool
        this.getCurrentTool().disable();
        this.setCurrentTool(toogleTool);
        this.getCurrentTool().enable();
      }
      else
      {
        this.setCurrentTool(null);
        toogleTool.disable();
      }
    }
    else
    {
      //Enabling new tool
      this.setCurrentTool(toogleTool);
      this.getCurrentTool().enable();
    }
  }

  public setCurrentTool(tool: Tool)
  {
    this.currentTool=tool;
    
    let notification = new AppNotification(AppNotification.CHANGE_TOOL_EVENT, tool);
    this.notificationService.send(notification);
  }
  public getCurrentTool() : Tool
  {
    return this.currentTool;
  }

  public loadLayerTools()
  {
    this.layerTools=new Array<Tool>();
    this.tools.forEach(tool => {
      if(tool.layerTool)
      {
        this.layerTools.push(tool);
      }
      
    });
  }

  public getLayerToolById(toolId: string)
  {
    let foundTool: Tool;
    this.layerTools.forEach(tool => {
      if(tool.id==toolId)
      {
        foundTool=tool;
      }
    }); 
    return foundTool;
  }

    /**
   * TODO: Improve this mechanism to use keyboard for another tools
   * 
   */
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.canvasService.clearMarker();
    }
  }

  public getLayerTools(layerConfig: LayerConfig) : Array<Tool>
  {
    let layerTools: Array<Tool>=[];
    if(layerConfig.getLayertools())
    {
      layerConfig.getLayertools().forEach(layertool => {
        if(layertool.toolid)
        {
          let tool = this.getLayerToolById(layertool.toolid);
          if(tool)
          {
            layerTools.push(tool);
          }
        }
      });
    }
    return layerTools;
  }

  

  public getToolParameter(tool: Tool, parameterKey: String) : any
  {
    let value : any= null;
    if(tool.customParameter.size>0)
    {
      value = tool.customParameter.get(parameterKey);
    }
    return value;
  }

  public loadToolsPermissions()
  {
    this.tools.forEach((tool: Tool)=>{
      this.loadToolPermission(tool);
    })
  }

  /**
   * Loads user group permissions for tool, configured on tools-permissions.config.json file
   * @param tool 
   */
  private loadToolPermission(tool: Tool) : void
  {
    tool.publicTool = true;
    toolsPermissionsJson.tools.forEach(currentTool => {
      if(tool.id == currentTool.id)
      {
        tool.publicTool=currentTool.public;
        
        currentTool.usergroups.forEach(usergroup => 
        {
          let userGroup = this.authenticationService.getUserGroupById(usergroup.id);
          if(userGroup)
          {
            tool.allowedUserGroups.push(userGroup);
          }
        });
      }
    })
  }
  public isToolVisibleForCurrentUser(tool: Tool) : boolean
  {
    let userInfo = this.authenticationService.getCurrentUser();

    let visible:boolean = false;
    if(userInfo)
    {
      if(tool.publicTool)
      {
        visible = true;
      }
      else
      {
        for (let i = 0; i < tool.allowedUserGroups.length; i++) {
          const allowedUserGroup = tool.allowedUserGroups[i];

          if(userInfo['usergroup_id']==allowedUserGroup.id)
          {
            visible = true;
            break;
          }

        }
      }
    }
    return visible;
  }

  public isToolEnabledForLayer(tool: Tool, layer: LayerConfig) : boolean
  {   

    let visible:boolean = false;

    layer.getLayertools().forEach(toolPerm => {
      if(toolPerm.toolid==tool.id)
      {
        visible = true;
      }
    }); 
    
    return visible;
  }

}
