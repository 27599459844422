import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/map/dialog/dialog.component';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { LayersInfo } from 'src/app/map/vo/layersinfo';
import { Feature } from '../../../vo/feature';
import { FeatureInfoTool } from '../../fetureinfo-tool/featureinfo-tool';
import { FetureInfoToolComponent } from '../../fetureinfo-tool/fetureinfo-tool.component';
import { Tool } from '../../tool';
import { AttributeSearchFeatureEdit } from './attribute-search-feature-edit';

export class AttributeSearchSettlementEdit extends AttributeSearchFeatureEdit
{
    static customizedFeatureInfo: string = "attribute-search-settlement-edit";

    constructor(parent: MatDialog, tool: any, feature: Feature, layerConfig: LayerConfig)
    {
        super(parent, tool, feature, layerConfig);        
    }
    showFeatureEdit()
    {
        this.tool.currentFeature = this.feature;
        this.tool.layer = this.layerConfig;
        return this.tool.openEditSettlement();
    }
}
