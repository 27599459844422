import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Users, Usersgroup, Usergroup } from '@funcate/sigweb-cti-api';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/service/backend.service';
import { ToastService } from 'src/app/service/toast.service';
import { LayerConfig, UserGroupPermission } from '../../layers/layers.config';
import { LayersConfigService } from '../../layers/layers.config.service';
import { LayerTool } from '../../vo/layertool';
import { ToolsService } from '../tools.service';
import { AppConfigTool } from './app-config-tool';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Tool } from '../tool';
import { UserGroup } from '../../vo/user-group';
import { UserGroupPermissionConfig } from '../../vo/user-group-permission';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { GenericConfirmationDialogComponent } from '../../dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { DialogComponent } from '../../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Newlayersconfig } from '@funcate/sigweb-cti-api/model/newlayersconfig';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { ImportLayerComponent } from './import-layer/import-layer.component';

@Component({
  selector: 'app-layers-config',
  templateUrl: './layers-config.component.html',
  styleUrls: ['./layers-config.component.css']
})
export class LayersConfigComponent implements OnInit {
  @ViewChild(JsonEditorComponent) layerAdvancedConfigEditor: JsonEditorComponent;
  layerAdvancedConfigEditorOptions = new JsonEditorOptions();
  private subscriptionTool:Subscription;
  private tool:AppConfigTool;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public currentSelectedLayerConfig: LayerConfig;
  public allTools: LayerTool[];
  public currentLayerUserGroupsPermissions: UserGroupPermissionConfig[];
  public layerAdvancedConfigJson: any;
  private confirmDialogRef: any;
  private importLayerDialogRef: any;
  public parent: MatDialog;

  overlayers: LayerConfig[]
  constructor(private toolsService: ToolsService, private layersConfigService: LayersConfigService, private backendService: BackendService, 
    private toastService: ToastService, private authenticationService: AuthenticationService, private dialog: MatDialog)
  {    
    this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (tool:AppConfigTool) => {
        this.init();
        this.tool=tool;
      },()=> {
      }
    );
    this.parent=dialog;
    
    this.layerAdvancedConfigEditorOptions.mode = 'code';
    this.layerAdvancedConfigEditorOptions.modes = ['code'];
    this.layerAdvancedConfigEditorOptions.statusBar = true;
    this.layerAdvancedConfigEditorOptions.onChange = () => this.onAdvancedLayerConfigChanged();
   }

  init()
  {
    this.layerAdvancedConfigJson={};
    this.overlayers=this.layersConfigService.getOverlayersFromJSON();
  }

  loadTools()
  {
    this.allTools=[];

    let tools = this.toolsService.getTools();
    let layerTools = this.currentSelectedLayerConfig.getLayertools();

    tools.forEach(tool => {
      if(tool.layerTool)
      {
        let enabled = false;
        layerTools.forEach(layerTool => 
        {          
          if(layerTool.toolid==tool.id)
          {
            enabled = true;    
          }           
        });        
        this.allTools.push(new LayerTool(tool.id.toString(), tool.title, enabled, this.currentSelectedLayerConfig));       
      }
    });

    this.allTools.sort((a,b)=>{
      if (a.toolTitle < b.toolTitle) {
        return -1;
      }
      if (a.toolTitle > b.toolTitle) {
          return 1;
      }
    })

  }

  loadLayerUserGroupsPermission() : void
  {
     let allUserGroups = UserGroup.USER_GROUPS;
     this.currentLayerUserGroupsPermissions =[];

     allUserGroups.forEach(userGroup => 
    {
      if(userGroup.id!=UserGroup.NONE.id)
      {
        let layerUserGroupPermission = new UserGroupPermissionConfig(userGroup, false, false);
        this.currentSelectedLayerConfig.getPermission().usergroups.forEach(layerUserGroup => 
        {
          if(userGroup.id==layerUserGroup.id)
          {
            layerUserGroupPermission.read = layerUserGroup.read;
            layerUserGroupPermission.write = layerUserGroup.write;
          }
        });
        this.currentLayerUserGroupsPermissions.push(layerUserGroupPermission);
      }
     });
  }

  ngOnInit(): void 
  {
    
  }  

  public close()
  {
    this.tool.closeDialog();
  }

  dropOverLayer(event: CdkDragDrop<string[]>) {
    
    moveItemInArray(this.overlayers, event.previousIndex, event.currentIndex);    
  }

  onChangeOverlayer(layerId: string, checked: boolean)
  {
    let overlayer = this.layersConfigService.getOverLayerById(layerId, this.overlayers);

    overlayer.setEnabled(checked);
  }

  selectLayer(layerConfig: LayerConfig)
  {
    this.currentSelectedLayerConfig = layerConfig;
    this.loadTools();
    this.loadLayerUserGroupsPermission();
    this.loadLayerApplicationConfigs();
  }
  loadLayerApplicationConfigs()
  {
    this.layerAdvancedConfigJson={};
    if(this.currentSelectedLayerConfig.getApplicationconfigs().length>0)
    {
      
      this.layerAdvancedConfigJson=this.currentSelectedLayerConfig.getApplicationconfigs()[0];
    }    
  }
  onToolSelection(event) 
  {

    let selectedTool = event.option.value;
    selectedTool.enabled = event.option.selected;
    let layerTools = this.currentSelectedLayerConfig.getLayertools();
    if(selectedTool.enabled==true)
    {    
      //Add tool to layer config  
      layerTools.push({ toolid: selectedTool.toolId});
    }
    else
    {

      let newLayerTool = [];
      //Remove tool to layer config
      layerTools.forEach((layerTool, index) => 
      {
        if(layerTool.toolid!=selectedTool.toolId)
        {          
          newLayerTool.push(layerTool);
        }
      });
      this.currentSelectedLayerConfig.setLayertools(newLayerTool);
    }
    
    
  }
  onGrupoPermissionChange(userGroupPermissionConfig: UserGroupPermissionConfig)
  {
    let layerPermission: UserGroupPermission=null;
    this.currentSelectedLayerConfig.getPermission().usergroups.forEach((layerUserGroupPermission: UserGroupPermission) => {
      if(layerUserGroupPermission.id==userGroupPermissionConfig.userGroup.id)
      {
        layerPermission=layerUserGroupPermission;
      }
    });

    if(layerPermission!=null)
    {
      //If already exist entry, just update permission
      layerPermission.read = userGroupPermissionConfig.read
      layerPermission.write = userGroupPermissionConfig.write
    }
    else
    {
      //If don't exists entry, add a new UserGroupPermission
      let newPermission: UserGroupPermission =
      {
        id:userGroupPermissionConfig.userGroup.id,
        read:userGroupPermissionConfig.read,
        write:userGroupPermissionConfig.write
      };

      this.currentSelectedLayerConfig.getPermission().usergroups.push(newPermission);
    }
  }

  onAdvancedLayerConfigChanged()
  {
    if(this.currentSelectedLayerConfig.getApplicationconfigs().length==0)
    {
      this.currentSelectedLayerConfig.getApplicationconfigs().push({});
    }
    this.currentSelectedLayerConfig.getApplicationconfigs()[0] = this.layerAdvancedConfigEditor.get();
    
  }
  confirmSave()
  {
    this.confirmDialogRef = this.dialog.open(DialogComponent, {
      height: '30%',
      width: '20%',
      data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente salvar as configurações para todas as camadas?" }
    });

    this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
      if (confirmResult) {
        this.save().then(() => {
          this.toastService.sucess("Configuração salva com sucesso, para utilizar a nova configuração abra a aplicação novamente.", "Sucesso");
          this.close()
        }).catch(() => {
          this.toastService.error("Problema ao salvar a configuração!", "Erro");
        })
      }
    });

  }
  save()
  {

    let userInfo = this.authenticationService.getCurrentUser();
    let grupo = this.authenticationService.getUserGroupById(userInfo['usergroup_id'])

    let layersJSON = this.layersConfigService.layersJson;

    //Marcando camada como salva
    this.overlayers.forEach(overlayer => {
      overlayer.setMemory(false);
    });

    layersJSON.layers.overlayers = this.overlayers;    

    let newLayersConfig: Newlayersconfig = new Object();
    newLayersConfig.usuarioid = Number.parseInt(userInfo.id);
    newLayersConfig.usuarionome = userInfo.name;
    newLayersConfig.grupoid = userInfo['usergroup_id'];
    if (grupo) {
      newLayersConfig.gruponome = grupo.name;
    }
    newLayersConfig.configjson = JSON.stringify(layersJSON);
    newLayersConfig.current = true;

    return this.backendService.setNewLayersConfig(newLayersConfig);    
  }

  deleteLayer()
  {

    this.confirmDialogRef = this.dialog.open(DialogComponent, {
      height: '30%',
      width: '20%',
      data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente apagar a camada selecionada?" }
    });
    

    this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
      if(confirmResult)
      {

        if(!this.currentSelectedLayerConfig.isMemory())
        {
          this.backendService.deleteLayer(this.currentSelectedLayerConfig.getId()).then(()=>
          {
            this.removeLayerFromList();
            this.save();
          })
        }
        else
        {
          this.removeLayerFromList();
        }

        
      }

    });
  }
  private removeLayerFromList()
  {
    this.overlayers.forEach((overlayer,index)=>
    {
      if(overlayer==this.currentSelectedLayerConfig)
      {
        this.overlayers.splice(index,1);
        this.toastService.sucess("Camada apagada com sucesso.","Sucesso");
        this.currentSelectedLayerConfig = null;            
      }          
});  
  }
  newLayer()
  {
    let newLayer = new LayerConfig(null, "Nova Camada", null, null, null, false, null, null, null, [], [], { public: false, usergroups: []});

    let advancedConfig = {       
      "geom_attribute": "geom",
      "geomtype": "Polygon",
      "id_attribute": "id",
      "table": "table_name",
      "vector": true
    };
    newLayer.setMemory(true);
    newLayer.setApplicationconfigs([advancedConfig]);
    this.overlayers.push(newLayer);

    this.selectLayer(newLayer);    

  }
  importLayer()
  {
    this.importLayerDialogRef = this.dialog.open(DialogComponent, {
      height: '90%',
      width: '90%',
      data: { component: ImportLayerComponent, title: "Importar Camada", layerConfigComponent: this }
    });

    this.importLayerDialogRef.afterClosed().toPromise().then(()=>{

    });
  }
  importedLayer(newLayer: LayerConfig)
  {
    this.importLayerDialogRef.close();
    this.overlayers.push(newLayer);
    this.selectLayer(newLayer);

    this.save().then(() => {
      this.toastService.sucess("Configuração salva com sucesso, para utilizar a nova configuração abra a aplicação novamente.", "Sucesso");
    }).catch(() => {
      this.toastService.error("Problema ao salvar a configuração!", "Erro");
    })
  }

}

