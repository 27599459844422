<mat-accordion *ngIf="layer">
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="layername">
            <mat-expansion-panel-header>
                <mat-panel-title id="layer-header" class="layers-col">
                    <mat-icon class="material-icons layer-icon">layers</mat-icon>{{layer.getName()}}
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
                <mat-form-field class="filtro">
                    <mat-label>Filtro</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. fonte" #input>
                </mat-form-field>
                <mat-radio-group aria-labelledby="example-radio-group-label" [(ngModel)]="currentStyle">
                    <table mat-table matSort [dataSource]="styles" class="mat-elevation-z8" (matSortChange)="sortData($event)"  matSortActive="id" matSortDirection="asc">
                        <ng-container matColumnDef="enabled">
                            <th mat-header-cell *matHeaderCellDef> </th>
                            <td mat-cell *matCellDef="let style">
                                <mat-radio-button class="example-radio-button" [value]="style">
                              </mat-radio-button>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="id"> Id </th>
                            <td mat-cell *matCellDef="let style" class="id-column" > {{getStyleId(style)}} </td>
                          </ng-container>
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> Nome </th>
                            <td mat-cell *matCellDef="let style" class="name-column"> {{style.name}} </td>
                          </ng-container>
                          <ng-container matColumnDef="title">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="title"> Descrição </th>
                            <td mat-cell *matCellDef="let style" class="title-column"> {{style.title}} </td>
                            
                          </ng-container>


                          <ng-container matColumnDef="legend">
                            <th mat-header-cell *matHeaderCellDef> Legenda </th>
                            <td mat-cell *matCellDef="let style">
                                
                                <img src="{{style.legendURL}}">
                            
                            </td>
                            
                          </ng-container>

                          <ng-container matColumnDef="acoes">
                            <th mat-header-cell *matHeaderCellDef> Ações 
                                <mat-icon *ngIf="hasEditionPermition()" title="Adicionar arquivo de estilo SLD" (click)="showAddStyle()" class="addSLD">add_box</mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let style" class="acoes-column"> 
                                <mat-icon *ngIf="downloading==false"  title="Baixar arquivo de estilo SLD" class="download" (click)="downloadStyle(style)">get_app</mat-icon>
                                <mat-icon *ngIf="downloading==true"  title="Baixando" class="disabled-download">get_app</mat-icon>
                                <mat-icon *ngIf="hasEditionPermition()" title="Alterar arquivo de estilo SLD" class="download" (click)="showUpdateStyle(style)">edit</mat-icon>
                                <mat-icon *ngIf="hasEditionPermition()" title="Delete estilo" class="download" (click)="confirmDeleteStyle(style)">indeterminate_check_box</mat-icon>
                            </td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
                          <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
                    </table>
              </mat-radio-group>                
            </div>
            <div *ngIf="isLoading" class="loading-div" style="">
                <mat-progress-spinner [diameter]="50"
                    color="primary" 
                    mode="indeterminate">
                </mat-progress-spinner>
            </div>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>


<div class="buttons-div">
    <!-- <div class="save-button">
        <button mat-raised-button color="primary" (click)="save()">Salvar</button>

    </div> -->
    <div class="cancel-button">
        <button mat-raised-button color="basic" (click)="close()">Cancelar</button>
    </div>
    <div class="apply-button">
        <button mat-raised-button color="primary" (click)="save()">Aplicar</button>

    </div>
</div>



