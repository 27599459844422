<mat-sidenav-container class="mat-sidenav-container">
    <mat-sidenav #sidenav mode="side" opened >
      <mat-nav-list>
  
        <a *ngIf="isConsulta==false" mat-list-item (click)="switchCertidao('certidao-uso')" [ngClass]="{'selected': certidaoUso}"> <mat-icon>description</mat-icon>Certidão de Uso de Solo </a>
        <a *ngIf="isConsulta==false" mat-list-item (click)="switchCertidao('certidao-emplacamento')" [ngClass]="{'selected': certidaoEmplacamento}"> <mat-icon>description</mat-icon>Certidão de Emplacamento e Confrontações </a>
        <a *ngIf="isConsulta==false" mat-list-item (click)="switchCertidao('certidao-unificacao')" [ngClass]="{'selected': certidaoUnificacao}"> <mat-icon>description</mat-icon>Certidão de Unificação de Lotes </a>
        <a *ngIf="isConsulta==false" mat-list-item (click)="switchCertidao('certidao-desdobro')" [ngClass]="{'selected': certidaoDesdobro}"> <mat-icon>description</mat-icon>Certidão de Desdobro de Lotes </a>
        <a *ngIf="isConsulta==false" mat-list-item (click)="switchCertidao('certidao-areas-e-datas')" [ngClass]="{'selected': certidaoAreasEDatas}"> <mat-icon>description</mat-icon>Certidão de Áreas e Datas </a>
        <a *ngIf="isConsulta==false" mat-list-item (click)="switchCertidao('certidao-medidas')" [ngClass]="{'selected': certidaoMedidas}"> <mat-icon>description</mat-icon>Certidão de Medidas e Confrontações</a>
        <a mat-list-item (click)="switchCertidao('relatorio-geral-imovel')" [ngClass]="{'selected': relatorioGeralImovel}"> <mat-icon>description</mat-icon>Relatório Geral do Imóvel</a>
     
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="certidao-content">
        <app-generate-certidao-uso *ngIf="certidaoUso" [ics]="inscricoes"></app-generate-certidao-uso>
        <app-generate-certidao-emplacamento *ngIf="certidaoEmplacamento" [ics]="inscricoes" [feature]="feature" [featureSRID]="featureSRID" [lotesLayer]="tool.lotesLayer"></app-generate-certidao-emplacamento>
        <app-generate-certidao-unificacao *ngIf="certidaoUnificacao" [ics]="inscricoes" [feature]="feature" [featureSRID]="featureSRID"></app-generate-certidao-unificacao>
        <app-generate-certidao-desdobro *ngIf="certidaoDesdobro" [ics]="inscricoes" [feature]="feature" [featureSRID]="featureSRID"></app-generate-certidao-desdobro>
        <app-generate-certidao-areas-e-datas *ngIf="certidaoAreasEDatas" [ics]="inscricoes" [feature]="feature" [featureSRID]="featureSRID"></app-generate-certidao-areas-e-datas>
        <app-generate-certidao-medidas *ngIf="certidaoMedidas" [ics]="inscricoes" [feature]="feature" [featureSRID]="featureSRID" [lotesLayer]="tool.lotesLayer"></app-generate-certidao-medidas>
        <app-generate-relatorio-geral-imovel *ngIf="relatorioGeralImovel" [ics]="inscricoes" [feature]="feature" [featureSRID]="featureSRID"></app-generate-relatorio-geral-imovel>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>