import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Users, Usersgroup, Usergroup, Importlayerfileinfo } from '@funcate/sigweb-cti-api';
import { Subscription } from 'rxjs';
import { BackendService } from 'src/app/service/backend.service';
import { ToastService } from 'src/app/service/toast.service';
import { LayerConfig, UserGroupPermission } from '../../../layers/layers.config';
import { LayersConfigService } from '../../../layers/layers.config.service';
import { LayerTool } from '../../../vo/layertool';
import { ToolsService } from '../../tools.service';
import { AppConfigTool } from '../app-config-tool';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Tool } from '../../tool';
import { UserGroup } from '../../../vo/user-group';
import { UserGroupPermissionConfig } from '../../../vo/user-group-permission';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { GenericConfirmationDialogComponent } from '../../../dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';
import { DialogComponent } from '../../../dialog/dialog.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Newlayersconfig } from '@funcate/sigweb-cti-api/model/newlayersconfig';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { StepsController } from '../../tools-utils/steps-controller';
import { NotificationService } from 'src/app/service/notification.service';
import { ImportLayerUploadFileStep } from './import-layer-steps/import-layer-upload-file-step';
import { ImportLayerUploadSLDFileStep } from './import-layer-steps/import-layer-upload-sld-file-step';
import { ImportLayerCheckDataStep } from './import-layer-steps/import-layer-check-data-step';
import { ImportLayerBasicInfoStep } from './import-layer-steps/import-layer-basic-info-step';
import { ImportLayerWMSInfoStep } from './import-layer-steps/import-layer-wms-info-step';
import { ImportLayerToolsInfoStep } from './import-layer-steps/import-layer-tools-info-step';
import { ImportLayerPermissionsInfoStep } from './import-layer-steps/import-layer-permissions-info-step';
import { ImportLayerAdvancedConfigInfoStep } from './import-layer-steps/import-layer-advanced-config-info-step';
import { Step } from 'src/app/map/vo/step';
import { LayersConfigComponent } from '../layers-config.component';


@Component({
  selector: 'app-import-layer',
  templateUrl: './import-layer.component.html',
  styleUrls: ['./import-layer.component.css']
})
export class ImportLayerComponent implements OnInit 
{    
  public tool:AppConfigTool;  
  stepsController: StepsController;
  importlayerfileinfo: Importlayerfileinfo;  
  legendPreviewURL: string;
  public newLayerConfig: LayerConfig;
  @ViewChild(JsonEditorComponent) layerAdvancedConfigEditor: JsonEditorComponent;
  parent: MatDialog;
  layerConfigComponent: LayersConfigComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private toolsService: ToolsService, private backendService: BackendService, 
    private toastService: ToastService, private notificationService: NotificationService, private dialog: MatDialog)
  {    
    this.importlayerfileinfo=null;
    this.parent=dialog;
    this.layerConfigComponent = data.layerConfigComponent;
  }

  ngOnInit(): void 
  {
    this.legendPreviewURL=null;
    this.initStepController();
  }  

  public close()
  {
    this.tool.closeDialog();
  }

  save()
  {

  }

  private initStepController()
  {
    
    this.stepsController = new StepsController(this.notificationService);

    let step1 = new ImportLayerUploadFileStep(this.stepsController.getSteps().length+1, this, this.toastService, this.backendService);

    this.stepsController.addNewStep(step1);

    let step2 = new ImportLayerUploadSLDFileStep(this.stepsController.getSteps().length+1, this, this.toastService, this.backendService);

    this.stepsController.addNewStep(step2);

    let step3 = new ImportLayerCheckDataStep(this.stepsController.getSteps().length+1, this, this.toastService, this.backendService);

    this.stepsController.addNewStep(step3);    

    let step4 = new ImportLayerBasicInfoStep(this.stepsController.getSteps().length+1, this, this.toastService, this.backendService);

    this.stepsController.addNewStep(step4);   

    let step5 = new ImportLayerWMSInfoStep(this.stepsController.getSteps().length+1, this, this.toastService, this.toolsService);

    this.stepsController.addNewStep(step5);   

    let step6 = new ImportLayerToolsInfoStep(this.stepsController.getSteps().length+1, this, this.toastService, this.toolsService);

    this.stepsController.addNewStep(step6);   

    let step7 = new ImportLayerPermissionsInfoStep(this.stepsController.getSteps().length+1, this, this.toastService, this.toolsService);

    this.stepsController.addNewStep(step7); 

    let step8 = new ImportLayerAdvancedConfigInfoStep(this.stepsController.getSteps().length+1, this, this.toastService, this.toolsService);

    this.stepsController.addNewStep(step8);

    this.stepsController.next();
  }

  public isCardVisible(id: string)
  {
    let currentStep: Step = this.stepsController.getCurrentStep();
    if(id=="upload-file-card")
    {
      if(currentStep instanceof ImportLayerUploadFileStep)
      {
        return true;
      }
    } else if(id=="upload-sld-file-card")
    {
      if(currentStep instanceof ImportLayerUploadSLDFileStep)
      {
        return true;
      }
    }else if(id=="upload-check-data-card")
    {
      if(currentStep instanceof ImportLayerCheckDataStep)
      {
        return true;
      }
    } else if(id=="create-layer-basic-info-card")
    {
      if(currentStep instanceof ImportLayerBasicInfoStep)
      {
        return true;
      }
    } else if(id=="create-layer-wms-info-card")
    {
      if(currentStep instanceof ImportLayerWMSInfoStep)
      {
        return true;
      }
    } else if(id=="create-layer-tools-info-card")
    {
      if(currentStep instanceof ImportLayerToolsInfoStep)
      {
        return true;
      }
    } else if(id=="create-layer-permissions-info-card")
    {
      if(currentStep instanceof ImportLayerPermissionsInfoStep)
      {
        return true;
      }
    } else if(id=="create-layer-advanced-config-info-card")
    {
      if(currentStep instanceof ImportLayerAdvancedConfigInfoStep)
      {
        return true;
      }
    }
    else if(id=="botoes-card")
    {
        return true;      
    }
    
    
    return false;
  }
  clearLayerName(e)
  {
    let currentValue = e.target.value;
    e.target.value=currentValue.replace(/[^a-z0-9_]/gi, "");     
    
  }

  importLayer()
  {
    let importLayerFileInfo : Importlayerfileinfo = new Object();

    importLayerFileInfo.defaultworkspace = this.importlayerfileinfo.defaultworkspace;
    importLayerFileInfo.featurecount = this.importlayerfileinfo.featurecount;
    importLayerFileInfo.filepath = this.importlayerfileinfo.filepath;
    importLayerFileInfo.filetype = this.importlayerfileinfo.filetype;
    importLayerFileInfo.geomcolumn = this.importlayerfileinfo.geomcolumn;
    importLayerFileInfo.geomtype = this.importlayerfileinfo.geomtype;
    importLayerFileInfo.idcolumn = this.importlayerfileinfo.idcolumn;
    importLayerFileInfo.layername = this.importlayerfileinfo.layername;
    importLayerFileInfo.msg = this.importlayerfileinfo.msg;
    importLayerFileInfo.error = this.importlayerfileinfo.error;    
    importLayerFileInfo.sldfilepath = this.importlayerfileinfo.sldfilepath;
    importLayerFileInfo.sldpreviewname = this.importlayerfileinfo.sldpreviewname;
    importLayerFileInfo.srid = this.importlayerfileinfo.srid;
        
    this.backendService.importLayer(importLayerFileInfo).then((importlayerfileinfo: Importlayerfileinfo)=>
    {
      if(importlayerfileinfo.error==true)
      {
        this.toastService.error(importlayerfileinfo.msg, "Falha ao importar camada");
      }
      else
      {
        this.layerConfigComponent.importedLayer(this.newLayerConfig);
        this.toastService.sucess("Camada importada.", "Sucesso");
      }
    });
    
  }
  
}

