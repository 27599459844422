import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  options =
    {
      timeOut: 10000,
      positionClass: 'toast-top-center'
    };

  constructor(private toastr: ToastrService) { }

  public error(message: string, title: string) {
    this.toastr.error(message, title, this.options)
  }

  public warning(message: string, title: string) {
    this.toastr.warning(message, title, this.options)
  }

  public sucess(message: string, title: string) {
    this.toastr.success(message, title, this.options)
  }

  public info(message: string, title: string) {
    this.toastr.info(message, title, this.options)
  }

  public clear()
  {
    this.toastr.clear();
  }


}
