import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, JsonpClientBackend } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { AppNotification } from '../map/vo/notification';
import { BackendService } from './backend.service';
import { Authentication, Changepassword, Userinfo, Users} from '@funcate/sigweb-cti-api';


import { LocalStorageService } from './local-storage.service';
import { ToastService } from './toast.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { UserGroup } from '../map/vo/user-group';
import { GeneralService } from './general.service';
import { environment } from 'src/environments/environment';
import { LayerConfig } from '../map/layers/layers.config';

@Injectable({
    providedIn: 'root'
  })

export class AuthenticationService {
   
   private authenticationUserInfo: string = "authenticationUserInfo";
   private expirationDays: number = 1;
   private currentUserInfo: any;

    constructor(private backendService: BackendService, private localStorageService: LocalStorageService, private toastService: ToastService, 
      private notificationService: NotificationService, public http: HttpClient) 
    {
    }
      
    public authenticate(username: string, password: string) : Promise<any>
    {

      let promise = new Promise((resolve, reject) => {
        let auth : Authentication = new Object();
        auth["username"] = btoa(username);
        auth["password"] = btoa(password);
        this.backendService.authenticate(auth).then(userInfo => {

          this.currentUserInfo=userInfo;

          let expirationDate = this.getExpirationDate();
          
          userInfo.expirationDate=expirationDate;

          this.localStorageService.setValue(this.authenticationUserInfo ,userInfo).subscribe(() => {

            if(userInfo.authenticated==true)
            {
              this.toastService.sucess("Bem vindo "+ userInfo.name, "Autenticado");
              resolve(userInfo);      
            }
            else
            {
              this.toastService.error(userInfo.message, "Falha na autenticação");
              reject(userInfo);
            }            
          });
        });
      });

     return promise;
    }

    public isAuthenticated() : Promise<boolean>
    { 
      let promise = new Promise<boolean>((resolve, reject) => {
        this.localStorageService.getValue(this.authenticationUserInfo).toPromise().then((userInfoStorageObjectJSON: any) => {
          
          let userInfoStorageObject = JSON.parse(userInfoStorageObjectJSON);

          if(userInfoStorageObject 
            && userInfoStorageObject.value
            && userInfoStorageObject.value.token)
          {

            this.isLoginValid(userInfoStorageObject.value.token).then((valid: boolean)=>{
              this.currentUserInfo=userInfoStorageObject.value;
              if(valid==false)
              {
                this.logout();
              }
              resolve(valid);
              
            }).catch(()=>{
              this.logout();
              resolve(false)
            });
          
          }
          else
          {
            this.logout();
            resolve(false)
          }
        });
      });
      return promise;
      
    }

    public authenticationChanged()
    {
      this.localStorageService.getValue(this.authenticationUserInfo).toPromise().then((userInfoStorageObjectJSON: any) =>
       {
        let userInfoStorageObject = JSON.parse(userInfoStorageObjectJSON);

        let userInfo: Userinfo=null;
        if(userInfoStorageObject && userInfoStorageObject.value)
        {
          userInfo = userInfoStorageObject.value;
        }

        if(userInfo)
        {
          this.backendService.setAuthToken(userInfo.token);
        }
        else
        {
          this.backendService.setAuthToken(null);
        }
         
        let notification = new AppNotification(AppNotification.AUTHENTICATION_CHANGED, userInfo);
        this.currentUserInfo = userInfo;
        this.notificationService.send(notification);
      });
    }

    public logout()
    {
      this.currentUserInfo=null;
      this.localStorageService.setValue(this.authenticationUserInfo ,null).subscribe(() => {
        this.authenticationChanged();
      });

    }

    public getUserGroupById(id: number) : UserGroup
    {

      let userGroup :UserGroup = null;
      UserGroup.USER_GROUPS.forEach(currentUserGroup => {
        if(currentUserGroup.id==id)
        {
          userGroup=currentUserGroup;
        }
      })
      return userGroup;
    }
    public getCurrentUser() : Userinfo
    {
      return this.currentUserInfo;
    }
    
    public loadCurrentUserFromLocalStorage() : Promise<any>
    { 
      let promise = new Promise<any>((resolve, reject) => {
        if(this.currentUserInfo)
        {
          resolve(this.currentUserInfo);
        }
        else
        {
          this.localStorageService.getValue(this.authenticationUserInfo).toPromise().then((userInfoStorageObjectJSON: any) => {
          
            let userInfoStorageObject = JSON.parse(userInfoStorageObjectJSON);
  
            if(userInfoStorageObject && userInfoStorageObject.value)
            {
              this.currentUserInfo=userInfoStorageObject.value;
              resolve(userInfoStorageObject.value);
            }
            else
            {
              resolve(null);
            }
          });
        }
      });
      return promise;
    }

    /**
     * Check token on cti backend
     * @param token oauth2 token 
     * @returns 
     */
    private isLoginValid(token: String) : Promise<boolean>
    {

      let promise = new Promise<boolean>((resolve, reject) => {
        let url = environment.BACKEND_API_BASE_PATH+"/acesso/check_token";
        
        let httpOptions = {
          headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
          })
        };
  
        let request = this.http.get(url, httpOptions).subscribe(
          (data:any)=>{
              resolve(true);
          },(error)=>{
              resolve(false);
          }, () => {
              resolve(true);
          }
        );
      });
  
      return promise;
    }

    private getExpirationDate(): Date
    {
      let expirationDate = this.getCurrentDate();
      expirationDate.setDate(expirationDate.getDate()+this.expirationDays);

      return expirationDate;
    }

    public getCurrentDate() : Date
    {
      let now = new Date();
      let currentDate = new Date(now.getFullYear(),now.getMonth(), now.getDate());
      return currentDate;
    }

    public changePassword(id: number, oldPassword: string,newPassword: string) : Promise<any>
    {
      let changePassword: Changepassword = new Object();
      changePassword.id = id;
      changePassword.oldPassword = btoa(oldPassword);
      changePassword.newPassword = btoa(newPassword);            
      return this.backendService.changePassword(changePassword);
    }

} 