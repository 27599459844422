import { ToolsService } from '../tools.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PdfprintingComponent } from './pdfprinting.component'
import { DialogComponent } from '../../dialog/dialog.component';
import { Tool } from '../tool';

export class PdfprintingTool extends Tool {

    private dialog: MatDialog;

    cursorType=null;
    id='pdfprinting-tool';
    enabled=false;
    name='Map PDF printing';
    title='Impressão de mapa em PDF';
    type='click';
    protected visible:boolean=true;
    icon='picture_as_pdf';
    layerTool: boolean=false;
    customParameter=new Map<String, any>();

    dialogRef: MatDialogRef<DialogComponent>;

    constructor(toolsService: ToolsService, dialog: MatDialog) 
    { 
        super(toolsService)
        this.dialog = dialog;
    }

    public closeDialog(){
        this.dialogRef.close();
    }

    public execAction=(event:L.LeafletMouseEvent)=>{ };

    public enableImpl()
    {
        this.dialogRef = this.dialog.open(DialogComponent, {
            height: '95%',
            width: '80%',
            data: { component: PdfprintingComponent, title: "Impressão do mapa - instruções para ajustes"  }
        });
        
        let whenDialogIsOpen=this.dialogRef.afterOpened().toPromise();
        whenDialogIsOpen.then(
            ()=>{
                this.toolsService.dispatchTool(this);
            }
        );

        this.dialogRef.afterClosed().toPromise().then(()=>{
            this.toolsService.toogleTool(this);
        });
    }
    public disableImpl()
    {

    }
}
