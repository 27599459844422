import { Component, Inject, Input, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Certidaodesdobroconfig } from '@funcate/sigweb-cti-api';
import { Subscription } from 'rxjs';
import { LayersConfigService } from '../../../layers/layers.config.service';
import { BackendService } from '../../../../service/backend.service';
import { ToastrService } from 'ngx-toastr';
import { ToolsService } from '../../tools.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { GeneralService } from 'src/app/service/general.service';
import { CertidoesService } from 'src/app/service/certidoes.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../../../vo/notification';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { Canvas } from 'leaflet';
import * as L from 'leaflet';
import { FeatureService } from 'src/app/service/feature.service';
import { Inscricao } from 'src/app/map/vo/inscricao';


@Component({
  selector: 'app-generate-certidao-desdobro',
  templateUrl: './generate-certidao-desdobro.component.html',
  styleUrls: ['./generate-certidao-desdobro.component.css']
})
export class GenerateCertidaoDesdobroComponent implements OnInit {

  tool:any;
  subscriptionTool:Subscription;
  @Input() ics: string;
  @Input() feature: any;
  @Input() featureSRID: string;
  ic: string;
  interessado: string;
  ano: string;
  processo: string;
  loteamento: string;
  quadra: string;
  lote: string;
  notificationSubscrition: Subscription;
  map1LayerAppConfig: string = "certidao_desdobro_mapa1";
  map2LayerAppConfig: string = "certidao_desdobro_mapa2";
  map1Layers: LayerConfig[]=[];
  map2Layers: LayerConfig[]=[];
  map1Width: number=1200
  map1Height: number=600
  map2Width: number=1200
  map2Height: number=600
  downloading: boolean = false;

  constructor(private notificationService: NotificationService, private toastService: ToastrService,
   private toolsService: ToolsService, private featureService: FeatureService,
    private backendService: BackendService, private layersConfigService: LayersConfigService, 
    private authenticationService: AuthenticationService, private generalService: GeneralService, private certidoesService: CertidoesService) {

    //this.inscricao=data.inscricao
    this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (tool:any) => {
        this.tool=tool;
      }
    );

    this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
      if (notification.event==AppNotification.ON_DOWNLOAD_FINISHED_EVENT)
      {
          console.log("Download from '"+notification.object.url+"' is done to out file '"+notification.object.outputFilename+"'.");
          this.downloading = false;
      } 
  });
    
   }

  ngOnInit(): void 
  {    
    if(this.ics.length==1)
    {
      this.ic=this.ics[0];
    }       
  }

  createCertidaoDesdobroConfig() : Certidaodesdobroconfig
  {
    this.map1Layers = this.layersConfigService.getLayersWithAppConfig(this.map1LayerAppConfig);
    this.map2Layers = this.layersConfigService.getLayersWithAppConfig(this.map2LayerAppConfig);

    if(this.map1Layers.length>0 && this.map2Layers.length>0)
    {
      let map1URL = this.certidoesService.getMapURL(this.feature, this.map1Layers, this.map1Width, this.map1Height);
      let map2URL = this.certidoesService.getMapURL(this.feature, this.map2Layers, this.map2Width, this.map2Height);

      console.log(map1URL);
      console.log(map2URL);

      let inscricao : Inscricao = new Inscricao(this.ic);

      let certidaoDesdobroConfig: Certidaodesdobroconfig = new Object();
      certidaoDesdobroConfig['ano']=this.ano;
      certidaoDesdobroConfig['processo']=this.processo;
      certidaoDesdobroConfig['interessado']=this.interessado;
      certidaoDesdobroConfig['loteamento']=" ";
      certidaoDesdobroConfig['quadra']=inscricao.quadra;
      certidaoDesdobroConfig['lote']=inscricao.lote;
      certidaoDesdobroConfig['inscricao']=this.ic;
      certidaoDesdobroConfig['url_mapa1']=map1URL;
      certidaoDesdobroConfig['url_mapa2']=map2URL;

      return certidaoDesdobroConfig;
    }
  
  }  
  


  generate()
  {
    if(!this.interessado)
    {
      this.toastService.error("Favor selecionar uma inscrição.")
    }
    else
    if(!this.interessado)
    {
      this.toastService.error("Favor preencher o campo interessado.")
    }
    else  if(!this.ano)
    {
      this.toastService.error("Favor preencher o ano.")
    }
    else  if(!this.processo)
    {
      this.toastService.error("Favor preencher o número do processo.")
    } else
    {
      let user = this.authenticationService.getCurrentUser();
      if(!user)
      {
        this.toastService.error("Usuário não está autenticado corretamente. Efetue o login novamente.", "Problemas ao gerar certidão.");
      }
      else
      {
        let certidaoConfig = this.createCertidaoDesdobroConfig();


        let filename = "certidao-desdobro-"+this.ic+".docx";
        let url = environment.BACKEND_API_BASE_PATH+'/certidoes/desdobro/get';
        this.downloading = true;
        this.generalService.downloadFilePostMethod(url, certidaoConfig, filename, user.token);
      }
    }
  }
 
}
