import { Translate } from 'ol/interaction'


export class TranslateInteraction extends Translate
{
    id: string;
    step: any;
    constructor(id:string, options)
    {
        super(options);
        this.id=id;
        this.step=options.currentStep;
        this.on('translateend', this.onModifyEnd);
    }
    
    public onModifyEnd(event)
    {
        
        if(event.features)
        {
            if(event.features.getArray().length>0)
            {
                let feature = event.features.getArray()[0]
                this.step.onModifyEnd(feature);
            }

        }


    }
    
}



