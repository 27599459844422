import { Pipe, PipeTransform } from '@angular/core';
import * as Converter from 'xml-js';

export class XMLParser {

  constructor() 
  { }

  public static parseXMLToObject(xml: string) : any
  {
    let object =null;
    try
    {
      let jsonXML = Converter.xml2json(xml);
      object = JSON.parse(jsonXML);
      
    }
    catch(err)
    {
      console.log("Failed parsing xml string to object: " + err);
    }

    return object;
    
  }
}