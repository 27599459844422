import { Injectable } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-easyprint';
import '@geoman-io/leaflet-geoman-free';
import { ScaleSelector } from '../../leaflet/custom/control/scale-selector';
import { Observable, Subscriber } from 'rxjs';
import { Tool } from '../tools/tool';
import { LayerConfig, OVERLAYERS_INITIAL_ZINDEX, BASELAYERS_INITIAL_ZINDEX } from '../layers/layers.config';
import { LayersConfigService } from '../layers/layers.config.service';
import { ScriptService } from '../../service/script.service';
import { FeatureService } from 'src/app/service/feature.service';
import { Property } from '../vo/property';
import { ToastrService } from 'ngx-toastr';
import { ToastService } from 'src/app/service/toast.service';
import { GeomType } from '../vo/geomtype';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../vo/notification';
import {DomUtil} from 'leaflet';
import { EditLayerLoadGeomTool } from '../tools/edit-layer-load-geom-tool/edit-layer-load-geom-tool';
import { CapabilitiesService } from 'src/app/service/capabilities.service';
import { BoundingBox } from '../vo/boundingbox';
import { AuthenticationService } from 'src/app/service/authentication.service';
import * as proj4 from 'proj4';
import { Feature } from 'ol';
import { Coordinate } from 'ol/coordinate';
import * as ol from 'ol';
import Point from 'ol/geom/Point';
import GeoJSON from 'ol/format/GeoJSON';



declare var toogleMeasureControl: Function;

@Injectable({
  providedIn: 'root'
})
export class CanvasService {
  
  // define the center of the map
  mapCenter={lat: -23.95208893612136, lng: -46.48315429687501};
  
  map: any;
  leafletActiveLayers:L.TileLayer.WMS[];
  leafletBaselayer:L.TileLayer;
  canvasReady:Promise<void>;
  canvasIsReady:any;
  toolbarObservable: Observable<Tool>;
  layerObservable: Observable<void>;
  layerChangeStatus:Subscriber<void>;
  tools:Tool[];
  mapControls: any;
  easyPrintPlugin:any;
  markersLayer:L.GeoJSON;
  highlightLayer:L.GeoJSON;
  inEdition: boolean = false;
  currentEditionLayer: LayerConfig;
  loadEditionGeomControl: L.Control;
  private memoryEditionLayer: L.GeoJSON=null; 
  private memoryAnnotationLayer: L.FeatureGroup=null; 
  measureControl: any;
  measureLayer: any;
  

  constructor(private layersConfigService:LayersConfigService,
    private featureService: FeatureService,
    private toastService : ToastService,
    private notificationService: NotificationService,
    private layerConfigService: LayersConfigService,
    private capabilitiesService: CapabilitiesService,
    private authenticationService: AuthenticationService) {
    this.leafletActiveLayers=[];
    this.canvasReady=new Promise((resolve, reject) => {
      this.canvasIsReady=resolve;
    });

    // using to inform the subscribers that the layer status changes
    this.layerObservable=new Observable((observer) => {
      this.layerChangeStatus=observer;
    });

    const leafletMeasureScriptUrl = 'leaflet-measure/leaflet-measure.pt_BR.js';
    
    ScriptService.loadExternalScript(leafletMeasureScriptUrl);

    const proj4ScriptUrl = 'proj4/proj4-src.js';
    
    ScriptService.loadExternalScript(proj4ScriptUrl);

    const leafletGeometryUtilScriptUrl = 'leaflet-geometryutil/leaflet.geometryutil.js';

    ScriptService.loadExternalScript(leafletGeometryUtilScriptUrl);

    this.initProj4();
  }

  leafletEasyPrint() {
    this.easyPrintPlugin = L["easyPrint"]({
      sizeModes: ['A4Landscape'],
      exportOnly: true,
      hidden: true
    }).addTo(this.map);
  }

  saveMapToPng(){
    this.easyPrintPlugin.printMap('A4Landscape page', 'sigweb-mapa');
  }

  getLayerChanges(): Observable<void> {
    return this.layerObservable;
  }

  setToolbarObservable(toolbarObservable: Observable<Tool>) {
    this.toolbarObservable=toolbarObservable;
  }

  getControlOptions(){
    // Values to bind to Leaflet Directive
    let layersControlOptions = { position: 'topright' };
    return layersControlOptions;
  }
  
  getOptions(){
    let options = {
      zoom: 12,
      maxZoom: 22,
      minZoom: 3,
      center: L.latLng(this.mapCenter.lat,this.mapCenter.lng)
    };
    return options;
  }

  setMapReference(map:any){
    this.map=map;
    this.canvasIsReady();
    this.setZoomLevelChangedEvent();
  }

  private setZoomLevelChangedEvent()
  {    
    this.map.on('zoomend', function(e) {
      console.log("Current Zoom:" + e.target.getZoom());
  });
  }

  getToolbar(){
    return this.toolbarObservable;
  }

  onCanvasReady() {
    return this.canvasReady;
  }

  addScaleBar(){
    L.control.scale({metric:true}).addTo(this.map);
  }

  addScaleSelectorBar(){
    this.map.addControl(new ScaleSelector());
  }

  /**
   * One simple method to adding a marker over map
   * @param coord, one point, [latitude:number,longitude:number] where add a marker
   */
  addMarker(coord=[this.mapCenter.lat, this.mapCenter.lng ]){
    let point:L.LatLngExpression=L.latLng(coord[0],coord[1]);
    let aMarker = L.marker(point, {
      icon: L.icon({
          iconSize: [ 25, 41 ],
          iconAnchor: [ 13, 41 ],
          popupAnchor: [0, -30],
          iconUrl: 'assets/marker-icon.png',
          shadowUrl: 'assets/marker-shadow.png'
      })
    });
    aMarker.bindPopup("Latitude:"+this.mapCenter.lat+"<br/>Longitude:"+this.mapCenter.lng);
    aMarker.addTo(this.map);
  }

  swapBaselayer(baselayer:LayerConfig) {
    if(this.leafletBaselayer)
    {
      this.leafletBaselayer.removeFrom(this.map);
    }
    let baseLayerZIndex = BASELAYERS_INITIAL_ZINDEX;
    let newBaselayer=L.tileLayer(baselayer.getSource(), {
      maxZoom: 19,
      attribution: baselayer.getName(),
      zIndex: baseLayerZIndex
    });
    newBaselayer.addTo(this.map);
    this.leafletBaselayer=newBaselayer;
  }

  removeOverlayerFromMap(overlayer:LayerConfig) {
    let index=this.leafletActiveLayers.findIndex( (l)=> 
    {
      if(l.options.layers==overlayer.getId())
      {
        return true;
      }
      
    });
    let wms=this.leafletActiveLayers[index];
    this.leafletActiveLayers.splice(index,1);
    wms.removeFrom(this.map);
    // inform the subscribers that the layer status changes
    this.layerChangeStatus.next();
  }

  addOverlayerToMap(overlayer:LayerConfig)
  {
    
    let sourceUrl=overlayer.getSource()+overlayer.getPath();
    

    let anticfloat = (Math.floor( Math.random() * 2000000 ) + 1)/21;
    sourceUrl+="?anticfloat="+anticfloat;


    let options = {
      format: 'image/png',
      transparent: true,
      layers: overlayer.getId(),
      maxZoom: 22, 
      bounds: null, 
      tiled: true,
      tilesOrigin: "1,1"
    };

    if(overlayer.getCqlfilter())
    {
      options["cql_filter"]=overlayer.getCqlfilter();
    }

    if(overlayer.getStyle())
    {
      options["styles"]=overlayer.getStyle();
    }

    let newLayer=null;

    if(this.layerConfigService.isWMTSLayer(overlayer))
    {    
      let sourceWMTS = overlayer.getSource()+overlayer.getPath();

      sourceWMTS = sourceWMTS.replace("/wms", "/gwc/service/wmts");
      
      sourceWMTS+= '?layer='+ overlayer.getId();
      sourceWMTS+= '&service=WMTS';
      sourceWMTS+= '&request=GetTile';
      sourceWMTS+= '&version=1.0.0';
      sourceWMTS+= '&format=image%2Fpng';
      sourceWMTS+= '&tilematrixset='+ this.layerConfigService.getDefaultGWCTileMatrixName();
      sourceWMTS+= '&tileMatrix='+ this.layerConfigService.getDefaultGWCTileMatrixName() + '%3A{z}';
      sourceWMTS+= '&tileCol={x}';
      sourceWMTS+= '&tileRow={y}';
      
      if(overlayer.getBounds())
      {
        let ll=new L.LatLng(overlayer.getBounds().lowerLeft.lat, overlayer.getBounds().lowerLeft.lng);
        let ur=new L.LatLng(overlayer.getBounds().upperRight.lat, overlayer.getBounds().upperRight.lng);
        let bounds = new L.LatLngBounds(ll, ur);

        options.bounds = bounds;
      }
      
      
      newLayer = L.tileLayer(sourceWMTS, options);

      newLayer.on('tileerror', function(error, tile)
      {
        error.tile.style.display='none';
    });
    }
    else 
    {
      newLayer = L.tileLayer.wms(sourceUrl, options);
    }

    newLayer.addTo(this.map);
    newLayer["id"]=overlayer.getId();
   
    this.leafletActiveLayers.push(newLayer);
    
    
    // inform the subscribers that the layer status changes
    this.layerChangeStatus.next();

    
    return newLayer;
  }

  resetAllControls()
  {
    this.mapControls = 
    {
      'drawMarker':false,
      'drawCircleMarker':false,
      'drawPolyline':false,
      'drawRectangle':false,
      'drawPolygon':false,
      'drawCircle':false,
      'editMode':false,
      'dragMode':false,
      'cutPolygon':false,
      'removalMode':false,
      'pinningOption':false,
      'snappingOption':false}
  }

  addGeomanControls(){
    // using with faith because the geoman has no typifications
    this.map["pm"]["addControls"]({
      position: 'topleft',
      drawCircle: true,
      drawCircleMarker: true,
      drawPolyline: true,
      drawRectangle: true,
      drawPolygon: true,
      editMode: true,
      dragMode: true,
      cutPolygon: false,
      removalMode: true,
      drawMarker: true
    });

    /**
     * If i edit the ./node_modules/@types/leaflet/index.d.ts
     * and create a "pm:any;" property to the Map class it's works
        map.pm.addControls({
          position: 'topleft',
          drawCircle: false,
          drawCircleMarker: false,
          drawPolyline: true,
          drawRectangle: false,
          drawPolygon: true,
          editMode: false,
          dragMode: false,
          cutPolygon: false,
          removalMode: false,
          drawMarker: false
        });
     */
  }


  enableMapControls(controlPosition: string, controls: Array<string>)
  {

    this.resetAllControls();
    
    controls.forEach(control => {
      this.mapControls[control]=true;
    });

    this.map["pm"]["addControls"](this.mapControls);

    

  }

  getGeomsFromMap() {
    var fg = L.featureGroup();
    this.map.eachLayer((layer)=>{
       if((layer instanceof L.Path || layer instanceof L.Marker) && layer["pm"]){
        fg.addLayer(layer);
      }
    });
    console.log(fg.toGeoJSON());
  }

  updateOverLayers()
  {
    var zindex=OVERLAYERS_INITIAL_ZINDEX+this.layersConfigService.getOverlayers().length;
    //Iterate over overlayers config
    this.layersConfigService.getOverlayers().forEach(overlayer => 
    {

      //Getting leaflet layer (if it's added)
      let leafletLayer = this.leafletActiveLayers.find((layer)=> 
      {
        if(layer['id']==overlayer.getId()) 
        {
          return layer;
        }
      });

      //Checking if the currentUser has readacess to the current layer
      if(overlayer.isReadableForUser(this.authenticationService.getCurrentUser()))
      {
        //Syncronizing layer visibility (Enable or Disabled)
        if(overlayer.isEnabled()==false)
        {
          //Checking if leaflet layer is added (If true, removing it)
          if(leafletLayer!=null)
          {
            this.removeOverlayerFromMap(overlayer);
          }
        }
        else
        {
          //Checking if leaflet layer is added (Adding if not)
          if(leafletLayer==null)
          {
            leafletLayer=this.addOverlayerToMap(overlayer);
          }

          //Setting ZIndex according to array reverse index
          leafletLayer.setZIndex(zindex);

        }
      }
      else
      {
          //Layer not readable
          //Checking if leaflet layer is added (If true, removing it)
          if(leafletLayer!=null)
          {
            this.removeOverlayerFromMap(overlayer);
          }
      }      
      
      zindex--;
    }); 
  }

  toogleMeasureControl(enable: boolean)
  {
    this.measureControl = toogleMeasureControl(enable, this.map);
    this.measureLayer = this.measureControl.getLayer();
  }

  public fitFeatures(features: any)
  {    
    if(features && features.length>0)
    {
      //var bounds = new L.LatLngBounds(features[0].bbox);
      if(this.markersLayer)
      {
        this.markersLayer.addData(features);
      }
      else
      {
        this.markersLayer = L.geoJSON(features).addTo(this.map);
      }          

      //Fit to current address
      let boundsLayer = L.geoJSON(features).addTo(this.map);
      let options = 
      {
        maxZoom: 19
      }
      this.map.fitBounds(boundsLayer.getBounds(), options);
      boundsLayer.removeFrom(this.map);
    }
    else
    {
      this.toastService.error("Não foi possível encontrar o elemento geográfico solicitado.", 'Atenção')
    }
  }

  public fitLayer(layer: LayerConfig)
  {      
      if(layer)
      {
          this.layerConfigService.enableLayer(layer);
      }   
      
      if(layer)
      {
          let leafletLayer = this.getLeafletLayerById(layer.getId());

          this.capabilitiesService.getCapabilitiesforLayer(leafletLayer).then(capabilities => 
          {
              let layerBB : BoundingBox= this.capabilitiesService.getLayerLatLongBoundingBox(capabilities);
              
              let sw = L.latLng(layerBB.getMiny(), layerBB.getMinx());
              let ne = L.latLng(layerBB.getMaxy(), layerBB.getMaxx());

              let bb : L.LatLngBounds =  L.latLngBounds(sw, ne);

              this.map.fitBounds(bb);
          });
      }

  }

  public highlightFeatures(features: any, style: any)
  {
    this.toastService.clear();
    if(features && features.length>0)
    {

      if(this.highlightLayer)
      {
        this.highlightLayer.addData(features);
      }
      else
      {
        this.highlightLayer = L.geoJSON(features).addTo(this.map);
      }

      this.highlightLayer.setStyle(style);

      //Fit to current address
      let boundsLayer = L.geoJSON(features).addTo(this.map);
      this.map.fitBounds(boundsLayer.getBounds());
      boundsLayer.removeFrom(this.map);
    }
    else
    {
      this.toastService.error("Não foi possível encontrar o elemento geográfico solicitado.", 'Atenção')
    }
  }

  public getLeafletLayerById(id: string)
  {
    let layer=null;
    this.map.eachLayer( (l:any)=>
    {

      console.log(l)
      if(l && l.id==id)
      {
        layer=l;
      }

    });
    return layer;
  }

  public clearMarker() {
    if(this.markersLayer)
    {
      this.markersLayer.removeFrom(this.map);
      this.markersLayer=null;
    }
    this.clearHighlights();
  }

  public clearHighlights()
  {
    if(this.highlightLayer)
    {
      this.highlightLayer.removeFrom(this.map);
      this.highlightLayer=null;
    }
  }

  public enableEdition(layer: LayerConfig)
  {

    if(this.inEdition==true)
    {
      if(layer!=this.currentEditionLayer)
      {
        this.resetAllControls();
      }
    }
    else
    {
      this.inEdition=true;
    }

    this.currentEditionLayer = layer;

    let geomtype = this.layersConfigService.getLayerGeomType(this.currentEditionLayer);

    if(geomtype==GeomType.Point)
    {
      this.map.pm.addControls({  
        drawControls: true, 
        drawMarker: true,
        drawPolyline: false,
        drawPolygon: false,
        drawRectangle: false,
        drawCircle: false,
        drawCircleMarker: false,
        editControls: true, 
        cutPolygon: false,
        optionsControls: true, 
        customControls: true, 
        oneBlock: true 
        
       });
    } else if(geomtype==GeomType.Line)
    {
      this.map.pm.addControls({  
        drawControls: true, 
        drawMarker: false,
        drawPolyline: true,
        drawPolygon: false,
        drawRectangle: false,
        drawCircle: false,
        drawCircleMarker: false,
        editControls: true, 
        cutPolygon: false,
        optionsControls: true, 
        customControls: true, 
        oneBlock: true 
        
       });
    } else if(geomtype==GeomType.Polygon)
    {
      this.map.pm.addControls({  
        drawControls: true, 
        drawMarker: false,
        drawPolyline: false,
        drawPolygon: true,
        drawRectangle: false,
        drawCircle: false,
        drawCircleMarker: false,
        editControls: true, 
        cutPolygon: false,
        optionsControls: true, 
        customControls: true, 
        oneBlock: true 
        
       });
    }
    
    this.map.pm.Toolbar.changeControlOrder(['drawMarker','drawPolygon','drawPolyline', 'add-geom-by-coords-edition-layer-tool' ,'edit-layer-load-geom-tool','editMode', 'dragMode', 'removalMode', 'save-edition-layer-tool'])

    this.memoryEditionLayer = L.geoJSON().addTo(this.map);
    
    let notification = new AppNotification(AppNotification.ENABLE_LAYER_EDITION_EVENT, this.currentEditionLayer);
    this.notificationService.send(notification);
    
  }
  
  public disableEdition()
  {
      this.map.pm.addControls({  
        drawControls: false, 
        drawMarker: false,
        drawPolyline: false,
        drawPolygon: false,
        drawRectangle: false,
        drawCircle: false,
        drawCircleMarker: false,
        editControls: false, 
        cutPolygon: false,
        optionsControls: false, 
        customControls: false, 
        oneBlock: true 
        
       });

       this.getMap().pm["Toolbar"].removeControls();

       this.getMap().pm["Toolbar"] = new L.PM['Toolbar'](this.getMap());
       
       this.map.pm.disableDraw();

       if(this.currentEditionLayer)
       {
        let notification = new AppNotification(AppNotification.DISABLE_LAYER_EDITION_EVENT, this.currentEditionLayer);
        this.notificationService.send(notification);
       
        if(this.memoryEditionLayer)
        {
          this.memoryEditionLayer.removeFrom(this.map);
          this.memoryEditionLayer=L.geoJSON().addTo(this.map);
        }
      
      }
       this.currentEditionLayer=null;
       this.getMap().off('pm:actionclick');
       this.getMap().off('pm:buttonclick');
  }

  public getCurrentEditionLayer() : LayerConfig
  {
    return this.currentEditionLayer;
  }

  public getMap() : L.Map
  {
    return this.map;
  }

  public getMemoryEditionLayer() : L.GeoJSON
  {
    return this.memoryEditionLayer;
  }

  public clearMemoryEditionLayer()
  {
    this.memoryEditionLayer.clearLayers();
  }

  public getGeomTypeFromLeafletType(shape: string) : GeomType
  {
    if(shape=='Marker')
    {
      return GeomType.Point;
    } else if(shape=='Line')
    {
      return GeomType.Line;
    } else if(shape=='Polygon')
    {
      return GeomType.Polygon;
    }
  }
  public updateLayerOpacity(leafletLayer: any, opacity: number) {

    if(leafletLayer!=undefined)
    leafletLayer.setOpacity(opacity);
  } 
  public redrawLayer(layer: LayerConfig)
  {
    layer.setEnabled(false);
    this.updateOverLayers();

    layer.setEnabled(true);
    this.updateOverLayers();
  }

  public clearMeasure()
  {
    if(this.measureLayer)
    {
      this.map.removeLayer(this.measureLayer);      
    }
  }

  public setLayerFilter(attributeFilter: Property, layer: LayerConfig)
  {
    let cqlFilter="";
    if(attributeFilter)
    {
      cqlFilter = this.featureService.getWMSAttributeCQLFilter(attributeFilter);
    }
    else
    {
      cqlFilter = "";
    }
    
    layer.setCqlfilter(cqlFilter);
    if(layer.isEnabled())
    {
      this.redrawLayer(layer);    
    }    
  }

  public notifyLayerChanged()
  {
    this.layerChangeStatus.next();
  }

  private initProj4()
  {
    proj4['default'].defs("EPSG:31983","+proj=utm +zone=23 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");    
  }

  public findCoord(x:number, y:number, srid: number)
  {

    let currentFeature = null;    

    if(srid==4326)
    {
        currentFeature = new Feature(new Point([x,y]));      

    } else if(srid==31983)
    { 
      let reprojectedCoords = proj4['default']("EPSG:" + srid, 'EPSG:4326',[x,y]);

      currentFeature = new Feature(new Point(reprojectedCoords));

    }

    if(currentFeature)
    {
      let format = new GeoJSON();
    
      let feature = JSON.parse(format.writeFeature(currentFeature));
  
      this.fitFeatures([feature]);
      
    }
    else
    {
      this.toastService.error("Coordenada inválida.", "Erro")
    }   

    
  }

  public reprojectCoordToWGS84(x:number, y:number, srid: number)
  {
    let currentFeature = null;    

    if(srid==4326)
    {
        return {x:x, y:y};

    } else
    { 
      let reprojectedCoords = proj4['default']("EPSG:" + srid, 'EPSG:4326',[x,y]);

      return {x:reprojectedCoords[0], y:reprojectedCoords[1]};

    }    
  }

  public convertDMSToDD(degrees, minutes, seconds, direction) 
  {
    var dd = degrees + minutes/60 + seconds/(60*60);

    if (direction == "S" || direction == "W") {
        dd = dd * -1;
    }
    return dd;
  }

  public enableAnnotation(reference)
  {
      this.map.pm.addControls({  
        drawControls: true, 
        drawMarker: true,
        drawPolyline: true,
        drawPolygon: true,
        drawRectangle: true,
        drawCircle: true,
        drawCircleMarker: true,
        drawText: true,
        editControls: true, 
        cutPolygon: false,
        optionsControls: false, 
        customControls: false, 
        oneBlock: true ,        
        dragMode: true
        
       });
    
    this.map.pm.Toolbar.changeControlOrder(['drawMarker','drawPolygon','drawPolyline', 'drawRectangle','drawCircle', 'drawCircleMarker', 'drawText', 'editMode', 'dragMode', 'removalMode'])

    let localMemoryAnnotationLayer = new L.FeatureGroup().addTo(this.map);  

    this.memoryAnnotationLayer = localMemoryAnnotationLayer;

    let localGetAnnotationLayers = this.getAnnotationLayers;
    
    //Quando adicionada criadas novas geometrias ou adicionadas a partir do banco
    localMemoryAnnotationLayer.on("layeradd",function(event)
    {
      // let geoJSONFeatures = event.layer.toGeoJSON();
      
      // let feature = null;
      
      // if(geoJSONFeatures.features)
      // {
      //   //features collection
        
      //   if(geoJSONFeatures.features.length>0)
      //   {
      //     feature = geoJSONFeatures.features[0];
      //   }
      // }
      // else
      // {
      //   //single feature
      //   feature = geoJSONFeatures;
      // }

      // if(feature !=null        
      //   && feature.properties['name'])
      // {        
      //   let name = feature.properties['name'];

      //   event.layer.bindTooltip(name, {permanent: true, direction: "center", className: "my-labels"});
      // }      

      event.layer.on('pm:edit', function(e)
      {
        if(e.shape!="Marker")
        {  
          if(e.layer.getTooltip())
          {
            let ll = e.layer.getBounds().getCenter();
            e.layer.getTooltip().setLatLng(ll);
            e.layer.getTooltip().update();
          }
        }
      });
      event.layer.on('pm:drag', function(e)
      {
        
          if(e.shape!="Marker")
          {  
            if(e.layer.getTooltip())
            {        
              let ll = e.layer.getBounds().getCenter();
              e.layer.getTooltip().setLatLng(ll);
              e.layer.getTooltip().update();          
            }
          }                  
      });
    });
  
    
    this.getMap().on('pm:create', function(e)
    { 
      let geoJSONFeatures = e.layer.toGeoJSON();
      
      let feature = null;
      
      if(geoJSONFeatures.features)
      {
        //features collection
        
        if(geoJSONFeatures.features.length>0)
        {
          feature = geoJSONFeatures.features[0];
        }
      }
      else
      {
        //single feature
        feature = geoJSONFeatures;
      }

      if(feature && e.layer instanceof L.Circle)
      {
        feature.properties["radius"] = e.layer.getRadius();
      }

      let layer =localGetAnnotationLayers(feature);  

      localMemoryAnnotationLayer.addLayer(layer);
      
      e.layer.remove();
    });
  }

  public getAnnotationLayers(feature)
  {
    let layer = L.geoJSON(feature, {
      pointToLayer: function(feature, latlng) {
          if (feature.properties.radius) 
          {
              return new L.Circle(latlng, feature.properties.radius);
          }
          else
          {
            return new L.Marker(latlng);
          }
          
      },
      onEachFeature: function (feature, l) 
      {
        // Create an input
        var div = L.DomUtil.create('div', 'annotation-edit-div');
        var input = L.DomUtil.create('input', 'annotation-edit-input form-control', div);
        var button = L.DomUtil.create('button', 'annotation-edit-button btn btn-primary', div);

        button['type']="button"; 
        button.innerHTML="Salvar";
        // Set a feature property as value
       
        
        if(feature.properties.name)
        {
          input['value'] = feature.properties.name;
          l.bindTooltip(feature.properties.name, {permanent: true, direction: "center", className: "annotation-edit-label"});
        }                        
        
        // Add a listener to watch for change on input
        L.DomEvent.addListener(button, 'click', function () {
            // Input changed, change property value                            
            feature.properties.name = input['value'];
         
            if(feature.properties.name)
            {
              if(l.getTooltip())
              {
                l.setTooltipContent(feature.properties.name);
                l.openPopup();
              }
              else
              {
                l.bindTooltip(feature.properties.name, {permanent: true, direction: "center", className: "annotation-edit-label"});
              }              
            }
            else
            {
              l.closeTooltip();
            }
            l.closePopup();
        });
        // Bind popup to layer with input
        l.bindPopup(div);
      }
  });
    return layer;
  }

  public getAnnotationLayer()
  {
    return this.memoryAnnotationLayer;
  }

  public disableAnnotation()
  {
      this.map.pm.addControls({  
        drawControls: false, 
        drawMarker: false,
        drawPolyline: false,
        drawPolygon: false,
        drawRectangle: false,
        drawCircle: false,
        drawCircleMarker: false,
        editControls: false, 
        cutPolygon: false,
        optionsControls: false, 
        customControls: false, 
        oneBlock: true,
        dragMode: false 
        
       });  

       this.getMap().pm["Toolbar"].removeControls();
       
       this.getMap().pm["Toolbar"] = new L.PM['Toolbar'](this.getMap());

       this.map.pm.disableDraw();

      if(this.memoryAnnotationLayer)
      {
        this.memoryAnnotationLayer.removeFrom(this.map);
        this.memoryAnnotationLayer=L.geoJSON().addTo(this.map);
      }      
      
      this.getMap().off('pm:actionclick');
      this.getMap().off('pm:buttonclick');
  }

}
