<mat-accordion class="layers-config-accordion">    
  <ng-container>
      <mat-expansion-panel [expanded]="true" class="tool-expasion-panel" >
          <mat-expansion-panel-header>
              <mat-panel-title id="user-header" class="user-col">
                  <mat-icon class="material-icons layer-icon">layers</mat-icon>Configuração de camadas
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="align-column">            
            <div class="outside-div">
              <div class="layers-div">
                <div cdkDropList id="overlayer-list" [cdkDropListData]="overlayers" class="overlayer-list" (cdkDropListDropped)="dropOverLayer($event)">
                  <div class="overlayer-placeholder" *cdkDragPlaceholder></div>
                  <ng-container *ngFor="let overlayer of overlayers">
                    <div class="overlayer-box"  [ngClass]="{'overlayer-box-selected': overlayer === currentSelectedLayerConfig}" cdkDrag>
                        <div class="layer-row" cdkDragHandle>
                            <div class="layerNameIcon" >
                                <mat-icon class="material-icons layer-icon">layers</mat-icon>
                                <div class="layer-text">
                                    <h3> {{overlayer.getName()}} </h3>
                                    <p>
                                        <span> {{overlayer.title}} </span><mat-icon class="overlayer-description-icon" [matTooltip]="overlayer.getDescription()">help</mat-icon>
                                    </p>
                                </div>
                            </div>  
                            <div class="layer-switch">
                                <mat-slide-toggle [checked]="overlayer.isEnabled()" [name]="overlayer.getName()"
                                 ([ngModel])="overlayer.isEnabled()" 
                                (change)="onChangeOverlayer(overlayer.getId(), $event.checked)" color="primary"></mat-slide-toggle>
                            </div>   
                        </div>
                        <div class="layer-config-div">
                          <mat-icon class="material-icons cdk-drag-disabled" title="Selecionar camada para configuração" (click)="selectLayer(overlayer)" cdkDragDisabled>settings</mat-icon>
                        </div>
                      </div>
                    </ng-container>
                </div>
              </div>
              <div class="layer-data-div">
                
                <mat-card class="align-row header-card">
                  <div class="current-layer-title">                    
                    <mat-icon class="material-icons layer-icon">layers</mat-icon><h3>{{ currentSelectedLayerConfig?currentSelectedLayerConfig.getName():"Selecione uma camada"}}</h3> 
                  </div>
                  <div class="tool-bar">
                    <mat-icon class="material-icons action-icon upload-layer" title="Importar nova camada" (click)="importLayer()">upload_file</mat-icon>
                    <mat-icon class="material-icons action-icon" title="Adicionar camada de servidor WMS" (click)="newLayer()">add</mat-icon>
                    <mat-icon class="material-icons action-icon" title="Apagar camada selecionada" *ngIf="currentSelectedLayerConfig" (click)="deleteLayer()">delete</mat-icon>
                  </div>                  
                </mat-card>                  
               
              <div class="align-column" *ngIf="currentSelectedLayerConfig">                

                <mat-expansion-panel [expanded]="true" class="layer-exp-panel">
                  <mat-expansion-panel-header>
                      <mat-panel-title>
                          <mat-icon class="material-icons">info</mat-icon>Informações da Camada
                      </mat-panel-title>
                      <mat-panel-description>
                      </mat-panel-description>
                  </mat-expansion-panel-header>
  
                  <div class="align-row">                  
                    <mat-form-field appearance="fill">
                      <mat-label>Nome:</mat-label>
                      <input matInput type="text" class="property-value"  [(ngModel)]="currentSelectedLayerConfig.name" required>
                    </mat-form-field>   
                    <mat-form-field appearance="fill">
                      <mat-label>Titulo: </mat-label>
                      <input matInput type="text" class="property-value"  [(ngModel)]="currentSelectedLayerConfig.title">
                    </mat-form-field>   
                  </div>
                  <div class="align-row">                  
                    <mat-form-field appearance="fill">
                      <mat-label>Descrição: </mat-label>
                      <input matInput type="text" class="property-value"  [(ngModel)]="currentSelectedLayerConfig.description">
                    </mat-form-field>   
                  </div>
                  
                 
  
                  </mat-expansion-panel>
  
                  <mat-expansion-panel [expanded]="true"  class="layer-exp-panel">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon class="material-icons">public</mat-icon>Informações de Conexão WMS
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="align-row">                  
                      <mat-form-field appearance="fill">
                        <mat-label>Fonte (URL): </mat-label>
                        <input matInput type="text" class="property-value" [(ngModel)]="currentSelectedLayerConfig.source">
                      </mat-form-field>   
                    </div>
                    <div class="align-row">                  
                      <mat-form-field appearance="fill">
                        <mat-label>Caminho: </mat-label>
                        <input matInput type="text" class="property-value" [(ngModel)]="currentSelectedLayerConfig.path" required>
                      </mat-form-field>   
                    </div>
                    <div class="align-row">                                        
                      <mat-form-field appearance="fill">
                        <mat-label>Workspace: </mat-label>
                        <input matInput type="text" class="property-value" [(ngModel)]="currentSelectedLayerConfig.workspace" required>
                      </mat-form-field>   
                      <mat-form-field appearance="fill">
                        <mat-label>Identificador: </mat-label>
                        <input matInput type="text" class="property-value" [(ngModel)]="currentSelectedLayerConfig.id" required>
                      </mat-form-field>   
                    </div>
                   
                    
    
                    </mat-expansion-panel>
  
  
                    <mat-expansion-panel [expanded]="true"  class="layer-exp-panel">
                      <mat-expansion-panel-header>
                          <mat-panel-title>
                              <mat-icon class="material-icons">construction</mat-icon>Ferramentas Habilitadas
                          </mat-panel-title>
                          <mat-panel-description>
                          </mat-panel-description>
                      </mat-expansion-panel-header>
                      <mat-selection-list #layertools (selectionChange)="onToolSelection($event)" class="layer-tools-selection">
                        <mat-list-option *ngFor="let tool of allTools" class="tool-list-option" [value]="tool" [selected]="tool.enabled">
                            <div class="tool-div">
                              {{tool.toolTitle}} 
                            </div>
                                                   
                        </mat-list-option>
                      </mat-selection-list>
                      </mat-expansion-panel>
  
  
                      <mat-expansion-panel [expanded]="true" class="layer-exp-panel">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon class="material-icons">manage_account</mat-icon>Permissões
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
  
                        </mat-expansion-panel-header>
                        <mat-card>
                          <div class="align-row">                  
                            <div>                          
                              <mat-checkbox class="property-value checkbox-public" [(ngModel)]="currentSelectedLayerConfig.permission.public">Pública</mat-checkbox>          
                            </div>
                          </div>
                          <div class="align-column">                                              
                            <mat-label>Grupos de Usuários: </mat-label>
                            <div class="align-column">
                              <mat-card *ngFor="let userGroupPermission of currentLayerUserGroupsPermissions" class="user-group-card">
                                <div class="align-row">
                                  <div class="user-group-name">
                                    {{userGroupPermission.userGroup.name}}
                                  </div>
                                  <div class="user-group-permission">                                      
                                    <div>                                  
                                      <mat-checkbox class="property-value" [(ngModel)]="userGroupPermission.read" (change)="onGrupoPermissionChange(userGroupPermission)">Leitura</mat-checkbox>                    
                                    </div>
                                    <div>                                  
                                      <mat-checkbox class="property-value" [(ngModel)]="userGroupPermission.write" (change)="onGrupoPermissionChange(userGroupPermission)">Escrita</mat-checkbox>                   
                                    </div>
                                  </div>
                                </div>
                              </mat-card>
                            </div>                                                         
                        </div>
                        </mat-card>
                        
                      </mat-expansion-panel>
  
                        <mat-expansion-panel [expanded]="true" class="layer-exp-panel">
                          <mat-expansion-panel-header>
                              <mat-panel-title>
                                  <mat-icon class="material-icons">settings_suggest</mat-icon>Configurações Avançadas
                              </mat-panel-title>
                              <mat-panel-description>
                              </mat-panel-description>
                          </mat-expansion-panel-header>
                          <div class="align-row">
                              <json-editor [options]="layerAdvancedConfigEditorOptions" [data]="layerAdvancedConfigJson" class="layer-advanced-config" style="width: 100%; height: 600px;"></json-editor>
                          </div>
                          </mat-expansion-panel>
                  </div>              
              </div>
            </div>
            <div class="align-row buttons-div">

              <button type="button" (click)="close()" mat-raised-button color="primary" title="Fechar">
                <span class="material-icons">done</span> Fechar
              </button>    
              <button type="button" (click)="confirmSave()" mat-raised-button color="primary" title="Salvar todas as modificações para todas as novas sessões">
                <span class="material-icons">save</span> Salvar
              </button>           
        
          </div>     
          </div>          
      </mat-expansion-panel>
  </ng-container>
</mat-accordion>


