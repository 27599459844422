import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AuthenticationComponent } from './authentication/authentication.component';
import { NotificationService } from './service/notification.service';
import { AppNotification } from './map/vo/notification';
import { AuthenticationService } from './service/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent 
{

  private authDialog: MatDialogRef<AuthenticationComponent>;
  private parentDialog: MatDialog;
  notificationSubscrition: Subscription;
  
  constructor(private dialog: MatDialog, private notificationService: NotificationService, private authenticationService: AuthenticationService)
  {
    this.parentDialog=dialog;
    this.authenticationService.isAuthenticated().then((authenticated: any) =>{
      if(!authenticated)
      {
        this.showAuthentication();
      }
      this.authenticationService.authenticationChanged();
    });
    

    this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
      if (notification.event==AppNotification.AUTHENTICATION_CHANGED) 
      {
        if(notification.object && notification.object.authenticated)
        {
          if(this.authDialog)
          {
            this.authDialog.close();
            this.authDialog=null;
          }
        }
        else
        {
          if(this.authDialog==null)
          {
            this.showAuthentication();
          }
        }        
      } else if (notification.event==AppNotification.SHOW_AUTHENTICATION_DIALOG) 
      {
        this.showAuthentication();
      }
    });
  }
  

  showAuthentication()
  {
    this.authDialog = this.parentDialog.open(AuthenticationComponent, {
      height: '100%',
      width: '100%',
      panelClass: 'authentication-dialog'
    });
 
  }
}
