import { UserGroup } from './user-group';

export class AppUser
 {
    id: number;
    name: string;
    userGroup: UserGroup;

    constructor(id: number, name: string, userGroup: UserGroup)
    {
        this.id=id;
        this.name=name;
        this.userGroup=userGroup;
    }

}
