export class Annotation
 {
    private id: number;
    private ownerUserId: number;
    private groupId: number;
    private title: string;
    private description: string;
    private data: string; 

    constructor()
    {
    }   

    public getId(): number {
        return this.id;
    }

    public setId(id: number): void {
        this.id = id;
    }

    public getOwnerUserId(): number {
        return this.ownerUserId;
    }

    public setOwnerUserId(ownerUserId: number): void {
        this.ownerUserId = ownerUserId;
    }

    public getGroupId(): number {
        return this.groupId;
    }

    public setGroupId(groupId: number): void {
        this.groupId = groupId;
    }

    public getTitle(): string {
        return this.title;
    }

    public setTitle(title: string): void {
        this.title = title;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(description: string): void {
        this.description = description;
    }

    public getData(): string {
        return this.data;
    }

    public setData(data: string): void {
        this.data = data;
    }
}
