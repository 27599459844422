import { Injectable } from '@angular/core';
import { Cadastroimobiliario } from '@funcate/sigweb-cti-api';
import { CanvasService } from '../map/canvas/canvas.service';
import { LayersConfigService } from '../map/layers/layers.config.service';
import { Inscricao } from '../map/vo/inscricao';
import { Property } from '../map/vo/property';
import { BackendService } from './backend.service';
import { FeatureService } from './feature.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class ImovelService {

  constructor(private canvasService: CanvasService,
    private layerService: LayersConfigService,
    private featureService: FeatureService,
    private toastService : ToastService, 
    private backendService: BackendService) { }
  
  private getLoteFilter(inscricao: Inscricao) : Array<Property>
  {
    let attributeFilterList: Array<Property> = [];
    
    attributeFilterList.push(new Property('setor', inscricao.setor));
    attributeFilterList.push(new Property('quadra', inscricao.quadra));
    attributeFilterList.push(new Property('logradouro', inscricao.logradouro));
    attributeFilterList.push(new Property('lote', inscricao.lote));

    if(inscricao.sublote)
    {
      attributeFilterList.push(new Property('sublote', inscricao.sublote));
    }

    return attributeFilterList;
  }
  public getImovelData(inscricao: Inscricao) : Promise<Cadastroimobiliario> 
  {
    return this.backendService.getCadImob(inscricao.getInscricaoCompleta());      
  }

  public fitFeature(inscricaoLote: string)
  {
    let inscricao = new Inscricao(inscricaoLote);

    this.getImovelData(inscricao).then((cadImob: Cadastroimobiliario)=>
    {
      this.toastService.clear();
      if(cadImob && cadImob['situacao_cadastral'].ativo=='N')
      {        
        this.toastService.warning("O imóvel consultado está inativo.","Aviso");        
      }

      let quicksearchLayerConfig = this.layerService.getQuicksearchLayer();

      let attributeFilterList = this.getLoteFilter(inscricao);
      let attributesFilterList: Array<Array<Property>>=[];
      attributesFilterList.push(attributeFilterList);
  
      if(quicksearchLayerConfig)
      {
        this.layerService.enableLayer(quicksearchLayerConfig);
        
          //Using EPSG: 4326, Leaflet expects GeoJSON with this SRS
        this.featureService.getFeatures(quicksearchLayerConfig, "", attributesFilterList).then(features => 
        {
          this.canvasService.fitFeatures(features); 
  
          this.highlightParentObjects(features);
        });    
      }  
        
    }).catch((error)=>
    {      
      this.toastService.error("Não foi possível buscar as informações do cadastro imobiliário.","Problema na consulta");
    });



  }

  /**
   * Destaca as geometrias de camadas parentes. São configuradas a partir da applicationconfigs:highlight_parent_layers 
   * 
   */
  public highlightParentObjects(features: any)
  {
    let quicksearchLayerConfig = this.layerService.getQuicksearchLayer();

    let layerHighligthParents = this.layerService.getHighlightParents(quicksearchLayerConfig);

    if(layerHighligthParents && layerHighligthParents.length>0)
    {
      layerHighligthParents.forEach(highlightLayer => 
      {
        let parentLayerId=highlightLayer.parent_layerid;
        let parentLayerField=highlightLayer.parent_field;
        let relationField=highlightLayer.relation_field;
        let style=highlightLayer.style;

        if(parentLayerId && parentLayerField && relationField && features && features.length>0)
        {
          let relationValue=features[0].properties[relationField];

          if(relationValue)
          {
            let attributeFilterList: Array<Property> = [];
    
            attributeFilterList.push(new Property(parentLayerField, relationValue));
  
            let attributesFilterList: Array<Array<Property>>=[];
  
            attributesFilterList.push(attributeFilterList);
  
            let parentLayerConfig = this.layerService.getOverLayerById(parentLayerId);
  
            this.featureService.getFeatures(parentLayerConfig, "", attributesFilterList).then(features => 
              {
                if(features && features.length>0)
                {
                  this.canvasService.highlightFeatures(features, style);    
                }
                else
                {
                  this.toastService.warning("Não foi possível encontrar um elemento geográfico associado.", "Atenção")
                }                
              });
          }

        }

      });
    }
  } 

  public fitFeatures(inscricoesLote: Array<Inscricao>)
  {
    let quicksearchLayerConfig = this.layerService.getQuicksearchLayer();    

    if(quicksearchLayerConfig)
    {
      let attributesFilterList: Array<Array<Property>>=[];
      inscricoesLote.forEach((inscricao)=>{
        let attributeFilterList = this.getLoteFilter(inscricao);
        attributesFilterList.push(attributeFilterList);
      });

      this.featureService.getFeatures(quicksearchLayerConfig, "", attributesFilterList).then(features => 
      {
        this.canvasService.fitFeatures(features);    

        this.highlightParentObjects(features);
      });

    }
  }
}
