<mat-accordion>    
    <ng-container >
        <mat-expansion-panel [expanded]="true" class="">
            <mat-expansion-panel-header>
                <mat-panel-title id="user-header" class="user-col">
                    <mat-icon class="material-icons">person</mat-icon> Adicionar/Editar Membro
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-card>
                <mat-card-header>
                    <mat-card-title> {{tipoMembro}} </mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table class="example-full-width edit-membro-cadastro-social" cellspacing="0" id="edit-membro-cadastro-social">
                        <tr>
                            <td colspan="5">
                                <mat-form-field class="example-full-width">
                                    <input hidden=true matInput>
                                    <mat-label>Tipo de Membro do Casatro Social:</mat-label>
                                    <mat-radio-group class="example-radio-group" [(ngModel)]="tipoMembro" (change)="tipoMembroChanged()" [disabled]="readOnly">
                                        <div *ngFor="let item of optionsTiposMembro" class="edit-tipo-membro">
                                            <mat-radio-button class="example-radio-button"  [value]="item.value" [disabled]="isMemberTypeAvailable(item.value)">
                                                {{item.value}}
                                                </mat-radio-button>
                                        </div>                                        
                                    </mat-radio-group>                                    
                                </mat-form-field>     
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <mat-form-field class="example-full-width">
                                    <mat-label>Nome:</mat-label>
                                    <input matInput [(ngModel)]="currentMember.nome" class="property-value"  [readonly]="readOnly">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Data de Nascimento:</mat-label>
                                    <input matInput  [matDatepicker]="data_nascimento_picker" [max]="maxDate" [(ngModel)]="currentMember.data_nascimento" class="property-value"  [disabled]="readOnly">
                                    <mat-datepicker-toggle matSuffix [for]="data_nascimento_picker"></mat-datepicker-toggle>
                                    <mat-datepicker #data_nascimento_picker></mat-datepicker>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Naturalidade:</mat-label>
                                    <input matInput [(ngModel)]="currentMember.naturalidade" class="property-value"  [readonly]="readOnly">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Sexo:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="currentMember.sexo"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsSexoMembro" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Estado Civil:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="currentMember.estado_civil"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsEstadoCivilMembro" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select>   
                                </mat-form-field>
                            </td>
                        </tr>      
                        <tr>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>CPF/CNPJ:</mat-label>
                                    <input matInput [(ngModel)]="currentMember.cpf" class="property-value"  [readonly]="readOnly" mask="000.000.000-00||00.000.000/0000-00">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>RG:</mat-label>
                                    <input matInput [(ngModel)]="currentMember.rg" class="property-value"  [readonly]="readOnly">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Data de Expedição (RG):</mat-label>
                                    <input matInput [matDatepicker]="rg_data_exp_picker" [max]="maxDate" [(ngModel)]="currentMember.rg_data_exp" class="property-value" [disabled]="readOnly">                                    
                                    <mat-datepicker-toggle matSuffix [for]="rg_data_exp_picker"></mat-datepicker-toggle>
                                    <mat-datepicker #rg_data_exp_picker [disabled]="readOnly"></mat-datepicker>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Órgão Expedido/UF (RG):</mat-label>
                                    <input matInput [(ngModel)]="currentMember.orgao_expedidor" class="property-value"  [readonly]="readOnly">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Escolaridade:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="currentMember.escolaridade"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsEscolaridadeMembro" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select> 
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Profissão:</mat-label>
                                    <input matInput [(ngModel)]="currentMember.profissao" class="property-value"  [readonly]="readOnly">
                                </mat-form-field>
                            </td>
                        </tr>  
                        <tr>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Telefone:</mat-label>
                                    <input matInput [(ngModel)]="currentMember.telefone" class="property-value"  [readonly]="readOnly" mask="(00) 0000-0000">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Celular:</mat-label>
                                    <input matInput [(ngModel)]="currentMember.celular" class="property-value"  [readonly]="readOnly" mask="(00) 00000-0000">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Telefone Recado:</mat-label>
                                    <input matInput [(ngModel)]="currentMember.telefone_recado" class="property-value"  [readonly]="readOnly" mask="(00) 00000-0000||(00) 0000-0000">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>E-mail:</mat-label>
                                    <input matInput [(ngModel)]="currentMember.email" class="property-value"  [readonly]="readOnly" type="email" email="true">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Renda Invidual:</mat-label>
                                    <input matInput [(ngModel)]="currentMember.renda_individual" class="property-value"  [readonly]="readOnly" [allowNegativeNumbers]="false" prefix="R$ " mask="separator.2" mask="separator" [thousandSeparator]="'.'" [decimalMarker]="','" >
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-checkbox [(ngModel)]="currentMember.possui_outro_imovel"  [disabled]="readOnly">Possui Outro Imóvel</mat-checkbox>                                       
                            </td>
                        </tr> 
                        <tr>
                            <td>
                                <mat-checkbox [(ngModel)]="currentMember.ja_atendido_programa_his"  [disabled]="readOnly">Já Foi Atendido Por Programa HIS</mat-checkbox>                                       
                            </td>
                        </tr>
                        <tr>
                            <td colspan="3" class="edit-settlement-property-checkbox-member-options-td">
                                <mat-form-field class="example-full-width">
                                    <mat-label class="edit-settlement-property-checkbox-member-option-label">Fonte de Renda:</mat-label>
                                    <div class="edit-settlement-property-checkbox-member-form-field-div">
                                        <input hidden=true matInput>
                                        
                                        <div *ngFor="let item of optionsFonteRendaMembro" class="edit-settlement-property-checkbox-member-option-div">
                                            <mat-checkbox 
                                            [checked]="isSelected(currentMember.getFontesRendaAsString(), item.value)" 
                                            (change)="currentMember.setFontesRendaAsString(item.value, $event.checked)"  [disabled]="readOnly">{{item.value}}</mat-checkbox>    
                                        </div>                                
                                    </div>                                    
                                </mat-form-field>                                
                            </td>
                            <td colspan="3" class="edit-settlement-property-checkbox-member-options-td" >
                                <mat-form-field class="example-full-width" *ngIf="currentMember.possuidor==true">
                                    <mat-label class="edit-settlement-property-checkbox-member-option-label">Programa Social:</mat-label>
                                    <div class="edit-settlement-property-checkbox-member-form-field-div">
                                        <input hidden=true matInput>
                                        
                                        <div *ngFor="let item of optionsProgramaSocialMembro" class="edit-settlement-property-checkbox-member-option-div">
                                            <mat-checkbox 
                                            [checked]="isSelected(currentMember.getProgramasSociaisAsString(), item.value)" 
                                            (change)="currentMember.setProgramasSociaisAsString(item.value, $event.checked)" [disabled]="readOnly">{{item.value}}</mat-checkbox>    
                                        </div>                                
                                    </div>                                    
                                </mat-form-field>                                
                            </td>
                        </tr>   
                        <tr>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>Pessoa Com Deficiência:</mat-label>
                                    <mat-select  class="property-value" [(ngModel)]="currentMember.deficiencia"  [disabled]="readOnly">
                                        <mat-option *ngFor="let item of optionsDeficienciaMembro" [value]="item.value" >
                                            {{item.value}}
                                        </mat-option>
                                    </mat-select> 
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-full-width">
                                    <mat-label>CID da PCD:</mat-label>
                                    <input matInput [(ngModel)]="currentMember.cidpcd" class="property-value"  [readonly]="readOnly">
                                </mat-form-field>
                            </td>
                        </tr>
                    </table>             
                </mat-card-content>
            </mat-card>
            <mat-card *ngIf="parent.hasEditionPermition() && readOnly==false">
                <mat-card-content>
                    <div class="align-row buttons-div">
            
                        <button type="button" (click)="close()" mat-raised-button color="primary" title="Fechar">
                          <span class="material-icons">close</span> Fechar
                        </button>    
                        <button type="button" (click)="confirmSave()" mat-raised-button color="primary" title="Salvar Membro">
                          <span class="material-icons">save</span> Salvar
                        </button>           
                    
                    </div> 
                </mat-card-content>
            </mat-card>
        </mat-expansion-panel>
    </ng-container>
  </mat-accordion>  
 