import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Subscription } from 'rxjs';
import { LayerConfig } from '../../layers/layers.config';
import { ToolsService } from '../tools.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { ToastService } from 'src/app/service/toast.service';
import { SearchCoordTool } from './search-coord-tool';

@Component({
  selector: 'search-coord-tool',
  templateUrl: './search-coord-tool.component.html',
  styleUrls: ['./search-coord-tool.component.css']
})


export class SearchCoordComponent implements AfterViewInit
{
  private tool:SearchCoordTool;
  private subscriptionTool:Subscription;
  coordsType: any[];
  searchCoordsType: number;
  utmCoordsX: string;
  utmCoordsY: string;
  geoCoordsDecimalX: any;
  geoCoordsDecimalY: any;
  geoCoordsDegreesX: any;
  geoCoordsDegreesY: any;  
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    private toolsService: ToolsService, private canvasService: CanvasService, private toastService: ToastService, private dialog: MatDialog) 
    {
      this.subscriptionTool=this.toolsService.toolsObservable.subscribe(
      (tool:SearchCoordTool) => {
        this.tool=tool;
        this.update();
      },()=> {
      }
    );
   }

  ngOnInit(): void
  {
    this.geoCoordsDegreesX = new Object();
    this.geoCoordsDegreesY = new Object();
  }

  ngAfterViewInit() 
  {
    this.searchCoordsType=1;
  }  

  private update() 
  {
   this.initCoordsType();
  }

  private initCoordsType()
  {
    this.coordsType = [];
    this.coordsType.push(
    {
      id:1,
      name: "Coordenada UTM Zona 23S - SIRGAS2000 (metros)",
      srid: 31983
    });
    this.coordsType.push(
    {
      id:2,
      name: "Coordenada Geográficas (Grau Decimal)",
      srid: 4326
      
    });
    this.coordsType.push(
    {
      id:3,
      name: "Coordenada Geográficas (Grau Minuto Segundo)",
      srid: 4326
    });      
  }

  public findCoord()
  {
    if(this.searchCoordsType==1)
    {
        this.canvasService.findCoord(Number.parseFloat(this.utmCoordsX), Number.parseFloat(this.utmCoordsY), this.coordsType[0].srid);
    } else if(this.searchCoordsType==2)
    {
      this.canvasService.findCoord(Number.parseFloat(this.geoCoordsDecimalX),Number.parseFloat(this.geoCoordsDecimalY), this.coordsType[1].srid);
    } else if(this.searchCoordsType==3)
    {      

      let ddX = this.canvasService.convertDMSToDD(Number.parseInt(this.geoCoordsDegreesX.degrees),Number.parseInt(this.geoCoordsDegreesX.minutes), Number.parseFloat(this.geoCoordsDegreesX.seconds), this.geoCoordsDegreesX.direction);
      let ddY = this.canvasService.convertDMSToDD(Number.parseInt(this.geoCoordsDegreesY.degrees),Number.parseInt(this.geoCoordsDegreesY.minutes), Number.parseFloat(this.geoCoordsDegreesY.seconds), this.geoCoordsDegreesY.direction);

      this.canvasService.findCoord(Number.parseFloat(ddX),Number.parseFloat(ddY), this.coordsType[2].srid);
    }
  }

  onPaste(event)
  {
    if(event.target &&
      (event.target.id='utm-x') ||
      (event.target.id='utm-y') ||
      (event.target.id='lat-decimal-x') ||
      (event.target.id='lat-decimal-y'))      
      {
        let clipboardData = event.clipboardData;

        if(clipboardData)
        {
          let clipboardText = clipboardData.getData("text")
          if(clipboardText)
          {
            if(clipboardText.includes(','))
            {
              let coords = clipboardText.split('\n');              
              coords.forEach(coord => {
                if(coord.includes(','))
                {
                  let c = coord.split(',')
                  if(c.length==2)
                  {

                    if(this.searchCoordsType==1)
                    {
                      this.utmCoordsX = c[0];
                      this.utmCoordsY = c[1];
                    }
                    else if(this.searchCoordsType==2)
                    {
                      this.geoCoordsDecimalX = c[0];
                      this.geoCoordsDecimalY = c[1];
                    }

                    //Prevent the event to paste on field after set.
                    event.preventDefault();
                    //Set only the first row                    
                    return;                  
                    
                  }                  
                }
              });                            
            }
          }
        }
        

        
      }
      
  }  
  
}
