import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Enderecos } from '@funcate/sigweb-cti-api';
import { Observable, Subscription } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { BackendService } from 'src/app/service/backend.service';
import { ToastService } from 'src/app/service/toast.service';
import { LocalStorageService } from 'src/app/service/local-storage.service';
import { ImovelService } from 'src/app/service/imovel.service';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/service/notification.service';
import { AppNotification } from '../../vo/notification';
import { QuickSearchEnderecos } from '../../vo/quicksearch-enderecos';
import { Inscricao } from '../../vo/inscricao';
import { GeneralService } from 'src/app/service/general.service';


@Component({
  selector: 'app-quicksearch-tool',
  templateUrl: './quicksearch-tool.component.html',
  styleUrls: ['./quicksearch-tool.component.css']
})

export class QuicksearchToolComponent implements OnInit {
  enderecosControl: FormControl = new FormControl();
  numerosControl: FormControl = new FormControl();
  enderecosOptions: string[] = [];
  enderecosFilteredOptions: Observable<string[]>;
  enderecosStorageKey: string = "enderecos_"+environment.version;

  numerosOptions: string[] = [];
  numerosInscricoes: Object[];
  numerosFilteredOptions: Observable<string[]>;

  isInscricaoEnabled: boolean = false;
  inscricaoControl: FormGroup = new FormGroup(
    {
      setor: new FormControl(),
      quadra: new FormControl(),
      logradouro: new FormControl(),
      lote: new FormControl(),
      sublote: new FormControl()
    }
  );
  notificationSubscrition: Subscription;

  setorLength: number = 2;
  quadraLength: number = 5;
  logradouroLength: number = 4;
  loteLength: number = 5;
  subloteLength: number = 3;
  
  constructor(private backendService: BackendService,
    private localStorageService: LocalStorageService,
    private toastService: ToastService,
    private imovelService: ImovelService,
    private generalService: GeneralService,
    private notificationService: NotificationService) 
  {
    this.notificationSubscrition = this.notificationService.onNotification().subscribe(notification => {
      if (notification.event==AppNotification.AUTHENTICATION_CHANGED) 
      {
        if(notification.object && notification.object.authenticated)
        {
          this.loadEnderecos(notification.object.token);
        }        
      } 
    });
  }


  onPaste(event)
  {
    if(event.target &&
      (event.target.id='quicksearch-setor') ||
      (event.target.id='quicksearch-quadra') ||
      (event.target.id='quicksearch-logradouro') ||
      (event.target.id='quicksearch-lote') ||
      (event.target.id='quicksearch-sublote'))
      {
        let clipboardData = event.clipboardData;

        if(clipboardData)
        {
          let clipboardText = clipboardData.getData("text")
          if(clipboardText)
          {
            //Tirando os pontos se existirem
            clipboardText=clipboardText.replaceAll(".", "");
            
            if(clipboardText.length==19)
            {
              let inscricao: Inscricao = new Inscricao(clipboardText);

              this.inscricaoControl.get('setor').setValue(inscricao.setor);
              this.inscricaoControl.get('quadra').setValue(inscricao.quadra);
              this.inscricaoControl.get('logradouro').setValue(inscricao.logradouro);
              this.inscricaoControl.get('lote').setValue(inscricao.lote);
              this.inscricaoControl.get('sublote').setValue(inscricao.sublote);

              this.submitInscricao();
            }
          }
        }
      }
  }

  ngOnInit(): void 
  {
    this.inscricaoControl.get('setor').valueChanges.subscribe(value => {
     value = this.generalService.removeNAN(value);
     let v = this.generalService.trimSize(this.setorLength, value);
     this.inscricaoControl.get('setor').setValue(v, { emitEvent: false} );
   });
   this.inscricaoControl.get('quadra').valueChanges.subscribe(value => {
    value = this.generalService.removeNAN(value);
    let v = this.generalService.trimSize(this.quadraLength, value);
    this.inscricaoControl.get('quadra').setValue(v, { emitEvent: false} );
  });
  this.inscricaoControl.get('logradouro').valueChanges.subscribe(value => {
    value = this.generalService.removeNAN(value);
    let v = this.generalService.trimSize(this.logradouroLength, value);
    this.inscricaoControl.get('logradouro').setValue(v, { emitEvent: false} );
  });
  this.inscricaoControl.get('lote').valueChanges.subscribe(value => {
    value = this.generalService.removeNAN(value);
    let v = this.generalService.trimSize(this.loteLength, value);
    this.inscricaoControl.get('lote').setValue(v, { emitEvent: false} );
  });
  this.inscricaoControl.get('sublote').valueChanges.subscribe(value => {
    value = this.generalService.removeNAN(value);
    let v = this.generalService.trimSize(this.subloteLength, value);
    this.inscricaoControl.get('sublote').setValue(v, { emitEvent: false} );
  });

  }

  private loadEnderecos(bearer: string)
  {
    this.localStorageService.getValue(this.enderecosStorageKey).toPromise().then((qsEnderecosJSON: string) => {
      let qsEnderecos : any=null;
      if(qsEnderecosJSON)
      {
        qsEnderecos = JSON.parse(qsEnderecosJSON);
      }

      if(qsEnderecos && qsEnderecos.value.bearer == bearer)
      {
        this.publishEnderecos(qsEnderecos.value);
      }
      else
      {
        this.backendService.getEnderecos().then((enderecos: any) => 
        {
          let qsEnderecos = this.addTokenToEndereco(enderecos, bearer);
          this.publishEnderecos(qsEnderecos);
          this.localStorageService.setValue(this.enderecosStorageKey ,qsEnderecos).subscribe(() => {
            console.log("Loaded endereços");
          });
        });
      }
    
    });
  }
  private addTokenToEndereco(enderecos: Enderecos, bearer: string) : QuickSearchEnderecos
  {
    let qsEnderecos : QuickSearchEnderecos = new QuickSearchEnderecos();
    qsEnderecos.bearer = bearer;
    qsEnderecos.enderecos = enderecos;
    return qsEnderecos;
  }

  private publishEnderecos(qsEnderecos: QuickSearchEnderecos)
  {
    this.enderecosOptions = [];
    qsEnderecos.enderecos.forEach(endereco => {
      if(endereco['logradouro'])
      {
        this.enderecosOptions.push(endereco['logradouro']);
      }
     });

      this.enderecosFilteredOptions = this.enderecosControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterLogradouros(value))
      );
  }

  private publishNumeros(numeros: [])
  {
    this.numerosOptions = [];
    numeros.forEach(numero => {
      if(numero)
      {
        this.numerosOptions.push(numero);
      }
     });

      this.numerosFilteredOptions = this.numerosControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterNumeros(value))
      );
  }

  private filterLogradouros(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.enderecosOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  private filterNumeros(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.numerosOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  public enderecoSelected(enderecoSelecionado: string)
  {
    this.localStorageService.getValue(this.enderecosStorageKey).toPromise().then((qsEnderecos: string) => {
      let endereco = JSON.parse(qsEnderecos).value.enderecos.find(endereco => endereco.logradouro == enderecoSelecionado);
      let numeros: [];
      if(endereco)
      {
        numeros = endereco.numeros.split(";");
      }
      this.publishNumeros(numeros);
      this.publishNumerosInscricoes(endereco);

    });
  }
  private publishNumerosInscricoes(endereco: any)
  {
    let numeros = endereco.numeros.split(";");
    let inscricoes = endereco.inscricoes.split(";");
    this.numerosInscricoes = [] 
    if(numeros.length==inscricoes.length)
    {
      
      for (let i = 0; i < numeros.length; i++) {
        const numero = numeros[i];
        const inscricao = inscricoes[i];
        this.numerosInscricoes.push(
            {
              'numero': numero,
              'inscricao': inscricao
            }
          );
      }
     
    }
    
  }
  public numeroSelected(numeroSelecionado: string)
  {

    this.toastService.clear();

    this.toastService.info("Localizando...", "");

    this.numerosInscricoes.forEach(numeroIncricao => {
      if(numeroIncricao['numero']==numeroSelecionado)
      {
        let inscricao = numeroIncricao['inscricao'];
        console.log(inscricao);
        
        this.imovelService.fitFeature(inscricao)

        return;
      }
    });
  }

  public validateLength(field: string, value: any)
  {

    if(value.target.value)
    {
      let v: any;
      switch (field) {
        case 'setor':
          v = this.fillZero(2, value.target.value);
          this.inscricaoControl.get('setor').setValue(v, { emitEvent: false} );
          break;
        case 'quadra':
          v = this.fillZero(5, value.target.value);
          this.inscricaoControl.get('quadra').setValue(v, { emitEvent: false} );
          break;
        case 'logradouro':
          v = this.fillZero(4, value.target.value);
          this.inscricaoControl.get('logradouro').setValue(v, { emitEvent: false} );
          break;
        case 'lote':
          v = this.fillZero(5, value.target.value);
          this.inscricaoControl.get('lote').setValue(v, { emitEvent: false} );
          break;
        case 'sublote':
          v = this.fillZero(3, value.target.value);
          this.inscricaoControl.get('sublote').setValue(v, { emitEvent: false} );
          break;

        default:
          break;
      }
    }
  }
  /**
   * Adiciona zero na frente de cada numero da inscrição de maneira recursiva.
   * @param length quantidade máxima de digitos 
   * @param value valor atual
   */
  private fillZero(length: number, value: string)
  {
    if(value.length!=length)
    {
      value="0"+value;
      return this.fillZero(length,value);
    }
    return value;

  }

  public submitInscricao()
  {
    let setor = this.inscricaoControl.get('setor').value;
    let quadra = this.inscricaoControl.get('quadra').value;
    let logradouro = this.inscricaoControl.get('logradouro').value;
    let lote = this.inscricaoControl.get('lote').value;
    let sublote = this.inscricaoControl.get('sublote').value;

    this.toastService.clear();
    
    if(setor==null || setor.trim().length<2)
    {
      this.toastService.error("Preencher o campo setor.", 'Atenção')
      return ; 
    }
    if(quadra==null ||  quadra.trim().length<5)
    {
      this.toastService.error("Preencher o campo quadra.", 'Atenção')
      return ; 
    }
    if(logradouro==null || logradouro.trim().length<4)
    {
      this.toastService.error("Preencher o campo logradouro.", 'Atenção')
      return ; 
    }
    if(lote==null || lote.trim().length<5)
    {
      this.toastService.error("Preencher o campo lote.", 'Atenção')
      return ; 
    }
    if(sublote==null || sublote.trim().length<3)
    {
      this.toastService.error("Preencher o campo sublote.", 'Atenção')
      return ; 
    }

    this.toastService.info("Localizando...", "");

    let inscricao = setor+quadra+logradouro+lote+sublote;

    this.imovelService.fitFeature(inscricao);

  }

}
