<mat-card>
    <mat-card-header>
        <mat-card-title>Informações sobre Núcleo Irregular</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <table class="example-full-width" cellspacing="0" id="edit-settlement-table">
            <tr>
                <td colspan="3">
                    <mat-form-field class="example-full-width">
                        <mat-label>Núcleo:</mat-label>
                        <input matInput [(ngModel)]="settlement.nucleo" class="property-value" [readonly]="readOnly">
                    </mat-form-field>           
                </td>
                <td >
                    <mat-form-field class="example-full-width">
                        <mat-label>Sigla do Núcleo:</mat-label>
                        <input matInput [(ngModel)]="settlement.sigla" class="property-value"  [readonly]="readOnly">
                    </mat-form-field>
                </td>
                <td >
                    <mat-form-field class="example-full-width">
                        <mat-label>Código do PLHIS:</mat-label>
                        <input matInput [(ngModel)]="settlement.codigoPLHIS" class="property-value" [readonly]="readOnly">
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td >
                    <mat-form-field class="example-full-width">
                        <mat-label>Poligonal:</mat-label>
                        <mat-select required class="property-value" [(ngModel)]="settlement.poligonal" [disabled]="readOnly">
                            <mat-option *ngFor="let item of poligonalList" [value]="item" >
                            {{item}}
                            </mat-option>
                        </mat-select>                        
                    </mat-form-field>           
                </td>
                <td >
                    <mat-form-field class="example-full-width">
                        <mat-label>Tipo de Inadequação:</mat-label>
                        <mat-select required class="property-value" [(ngModel)]="settlement.tipoInadequacao" [disabled]="readOnly">
                            <mat-option *ngFor="let item of inadequacaoList" [value]="item" >
                                {{item}}
                                </mat-option>
                        </mat-select>   
                    </mat-form-field>
                </td>
                <td >
                    <mat-form-field class="example-full-width">
                        <mat-label>Origem:</mat-label>
                        <input matInput [(ngModel)]="settlement.origem" class="property-value" [readonly]="readOnly">
                    </mat-form-field>
                </td>
                <td >
                    <div class="areaDiv">
                        <div class="flex1">
                            <mat-form-field class="example-full-width">
                                <mat-label>Área Total:</mat-label>
                                <input matInput [(ngModel)]="settlement.areaTotal" [allowNegativeNumbers]="false" suffix=" m²" mask="separator.2" [thousandSeparator]="'.'" [decimalMarker]="','"  class="property-value" disabled  [readonly]="readOnly">
                            </mat-form-field>
                        </div>
                        <div class="refresh-button-div" *ngIf="readOnly==false">
                            <button *ngIf="calculatingArea==false" type="button" (click)="calculateArea(settlement.id)" mat-raised-button color="basic" title="Recalcular Área Total">
                                <span class="material-icons">refresh</span> 
                            </button>
                            <button *ngIf="calculatingArea==true" disabled type="button" mat-raised-button color="basic" title="Recalculando Área Total">
                                <span class="material-icons">refresh</span> 
                            </button>
                        </div>
                    </div>
                    
                    
                </td>
                <td >
                    <mat-form-field class="example-full-width">
                        <mat-label>Propriedade:</mat-label>
                        <mat-select required class="property-value" [(ngModel)]="settlement.propriedade"  [disabled]="readOnly">
                            <mat-option *ngFor="let item of propriedadeList" [value]="item" >
                                {{item}}
                                </mat-option>
                        </mat-select>   
                    </mat-form-field>
                </td>
            </tr>
            <tr>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Tipologia do Assentamento:</mat-label>
                        <mat-select required class="property-value" [(ngModel)]="settlement.tipologia"  [disabled]="readOnly">
                            <mat-option *ngFor="let item of tipologiaList" [value]="item" >
                                {{item}}
                                </mat-option>
                        </mat-select>   
                    </mat-form-field>           
                </td>
                <td>
                    <mat-form-field class="example-full-width">
                        <mat-label>Nro de Domicílios:</mat-label>
                        <input matInput [(ngModel)]="settlement.nroDomicilios" class="property-value"  [readonly]="readOnly">
                    </mat-form-field>
                </td>
                <td >
                    <div class="nroCoberturasDiv">
                        <div class="flex1">
                            <mat-form-field class="example-full-width">
                                <mat-label>Nro de Coberturas/Telhados:</mat-label>
                                <input matInput [(ngModel)]="settlement.nroCoberturas" class="property-value" [readonly]="readOnly" disabled>
                            </mat-form-field>
                        </div>
                        <div class="refresh-button-div" *ngIf="readOnly==false">
                            <button *ngIf="calculatingCoberturas==false" type="button" (click)="calculateNroCoberturas(settlement.id)" mat-raised-button color="basic" title="Recalcular Coberturas/Telhados">
                                <span class="material-icons">refresh</span> 
                            </button>
                            <button *ngIf="calculatingCoberturas==true"   disabled type="button" mat-raised-button color="basic" title="Recalculando Coberturas/Telhados">
                                <span class="material-icons fa-spin">refresh</span> 
                            </button>
                        </div>
                    </div>
                    

                </td>
            </tr>
        </table>
    </mat-card-content>
</mat-card>

<mat-card>
    <mat-tab-group mat-align-tabs="start">
        <mat-tab label="Documentação em Geral">
            <mat-card>
                <mat-card-content>
                    <table mat-table [dataSource]="settlement.documentos" class="mat-elevation-z8 example-full-width">
            
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> Id </th>
                            <td mat-cell *matCellDef="let item"> {{item.id}} </td>
                        </ng-container>
            
                        <ng-container matColumnDef="nome">
                            <th mat-header-cell *matHeaderCellDef> Nome </th>
                            <td mat-cell *matCellDef="let item"> {{item.nome_arquivo}} </td>
                        </ng-container>
            
                        <ng-container matColumnDef="dataInsercao">
                            <th mat-header-cell *matHeaderCellDef> Data de Inserção </th>
                            <td mat-cell *matCellDef="let item"> {{formatDate(item.data_insercao,'dd/MM/yyyy')}} </td>
                        </ng-container>
            
                        <!-- Weight Column -->
                        <ng-container matColumnDef="tipo">
                            <th mat-header-cell *matHeaderCellDef> Tipo Arquivo </th>
                            <td mat-cell *matCellDef="let item"> {{item.extensao_arquivo}} </td>
                        </ng-container>
                        
                        <!-- Symbol Column -->
                        <ng-container matColumnDef="acoes">
                            <th  mat-header-cell *matHeaderCellDef="let item"> Ações 
                                <mat-icon *ngIf="hasEditionPermition() && readOnly==false" title="Inserir e associar novo documento " (click)="newDocumentUpload(settlementDocumentType,'Enviar Documento de Assentamento')" class="addDocumentoUpload">file_upload</mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let item" > 
                                <mat-icon *ngIf="hasDocumentPreview(item)" (click)="previewDocument(item.id, item.extensao_arquivo)" title="Visualizar Documento" class="btn-icon download-documento" aria-hidden="false" aria-label="Visualizar Documento">preview</mat-icon> 
                                <mat-icon (click)="downloadDocument(item.id, item.nome_arquivo, item.extensao_arquivo)" title="Visualizar Documento" class="btn-icon download-documento" aria-hidden="false" aria-label="Visualizar Documento">download</mat-icon> 
                                <mat-icon *ngIf="hasEditionPermition() && readOnly==false" (click)="confirmDeleteDocument(item.id)" title="Apagar Documento" class="btn-icon apagar-documento" aria-hidden="false" aria-label="Apagar Documento">indeterminate_check_box</mat-icon> 
                            </td>
                        </ng-container>
                        
                        <tr mat-header-row *matHeaderRowDef="documentosDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: documentosDisplayedColumns;"></tr>
                        </table>
                </mat-card-content>
            </mat-card>
        </mat-tab>
        <mat-tab label="Projetos">
            <mat-card>
                <mat-card-content>
                    <table mat-table [dataSource]="settlement.projetos" class="mat-elevation-z8 example-full-width">
            
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> Id </th>
                            <td mat-cell *matCellDef="let item"> {{item.id}} </td>
                        </ng-container>
            
                        <ng-container matColumnDef="nome">
                            <th mat-header-cell *matHeaderCellDef> Nome </th>
                            <td mat-cell *matCellDef="let item"> {{item.nome_arquivo}} </td>
                        </ng-container>
            
                        <ng-container matColumnDef="dataInsercao">
                            <th mat-header-cell *matHeaderCellDef> Data de Inserção </th>
                            <td mat-cell *matCellDef="let item"> {{formatDate(item.data_insercao,'dd/MM/yyyy')}} </td>
                        </ng-container>
            
                        <!-- Weight Column -->
                        <ng-container matColumnDef="tipo">
                            <th mat-header-cell *matHeaderCellDef> Tipo Arquivo </th>
                            <td mat-cell *matCellDef="let item"> {{item.extensao_arquivo}} </td>
                        </ng-container>
                        
                        <!-- Symbol Column -->
                        <ng-container matColumnDef="acoes">
                            <th  mat-header-cell *matHeaderCellDef="let item"> Ações 
                                <mat-icon *ngIf="hasEditionPermition() && readOnly==false" title="Inserir e associar novo projeto " (click)="newDocumentUpload(settlementProjectDocumentType,'Enviar Projeto')" class="addDocumentoUpload">file_upload</mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let item" >
                                <mat-icon *ngIf="hasDocumentPreview(item)" (click)="previewDocument(item.id, item.extensao_arquivo)" title="Visualizar Documento" class="btn-icon download-documento" aria-hidden="false" aria-label="Visualizar Documento">preview</mat-icon>                                  
                                <mat-icon (click)="downloadDocument(item.id, item.nome_arquivo, item.extensao_arquivo)" title="Visualizar Documento" class="btn-icon download-documento" aria-hidden="false" aria-label="Visualizar Documento">download</mat-icon> 
                                <mat-icon *ngIf="hasEditionPermition() && readOnly==false" (click)="confirmDeleteDocument(item.id)" title="Apagar Documento" class="btn-icon apagar-documento" aria-hidden="false" aria-label="Apagar Documento">indeterminate_check_box</mat-icon> 
                            </td>
                        </ng-container>
                        
                        <tr mat-header-row *matHeaderRowDef="documentosDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: documentosDisplayedColumns;"></tr>
                        </table>
                </mat-card-content>
            </mat-card>
        </mat-tab>
        <mat-tab label="Gravames Judiciais">
            <mat-card>
                <mat-card-content>
                    <table mat-table [dataSource]="settlement.gravames" class="mat-elevation-z8 example-full-width">
            
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef> Id </th>
                            <td mat-cell *matCellDef="let item"> {{item.id}} </td>
                        </ng-container>
            
                        <ng-container matColumnDef="nome">
                            <th mat-header-cell *matHeaderCellDef> Nome </th>
                            <td mat-cell *matCellDef="let item"> {{item.nome_arquivo}} </td>
                        </ng-container>
            
                        <ng-container matColumnDef="dataInsercao">
                            <th mat-header-cell *matHeaderCellDef> Data de Inserção </th>
                            <td mat-cell *matCellDef="let item"> {{formatDate(item.data_insercao,'dd/MM/yyyy')}} </td>
                        </ng-container>
            
                        <!-- Weight Column -->
                        <ng-container matColumnDef="tipo">
                            <th mat-header-cell *matHeaderCellDef> Tipo Arquivo </th>
                            <td mat-cell *matCellDef="let item"> {{item.extensao_arquivo}} </td>
                        </ng-container>
                        
                        <!-- Symbol Column -->
                        <ng-container matColumnDef="acoes">
                            <th  mat-header-cell *matHeaderCellDef="let item"> Ações 
                                <mat-icon *ngIf="hasEditionPermition() && readOnly==false" title="Inserir e associar gravames judiciais " (click)="newDocumentUpload(settlementJudicialDocumentType,'Enviar Gravames Judiciais')" class="addDocumentoUpload">file_upload</mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let item" >
                                <mat-icon *ngIf="hasDocumentPreview(item)" (click)="previewDocument(item.id, item.extensao_arquivo)" title="Visualizar Documento" class="btn-icon download-documento" aria-hidden="false" aria-label="Visualizar Documento">preview</mat-icon>                                  
                                <mat-icon (click)="downloadDocument(item.id, item.nome_arquivo, item.extensao_arquivo)" title="Visualizar Documento" class="btn-icon download-documento" aria-hidden="false" aria-label="Visualizar Documento">download</mat-icon> 
                                <mat-icon *ngIf="hasEditionPermition() && readOnly==false" (click)="confirmDeleteDocument(item.id)" title="Apagar Documento" class="btn-icon apagar-documento" aria-hidden="false" aria-label="Apagar Documento">indeterminate_check_box</mat-icon> 
                            </td>
                        </ng-container>
                        
                        <tr mat-header-row *matHeaderRowDef="documentosDisplayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: documentosDisplayedColumns;"></tr>
                        </table>
                </mat-card-content>
            </mat-card>
        </mat-tab>
        
        
        <mat-tab label="Histórico da Contagem de Domicílios:">
            <mat-card>
                <mat-card-content>
                    <table mat-table [dataSource]="historicoDataSource" class="mat-elevation-z8 example-full-width" id="edit-settlement-qtd-domicilios-table">
            
                          
                        <ng-container matColumnDef="add-ano">
                            <th mat-header-cell *matHeaderCellDef> 
                                
                                    <mat-form-field appearance="fill" >
                                        <mat-label>Ano:</mat-label>
                                        <input matInput [formControl]="ano" [matDatepicker]="picker"  [max]="maxDate" class="property-value" required>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker [dateClass]="dateClass" #picker startView="multi-year"
                                        (yearSelected)="chosenYearHandler($event, picker)"></mat-datepicker>
                                    </mat-form-field>                                    
                                
                             </th>
                        </ng-container>
                        
                        <ng-container matColumnDef="add-qtd">
                            <th mat-header-cell *matHeaderCellDef>
                                
                                    <mat-form-field appearance="fill">
                                        <mat-label>Quantidade de Domicílio:</mat-label>
                                        <input matInput type="number" [formControl]="qtd"  class="property-value" min="1"
                                        step="1" oninput="this.value=(parseInt(this.value)||0)" required>    
                                    </mat-form-field>                                    
                                
                                
                            </th>
                        </ng-container>

                        <ng-container matColumnDef="add-obs">
                            <th mat-header-cell *matHeaderCellDef>
                                
                                    <mat-form-field appearance="fill">
                                        <mat-label>Observações:</mat-label>
                                        <textarea matInput [formControl]="obs"  class="property-value" >   </textarea> 
                                    </mat-form-field>
                                
                                
                            </th>
                        </ng-container>

                        <ng-container matColumnDef="add-botoes">
                            <th mat-header-cell *matHeaderCellDef>
                                <div>
                                    <mat-icon (click)="saveHistorico()" title="Adicionar histórico de quantidade de domicilios" class="add-new-historico-icon">done</mat-icon>                                    
                                </div>                                
                            </th>
                        </ng-container>

                        <ng-container matColumnDef="ano">
                            <th mat-header-cell *matHeaderCellDef> Ano </th>
                            <td mat-cell *matCellDef="let item"> {{item.ano}} </td>
                        </ng-container>
            
                        <ng-container matColumnDef="qtd">
                            <th mat-header-cell *matHeaderCellDef> Quantidade de Domicílios </th>
                            <td mat-cell *matCellDef="let item"> {{item.qtd}} </td>
                        </ng-container>

                        <ng-container matColumnDef="obs">
                            <th mat-header-cell *matHeaderCellDef> Observações </th>
                            <td mat-cell *matCellDef="let item"> {{item.obs}} </td>
                        </ng-container>
                       
                        <!-- Symbol Column -->
                        <ng-container matColumnDef="acoes">
                            <th  mat-header-cell *matHeaderCellDef="let item"> Ações 
                                <mat-icon *ngIf="hasEditionPermition() && newHistoricoDomicilio==null && readOnly==false" title="Inserir histórico de quantidade de domicilios" (click)="showNewHistorico()" class="addQtdDomicilios">add</mat-icon>
                                <mat-icon *ngIf="hasEditionPermition() && newHistoricoDomicilio!=null && readOnly==false" (click)="hideNewHistorico()" title="Cancelar" class="cancel-new-historico-icon">block</mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let item" >                                 
                                <mat-icon *ngIf="hasEditionPermition() && readOnly==false" (click)="deleteQtdDomicilios(item.ano)" title="Apagar histórico de quantidade de domicilios" class="btn-icon apagar-documento" aria-hidden="false" aria-label="Apagar  histórico de quantidade de domicilios">indeterminate_check_box</mat-icon> 
                                <mat-icon *ngIf="hasEditionPermition() && readOnly==false" (click)="editQtdDomicilios(item)" title="Editar histórico de quantidade de domicilios" class="btn-icon editar-historico" aria-hidden="false" aria-label="Apagar  histórico de quantidade de domicilios">edit</mat-icon> 
                            </td>
                        </ng-container>
                        
                        <tr mat-header-row *matHeaderRowDef="historicosQtdDomiciliosDisplayedColumns"></tr>
                        
                        <tr mat-header-row *matHeaderRowDef="['add-ano', 'add-qtd', 'add-obs', 'add-botoes']" class="new-historico-tr" [ngClass]="newHistoricoDomicilio==null ? 'hide' : ''"></tr>
                        
                        
                        <tr mat-row *matRowDef="let row; columns: historicosQtdDomiciliosDisplayedColumns;"></tr>
                        
                    
                        </table>
                </mat-card-content>
            </mat-card>
        </mat-tab>
    </mat-tab-group>
</mat-card>
<mat-card *ngIf="hasEditionPermition() && readOnly==false">
    <mat-card-content>
        <div class="align-row buttons-div">

            <button type="button" (click)="close()" mat-raised-button color="primary" title="Fechar">
              <span class="material-icons">close</span> Fechar
            </button>    
            <button type="button" (click)="confirmSave()" mat-raised-button color="primary" title="Salvar as Informações">
              <span class="material-icons">save</span> Salvar
            </button>           
        
        </div> 
    </mat-card-content>
</mat-card>