import {ToolsService} from '../tools.service';
import GeoJSON from 'ol/format/GeoJSON';

import { LayersInfo } from '../../vo/layersinfo';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
//import { FetureInfoToolComponent } from '../fetureinfo-tool.component';
import { DialogComponent } from '../../dialog/dialog.component';
import { Tool } from '../tool';
import { Feature } from 'src/app/map/vo/feature';
import { AbstractLayerTool } from '../abstract-layer-tool';
import { LayerConfig } from 'src/app/map/layers/layers.config';
import { CanvasService } from 'src/app/map/canvas/canvas.service';
import { LayersConfigService } from 'src/app/map/layers/layers.config.service';
import { PropertysearchToolComponent } from '../propertysearch-tool/propertysearch-tool.component';
import { EditionoperationsInner, Historicoprocessoimovel, HistoricoprocessoimovelInner, Imoveisrelations } from '@funcate/sigweb-cti-api';
import { EditHistoricoComponent } from '../property-registration/edit-historico/edit-historico.component';
import { FeatureService } from 'src/app/service/feature.service';
import Point from 'ol/geom/Point';
import { Property } from '../../vo/property';
import { BackendService } from 'src/app/service/backend.service';
import { ToastService } from 'src/app/service/toast.service';
import { Imovel } from '../../vo/imovel';
import { Inscricao } from '../../vo/inscricao';
import MultiPoint from 'ol/geom/MultiPoint';
import { GeometryService } from 'src/app/service/geometry.service';
import { GenericConfirmationDialogComponent } from '../../dialog/generic-confirmation-dialog/generic-confirmation-dialog.component';

export class NewImovelTool extends AbstractLayerTool {

    private dialog: MatDialog;

    cursorType='tool-cursor-pointer';
    id='add-imovel-tool';
    enabled=false;
    name='Add Imovel Tool';
    title='Espacializar novo imóvel';
    type='click';
    map:L.Map=null;
    ll: any; 
    protected visible:boolean=true;
    icon='push_pin';
    layerTool: boolean=true;
    customParameter=new Map<String, any>();
    private selectedFeature: Feature;
    private dialogRef: any;
    addHistoricoDialog: any;
    public imoveisICAttributeConfig: string = "inscricao_attribute";
    private geoJSONDefaultSRID = '4326'
    private selectedInscricoes : Inscricao[];
    private currentHistoricoId: any;
    private newImoveisId: any[];
    private confirmDialogRef: any;


    searchPropertyDialogRef: MatDialogRef<DialogComponent>;

    constructor(toolService: ToolsService, private backendService: BackendService, private layerConfigService: LayersConfigService, 
        private toastService: ToastService, private canvasService: CanvasService, dialog: MatDialog) 
    { 
        super(toolService)
        this.dialog = dialog;
    }

    public execAction=(event:L.LeafletMouseEvent)=>{
        this.map=event.sourceTarget;
        this.ll=event.latlng;

        this.searchInscricoes();        
    };

    public commitOperation()
    {

        let layer = this.layerConfigService.getCtiLayer();

        let layerTable = this.layerConfigService.getLayerTable(layer);
    
        let layerGeomAttribute = this.layerConfigService.getLayerGeomAttribute(layer);

        let imoveisIdAttribute = this.layerConfigService.getLayerIdAttribute(layer);

        let imoveisICAttribute = this.layerConfigService.getLayerAppConfig(layer, this.imoveisICAttributeConfig);                  

        let format = new GeoJSON();
        
        let multiGeom : MultiPoint =  new MultiPoint([[this.ll.lng, this.ll.lat]]);
        
        let geoJSON = format.writeGeometry(multiGeom, {featureProjection: "EPSG:"+this.geoJSONDefaultSRID});  
        
        let operations : Array<EditionoperationsInner> = []   

        this.selectedInscricoes.forEach(selectedInscricao => 
        {
            let currentOperation : EditionoperationsInner = new Object();
            currentOperation.operationtype=1;    
    
            let newProperties = [];    
    
            {            
                newProperties.push(new Property(imoveisICAttribute, selectedInscricao.getInscricaoCompleta()));
                newProperties.push(new Property("setor", selectedInscricao.setor));
                newProperties.push(new Property("quadra", selectedInscricao.quadra));
                newProperties.push(new Property("logradouro", selectedInscricao.logradouro));
                newProperties.push(new Property("lote", selectedInscricao.lote));
                newProperties.push(new Property("sublote", selectedInscricao.sublote));
            }

            currentOperation.table=layerTable;
            currentOperation.geomattribute=layerGeomAttribute;
            currentOperation.geom=geoJSON;
            currentOperation.idattribute=imoveisIdAttribute;
            currentOperation.srid=this.geoJSONDefaultSRID;
            currentOperation.properties=newProperties;
            currentOperation['ic']=selectedInscricao.getInscricaoCompleta();
     
            operations.push(currentOperation);
        });

        this.backendService.executeEditionOperations(operations).then((outOperations)=>
        {          
            if(outOperations.length>0)
            {   
                this.toastService.sucess(outOperations.length + (outOperations.length==1?" Imóvel criado":" Imóveis criados") + " com sucesso. ", "Sucesso");
                this.newImoveisId = [];                
                this.updateImoveisRelation(outOperations);
                this.canvasService.redrawLayer(layer);
            }
            
        }).catch((exception: any)=>
        {
          this.toastService.error("Erro ao inserir " + (operations.length==1?"novo imóvel":"novos imóveis")  +" na camada. ", "Erro");
          this.rollbackHistorico();
          console.log(exception);
        });
  
    }

    public enableImpl()
    {
        let layer : LayerConfig = this.layerConfigService.getCtiLayer();
        if(layer.isEnabled()==false)
        {
            this.layerConfigService.enableLayer(layer);
        }
    }
    public disableImpl()
    {
    }

    public closeDialog()
    {
        this.dialogRef.close();
    }
    public setFeature(feature: Feature)
    {
        this.selectedFeature = feature;
    }

    public isEnabled(layer :LayerConfig) : boolean
    {
        let currentLayerTool = this.layerConfigService.getCtiLayer();

        if(this.enabled && currentLayerTool.getId()==layer.getId() )
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    public searchInscricoes()
    {
  
        let oneOnly = false;
  
        this.searchPropertyDialogRef = this.dialog.open(DialogComponent, {
            height: '80%',
            width: '90%',
            data: { component: PropertysearchToolComponent, title: "Pesquisa de Imóveis", parent: this, searchOnly: true, withoutGeometry: true, oneOnly: oneOnly}
        });      
    }

    public closeSearchInscricoes(imoveis: Imovel[])
    {
        this.selectedInscricoes=[];

        if(imoveis.length>1)
        {
            this.confirm(imoveis);
        }
        else
        {
            this.startInsertImovel(imoveis);   
        }
    }

    private confirm(imoveis: Imovel[])
    { 
          this.confirmDialogRef = this.dialog.open(DialogComponent, {
            height: '30%',
            width: '20%',
            data: { component: GenericConfirmationDialogComponent, title: "Confirmar", confirmTitle: "", confirmMessage: "Deseja realmente inserir as " + imoveis.length + " inscrições selecionadas?" }
          });
    
          this.confirmDialogRef.afterClosed().toPromise().then((confirmResult)=>{
            if(confirmResult)
            {
              
              //Commit das operações
              this.startInsertImovel(imoveis);         
            }
          });        
    }

    private startInsertImovel(imoveis: Imovel[])
    {
        this.searchPropertyDialogRef.close();

        imoveis.forEach(imovel => {
        
            let ic = Inscricao.fromImovel(imovel);

            this.selectedInscricoes.push(ic);             
        });
        this.addHistorico();
    }

    
    private addHistorico()
    {  
        let item : HistoricoprocessoimovelInner = new Object;

        this.addHistoricoDialog = this.dialog.open(DialogComponent, {
          height: '60%',
          maxHeight: '60%',
          maxWidth: '80%',
          data: { component: EditHistoricoComponent, title: "Adicionar histórico de processo para o imóvel inserido", historico: item, icList: this.selectedInscricoes, parent: this  }
        });
    }
  
    //Após fechar a adição do historico do processo, se voltar um o ID, passamos pro proxima passo e guardamos o ID para caso de precisar voltar atrás
    public closeEditHistorico(historicoProcesso: Historicoprocessoimovel)
    {
      if(historicoProcesso.length>0)
      {
        if(historicoProcesso[0].id)
        {
           this.currentHistoricoId = historicoProcesso[0].id.toString();
          if(this.addHistoricoDialog)
          {
            this.addHistoricoDialog.close();

            this.commitOperation();

          }
        }
      }
      
    }

    private rollbackHistorico()
    {
      this.backendService.deleteHistoricoProcessoImovel(this.currentHistoricoId).then(()=>
      {
        console.log("Remoção do historico de processo adicionado (id: "+this.currentHistoricoId+") devido a um problema no commit da operação de inserção.")
      })
    }
    public isVisibleForLayer(layer :LayerConfig) : boolean
    {
        return this.layerTool;
    }


    private updateImoveisRelation(editionOperations: any[])
    {
        if(editionOperations && editionOperations.length>0)
        {
            let imoveisRelations: Imoveisrelations = [];

            editionOperations.forEach(editionOperation => 
            {
                let imovelRelation = new Object();

                imovelRelation['inscricao'] = editionOperation['ic'];
        
                imovelRelation['imovel_id'] = editionOperation['sucess'];
        
                imoveisRelations.push(imovelRelation);                                
            });            
    
          this.backendService.updateImoveisRelation(imoveisRelations).then((updatedImoveisRelations)=>
          {
            this.toastService.sucess("Relações do Imóvel inserido atualizadas.", "Sucesso");
            console.log(updatedImoveisRelations);            
          });
        }
        
    }
}
