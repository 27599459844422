<mat-card >
    <mat-card-header>
      <mat-icon>confirm</mat-icon>  
      <mat-card-title>{{title}}</mat-card-title>
      <mat-card-subtitle></mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <p>{{confirmMessage}}</p>
    </mat-card-content>
    <mat-card-actions class="confirm-actions">
      <button mat-raised-button color="primary" (click)="confirm()">Confirmar</button>
      <button mat-raised-button color="warn" (click)="cancel()">Cancelar</button>
    </mat-card-actions>
  </mat-card>