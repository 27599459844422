import { SettlementDocumentType } from './settlement-document-type';
import { SettlementProjectDocumentType } from './settlement-project-document-type';
import { SettlementJudicialDocumentType } from './settlement-judicial-document-type';
import { GenericFeatureDocumentType } from './generic-feature-document-type';
import { SettlementPropertyCadSocialTitularDocumentType } from './settlement-property-cad-social-titular-document-type';
import { SettlementPropertyCadSocialSEHABDocumentType } from './settlement-property-cad-social-sehab-document-type';
import { SettlementPropertyDocumentType } from './settlement-property-document-type';
import { AbstractDocumentAuxiliarField } from './auxiliar-fields/abstract-document-auxiliar-field';
export abstract class AbstractDocumentType
{
  static readonly SETTLEMENT_DOCUMENTS:number = 0;
  static readonly SETTLEMENT_PROJECTS_DOCUMENTS:number = 1;
  static readonly SETTLEMENT_JUDICIAL_DOCUMENTS:number = 2;
  static readonly GENERIC_FEATURE_DOCUMENTS:number = 3;
  static readonly SETTLEMENT_PROPERTY_CAD_SOCIAL_TITULAR_DOCUMENTS:number = 4;
  static readonly SETTLEMENT_PROPERTY_CAD_SOCIAL_SEHAB_DOCUMENTS:number = 5;
  static readonly SETTLEMENT_PROPERTY_DOCUMENTS:number = 6;

   type: number;
   abstract getSupportedExtensions();

   static getInstance(id: number)
   {
      if(id==this.SETTLEMENT_DOCUMENTS)
      {
         return new SettlementDocumentType(this.SETTLEMENT_DOCUMENTS);
      } else if(id==this.SETTLEMENT_PROJECTS_DOCUMENTS)
      {
         return new SettlementProjectDocumentType(this.SETTLEMENT_PROJECTS_DOCUMENTS);
      }else if(id==this.SETTLEMENT_JUDICIAL_DOCUMENTS)
      {
         return new SettlementJudicialDocumentType(this.SETTLEMENT_JUDICIAL_DOCUMENTS);
      }else if(id==this.GENERIC_FEATURE_DOCUMENTS)
      {
         return new GenericFeatureDocumentType(this.GENERIC_FEATURE_DOCUMENTS);
         
      }else if(id==this.SETTLEMENT_PROPERTY_CAD_SOCIAL_TITULAR_DOCUMENTS)
      {
         return new SettlementPropertyCadSocialTitularDocumentType(this.SETTLEMENT_PROPERTY_CAD_SOCIAL_TITULAR_DOCUMENTS);
      }else if(id==this.SETTLEMENT_PROPERTY_CAD_SOCIAL_SEHAB_DOCUMENTS)
      {
         return new SettlementPropertyCadSocialSEHABDocumentType(this.SETTLEMENT_PROPERTY_CAD_SOCIAL_SEHAB_DOCUMENTS);
      } else if(id==this.SETTLEMENT_PROPERTY_DOCUMENTS)
      {
         return new SettlementPropertyDocumentType(this.SETTLEMENT_PROPERTY_DOCUMENTS);
      }
      throw ("Tipo de documento sem implementação!");
   }

   getAuxiliarComponents() : AbstractDocumentAuxiliarField[]
   {
      return []
   }
}
